import React, {useEffect} from 'react';
import {useLocation} from 'react-router';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useAuth} from './components/AuthPage';
import {ActivateAccount} from './components/AuthPage/ActivateAccount';
import {ForgotPassword} from './components/AuthPage/ForgotPassword';
import {Login} from './components/AuthPage/Login';
import {ResendActivation} from './components/AuthPage/ResendActivation';
import {ResetPassword} from './components/AuthPage/ResetPassword';
import {Signup} from './components/AuthPage/Signup';
import {StudioPage} from './components/StudioPage';
import {useAppDispatch} from './store';
import {loadConfig} from './store/config';

const App = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch && dispatch(loadConfig());
  }, [dispatch]);

  return (
    <Switch>
      <Route path="/login" render={props => <Login {...props} />} />
      <Route path="/signup" render={props => <Signup {...props} />} />
      <Route path="/activateAccount" render={props => <ActivateAccount {...props} />} />
      <Route
        path="/resend-activation"
        render={props => <ResendActivation {...props} />}
      />
      <Route path="/forgot-password" render={props => <ForgotPassword {...props} />} />
      <Route path="/resetConfirmation" render={props => <ResetPassword {...props} />} />
      <Route path="/netron" />
      <PrivateRoute path="*" render={() => <StudioPage />} />
    </Switch>
  );
};

const PrivateRoute = ({authenticated, render, ...rest}) => {
  const {isAuthenticated} = useAuth();
  const location = useLocation();

  if (isAuthenticated) {
    return <Route {...rest} render={props => render(props)} />;
  } else {
    return <Redirect to={{pathname: '/login', state: {from: location}}} />;
  }
};

export default App;
