import {Paper} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {IntegrationRole} from '../../../types/integrations/IntegrationResponse';
import {StudioTab, StudioTabs} from '../../../base-components/StudioTab';
import {useMarketplace, actions} from '../../Marketplace';
import {Modules} from '../Modules';
import {Link} from 'react-router-dom';
import {fetchSolutionCategories} from '../../../store/marketplace/solutions';
import {RoleTabs} from '../RoleTabs';
import {useStudioMode} from '../../StudioMode/StudioModeProvider';
import {LiteMarketplaceInfo} from '../../StudioLite/LiteMarketplaceInfo';

const {setFilters} = actions;
const PAGE_SIZE = 3;
const PURPOSE = 'SOLUTION_STORAGE';

export const Solutions = () => {
  const {mode} = useStudioMode();
  const isLiteMode = mode === 'LITE';

  const reduxDispatch = useDispatch();
  const [categoryTabIndex, setCategoryTabIndex] = useState(0);
  const [role, setRole] = useState<IntegrationRole>('PUBLIC');
  const showInfoOnly = isLiteMode && role !== 'PUBLIC';
  const categories = useSelector((state: RootState) => state.solutions.categories[role]);

  const {
    state: {results},
    dispatch,
  } = useMarketplace({
    role,
    purpose: PURPOSE,
    pageSize: PAGE_SIZE,
    isEnabled: !showInfoOnly,
  });

  const category = categories[categoryTabIndex];

  useEffect(() => {
    reduxDispatch(fetchSolutionCategories('PUBLIC'));
    reduxDispatch(fetchSolutionCategories('ORGANIZATION'));
  }, [reduxDispatch]);

  useEffect(() => {
    dispatch(setFilters({categoryName: category}));
  }, [category, dispatch]);

  const searchParams = new URLSearchParams({
    role: isLiteMode ? 'PUBLIC' : role,
    purpose: PURPOSE,
  }).toString();

  return (
    <>
      <Paper className="dashboard__paper" data-testid="dashboard-solutions">
        <h5 className="dashboard__item-title">
          <FormattedMessage id="dashboard.solutions" />
        </h5>
        <RoleTabs role={role} onChange={setRole} />
        <div className="dashboard__item-body">
          <StudioTabs
            value={categories.findIndex(tab => tab === category)}
            onChange={(event, index) => setCategoryTabIndex(index)}
            variant="scrollable"
            className="dashboard__tabs"
          >
            {categories.map((name, index) => (
              <StudioTab key={index} label={name} data-testid="dashboard-solution-tab" />
            ))}
          </StudioTabs>
          {isLiteMode && showInfoOnly ? (
            <div className="marketplace__lite-tab">
              <LiteMarketplaceInfo />
            </div>
          ) : (
            <Modules list={results} type={PURPOSE} />
          )}
        </div>
      </Paper>
      <div className="dashboard__view-all">
        <Link to={`/marketplace?${searchParams}`}>
          <FormattedMessage id="dashboard.viewAllSolutions" />
        </Link>
      </div>
    </>
  );
};
