import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Dialog, Typography} from '@material-ui/core';
import RoundButton from '../../../base-components/StudioButton/RoundButton';
import {IntegrationTile} from './IntegrationTile';
import {FormattedMessage} from 'react-intl';
import {RootState} from '../../../store';
import {
  fetchNewIntegrationForm,
  setIsChooseModalOpen,
  setIsCreateModalOpen,
} from '../../../store/marketplace/configure';
import {IntegrationType} from '../../../types/integrations/IntegrationTypesResponse';
import './ChooseIntegrationDialog.scss';

export const ChooseIntegrationDialog = () => {
  const dispatch = useDispatch();
  const types = useSelector(
    (state: RootState) => state.configureMarketplace.integrationTypes
  );
  const isOpen = useSelector(
    (state: RootState) => state.configureMarketplace.isChooseModalOpen
  );
  const createModalPurpose = useSelector(
    (state: RootState) => state.configureMarketplace.createModalPurpose
  );

  const handleTileClick = (type: IntegrationType) => {
    dispatch(fetchNewIntegrationForm({type, purpose: createModalPurpose}));
    dispatch(setIsCreateModalOpen(true));
  };

  return (
    <Dialog fullScreen open={isOpen} data-testid="choose-integration-dialog">
      <div className="choose-integration-dialog__top">
        <RoundButton
          buttonIcon="close"
          buttonRole="secondary"
          size="medium"
          onClick={() => dispatch(setIsChooseModalOpen(false))}
        />
        <div className="choose-integration-dialog__close-btn-label">
          <FormattedMessage id="form.close" />
        </div>
      </div>
      <div className="choose-integration-dialog__body">
        <Typography
          variant="h3"
          component="h2"
          className="choose-integration-dialog__title"
        >
          <FormattedMessage id="marketplaceConfig.select" />
        </Typography>
        <div className="choose-integration-dialog__list">
          {types.map(
            type =>
              type.icon && (
                <IntegrationTile
                  imageSrc={type.icon}
                  title={type.name}
                  description={type.description}
                  className="choose-integration-dialog__tile"
                  key={type.key}
                  onClick={() => handleTileClick(type)}
                />
              )
          )}
        </div>
      </div>
    </Dialog>
  );
};
