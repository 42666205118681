import {Box, Toolbar} from '@material-ui/core';
import {differenceBy, uniq} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../../../store';
import {DataPreparationOrigin} from '../../../types/dataset/DataPreparationContainer';
import {mergeAnnotations, dataPrepareActions} from '../../../store/dataPrepare';
import {startAutoLabel, stopAutoLabel} from '../../../store/iat';
import {
  AnnotationDataType,
  AnnotationType,
  DataPrepareTypeStr,
} from '../../../types/dataset/DatasetGetAnnotationsResponse';
import {Navigation} from '../Navigation';
import {ClassificationPrepare} from './ClassificationPrepare';
import {ConfirmDialog} from '../../../base-components/StudioConfirmDialog';
import {useIntl} from 'react-intl';
import {MergeDialog} from '../MergeDialog';
import {Alert} from '@material-ui/lab';

type ClassificationViewProps = {
  projectId: string;
  datasetId?: string;
  inProgress: boolean;
  origin: DataPreparationOrigin;
  onSubmit: (annotationData: AnnotationDataType) => void;
  onBack: () => void;
};

const isClassification = (annotation: AnnotationType) =>
  annotation.category_id != null && !annotation.bbox;

const {setAnnotationData} = dataPrepareActions;

export const ClassificationView = ({
  projectId,
  datasetId,
  inProgress,
  origin,
  onSubmit,
  onBack,
}: ClassificationViewProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const autoLabelReady = useSelector((state: RootState) => state.iat.autoLabelReady);
  const autoLabelBusy = useSelector((state: RootState) => state.iat.isBusy);
  const isAutoLabel = useSelector((state: RootState) => state.dataPrepare.isAutoLabel);
  const annotationData = useSelector(
    (state: RootState) => state.dataPrepare.annotationData
  );
  const [isMergeDialogOpen, setIsMergeDialogOpen] = useState(false);
  const [predictions, setPredictions] = useState<AnnotationType[]>([]);
  const [isSubmitConfirmOpen, setIsSubmitConfirmOpen] = useState(false);
  const [
    processedAnnotations,
    setProcessedAnnotations,
  ] = useState<AnnotationDataType | null>(null);

  const classificationAnnotations =
    annotationData?.annotations?.filter(isClassification) || [];
  const currentCategories = annotationData?.categories || [];
  const currentImages = annotationData?.images || [];

  const updateClassification = (
    annotations: AnnotationType[],
    isPrediction?: boolean
  ) => {
    const res: any = new Promise(resolve => {
      if (annotations && annotations.length && datasetId && annotationData) {
        const mergeToLocal = () => {
          const diffArr = differenceBy(annotationData.annotations, annotations, 'id');
          dispatch(
            setAnnotationData({
              ...annotationData,
              annotations: diffArr.concat(annotations),
            })
          );
        };

        const images = annotations.map(el => ({
          id: el.image_id,
        }));

        mergeToLocal();
        if (!isPrediction) {
          dispatch(
            mergeAnnotations({
              resourceId: datasetId,
              annotations,
              images,
            })
          ).then(() => resolve({}));
        }
      } else {
        resolve({});
      }
    });
    return res;
  };

  // Start Autolabel
  useEffect(() => {
    if (projectId && datasetId && isAutoLabel && !autoLabelReady && !autoLabelBusy) {
      dispatch(
        startAutoLabel({
          projectId,
          resourceId: datasetId,
          type: DataPrepareTypeStr.CLASSIFICATION,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoLabelReady, projectId, isAutoLabel, dispatch, datasetId]);

  // Stop Autolabel
  useEffect(() => {
    return () => {
      autoLabelReady && dispatch(stopAutoLabel({projectId, resourceId: datasetId}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoLabelReady]);

  const isValid = Boolean(annotationData && annotationData.annotations.length);

  const disableForward = !isValid || inProgress;

  const onForward = () => {
    // Check for unreviewed predictions
    if (!!predictions.length) {
      setIsMergeDialogOpen(true);
    } else {
      setProcessedAnnotations(annotationData);
      validateLabelAndCategories(annotationData);
    }
  };

  const handleSubmit = (data: AnnotationDataType | null) => {
    const final = data ? data.annotations : [];
    if (
      annotationData &&
      final?.length &&
      currentCategories.length &&
      currentImages.length
    ) {
      const validAnnotations = final.filter(el => el?.category_id !== undefined);

      const validImageId = uniq(validAnnotations?.map(el => el.image_id));
      const validCategoryId = uniq(validAnnotations?.map(el => el.category_id));
      const validImages = currentImages.filter(el => validImageId.includes(el.id));
      const validCategories = currentCategories.filter(el =>
        validCategoryId.includes(el.id)
      );

      onSubmit({
        ...annotationData,
        images: validImages,

        categories: validCategories,
        annotations: validAnnotations,
      });
    }
  };

  const validateLabelAndCategories = (data: AnnotationDataType | null) => {
    const annotations = data?.annotations || [];
    const hasOrphanAnnotations = () => !annotations?.every(el => !!el.category_id);
    const hasOrphanLabels = () => {
      // used labels must equal label list count
      const labelUsedList = uniq(annotations?.map(el => el.category_id));
      return labelUsedList.length !== currentCategories.length;
    };
    const hasOrphanImages = () => {
      const imagesUsedList = uniq(annotations.map(el => el.image_id));
      return imagesUsedList.length !== currentImages.length;
    };
    if (hasOrphanAnnotations() || hasOrphanLabels() || hasOrphanImages()) {
      setIsSubmitConfirmOpen(true);
    } else {
      handleSubmit(data);
    }
  };

  const onMerge = async () => {
    setIsMergeDialogOpen(false);
    setProcessedAnnotations(annotationData);
    predictions.length && (await updateClassification(predictions));
    validateLabelAndCategories(annotationData);
  };

  const onDiscard = () => {
    setIsMergeDialogOpen(false);
    if (annotationData) {
      const newData: AnnotationDataType = {
        ...annotationData,
      };
      if (annotationData?.annotations.length && predictions.length) {
        newData.annotations = differenceBy(
          annotationData?.annotations,
          predictions,
          'id'
        );
      }
      setProcessedAnnotations(newData);
      validateLabelAndCategories(newData);
    }
  };
  return (
    <>
      <Toolbar className="data-prepare__header" variant="dense">
        <Box>
          <h4>Add Labels to Images</h4>
        </Box>
        <Box className="detection-view__container">
          {isAutoLabel && (
            <Alert severity="warning" className="detection-view__alert">
              {intl.formatMessage({id: 'dataPrep.detection.assistedLabel'})}
            </Alert>
          )}
        </Box>
        <Navigation
          onForward={onForward}
          onBack={onBack}
          disableForward={disableForward}
          disableBack={inProgress}
        />
      </Toolbar>
      <ClassificationPrepare
        projectId={projectId}
        datasetId={datasetId}
        annotations={classificationAnnotations}
        labels={currentCategories}
        images={currentImages}
        origin={origin}
        onChange={updateClassification}
        onAutoLabel={updateClassification}
        onPredictionChange={setPredictions}
      />
      <MergeDialog
        open={isMergeDialogOpen}
        onClose={() => setIsMergeDialogOpen(false)}
        onMerge={onMerge}
        onDiscard={onDiscard}
        title={intl.formatMessage({
          id: 'dataPrep.classification.title',
        })}
      />
      <ConfirmDialog
        type="confirm"
        title={intl.formatMessage({id: 'dataPrep.classification.title'})}
        message={intl.formatMessage({
          id: 'dataPrep.finalize.warning.desc.classification',
        })}
        open={isSubmitConfirmOpen}
        onClose={() => setIsSubmitConfirmOpen(false)}
        onOk={() => {
          setIsSubmitConfirmOpen(false);
          handleSubmit(processedAnnotations);
        }}
        submitLabel={intl.formatMessage({id: 'form.confirm'})}
      />
    </>
  );
};
