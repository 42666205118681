import {Parameter} from '../../types/model/framework/Parameter';
import {request} from '../request';

export async function getPreProcessorParameters({
  projectId,
  applicationId,
}: {
  projectId: string;
  applicationId: string;
}) {
  return request<Array<Parameter>>({
    method: 'get',
    url: `/preprocessor/parameters/get/${projectId}/${applicationId}`,
  });
}
