import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {StudioSelect} from '../../../base-components/StudioSelect';
import {RootState} from '../../../store';
import {fetchSolutionCategories} from '../../../store/marketplace/solutions';
import {IntegrationRole} from '../../../types/integrations/IntegrationResponse';

type CategoryFilterProps = {
  label: string;
  role: IntegrationRole;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
};

export const CategoryFilter = ({
  role,
  value,
  label,
  placeholder,
  disabled,
  onChange,
}: CategoryFilterProps) => {
  const dispatch = useDispatch();
  const categories = useSelector((state: RootState) => state.solutions.categories[role]);

  useEffect(() => {
    dispatch(fetchSolutionCategories(role));
  }, [dispatch, role]);

  const fieldProps = {
    value,
    onChange: (event: React.ChangeEvent<{value: unknown}>) =>
      onChange(event.target.value as string),
  };

  return (
    <StudioSelect
      options={categories.reduce((acc, curr) => ({...acc, [curr]: curr}), {})}
      placeholder={placeholder}
      label={label}
      fullWidth
      disabled={disabled}
      SelectProps={fieldProps}
    />
  );
};
