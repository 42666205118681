import {Typography, Grid, Box} from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import ReactDropzone from 'react-dropzone';
import Folder from '@material-ui/icons/Folder';
import PropTypes from 'prop-types';
import {PADDING_SIZE} from './DropAreaMap';
import './DropArea.scss';
import {FormattedMessage} from 'react-intl';

function DropArea({size, onChange, message, disabled = false, accept, className}) {
  const onDrop = files => {
    onChange && onChange(files);
  };

  return (
    <Box
      className={cn(
        'droparea-container',
        disabled && 'droparea-container--disabled',
        className
      )}
      data-testid="drop-area"
      id="drop-area"
      p={1}
    >
      <Box className="droparea-inner-container" p={PADDING_SIZE[size]}>
        <ReactDropzone onDrop={onDrop} accept={accept}>
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps()} className="hit-area">
              <input {...getInputProps()} disabled={disabled} />
              <Typography variant="subtitle1" color="textSecondary" align="center">
                {message || <FormattedMessage id="storage.dragAndDrop" />}
              </Typography>
              <Box>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      color={disabled ? 'textSecondary' : 'primary'}
                      component="span"
                    >
                      <FormattedMessage id="storage.clickToBrowse" />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Folder color={disabled ? 'disabled' : 'primary'} fontSize="small" />
                  </Grid>
                </Grid>
              </Box>
            </div>
          )}
        </ReactDropzone>
      </Box>
    </Box>
  );
}

DropArea.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  accept: PropTypes.string,
  message: PropTypes.string,
};

DropArea.defaultProps = {
  onChange: null,
  size: 'xs',
};

export default DropArea;
