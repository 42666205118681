import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ArrowLeftIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="16px"
          height="11px"
          viewBox="0 0 16 11"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <polygon
              id="arrowLeftIcon"
              points="19.5 11.6796875 19.5 13.3203125 7.703125 13.3203125 10.671875 16.328125 9.5 17.5 4.5 12.5 9.5 7.5 10.671875 8.671875 7.703125 11.6796875"
            ></polygon>
          </defs>
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-848.000000, -1299.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 501.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-arrow-left-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#arrowLeftIcon"></use>
                      </mask>
                      <use
                        id="arrow-left"
                        fill="currentColor"
                        xlinkHref="#arrowLeftIcon"
                      ></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

ArrowLeftIcon.propTypes = {
  className: PropTypes.string,
};

export default ArrowLeftIcon;
