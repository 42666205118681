import React, {useContext, useEffect, useState} from 'react';
import Hotkeys from 'react-hot-keys';
import {
  Paper,
  Toolbar,
  IconButton,
  Box,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Annotator from '../../../base-components/StudioAnnotator/Annotator';
import {BoxLabelProps} from '../../../base-components/StudioAnnotator/types';
import MenuList, {
  MenuListLocation,
} from '../../../base-components/StudioAnnotator/MenuList';
import {remove} from 'lodash';
import {
  AnnotatorContext,
  NOT_SCALE_TO_FIT,
} from '../../../base-components/StudioAnnotator/Provider';
import ReactResizeDetector from 'react-resize-detector';
import fileSize from 'filesize';
import {SCALE_FACTOR} from '../../../base-components/StudioAnnotator/const';
import {useIntl} from 'react-intl';
import {
  MAX_SCALE_FACTOR,
  MIN_SCALE_FACTOR,
  ZOOM_PAD_HORIZONTAL,
  ZOOM_PAD_VERTICAL,
} from './const';
import {ImageType} from '../../../types/dataset/DatasetGetAnnotationsResponse';
import './AnnotatorView.scss';

export type LabelDataType = {
  id: number;
  label: string;
};

export type AnnotatorViewProps = {
  image?: ImageType | null;
  src?: string;
  labels?: LabelDataType[];
  annotations?: BoxLabelProps[];
  predictions?: number[];
  onChange?: (labels: BoxLabelProps[]) => void;
  onSelect?: (labels: BoxLabelProps[]) => void;
};

const getScaleFactor = (from: number, to: number) => to / from;

export const AnnotatorView = ({
  image,
  src,
  labels,
  annotations = [],
  predictions = [],
  onChange = () => {},
  onSelect = () => {},
}: AnnotatorViewProps) => {
  const intl = useIntl();
  const FIT_TO_PAGE_LABEL = intl.formatMessage({
    id: 'dataPrep.detection.fitPage',
  });

  const {
    scale,
    imageLayer,
    setImageLayer,
    scaleToFitFactor,
    setScaleToFitFactor,
  } = useContext(AnnotatorContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [lastLabelUsed, setlastLabelUsed] = useState<string | null>(null);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

  const [contextLocation, setContextLocation] = useState<MenuListLocation>({
    x: 0,
    y: 0,
  });

  const onContextMenu = (e: PointerEvent) => {
    setContextLocation({x: e.x, y: e.y});
    setMenuOpen(true);
  };

  const onClose = () => {
    setMenuOpen(false);
    setContextLocation({x: 0, y: 0});
  };

  const onBoxLabelCreated = (label: BoxLabelProps) =>
    onChange([...annotations, lastLabelUsed ? {...label, label: lastLabelUsed} : label]);

  const onDelete = (id: string) => onChange(remove([...annotations], el => el.id !== id));

  const onSelectLabel = (value: string) => {
    setlastLabelUsed(value);
    onChange(
      updateAnnotationLabels(
        annotations.filter(el => selectedLabels.includes(el.id)),
        value
      )
    );
  };

  const onAnnotationChange = (target: BoxLabelProps, value: Partial<BoxLabelProps>) =>
    onChange(updateAnnotation(target, value));

  const updateAnnotation = (target: BoxLabelProps, newProp: Partial<BoxLabelProps>) =>
    annotations.map(el => (el.id === target.id ? {...el, ...newProp} : el));

  const updateAnnotationLabels = (targets: BoxLabelProps[], label: string) =>
    annotations.map(el => (!targets.includes(el) ? el : {...el, label}));

  const resetUI = () => {
    setSelectedLabels([]);
    setMenuOpen(false);
  };

  const onDeleteKey = () => {
    onChange(remove([...annotations], el => !selectedLabels.includes(el.id)));
    resetUI();
  };
  const onSelectAllKey = () => setSelectedLabels(annotations.map(el => el.id));

  const hotKey: {[key: string]: Function} = {
    del: onDeleteKey,
    backspace: onDeleteKey,
    'ctrl+a': onSelectAllKey,
    'cmd+a': onSelectAllKey,
  };

  // Reset annotation selection when image changes
  useEffect(() => setSelectedLabels([]), [image]);

  useEffect(() => {
    // by default use "Fit to page" when image selection changes
    image && setScaleToFitFactor(1);
  }, [image, setScaleToFitFactor]);

  useEffect(() => {
    // by default use "Fit to page" when image selection changes
    const selections = selectedLabels.length
      ? annotations.filter(el => selectedLabels.includes(el.id))
      : [];
    onSelect(selections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLabels]);

  return (
    <Hotkeys
      keyName="del,backspace,ctrl+a,cmd+a"
      onKeyUp={(keyName: string) => hotKey[keyName]()}
    >
      <ReactResizeDetector handleWidth handleHeight>
        {({width = 0, height = 0}) => {
          const getScale = () => {
            const scaleX = getScaleFactor(image?.width || 0, width - ZOOM_PAD_HORIZONTAL);
            const scaleY = getScaleFactor(image?.height || 0, height - ZOOM_PAD_VERTICAL);
            return Math.min(scaleX, scaleY);
          };
          const onScaleSelect = (newScale: number) => {
            if (newScale === NOT_SCALE_TO_FIT) {
              setScaleToFitFactor(getScale());
            } else {
              const scaleTo = Math.min(
                Math.max(newScale, MIN_SCALE_FACTOR),
                MAX_SCALE_FACTOR
              );
              setImageLayer({
                scale: scaleTo,
                x: imageLayer.x + (width * imageLayer.scale - width * scaleTo) / 2,
                y: imageLayer.y + (height * imageLayer.scale - height * scaleTo) / 2,
              });
              setScaleToFitFactor(NOT_SCALE_TO_FIT);
            }
          };

          let scaleValue = 1;
          // Use scale to fit
          if (scaleToFitFactor !== NOT_SCALE_TO_FIT) {
            const scaleFactor = getScale();
            imageLayer.scale !== scaleFactor &&
              setImageLayer({
                scale: scaleFactor,
                x: (width - width * scaleFactor) / 2,
                y: (height - height * scaleFactor) / 2,
              });
            scaleValue = NOT_SCALE_TO_FIT;
          } else {
            scaleValue = scale();
          }
          return (
            <Paper className="detection-view__main" elevation={0}>
              <Toolbar className="detection-view__main_toolbar" variant="dense">
                <span className="detection-view__main_info_container">
                  <span className="detection-view__main_filename">
                    {image?.file_name || ''}
                  </span>
                  <span className="detection-view__main_info">
                    {image?.height != null && image?.width != null
                      ? `${image.width}x${image.height}`
                      : ''}
                  </span>
                  <span className="detection-view__main_info">
                    {fileSize(image?.size || 0) || ''}
                  </span>
                </span>
                <Box flexGrow={1}></Box>
                <IconButton
                  size="small"
                  disabled={!image || scaleValue === MAX_SCALE_FACTOR}
                  onClick={() => onScaleSelect(imageLayer.scale * SCALE_FACTOR)}
                >
                  <ZoomInIcon />
                </IconButton>
                <span>
                  <FormControl className="detection-view__main_toolbar_scale_dropdown">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={scaleValue}
                      labelWidth={200}
                      defaultValue={scale()}
                      renderValue={val => {
                        return scaleToFitFactor === NOT_SCALE_TO_FIT
                          ? Math.round(Number(val) * 100) + '%'
                          : FIT_TO_PAGE_LABEL;
                      }}
                      onChange={({target}) => onScaleSelect(target.value as number)}
                      variant="standard"
                    >
                      <MenuItem value={NOT_SCALE_TO_FIT}>{FIT_TO_PAGE_LABEL}</MenuItem>
                      <MenuItem value={0.5}>50%</MenuItem>
                      <MenuItem value={0.75}>75%</MenuItem>
                      <MenuItem value={1}>100%</MenuItem>
                      <MenuItem value={1.25}>125%</MenuItem>
                      <MenuItem value={1.5}>150%</MenuItem>
                      <MenuItem value={2}>200%</MenuItem>
                    </Select>
                  </FormControl>
                </span>
                <IconButton
                  size="small"
                  disabled={!image || scaleValue === MIN_SCALE_FACTOR}
                  onClick={() => onScaleSelect(imageLayer.scale / SCALE_FACTOR)}
                >
                  <ZoomOutIcon />
                </IconButton>
              </Toolbar>
              <div>
                <Annotator
                  src={src}
                  lastLabelUsed={lastLabelUsed}
                  width={width}
                  height={height}
                  image={image}
                  boxLabels={annotations}
                  selectedLabels={selectedLabels}
                  onBoxLabelCreated={onBoxLabelCreated}
                  onSelect={setSelectedLabels}
                  onContextMenu={onContextMenu}
                  onChange={onAnnotationChange}
                  onDelete={onDelete}
                  tool={'RECT'}
                />
                <MenuList
                  open={menuOpen}
                  location={contextLocation}
                  onClose={onClose}
                  onSelect={onSelectLabel}
                  items={labels?.map(e => e.label)}
                />
              </div>
            </Paper>
          );
        }}
      </ReactResizeDetector>
    </Hotkeys>
  );
};
