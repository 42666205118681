import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router';
import useSWR from 'swr';
import {toast} from '../../../base-components/StudioToast';
import {useIntl} from 'react-intl';
import {
  preProcessorGet,
  PreProcessorGetUrl,
} from '../../../api/preprocessor/PreProcessorGet';
import {preProcessorUpdate} from '../../../api/preprocessor/PreProcessorUpdate';
import {PreProcessingForm} from './PreProcessingForm';
import {UpdatePreProcessorRequest} from '../../../types/preprocessor/UpdatePreProcessorRequest';
import {getPreProcessorFile} from '../../../api/preprocessor/PreProcessorView';

type ReturnToOptions = 'spaDetails' | undefined;

export const EditPreProcessor = () => {
  const {projectId, preProcessorId} = useParams<{
    projectId: string;
    preProcessorId: string;
  }>();
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const [files, setFiles] = useState<Record<string, string>>({});
  const returnTo = search.get('returnTo') as ReturnToOptions;
  const viewOnly = search.has('viewOnly');
  const applicationId = search.get('applicationId');
  const cacheKey = PreProcessorGetUrl({projectId, preProcessorId});
  const {data: preProcessorGetResult} = useSWR(
    cacheKey,
    () => preProcessorGet({projectId, preProcessorId}),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  useEffect(() => {
    getFiles();

    async function getFiles() {
      const filePaths = preProcessorGetResult?.body?.filePaths ?? [];
      const allFiles: Record<string, string> = {};

      for (const fileName of filePaths) {
        if (projectId && preProcessorId && fileName) {
          const {data: preProcessorViewResult} = await getPreProcessorFile(
            projectId,
            preProcessorId,
            fileName
          );
          const text = await preProcessorViewResult.text();
          allFiles[fileName] = text;
        }
      }

      setFiles(allFiles);
    }
  }, [preProcessorGetResult, projectId, preProcessorId]);

  const intl = useIntl();
  const history = useHistory();

  const returnToPreviousPage = () => {
    if (returnTo === 'spaDetails' && applicationId) {
      history.push(`/project/${projectId}/inference/${applicationId}/details`);
    } else {
      history.push(`/project/${projectId}/upload`);
    }
  };

  const handleSubmit = async (values: UpdatePreProcessorRequest) => {
    try {
      const resp = await preProcessorUpdate(values);
      if (!resp.errors?.length) {
        toast.success(intl.formatMessage({id: 'editPreProcessor.success'}));
        history.push(`/project/${projectId}/upload`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getReturnText = () => {
    switch (returnTo) {
      case 'spaDetails':
        return intl.formatMessage({id: 'form.returnToDetails'});
      default:
        return undefined;
    }
  };

  return (
    <PreProcessingForm
      title={intl.formatMessage({id: 'editPreProcessor.header'})}
      projectId={projectId}
      viewOnly={viewOnly}
      returnText={getReturnText()}
      template={preProcessorGetResult?.body}
      filePaths={files}
      onSubmit={handleSubmit}
      onCancel={returnToPreviousPage}
    />
  );
};
