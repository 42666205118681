import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MarketplaceRow, Marketplaces} from './Marketplaces';
import {toast} from '../../base-components/StudioToast';
import {DeleteIntegrationDialog} from './DeleteIntegrationDialog';
import {useIntl} from 'react-intl';
import {RootState} from '../../store';
import {
  fetchEditIntegrationForm,
  fetchIntegrationList,
  fetchIntegrationTypes,
  integrationDelete,
  setIsChooseModalOpen,
  setIsEditModalOpen,
  setCreateModalPurpose,
} from '../../store/marketplace/configure';
import {
  Integration,
  IntegrationPurpose,
} from '../../types/integrations/IntegrationResponse';
import {IntegrationType} from '../../types/integrations/IntegrationTypesResponse';
import './ConfigureMarketplace.scss';

const MARKETPLACES: Array<{labelId: string; purpose: IntegrationPurpose}> = [
  {
    labelId: 'marketplaceConfig.datasetMarketplace',
    purpose: 'DATASET_STORAGE',
  },
  {
    labelId: 'marketplaceConfig.modelMarketplace',
    purpose: 'MODEL_STORAGE',
  },
  {
    labelId: 'marketplaceConfig.ispMarketplace',
    purpose: 'ISP_STORAGE',
  },
  {
    labelId: 'marketplaceConfig.ppMarketplace',
    purpose: 'PP_STORAGE',
  },
  {
    labelId: 'marketplaceConfig.solutionMarketplace',
    purpose: 'SOLUTION_STORAGE',
  },
];

export const ConfigureMarketplace = () => {
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [targetedRow, setTargetedRow] = useState<MarketplaceRow | null>(null);
  const intl = useIntl();
  const dispatch = useDispatch();
  const integrationsList = useSelector(
    (state: RootState) => state.configureMarketplace.list
  );
  const integrationTypes = useSelector(
    (state: RootState) => state.configureMarketplace.integrationTypes
  );
  const deleteResponse = useSelector(
    (state: RootState) => state.configureMarketplace.deleteResponse
  );

  const prevDeleteResponse = useRef(deleteResponse);

  useEffect(() => {
    if (targetedRow && deleteResponse && deleteResponse !== prevDeleteResponse.current) {
      toast.success(`Successfully deleted marketplace: ${targetedRow?.name}`);
      prevDeleteResponse.current = deleteResponse;
      setIsDeleteDialogVisible(false);
    }
  }, [deleteResponse, targetedRow]);

  useEffect(() => {
    dispatch(fetchIntegrationList());
    dispatch(fetchIntegrationTypes());
  }, [dispatch]);

  const handleMenuDeleteClick = (row: MarketplaceRow) => {
    setTargetedRow(row);
    setIsDeleteDialogVisible(true);
  };

  const data =
    integrationsList &&
    MARKETPLACES.map(marketplace => {
      const integrationDetails: Partial<Integration> =
        integrationsList.find(
          integration => integration.purpose === marketplace.purpose
        ) || {};

      const typeDetails: Partial<IntegrationType> =
        integrationTypes.find(type => type.key === integrationDetails.type) || {};

      const row: MarketplaceRow = {
        id: integrationDetails.id,
        marketplaceType: intl.formatMessage({id: marketplace.labelId}),
        name: integrationDetails.name,
        complete: integrationDetails.complete,
        storageType: typeDetails.name,
        purpose: marketplace.purpose,
      };
      return row;
    });

  return (
    <div className="integrations-tab">
      <Marketplaces
        className="integrations-tab__table"
        data={data}
        onMenuEditClick={({id, name}) => {
          if (id && name) {
            dispatch(fetchEditIntegrationForm({id, name}));
            dispatch(setIsEditModalOpen(true));
          }
        }}
        onMenuDeleteClick={handleMenuDeleteClick}
        onCreateClick={row => {
          dispatch(setCreateModalPurpose(row.purpose));
          dispatch(setIsChooseModalOpen(true));
        }}
      />
      <DeleteIntegrationDialog
        isOpen={isDeleteDialogVisible}
        onDeleteClick={row => row.id && dispatch(integrationDelete(row.id))}
        onCancelClick={() => setIsDeleteDialogVisible(false)}
        row={targetedRow}
      />
    </div>
  );
};
