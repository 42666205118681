import PropTypes from 'prop-types';

export const PromptProps = PropTypes.arrayOf(
  PropTypes.shape({
    '@class': PropTypes.string,
    disabled: PropTypes.bool,
    key: PropTypes.string.isRequired,
    mandatory: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })
);
