import React, {Component} from 'react';
import PropTypes from 'prop-types';

class StopCircleIcon extends Component {
  render() {
    return (
      <div className={this.props.className} style={{display: 'flex'}}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_716_47295)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16H16V8H8V16ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_716_47295">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    );
  }
}

StopCircleIcon.propTypes = {
  className: PropTypes.string,
};

export default StopCircleIcon;
