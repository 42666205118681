import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import {StudioPopover} from '../StudioPopover';
import './StudioCheckbox.scss';
import {StudioTooltip} from '../StudioTooltip';

interface StudioCheckboxProps extends CheckboxProps {
  name: string;
  label?: string;
  tooltip?: string;
  fixedTooltip?: boolean;
  nativeTooltipTitle?: string;
  nativeTooltip?: boolean;
  helperText?: string;
  'data-testid'?: string;
}

export const StudioCheckbox = ({
  className,
  label,
  disabled = false,
  tooltip,
  fixedTooltip = false,
  nativeTooltipTitle,
  nativeTooltip = false,
  helperText,
  ...rest
}: StudioCheckboxProps) => {
  return (
    <FormGroup
      className={cn('studio-checkbox', className)}
      id={rest.id || rest.name || rest['data-testid'] || 'studio-checkbox'}
    >
      {nativeTooltip ? (
        <StudioTooltip title={nativeTooltipTitle} placement="top">
          <FormControlLabel
            label={label}
            disabled={disabled}
            control={<Checkbox {...rest} />}
          />
        </StudioTooltip>
      ) : (
        <FormControlLabel
          label={label}
          disabled={disabled}
          control={<Checkbox {...rest} />}
        />
      )}
      {(tooltip || fixedTooltip) && (
        <StudioPopover
          infoIconClass={cn(
            'studio-checkbox__tooltip',
            fixedTooltip && !tooltip && 'studio-checkbox__tooltip--hidden'
          )}
          infoMessage={tooltip}
        />
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormGroup>
  );
};

StudioCheckbox.defaultProps = {
  className: null,
  disabled: false,
  fixedTooltip: false,
};
