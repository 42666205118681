import ls from 'local-storage';
import {mutate} from 'swr';
import {preProcessorDelete} from '../../api/preprocessor/PreProcessorDelete';
import {preProcessorGet} from '../../api/preprocessor/PreProcessorGet';
import {PreProcessorListUrl} from '../../api/preprocessor/PreProcessorList';
import {preProcessorNew} from '../../api/preprocessor/PreProcessorNew';
import {preProcessorUpdate} from '../../api/preprocessor/PreProcessorUpdate';
import {toast} from '../../base-components/StudioToast';
import {transformToValuesArr} from '../../components/ISPForm/util';
import {LS_KEY} from '../../config/constants';
import ERROR from '../../config/error';
// TODO: need to verify why its using the project update
import {stageFetch} from '../stages';
// ACTIONS
export const ISP_PENDING = 'isp/pending';
export const ISP_SUCCESS = 'isp/success';
export const ISP_ERROR = 'isp/error';
export const ISP_DELETED = 'isp/deleted';

// ACTION CREATORS
export const ispPending = payload => ({
  type: ISP_PENDING,
  payload,
});

export const ispSuccess = payload => ({
  type: ISP_SUCCESS,
  payload,
});

export const ispError = payload => ({
  type: ISP_ERROR,
  payload,
});

export const ispGetTemplate = params => {
  return dispatch => {
    dispatch(ispPending());
    return preProcessorNew({
      executionContainer: 'IN_GSP',
      transformationType: 'ISP',
      applicationType: 'OPEN_VX_APP',
      ...params,
    })
      .then(data =>
        dispatch(
          ispSuccess({
            template: data.body,
            colorSpaceOptions: transformToValuesArr(data.body.input_color_space_options),
          })
        )
      )
      .catch(error => handleError(dispatch, ERROR.ISP_SERVER_ERROR(), error));
  };
};

export const ispFetch = (ispId, projectId) => {
  return dispatch => {
    dispatch(ispPending());
    return preProcessorGet({preProcessorId: ispId, projectId})
      .then(res => dispatch(ispSuccess({...res, isNew: false})))
      .catch(error => handleError(dispatch, ERROR.ISP_SERVER_ERROR(), error));
  };
};

// Used for updates in-mem data
export const ispUpdateLocal = (data, isNew = false) => {
  isNew && ls(LS_KEY.ISP, data.body);
  return dispatch => dispatch(ispSuccess({...data, isNew}));
};

export const ispUpdate = transformation => {
  return dispatch => {
    dispatch(ispPending());
    return preProcessorUpdate(transformation)
      .then(res => {
        if (!res.errors) {
          mutate(PreProcessorListUrl({projectId: transformation.projectId}));
          ls.remove(LS_KEY.ISP);
          return dispatch(ispSuccess(res));
        } else {
          dispatch(ispError(ERROR.ISP_UPDATE_ERROR(transformation.name)));
        }
        return res;
      })
      .catch(error =>
        handleError(
          dispatch,
          ERROR.ISP_UPDATE_ERROR(transformation.name, error.response.statusText),
          error
        )
      );
  };
};

export const ispDelete = (ispId, projectId) => {
  return dispatch =>
    preProcessorDelete({preProcessorId: ispId, projectId})
      .then(res => {
        if (!res.errors) {
          toast.success('ISP successfully deleted');
          dispatch(stageFetch(projectId));
          return dispatch(ispSuccess(res));
        }
      })
      .catch(error => handleError(dispatch, ERROR.ISP_DELETE_ERROR(), error));
};

function handleError(dispatch, msg, error) {
  toast.error(msg);
  dispatch(ispError(error));
}
