import React, {useContext} from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import {Divider, DialogContent, DialogTitle} from '@material-ui/core';
import PropTypes from 'prop-types';
import {StageContext} from '../Provider';
import {FormInput} from './FormInput';
import './DetailForm.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" timeout={750} ref={ref} {...props} />;
});

export const DetailForm = ({data}) => {
  const {setOpenDetails, openDetails} = useContext(StageContext);

  return (
    <Dialog
      className="flowbuilder-form"
      open={openDetails}
      TransitionComponent={Transition}
      onClose={() => setOpenDetails(false)}
    >
      <DialogTitle className="flowbuilder-form__title" f>
        {data?.module_category_label}
        <Divider />
      </DialogTitle>
      <DialogContent className="flowbuilder-form__content">
        {openDetails &&
          data?.modules &&
          data.modules.map((item, index) => (
            <ExpansionPanel
              square
              elevation={0}
              key={index}
              className="flowbuilder-form__fields"
              defaultExpanded={item.module_enabled}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={item.module_name}
                id={item.module_name}
              >
                <Typography>{item.module_display_name}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="flowbuilder-form__details">
                <FormInput data={item.module_parameters} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
      </DialogContent>
      <Divider />
    </Dialog>
  );
};

DetailForm.propTypes = {
  data: PropTypes.array,
};

DetailForm.defaultProps = {
  data: [],
};
