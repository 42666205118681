import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  AnnotationDataType,
  AnnotationType,
  DataPrepareType,
} from '../../types/dataset/DatasetGetAnnotationsResponse';
import {FileItem} from '../../components/DataPrepare/FileViewer/FileViewer';
import axios from 'axios';
import URL_CONST, {DATASET_OP} from '../../config/url';
import {
  DataPreparationContainer,
  DataPreparationOrigin,
} from '../../types/dataset/DataPreparationContainer';
import {DatasetLabelingStartResponse} from '../../types/dataset/DatasetLabelingStartResponse';
import {getOriginUrl} from '../../components/DataPrepare/helpers';
import {DatasetPreparationResumeResponse} from '../../types/dataset/DatasetPreparationResumeResponse';
import {DatasetMergeRevertResponse} from '../../types/dataset/DatasetMergeRevertResponse';

export enum DataPrepareStep {
  SELECT_PREPARE_TYPE,
  DEPLOYMENT_DATA_REVIEW,
  DATA_UPLOAD,
  DATA_REVIEW,
  LABEL_UPLOAD,
  LABEL_REVIEW,
  SELECT_LABELLING_TYPE,
  CLASSIFICATION_PREPARE,
  DETECTION_PREPARE,
}

export type DataPrepareUploadProgress = {
  progress: number;
  total: string;
};

type DataPrepareStatus = 'LOADING' | 'SUBMITTING' | 'UPLOADING' | 'IDLE';

export const originToContainerMap: Record<
  DataPreparationOrigin,
  'datasetRelabelingContainer' | 'ddaPreparationContainer' | 'rawPreparationContainer'
> = {
  dataset: 'datasetRelabelingContainer',
  deployment: 'ddaPreparationContainer',
  raw: 'rawPreparationContainer',
};

export type DataPrepareState = {
  status: DataPrepareStatus;
  step: DataPrepareStep;
  name: string | null;
  description: string | null;
  type: DataPrepareType | null;
  uploadedFiles: FileItem[];
  uploadProgress: DataPrepareUploadProgress | null;
  annotationData: AnnotationDataType | null;
  isAddMoreDialogOpen: boolean;
  isAutoLabel: boolean;
  containers: {
    datasetRelabelingContainer: DataPreparationContainer | null;
    ddaPreparationContainer: DataPreparationContainer | null;
    rawPreparationContainer: DataPreparationContainer | null;
  };
};

const initialState: DataPrepareState = {
  status: 'LOADING',
  step: DataPrepareStep.SELECT_PREPARE_TYPE,
  name: null,
  description: null,
  type: null,
  uploadedFiles: [],
  uploadProgress: null,
  annotationData: null,
  isAddMoreDialogOpen: false,
  isAutoLabel: false,
  containers: {
    datasetRelabelingContainer: null,
    ddaPreparationContainer: null,
    rawPreparationContainer: null,
  },
};

export const loadAnnotations = createAsyncThunk(
  'dataset/prepare/load',
  async (args: {projectId: string; origin: DataPreparationOrigin}) => {
    const {projectId, origin} = args;
    const url = getOriginUrl(origin)(projectId, DATASET_OP.GET_ANNOTATIONS);
    const {data} = await axios.get<AnnotationDataType>(url);
    return data;
  }
);

export const saveAnnotations = createAsyncThunk(
  'dataset/prepare/save',
  async (args: {
    resourceId: string;
    projectId: string;
    updatedAnnotations: AnnotationDataType;
  }) => {
    const {resourceId, projectId, updatedAnnotations} = args;
    await axios.post(
      URL_CONST.DATASET(resourceId, DATASET_OP.SAVE_ANNOTATIONS),
      updatedAnnotations,
      {
        params: {
          projectId,
        },
      }
    );
    return updatedAnnotations;
  }
);

export const saveMethodUpdate = createAsyncThunk(
  'dataset/prepare/edit',
  async (args: {resourceId: string; projectId: string; answers: any}) => {
    const {resourceId, projectId, answers} = args;
    return await axios.post(URL_CONST.DATASET_PREPARE_EDIT, {
      projectId,
      resourceId,
      answers,
    });
  }
);

export const mergeAnnotations = createAsyncThunk(
  'dataset/prepare/merge',
  async (args: {
    resourceId: string;
    annotations: AnnotationType[];
    images: {id: number}[];
  }) => {
    const {resourceId, annotations, images} = args;
    return await axios.post(URL_CONST.DATASET(resourceId, DATASET_OP.MERGE_ANNOTATIONS), {
      annotations,
      images,
    });
  }
);

export const mergeCategories = createAsyncThunk(
  'dataset/prepare/merge_categories',
  async (args: {resourceId: string; updatedAnnotations: AnnotationDataType}) => {
    const {resourceId, updatedAnnotations} = args;
    await axios.post(
      URL_CONST.DATASET(resourceId, DATASET_OP.MERGE_CATEGORIES),
      updatedAnnotations
    );
    return updatedAnnotations;
  }
);

export const submitAnnotations = createAsyncThunk(
  'dataset/prepare/submit',
  async (args: {
    projectId: string;
    type: DataPrepareType;
    annotationData: AnnotationDataType;
  }) => {
    const {projectId, annotationData, type} = args;
    return await axios.post(
      URL_CONST.DATASET(projectId, DATASET_OP.SET_ANNOTATIONS),
      annotationData,
      {
        params: {
          loader: type === DataPrepareType.CLASSIFICATION ? 'ImageFolder' : 'COCO',
        },
      }
    );
  }
);

export const datasetLabelingStart = createAsyncThunk(
  'dataset/labeling/start',
  async (args: {projectId: string}) => {
    const {projectId} = args;
    const {data} = await axios.get<DatasetLabelingStartResponse>(
      URL_CONST.DATASET(projectId, DATASET_OP.LABELING_START)
    );
    return data;
  }
);

export const datasetLabelingApply = createAsyncThunk(
  'dataset/labeling/apply',
  async (args: {projectId: string; type: DataPrepareType}) => {
    const {projectId, type} = args;
    await axios.post(
      URL_CONST.DATASET(projectId, DATASET_OP.LABELING_APPLY),
      {},
      {
        params: {
          loader: type === DataPrepareType.CLASSIFICATION ? 'ImageFolder' : 'COCO',
        },
      }
    );
  }
);

export const resumeDataPrep = createAsyncThunk(
  'dataset/prepare/resume',
  async (args: {projectId: string; context: DataPreparationOrigin}) => {
    const {projectId, context} = args;
    const {data} = await axios.get<DatasetPreparationResumeResponse>(
      URL_CONST.DATASET_PREP_RESUME(projectId, context)
    );

    return {container: data.body, context};
  }
);

export const revertDatasetMerge = createAsyncThunk(
  'dataset/prepare/revert-merge',
  async (args: {projectId: string}) => {
    const {projectId} = args;
    const {data} = await axios.get<DatasetMergeRevertResponse>(
      URL_CONST.DATASET_MERGE_REVERT(projectId)
    );
    if (data.errors?.length) {
      throw new Error('Error during dataset merge revert.');
    }
    return data.body;
  }
);

const dataPrepareSlice = createSlice({
  name: 'dataset/prepare',
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<DataPrepareStatus>) {
      state.status = action.payload;
    },
    setStep(state, action: PayloadAction<DataPrepareStep>) {
      state.step = action.payload;
    },
    setName(state, action: PayloadAction<string | null>) {
      state.name = action.payload;
    },
    setDescription(state, action: PayloadAction<string | null>) {
      state.description = action.payload;
    },
    setType(state, action: PayloadAction<DataPrepareType | null>) {
      state.type = action.payload;
    },
    setUploadProgress(state, action: PayloadAction<DataPrepareUploadProgress | null>) {
      state.uploadProgress = action.payload;
    },
    setUploadedFiles(state, action: PayloadAction<FileItem[]>) {
      state.uploadedFiles = action.payload;
    },
    setAnnotationData(state, action: PayloadAction<AnnotationDataType | null>) {
      state.annotationData = action.payload;
    },
    setIsAddMoreDialogOpen(state, action: PayloadAction<boolean>) {
      state.isAddMoreDialogOpen = action.payload;
    },
    setIsAutoLabel(state, action: PayloadAction<boolean>) {
      state.isAutoLabel = action.payload;
    },
    setDataPrep(
      state,
      action: PayloadAction<{
        container: DataPreparationContainer;
        context: DataPreparationOrigin;
      }>
    ) {
      const key = originToContainerMap[action.payload.context];
      state.containers[key] = action.payload.container;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAnnotations.fulfilled, (state, action) => {
        state.annotationData = action.payload;
        state.isAutoLabel = action.payload.info?.isAutoLabel || false;
      })
      .addCase(saveAnnotations.fulfilled, (state, action) => {
        state.annotationData = action.payload;
      })
      .addCase(mergeCategories.fulfilled, (state, action) => {
        state.annotationData = action.payload;
      })
      .addCase(resumeDataPrep.fulfilled, (state, action) => {
        if (action.payload.container) {
          const key = originToContainerMap[action.payload.context];
          state.containers[key] = action.payload.container;
        }
      })
      .addCase(revertDatasetMerge.fulfilled, (state, action) => {
        if (action.payload) {
          state.containers['ddaPreparationContainer'] = action.payload;
          state.containers['rawPreparationContainer'] = null;
        }
      });
  },
});

export const {
  actions: dataPrepareActions,
  reducer: dataPrepareReducer,
} = dataPrepareSlice;
