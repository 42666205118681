import {BoxProps} from '@material-ui/core';
import React from 'react';
import './ImageCard.scss';
import {Stage, Layer, Line, Group} from 'react-konva';

export type PoseDetectionOverlayProps = BoxProps & {
  width: number;
  height: number;
  points: number[][][];
};
export const PoseDetectionOverlay = ({
  width,
  height,
  points,
}: PoseDetectionOverlayProps) => {
  return (
    <Stage
      width={width}
      height={height}
      style={{position: 'absolute', top: 0, left: 0, width: width, height: height}}
      className="canvas-overlay-container"
    >
      <Layer>
        {points.map(p => {
          return (
            <Group>
              <Line
                x={0}
                y={0}
                points={[
                  p[15][1] * width,
                  p[15][0] * height,
                  p[13][1] * width,
                  p[13][0] * height,
                  p[11][1] * width,
                  p[11][0] * height,
                  p[12][1] * width,
                  p[12][0] * height,
                  p[14][1] * width,
                  p[14][0] * height,
                  p[16][1] * width,
                  p[16][0] * height,
                ]}
                stroke="yellow"
                strokeWidth={1}
              />
              <Line
                x={0}
                y={0}
                points={[
                  p[10][1] * width,
                  p[10][0] * height,
                  p[8][1] * width,
                  p[8][0] * height,
                  p[6][1] * width,
                  p[6][0] * height,
                  p[5][1] * width,
                  p[5][0] * height,
                  p[7][1] * width,
                  p[7][0] * height,
                  p[9][1] * width,
                  p[9][0] * height,
                ]}
                stroke="yellow"
                strokeWidth={1}
              />
              <Line
                x={0}
                y={0}
                points={[
                  p[4][1] * width,
                  p[4][0] * height,
                  p[2][1] * width,
                  p[2][0] * height,
                  p[0][1] * width,
                  p[0][0] * height,
                  p[1][1] * width,
                  p[1][0] * height,
                  p[3][1] * width,
                  p[3][0] * height,
                ]}
                stroke="yellow"
                strokeWidth={1}
              />
            </Group>
          );
        })}
      </Layer>
    </Stage>
  );
};
