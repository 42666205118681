import fileSize from 'filesize';
import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Image from 'material-ui-image';
import cn from 'classnames';
import {FormattedMessage} from 'react-intl';
import URL from '../../config/url';
import HumanReadableTime from '../../base-components/StudioHumanReadableTime/HumanReadableTime';
import {DataSplitType} from '../../types/dataset/Dataset';
import {MarketplaceFilters} from '../../types/marketplace/MarketplaceFilesRequest';
import {MarketplaceResult} from '../../types/marketplace/MarketplaceFilesResponse';
import {filterDefinitions} from './filterDefinitions';
import {MarketplaceTagList} from './MarketplaceExplorer/MarketplaceGridItem';
import {LabelDistribution} from '../../types/dataset/Dataset';
import {MarketplaceItemPlaceholder} from './MarketplaceExplorer/MarketplaceGridItem';
import {useCompatibleDatasetsMap} from './MarketplaceExplorer/useCompatibleDatasetsMap';
import {modelSourceMap} from '../../types/model/Model';
import {modelSourceToString} from '../../types/model/ModelSource';
import {modelPurposeToString} from '../../types/model/ModelPurpose';
import {transformMessageToIntlKey} from './../../utils/language';
import {Statistics} from '../../types/model/Statistics';

const THUMBNAIL_GRID_COLUMNS = 3;
const THUMBNAIL_GRID_ROWS = 3;
const THUMBNAIL_HEIGHT = 64;
const THUMBNAIL_WIDTH = 64;

export type MetadataDefinition = {
  label: string;
  render: (result: MarketplaceResult) => React.ReactNode;
};

export type MetadataMap = Record<string, MetadataDefinition>;

export const metadataMap: MetadataMap = {
  displayName: {
    label: 'marketplaceResult.displayName',
    render: result => result.metadata.displayName,
  },
  description: {
    label: 'marketplaceResult.description',
    render: result => result.metadata.description,
  },
  purpose: {
    label: 'marketplaceResult.purpose',
    render: result => getMetadataDisplayName('purpose', result.metadata.purpose),
  },
  provider: {
    label: 'marketplaceResult.provider',
    render: result => result.metadata.provider,
  },
  creationDateTime: {
    label: 'marketplaceResult.creationDateTime',
    render: result => <HumanReadableTime date={result.metadata.creationDateTime} />,
  },
  studioVersion: {
    label: 'marketplaceResult.studioVersion',
    render: result => result.metadata.studioVersion,
  },
  version: {
    label: 'marketplaceResult.version',
    render: result => result.metadata.version,
  },
  size: {
    label: 'marketplaceResult.size',
    render: result => fileSize(result.size),
  },
  tags: {
    label: 'marketplaceResult.tags',
    render: result =>
      result.metadata.tags != null && result.metadata.tags.length > 0 ? (
        <MarketplaceTagList tags={result.metadata.tags} />
      ) : null,
  },

  // Dataset
  colorSpace: {
    label: 'marketplaceResult.colorSpace',
    render: result => getMetadataDisplayName('colorSpace', result.metadata.colorSpace),
  },
  dataLoader: {
    label: 'marketplaceResult.dataLoader',
    render: result => getMetadataDisplayName('dataLoader', result.metadata.dataLoader),
  },
  classCount: {
    label: 'marketplaceResult.classCount',
    render: result => getClassCount(result.metadata.labelDistribution),
  },
  dataSplit: {
    label: 'marketplaceResult.dataSplit',
    render: result =>
      result.metadata.labelDistribution && (
        <DataSplitCounts labelDistribution={result.metadata.labelDistribution} />
      ),
  },
  labels: {
    label: 'marketplaceResult.labels',
    render: result => {
      if (result.metadata.labelDistribution) {
        const distributions = Object.values(result.metadata.labelDistribution);
        if (distributions.length && distributions[0]?.labelNames) {
          return <MarketplaceTagList tags={distributions[0].labelNames} />;
        }
      }
    },
  },
  solutionWithoutDatasetPreview: {
    label: 'marketplaceResult.solutionWithoutDatasetPreview',
    render: () => {
      const height = THUMBNAIL_GRID_ROWS * THUMBNAIL_HEIGHT;
      const width = THUMBNAIL_GRID_COLUMNS * THUMBNAIL_WIDTH;

      return (
        <div
          style={{
            width,
            height,
          }}
          data-testid="marketplace-preview-result-solution-without-dataset"
        >
          <MarketplaceItemPlaceholder
            purpose="DATASET_STORAGE"
            style={{
              width,
              height,
            }}
          />
        </div>
      );
    },
  },
  datasetPreview: {
    label: 'marketplaceResult.datasetPreview',
    render: result => {
      const height = THUMBNAIL_GRID_ROWS * THUMBNAIL_HEIGHT;
      const width = THUMBNAIL_GRID_COLUMNS * THUMBNAIL_WIDTH;
      return (
        <div
          style={{
            width,
            height,
          }}
        >
          <Image
            alt={result.displayName}
            errorIcon={
              <MarketplaceItemPlaceholder
                purpose="DATASET_STORAGE"
                style={{
                  width,
                  height,
                }}
              />
            }
            color="transparent"
            imageStyle={{
              width,
              height,
            }}
            src={URL.DATASET_THUMBNAILS_FROM_MARKETPLACE({
              datasetName: result.name,
              marketplaceId: result.integrationId,
              rows: THUMBNAIL_GRID_ROWS,
              columns: THUMBNAIL_GRID_COLUMNS,
              width: THUMBNAIL_WIDTH,
              height: THUMBNAIL_HEIGHT,
            })}
          />
        </div>
      );
    },
  },

  // Model
  type: {
    label: 'marketplaceResult.type',
    render: result => getMetadataDisplayName('type', result.metadata.type),
  },
  baseModel: {
    label: 'marketplaceResult.baseModel',
    render: result => getMetadataDisplayName('baseModel', result.metadata.baseModel),
  },
  yoloType: {
    label: 'marketplaceResult.modelVariant',
    render: result =>
      result?.metadata?.yoloType ? (
        <FormattedMessage
          tagName="span"
          id={transformMessageToIntlKey(result.metadata.yoloType, 'modelVariant')}
        />
      ) : null,
  },
  modelHelperFile: {
    label: 'marketplaceResult.modelHelperFile',
    render: result => result.metadata.modelHelperFile,
  },
  modelCompatibleDataset: {
    label: 'marketplaceResult.dataset',
    render: result =>
      result.metadata.compatibleDatasets ? (
        <CompatibleDatasetInfo
          signatures={result.metadata.compatibleDatasets}
          field="name"
        />
      ) : null,
  },
  modelCompatibleDatasetImages: {
    label: 'marketplaceResult.datasetImages',
    render: result =>
      result.metadata.compatibleDatasets ? (
        <CompatibleDatasetInfo
          signatures={result.metadata.compatibleDatasets}
          field="images"
        />
      ) : null,
  },
  modelCompatibleDatasetSplit: {
    label: 'marketplaceResult.dataSplit',
    render: result =>
      result.metadata.compatibleDatasets ? (
        <CompatibleDatasetInfo
          signatures={result.metadata.compatibleDatasets}
          field="dataSplit"
        />
      ) : null,
  },
  modelCompatibleDatasetTags: {
    label: 'marketplaceResult.datasetTags',
    render: result =>
      result.metadata.compatibleDatasets ? (
        <CompatibleDatasetInfo
          signatures={result.metadata.compatibleDatasets}
          field="tags"
        />
      ) : null,
  },
  modelClassCount: {
    label: 'marketplaceResult.classCount',
    render: result => result.metadata.numberOfClasses,
  },
  modelTL: {
    label: 'marketplaceResult.tl',
    render: result => (result.metadata.modelSource === 'TL_MODEL' ? 'Yes' : 'No'),
  },
  modelSource: {
    label: 'marketplaceResult.source',
    render: result =>
      result.metadata.modelSource ? modelSourceMap[result.metadata.modelSource] : null,
  },
  modelPreview: {
    label: 'marketplaceResult.modelPreview',
    render: () => (
      <MarketplaceItemPlaceholder
        purpose="MODEL_STORAGE"
        style={{
          width: THUMBNAIL_GRID_COLUMNS * THUMBNAIL_WIDTH,
          height: THUMBNAIL_GRID_ROWS * THUMBNAIL_HEIGHT,
        }}
      />
    ),
  },
  // Pre Processor
  ispPreview: {
    label: 'marketplaceResult.ispPreview',
    render: () => (
      <MarketplaceItemPlaceholder
        purpose="ISP_STORAGE"
        style={{
          width: THUMBNAIL_GRID_COLUMNS * THUMBNAIL_WIDTH,
          height: THUMBNAIL_GRID_ROWS * THUMBNAIL_HEIGHT,
        }}
      />
    ),
  },
  transformationType: {
    label: 'marketplaceResult.preProcessorType',
    render: result =>
      getMetadataDisplayName(
        'transformationType',
        result.metadata.transformation?.transformationType
      ),
  },
  inputColorSpace: {
    label: 'marketplaceResult.inputColorSpace',
    render: result => {
      const colorSpace =
        result.metadata.transformation?.transformations?.input?.input_color_space;
      const options: Record<string, string> =
        result.metadata.transformation?.input_color_space_options || {};
      return colorSpace ? options[colorSpace] : null;
    },
  },
  outputColorSpace: {
    label: 'marketplaceResult.outputColorSpace',
    render: result => {
      const colorSpace =
        result.metadata.transformation?.transformations?.input?.output_color_space;
      const options: Record<string, string> =
        result.metadata.transformation?.input_color_space_options || {};
      return colorSpace ? options[colorSpace] : null;
    },
  },
  ispModules: {
    label: 'marketplaceResult.ispModules',
    render: result => {
      const modules = result.metadata.transformation?.transformations?.libraries?.[0].modules?.map(
        module => module.module_display_name
      );
      return modules?.length ? <MarketplaceTagList tags={modules} /> : null;
    },
  },
  // Post Processor
  ppPreview: {
    label: 'marketplaceResult.ppPreview',
    render: () => (
      <MarketplaceItemPlaceholder
        purpose="PP_STORAGE"
        style={{
          width: THUMBNAIL_GRID_COLUMNS * THUMBNAIL_WIDTH,
          height: THUMBNAIL_GRID_ROWS * THUMBNAIL_HEIGHT,
        }}
      />
    ),
  },
  postProcessorType: {
    label: 'marketplaceResult.postProcessorType',
    render: result =>
      getMetadataDisplayName('postProcessorType', result.metadata.postProcessorType),
  },
  // Shared Pre and Post Processor
  applicationType: {
    label: 'marketplaceResult.appType',
    render: result =>
      getMetadataDisplayName(
        'applicationType',
        result.metadata.transformation
          ? result.metadata.transformation?.script?.applicationType
          : result.metadata.applicationType
      ),
  },
  executionContainer: {
    label: 'marketplaceResult.targetDevice',
    render: result =>
      getMetadataDisplayName(
        'executionContainer',
        result.metadata.transformation
          ? result.metadata.transformation?.script?.executionContainer
          : result.metadata.executionContainer
      ),
  },
  // Solution
  categoryName: {
    label: 'marketplaceResult.category',
    render: result => result.metadata.categoryName,
  },
  solutionData: {
    label: 'marketplaceResult.dataset',
    render: result => result.metadata.dataSet?.name,
  },
  solutionDataClassCount: {
    label: 'marketplaceResult.classCount',
    render: result => getClassCount(result.metadata.dataSet?.labelDistribution),
  },
  solutionDataSplit: {
    label: 'marketplaceResult.dataSplit',
    render: result =>
      result.metadata.dataSet?.labelDistribution && (
        <DataSplitCounts labelDistribution={result.metadata.dataSet.labelDistribution} />
      ),
  },
  solutionDataTags: {
    label: 'marketplaceResult.datasetTags',
    render: result =>
      result.metadata.dataSet?.marketplaceTags != null &&
      result.metadata.dataSet?.marketplaceTags.length > 0 ? (
        <MarketplaceTagList tags={result.metadata.dataSet.marketplaceTags} />
      ) : null,
  },
  solutionModel: {
    label: 'marketplaceResult.model',
    render: result => result.metadata.model?.name,
  },
  solutionPurpose: {
    label: 'marketplaceResult.purpose',
    render: result =>
      result.metadata.model?.modelPurpose
        ? modelPurposeToString(result.metadata.model?.modelPurpose)
        : null,
  },
  solutionModelType: {
    label: 'marketplaceResult.type',
    render: result => getMetadataDisplayName('type', result.metadata.model?.type),
  },
  solutionModelBase: {
    label: 'marketplaceResult.baseModel',
    render: result =>
      getMetadataDisplayName('baseModel', result.metadata.model?.baseModel),
  },
  solutionYoloType: {
    label: 'marketplaceResult.modelVariant',
    render: result =>
      result?.metadata?.model?.yoloType ? (
        <FormattedMessage
          tagName="span"
          id={transformMessageToIntlKey(result.metadata.model.yoloType, 'modelVariant')}
        />
      ) : null,
  },
  solutionModelAccuracy: {
    label: 'testCompare.top1Accuracy',
    render: result =>
      result.metadata.model?.statistics?.FLOAT?.lastTop1Accuracy != null
        ? `${Math.round(result.metadata.model.statistics.FLOAT.lastTop1Accuracy * 100) /
            100}%`
        : null,
  },
  solutionModelMAP: {
    label: 'testCompare.meanAveragePrecision',
    render: result => {
      const getAccuracy = (statistics?: Statistics) =>
        Math.max(statistics?.accuracy || 0, statistics?.lastTop1Accuracy || 0);
      let accuracy = getAccuracy(result.metadata.model?.statistics?.INITIAL);
      if (!accuracy) {
        accuracy = getAccuracy(result.metadata.model?.statistics?.COMPRESSED);
      }
      if (!accuracy) {
        accuracy = getAccuracy(result.metadata.model?.statistics?.FLOAT);
      }
      return accuracy ? `${Math.round(accuracy * 100) / 100}%` : null;
    },
  },
  solutionModelSource: {
    label: 'marketplaceResult.source',
    render: result =>
      result.metadata.model?.modelSource
        ? modelSourceToString(result.metadata.model.modelSource)
        : null,
  },
  solutionModelTags: {
    label: 'marketplaceResult.modelTags',
    render: result =>
      result.metadata.model?.marketplaceTags != null &&
      result.metadata.model?.marketplaceTags.length > 0 ? (
        <MarketplaceTagList tags={result.metadata.model.marketplaceTags} />
      ) : null,
  },
};

/*===== HELPERS =======*/
export const getImageCount = (labelDistribution: LabelDistribution) => {
  const distributionEntries = Object.entries(labelDistribution);
  return distributionEntries.reduce(
    (sum, [_, distribution]) => sum + (distribution?.imagesCount ?? 0),
    0
  );
};

export const getClassCount = (labelDistribution: LabelDistribution | undefined) => {
  const count = (type: DataSplitType) => labelDistribution?.[type]?.labelsCount ?? 0;
  return Math.max(count('UNSPLIT'), count('TRAIN'), count('VALIDATION'), count('TEST'));
};

const CompatibleDatasetInfo = ({
  signatures,
  field,
}: {
  signatures: string[];
  field: 'name' | 'images' | 'dataSplit' | 'tags';
}) => {
  const compatibleDatasetsMap = useCompatibleDatasetsMap(signatures);
  if (compatibleDatasetsMap.size === 0) {
    return null;
  }
  const randomCompatibleDataset = compatibleDatasetsMap.values().next().value; // It was always random
  const name = randomCompatibleDataset.displayName;
  const labelDistribution = randomCompatibleDataset.metadata.labelDistribution;
  const tags = randomCompatibleDataset.metadata.tags;
  if (field === 'name' && name) {
    return <div>{name}</div>;
  } else if (field === 'images' && labelDistribution != null) {
    return <div>{getImageCount(labelDistribution)}</div>;
  } else if (field === 'dataSplit' && labelDistribution != null) {
    return <DataSplitCounts labelDistribution={labelDistribution} />;
  } else if (field === 'tags' && tags && tags?.length > 0) {
    return <MarketplaceTagList tags={tags} />;
  } else {
    return null;
  }
};

const getMetadataDisplayName = (column: keyof MarketplaceFilters, value?: string) => {
  if (value) {
    const definition = filterDefinitions.find(definition => definition.key === column);
    if (definition && definition.type === 'OPTIONS') {
      return definition.options.getValue(value);
    }
  }
};

export const DataSplitCounts = ({
  labelDistribution,
  className,
}: {
  labelDistribution: LabelDistribution;
  className?: string;
}) => {
  const distributionEntries = Object.entries(labelDistribution);
  if (distributionEntries.length) {
    return (
      <TableContainer
        component={Paper}
        className={cn('data-split-counts', className)}
        elevation={0}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              {distributionEntries.map(([splitType]) => (
                <TableCell key={splitType}>{splitType}</TableCell>
              ))}
              <TableCell className="data-split-counts__total">
                <FormattedMessage id="marketplaceResult.dataSplitTotalTitle" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {distributionEntries.map(([_, distribution], index) => (
                <TableCell key={index}>{distribution?.imagesCount}</TableCell>
              ))}
              <TableCell className="data-split-counts__total">
                {distributionEntries.reduce(
                  (sum, [_, distribution]) => sum + (distribution?.imagesCount ?? 0),
                  0
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return null;
};
