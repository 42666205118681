import React, {useState, useMemo} from 'react';

export const FieldWatcherStateContext = React.createContext();
export const FieldWatcherAPIContext = React.createContext();

export const FieldWatcherProvider = ({children}) => {
  const [registeredFields, setRegisteredFields] = useState({});

  const API = useMemo(() => {
    const register = name => {
      setRegisteredFields(registeredFields => ({...registeredFields, [name]: true}));
    };

    const unregister = name => {
      setRegisteredFields(registeredFields => {
        const {[name]: removedKey, ...rest} = registeredFields;
        return rest;
      });
    };

    return {
      register,
      unregister,
    };
  }, []);

  return (
    <FieldWatcherStateContext.Provider value={registeredFields}>
      <FieldWatcherAPIContext.Provider value={API}>
        {children}
      </FieldWatcherAPIContext.Provider>
    </FieldWatcherStateContext.Provider>
  );
};
