import {Component} from 'react';
import PropTypes from 'prop-types';

class BaseStageList extends Component {
  renderList() {
    let stagesCount = this.props.stages.length;
    let previousIsfinished = false;
    let activeStepIndex = 0;
    this.props.stages.forEach((stage, i) => {
      if (stage.active) {
        activeStepIndex = i;
      }
    });
    return this.props.stages.map((stage, i) => {
      const isFirst = i === 0;
      const isLast = i === stagesCount - 1;
      const isActive = i === activeStepIndex;
      let step = this.getStage(stage, isFirst, isLast, previousIsfinished, isActive);
      previousIsfinished = stage.status === 'FINISHED';
      return step;
    });
  }

  getStage(stage, first, last, previousIsfinished, active) {
    return {
      stage,
      first,
      last,
      finished: previousIsfinished,
      active,
    };
  }
}

BaseStageList.propTypes = {
  projectId: PropTypes.string.isRequired,
  stages: PropTypes.array.isRequired,
};

export default BaseStageList;
