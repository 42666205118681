import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {addListener} from '../../../store/statusChecker';
import {setChatConnection, setDeployTask, TASK_STATUS, useDeploy} from '../useDeploy';
import {useDeployTask} from './useDeployTask';
import {TaskInfo} from '../../../types/metadata/TaskInfo';
import {ChatConnection, ChatConnectionStatus, MessageType} from '../../Chat/Chat';
import {TestInitiateRequest} from '../../../api/test/TestInitiate';
import {toast} from '../../../base-components/StudioToast';
import Util from '../../../util';

class WebsocketChatConnection implements ChatConnection {
  private consumeMessage: (m: string, t: MessageType) => void = () => {};
  private consumeStatus: (s: ChatConnectionStatus) => void = () => {};
  private websocket: WebSocket;
  private timer: NodeJS.Timeout;
  private connectionStatus: ChatConnectionStatus = ChatConnectionStatus.CONNECTING;
  private onRegister: (chatConnection: WebsocketChatConnection, message: string) => void;

  constructor(
    url: string,
    onRegister: (chatConnection: WebsocketChatConnection, message: string) => void
  ) {
    this.websocket = new WebSocket(url);
    this.websocket.onmessage = event => this.onMessage(event);
    this.websocket.onerror = event => this.onError(event);
    this.websocket.onopen = () =>
      this.websocket.send(JSON.stringify({id: 'registerDataChannel'}));
    this.timer = setInterval(() => {
      this.websocket.send(
        JSON.stringify({
          id: 'heartbeat',
        })
      );
    }, 10_000);
    this.onRegister = onRegister;
  }

  send(message: string): void {
    if (this.isChatCommunicationOpen()) {
      const data = JSON.stringify({
        id: 'data',
        data: {type: 'message', payload: message},
      });
      this.websocket.send(data);
    } else {
      toast.error(`Can't send in status ${this.connectionStatus}`);
    }
  }

  setMessageConsumer(x: (m: string, t: MessageType) => void): void {
    this.consumeMessage = x;
  }

  setStatusConsumer(x: (s: ChatConnectionStatus) => void): void {
    this.consumeStatus = x;
  }

  updateTaskInfo(x: TaskInfo): void {
    if (
      this.connectionStatus === ChatConnectionStatus.DISCONNECTED ||
      this.connectionStatus === ChatConnectionStatus.ERROR
    ) {
      return;
    }
    if (Util.isRealNumberEqual(x.percentCompleted, 100)) {
      this.setStatus(ChatConnectionStatus.DISCONNECTED);
      x.warnings.forEach(w => {
        this.consumeMessage(`${w.message}\n${w.detailMessage}`, 'SYSTEM');
        this.setStatus(ChatConnectionStatus.ERROR);
      });
      this.close();
    }
  }

  close(): void {
    clearInterval(this.timer);
    if (this.isWebsocketOpen()) {
      this.websocket.send(JSON.stringify({id: 'disconnect'}));
    }
    this.websocket.close();
    this.consumeMessage('Disconnected', 'SYSTEM');
  }

  private isChatCommunicationOpen() {
    return this.connectionStatus === ChatConnectionStatus.ACTIVE;
  }

  private isWebsocketOpen() {
    return this.websocket.readyState === WebSocket.OPEN;
  }

  private setStatus(s: ChatConnectionStatus) {
    if (this.connectionStatus !== s) {
      this.consumeStatus(s);
      this.connectionStatus = s;
    }
  }

  private onMessage(event: MessageEvent) {
    const msg = JSON.parse(event.data);
    if (msg.id === 'acknowledgeDataChannel') {
      this.setStatus(ChatConnectionStatus.CONNECTED);
      this.onRegister(this, msg.sessionId);
      this.consumeMessage('Connected. Waiting for the application to start...', 'SYSTEM');
      return;
    }
    if (msg.id === 'startCommunication') {
      this.consumeMessage('Application started!', 'SYSTEM');
      this.setStatus(ChatConnectionStatus.ACTIVE);
      return;
    }
    if (msg.id === 'stopCommunication') {
      this.setStatus(ChatConnectionStatus.DISCONNECTED);
      this.close();
      return;
    }
    if (msg.id === 'data') {
      if (msg.data.type === 'message') {
        this.consumeMessage(msg.data.payload, 'APPLICATION');
      } else if (msg.data.type === 'error') {
        this.consumeMessage('ERROR: ' + msg.data.payload, 'APPLICATION');
      } else {
        this.consumeMessage('Protocol error: unknown type', 'SYSTEM');
        toast.error(`Unexpected type ${event.data}`);
        this.setStatus(ChatConnectionStatus.ERROR);
        this.close();
      }
      return;
    }
    this.consumeMessage('Protocol error: unknown id', 'SYSTEM');
    toast.error(`Unexpected id ${event.data}`);
    this.setStatus(ChatConnectionStatus.ERROR);
    this.close();
  }

  private onError(event: Event) {
    console.error(event);
    this.consumeMessage('Connection error', 'SYSTEM');
    this.setStatus(ChatConnectionStatus.ERROR);
  }
}

export const useTextTask = (projectId: string) => {
  const reduxDispatch = useDispatch();
  const [{chatConnection}, dispatch] = useDeploy();
  const {startDeploy, terminateDeploy} = useDeployTask(projectId);

  const monitorStatus = useCallback(
    onMonitoringResponse => {
      reduxDispatch(
        addListener('GSP_DEPLOY', (status: TaskInfo) => {
          dispatch(setDeployTask({monitoringResponse: status}));
          onMonitoringResponse?.(status);
        })
      );
    },
    [dispatch, reduxDispatch]
  );

  const start = ({
    projectId,
    applicationId,
    nodeId,
    name,
    description,
    signalingServerUrl,
  }: {
    projectId: string;
    applicationId: string;
    nodeId: string;
    name: string;
    description: string;
    signalingServerUrl: string;
  }) => {
    try {
      const websocketChatConnection = new WebsocketChatConnection(
        signalingServerUrl,
        async (chatConnection, streamingSessionId) => {
          try {
            const config = {
              name,
              description,
              projectId,
              applicationId,
              nodeId,
              deploymentType: 'INFERENCE',
              deploymentInput: {
                inputSourceType: 'TEXT_INPUT',
              },
              parameters: {
                streamingSessionId,
              },
            } as TestInitiateRequest;
            dispatch(setDeployTask({status: TASK_STATUS.IN_PROGRESS}));
            await startDeploy(config);
            monitorStatus((taskInformation: TaskInfo) =>
              chatConnection.updateTaskInfo(taskInformation)
            );
          } catch (error) {
            const errorMessage = (error as Error).message;

            toast.error(errorMessage);
            terminateDeploy();
            dispatch(setChatConnection(undefined));
          }
        }
      );

      dispatch(setChatConnection(websocketChatConnection));
    } catch (error) {
      const errorMessage = (error as Error).message;

      toast.error(errorMessage);
    }
  };

  const stop = () => {
    terminateDeploy();
    chatConnection?.close();
    dispatch(setChatConnection(undefined));
  };

  return [start, stop] as const;
};
