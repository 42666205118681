import React from 'react';
import './StagePanel.scss';
import BaseStage from '../Base/BaseStage';
import PropTypes from 'prop-types';
import StageAnimation from '../StageAnimation/StageAnimation';
import StageStep from '../StageStep/StageStep';

class StagePanel extends BaseStage {
  constructor(props) {
    super(props);

    this.activatePanel = this.activatePanel.bind(this);
  }

  render() {
    return (
      <div
        className={'stage-panel ' + this.getPanelStyle()}
        id={this.props.stage.type + '-panel'}
      >
        {this.getOverlay()}
        <div className="stage-panel-filler" />
        <div
          className={'stage-panel-content' + this.getContentStyle()}
          id={this.props.stage.type + '-panel-content'}
          data-testid={`${this.props.stage.type}-panel-content`}
        >
          {this.getSharedContent()}
          {this.getLockedContent()}
          {this.getInactiveContent()}
          {this.getActiveContent()}
          {this.getInProgressContent()}
          {this.getFinishedContent()}
        </div>
        <div className={'line' + this.getLineStyle()} />
        <div
          className={'stage-panel-outline' + this.getOutlineStyle()}
          id={this.props.stage.type + '-panel-outline'}
        />
      </div>
    );
  }

  getPanelStyle() {
    return '';
  }

  getLineStyle() {
    return this.props.first ? ' line-first' : this.props.last ? ' line-last' : '';
  }

  getSharedContent() {
    return null;
  }

  getInactiveContent() {
    return (
      <div className="inactive-content">
        <div className="inactive-container">
          <span className="lock-reason">{this.props.stage.displayText}</span>
        </div>
      </div>
    );
  }

  getActiveContent() {
    return (
      <div className="active-content">
        <div className="active-container">
          <span className="lock-reason">{this.props.stage.displayText}</span>
        </div>
      </div>
    );
  }

  getLockedContent() {
    return (
      <div className="locked-content">
        <div className="lock-icon"></div>
        <p className="lock-reason">{this.props.stage.lockReason}</p>
      </div>
    );
  }

  getInProgressContent() {
    return (
      <div className="in-progress-content">
        <span className="lock-reason">{this.props.stage.displayText}</span>
      </div>
    );
  }

  getFinishedContent() {
    return (
      <div className="finished-content">
        <span className="lock-reason">{this.props.stage.displayText}</span>
      </div>
    );
  }

  getOutlineStyle() {
    return this.getContentStyle();
  }

  getContentStyle() {
    let classList = this.props.active ? ' focused ' : ' unfocused ';
    if (this.props.stage.status === 'LOCKED' || this.props.shouldDisabledViewStage) {
      classList += 'locked';
    } else if (this.props.stage.status === 'IN_PROGRESS') {
      classList += 'in-progress';
    } else if (this.props.stage.status === 'FINISHED') {
      classList += 'finished';
    } else if (this.props.active) {
      classList += 'active';
    } else {
      classList += 'inactive';
    }
    return classList;
  }

  getOverlay() {
    return (
      <div
        className={
          'stage-panel-overlay' + (this.props.stage.active ? ' active' : ' inactive')
        }
        onClick={StageStep.activateStage}
        id={this.props.stage.type + '-panel-overlay'}
      />
    );
  }

  activatePanel() {
    let elem = document.getElementById(`${this.props.stage.type}-container`);
    const stageAnimation = new StageAnimation();
    if (elem && !elem.classList.contains('active')) {
      stageAnimation.updateStages(elem);
    }
  }
}

StagePanel.propTypes = {
  stages: PropTypes.array.isRequired,
};

export default StagePanel;
