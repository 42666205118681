import {KonvaEventObject} from 'konva/types/Node';
import {KonvaPointerEvent} from 'konva/types/PointerEvents';
import {sumBy} from 'lodash';
import React, {useMemo, useState} from 'react';
import {Line as KonvaLine, Rect} from 'react-konva';
import {BOX_LABEL_DEFAULT, BOX_LABEL_SELECTED, genId} from './const';
import Tag from './Tag';
import {LabelPropsEvent, LineLabelProps, LinePropsEvent, Point} from './types';

type LineProps = {
  data?: LineLabelProps;
  closeTargetRadius?: number;
  width?: number;
  height?: number;
  selected?: boolean;
  complete?: boolean;
};

export function Line({
  data = {id: '', points: []},
  width,
  height,
  onComplete = () => {},
  selected = false,
  onDelete = () => {},
  onClick = () => {},
  onContextMenu = () => {},
  complete = false,
}: LineProps & LabelPropsEvent & LinePropsEvent) {
  const [points, setPoints] = useState<Point[]>([]);
  const [nextPoint, setNextPoint] = useState<Point>({x: 0, y: 0});
  const [tagCenter, setTagCenter] = useState<Point>({x: 0, y: 0});
  const [isComplete, setIsComplete] = useState(complete);

  const [labelStyle, setLabelStyle] = useState(BOX_LABEL_DEFAULT);
  const [isHover, setIsHover] = useState(false);

  const onPointDraw = ({x, y}: Point) => {
    const newPoints = points.concat({x, y});
    console.log('!newPoints: ', newPoints);
    setPoints(newPoints);
    if (newPoints.length === 2) {
      onComplete({...data, id: genId('LINE'), points: newPoints, complete: true});
      setNextPoint(points[0]);
      setIsComplete(true);
    }
  };

  useMemo(() => {
    if (data) {
      const pArr = data.points;
      setPoints(pArr);
      // set tag location
      const cX = sumBy(pArr, val => val.x) / pArr.length;
      const cY = sumBy(pArr, val => val.y) / pArr.length;
      setTagCenter({x: cX, y: cY});
    }
  }, [data]);

  useMemo(() => {
    setLabelStyle(isHover || selected ? BOX_LABEL_SELECTED : BOX_LABEL_DEFAULT);
  }, [isHover, selected]);

  const onMouse = (isHovering: boolean) => setIsHover(isHovering);

  const onLocalContextMenu = (e: KonvaPointerEvent) => {
    e.evt.preventDefault();
    onContextMenu && onContextMenu(e);
  };

  const onLineClick = (e: KonvaEventObject<MouseEvent>) => {
    e.evt.preventDefault();
    onClick(e);
  };

  return (
    <>
      <KonvaLine
        {...labelStyle}
        lineJoin="round"
        closed={isComplete}
        hitStrokeWidth={20}
        shadowForStrokeEnabled
        points={points
          .flatMap(point => [point.x, point.y])
          .concat(isComplete ? [] : [nextPoint.x, nextPoint.y])}
        onMouseOver={() => onMouse(true)}
        onMouseOut={() => onMouse(false)}
        onContextMenu={onLocalContextMenu}
        onClick={onLineClick}
      />

      {/* Hit Area */}
      {!isComplete && (
        <Rect
          x={0}
          y={0}
          width={width}
          height={height}
          onClick={event => {
            if (!isComplete) {
              const x = event.evt.offsetX;
              const y = event.evt.offsetY;
              onPointDraw({x, y});
            }
          }}
          // onContextMenu={onLocalContextMenu}
          onMouseMove={event => {
            if (!isComplete) {
              const x = event.evt.offsetX;
              const y = event.evt.offsetY;
              setNextPoint({x, y});
            }
          }}
          style={{border: '3px solid red'}}
        />
      )}
      {isComplete && (
        <Tag
          label={data.label}
          {...tagCenter}
          selected={selected || isHover}
          onDelete={onDelete}
          yBuffer={40}
          onClick={onLineClick}
          onContextMenu={onLocalContextMenu}
        />
      )}
    </>
  );
}
