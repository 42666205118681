import {ImageProps} from 'material-ui-image/lib/components/Image/Image';
import cn from 'classnames';
import * as React from 'react';
import {StudioCanvasOverlay} from '../../base-components/StudioOverlay/StudioCanvasOverlay';
import {BoundingBoxType} from '../../base-components/StudioOverlay/StudioCanvasOverlay/StudioCanvasOverlay';
import {StudioImageNode} from '../../base-components/StudioOverlay/StudioImageNode/StudioImageNode';
import './DataViewerItem.scss';
import {Skeleton} from '../Skeleton';

// This a copy of DataViewerItem with slight modification to allow thumbnail loading as part of the batch image download. Instead of a 1 request per image.
const SIZE_PX = 93;

export type DataViewerItemThumbnailProps = {
  adornment?: React.ReactNode;
  annotation?: BoundingBoxType[];
  className?: string;
  width?: number;
  height?: number;
  onClick?: ImageProps['onClick'];
  url: string;
  pos: number;
};

export const DataViewerItemThumbnail = ({
  adornment,
  className,
  height = SIZE_PX,
  width = SIZE_PX,
  onClick,
  url,
  pos,
  annotation = [],
}: DataViewerItemThumbnailProps) => {
  const [isDrawing, setIsDrawing] = React.useState(true);

  return (
    <>
      {isDrawing && <Skeleton variant="rect" style={{width, height}} />}
      <div
        className={cn('data-viewer-item', className)}
        style={{width, height: isDrawing ? 0 : height, overflow: 'hidden'}}
        onClick={onClick}
      >
        <StudioCanvasOverlay width={width} height={height} data={annotation}>
          <StudioImageNode
            src={url}
            size={height}
            position={pos}
            onLoad={() => {
              if (isDrawing) {
                setIsDrawing(false);
              }
            }}
          />
        </StudioCanvasOverlay>
        {adornment}
      </div>
    </>
  );
};
