import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {Grid, Paper} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {CreateProjectButton} from '../ProjectList/CreateProjectButton';
import {Marketplace} from './Marketplace';
import {RecentProjects} from './RecentProjects';
import {viewOpenProjectForm} from '../../store/view';
import {projectListFetchReplace, getProjectListData} from '../../store/projectList';
import {fetchRecentDeployments} from '../../store/prodDeployments/dashboard';
import {RecentDeployments} from './RecentDeployments';
import {ConversationActionListener} from '../ConversationAssistant/ConversationActionListener';
import FormButton from '../../base-components/StudioButton/FormButton';
import {FormattedMessage} from 'react-intl';
import {Solutions} from './Solutions';
import {useStudioMode} from '../StudioMode/StudioModeProvider';
import {LiteWelcome} from '../StudioLite/LiteWelcome';
import {LiteProjects} from '../StudioLite/LiteProjects';
import {LiteDeployments} from '../StudioLite/LiteDeployments';
import {StudioWizard} from '../StudioWizard';
import {ReactComponent as PinIcon} from './pin.svg';
import './Dashboard.scss';

const DashboardBase = ({
  projects,
  deployments,
  projectListFetchReplace,
  fetchRecentDeployments,
  viewOpenProjectForm,
  userId,
  groupId,
}) => {
  const {mode} = useStudioMode();
  const history = useHistory();
  const location = useLocation();
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(
    function showWizardSoon() {
      if (params.get('wizard')) {
        params.delete('wizard');
        location.search = params.toString();
        history.replace(location, location.state);
        setTimeout(() => {
          setIsWizardOpen(true);
        }, 2000);
      }
    },
    [params, location, history]
  );

  useEffect(() => {
    if (mode !== 'LITE') {
      projectListFetchReplace();
      fetchRecentDeployments({
        firstPage: 0,
        pageSize: 3,
        paginated: true,
        orderDefinitions: [{field: 'creationTime', direction: 'DESC'}],
        params: {
          deploymentType: ['PRODUCTION'],
        },
        groupId,
      });
    }
  }, [fetchRecentDeployments, projectListFetchReplace, userId, groupId, mode]);

  const renderLiteMode = () => (
    <>
      <Paper className={cn('dashboard__paper', 'dashboard__item--lite')}>
        <LiteWelcome />
      </Paper>
      <Paper className={cn('dashboard__paper', 'dashboard__item--lite')}>
        <h5 className="dashboard__header">
          <FormattedMessage id="dashboard.projects" />
        </h5>
        <LiteProjects />
      </Paper>
      <Paper className={cn('dashboard__paper', 'dashboard__item--lite')}>
        <h5 className="dashboard__header">
          <FormattedMessage id="dashboard.deployments" />
        </h5>
        <LiteDeployments />
      </Paper>
    </>
  );

  const renderFullMode = () => {
    return (
      <>
        <h5 className="dashboard__header">
          <FormattedMessage id="dashboard.projects" />
        </h5>
        <CreateProjectButton
          onClick={() => viewOpenProjectForm()}
          className="dashboard__create-project-tile"
        />
        <Paper className="dashboard__paper">
          <div className="dashboard__item-title">
            <FormattedMessage id="dashboard.recentProjects" />
          </div>
          <div className="dashboard__item-body">
            {projects.length > 0 ? (
              <RecentProjects projects={projects} />
            ) : (
              <div className="dashboard__empty">
                <FormattedMessage id="dashboard.noProjects" />
              </div>
            )}
          </div>
        </Paper>
        <div className="dashboard__view-all">
          <Link to="/project/list">
            <FormattedMessage id="dashboard.viewAllProjects" />
          </Link>
        </div>
        <Paper className="dashboard__paper">
          <div className="dashboard__item-title">
            <FormattedMessage id="dashboard.recentDeployments" />
          </div>
          <div className="dashboard__item-body">
            {deployments.length > 0 ? (
              <RecentDeployments deployments={deployments} />
            ) : (
              <div className="dashboard__empty">
                <FormattedMessage id="dashboard.noDeployments" />
              </div>
            )}
          </div>
        </Paper>
        <div className="dashboard__view-all">
          <Link to="/deployments">
            <FormattedMessage id="dashboard.viewAllDeployments" />
          </Link>
        </div>
      </>
    );
  };

  return (
    <Grid container className="dashboard">
      <Grid
        item
        xs={6}
        container
        direction="column"
        className="dashboard__column"
        wrap="nowrap"
      >
        {mode === 'LITE' ? renderLiteMode() : renderFullMode()}
      </Grid>
      <Grid
        item
        xs={6}
        container
        direction="column"
        className="dashboard__column"
        wrap="nowrap"
      >
        {mode === 'LITE' && (
          <div className="dashboard__tour-guide-row">
            <FormButton
              className="dashboard__tour-guide-button"
              buttonRole="primary"
              value={
                <Fragment>
                  <PinIcon />{' '}
                  <div className="dashboard__tour-guide-button-text">Tour Guide</div>
                </Fragment>
              }
              onClick={() => {
                setIsWizardOpen(true);
              }}
            />
          </div>
        )}
        <h5 className="dashboard__header">
          <FormattedMessage id="dashboard.marketplace" />
        </h5>
        <Marketplace />
        <Solutions />
      </Grid>
      <ConversationActionListener />
      {mode === 'LITE' && (
        <StudioWizard
          isOpen={isWizardOpen}
          onClose={() => {
            setIsWizardOpen(false);
          }}
        />
      )}
    </Grid>
  );
};

DashboardBase.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      lastUpdateTime: PropTypes.string,
    })
  ),
  onMount: PropTypes.func,
  onCreateClick: PropTypes.func,
};

DashboardBase.defaultProps = {
  projects: [],
  onMount: () => {},
  onCreateClick: () => {},
};

const mapStateToProps = state => ({
  projects: getProjectListData(state),
  deployments: state.deploymentDashboard.recentDeployments.data,
  userId: state.user.userId,
  groupId: state.user.groupId,
});

const mapDispatchToProps = {
  projectListFetchReplace,
  viewOpenProjectForm,
  fetchRecentDeployments,
};

export const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardBase);
