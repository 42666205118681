import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import URL from '../../config/url';
import {
  IntegrationPurpose,
  IntegrationResponse,
  IntegrationRole,
  Integration,
} from '../../types/integrations/IntegrationResponse';

type MarketplaceState = {
  integrations: {[key in IntegrationPurpose]?: {[key in IntegrationRole]?: Integration}};
};

const initialState: MarketplaceState = {
  integrations: {},
};

export const fetchMarketplaceIntegrations = createAsyncThunk(
  'marketplace-integrations/fetch',
  async () => {
    const {data} = await axios.get<IntegrationResponse>(URL.INTEGRATION_GET);
    return data.body;
  }
);

const marketplaceSlice = createSlice({
  name: 'marketplace-integrations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMarketplaceIntegrations.fulfilled, (state, action) => {
      state.integrations = {};
      action.payload.forEach(integration => {
        const {role, purpose} = integration;
        const purposeMap = {...state.integrations[purpose]};
        purposeMap[role] = integration;
        state.integrations[purpose] = purposeMap;
      });
    });
  },
});

export const {reducer: marketplaceReducer} = marketplaceSlice;
