import axios from 'axios';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {postTestFinalize} from '../../../api/test/TestFinalize';
import {postTestInitiate, TestInitiateRequest} from '../../../api/test/TestInitiate';
import URL from '../../../config/url';
import {projectFetch} from '../../../store/project';
import {addListener, removeListener} from '../../../store/statusChecker';
import Util from '../../../util';
import {
  resetAllTasks,
  setDeployConfig,
  setDeployTask,
  TASK_STATUS,
  useDeploy,
} from '../useDeploy';
import {Deployment} from '../../../types/deployment/DeploymentResponse';

export const useDeployTask = (projectId: string) => {
  const [, dispatch] = useDeploy();
  const reduxDispatch = useDispatch();

  const finalize = useCallback(async () => {
    const response = await postTestFinalize({projectId});
    if (response.errors?.length) {
      throw new Error('Error received during GSP test finalize');
    }
    reduxDispatch(projectFetch(projectId));
  }, [projectId, reduxDispatch]);

  const monitorStatus = useCallback(() => {
    return new Promise<void>(resolve => {
      reduxDispatch(
        addListener('GSP_DEPLOY', (status: any) => {
          dispatch(setDeployTask({monitoringResponse: status}));
          if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
            reduxDispatch(removeListener('GSP_DEPLOY'));
            resolve();
          }
        })
      );
    });
  }, [dispatch, reduxDispatch]);

  const start = useCallback(
    async (
      params: TestInitiateRequest,
      onStartDeploy?: (deployment: Deployment) => void
    ) => {
      const response = await postTestInitiate(params);
      if (response.errors?.length) {
        throw new Error('Error received during GSP test');
      }
      if (onStartDeploy && response.body) {
        onStartDeploy(response.body);
      }
    },
    []
  );

  const run = useCallback(
    async (params: TestInitiateRequest) => {
      dispatch(setDeployTask({status: TASK_STATUS.IN_PROGRESS}));
      await start(params);
      await monitorStatus();
      await finalize();
      dispatch(setDeployTask({status: TASK_STATUS.COMPLETE}));
    },
    [dispatch, start, monitorStatus, finalize]
  );

  const terminate = useCallback(async () => {
    try {
      await axios.get(URL.OPERATION_CANCEL('GSP_DEPLOY'), {
        params: {projectId},
      });
      reduxDispatch(removeListener('GSP_DEPLOY'));
      dispatch(resetAllTasks());
    } catch (e) {}
  }, [dispatch, projectId, reduxDispatch]);

  const resumeIfExists = useCallback(async () => {
    try {
      const response = await axios.get(URL.PROJECT_OP_STATUS(projectId));
      const {tasks} = response.data.body;
      const existingTask = tasks.find((task: any) => task.type === 'GSP_DEPLOY');
      if (!existingTask) {
        return;
      }
      const {deploymentId, gspBoxId} = existingTask;
      dispatch(setDeployConfig({deploymentId, nodeId: gspBoxId}));
      dispatch(setDeployTask({status: TASK_STATUS.IN_PROGRESS}));
      await monitorStatus();
      await finalize();
      dispatch(setDeployTask({status: TASK_STATUS.COMPLETE}));
    } catch (e) {
      dispatch(resetAllTasks());
    }
  }, [dispatch, projectId, monitorStatus, finalize]);

  return {
    runDeploy: run,
    startDeploy: start,
    terminateDeploy: terminate,
    resumeDeploy: resumeIfExists,
  };
};
