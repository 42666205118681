import {DeploymentParameters} from '../../types/deployment/DeploymentParameters';
import {DeploymentInput} from '../../types/deployment/DeploymentInput';
import {DeploymentType} from '../../types/deployment/DeploymentType';
import {request} from '../request';
import {Deployment} from '../../types/deployment/DeploymentResponse';

export type TestInitiateRequest = {
  projectId: string;
  inferenceDataId?: string;
  applicationId: string;
  nodeId: string;
  name: string;
  description: string;
  deploymentType: DeploymentType;
  deploymentInput: DeploymentInput;
  parameters?: DeploymentParameters;
};

export type TestInitiateResponse = {};

export async function postTestInitiate(data: TestInitiateRequest) {
  return request<Deployment>({
    method: 'post',
    url: '/gsp/test/initiate',
    data,
  });
}
