import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {ReactComponent as LiteWelcomeIcon} from '../../assets/images/studio-lite-welcome.svg';

export const LiteWelcome = () => {
  const intl = useIntl();
  return (
    <div>
      <div style={{textAlign: 'center'}}>
        <LiteWelcomeIcon />
        <h5 style={{fontSize: '20px'}}>
          <FormattedMessage id="studioLite.header" />
        </h5>
      </div>
      <p>
        {intl.formatMessage(
          {id: 'studioLite.intro.1'},
          {
            emailLink: <a href="mailto:studio-lite@blaize.com">studio-lite@blaize.com</a>,
          }
        )}
      </p>
      <p>
        {intl.formatMessage(
          {id: 'studioLite.intro.2'},
          {
            browseLink: (
              <Link to="/marketplace?role=PUBLIC&purpose=SOLUTION_STORAGE">
                Browse Solutions
              </Link>
            ),
            learnLink: (
              <a
                href="https://www.blaize.com/products/ai-studio/#marketplace"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            ),
          }
        )}
      </p>
    </div>
  );
};
