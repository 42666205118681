import {Credentials} from '../../types/user/Credentials';
import {request} from '../request';

export function addUser(credentials: Credentials) {
  return request<{}>({
    method: 'POST',
    url: '/user/new',
    data: credentials,
    headers: {bypassDefaultErrorHandler: true},
  });
}
