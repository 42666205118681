import {unwrapResult} from '@reduxjs/toolkit';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {RootState, useAppDispatch} from '../../../store';
import {prepareDDA, prepareDDAFinalize} from '../../../store/prodDeployments/retrain';
import {dataPrepareActions} from '../../../store/dataPrepare';

import {Answer} from '../../../types/prompts/Answer';
import {DynamicForm} from '../../DynamicForm';
import './Retrain.scss';
import {differenceBy} from 'lodash';
import {Fade} from '@material-ui/core';
import Waiting from '../../../base-components/StudioWaiting/Waiting';

const {setDataPrep} = dataPrepareActions;

export const Retrain = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const projectId = useSelector(
    (state: RootState) => state.deploymentRetrainDialog.projectId
  );
  const deploymentId = useSelector(
    (state: RootState) => state.deploymentRetrainDialog.deploymentId
  );
  const prompts = useSelector(
    (state: RootState) => state.deploymentRetrainDialog.prompts
  );
  const totalPages = useSelector(
    (state: RootState) => state.deploymentRetrainDialog.totalPages
  );
  const page = useSelector((state: RootState) => state.deploymentRetrainDialog.page);
  const defaults = useSelector(
    (state: RootState) => state.deploymentRetrainDialog.defaults
  );
  const [answers, setAnswers] = useState<Answer[]>([]);

  useEffect(() => {
    if (projectId && deploymentId) {
      dispatch(prepareDDA({projectId, deploymentId}));
    }
  }, [dispatch, projectId, deploymentId]);

  const handleSubmit = async (payload: Array<Answer>) => {
    const diff = differenceBy(answers, payload, 'key');
    const newAnswers = diff.concat(payload);

    if (page < totalPages) {
      setAnswers(newAnswers);
      if (projectId && deploymentId) {
        dispatch(prepareDDA({projectId, deploymentId, page: page + 1}));
      }
    } else {
      if (projectId && deploymentId) {
        try {
          const action = await dispatch(
            prepareDDAFinalize({
              projectId,
              resourceId: deploymentId,
              answers: newAnswers,
            })
          );
          const finalizationResult = unwrapResult(action);
          dispatch(
            setDataPrep({
              container: finalizationResult.ddaPreparationContainer,
              context: 'deployment',
            })
          );
          history.push(
            `/project/${finalizationResult.projectId}/data/prepare/deployment`
          );
        } catch (e) {}
      }
    }
  };

  const handleBack = () => {
    if (page === 1) {
      history.goBack();
    } else if (page > 1) {
      if (projectId && deploymentId) {
        dispatch(prepareDDA({projectId, deploymentId, page: page - 1}));
      }
    }
  };

  const RenderButtons = (isSubmitting: boolean, isValid: boolean) => {
    useEffect(() => {
      setLoading(isSubmitting);
    }, [isSubmitting]);

    return (
      <div className="prod-deploy-retrain__actions">
        <FormButton
          buttonRole="naked"
          value={intl.formatMessage({id: 'form.back'})}
          onClick={handleBack}
          type="button"
          buttonIcon="chevronLeft"
          iconBeforeText
        />
        <FormButton
          buttonRole="primary"
          value={intl.formatMessage({id: 'form.next'})}
          type="submit"
          disabled={isSubmitting || !isValid}
        />
      </div>
    );
  };

  return (
    <div
      className="prod-deploy-retrain"
      data-testid="prod-deploy-retrain"
      id="prod-deploy-retrain"
    >
      <Fade in={loading}>
        <div className="data-prepare__loading">
          <Waiting
            diameter={100}
            loadingText={intl.formatMessage({
              id: 'dataPrep.configure.dataset',
            })}
          />
        </div>
      </Fade>
      <DynamicForm
        title={'prodDeployment.configureDataset'}
        prompts={prompts}
        defaults={defaults}
        onSubmit={handleSubmit}
        buttonPlacement={'top'}
        renderButtons={RenderButtons}
      />
    </div>
  );
};
