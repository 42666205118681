import axios from 'axios';
import {ExecutionContainer} from '../../types/model/framework/ExecutionContainer';
import {PostProcessorType} from '../../types/postprocessor/PostProcessorType';
import {Response} from '../../types/response/Response';
import {Application} from '../../types/project/Application';

type ParameterType = 'PRE_PROCESSOR' | 'POST_PROCESSOR';

export type UpdateParameterRequest = {
  parameterType: ParameterType;
  postProcessorType?: PostProcessorType;
  executionContainer?: ExecutionContainer;
  name: string;
  value: string;
};

export type UpdateParametersRequest = {
  projectId: string;
  applicationId: string;
  parameters: Array<UpdateParameterRequest>;
};

export function updateParameters(request: UpdateParametersRequest) {
  return axios.post<Response<Application>>(
    '/studio/application/parameters/update',
    request
  );
}

export function resetParameters(
  projectId: string,
  applicationId: string,
  pre: boolean,
  post: boolean
) {
  return axios.post<Response<Application>>(
    `/studio/application/parameters/reset?projectId=${projectId}&applicationId=${applicationId}&pre=${pre}&post=${post}`
  );
}
