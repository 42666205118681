import React, {MutableRefObject, useCallback, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import {StudioTooltip} from '../StudioTooltip';
import './TextOverflow.scss';

export type TextOverflowProps = {
  text: string;
  className?: string;
};

export const TextOverflow = ({text, className, ...rest}: TextOverflowProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isTruncated = useTruncated(ref);

  const block = (
    <div ref={ref} className={cn('text-overflow', className)} {...rest}>
      {text}
    </div>
  );

  if (!isTruncated) {
    return block;
  }

  return <StudioTooltip title={text}>{block}</StudioTooltip>;
};

const useTruncated = (ref: MutableRefObject<HTMLDivElement | null>) => {
  const [isTruncated, setTruncated] = useState(false);
  const handleRef = useCallback(() => {
    if (ref.current) {
      setTruncated(ref.current.scrollWidth !== ref.current.clientWidth);
    }
  }, [ref]);

  useEffect(() => {
    handleRef();
    window.addEventListener('resize', handleRef);
    return () => window.removeEventListener('resize', handleRef);
  }, [handleRef]);

  return isTruncated;
};
