import React, {Component} from 'react';
import PropTypes from 'prop-types';

class BaseDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: this.props.isOpen,
      form: this.props.children,
      stageStep: null,
      action: null,
      selectedLoader: null,
    };
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }
    return <div id={this.props.id}>{this.state.form}</div>;
  }

  display(newForm, stageStep, action, exit) {
    this.setState({
      isOpen: true,
      form: newForm,
      stageStep: stageStep,
      action: action,
      exit: exit,
    });
  }

  hideStage(stageStep) {
    if (stageStep === this.state.stageStep) {
      this.hide();
    }
  }

  hide() {
    this.hideElements();
    this.setState({
      isOpen: false,
      form: this.state.form,
    });
  }

  hideElements() {
    let dialog = document.getElementById(this.props.id);
    if (dialog) {
      dialog.classList.add('hidden');
    }
  }
}

export const DialogProps = {
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string,
  src: PropTypes.string,
  onFinish: PropTypes.any,
  onClick: PropTypes.func,
  assistantPanel: PropTypes.any,
  updateConversionStatus: PropTypes.any,
};

BaseDialog.propTypes = DialogProps;

export default BaseDialog;
