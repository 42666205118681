import useSWR from 'swr';

import {request} from '../request';

export type DeploymentEnvironmentVariablesRequest = {
  latest?: boolean;
  projectId?: string;
  applicationId?: string;
};

export type DeploymentEnvironmentVariablesResponse = {};

export const EnvironmentVariablesPath = '/gsp/environment';

export async function getDeploymentEnvironmentVariables(
  params: DeploymentEnvironmentVariablesRequest
) {
  const response = await request<DeploymentEnvironmentVariablesResponse>({
    params,
    method: 'get',
    url: EnvironmentVariablesPath,
  });

  return response.body;
}

type UseDeploymentEnvironmentVariables = DeploymentEnvironmentVariablesRequest & {
  avoidFetch?: boolean;
};

export function useDeploymentEnvironmentVariables({
  avoidFetch = false,
  ...data
}: UseDeploymentEnvironmentVariables) {
  const shouldFetch = !avoidFetch && data.projectId && data.applicationId;

  return useSWR(
    shouldFetch ? EnvironmentVariablesPath : null,
    () => getDeploymentEnvironmentVariables(data),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
}
