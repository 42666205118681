import React from 'react';
import {
  IconButton,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {ArrowDropDown} from '@material-ui/icons';
import cn from 'classnames';
import {FormattedMessage} from 'react-intl';
import {MarketplaceResult} from '../../../types/marketplace/MarketplaceFilesResponse';
import {
  SortableColumn,
  SortDirection,
} from '../../../types/marketplace/MarketplaceFilesRequest';
import {metadataMap} from '../metadataMap';
import {StudioCheckbox} from '../../../base-components/StudioCheckbox';
import './MarketplaceList.scss';

type MarketplaceListProps = {
  getKey: (result: MarketplaceResult) => string;
  selectedIds: Record<string, MarketplaceResult>;
  sort: SortableColumn;
  sortDir: SortDirection;
  results?: Array<MarketplaceResult>;
  columns: Array<SortableColumn>;
  className?: string;
  multiSelect?: boolean;
  onSelectedChange: (result: MarketplaceResult) => void;
  onSort: (column: SortableColumn, direction: SortDirection) => void;
  onViewMore?: (index: number) => void;
  onSelectAllChange?: (selectAll: boolean) => void;
};

export const MarketplaceList = ({
  getKey,
  sort,
  sortDir,
  selectedIds,
  results,
  className,
  columns,
  multiSelect,
  onSelectedChange,
  onSelectAllChange,
  onSort,
  onViewMore,
}: MarketplaceListProps) => {
  if (results === undefined) {
    return (
      <div className="marketplace-list__not-configured">
        <FormattedMessage id="marketplaceExplorer.not.configured" />
      </div>
    );
  }

  if (results.length === 0) {
    return (
      <div className="marketplace-list__no-results">
        <FormattedMessage id="marketplaceExplorer.empty" />
      </div>
    );
  }

  const getSortDirection = (column: typeof sort) =>
    sort === column ? (sortDir === 'ASC' ? 'asc' : 'desc') : 'asc';

  const handleSort = (column: typeof sort) => {
    const direction = column === sort ? (sortDir === 'ASC' ? 'DESC' : 'ASC') : 'ASC';
    onSort(column, direction);
  };

  const allItemsSelected = results.every(value => selectedIds[getKey(value)]);

  const columnDefinitions = columns.map(column => ({
    property: column,
    ...metadataMap[column],
  }));

  return (
    <TableContainer
      component={Paper}
      className={cn('marketplace-list', className)}
      elevation={0}
    >
      <Table
        stickyHeader
        className="marketplace-list__table"
        size="small"
        data-testid="marketplace-list-table"
        id="marketplace-list-table"
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="marketplace-list__select-header">
                {multiSelect && (
                  <StudioCheckbox
                    data-testid="marketplace-list-checkbox"
                    id="marketplace-list-checkbox"
                    name="select-all"
                    checked={allItemsSelected}
                    onChange={() => onSelectAllChange?.(!allItemsSelected)}
                  />
                )}
                <div>Select</div>
              </div>
            </TableCell>
            {columnDefinitions.map(({label, property}) => (
              <TableCell key={property}>
                <TableSortLabel
                  active={sort === property}
                  direction={getSortDirection(property)}
                  onClick={() => handleSort(property)}
                  IconComponent={ArrowDropDown}
                >
                  <FormattedMessage id={label} />
                </TableSortLabel>
              </TableCell>
            ))}
            {onViewMore && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((result, index) => (
            <TableRow key={index}>
              <TableCell>
                {multiSelect ? (
                  <StudioCheckbox
                    data-testid="marketplace-list-checkbox"
                    id="marketplace-list-checkbox"
                    name={getKey(result)}
                    checked={Boolean(selectedIds[getKey(result)])}
                    onChange={() => onSelectedChange(result)}
                  />
                ) : (
                  <Radio
                    data-testid="marketplace-list-radio"
                    id="marketplace-list-radio"
                    value={index}
                    checked={Boolean(selectedIds[getKey(result)])}
                    color="default"
                    onChange={() => onSelectedChange(result)}
                    size="small"
                  />
                )}
              </TableCell>
              {columnDefinitions.map(({render}, index) => (
                <TableCell key={index}>
                  {render(result) || (
                    <FormattedMessage
                      tagName="span"
                      id="marketplaceExplorer.listView.emptyColumnValue"
                    />
                  )}
                </TableCell>
              ))}
              {onViewMore && (
                <TableCell>
                  <IconButton aria-label="view details" onClick={() => onViewMore(index)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
