import axios from 'axios';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router';
import {toast} from '../../../base-components/StudioToast';
import {useAppDispatch} from '../../../store';
import {actions} from '../../../store/user/user';

const AuthContext = React.createContext({});

export const AuthProvider = ({children}) => {
  const dispatch = useAppDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const location = useLocation();
  const prevIsAuthenticated = useRef(isAuthenticated);
  const pathname = location.pathname;

  const checkAuthStatus = useCallback(async () => {
    try {
      const {data} = await axios.get('/studio/operation/userStatus', {
        headers: {bypassDefaultErrorHandler: true},
      });
      data.body && dispatch(actions.setUser(data.body));
      setIsAuthenticated(true);
    } catch {
      setIsAuthenticated(false);
    }
  }, [dispatch]);

  // re-check auth every time the path changes
  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus, pathname]);

  // re-check auth every time the window is focused
  useEffect(() => {
    window.addEventListener('focus', checkAuthStatus);

    return () => {
      window.removeEventListener('focus', checkAuthStatus);
    };
  }, [checkAuthStatus]);

  useEffect(() => {
    if (prevIsAuthenticated.current && isAuthenticated === false) {
      toast.error('Session expired');
    }
    prevIsAuthenticated.current = isAuthenticated;
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider value={{isAuthenticated, setIsAuthenticated}}>
      {isAuthenticated == null || children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
