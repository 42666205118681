const dataTypes = {
  AUTO: 'Auto (AMP)',
  INT8: '8 bit',
  INT16: '16 bit',
  BFLOAT16: '16-bit float',
};

export type DataType = keyof typeof dataTypes;

export function dataTypeToString(dataType: DataType) {
  return dataTypes[dataType] ?? dataType;
}
