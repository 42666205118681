import {ButtonProps} from '@material-ui/core';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import AddIcon from '../../assets/icons/AddIcon';
import ChevronDownIcon from '../../assets/icons/ChevronDownIcon';
import SearchIcon from '../../assets/icons/SearchIcon';
import './FormButton.scss';
import ChevronLeftIcon from '../../assets/icons/ChevronLeftIcon';

export type FormButtonProps = {
  buttonRole: 'primary' | 'secondary' | 'delete' | 'assistant' | 'naked';
  value: string;
  id?: string;
  onClick?: () => void;
  customClass?: string;
  buttonIcon?: 'chevron' | 'add' | 'search' | 'chevronLeft';
  disabled?: boolean;
  type: ButtonProps['type'];
  className?: string;
  dataTestid?: string;
  iconBeforeText?: boolean;
};

/**
 * Use form button within form component
 * State properties: none
 */

class FormButton extends Component<FormButtonProps> {
  render() {
    const {
      id,
      disabled,
      value,
      buttonIcon,
      onClick,
      type,
      className,
      dataTestid,
      iconBeforeText = false,
    } = this.props;
    if (buttonIcon) {
      return (
        <button
          className={cn(`${this.getButtonStyle()} with-icon`, className)}
          onClick={onClick}
          {...(id ? {id: id} : {})}
          disabled={disabled}
          type={type}
          data-testid={dataTestid}
          id={dataTestid}
        >
          {iconBeforeText && this.getIcon(buttonIcon, 'left')}
          {value}
          {!iconBeforeText && this.getIcon(buttonIcon)}
        </button>
      );
    }
    return (
      <button
        className={cn(this.getButtonStyle(), className)}
        onClick={onClick}
        {...(id ? {id: id} : {})}
        disabled={disabled}
        type={type}
        data-testid={dataTestid}
        id={dataTestid}
      >
        {value}
      </button>
    );
  }

  getButtonStyle() {
    const customCls = this.props.customClass ? this.props.customClass : '';
    return 'form-button ' + this.props.buttonRole + ' ' + customCls;
  }

  getIcon(name: NonNullable<FormButtonProps['buttonIcon']>, className: string = '') {
    const classNames = `buttonIcon ${className}`;
    const icons = {
      chevron: <ChevronDownIcon className={classNames} />,
      chevronLeft: <ChevronLeftIcon className={classNames} />,
      add: <AddIcon className={classNames} />,
      search: <SearchIcon className={classNames} />,
    };
    return icons[name];
  }

  static propTypes = {
    buttonRole: PropTypes.oneOf(['primary', 'secondary', 'delete', 'assistant', 'naked'])
      .isRequired,
    value: PropTypes.string.isRequired,
    id: PropTypes.string,
    onClick: PropTypes.func,
    customClass: PropTypes.string,
    buttonIcon: PropTypes.oneOf(['chevron', 'add', 'search', 'chevronLeft']),
    disabled: PropTypes.bool,
    type: PropTypes.string,
  };

  static defaultProps = {
    id: null,
    onClick: () => null,
    customClass: null,
    buttonIcon: null,
    disabled: false,
    type: 'button',
  };
}

export default FormButton;
