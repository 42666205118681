import {SvgIcon} from '@material-ui/core';
import React from 'react';

const PolygonIcon = (props: any) => {
  return (
    <SvgIcon
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 4.44615L7.78788 1L9.72727 8.13846L1 15.7692H15.7879L17 4.44615Z"
        stroke="#767676"
        strokeWidth="2"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};

export default PolygonIcon;
