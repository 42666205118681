import {PreProcessor} from '../../types/preprocessor/PreProcessor';
import {request} from '../request';

export type PreProcessorGetRequest = {
  projectId: string;
  preProcessorId: string;
};

export type PreProcessorGetResponse = PreProcessor;

export const PreProcessorGetUrl = ({projectId, preProcessorId}: PreProcessorGetRequest) =>
  `/preprocessor/get/${projectId}?${new URLSearchParams({
    preProcessorId,
  }).toString()}`;

export async function preProcessorGet(data: PreProcessorGetRequest) {
  return request<PreProcessorGetResponse>({
    method: 'get',
    url: PreProcessorGetUrl(data),
  });
}
