import {useDispatch, useSelector} from 'react-redux';
import React from 'react';
import {ConfirmDialog} from '../../../base-components/StudioConfirmDialog';
import {
  getIsTerminateDialogOpen,
  getIsTerminationEnabled,
  viewCloseTerminateDialog,
} from '../../../store/view';

export enum TerminateDialogType {
  DATA,
  MODEL,
  OPTIMIZE,
  FINETUNE,
  DEPLOY,
}

export type TerminateDialogProps = {
  title: string;
  message: string;
  terminate(): Promise<void>;
};

export const TerminateDialog = ({title, message, terminate}: TerminateDialogProps) => {
  const dispatch = useDispatch();
  const enabled = useSelector(getIsTerminationEnabled);
  const open = useSelector(getIsTerminateDialogOpen);
  return (
    <ConfirmDialog
      type="terminate"
      open={open}
      title={title}
      message={message}
      onClose={() => {
        dispatch(viewCloseTerminateDialog());
      }}
      onOk={async () => {
        dispatch(viewCloseTerminateDialog());
        terminate();
      }}
      submitLabel="Terminate"
      disableSubmit={!enabled}
    />
  );
};
