import PropTypes from 'prop-types';

export const MenuItemProps = PropTypes.shape({
  // vv REQUIRED vv
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  //vv optional extras vv
  helpingInfo: PropTypes.any,
  action: PropTypes.func,
  disabled: PropTypes.bool,
  originalProperties: PropTypes.object,
});

export const CascadeItemProps = PropTypes.shape({
  // vv REQUIRED vv
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(MenuItemProps).isRequired,
  //vv optional extras
  helpingInfo: PropTypes.any,
  disabled: PropTypes.boolean,
});

export const ActionSegmentProps = PropTypes.exact({
  // vv REQUIRED vv
  title: PropTypes.string,
  icon: PropTypes.oneOf(['chevron', 'add']),
  action: PropTypes.func,
});
