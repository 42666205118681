import {CONSOLE_ADD, CONSOLE_CLEAR} from './actions';

const initialState = {
  list: [],
};

export const consoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSOLE_ADD:
      const {payload} = action;
      return {
        ...state,
        list: [...[payload], ...state.list],
      };
    case CONSOLE_CLEAR:
      return {
        ...state,
        list: [],
      };
    default:
      return state;
  }
};
