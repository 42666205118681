import React, {useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import {Logo} from '../../base-components/StudioLogo';
import {SideBar} from './SideBar';
import {connect} from 'react-redux';
import {viewSetConsole, getIsConsoleOpen} from '../../store/view';
import {Tabs, Tab} from '@material-ui/core';
import {useLocation, matchPath, useHistory} from 'react-router';
import {PrivateRoutes} from '../../config/routes';
import {useIntl} from 'react-intl';
import {useStudioMode} from '../StudioMode/StudioModeProvider';
import {AppDispatch, RootState} from '../../store';
import './MainMenu.scss';

const iconColor = (selected: boolean) => ({
  color: selected ? '#00f6fb' : 'white',
});

export type MainMenuProps = {
  projectId: string | undefined;
  onConsoleClick: (data: {open: boolean}) => void;
  showProjectTabs: boolean;
  showDeployTabs: boolean;
  isConsoleOpen: boolean;
};

const MainMenuBase = ({
  projectId,
  onConsoleClick,
  showProjectTabs,
  showDeployTabs,
  isConsoleOpen,
}: MainMenuProps) => {
  const intl = useIntl();
  const {pathname} = useLocation();
  const history = useHistory();
  const {mode} = useStudioMode();
  const isLiteMode = mode === 'LITE';

  const tabs = useMemo(
    () =>
      [
        {
          name: 'design',
          path: PrivateRoutes.DESIGN,
          button: ({key}) => (
            <Tab
              key={key}
              label={intl.formatMessage({id: 'menu.design'})}
              className={cn('text-tab', {'hide-tab': !showProjectTabs})}
            />
          ),
        },
        {
          name: 'test',
          path: PrivateRoutes.TEST,
          button: ({key}) => (
            <Tab
              key={key}
              label={intl.formatMessage({id: 'menu.test'})}
              className={cn('text-tab', {'hide-tab': !showProjectTabs})}
            />
          ),
        },
        {
          name: 'deploy',
          path: PrivateRoutes.DEPLOY_ALL,
          matchedPaths: [
            PrivateRoutes.DEPLOY_ALL,
            PrivateRoutes.DEPLOY,
            PrivateRoutes.DEPLOY_INFERENCE_APP,
            PrivateRoutes.DEPLOYMENT_RETRAIN,
          ],
          button: ({key}) => (
            <Tab
              key={key}
              label={intl.formatMessage({id: 'menu.deploy'})}
              className={cn('text-tab', {'hide-tab': !showProjectTabs})}
            />
          ),
        },
        {
          name: 'deploy',
          path: PrivateRoutes.DASHBOARD_DEPLOY_ALL,
          matchedPaths: [
            PrivateRoutes.DASHBOARD_DEPLOY_ALL,
            PrivateRoutes.DASHBOARD_DEPLOY,
          ],
          button: ({key}) => (
            <Tab
              key={key}
              label={intl.formatMessage({id: 'menu.deploy'})}
              className={cn('text-tab', {'hide-tab': !showDeployTabs})}
            />
          ),
        },
        {
          name: 'console',
          button: ({key, selected}) => (
            <Tab
              key={key}
              className="icon-tab"
              icon={<CallToActionIcon style={iconColor(selected)} />}
            />
          ),
        },
        {
          name: 'help',
          path: PrivateRoutes.HELP,
          button: ({key, selected}) => (
            <Tab
              key={key}
              className="icon-tab"
              data-testid="main-menu-help-btn"
              id="main-menu-help-btn"
              icon={<HelpOutlineIcon style={iconColor(selected)} />}
            />
          ),
        },
        {
          name: 'settings',
          path: PrivateRoutes.SETTINGS,
          button: ({key, selected}) => (
            <Tab
              key={key}
              className="icon-tab"
              icon={<AccountCircleRoundedIcon style={iconColor(selected)} />}
            />
          ),
        },
        {
          name: 'dashboard',
          path: PrivateRoutes.DASHBOARD,
          button: ({key}) => (
            <Tab
              key={key}
              label={intl.formatMessage({id: 'menu.dashboard'})}
              className={cn('text-tab', 'hide-tab')}
            />
          ),
        },
      ] as Array<{
        name: string;
        path?: string;
        matchedPaths?: string[];
        button: (options: {key: string; selected: boolean}) => void;
      }>,
    [intl, showDeployTabs, showProjectTabs]
  );

  const CONSOLE_INDEX = tabs.findIndex(tab => tab.name === 'console');

  const previousIndex = tabs.findIndex(tab => {
    if (tab.matchedPaths?.length) {
      return tab.matchedPaths.some(path => matchPath(pathname, {path, exact: true}));
    }
    return matchPath(pathname, {path: tab.path, exact: true});
  });
  const [routeIndex, setRouteIndex] = React.useState(previousIndex);

  const handleChange = (_event: unknown, index: number) => {
    const tab = tabs[index];
    setRouteIndex(index);
    if (index === CONSOLE_INDEX) {
      onConsoleClick({open: !isConsoleOpen});
    } else if (tab.path) {
      const path = projectId ? tab.path.replace(':projectId', projectId) : tab.path;
      onConsoleClick({open: false});
      history.push(path);
    }
  };

  useEffect(() => {
    if (tabs && pathname) {
      const i = tabs.findIndex(tab => {
        if (tab.matchedPaths?.length) {
          return tab.matchedPaths.some(path => matchPath(pathname, {path, exact: true}));
        }
        return matchPath(pathname, {path: tab.path, exact: true});
      });
      setRouteIndex(i);
    }
  }, [pathname, tabs]);

  useEffect(() => {
    if (!isConsoleOpen) {
      setRouteIndex(previousIndex);
    }
  }, [isConsoleOpen, setRouteIndex, previousIndex]);

  const isTestInferenceAppPage = matchPath(pathname, {
    path: PrivateRoutes.VIEW_TEST_INFERENCE_APP,
    exact: true,
  });

  const isDeploymentInferenceAppPage = matchPath(pathname, {
    path: PrivateRoutes.VIEW_DEPLOYMENT_INFERENCE_APP,
    exact: true,
  });

  return (
    <header className="main-menu-container" data-testid="main-menu">
      <div className="main-menu">
        <Link to="/dashboard" className="menu-item">
          <button className="inner-button" data-testid="home-button" id="home-button">
            <Logo />
          </button>
        </Link>
        <Tabs value={routeIndex < 0 ? false : routeIndex} onChange={handleChange}>
          {tabs.map((tab, index) =>
            tab.button({key: index.toString(), selected: index === routeIndex})
          )}
        </Tabs>
      </div>
      <SideBar
        className={cn(
          'main-menu__sidebar',
          projectId &&
            !isTestInferenceAppPage &&
            !isDeploymentInferenceAppPage &&
            !isLiteMode &&
            'main-menu__sidebar--visible'
        )}
      />
    </header>
  );
};

const mapStateToProps = (state: RootState) => ({
  isConsoleOpen: getIsConsoleOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onConsoleClick: (data: {open: boolean}) => dispatch(viewSetConsole(data)),
});

export const MainMenu = connect(mapStateToProps, mapDispatchToProps)(MainMenuBase);
