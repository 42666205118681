import React from 'react';
import cn from 'classnames';
import {FormattedMessage} from 'react-intl';
import {DeploymentStatusType} from '../../../types/deployment/DeploymentResponse';
import './DeploymentStatus.scss';

type DeployStatusProps = {
  status: DeploymentStatusType;
};

const statusToTextMap: Record<DeploymentStatusType, string> = {
  IDLE: 'prodDeployment.idle',
  COPYING_DATA: 'prodDeployment.copyingData',
  DEPLOYING: 'prodDeployment.deploying',
  STARTING: 'prodDeployment.starting',
  RUNNING: 'prodDeployment.running',
  STOPPING: 'prodDeployment.stopping',
  ERROR: 'prodDeployment.error',
  DELETED: 'prodDeployment.delete',
};

export const DeployStatus = ({status}: DeployStatusProps) => {
  return (
    <span className="deploy-status">
      <span
        className={cn(
          'deploy-status__bulb',
          status === 'STOPPING' && 'deploy-status__bulb--stopping',
          status === 'STARTING' && 'deploy-status__bulb--starting',
          (status === 'IDLE' || status === 'ERROR') && 'deploy-status__bulb--empty'
        )}
      />
      <FormattedMessage id={statusToTextMap[status] || 'general.na.short'} />
    </span>
  );
};
