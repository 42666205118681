import React, {Component} from 'react';
import PropTypes from 'prop-types';

class PauseIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="10px"
          height="12px"
          viewBox="0 0 10 12"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-851.000000, -6751.000000)"
            >
              <g id="Model-Action-Icons" transform="translate(56.000000, 6308.000000)">
                <g id="row" transform="translate(0.000000, 399.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="model-actions-/-pause-/-Enabled,-Focus"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M7,17.820625 L7,6.18 L10.3203125,6.18 L10.3203125,17.820625 L7,17.820625 Z M13.6796875,6.18 L17,6.18 L17,17.820625 L13.6796875,17.820625 L13.6796875,6.18 Z"
                        id="pause"
                        fill="currentColor"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

PauseIcon.propTypes = {
  className: PropTypes.string,
};

export default PauseIcon;
