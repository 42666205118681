import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import HumanReadableTime from '../../../base-components/StudioHumanReadableTime/HumanReadableTime';
import {
  ProjectButton,
  ProjectButtonColumn as Column,
  ProjectButtonDescription as Description,
  ProjectButtonName as Name,
} from '../ProjectButton';
import Waiting from '../../../base-components/StudioWaiting/Waiting';
import './ProjectListItem.scss';
import {FormattedMessage} from 'react-intl';

// Add back for projects with tasks running when we have that information.
const statusIcon = project => {
  return (
    <Waiting diameter={28} className="project-status-icon project-list__status--hidden" />
  );
};

export const ProjectListItem = ({menuButton: MenuButton, onOpen, project}) => (
  <Grid
    className="project-list-item"
    item
    key={project.id}
    data-testid="project-list-item"
  >
    <ProjectButton className="existing-project-button" onClick={onOpen}>
      {statusIcon(project)}
      <Column>
        <Description>
          <FormattedMessage id="project.lastEdited" />{' '}
          <HumanReadableTime date={project.lastUpdateTime} />
        </Description>
        <Name>{project.name}</Name>
      </Column>
      <Column>
        <Description>
          <FormattedMessage id="project.dataset" />
        </Description>
        <Name>{project.datasetName}</Name>
      </Column>
      <Column>
        <Description>
          <FormattedMessage id="project.model" />
        </Description>
        <Name>{project.modelName}</Name>
      </Column>
      {/* <Column className="project-process-info">
                  <Description>Processes</Description>
                  <Name>0</Name>
                  <footer>01:01:0</footer>
                </Column>
                <Column className="project-budget">
                  <Description>Budget</Description>
                  <Name>98%</Name>
                  <footer>$1000 limit</footer>
                </Column> */}
      <MenuButton />
    </ProjectButton>
  </Grid>
);

ProjectListItem.propTypes = {
  menuButton: PropTypes.func,
  project: PropTypes.object,
};
