import useSWR from 'swr';
import {PreProcessorList} from '../../types/metadata/PreProcessorList';
import {assertResponseBody, request} from '../request';

export type PreProcessorListRequest = {
  projectId?: string | null;
};

export type PreProcessorListResponse = PreProcessorList;

export const PreProcessorListUrl = ({projectId}: PreProcessorListRequest) =>
  `/preprocessor/full_list/${projectId}`;

export async function preProcessorList(params: PreProcessorListRequest) {
  return request<PreProcessorListResponse>({
    method: 'GET',
    url: PreProcessorListUrl(params),
  });
}

export function usePreProcessorList(params: PreProcessorListRequest) {
  return useSWR(
    params.projectId ? PreProcessorListUrl(params) : null,
    () => assertResponseBody(preProcessorList(params)),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
}
