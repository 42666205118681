export const getIspData = state => state.isp.data;

export const getIspColorSpaceOptions = state => state.isp.colorSpaceOptions;

export const getIspTemplate = state => state.isp.template;

export const getIspIsNew = state => state.isp.isNew;

export const getIspErrors = state => state.isp.errors;

export const getIspWarnings = state => state.isp.warnings;

export const getIspIsPending = state => state.isp.isPending;
