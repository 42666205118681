export const MIN_SCALE_FACTOR = 0.5; // 50% Min Zoom limit
export const MAX_SCALE_FACTOR = 10; // 1000% Max Zoom limit
export const THUMBNAIL_SIZE_GRID = 93;
export const THUMBNAIL_SIZE_LIST = 30;
export const ZOOM_PAD_HORIZONTAL = 5;
export const ZOOM_PAD_VERTICAL = 110;

export const THUMBNAIL_FILTER = [
  {
    id: 'imageStateBy',
    label: 'general.filter.title.view',
    type: 'checkbox',
    items: [
      {label: 'general.filter.item.reviewed', value: 'reviewed'},
      {label: 'general.filter.item.notReviewed', value: 'notReviewed'},
    ],
  },
  {
    id: 'sortBy',
    label: 'general.filter.title.sort',
    type: 'radio',
    items: [
      {label: 'general.filter.item.recentlyAddedAsc', value: 'recentlyAddedAsc'},
      {label: 'general.filter.item.recentlyAddedDesc', value: 'recentlyAddedDesc'},
    ],
  },
];
