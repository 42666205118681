import {
  PROJECT_PENDING,
  PROJECT_SUCCESS,
  PROJECT_ERROR,
  PROJECT_PENDING_STATUS,
} from './actions';

const initialState = {
  isPending: false,
  pendingStatus: null,
  data: {
    id: null,
    name: null,
    description: null,
    icon: 'icons/model-icon.png',
    lastUpdateTime: null,
    dataSet: {
      name: '',
      size: 0,
      creationDate: null,
      dataType: null,
      count: 0,
      histogram: null,
      pageCount: 0,
    },
    model: null,
    transformations: {},
    applications: {},
  },
  errors: null,
};

export const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_PENDING:
      return {
        ...state,
        isPending: true,
        pendingStatus: null,
        data: action.payload?.body || state.data,
        errors: null,
      };
    case PROJECT_PENDING_STATUS:
      return {
        ...state,
        pendingStatus: action.payload,
      };

    case PROJECT_SUCCESS:
      return {
        ...state,
        data: {...action.payload.body, ...action.payload.data},
        isPending: false,
        pendingStatus: null,
      };
    case PROJECT_ERROR:
      return {
        ...state,
        isPending: false,
        pendingStatus: null,
        errors: action.payload,
      };
    default:
      return state;
  }
};
