import React from 'react';
import axios from 'axios';
import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Typography} from '@material-ui/core';

import URL from '../../config/url';
import {StudioVersionResponse} from '../../types/operation/StudioVersionResponse';

export const ServerVersion = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [version, setVersion] = useState({
    studioVersion: '',
    versionType: '',
    buildNumber: '',
    buildTime: '',
    runtimeVersions: {},
    componentVersions: {},
    serviceVersions: {},
  });

  useEffect(() => {
    (async function fetchSDKVersion() {
      try {
        const {
          data: {body: serverVersions},
        } = await axios.get<StudioVersionResponse>(URL.NETDEPLOY_VERSION);

        console.log('Data>>', serverVersions);

        setVersion(prevState => ({
          ...prevState,
          ...serverVersions,
        }));
        setIsLoading(false);
      } catch (error) {
        console.error('Error while fetching server version', error);
      }
    })();
  }, []);

  return (
    !isLoading && (
      <>
        <Typography
          data-testid="server-version"
          variant="subtitle1"
          key="studioServerVersion"
        >
          <FormattedMessage
            id="about.studioServerVersion"
            values={{
              version: <span>{version.studioVersion}</span>,
              type: <span>{version.versionType}</span>,
            }}
          />
        </Typography>
        <Typography variant="subtitle1" key="studioServerBuildVersion">
          <FormattedMessage
            id="about.studioServerBuildVersion"
            values={{
              buildNumber: <span>{version.buildNumber}</span>,
              buildTime: <span>{version.buildTime}</span>,
            }}
          />
        </Typography>
      </>
    )
  );
};
