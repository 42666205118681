import React, {useState, useEffect, useContext} from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {useParams} from 'react-router';
import {withRouter} from 'react-router-dom';
import {Formik, Form} from 'formik';
import {isEmpty} from 'lodash';
import {useIntl} from 'react-intl';
import {getIsEditIspFormOpen, viewOpenEditISP, viewCloseEditISP} from '../../store/view';
import {ISPForm, SubmitButton} from './CreateISPFormFields';
import validationSchema from './ValidationSchema';
import {
  ispUpdateLocal,
  ispDelete,
  getIspColorSpaceOptions,
  getIspData,
} from '../../store/isp';
import {StudioDrawer} from '../../base-components/StudioDrawer';
import ModelActionButton from '../../base-components/StudioButton/ModelActionButton';
import {ConfirmDialog} from '../../base-components/StudioConfirmDialog';
import {ISPFlowgraphContext} from '../Flowgraph/Editor/ISPFlowgraphProvider';
import {openPublishForm} from '../../store/marketplace/configure';
import './ModifySPFormDrawer.scss';

const ModifySPFormDrawerBase = ({
  origData,
  open,
  dispatchViewCloseEditISP,
  dispatchIspDelete,
  dispatchOpenPublishForm,
  history,
  colorSpaceOptions,
  projectId,
}) => {
  const intl = useIntl();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [formData, setFormData] = useState(null);
  const {memData: data, setMemData, setIsDirty} = useContext(ISPFlowgraphContext);
  const params = useParams();

  useEffect(() => {
    data && setFormData(data.transformations);
  }, [data]);

  function onSubmit(values, {resetForm}) {
    const name = values.name;
    delete values.name;
    dispatchViewCloseEditISP();
    setMemData({
      ...data,
      name,
      transformations: {...data.transformations, input: values},
    });
    setIsDirty(true);
    resetForm();
  }

  const onDelete = () => {
    if (data.id && data.projectId) {
      dispatchIspDelete(data.id, data.projectId);
    }
    setOpenConfirm(false);
    dispatchViewCloseEditISP();
    history.push(`/project/${params.projectId}/upload`);
  };

  return (
    <StudioDrawer open={open} anchor="right">
      {!isEmpty(formData) && (
        <Formik
          initialValues={{
            name: data?.name,
            description: formData.description,
            bpp: formData.input?.bpp,
            input_color_space: formData.input?.input_color_space,
            output_color_space: formData.input?.output_color_space,
            input_width: formData.input?.input_width,
            input_height: formData.input?.input_height,
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          <Form className="modify-isp-form" data-testid="modify-isp-form">
            <ISPForm
              className="modify-isp-form__container"
              colorSpaceOptions={colorSpaceOptions}
            />
            <div className="modify-isp-form__buttons">
              <SubmitButton value={intl.formatMessage({id: 'form.update'})} />
              {/* TODO: Replace the action button with MUI counterpart */}
              <ModelActionButton
                customClass="delete-button"
                value="delete"
                nested
                type="button"
                onClick={() => setOpenConfirm(true)}
              />
              {data.id != null && (
                <ModelActionButton
                  value="share"
                  nested
                  type="button"
                  onClick={() =>
                    dispatchOpenPublishForm({
                      projectId,
                      resourceId: data.id,
                      purpose: 'ISP_STORAGE',
                    })
                  }
                />
              )}
            </div>
          </Form>
        </Formik>
      )}
      <ConfirmDialog
        type="confirm"
        title={intl.formatMessage({id: 'isp.deleteDialogTitle'})}
        message={intl.formatMessage({id: 'isp.deleteDialogContent'}, {name: data?.name})}
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onOk={onDelete}
        submitLabel={intl.formatMessage({id: 'form.delete'})}
      />
    </StudioDrawer>
  );
};

ModifySPFormDrawerBase.propTypes = {
  projectId: PropTypes.string,
};

ModifySPFormDrawerBase.defaultProps = {
  projectId: null,
};

const mapStateToProps = state => ({
  origData: getIspData(state),
  open: getIsEditIspFormOpen(state),
  colorSpaceOptions: getIspColorSpaceOptions(state),
});

const mapDispatchToProps = {
  dispatchViewOpenEditISP: viewOpenEditISP,
  dispatchViewCloseEditISP: viewCloseEditISP,
  dispatchIspUpdate: ispUpdateLocal,
  dispatchIspDelete: ispDelete,
  dispatchOpenPublishForm: openPublishForm,
};

export const ModifySPFormDrawer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ModifySPFormDrawerBase);
