import {
  MODEL_FETCH_DETAILS_SUCCESS,
  MODEL_FETCH_DETAILS_ERROR,
  MODEL_FETCH_DETAILS_PENDING,
  MODEL_UPDATE_MODELVIEWER,
  MODEL_TOGGLE_DETAILS_PANEL,
  MODEL_DETAILS_PANEL_CONTEXT,
} from './actions';

export const REQUEST_STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  ERROR: 'error',
};

const initialState = {
  status: REQUEST_STATUS.IDLE,
  data: null,
  error: null,
  isDetailsPanelOpen: false,
  detailsPanelContext: 'FLOATING_POINT',
  isViewerStale: false,
};

export const modelReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODEL_FETCH_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        status: REQUEST_STATUS.IDLE,
      };
    case MODEL_FETCH_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
        status: REQUEST_STATUS.ERROR,
      };
    case MODEL_FETCH_DETAILS_PENDING:
      return {
        ...state,
        status: REQUEST_STATUS.PENDING,
      };
    case MODEL_UPDATE_MODELVIEWER:
      return {
        ...state,
        isViewerStale: action.payload,
      };
    case MODEL_TOGGLE_DETAILS_PANEL:
      return {
        ...state,
        isDetailsPanelOpen: !state.isDetailsPanelOpen,
      };
    case MODEL_DETAILS_PANEL_CONTEXT:
      return {
        ...state,
        detailsPanelContext: action.payload,
      };
    default:
      return state;
  }
};
