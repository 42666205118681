import React, {Component} from 'react';
import PropTypes from 'prop-types';
import StageAnimation from '../StageAnimation/StageAnimation';
import Util from '../../../util';
import TaskInfoProps from '../../../model/taskInfoPropTypes';
import {toast} from '../../../base-components/StudioToast';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import axios from 'axios';
import URL from '../../../config/url';
import {StageAssistantForm} from '../../StageAssistantForm';
import {connect} from 'react-redux';
import {addListener, removeListener} from '../../../store/statusChecker';
import {viewDisableTermination, viewEnableTermination} from '../../../store/view';
import {projectFetch} from '../../../store/project';

class DataSplitAction extends Component {
  constructor(props) {
    super(props);

    this.stageAnimation = new StageAnimation();
    this.initiateDataSplit = this.initiateDataSplit.bind(this);
    this.handleDataSplitResponse = this.handleDataSplitResponse.bind(this);
    this.finishDataSplit = this.finishDataSplit.bind(this);
    this.cancelDataSplit = this.cancelDataSplit.bind(this);
    this.showAssistant = this.showAssistant.bind(this);
    this.getQuestionsForm = this.getQuestionsForm.bind(this);
    this.clarifyDataSplit = this.clarifyDataSplit.bind(this);
    this.handleDataSplitClarifyResponse = this.handleDataSplitClarifyResponse.bind(this);
    this.monitorStatus = this.monitorStatus.bind(this);
    this.processDataSplitProgress = this.processDataSplitProgress.bind(this);
    this.finalizeDataSplit = this.finalizeDataSplit.bind(this);
    this.terminateDataSplit = this.terminateDataSplit.bind(this);
  }

  componentDidMount() {
    if (this.props.readyForDataSplit) {
      this.props.setDatasetActionInProgress('split');
      this.initiateDataSplit();
    } else if (this.props.taskProgress && this.props.taskProgress.type === 'SPLIT_DATA') {
      this.props.setDatasetActionInProgress('split');
      if (this.props.taskProgress.prompts && this.props.taskProgress.prompts.length) {
        this.stageAnimation.shiftStages('ADD_DATA');
        this.showAssistant(this.props.taskProgress);
      } else {
        this.monitorStatus();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.readyForDataSplit !== prevProps.readyForDataSplit) {
      if (this.props.readyForDataSplit) {
        this.initiateDataSplit();
      }
    } else if (
      prevProps.taskProgress?.type !== 'SPLIT_DATA' &&
      this.props.taskProgress?.type === 'SPLIT_DATA'
    ) {
      this.props.setDatasetActionInProgress('split');
      if (this.props.taskProgress.prompts && this.props.taskProgress.prompts.length) {
        this.stageAnimation.shiftStages('ADD_DATA');
        this.showAssistant(this.props.taskProgress);
      } else {
        this.monitorStatus();
      }
    }
  }

  componentWillUnmount() {
    this.props.removeStatusListener('SPLIT_DATA');
  }

  render() {
    return null;
  }

  initiateDataSplit(caAnswers) {
    this.stageAnimation.markInProgress('ADD_DATA');
    if (Array.isArray(caAnswers)) {
      this.clarifyDataSplit(null, caAnswers);
    } else {
      axios
        .get(URL.DATASET_SPLIT, {
          params: {
            projectId: this.props.projectId,
          },
        })
        .then(response => this.handleDataSplitResponse(response.data))
        .catch(error => toast.error(`data split error: ${error.message}`));
    }
  }

  handleDataSplitResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.finishDataSplit();
    } else {
      this.showAssistant(response);
    }
  }

  async finishDataSplit() {
    if (this.props.onFinish) {
      // Splitting the dataset results in a new id, so refresh the project details.
      await this.props.projectFetch(this.props.projectId);
      this.props.onFinish('IMPORT_MODEL');
    }
  }

  cancelDataSplit() {
    if (this.props.onFinish) {
      this.props.onFinish('ADD_DATA');
    }
  }

  showAssistant(response) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.display(
        this.getQuestionsForm(response.prompts, response.defaults),
        'ADD_DATA',
        null,
        this.terminateDataSplit
      );
    }
  }

  getQuestionsForm(questions, defaults) {
    return (
      <StageAssistantForm
        prompts={questions}
        defaults={defaults}
        onSubmit={this.clarifyDataSplit}
        onCancel={this.terminateDataSplit}
        title="Select data split parameters"
        notifyType="SPLIT_DATA"
      />
    );
  }

  clarifyDataSplit(answersArray, caAnswers) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.hideStage('ADD_DATA');
    }
    const clarificationRequest = {
      projectId: this.props.projectId,
      answers: answersArray,
      caAnswers,
    };

    axios
      .post(URL.DATASET_SPLIT_CLARIFY, clarificationRequest, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => this.handleDataSplitClarifyResponse(response.data))
      .catch(error => toast.error(`data split error: ${error.message}`));
  }

  handleDataSplitClarifyResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.cancelDataSplit();
    } else if (!response.body && response.warnings && response.warnings.length > 0) {
      this.cancelDataSplit();
    } else if (response.prompts) {
      this.showAssistant(response);
    } else {
      this.monitorStatus();
    }
  }

  monitorStatus() {
    this.props.viewEnableTermination();
    this.stageAnimation.markInProgress('ADD_DATA');
    this.props.addStatusListener('SPLIT_DATA', this.processDataSplitProgress);
  }

  processDataSplitProgress(status) {
    DataSplitAction.displayDataSplitProgress(status);
    if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
      this.props.removeStatusListener('SPLIT_DATA');
      this.finalizeDataSplit();
    }
  }

  static displayDataSplitProgress(status) {
    let progressText = document.getElementById('add-data-action-progress');
    if (progressText) {
      progressText.innerText = status.statusMessage;
    }
  }

  finalizeDataSplit() {
    this.props.viewDisableTermination();
    axios
      .get(URL.DATASET_SPLIT_FINALIZE, {
        params: {
          projectId: this.props.projectId,
        },
      })
      .then(res => {
        if (res.data.body && !res.data.errors && !res.data.prompts) {
          this.finishDataSplit();
        } else if (res.data.prompts) {
          this.showAssistant(res);
        } else {
          this.cancelDataSplit();
        }
      })
      .catch(error => toast.error(`data split error: ${error.message}`));
  }

  terminateDataSplit() {
    this.props.viewDisableTermination();
    return axios
      .get(URL.OPERATION_CANCEL('SPLIT_DATA'), {
        params: {projectId: this.props.projectId},
      })
      .then(res => {
        this.props.assistantPanel.current.hideStage('ADD_DATA');
        this.props.removeStatusListener('SPLIT_DATA');
        this.cancelDataSplit();
      })
      .catch(error => toast.error(`cancel data split error: ${error.message}`));
  }
}

DataSplitAction.propTypes = {
  projectId: PropTypes.string.isRequired,
  assistantPanel: PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)})
    .isRequired,
  onFinish: PropTypes.func.isRequired,
  taskProgress: TaskInfoProps,
};

const mapDispatchToProps = {
  projectFetch,
  addStatusListener: addListener,
  removeStatusListener: removeListener,
  viewEnableTermination,
  viewDisableTermination,
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(
  DataSplitAction
);
