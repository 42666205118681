import React, {Component} from 'react';
import PropTypes from 'prop-types';

class AlertFIlledIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="18px"
          height="18px"
          viewBox="0 0 18 18"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Blaize-Studio---Icons" transform="translate(-847.000000, -989.000000)">
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 195.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-alert-fill-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M13,17 L13,15 L11,15 L11,17 L13,17 Z M13,13 L13,7 L11,7 L11,13 L13,13 Z"
                        id="alert-fill"
                        fill="currentColor"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

AlertFIlledIcon.propTypes = {
  className: PropTypes.string,
};

export default AlertFIlledIcon;
