import React, {useState, useEffect, useContext} from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ModuleButton from './ModuleButton';
import {Paper} from '@material-ui/core';
import {StudioTooltip} from '../../../base-components/StudioTooltip';
import {INFO_MESSAGES} from '../messages';
import {ISPFlowgraphContext} from '../Editor/ISPFlowgraphProvider';
import {Skeleton} from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import {groupBy} from 'lodash';
import {MODULE_MAP} from '../util';
import './ModuleList.scss';
import {FormattedMessage} from 'react-intl';

export const ModuleList = ({ispModules, handler, loading}) => {
  const {rawModules} = useContext(ISPFlowgraphContext);
  const [message, setMessage] = useState(null);
  const [groupedModules, setGroupedModules] = useState(null);

  useEffect(() => {
    setMessage(rawModules.length ? null : INFO_MESSAGES.NEW_GRAPH);
  }, [rawModules]);

  useEffect(() => {
    ispModules.length && setGroupedModules(groupBy(ispModules, 'module_category_code'));
  }, [ispModules]);

  return (
    <StudioTooltip placement="right-start" open={!!message} title={message}>
      <Paper
        className="flowgraph__module_container"
        elevation={2}
        data-testid="flow-graph-module-list"
      >
        {loading ? (
          <div className="flowgraph__loading_container">
            <Skeleton animation="wave" variant="rect" height={50} />
            <Skeleton animation="wave" variant="rect" height={50} />
          </div>
        ) : (
          <div className="flowgraph__module_list">
            {groupedModules ? (
              Object.keys(groupedModules).map(key => (
                <ExpansionPanel
                  key={'cat-' + key}
                  className="flowgraph__module_list_expansion"
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="flowgraph__module_list_summary"
                    data-testid={`flow-graph-module-list-${key}`}
                  >
                    <Typography>{MODULE_MAP[key].label}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div key={'cat-' + key} className="flowgraph__module_list_detail">
                      <Grid container spacing={1}>
                        {groupedModules[key].map((m, mi) => (
                          <Grid xs={12}>
                            <ModuleButton
                              key={'mb-' + mi}
                              buttonProps={{
                                name: m.module_display_name,
                                node: m,
                                handler: handler,
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))
            ) : (
              <Alert severity="info" variant="filled">
                <FormattedMessage id="isp.noModules" />
              </Alert>
            )}
          </div>
        )}
      </Paper>
    </StudioTooltip>
  );
};

ModuleList.propTypes = {
  ispModules: PropTypes.array,
  handler: PropTypes.func,
  loading: PropTypes.bool,
};

ModuleList.defaultProps = {
  ispModules: [],
  handler: () => {},
  loading: false,
};
