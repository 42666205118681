import axios from 'axios';
import {Application} from '../../types/project/Application';
import {Response} from '../../types/response/Response';

export type AssignPostProcessorRequest = {
  projectId: string;
  applicationId: string;
  postProcessorId: string;
};

export const ASSIGN_POST_PROCESSOR_URL = '/studio/application/postprocessor/assign';

export async function assignPostProcessor(args: [string, AssignPostProcessorRequest]) {
  const {data} = await axios.post<Response<Application>>(args[0], args[1]);
  return data.body;
}
