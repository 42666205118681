import {request} from '../request';
import {ProductionNode} from './DeploymentNodes';

export const deleteDeploymentNode = async (nodeId: string) => {
  return await request<ProductionNode>({
    method: 'DELETE',
    url: '/deployment/node',
    params: {
      nodeId,
    },
  });
};
