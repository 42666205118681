import {request} from '../request';

export type TestFinalizeRequest = {
  projectId: string;
};

export type TestFinalizeResponse = {};

export async function postTestFinalize(params: TestFinalizeRequest) {
  return request<TestFinalizeResponse>({
    method: 'post',
    url: '/gsp/test/finalize',
    params,
  });
}
