import React from 'react';
import BeforeImage1 from './images/edge_enhancement-before.png';
import AfterImage from './images/edge_enhancement-after.png';
import './Help.scss';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>Edge Enhancement</h4>
      <div className="flow-graph__help_content_detail">
        <p>Edge Enhancement is an image processing method that enhances the edge contrast of  an image to improve its sharpness. It is also called sharpening. The ideal amount of edge enhancement is required to produce a pleasant and sharp-looking image, without losing too much detail.</p>
        <p>There are many sharpening techniques using low pass and high pass filters. The most successful and easy method of edge enhancement is unsharp masking technique.</p>
        <p>This technique comprises of the following steps:</p>
        <ol>
          <li>Input original image is to be blurred first with a Gaussian Blur filter , which results in a blurred image.</li>
          <li>The blurred image is then subtracted from the original input image to detect edges and the result will be the Mask(Unsharp ), which focuses only the edges.</li>
          <li>This obtained unsharp filter or unsharp image is added back to the original image so that edges will be enhanced, and final sharpened image will be produced.</li>
        </ol>
        <div className="help_images">
          <figure>
            <img src={BeforeImage1} alt="before" />
            <figcaption>Input</figcaption>
          </figure>
          <figure>
            <img src={AfterImage} alt="after" />
            <figcaption>Output</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};
