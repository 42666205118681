import React from 'react';
import toaster from 'toasted-notes';
import AlertOutlineIcon from '../../assets/icons/AlertOutlineIcon';
import AlertFIlledIcon from '../../assets/icons/AlertFIlledIcon';
import WarningFillIcon from '../../assets/icons/WarningFillIcon';
import CheckCircleIcon from '../../assets/icons/CheckCircleIcon';
import './index.scss';

/*
  Mocks
  https://argodesign.invisionapp.com/share/BKU9ILSZVN8#/screens/390524587

  Usage for callbacks
  const onClose = () => console.log('t=test');
  
  toaster.error({
    title: 'System Error', 
    subtitle: 'Something went wrong.', 
    actions: [
      {label: 'Test', callback: onClose}, 
      {label: 'Test2', callback: onClose}
    ]})
  
  // Shortcut usage for quick notification message
  toaster.error('Something went wrong');

*/
// https://sancho-ui.com/components/toast/
// title: The title of the notification.
// subtitle: The subtitle of the notification.
// duration: How long the toast remains visible in ms. Set to null for it to remain open indefinitely.
// position: The position where the toast will appears. Can be top-left, top, top-right, bottom-left, bottom, bottom-right.
// intent: Accepts the same intent options as Alert.

const CONFIG = {
  position: 'bottom-right',
  duration: 5000,
};

function renderIcon(param) {
  switch (param) {
    case 'error':
      return <AlertFIlledIcon />;
    case 'success':
      return <CheckCircleIcon />;
    case 'warn':
      return <WarningFillIcon />;
    default:
      return <AlertOutlineIcon />;
  }
}

function message(opt) {
  const renderActions = param => {
    return (
      <span className="toaster-action">
        {param.map((action, i) => {
          return (
            <button key={i} className="form-button primary" onClick={action.callback}>
              {action.label}
            </button>
          );
        })}
      </span>
    );
  };

  return props => (
    <div
      className={`toaster icon toaster-${opt.intent}`}
      onClick={() => props.onClose()}
      data-testid="toast"
      id="toast"
    >
      {renderIcon(opt.intent)}
      {opt.title && (
        <span>
          <strong>{opt.title}</strong>
        </span>
      )}
      <span>{opt.subtitle}</span>
      {opt.actions && renderActions(opt.actions)}
    </div>
  );
}

function _toaster(options) {
  const {delayIfPageHidden, ...rest} = options;
  const opt = {
    ...CONFIG,
    ...rest,
  };
  if (delayIfPageHidden && document.visibilityState === 'hidden') {
    document.addEventListener(
      'visibilitychange',
      () => {
        if (document.visibilityState === 'visible') {
          toaster.notify(message.call(null, opt), opt);
        }
      },
      {once: true}
    );
  } else {
    toaster.notify(message.call(null, opt), opt);
  }
}

function validateOptions(val) {
  if (val) {
    return typeof val === 'string' ? {subtitle: val} : val;
  }
  return {};
}

// Shortcuts
const info = opt => _toaster({intent: 'info', ...validateOptions(opt)});
const error = opt =>
  _toaster({
    intent: 'error',
    title: 'Error!',
    duration: 30000,
    delayIfPageHidden: true,
    ...validateOptions(opt),
  });
const warn = opt => _toaster({intent: 'warn', title: 'Warning', ...validateOptions(opt)});
const success = opt =>
  _toaster({intent: 'success', title: 'Success!', ...validateOptions(opt)});

export const toast = {
  info: info,
  error: error,
  warn: warn,
  success: success,
};
