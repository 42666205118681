import React, {useState} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import ERROR from '../../../config/error';
import {StudioFormikSelect} from '../../Formik/StudioFormikSelect';
import FormButton from '../../../base-components/StudioButton/FormButton';
import URL from '../../../config/url';
import {formikErrors} from '../../Formik/formikErrors';
import {FormattedMessage, useIntl} from 'react-intl';
import axios from 'axios';
import {toast} from '../../../base-components/StudioToast';

const deleteApplication = ({applicationId, projectId, onSuccess, onFinish}) => {
  axios
    .post(
      URL.APPLICATION_DELETE({
        applicationId,
        projectId,
      })
    )
    .then(value => onSuccess())
    .catch(error => toast.error(`Delete application error: ${error.message}`))
    .finally(() => onFinish());
};
const validationSchema = Yup.object({
  applicationId: Yup.string().required(ERROR.FIELD_REQUIRED()),
});

export const DeleteApplicationDialog = ({
  applicationNames,
  onClose,
  isOpen,
  projectId,
  applicationId,
  onSuccess,
}) => {
  const intl = useIntl();

  const [deleting, setDeleting] = useState(false);

  const onFinish = () => {
    onClose();
    setDeleting(false);
  };

  const initialValues = {
    applicationId,
    projectId,
    onSuccess,
    onFinish,
  };

  return (
    <Dialog open={isOpen} data-testid="delete-app-dialog">
      <Formik
        initialValues={initialValues}
        initialErrors={formikErrors(validationSchema, initialValues)}
        onSubmit={args => {
          setDeleting(true);
          deleteApplication(args);
        }}
        validationSchema={validationSchema}
      >
        {({isValid}) => (
          <Form>
            <DialogTitle className="dplmt-delete-app-title">
              <div className="dplmt-delete-app-title-top">
                <div className="dplmt-delete-app-title-text">
                  <Typography color="primary" variant="h6">
                    <FormattedMessage id="test.deleteApplication" />
                  </Typography>
                </div>
                <IconButton
                  onClick={onClose}
                  className="dplmt-delete-app-title-text"
                  data-testid="delete-app-close-button"
                  disabled={deleting}
                >
                  <Close />
                </IconButton>
              </div>
              <DialogContentText>
                <FormattedMessage id="test.form.selectApplicationToDelete" />
              </DialogContentText>
            </DialogTitle>
            <DialogContent>
              <Grid container justifyContent="center">
                <Grid item className="dplmt-delete-app-form">
                  <StudioFormikSelect
                    name="applicationId"
                    label={intl.formatMessage({id: 'test.form.availableApplications'})}
                    placeholder={intl.formatMessage({
                      id: Object.keys(applicationNames).length
                        ? 'test.form.selectApplication'
                        : 'test.form.noApplicationsAvailable',
                    })}
                    disablePlaceholder
                    className="dplmt-delete-app-field"
                    options={applicationNames}
                    disabled={deleting}
                  />
                  <Field name="projectId" type="hidden" />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <FormButton
                type="submit"
                buttonRole="primary"
                value={intl.formatMessage({
                  id: deleting ? 'test.form.deleting' : 'form.delete',
                })}
                disabled={deleting || !isValid}
                dataTestid="delete-app-confirm-button"
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
