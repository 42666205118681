import React, {useMemo} from 'react';
import {
  getProjectPostProcessorTemplate,
  getProjectPostProcessorTemplateUrl,
} from '../../../api/postprocessor/GetNewPostProcessor';
import {useHistory, useLocation, useParams} from 'react-router';
import useSWR from 'swr';
import {updatePostProcessor} from '../../../api/postprocessor/UpdatePostProcessor';
import {toast} from '../../../base-components/StudioToast';
import {useIntl} from 'react-intl';
import {PostProcessingForm} from './PostProcessingForm';
import {
  assignPostProcessor,
  ASSIGN_POST_PROCESSOR_URL,
} from '../../../api/postprocessor/AssignPostProcessor';
import {UpdatePostProcessorRequest} from '../../../types/postprocessor/UpdatePostProcessorRequest';
import {ApplicationType} from '../../../types/model/framework/ApplicationType';

type ReturnToOptions = 'spaCreate' | undefined;

export const CreatePostProcessor = () => {
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const returnTo = search.get('returnTo') as ReturnToOptions;
  const useDefault = search.has('useDefault');
  const appType = search.has('appType')
    ? (search.get('appType') as ApplicationType)
    : undefined;
  const assignToApplication = search.has('assignToApplication');
  const applicationId = search.get('applicationId');
  const exposeAPI = search.get('exposeAPI');
  const exposeInference = search.get('exposeInference');

  const {projectId} = useParams<{projectId: string}>();
  const cacheKey = getProjectPostProcessorTemplateUrl(projectId);
  const {data} = useSWR(cacheKey, () => getProjectPostProcessorTemplate({projectId}), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const intl = useIntl();
  const history = useHistory();

  const returnToPreviousPage = () => {
    if (returnTo === 'spaCreate' && applicationId) {
      history.push(
        `/project/${projectId}/inference/${applicationId}/create?exposeAPI=${exposeAPI}&exposeInference=${exposeInference}`
      );
    } else {
      history.push(`/project/${projectId}/upload`);
    }
  };

  const getReturnText = () => {
    switch (returnTo) {
      case 'spaCreate':
        return intl.formatMessage({id: 'form.returnToConfigure'});
      default:
        return undefined;
    }
  };

  const handleSubmit = async (values: UpdatePostProcessorRequest) => {
    try {
      const resp = await updatePostProcessor(values);
      if (!resp.errors?.length) {
        if (assignToApplication && applicationId) {
          await assignPostProcessor([
            ASSIGN_POST_PROCESSOR_URL,
            {projectId, applicationId, postProcessorId: resp.body.id},
          ]);
        }
        toast.success(intl.formatMessage({id: 'createPostProcessor.success'}));
        returnToPreviousPage();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <PostProcessingForm
      title={intl.formatMessage({id: 'createPostProcessor.header'})}
      returnText={getReturnText()}
      projectId={projectId}
      template={
        applicationId && data?.body
          ? {
              ...data?.body,
              applicationType: appType,
              postProcessorType: 'APPLICATION',
              executionContainer: 'BROWSER',
              useDefaultFile: useDefault,
            }
          : data?.body
      }
      onSubmit={handleSubmit}
      onCancel={returnToPreviousPage}
    />
  );
};
