import PropTypes from 'prop-types';
import StatisticsInfoProps from './statisticsInfoPropTypes';

export const StageProps = PropTypes.shape({
  type: PropTypes.string.isRequired,
  displayText: PropTypes.string.isRequired,
  lockReason: PropTypes.string,
  status: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  statistics: StatisticsInfoProps,
  entityName: PropTypes.string,
  progress: PropTypes.any,
});
