import axios from 'axios';
import {useEffect, useRef} from 'react';
import URL from '../../../config/url';
import {matchPath, useLocation} from 'react-router';
import {PrivateRoutes} from '../../../config/routes';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {IntegrationPurpose} from '../../../types/integrations/IntegrationResponse';

type ConversationNotifierProps = {
  projectId?: string;
  pathname: string;
};

const NOTIFICATION_ROUTES = [
  PrivateRoutes.DATAPREPARE,
  PrivateRoutes.MARKETPLACE,
  PrivateRoutes.FLOWGRAPH,
  PrivateRoutes.TEST,
  PrivateRoutes.DEPLOY,
  PrivateRoutes.DEPLOY_INFERENCE_APP,
  PrivateRoutes.DEPLOY_ALL,
  PrivateRoutes.DEPLOY_DETAILS,
  PrivateRoutes.DASHBOARD_DEPLOY,
  PrivateRoutes.DASHBOARD_DEPLOY_ALL,
];

const idsPerRoute = {
  [PrivateRoutes.DATAPREPARE]: ['DATAPREP_OPEN', 'DATAPREP_CLOSE'],
  [PrivateRoutes.MARKETPLACE]: ['MARKETPLACE_OPEN', 'MARKETPLACE_CLOSE'],
  [PrivateRoutes.FLOWGRAPH]: ['ISP_OPEN', 'ISP_CLOSE'],
  [PrivateRoutes.TEST]: ['TEST_PAGE_OPEN', 'TEST_PAGE_CLOSE'],
  [PrivateRoutes.DEPLOY]: ['DEPLOY_PAGE_OPEN', 'DEPLOY_PAGE_CLOSE'],
  [PrivateRoutes.DEPLOY_INFERENCE_APP]: ['DEPLOY_PAGE_OPEN', 'DEPLOY_PAGE_CLOSE'],
  [PrivateRoutes.DEPLOY_ALL]: [
    'DEPLOY_DASHBOARD_PAGE_OPEN',
    'DEPLOY_DASHBOARD_PAGE_CLOSE',
  ],
  [PrivateRoutes.DEPLOY_DETAILS]: [
    'DEPLOY_DETAILS_PAGE_OPEN',
    'DEPLOY_DETAILS_PAGE_CLOSE',
  ],
  [PrivateRoutes.DASHBOARD_DEPLOY]: ['DEPLOY_PAGE_OPEN', 'DEPLOY_PAGE_CLOSE'],
  [PrivateRoutes.DASHBOARD_DEPLOY_ALL]: [
    'DEPLOY_DASHBOARD_PAGE_OPEN',
    'DEPLOY_DASHBOARD_PAGE_CLOSE',
  ],
};

const sendNotification = (navigationId: string, projectId?: string) => {
  axios.post(URL.CA_NOTIFY, {
    projectId,
    navigationId,
  });
};

export const ConversationNotifier = ({
  projectId,
  pathname,
}: ConversationNotifierProps) => {
  const location = useLocation();

  const isShareModalOpen = useSelector(
    (state: RootState) => state.configureMarketplace.share.isOpen
  );
  const isShareModalCanceled = useSelector(
    (state: RootState) => state.configureMarketplace.share.isCancelled
  );
  const shareModalPurpose = useSelector(
    (state: RootState) => state.configureMarketplace.share.purpose
  );

  const isStagePromptsOpen = useSelector(
    (state: RootState) => state.stages.prompts.isOpen
  );
  const isStagePromptsCancelled = useSelector(
    (state: RootState) => state.stages.prompts.isCancelled
  );
  const stageNotifyType = useSelector(
    (state: RootState) => state.stages.prompts.notifyType
  );

  const isAddDataDialogOpen = useSelector(
    (state: RootState) => state.addDataDialog.isOpen
  );
  const isAddDataDialogCancelled = useSelector(
    (state: RootState) => state.addDataDialog.isCancelled
  );

  const isAddModelDialogOpen = useSelector(
    (state: RootState) => state.addModelDialog.isOpen
  );
  const isAddModelDialogCancelled = useSelector(
    (state: RootState) => state.addModelDialog.isCancelled
  );

  const matchedRoute = NOTIFICATION_ROUTES.find(route => matchPath(pathname, route));

  useEffect(
    function addDataDialogNotify() {
      if (isAddDataDialogOpen) {
        sendNotification('ADD_MARKETPLACE_DATA_OPEN', projectIdRef.current);
      } else {
        if (isAddDataDialogCancelled) {
          sendNotification('ADD_MARKETPLACE_DATA_CLOSE', projectIdRef.current);
        }
      }
    },
    [isAddDataDialogOpen, isAddDataDialogCancelled]
  );

  // We need to use projectId in effects, but don't want to trigger effects
  // because the projectId changed. Hence, store in a ref.
  const projectIdRef = useRef(projectId);

  useEffect(
    function addModelDialogNotify() {
      if (isAddModelDialogOpen) {
        sendNotification('ADD_MARKETPLACE_MODEL_OPEN', projectIdRef.current);
      } else {
        if (isAddModelDialogCancelled) {
          sendNotification('ADD_MARKETPLACE_MODEL_CLOSE', projectIdRef.current);
        }
      }
    },
    [isAddModelDialogOpen, isAddModelDialogCancelled]
  );

  useEffect(
    function stagePromptsNotify() {
      if (isStagePromptsOpen) {
        sendNotification(`${stageNotifyType}_PROMPTS_OPEN`, projectIdRef.current);
      } else {
        if (isStagePromptsCancelled) {
          sendNotification(`${stageNotifyType}_PROMPTS_CLOSE`, projectIdRef.current);
        }
      }
    },
    [isStagePromptsOpen, isStagePromptsCancelled, stageNotifyType]
  );

  useEffect(
    function shareModalNotify() {
      const purposeToIdMap: {[k in IntegrationPurpose]: string} = {
        DATASET_STORAGE: 'PUBLISH_DATASET',
        MODEL_STORAGE: 'PUBLISH_MODEL',
        ISP_STORAGE: 'PUBLISH_ISP',
        PP_STORAGE: 'PUBLISH_POST_PROCESSOR',
        SOLUTION_STORAGE: 'PUBLISH_SOLUTION',
      };

      if (isShareModalOpen) {
        sendNotification(
          `${purposeToIdMap[shareModalPurpose]}_OPEN`,
          projectIdRef.current
        );
      } else {
        if (isShareModalCanceled) {
          sendNotification(
            `${purposeToIdMap[shareModalPurpose]}_CLOSE`,
            projectIdRef.current
          );
        }
      }
    },
    [isShareModalOpen, isShareModalCanceled, shareModalPurpose]
  );

  useEffect(
    function userGuideNotify() {
      if (matchPath(location.pathname, PrivateRoutes.HELP)) {
        sendNotification('USER_GUIDE_OPEN', projectId);
        return () => sendNotification('USER_GUIDE_CLOSE', projectId);
      }
    },
    [location.pathname, projectId]
  );

  useEffect(
    function navigationNotify() {
      const ids = matchedRoute ? idsPerRoute[matchedRoute] : null;
      if (ids) {
        const [openId, closeId] = ids;
        sendNotification(openId, projectId);
        return () => sendNotification(closeId, projectId);
      }
    },
    [matchedRoute, projectId]
  );

  useEffect(
    function updateProjectIdRef() {
      projectIdRef.current = projectId;
    },
    [projectId]
  );

  return null;
};
