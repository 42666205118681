import React, {Component} from 'react';
import PropTypes from 'prop-types';

class SearchIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="18px"
          height="18px"
          viewBox="0 0 18 18"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <path
              d="M15.7734375,14.015625 L20.7421875,18.984375 L19.2421875,20.484375 L14.2734375,15.515625 L14.2734375,14.71875 L13.9921875,14.4375 C12.7734314,15.4687552 11.3671955,15.984375 9.7734375,15.984375 C7.96092844,15.984375 6.42188133,15.3515688 5.15625,14.0859375 C3.89061867,12.8203062 3.2578125,11.2890715 3.2578125,9.4921875 C3.2578125,7.69530352 3.89061867,6.16406883 5.15625,4.8984375 C6.42188133,3.63280617 7.95311602,3 9.75,3 C11.546884,3 13.0781187,3.63280617 14.34375,4.8984375 C15.6093813,6.16406883 16.2421875,7.70311594 16.2421875,9.515625 C16.2421875,11.109383 15.7265677,12.5156189 14.6953125,13.734375 L14.9765625,14.015625 L15.7734375,14.015625 Z M6.5859375,12.703125 C7.46094187,13.5781294 8.52343125,14.015625 9.7734375,14.015625 C11.0234438,14.015625 12.0859331,13.5781294 12.9609375,12.703125 C13.8359419,11.8281206 14.2734375,10.7656312 14.2734375,9.515625 C14.2734375,8.26561875 13.8359419,7.20312937 12.9609375,6.328125 C12.0859331,5.45312063 11.0234438,5.015625 9.7734375,5.015625 C8.52343125,5.015625 7.46094187,5.45312063 6.5859375,6.328125 C5.71093313,7.20312937 5.2734375,8.26561875 5.2734375,9.515625 C5.2734375,10.7656312 5.71093313,11.8281206 6.5859375,12.703125 Z"
              id="searchIcon"
            ></path>
          </defs>
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-847.000000, -5171.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 4377.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-search-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#searchIcon"></use>
                      </mask>
                      <use
                        id="searchIcon"
                        fill="currentColor"
                        xlinkHref="#searchIcon"
                      ></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

SearchIcon.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
};

export default SearchIcon;
