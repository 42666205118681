import React, {useEffect, RefObject, useState} from 'react';
import {useSelector} from 'react-redux';
import {cancelPostProcessorImport} from '../../../api/postprocessor/CancelPostProcessorImport';
import {getStagesData} from '../../../store/stages';
import {PPUploadActionBase} from '../../StageView/StageActions/PPUploadAction';
import {AutoDetectDialog} from './AutoDetectDialog';
import {StagesState} from '../../../types/stage/StagesState';
import {usePostProcessorList} from '../../../api/postprocessor/GetPostProcessorList';
import {getProjectId} from '../../../config/routes';
import {useLocation} from 'react-router';
import {PostProcessorType} from '../../../types/postprocessor/PostProcessorType';

export type AutoDetectSpecificPostProcessorProps = {
  actionRef?: RefObject<InstanceType<typeof PPUploadActionBase>>;
};

export type AutoDetectPostProcessorProps = {
  postProcessorType: PostProcessorType;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  actionRef?: RefObject<InstanceType<typeof PPUploadActionBase>>;
};

export const AutoDetectModelPostProcessor = ({
  actionRef,
}: AutoDetectSpecificPostProcessorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const stages: StagesState = useSelector(getStagesData);
  const {readyForImportModelPostProcessors} = stages;

  useEffect(() => {
    if (readyForImportModelPostProcessors) {
      setIsOpen(true);
    }
  }, [readyForImportModelPostProcessors]);

  return (
    <AutoDetectPostProcessor
      postProcessorType={'MODEL'}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      actionRef={actionRef}
    />
  );
};

export const AutoDetectAppPostProcessor = ({
  actionRef,
}: AutoDetectSpecificPostProcessorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const stages: StagesState = useSelector(getStagesData);
  const {readyForImportAppPostProcessors} = stages;

  useEffect(() => {
    if (readyForImportAppPostProcessors) {
      setIsOpen(true);
    }
  }, [readyForImportAppPostProcessors]);

  return (
    <AutoDetectPostProcessor
      postProcessorType={'APPLICATION'}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      actionRef={actionRef}
    />
  );
};

const AutoDetectPostProcessor = ({
  postProcessorType,
  isOpen,
  setIsOpen,
  actionRef,
}: AutoDetectPostProcessorProps) => {
  const location = useLocation();
  const projectId = getProjectId(location.pathname);
  const {data: ppListData} = usePostProcessorList({projectId});

  return (
    <AutoDetectDialog
      isOpen={isOpen}
      title={`Add Compatible ${capFirst(postProcessorType)} Post Processors"`}
      purpose="PP_STORAGE"
      postProcessorType={postProcessorType}
      columns={[
        'displayName',
        'postProcessorType',
        'applicationType',
        'executionContainer',
        'provider',
        'creationDateTime',
        'size',
        'studioVersion',
      ]}
      onClose={async (projectId: string) => {
        setIsOpen(false);
        try {
          await cancelPostProcessorImport(projectId, postProcessorType);
        } catch (e) {
          console.error(e);
        }
      }}
      onSubmit={files => {
        actionRef?.current?.ppUpload(files);
      }}
      excludedRefs={ppListData?.existingPostProcessors?.map(pp => pp.name)}
    />
  );
};

function capFirst(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
