import React, {useEffect, useState} from 'react';
import {Prompt} from 'react-router-dom';
import {Location} from 'history';
import {ConfirmDialog} from '../StudioConfirmDialog';

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  confirmLabel: string;
  title: string;
  message: string;
}
const StudioRouteLeavingGuardProps = ({
  when,
  navigate,
  shouldBlockNavigation,
  title,
  message,
  confirmLabel,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmDialog
        type="confirm"
        open={modalVisible}
        title={title}
        message={message}
        onClose={closeModal}
        onOk={handleConfirmNavigationClick}
        submitLabel={confirmLabel}
      />
    </>
  );
};
export default StudioRouteLeavingGuardProps;
