import Util from '../../../util';
import StageStep from '../StageStep/StageStep';

const ITEM_WIDTH = 90;
const MIN_WIDTH = 5;
const MIN_INTERVAL = ITEM_WIDTH + 2 * MIN_WIDTH;
const MAX_INTERVAL = 420;

export default class StageAnimation {
  refreshStage(stage) {
    if (stage.status === 'FINISHED') {
      this.markFinished(stage.type);
    } else if (stage.status === 'IN_PROGRESS') {
      this.markInProgress(stage.type);
    } else if (stage.status === 'LOCKED') {
      this.markLocked(stage.type);
    } else if (stage.status === 'INACTIVE' || stage.active === true) {
      this.markActiveOrInactive(stage.type);
    }
  }

  refreshStages(newStages) {
    newStages.forEach(stage => {
      this.refreshStage(stage);
    });
  }

  refreshAndShiftStages(newStages, activeStage) {
    this.refreshStages(newStages);
    this.shiftStages(activeStage);
  }

  shiftStages(activeStage) {
    let elem = document.getElementById(activeStage + '-container');
    if (elem && !elem.classList.contains('active')) {
      this.updateStages(elem);
    }
  }

  markActiveOrInactive(stageType) {
    this.activateButton(stageType, '-container');
    const panelClass = this.isStageActive(stageType, '-container')
      ? 'active'
      : 'inactive';
    this.markButtonAs(stageType, '-button', 'section-button-inactive');
    this.markPanelAs(stageType, '-panel-content', panelClass);
    this.markPanelAs(stageType, '-panel-outline', panelClass);
    this.markPanelAs(stageType, '-panel-overlay', panelClass);
  }

  markLocked(stageType) {
    this.markButton(stageType, 'section-button-locked', 'locked');
  }

  markInProgress(stageType) {
    this.markButton(stageType, 'section-button-in-progress', 'in-progress');
  }

  markFinished(stageType) {
    this.markButton(stageType, 'section-button-finished', 'finished');
  }

  markButton(stageType, buttonClass, panelClass) {
    this.activateButton(stageType, '-container');
    this.markButtonAs(stageType, '-button', buttonClass);
    this.markPanelAs(stageType, '-panel-content', panelClass);
    this.markPanelAs(stageType, '-panel-outline', panelClass);
    this.markPanelAs(stageType, '-panel-overlay', panelClass);
  }

  isStageActive(stageType, postfix) {
    const container = document.getElementById(stageType + postfix);
    return container !== null && container.classList.contains('active');
  }

  activateButton(stageType, postfix) {
    const container = document.getElementById(stageType + postfix);
    if (container && !container.classList.contains('step-active-cursor')) {
      container.classList.add('step-active-cursor');
      container.onclick = StageStep.activateStage;
    }
  }

  markButtonAs(stageType, postfix, newStyleClass) {
    const button = document.getElementById(stageType + postfix);
    if (button && !button.classList.contains(newStyleClass)) {
      button.classList.remove('section-button-inactive');
      button.classList.remove('section-button-in-progress');
      button.classList.remove('section-button-finished');
      button.classList.remove('section-button-locked');
      button.classList.add(newStyleClass);
    }
  }

  markPanelAs(stageType, postfix, newStyleClass) {
    const panel = document.getElementById(stageType + postfix);
    if (panel && !panel.classList.contains(newStyleClass)) {
      panel.classList.remove('active');
      panel.classList.remove('inactive');
      panel.classList.remove('in-progress');
      panel.classList.remove('finished');
      panel.classList.remove('locked');
      panel.classList.add(newStyleClass);
    }
  }

  updateStages(section) {
    const sections = Util.getSiblings(section);
    const sectionsCount = sections.length;
    const activeElementIndex = this.getActiveElementIndex(section, sections);
    const intervals = this.getIntervals(section, sections, activeElementIndex);

    let offsetRight = 0;
    for (let i = 0; i < sectionsCount; ++i) {
      sections[i].classList.remove('active');
      sections[i].style.minWidth = intervals[i] + 'px';
      if (i >= activeElementIndex) {
        offsetRight += intervals[i];
      }
    }
    section.classList.add('active');

    let viewport = this.adjustContainerSize(section, sectionsCount);

    let stageListContainer = document.getElementById('stage-list-container');
    if (stageListContainer) {
      let offset = viewport - offsetRight - activeElementIndex * MAX_INTERVAL;

      stageListContainer.style.marginLeft = offset + 'px';
      let panel = document.getElementById('ADD_DATA-panel');
      if (!panel) {
        panel = document.getElementById('BLZ_BAYER_MODULES-panel');
      }
      if (panel) {
        let panels = this.getChildren(panel, '-content');
        let outlines = this.getChildren(panel, '-outline');
        let overlays = this.getChildren(panel, '-overlay');

        for (let i = 0; i < sectionsCount; ++i) {
          this.switchActivePanel(
            panels[i],
            outlines[i],
            overlays[i],
            'active',
            'inactive'
          );
          this.switchActivePanel(
            panels[i],
            outlines[i],
            overlays[i],
            'focused',
            'unfocused'
          );
        }
        this.switchActivePanel(
          panels[activeElementIndex],
          outlines[activeElementIndex],
          overlays[activeElementIndex],
          'inactive',
          'active'
        );
        this.switchActivePanel(
          panels[activeElementIndex],
          outlines[activeElementIndex],
          overlays[activeElementIndex],
          'unfocused',
          'focused'
        );
      }
    }
  }

  switchActivePanel(panel, outline, overlay, classToRemove, classToAdd) {
    if (panel.classList.contains(classToRemove)) {
      panel.classList.remove(classToRemove);
      outline.classList.remove(classToRemove);
      overlay.classList.remove(classToRemove);
      panel.classList.add(classToAdd);
      outline.classList.add(classToAdd);
      overlay.classList.add(classToAdd);
    } else {
      overlay.classList.remove(classToRemove);
      overlay.classList.add(classToAdd);
    }
  }

  getChildren(element, postfix) {
    return Util.getSiblings(element)
      .map(panel => document.getElementById(panel.id + postfix))
      .filter(this.notNull);
  }

  notNull(value) {
    return value !== null;
  }

  getIntervals(section, sections, activeElementIndex) {
    const sectionsCount = sections.length;

    let viewport = this.adjustContainerSize(section, sectionsCount);

    // maxInterval can shrink if window size is quite small, but limited by MIN_INTERVAL constant
    let maxInterval = Math.min(
      viewport - MIN_INTERVAL * (sectionsCount - 1),
      MAX_INTERVAL
    );
    maxInterval = Math.max(maxInterval, MIN_INTERVAL);

    const intervals = new Array(sectionsCount);

    // initialize intervals with minimum size, and Active element's interval with maximum size
    intervals.fill(MIN_INTERVAL);
    intervals[activeElementIndex] = maxInterval;

    // available space
    let space = viewport / 2 + maxInterval / 2;

    // occupied space
    let requiredSpace =
      maxInterval + (sectionsCount - activeElementIndex - 1) * MIN_INTERVAL;

    // going right from the Active element, try to enlarge intervals one by one.
    // if there's enough room, go next. If not, adjust the element so that available space = occupied space
    // For small screens: Minimum size: MIN_INTERVAL. As size can't be smaller, the leftmost element is shifted out of the screen
    for (let i = activeElementIndex + 1; i < sectionsCount; ++i) {
      if (space > requiredSpace) {
        requiredSpace += maxInterval - MIN_INTERVAL;
        intervals[i] = maxInterval;
      }
      if (space < requiredSpace) {
        intervals[i] -= requiredSpace - space;
        intervals[i] = Math.max(intervals[i], MIN_INTERVAL);
        break;
      }
    }

    // If there's still empty space at the right, adjust the rightmost element size so that available space = occupied space
    if (space > requiredSpace) {
      intervals[sectionsCount - 1] += space - requiredSpace;
    }

    if (space < requiredSpace) {
      // to let components go out of the screen if that screen is tiny
      space = viewport - requiredSpace;
    } else {
      space = viewport / 2 - maxInterval / 2;
    }

    requiredSpace = activeElementIndex * MIN_INTERVAL;

    // going left from the Active element, try to enlarge intervals one by one, same way
    for (let i = activeElementIndex - 1; i >= 0; --i) {
      if (space > requiredSpace) {
        requiredSpace += maxInterval - MIN_INTERVAL;
        intervals[i] = maxInterval;
      }
      if (space < requiredSpace) {
        intervals[i] -= requiredSpace - space;
        intervals[i] = Math.max(intervals[i], MIN_INTERVAL);
        break;
      }
    }

    return intervals;
  }

  getActiveElementIndex(section, elements) {
    let i;
    for (i = 0; i < elements.length; ++i) {
      if (elements[i] === section) {
        return i;
      }
    }
    return -1;
  }

  adjustContainerSize(section, sectionsCount) {
    let parentElement = section.parentElement;
    if (parentElement == null) {
      return 0;
    }
    parentElement.style.minWidth = sectionsCount * MIN_INTERVAL + 'px';

    return parentElement.clientWidth;
  }
}
