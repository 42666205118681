import {Button} from '@material-ui/core';
import cn from 'classnames';
import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import './DataViewerLabel.scss';

export type DataViewerLabelProps = {
  className?: string;
  count: number;
  label: string;
  onClick?(label: string): void;
};

export const DataViewerLabel = ({
  className,
  count,
  label,
  onClick,
}: DataViewerLabelProps) => (
  <div className={cn('data-viewer-label', className)}>
    <Button onClick={() => onClick?.(label)} disabled={!Boolean(onClick)}>
      <div className="data-viewer-label__title">
        <span className="dataset-label__label">
          {label || <FormattedMessage id="dataViewer.unlabeled" />}
        </span>{' '}
        ({count} <FormattedMessage id="dataViewer.files" />)
      </div>
    </Button>
  </div>
);
