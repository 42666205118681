import React from 'react';
import {PropTypes} from 'prop-types';
import {Grid} from '@material-ui/core';
import './ImageViewerHeader.scss';

export const ImageViewerHeader = ({left, right}) => {
  return (
    <Grid container className="image-viewer-header">
      <Grid item className="image-viewer-header__left" xs={12} sm={6}>
        {left}
      </Grid>
      <Grid item className="image-viewer-header__right" xs={12} sm={6}>
        {right}
      </Grid>
    </Grid>
  );
};

ImageViewerHeader.propTypes = {
  left: PropTypes.any,
  right: PropTypes.any,
};

ImageViewerHeader.defaultProps = {
  left: undefined,
  right: undefined,
};
