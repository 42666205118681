import React from 'react';
import {useField} from 'formik';
import {DynamicFormBody} from '../DynamicForm';
import {validateField} from '../DynamicForm.helpers';
import {useDynamicFormField} from '../DynamicFormToFormikAdapter';
import {StudioRadioGroup} from '../../../base-components/StudioRadio';

export const DynamicRadioGroup = ({
  label,
  options,
  optionsHelpText,
  order,
  subQuestions,
  name,
  className,
  required,
  tooltips,
  level,
  disabledOptions,
}) => {
  useDynamicFormField(name);

  const [field, meta] = useField({
    name,
    validate: value => validateField(value, 'STRING', required),
  });

  if (field.value === undefined) {
    return null;
  }

  const errorText = meta.initialError || (meta.error && meta.touched) ? meta.error : '';

  return (
    <>
      <StudioRadioGroup
        label={label}
        options={options}
        optionsHelpText={optionsHelpText}
        order={order}
        name={name}
        className={className}
        required={required}
        tooltips={tooltips}
        disabledOptions={disabledOptions}
        error={!!errorText}
        helperText={errorText}
        RadioGroupProps={field}
      />
      {subQuestions && field.value && (
        <DynamicFormBody prompts={subQuestions[field.value]} level={level + 1} />
      )}
    </>
  );
};
