import {Box, BoxProps, Grid, Typography} from '@material-ui/core';
import axios from 'axios';
import cn from 'classnames';
import Image from 'material-ui-image';
import React, {useEffect, useState, useRef} from 'react';
import {StudioTooltip} from '../../../base-components/StudioTooltip';
import {Skeleton} from '../../Skeleton';
import './ImageCardHorizontal.scss';

export type ImageCardHorizontalProps = BoxProps & {
  className?: string;
  title?: string;
  image: string;
  width: number;
  height: number;
  detailComponent?: React.ReactNode;
};

type XSizesItem = {
  id: string;
  w: number;
  h: number;
  s: number;
};

type ImageMetadata = {
  filename: string;
  width: number;
  height: number;
  fileSize: number;
};

export const ImageCardHorizontal = ({
  className,
  title,
  image,
  width,
  height,
  detailComponent,
  ...rest
}: ImageCardHorizontalProps) => {
  const isMounted = useRef(true);
  const [imageDataUrl, setImageDataUrl] = useState<string>();
  const [imageMetadata, setImageMetadata] = useState<ImageMetadata>();

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  useEffect(() => {
    (async function fetchImage() {
      try {
        const {data, headers} = await axios.get(image, {responseType: 'blob'});
        const unparsedJson = headers?.['x-sizes'];

        let header: XSizesItem | null = null;
        if (unparsedJson) {
          header = JSON.parse(unparsedJson)?.[0];
        }

        if (header && isMounted.current) {
          setImageMetadata({
            filename: header.id,
            width: header.w,
            height: header.h,
            fileSize: header.s,
          });
        }

        const reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function() {
          if (isMounted.current) {
            setImageDataUrl(reader.result as string);
          }
        };
      } catch (error) {
        console.error(error);
      }
    })();
  }, [image, height, width]);

  return (
    <Box className={cn('image-card-horizontal', className)} {...rest}>
      <Box className="image-card-horizontal_container">
        {!imageDataUrl ? (
          <Skeleton variant="rect" style={{width, height, marginTop: '8px'}} />
        ) : (
          <Image
            className="image-card-horizontal_image"
            src={imageDataUrl!}
            imageStyle={{width, height}}
            loading={<Skeleton variant="rect" style={{width, height}} />}
          />
        )}
        {imageMetadata && imageMetadata.filename && (
          <StudioTooltip
            title={imageMetadata.filename}
            PopperProps={{popperOptions: {positionFixed: true}}}
          >
            <Typography
              className="image-card-horizontal_text"
              variant="caption"
              color="textSecondary"
              component="div"
              align="center"
              noWrap
            >
              {imageMetadata.filename}
            </Typography>
          </StudioTooltip>
        )}
      </Box>
      <Box className="image-card-horizontal_content">
        {title && (
          <div className="image-card-horizontal_title" title={title}>
            {title}
          </div>
        )}
        {detailComponent && (
          <Grid
            container
            spacing={1}
            direction="column"
            className="image-card-horizontal_detail_content"
          >
            <Grid item>{detailComponent}</Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};
