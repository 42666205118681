import {isEmpty} from 'lodash';

export function transformToValuesArr(arr) {
  if (!isEmpty(arr)) {
    return Object.entries(arr).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  }
  return [];
}
