import {ISP_SUCCESS, ISP_ERROR, ISP_PENDING} from './actions';

const initialState = {
  isPending: false,
  isNew: false,
  colorSpaceOptions: null,
  template: null,
};

export const ispReducer = (state = initialState, action) => {
  switch (action.type) {
    case ISP_PENDING:
      return {
        ...state,
        isPending: true,
      };

    case ISP_SUCCESS:
      const {payload} = action;
      return {
        ...state,
        data: payload.body,
        template: payload.template || state.template,
        colorSpaceOptions: payload.colorSpaceOptions || state.colorSpaceOptions,
        isNew: payload.isNew,
        errors: payload.errors,
        prompts: payload.prompts,
        warnings: payload.warnings,
        isPending: false,
      };
    case ISP_ERROR:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    default:
      return state;
  }
};
