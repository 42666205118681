import {ADD_LISTENER, REMOVE_LISTENER, RESET} from './actions';

const initialState = {
  listeners: {},
};

export const statusCheckerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LISTENER: {
      return {
        ...state,
        listeners: {...state.listeners, [action.eventType]: action.handler},
      };
    }
    case REMOVE_LISTENER: {
      const clone = {...state.listeners};
      delete clone[action.eventType];
      return {
        ...state,
        listeners: clone,
      };
    }
    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};
