export enum DataPrepareTypeStr {
  CLASSIFICATION = 'Classification',
  DETECTION = 'Detection',
}

export enum DataPrepareType {
  CLASSIFICATION = 1,
  DETECTION,
}

export type InfoType = {
  version?: string;
  // ex
  // date_created: '2021-09-02';
  date_created?: string;
  count?: number;
  size?: number;
  ctime?: number; // to add later
  type?: DataPrepareType | null;
  isAutoLabel?: boolean;
};

// https://towardsdatascience.com/how-to-work-with-object-detection-datasets-in-coco-format-9bf4fb5848a4
export type CategoryType = {
  id: number;
  name: string;
  // COCO fields not offered for now. Need to discuss further
  supercategory?: string;
};

export type AnnotationType = {
  id?: number; // unique id
  // Used for classification
  image_id: number; // image
  category_id?: number; // label
  // Bounding box coordinates should be relative values
  // in [0, 1] in the following format:
  // [top-left-x, top-left-y, width, height]
  // ex [260, 177, 231, 199]
  // Used bof box labelling
  bbox?: number[];
  // COCO fields not offered for now. Need to discuss further
  // Used bof segmentation labelling
  segmentation?: any;
  // COCO fields not offered for now. Need to discuss further
  area?: number;
  iscrowd?: 0;
};

export type ImageType = {
  // Coco
  id: number;
  file_name: string; //'<filename0>.<ext>'
  height?: number;
  width?: number;
  date_captured: string;
  license?: number;
  // Non-COCO
  size: number;
  selected?: 0 | 1;
};

export type IdType = {
  datasetId: string;
  splitType: string;
};

export type AnnotationDataType = {
  info?: InfoType;
  categories: CategoryType[];
  annotations: AnnotationType[];
  images: ImageType[];
  _id?: IdType;
};

export type DatasetGetAnnotationsResponse = AnnotationDataType;
