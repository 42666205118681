import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from '../../../base-components/StudioToast';
import {IntegrationDialogBase} from '../IntegrationDialogBase';
import {useIntl} from 'react-intl';
import {RootState} from '../../../store';
import {
  integrationEditFinalize,
  setIsEditModalOpen,
} from '../../../store/marketplace/configure';
import {Answer} from '../../../types/prompts/Answer';

export const EditIntegrationDialog = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector(
    (state: RootState) => state.configureMarketplace.isEditModalOpen
  );

  const editPromptsData = useSelector(
    (state: RootState) => state.configureMarketplace.editPromptsData
  );
  const isPending = useSelector(
    (state: RootState) => state.configureMarketplace.isEditPromptsPending
  );
  const submissionResponse = useSelector(
    (state: RootState) => state.configureMarketplace.editFinalize
  );
  const submissionError = useSelector(
    (state: RootState) => state.configureMarketplace.editFinalizeError
  );

  const prevSuccessRef = useRef(submissionResponse);
  const prevErrorRef = useRef(submissionError);

  useEffect(() => {
    if (submissionError && submissionError !== prevErrorRef.current) {
      toast.error(`Error! Could not update integration. Please try again.`);
      prevErrorRef.current = submissionError;
    }
    if (
      editPromptsData &&
      submissionResponse &&
      submissionResponse !== prevSuccessRef.current
    ) {
      toast.success(`Successfully updated integration: ${editPromptsData?.name}`);
      dispatch(setIsEditModalOpen(false));
      prevSuccessRef.current = submissionResponse;
    }
  }, [submissionResponse, submissionError, editPromptsData, dispatch]);

  const handleSubmit = async (answers: Array<Answer>) => {
    const formattedAnswers = answers.map(({key, type: fieldType, value}) => ({
      invalid: false,
      key,
      type: fieldType,
      value,
      values: null,
    }));
    if (editPromptsData) {
      await dispatch(
        integrationEditFinalize({id: editPromptsData.id, answers: formattedAnswers})
      );
    }
  };

  return (
    <IntegrationDialogBase
      isOpen={isOpen}
      isPending={isPending}
      prompts={editPromptsData?.prompts}
      title={`Edit ${editPromptsData?.name}`}
      onClose={() => dispatch(setIsEditModalOpen(false))}
      onSubmit={handleSubmit}
      defaults={editPromptsData?.defaults}
      submitText={intl.formatMessage({id: 'form.submit'})}
      data-testid="edit-integration-dialog"
    />
  );
};
