import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {grey, lightBlue} from '@material-ui/core/colors';
import {StudioTooltip} from '../StudioTooltip';

export type StudioPopoverProps = {
  infoIconClass?: string;
  infoMessage?: string;
  ttPlacement?: string;
};

export const StudioPopover = ({
  infoIconClass,
  infoMessage,
  ttPlacement = 'top',
}: StudioPopoverProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <StudioTooltip title={infoMessage} placement={ttPlacement}>
      <InfoOutlinedIcon
        className={infoIconClass}
        style={{color: open ? lightBlue[600] : grey[400]}}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      />
    </StudioTooltip>
  );
};
