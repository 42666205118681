import React, {Component} from 'react';
import PropTypes from 'prop-types';

class MessageTailIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="13px"
          height="18px"
          viewBox="0 0 13 18"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g id="Tail" stroke="none" strokeWidth="1">
            <path d="M0,18 C3.44591401,16.6673873 5.97489254,12.8668644 6,9 L6,0 L9,1 C9,6.57184505 8.80039654,11.5213432 13,14.6210651 C9.20852463,16.7471421 4.66593645,17.9719 0,18 Z"></path>
          </g>
        </svg>
      </div>
    );
  }
}

MessageTailIcon.propTypes = {
  className: PropTypes.string,
};

export {MessageTailIcon};
