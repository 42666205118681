import {Chip, Dialog} from '@material-ui/core';
import {
  Computer as ComputerIcon,
  Group as GroupIcon,
  Public as PublicIcon,
} from '@material-ui/icons';
import {Pagination} from '@material-ui/lab';
import {debounce} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {DropArea} from '../../../base-components/StudioDropArea';
import {StudioPopover} from '../../../base-components/StudioPopover';
import {StudioSearch} from '../../../base-components/StudioSearch';
import {StudioTab, StudioTabPanel, StudioTabs} from '../../../base-components/StudioTab';
import {getProjectId} from '../../../config/routes';
import {
  MarketplaceFilters,
  SortableColumn,
} from '../../../types/marketplace/MarketplaceFilesRequest';
import {
  Integration,
  IntegrationPurpose,
  IntegrationRole,
} from '../../../types/integrations/IntegrationResponse';
import {MarketplaceResult} from '../../../types/marketplace/MarketplaceFilesResponse';
import {FileExplorer} from '../../TestDeployView/FileExplorer';
import {filterDefinitions} from '../filterDefinitions';
import {MarketplaceList} from '../MarketplaceExplorer/MarketplaceList';
import {actions, useMarketplace} from '../MarketplaceExplorer/useMarketplace';
import {MarketplaceFilterPanel} from '../MarketplaceFilterPanel/MarketplaceFilterPanel';
import {toast} from '../../../base-components/StudioToast';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store';
import './ImportDialog.scss';

type ImportDialogTab = IntegrationRole | 'LOCAL';

export type InitialImportDialogState = {
  displayName?: string;
  role?: ImportDialogTab;
  filters?: MarketplaceFilters;
};

type ImportDialogProps = {
  isOpen: boolean;
  title: string;
  purpose: IntegrationPurpose;
  columns: SortableColumn[];
  onClose: (isSubmit: boolean) => void;
  onSubmit: (item: MarketplaceResult, integration?: Integration) => Promise<void> | void;
  onUploadSubmit?: (files: File[], totalSize: number) => Promise<void> | void;
  initialState?: InitialImportDialogState;
  defaultFilterValues?: MarketplaceFilters;
};

const PAGE_SIZE = 5;

const {
  setDisplayName,
  setSort,
  setSortDir,
  setSelected,
  setFilters,
  resetFilters,
} = actions;

export const ImportDialog = ({
  isOpen,
  title,
  purpose,
  columns,
  onClose,
  onSubmit,
  onUploadSubmit,
  initialState = {},
  defaultFilterValues = {},
}: ImportDialogProps) => {
  const intl = useIntl();
  const location = useLocation();
  const [files, setFiles] = useState<File[]>([]);
  const [role, setRole] = useState<IntegrationRole | undefined>('PUBLIC');
  const [search, setSearch] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [totalUploadSize, setTotalUploadSize] = useState(0); //in MB
  const serverConfig = useSelector((state: RootState) => state.config);

  const {
    state: {selectedIds, sort, sortDir, filters, results, count, page},
    integration,
    updateResults,
    dispatch,
  } = useMarketplace({
    role,
    purpose,
    pageSize: PAGE_SIZE,
    isEnabled: isOpen,
    projectId: getProjectId(location.pathname),
    defaultFilterValues,
  });

  const selectedItem = Object.values(selectedIds).filter(value => value)[0];

  useEffect(() => {
    const {filters, displayName, role} = initialState;
    if (role === 'LOCAL') {
      setRole(undefined);
    } else {
      if (filters) {
        dispatch(setFilters(filters));
      }
      if (role) {
        setRole(role);
      }
      if (displayName) {
        dispatch(setDisplayName(displayName));
      }
    }
  }, [initialState, dispatch]);

  useEffect(() => {
    const resetState = () => {
      setSearch('');
      setRole('PUBLIC');
      setFiles([]);
    };

    if (!isOpen) {
      resetState();
    } else {
      setIsSubmitted(false);
    }
  }, [isOpen, dispatch]);

  useEffect(() => {
    if (files && files.length) {
      let total = 0;
      files.forEach(({size: fSize}: File) => (total += fSize));
      total /= 1000000;
      setTotalUploadSize(total);
      if (total > serverConfig.maxRequestSize) {
        toast.warn({
          subtitle: intl.formatMessage(
            {id: 'importDialog.warn.upload.limit'},
            {limit: serverConfig.maxRequestSize + 'mb'}
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, serverConfig.maxRequestSize, intl]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetDisplayName = useCallback(
    debounce((displayName: string) => dispatch(setDisplayName(displayName)), 500),
    [dispatch]
  );

  const TABS: Array<{
    name: string;
    role?: IntegrationRole;
    icon: JSX.Element;
    tooltip?: string;
  }> = [
    {
      name: 'marketplaceExplorer.public',
      role: 'PUBLIC',
      icon: <PublicIcon />,
      tooltip: 'A public shared repository of application components.',
    },
    {
      name: 'marketplaceExplorer.org',
      role: 'ORGANIZATION',
      icon: <GroupIcon />,
      tooltip:
        'A repository of application components that is private to your organization.',
    },
  ];

  if (onUploadSubmit) {
    TABS.push({name: 'importDialog.upload', icon: <ComputerIcon />});
  }

  const tabIndex = role ? TABS.findIndex(tab => tab.role === role) : 2;
  const isFileUploadTab = tabIndex === 2;

  const handleFileListChange = (newFiles: File[]) => setFiles([...files, ...newFiles]);

  const handleFileListDelete = (fileIndex: number) =>
    setFiles(files.filter((file, index) => index !== fileIndex));

  const updateSearch = (search: string) => {
    /* We want to debounce the API fetch as the user types a search query, without
     * interrupting the user while typing. Hence, we separate the filter used for the
     * API call (displayName) with the value used for the search box (search).
     */

    debouncedSetDisplayName(search);
    setSearch(search);
  };

  const handleSubmit = () => {
    if (!isSubmitted) {
      // hack to prevent this from being executed twice after double click
      // not sure if better way exists
      setIsSubmitted(true);
      handleClose(true);
      if (isFileUploadTab) {
        const totalSize = files.reduce((acc, curr) => acc + curr.size, 0);
        onUploadSubmit?.(files, totalSize);
      } else if (selectedItem != null) {
        onSubmit(selectedItem, integration);
      }
    }
  };

  const handleClose = (isSubmit?: boolean) => {
    onClose(Boolean(isSubmit));
  };

  const getResultKey = (result: MarketplaceResult) =>
    `${result.name}_${result.integrationId}`;

  return (
    <Dialog
      open={isOpen}
      BackdropProps={{
        invisible: true,
      }}
      className="import-dialog"
      onClose={() => handleClose()}
      maxWidth={false}
      data-testid="file-upload-modal"
      id="file-upload-modal"
    >
      <h2 className="import-dialog__title">{title}</h2>
      <div className="import-dialog__body">
        <div
          className="import-dialog__filter-panel"
          data-testid="import-dialog-filter-panel"
          id="import-dialog-filter-panel"
        >
          {!isFileUploadTab && (
            <MarketplaceFilterPanel
              filterDefinitions={filterDefinitions}
              purpose={purpose}
              filters={filters}
              onChange={newFilters => dispatch(setFilters(newFilters))}
              onReset={() => dispatch(resetFilters(defaultFilterValues))}
              compact
            />
          )}
        </div>
        <div className="import-dialog__main">
          <StudioTabs
            value={tabIndex}
            onChange={(event, index) => {
              const role = TABS[index].role;
              setRole(role);
            }}
            variant="scrollable"
            className="import-dialog__tabs"
          >
            {TABS.map(({name, icon, tooltip}) => (
              <StudioTab
                key={name}
                icon={icon}
                label={
                  <>
                    <div className="import-dialog__tab-name">
                      {intl.formatMessage({id: name})}
                    </div>
                    {tooltip && <StudioPopover infoMessage={tooltip} />}
                  </>
                }
                className="import-dialog__tab"
                data-testid="import-dialog-tab"
                id="import-dialog-tab"
              />
            ))}
          </StudioTabs>
          <StudioTabPanel
            value={tabIndex === 0 || tabIndex === 1 ? 0 : 2}
            index={0}
            className="import-dialog__tab-panel"
          >
            <div className="import-dialog__menu">
              <div className="import-dialog__filter-chips">
                {(Object.keys(filters) as Array<keyof MarketplaceFilters>).flatMap(
                  key => {
                    const value = filters[key];
                    const definition = filterDefinitions.find(
                      definition => definition.key === key
                    );
                    return definition &&
                      definition.type === 'OPTIONS' &&
                      typeof value === 'string'
                      ? [
                          <Chip
                            key={key}
                            className="import-dialog__chip"
                            clickable={false}
                            label={definition.options.getValue(value)}
                            data-testid="import-dialog-filter-chip"
                            id="import-dialog-filter-chip"
                            onDelete={() =>
                              dispatch(setFilters({...filters, [key]: undefined}))
                            }
                          />,
                        ]
                      : [];
                  }
                )}
              </div>
              <StudioSearch
                placeholder={intl.formatMessage({id: 'marketplaceExplorer.search'})}
                onChange={e => updateSearch(e.target.value)}
                data-testid="import-dialog-search"
                id="import-dialog-search"
                value={search}
              />
            </div>
            <MarketplaceList
              getKey={getResultKey}
              results={results}
              sort={sort}
              sortDir={sortDir}
              selectedIds={selectedIds}
              columns={columns}
              onSelectedChange={result =>
                dispatch(setSelected({[getResultKey(result)]: result}))
              }
              onSort={(column, direction) => {
                dispatch(setSort(column));
                dispatch(setSortDir(direction));
              }}
            />
            {count > PAGE_SIZE && (
              <Pagination
                count={Math.ceil(count / PAGE_SIZE)}
                page={page + 1}
                shape="rounded"
                className="import-dialog__pagination"
                data-testid="import-dialog-pagination"
                id="import-dialog-pagination"
                onChange={(_, p) => integration && updateResults(integration, p - 1)}
              />
            )}
          </StudioTabPanel>
          <StudioTabPanel value={tabIndex} index={2} className="import-dialog__tab-panel">
            <DropArea
              size="xlg"
              onChange={handleFileListChange}
              className="import-dialog__drop"
            />
            <FileExplorer
              files={files}
              onDelete={(file, index) => handleFileListDelete(index)}
              onClear={() => setFiles([])}
              size="small"
              icon="delete"
              className="import-dialog__files"
            />
          </StudioTabPanel>
        </div>
      </div>
      <div className="import-dialog__actions">
        <FormButton
          buttonRole="secondary"
          value={intl.formatMessage({id: 'form.cancel'})}
          onClick={() => handleClose()}
          type="button"
        />
        <FormButton
          buttonRole="primary"
          value={intl.formatMessage({id: 'form.submit'})}
          type="submit"
          disabled={
            isSubmitted ||
            (isFileUploadTab && !files.length) ||
            (!isFileUploadTab && selectedItem == null) ||
            totalUploadSize > serverConfig.maxRequestSize
          }
          onClick={handleSubmit}
        />
      </div>
    </Dialog>
  );
};
