import React, {useEffect} from 'react';
import axios from 'axios';

export type StudioMode = 'LITE' | 'FULL';

export type StudioModeContextType = {
  mode: StudioMode;
  setMode: React.Dispatch<React.SetStateAction<StudioMode | undefined>>;
};

const StudioModeContext = React.createContext<StudioModeContextType | undefined>(
  undefined
);

type StudioFeaturePack = {
  name: string;
};

export const StudioModeProvider = ({children}: {children: React.ReactNode}) => {
  const [mode, setMode] = React.useState<StudioMode>();

  useEffect(() => {
    const fetchMode = async () => {
      try {
        const {data} = await axios.get<{body: Array<StudioFeaturePack>}>(
          '/studio/feature/packs'
        );
        if (data.body?.find(pack => pack.name === 'lite')) {
          setMode('LITE');
        } else {
          setMode('FULL');
        }
      } catch (e) {
        setMode('FULL');
        console.error(e);
      }
    };
    fetchMode();
  }, []);

  return mode ? (
    <StudioModeContext.Provider value={{mode, setMode}}>
      {children}
    </StudioModeContext.Provider>
  ) : null;
};

export const useStudioMode = () => {
  const context = React.useContext(StudioModeContext);
  if (!context) {
    throw new Error('useStudioModeProvider must be used within a StudioModeProvider');
  }
  return context;
};

// For use in class components
export const StudioModeConsumer = ({
  children,
}: {
  children: (context: StudioModeContextType) => React.ReactNode;
}) => {
  return (
    <StudioModeContext.Consumer>
      {context => {
        if (!context) {
          throw new Error(
            'useStudioModeProvider must be used within a StudioModeProvider'
          );
        }
        return children(context);
      }}
    </StudioModeContext.Consumer>
  );
};
