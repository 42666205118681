import {Card, CardContent, IconButton} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import cn from 'classnames';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import HumanReadableTime from '../../../base-components/StudioHumanReadableTime/HumanReadableTime';
import {UnparsedDate} from '../../../types/utility/UnparsedDate';
import './TestDescription.scss';

export type TestDescriptionProps = {
  className?: string;
  name: string;
  createdOn: UnparsedDate;
  isExpanded: boolean;
  onExpand: () => void;
};

export function TestDescription({
  className,
  createdOn,
  name,
  isExpanded,
  onExpand,
}: TestDescriptionProps) {
  return (
    <Card className={cn('test-description', className)} onClick={onExpand} elevation={0}>
      <CardContent className="test-description__content">
        <div className="test-description__name">{name}</div>
        <div className="test-description__created-on">
          <FormattedMessage
            id="test.createdOn"
            values={{date: <HumanReadableTime date={createdOn} />}}
          />
        </div>
        <div className="test-description__name">{name}</div>
        <div className="test-description__created-on">
          <FormattedMessage
            id="test.createdOn"
            values={{date: <HumanReadableTime date={createdOn} />}}
          />
        </div>
        <IconButton className="test-description__expand">
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </CardContent>
    </Card>
  );
}
