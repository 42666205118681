import {Button} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../../../store';
import {fetchInferenceApps} from '../../../store/prodDeployments/dashboard';
import {CopyableURL} from '../CopyableURL/CopyableURL';
import {Metadata, MetadataList} from '../MetadataList/MetadataList';
import './InferenceAppDetails.scss';
import {Link} from 'react-router-dom';
import {StudioPopover} from '../../../base-components/StudioPopover';
import {InferenceApplicationsRequest} from '../../../types/deployment/InferenceApplicationsRequest';
import {InferenceApplicationInfo} from '../../../types/deployment/InferenceApplicationsResponse';

type InferenceAppDetailsProps = {
  deploymentId: string | undefined | null;
  applicationId: string;
  projectId: string;
};

export const InferenceAppDetails = ({
  deploymentId,
  applicationId,
  projectId,
}: InferenceAppDetailsProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const groupId = useSelector((state: RootState) => state.user.groupId);

  const inferenceApps: Array<InferenceApplicationInfo> = useSelector(
    (state: RootState) => state.deploymentDashboard.inferenceApps.data
  );

  const application = inferenceApps.find(app => app.id === applicationId);

  const deployment = application?.deployments
    ? application.deployments.find(deploy => deploy.id === deploymentId)
    : undefined;

  const batchInferenceApiUrl = deployment
    ? deployment.batchInferenceUrl
    : application?.batchInferenceUrl;
  const batchInferenceSpaUrl = deployment
    ? deployment.inferencePageUrl
    : application?.inferencePageUrl;

  useEffect(() => {
    if (applicationId && groupId) {
      const requestBody: InferenceApplicationsRequest = {
        filterOutNonInferenceApps: true,
        firstPage: 0,
        pageSize: 100,
        paginated: false,
        orderDefinitions: [],
        params: {},
        applicationId: applicationId,
        groupId,
      };

      dispatch(fetchInferenceApps(requestBody));
    }
  }, [projectId, applicationId, dispatch, groupId]);

  return (
    <>
      <MetadataList>
        <Metadata label="prodDeployment.api">
          {batchInferenceApiUrl ? (
            <CopyableURL path={batchInferenceApiUrl} />
          ) : (
            <div>API Not Published</div>
          )}
        </Metadata>
        <Metadata label="prodDeployment.singlePage.full">
          {batchInferenceSpaUrl ? (
            <CopyableURL path={batchInferenceSpaUrl} />
          ) : (
            <div>SPA Not Published</div>
          )}
        </Metadata>
        <Metadata label="prodDeployment.type">
          <div>{deploymentId ? 'Production' : 'Test'}</div>
        </Metadata>

        <Button className="dplmt-config__link dplmt-inference-app__link ">
          {deploymentId ? (
            <Link
              to={`/project/${projectId}/inference/${applicationId}/deployment/${deploymentId}/configure`}
            >
              {intl.formatMessage({id: 'test.createInference'})}
            </Link>
          ) : (
            <Link to={`/project/${projectId}/inference/${applicationId}/create`}>
              {intl.formatMessage({id: 'test.createInference'})}
            </Link>
          )}
        </Button>
        <StudioPopover
          infoIconClass="prod-deploy-details__tooltip"
          infoMessage={intl.formatMessage({
            id: 'prodDeployment.inferenceApp.tooltip',
          })}
          ttPlacement="right"
        />
      </MetadataList>
    </>
  );
};
