import React, {Fragment} from 'react';
import cn from 'classnames';
import {List, ListItem, Divider} from '@material-ui/core';
import {Link} from 'react-router-dom';
import HumanReadableTime from '../../../base-components/StudioHumanReadableTime/HumanReadableTime';
import {DeploymentShortInfo} from '../../../types/deployment/DeploymentShortListResponse';
import './RecentDeployments.scss';
import {useIntl} from 'react-intl';

export type RecentDeploymentsProps = {
  deployments: Array<DeploymentShortInfo>;
  className: string;
};

export const RecentDeployments = ({deployments, className}: RecentDeploymentsProps) => {
  const intl = useIntl();
  return (
    <List
      className={cn('recent-deployments', className)}
      disablePadding
      data-testid="recent-deployments"
    >
      {deployments.map(({id, name, startTime}, index) => (
        <Fragment key={index}>
          {index > 0 && <Divider />}
          <Link to={`deployment/${id}/details?origin=dashboard`}>
            <ListItem
              button
              className="recent-deployments__body-row"
              key={id}
              data-test-projectid={id}
              data-testid="recent-deployments-row"
            >
              <div className="recent-deployments__body-cell recent-deployments__name">
                {name}
              </div>
              <div className="recent-deployments__body-cell recent-deployments__date">
                {startTime ? (
                  <HumanReadableTime date={startTime} />
                ) : (
                  <p>{intl.formatMessage({id: 'prodDeployment.not.started'})}</p>
                )}
              </div>
            </ListItem>
          </Link>
        </Fragment>
      ))}
    </List>
  );
};
