import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';

import './StageView.scss';
import {withRouter} from 'react-router';
import StageLine from './StageLine/StageLine';
import StageList from './StageList/StageList';
import Waiting from '../../base-components/StudioWaiting/Waiting';
import AssistantPanel from '../DialogLegacy/AssistantPanel';
import ModelCreateHelperFileAction from './StageActions/ModelCreateHelperFileAction';
import ModelConversionAction from './StageActions/ModelConversionAction';
import CreateDeploymentAction from './StageActions/CreateDeploymentAction';
import DataUploadAction from './StageActions/DataUploadAction';
import DataSplitAction from './StageActions/DataSplitAction';
import DataTilingAction from './StageActions/DataTilingAction';
import ImportModelAction from './StageActions/ImportModelAction';
import ModelOptimizationAction from './StageActions/ModelOptimizationAction';
import ModelBuildAction from './StageActions/ModelBuildAction';
import FinetuneAction from './StageActions/FinetuneAction';
import {
  stageFetch,
  getIsReadyForConversion,
  getStagesData,
  getIsReadyForCreateHelperFile,
  getIsReadyForCreatePyTorchModelFile,
} from '../../store/stages';
import {ConversationActionListener} from '../ConversationAssistant/ConversationActionListener';
import {ModelDetailsDialog} from '../ModelDetailsDialog';
import ModelFilePrepareAction from './StageActions/ModelFilePrepareAction';
import {AddDataDialog} from '../Marketplace';
import {AddModelDialog} from '../Marketplace';
import ISPUploadAction from './StageActions/ISPUploadAction';
import {AddISPDialog} from '../Marketplace/ImportDialogs/AddISPDialog';
import {
  getIsTerminateDialogOpen,
  getTerminateDialogMessage,
  getTerminateDialogTitle,
  viewOpenTerminateDialog,
} from '../../store/view';
import {TerminateDialog} from './StagePanel/TerminateDialog';
import {PropertiesPanelForm} from '../PropertiesPanelForm/PropertiesPanelForm';
import DataPrepareAction from './StageActions/DataPrepareAction';
import StageAnimation from './StageAnimation/StageAnimation';
import PPUploadAction from './StageActions/PPUploadAction';
import {AddPPDialog} from '../Marketplace/ImportDialogs/AddPPDialog';
import {
  AutoDetectModelPostProcessor,
  AutoDetectAppPostProcessor,
} from '../Marketplace/AutoDetectDialogs/AutoDetectPostProcessor';
import {AutoDetectPreProcessor} from '../Marketplace/AutoDetectDialogs/AutoDetectPreProcessor';

class StageView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPending: true,
      datasetActionInProgress: null,
      modelActionInProgress: null,
      ispActionInProgress: null,
      ppActionInProgress: null,
      terminate: null,
    };

    this._isMounted = false;

    // references
    this.stageList = React.createRef();
    this.assistantPanel = React.createRef();
    this.modelViewer = React.createRef();
    this.dataUploadAction = React.createRef();
    this.dataSplitAction = React.createRef();
    this.dataTilingAction = React.createRef();
    this.dataPrepAction = React.createRef();
    this.importModelAction = React.createRef();
    this.addISPAction = React.createRef();
    this.addPPAction = React.createRef();
    this.convertModelAction = React.createRef();
    this.createHelperFileModelAction = React.createRef();
    this.prepareModelFileAction = React.createRef();
    this.modelBuildAction = React.createRef();
    this.optimizeModelAction = React.createRef();
    this.finetuneAction = React.createRef();
    this.createDeploymentAction = React.createRef();

    this.refresh = this.refresh.bind(this);
    this.navigateToTestView = this.navigateToTestView.bind(this);
    this.setImageSet = this.setImageSet.bind(this);
    this.updateStatistics = this.updateStatistics.bind(this);
    this.updateConversionStatus = this.updateConversionStatus.bind(this);
    this.updateAccuracy = this.updateAccuracy.bind(this);
    this.initiateModelConversion = this.initiateModelConversion.bind(this);
    this.initiateModelCreateHelperFile = this.initiateModelCreateHelperFile.bind(this);
    this.initiateModelCreatePyTorchFile = this.initiateModelCreatePyTorchFile.bind(this);
    this.setDatasetActionInProgress = this.setDatasetActionInProgress.bind(this);
    this.setModelActionInProgress = this.setModelActionInProgress.bind(this);
    this.setISPActionInProgress = this.setISPActionInProgress.bind(this);
    this.setPPActionInProgress = this.setPPActionInProgress.bind(this);
    this.openTerminateDialog = this.openTerminateDialog.bind(this);
    this.onWindowFocus = this.onWindowFocus.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.refresh(null, true);
    window.addEventListener('focus', this.onWindowFocus);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onWindowFocus);
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.projectId !== prevProps.projectId) {
      this.setState({isPending: true});
      this.refresh(null, true);
    }
  }

  onWindowFocus() {
    const stageAnimation = new StageAnimation();
    const activeStage = this.props.stagesData?.stages?.find(stage =>
      stageAnimation.isStageActive(stage.type, '-container')
    )?.type;
    this.refresh(activeStage);
  }

  openTerminateDialog({title, message, terminate}) {
    this.props.viewOpenTerminateDialog({
      title,
      message,
    });
    this.setState({
      terminate,
    });
  }

  async refresh(activeStage, redraw = false) {
    const uploadingStages = {};
    if (this.dataUploadAction.current?.fileUpload) {
      uploadingStages['ADD_DATA'] = true;
    }
    if (this.importModelAction.current?.fileUpload) {
      uploadingStages['IMPORT_MODEL'] = true;
    }

    await this.props.dispatchStagesFetch(
      this.props.projectId,
      {activeStage},
      redraw,
      uploadingStages
    );

    if (this._isMounted) {
      this.setState({isPending: false});

      if (this.props.stagesData) {
        if (!redraw) {
          this.initiateModelCreateHelperFile();
          this.initiateModelCreatePyTorchFile();
          this.updateAccuracy(this.props.stagesData.stages[3]);
        }
        this.updateConversionStatus(this.props.stagesData.stages[2]);
      }
    }
  }

  render() {
    if (this.state.isPending) {
      return (
        <div className="stage-waiting">
          <Waiting diameter={50} loadingText="Loading..." />
        </div>
      );
    }
    const {projectId, stagesData, project} = this.props;
    if (stagesData) {
      return (
        <div className="stage-space">
          <PropertiesPanelForm />
          <ConversationActionListener
            setModelActionInProgress={this.setModelActionInProgress}
            setDatasetActionInProgress={this.setDatasetActionInProgress}
            dataUploadAction={this.dataUploadAction}
            importModelAction={this.importModelAction}
            modelBuildAction={this.modelBuildAction}
            dataSplitAction={this.dataSplitAction}
            optimizeModelAction={this.optimizeModelAction}
            finetuneAction={this.finetuneAction}
            createDeploymentAction={this.createDeploymentAction}
          />
          <ModelDetailsDialog projectId={projectId} />
          <AssistantPanel
            isOpen={false}
            id="assistant-panel"
            projectId={projectId}
            ref={this.assistantPanel}
          />
          <StageLine stages={stagesData.stages} projectId={this.props.projectId} />
          <StageList
            stages={stagesData.stages}
            projectId={this.props.projectId}
            model={project.data.model}
            ref={this.stageList}
            assistantPanel={this.assistantPanel}
            modelViewer={this.modelViewer}
            onFinish={this.refresh}
            dataUploadAction={this.dataUploadAction}
            dataSplitAction={this.dataSplitAction}
            dataTilingAction={this.dataTilingAction}
            dataPrepAction={this.dataPrepAction}
            importModelAction={this.importModelAction}
            modelConversionAction={this.convertModelAction}
            prepareModelFileAction={this.prepareModelFileAction}
            modelCreateHelperFileAction={this.createHelperFileModelAction}
            modelOptimizationAction={this.optimizeModelAction}
            modelBuildAction={this.modelBuildAction}
            finetuneAction={this.finetuneAction}
            createDeploymentAction={this.createDeploymentAction}
            taskProgress={stagesData.stages[0].progress}
            datasetActionInProgress={this.state.datasetActionInProgress}
            setDatasetActionInProgress={this.setDatasetActionInProgress}
            modelActionInProgress={this.state.modelActionInProgress}
            setModelActionInProgress={this.setModelActionInProgress}
            readyForModelBuilding={stagesData.readyForModelBuilding}
            readyForFinetuning={stagesData.readyForFinetuning}
            readyForDataSplit={stagesData.readyForDataSplit}
            resplitAvailable={stagesData.resplitAvailable}
            tilingAvailable={stagesData.tilingAvailable}
            createHelperFileAction={this.createHelperFileModelAction}
            openTerminateDialog={this.openTerminateDialog}
            dataPrepUnfinished={
              stagesData.dataLabelingUnfinished ||
              stagesData.datasetRelabelingUnfinished ||
              stagesData.ddaMergeUnfinished
            }
          />
          <AddDataDialog actionRef={this.dataUploadAction} />
          <AddModelDialog actionRef={this.importModelAction} />
          <AddISPDialog actionRef={this.addISPAction} />
          <AddPPDialog actionRef={this.addPPAction} />
          <AutoDetectModelPostProcessor actionRef={this.addPPAction} />
          <AutoDetectAppPostProcessor actionRef={this.addPPAction} />
          <AutoDetectPreProcessor actionRef={this.addISPAction} />
          <TerminateDialog
            open={this.props.isTerminateDialogOpen}
            title={this.props.terminateDialogTitle}
            message={this.props.terminateDialogMessage}
            terminate={this.state.terminate}
          />
          <DataUploadAction
            projectId={projectId}
            assistantPanel={this.assistantPanel}
            ref={this.dataUploadAction}
            onFinish={this.refresh}
            setImages={this.setImageSet}
            taskProgress={stagesData.stages[0].progress}
            setDatasetActionInProgress={this.setDatasetActionInProgress}
          />
          <DataSplitAction
            projectId={projectId}
            assistantPanel={this.assistantPanel}
            ref={this.dataSplitAction}
            onFinish={this.refresh}
            taskProgress={stagesData.stages[0].progress}
            setDatasetActionInProgress={this.setDatasetActionInProgress}
            readyForDataSplit={stagesData.readyForDataSplit}
            resplitAvailable={stagesData.resplitAvailable}
          />
          <DataTilingAction
            projectId={projectId}
            assistantPanel={this.assistantPanel}
            ref={this.dataTilingAction}
            onFinish={this.refresh}
            taskProgress={stagesData.stages[0].progress}
            setDatasetActionInProgress={this.setDatasetActionInProgress}
            tilingAvailable={stagesData.tilingAvailable}
          />
          <ImportModelAction
            projectId={projectId}
            assistantPanel={this.assistantPanel}
            ref={this.importModelAction}
            onFinish={this.refresh}
            taskProgress={stagesData.stages[1].progress}
            setModelActionInProgress={this.setModelActionInProgress}
          />
          <ModelCreateHelperFileAction
            projectId={projectId}
            model={project.data.model}
            assistantPanel={this.assistantPanel}
            ref={this.createHelperFileModelAction}
            onFinish={this.refresh}
            readyForHelperPreparation={this.props.openCreateHelperFile}
            taskProgress={stagesData.stages[1].progress}
            setModelActionInProgress={this.setModelActionInProgress}
          />
          <ModelFilePrepareAction
            projectId={projectId}
            model={project.data.model}
            assistantPanel={this.assistantPanel}
            ref={this.prepareModelFileAction}
            convertModelAction={this.convertModelAction}
            onFinish={this.refresh}
            readyForPyTorchModelPreparation={this.props.openCreatePyTorchModelFile}
            taskProgress={stagesData.stages[1].progress}
            setModelActionInProgress={this.setModelActionInProgress}
          />
          <ModelConversionAction
            projectId={projectId}
            assistantPanel={this.assistantPanel}
            ref={this.convertModelAction}
            onFinish={this.refresh}
            prepareModelFileAction={this.prepareModelFileAction}
            readyForConversion={
              !this.props.openCreateHelperFile &&
              !this.props.openCreatePyTorchModelFile &&
              this.props.openConversion
            }
            taskProgress={stagesData.stages[1].progress}
            setModelActionInProgress={this.setModelActionInProgress}
          />
          <ModelBuildAction
            projectId={projectId}
            assistantPanel={this.assistantPanel}
            ref={this.modelBuildAction}
            onFinish={this.refresh}
            taskProgress={stagesData.stages[1].progress}
            setModelActionInProgress={this.setModelActionInProgress}
          />
          <ModelOptimizationAction
            projectId={projectId}
            assistantPanel={this.assistantPanel}
            ref={this.optimizeModelAction}
            onFinish={this.refresh}
            taskProgress={stagesData.stages[2].progress}
          />
          <FinetuneAction
            projectId={projectId}
            ref={this.finetuneAction}
            assistantPanel={this.assistantPanel}
            updateStatistics={this.updateStatistics}
            stage={stagesData.stages[3]}
            onFinish={this.refresh}
          />
          <CreateDeploymentAction
            projectId={projectId}
            assistantPanel={this.assistantPanel}
            ref={this.createDeploymentAction}
            onFinish={this.refresh}
            taskProgress={stagesData.stages[4].progress}
            navigateToTestView={this.navigateToTestView}
          />
          <ISPUploadAction
            projectId={projectId}
            assistantPanel={this.assistantPanel}
            ref={this.addISPAction}
            onFinish={this.refresh}
            setISPActionInProgress={this.setISPActionInProgress}
          />
          <PPUploadAction
            projectId={projectId}
            assistantPanel={this.assistantPanel}
            ref={this.addPPAction}
            onFinish={this.refresh}
            setPPActionInProgress={this.setPPActionInProgress}
          />
          <DataPrepareAction
            projectId={projectId}
            assistantPanel={this.assistantPanel}
            dataLabelingUnfinished={stagesData.dataLabelingUnfinished}
            datasetRelabelingUnfinished={stagesData.datasetRelabelingUnfinished}
            ddaMergeUnfinished={stagesData.ddaMergeUnfinished}
            ref={this.dataPrepAction}
            onFinish={this.refresh}
          />
        </div>
      );
    } else {
      return <div>Project is not open</div>;
    }
  }

  initiateModelCreateHelperFile() {
    if (!this.props.stagesData || !this.props.stagesData.readyForHelperPreparation) {
      return;
    }
    let current = this.createHelperFileModelAction.current;
    if (current) {
      current.createHelperFile();
    }
  }

  initiateModelCreatePyTorchFile() {
    if (
      !this.props.stagesData ||
      !this.props.stagesData.readyForPyTorchModelPreparation
    ) {
      return;
    }
    let current = this.prepareModelFileAction.current;
    if (current) {
      current.preparePyTorchModelFile();
    }
  }

  initiateModelConversion() {
    if (!this.props.stagesData || !this.props.stagesData.readyForConversion) {
      return;
    }
    let current = this.convertModelAction.current;
    if (current) {
      current.convertModel();
    }
  }

  setDatasetActionInProgress(type) {
    this.setState({
      datasetActionInProgress: type,
    });
  }

  setISPActionInProgress(type) {
    this.setState({
      ispActionInProgress: type,
    });
  }

  setPPActionInProgress(type) {
    this.setState({
      ppActionInProgress: type,
    });
  }

  setModelActionInProgress(type) {
    this.setState({
      modelActionInProgress: type,
    });
  }

  updateAccuracy(activeStage) {
    let current = this.stageList.current;
    if (current) {
      current.updateAccuracy(activeStage);
    }
  }

  navigateToTestView() {
    this.props.history.push(`/project/${this.props.projectId}/test`);
  }

  updateConversionStatus(stage) {
    let current = this.stageList.current;
    if (current) {
      current.updateConversionStatus(stage);
    }
  }

  updateStatistics(statistics, message, percent) {
    if (this.stageList.current) {
      this.stageList.current.updateStatistics(statistics, message, percent);
    }
  }

  setImageSet(images) {
    if (this.stageList.current) {
      this.stageList.current.setImageSet(images);
    }
  }
}

StageView.propTypes = {
  projectId: PropTypes.string,
};

StageView.defaultProps = {
  projectId: null,
};

const mapStateToProps = state => ({
  project: state.project,
  stagesData: getStagesData(state),
  openConversion: getIsReadyForConversion(state),
  openCreateHelperFile: getIsReadyForCreateHelperFile(state),
  openCreatePyTorchModelFile: getIsReadyForCreatePyTorchModelFile(state),
  isTerminateDialogOpen: getIsTerminateDialogOpen(state),
  terminateDialogTitle: getTerminateDialogTitle(state),
  terminateDialogMessage: getTerminateDialogMessage(state),
});

const mapDispatchToProps = {
  dispatchStagesFetch: stageFetch,
  viewOpenTerminateDialog,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(StageView);
