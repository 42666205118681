import {useIntl} from 'react-intl';
import React, {useState} from 'react';
import {DeploymentStatusType} from '../../../types/deployment/DeploymentResponse';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import WarningIcon from '@material-ui/icons/Warning';
import {IconButton} from '@material-ui/core';
import StopCicleIcon from '../../../assets/icons/StopCircle';
import {ConfirmDialog} from '../../../base-components/StudioConfirmDialog';
import './DeploymentActionButton.scss';
import {StudioPopover} from '../../../base-components/StudioPopover';
import {InputSource} from '../../../types/deployment/InferenceInputSource';
import {InputChoice} from '../DeploymentDetails/DeploymentDetails';

type DeploymentActionButtonProps = {
  status: DeploymentStatusType;
  formStatusOverride?: boolean;
  inputSourceLocationType?: InputChoice | null;
  inputSourceId?: string | null;
  inputSourceType?: InputSource;
  streamingUrl?: string | null;
  confirmTitle?: string;
  confirmMessage?: string;
  confirmSubmitLabel?: string;
  detailedTooltip?: boolean;
  onStart?: () => void;
  onStop?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
};

export const DeploymentActionButton = ({
  status,
  formStatusOverride,
  inputSourceLocationType,
  inputSourceId,
  inputSourceType,
  streamingUrl,
  onStart,
  onStop,
  onOpen,
  onClose,
  confirmTitle,
  confirmMessage,
  confirmSubmitLabel,
  detailedTooltip,
}: DeploymentActionButtonProps) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const intl = useIntl();
  const handleClick = () => {
    if (status === 'IDLE') {
      onStart && onStart();
    } else if (status !== 'ERROR') {
      setIsConfirmOpen(true);
      onOpen && onOpen();
    }
  };

  const icon =
    status === 'ERROR' ? (
      <WarningIcon
        id="deploy-warning-icon"
        className="deploy-action-button__icon--warning"
        fontSize="medium"
      />
    ) : status === 'IDLE' ? (
      <PlayCircleFilledIcon fontSize="medium" />
    ) : (
      <StopCicleIcon />
    );

  const areDeploymentSourcesValid = () => {
    if (inputSourceLocationType === 'DEVICE' && inputSourceId) {
      return true;
    } else if (inputSourceLocationType === 'EXTERNAL' && inputSourceType) {
      if (inputSourceId) {
        return true;
      } else if (streamingUrl) {
        return true;
      }
    }
    if (formStatusOverride) {
      return true;
    } else {
      return false;
    }
  };
  let tooltip = detailedTooltip
    ? 'prodDeployment.detection.invalid.sources.tooltip'
    : 'prodDeployment.detection.first.start.tooltip';
  tooltip = intl.formatMessage({id: tooltip});

  return (
    <>
      {status === 'IDLE' && !areDeploymentSourcesValid() && (
        <StudioPopover
          infoIconClass="deploy-action-button__tooltip"
          infoMessage={tooltip}
          ttPlacement="top"
        />
      )}
      <IconButton
        className="deploy-action-button"
        disabled={
          ['ERROR', 'STARTING', 'STOPPING', 'DEPLOYING', 'DELETED'].includes(status) ||
          (status === 'IDLE' && !areDeploymentSourcesValid())
        }
        type="submit"
        form="deployment-details-form"
        onClick={handleClick}
        data-testid={
          status === 'ERROR'
            ? 'deploy-warning-icon'
            : status === 'IDLE'
            ? 'deploy-start-icon'
            : 'deploy-stop-icon'
        }
      >
        {icon}
      </IconButton>
      <ConfirmDialog
        type="confirm"
        title={confirmTitle ?? intl.formatMessage({id: 'prodDeployment.confirmTitle'})}
        message={
          confirmMessage ?? intl.formatMessage({id: 'prodDeployment.confirmMessage'})
        }
        submitLabel={
          confirmSubmitLabel ??
          intl.formatMessage({id: 'prodDeployment.confirmSubmitLabel'})
        }
        open={isConfirmOpen}
        onClose={() => {
          setIsConfirmOpen(false);
          onClose && onClose();
        }}
        onOk={() => {
          setIsConfirmOpen(false);
          onClose && onClose();
          onStop && onStop();
        }}
      />
    </>
  );
};
