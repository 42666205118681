import {consoleAdd} from '../../store/console';
import {get, isFunction, pickBy} from 'lodash';
import {MESSAGE_TYPE} from '../StudioConsole/StudioConsole';
import {store} from '../../store';
import {toast} from '../../base-components/StudioToast';
import axios from 'axios';
import moment from 'moment';
import {viewOpenConsole} from '../../store/view';

const isSerializable = value => {
  if (isFunction(value)) {
    return false;
  }
  if (Array.isArray(value) && value.some(isFunction)) {
    return false;
  }
  return true;
};

const packageMessage = (type, config) => {
  return {
    type: type,
    time: moment().format('h:mm:ss a'),
    request: pickBy(config, isSerializable),
  };
};

const interceptResponseSuccess = res => {
  const storeMessagesToConsole = (type, messages) => {
    const packedMessages = messages.map(mes => {
      let pMes = packageMessage(type, res.config);

      if (typeof mes === 'string') {
        try {
          pMes = {...pMes, ...JSON.parse(mes)};
        } catch (e) {
          pMes.message = mes;
        }
      } else {
        pMes = {...pMes, ...mes};
      }
      return pMes;
    });

    packedMessages.forEach(pMes => store.dispatch(consoleAdd(pMes)));

    // Show messages on UI
    if (!res.config.headers.bypassDefaultErrorHandler) {
      if (packedMessages.length === 1) {
        const pMes = packedMessages[0];
        toast[type]({
          subtitle: pMes.message,
          actions: pMes.detailMessage
            ? [{label: 'Show details', callback: () => store.dispatch(viewOpenConsole())}]
            : null,
        });
      } else if (
        packedMessages.length > 1 &&
        [MESSAGE_TYPE.ERROR, MESSAGE_TYPE.WARN].includes(type)
      ) {
        toast[type]({
          subtitle:
            type === MESSAGE_TYPE.ERROR
              ? 'Multiple Errors Occurred'
              : 'Multiple Warnings Occurred',
          actions: [
            {label: 'View All', callback: () => store.dispatch(viewOpenConsole())},
          ],
        });
      }
    }
  };

  const errors = get(res, 'data.errors');
  const warnings = get(res, 'data.warnings');

  // Log the warn and error API calls
  errors && storeMessagesToConsole(MESSAGE_TYPE.ERROR, errors);
  warnings && storeMessagesToConsole(MESSAGE_TYPE.WARN, warnings);
  // Log the success API calls
  if (!errors || !errors.length) {
    store.dispatch(consoleAdd(packageMessage(MESSAGE_TYPE.SUCCESS, res.config)));
  }
};

const interceptResponseError = error => {
  // This may be done on the backend. Since no packaging was done for normal HTTP Errors
  // This is a discussion point on what backend should return or maybe open discussion to standardize it of some sort
  store.dispatch(
    consoleAdd({
      ...packageMessage(MESSAGE_TYPE.ERROR, error.config),
      message: error.message,
      detailMessage: error.response?.data?.message,
    })
  );

  // TODO: enable later on after further investigation on every page. Otherwise this fails the tests since the tests and pages are not initially written to handle HTTP errors. This is needed but need to sync up all calls with it. Disabling for now.
  // if (!error.config.headers.bypassDefaultErrorHandler) {
  //   toast.error({
  //     subtitle: error.message,
  //     actions: [{label: 'Details', callback: () => store.dispatch(viewOpenConsole())}],
  //   });
  // }
};

const init = error => {
  axios.interceptors.response.use(
    response => {
      interceptResponseSuccess(response);
      return response;
    },
    error => {
      interceptResponseError(error);
      return Promise.reject(error);
    }
  );
};

export {init as default};
