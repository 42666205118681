import axios from 'axios';
import {Formik} from 'formik';
import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import * as Yup from 'yup';
import {
  AuthPageBody,
  AuthPageContainer,
  AuthPageEmailField,
  AuthPageFooter,
  AuthPageForm,
  AuthPageHeader,
  AuthPageSubmitButton,
  AuthPageSummary,
} from '..';
import {formikErrors} from '../../Formik/formikErrors';
import ERROR from '../../../config/error';
import URL from '../../../config/url';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {ResetEmailSent} from '../ResetEmailSent';
import {AuthError} from '../AuthPage';
import {FormattedMessage, useIntl} from 'react-intl';

const validationSchema = Yup.object({
  email: Yup.string()
    .email(ERROR.INVALID_EMAIL())
    .required(ERROR.FIELD_REQUIRED()),
});

export function ForgotPassword() {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [error, setError] = useState(null);
  const [hasSubmitted, setSubmitted] = useState(false);

  if (hasSubmitted) {
    return <ResetEmailSent />;
  }

  const initialValues = {
    email: query.get('emailAddress') || '',
  };

  return (
    <AuthPageContainer>
      <Formik
        initialValues={initialValues}
        initialErrors={formikErrors(validationSchema, initialValues)}
        onSubmit={async fields => setError(await sendPasswordResetLink(fields))}
        validationSchema={validationSchema}
      >
        <AuthPageForm>
          <AuthPageHeader>
            <FormattedMessage id="auth.forgotPassword" />
          </AuthPageHeader>
          <AuthPageBody>
            <AuthPageSummary>
              <FormattedMessage id="auth.forgotPasswordSummary" />
            </AuthPageSummary>
            <AuthError error={error} />
            <AuthPageEmailField name="email" />
          </AuthPageBody>
          <AuthPageFooter>
            <FormButton
              value={intl.formatMessage({id: 'form.cancel'})}
              buttonRole="naked"
              onClick={() => history.push('/login')}
            />
            <AuthPageSubmitButton
              value={intl.formatMessage({id: 'auth.sendPasswordResetLink'})}
            />
          </AuthPageFooter>
        </AuthPageForm>
      </Formik>
    </AuthPageContainer>
  );

  async function sendPasswordResetLink(fields) {
    try {
      const res = await axios.post(
        URL.FORGOT_PASSWORD,
        {
          emailAddress: fields.email,
        },
        {headers: {bypassDefaultErrorHandler: true}}
      );

      if (res.data.errors) {
        return res.data.errors;
      }

      setSubmitted(true);
      return null;
    } catch (err) {
      return err;
    }
  }
}
