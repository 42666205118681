import React from 'react';
import BeforeImage from './images/gamma_correction-before.png';
import AfterImage1 from './images/gamma_correction-after1.png';
import AfterImage2 from './images/gamma_correction-after2.png';
import AfterImage3 from './images/gamma_correction-after3.png';
import AfterImage4 from './images/gamma_correction-after4.png';
import AfterImage5 from './images/gamma_correction-after5.png';
import './Help.scss';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>Gamma Correction</h4>
      <div className="flow-graph__help_content_detail">
        <p>In a single sensor digital camera, the light intensity from the object is focused on a sensor array to capture the image through a Color Filter Array (CFA). Bayer CFA is the widely used CFA, since it can preserve maximum details Each pixel in an image has a certain brightness level, called luminance. This value is between 0 to 1, where  0 means complete darkness (black), and 1 is brightest (white). Different camera or video recorder devices do not correctly capture luminance i.e, they are not  linear.</p>
        <p>Different display devices (monitor, phone screen, TV) do not display luminance correctly. Therefore the gamma correction function is used. Gamma correction function is used to correct image's luminance as follows:</p>
        <code>output luminance = gamma correction func[ input luminance ]</code>
        <p>The luminance is a value between 0 to 1.</p>
        <p>Our eyes do not perceive light the way cameras do. With a digital camera, when twice the number of photons hit the sensor, it receives twice the signal (a "linear" relationship). That is not how our eyes work. Instead, we perceive twice the light as being only a fraction brighter — and increasingly so for higher light intensities (a "nonlinear" relationship). Compared to a camera, we are much more sensitive to changes in dark tones than we are to similar changes in bright tones.</p>
        <p>The solid red curve is typical CRT monitor's voltage and brightness ratio. The dashed red curve is its inverse function, the gamma correction function. The gray dotted line is the corrected result.</p>
        <p>Gamma correction function is a function that maps luminance levels to compensate the non-linear luminance effect of display devices (or sync it to human perception on brightness).</p>
        <p>Gamma correction function” is defined by:</p>
        <code>Gamma Correction Function[x] = x^(γ)</code>
        <p>where γ is a constant. The value γ is said to be the gamma.</p>
        <p>*The more gamma value, more darkness and the less gamma value, the more brightness.</p>
        <div className="help_images">
          <figure>
            <img src={BeforeImage} alt="before" />
            <figcaption>Input</figcaption>
          </figure>
          <figure>
            <img src={AfterImage1} alt="after" />
            <figcaption>Output (Gamma = 2.2)</figcaption>
          </figure>
          <figure>
            <img src={AfterImage2} alt="before" />
            <figcaption>Output (Gamma = 2.8)</figcaption>
          </figure>
          <figure>
            <img src={AfterImage3} alt="after" />
            <figcaption>Output (Gamma = 1.2)</figcaption>
          </figure>
          <figure>
            <img src={AfterImage4} alt="after" />
            <figcaption>Output (Gamma = 0.6)</figcaption>
          </figure>
          <figure>
            <img src={AfterImage5} alt="after" />
            <figcaption>Output (Gamma = 0.45)</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};
