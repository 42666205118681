import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ShareIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        {/* <svg
          width="10px"
          height="10px"
          viewBox="0 0 10 10"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-851.000000, -7874.000000)"
            >
              <g id="Model-Action-Icons" transform="translate(56.000000, 7022.000000)">
                <g id="row" transform="translate(0.000000, 807.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="model-actions-/-view-details-/-Enabled,-Focus-model-actions-/-stop-/-Enabled,-Focus"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <polygon
                        id="Stop"
                        fill="currentColor"
                        points="7 7 17 7 17 17 7 17"
                      ></polygon>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg> */}
        <svg
          className="MuiSvgIcon-root jss287"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          tabIndex="-1"
          title="Share"
          data-ga-event-category="material-icons"
          data-ga-event-action="click"
          data-ga-event-label="Share"
        >
          <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"></path>
        </svg>
      </div>
    );
  }
}

ShareIcon.propTypes = {
  className: PropTypes.string,
};

export default ShareIcon;
