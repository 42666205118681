import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type UserState = {
  userId: string | null;
  groupId: string | null;
};

const initialState: UserState = {
  userId: null,
  groupId: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{
        userId: string;
        groupId: string;
      }>
    ) => {
      state.groupId = action.payload.groupId;
      state.userId = action.payload.userId;
    },
  },
});

export const {reducer: userReducer, actions} = userSlice;
