import {ImportFinalizationRequest} from '../../types/importing/ImportFinalizationRequest';
import {InferenceData} from '../../types/metadata/InferenceData';
import {request} from '../request';

export type InferenceDataFinalizeRequest = ImportFinalizationRequest;

export type InferenceDataFinalizeResponse = InferenceData;

export async function postInferenceDataFinalize(data: InferenceDataFinalizeRequest) {
  return request<InferenceDataFinalizeResponse>({
    method: 'post',
    url: '/dataset/import/inference/finalize',
    data,
  });
}
