import React from 'react';
import {PropTypes} from 'prop-types';
import {Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core';
import './BaseModalDialog.scss';

export const BaseModalDialog = ({
  open,
  onClose,
  title,
  content,
  buttons,
  size,
  testId,
}) => {
  const descriptionElementRef = React.useRef(null);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="base-modal-dialog"
      maxWidth={size || 'md'}
      fullWidth
      scroll="paper"
      data-testid={testId}
      id={testId}
    >
      <DialogTitle className="base-modal-dialog__title">{title}</DialogTitle>
      <DialogContent>
        <div
          id="scroll-dialog-description"
          className="base-modal-dialog__content_container"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {content}
        </div>
      </DialogContent>
      <DialogActions className="base-modal-dialog__button_container">
        {buttons}
      </DialogActions>
    </Dialog>
  );
};

BaseModalDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  testId: PropTypes.string,
  content: PropTypes.any,
  buttons: PropTypes.any,
  onClose: PropTypes.func,
};

BaseModalDialog.defaultProps = {
  open: false,
  title: null,
  testId: null,
  content: null,
  buttons: null,
  onClose: () => {},
};
