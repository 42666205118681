import React, {useState, useEffect} from 'react';
import {Socket} from 'rete-react-render-plugin';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {isEmpty, isEqual, debounce} from 'lodash';
import {ParamForm} from '../ParamForm';
import {StylesProvider} from '@material-ui/core/styles';
import jss from '../../../components/ProviderWrapper/JSSStyleInsertionPoint';
import {StudioTooltip} from '../../../base-components/StudioTooltip';
import {INFO_MESSAGES, ERROR_MESSAGES} from '../messages';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import './ISPNode.scss';

const NodeSocket = ({sockets, type, bindSocket, bindControl}) => {
  return sockets?.map(conn => (
    <div className={cn('flowgraph-node__socket', type)} key={conn.key}>
      <Socket type={type} socket={conn.socket} io={conn} innerRef={bindSocket} />
      {/*
      // TODO: activate later
      {conn.showControl() && (
        <Control
          className="input-control"
          control={conn.control}
          innerRef={bindControl}
        />
      )} */}
    </div>
  ));
};

const ERROR_PRIORITY = ['form', 'inCompatible'];
const ERROR_MESSAGE_MAP = {
  form: ERROR_MESSAGES.INVALID_CONFIG,
  inCompatible: ERROR_MESSAGES.MODULE_INCOMPATIBLE,
};
export const ISPNode = ({node, bindControl, bindSocket, editor}) => {
  const {data} = node;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(false);
  const [error, setError] = useState({});
  const [invalid, setInvalid] = useState(false);
  const [hasInfo, setHasInfo] = useState(false);
  const [message, setMessage] = useState(null);
  const [isMoving, setIsMoving] = useState(false);
  const [exist, setExist] = useState(true);
  const [mCoor, setMCoor] = useState({before: null, after: null});
  const outputs = Array.from(node.outputs.values());
  const inputs = Array.from(node.inputs.values());
  const isSelected = editor?.selected.contains(node);
  const inCon = node.inputs.get('in-0').connections;
  const outCon = node.outputs.get('out-0').connections;

  const onChange = val => {
    if (!isEqual(data.module_parameters, val)) {
      data.module_parameters = val;
    }
  };
  const onMouseDown = e => {
    setMCoor({before: {x: e.clientX, y: e.clientY}});
    setIsMoving(true);
  };
  const onMouseUp = e => setMCoor({...mCoor, after: {x: e.clientX, y: e.clientY}});

  const onDelete = e => {
    e.preventDefault();
    editor.removeNode(node);
  };

  const onFormError = err => {
    const hasError = !isEmpty(err);
    error.form !== hasError && setError({...error, form: hasError});
  };

  useEffect(() => {
    editor.on(
      'translate',
      debounce(() => setIsMoving(true), 5)
    );
    editor.on(
      'translated',
      debounce(() => setIsMoving(false), 750)
    );
  }, [editor]);

  useEffect(() => {
    editor.on('noderemove', removedNode => {
      if (node === removedNode) {
        setExist(false);
      }
    });
  }, [editor, node]);

  useEffect(() => {
    editor.on('process', params => {
      const {availableModules} = params;
      availableModules &&
        setError({
          ...error,
          inCompatible: availableModules.findIndex(i => i.id === node.data.id) === -1,
        });
    });
  }, [editor, error, node.data.id]);

  useEffect(() => {
    if (!invalid && inCon && outCon && !inCon.length && !outCon.length) {
      setHasInfo(true);
      setMessage(INFO_MESSAGES.CONNECT_MODULE);
    } else {
      setHasInfo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inCon, outCon, inCon.length, outCon.length, invalid]);

  useEffect(() => {
    if (!isEmpty(error)) {
      let isError = false;
      for (let i = 0; i < ERROR_PRIORITY.length; i++) {
        isError = !!error[ERROR_PRIORITY[i]];
        if (isError) {
          setMessage(ERROR_MESSAGE_MAP[ERROR_PRIORITY[i]]);
          break;
        }
      }
      setInvalid(isError);
      node.setHasError(isError);
    }
  }, [error, node]);

  useEffect(() => {
    if (mCoor.before && mCoor.after) {
      const {before, after} = mCoor;
      const wasDragged = before.x !== after.x || before.y !== after.y;
      !wasDragged && setOpen(!open);
      setIsMoving(false);
      setMCoor({before: null, after: null});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mCoor, mCoor.before, mCoor.after, setOpen]);

  useEffect(() => {
    setSelected(isSelected);
    !isSelected && setOpen(false);
  }, [isSelected]);

  useEffect(() => {
    // hide tooltip when toggle opening the module. Then toggle back after a delay.
    // This will give a better behavior for tooltip rendering
    setIsMoving(true);
    setTimeout(() => setIsMoving(false), 500);
  }, [open, setIsMoving]);

  return (
    <StylesProvider jss={jss}>
      <StudioTooltip
        PopperProps={{container: document.getElementById('flow-graph')}}
        placement="right"
        open={exist && !isMoving && (invalid || hasInfo)}
        title={message}
      >
        <div onMouseDown={onMouseDown} onMouseUp={() => setIsMoving(false)}>
          <div
            className={cn('flowgraph-node__sockets', {
              selected,
              open,
            })}
          >
            <NodeSocket
              sockets={inputs}
              type="input"
              bindSocket={bindSocket}
              bindControl={bindControl}
            />
            <NodeSocket sockets={outputs} type="output" bindSocket={bindSocket} />
          </div>
          <div
            className={cn('flowgraph-node', data.module_category_code, {
              selected: selected,
              error: invalid,
            })}
          >
            <ExpansionPanel
              elevation={0}
              className="flowgraph-node__expansion"
              expanded={selected && open}
            >
              <ExpansionPanelSummary
                className={cn('flowgraph-node__summary', data.module_category_code, {
                  error: invalid,
                })}
                expandIcon={<ExpandMoreIcon />}
                onMouseUp={onMouseUp}
              >
                <Typography component="small">{data.module_category}</Typography>
                <Typography component="h7">{data.label}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="flowgraph-node__detail">
                <ParamForm
                  data={data.module_parameters}
                  onError={onFormError}
                  onChange={onChange}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <IconButton
              aria-label="delete"
              className="flowgraph-node__delete_button"
              onClick={onDelete}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      </StudioTooltip>
    </StylesProvider>
  );
};

ISPNode.propTypes = {
  data: PropTypes.any,
};

ISPNode.defaultProps = {
  data: {},
};
