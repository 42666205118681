import React from 'react';
import cn from 'classnames';
import Paper from '@material-ui/core/Paper';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Button,
} from '@material-ui/core';
import {StudioKebabMenu} from '../../../base-components/StudioKebabMenu';
import {FormattedMessage, useIntl} from 'react-intl';
import {IntegrationPurpose} from '../../../types/integrations/IntegrationResponse';
import './Marketplaces.scss';

export type MarketplaceRow = {
  id?: string;
  marketplaceType: string;
  name?: string;
  complete?: boolean;
  storageType?: string;
  purpose: IntegrationPurpose;
};

type MarketplacesProps = {
  data: Array<MarketplaceRow> | null;
  className: string;
  onMenuEditClick: (row: MarketplaceRow) => void;
  onMenuDeleteClick: (row: MarketplaceRow) => void;
  onCreateClick: (row: MarketplaceRow) => void;
};

export const Marketplaces = ({
  data = [],
  className,
  onMenuEditClick,
  onMenuDeleteClick,
  onCreateClick,
}: MarketplacesProps) => {
  const intl = useIntl();

  const renderTableBody = () => (
    <>
      <TableHead>
        <TableRow>
          <TableCell className="integrations__header-cell">
            <FormattedMessage id="marketplaceConfig.marketplaceType" />
          </TableCell>
          <TableCell className="integrations__header-cell"></TableCell>
          <TableCell className="integrations__header-cell">
            <FormattedMessage id="marketplaceConfig.name" />
          </TableCell>
          <TableCell className="integrations__header-cell">
            <FormattedMessage id="marketplaceConfig.storageType" />
          </TableCell>
          <TableCell className="integrations__header-cell">
            <FormattedMessage id="marketplaceConfig.status" />
          </TableCell>
          <TableCell className="integrations__header-cell" />
        </TableRow>
      </TableHead>
      <Paper component={TableBody}>
        {data &&
          data.map(row => {
            const integrationExists = Boolean(row.id);
            return (
              <TableRow key={row.marketplaceType} className="integrations__body-row">
                <TableCell className="integrations__body-cell">
                  {row.marketplaceType}
                </TableCell>
                <TableCell className="integrations__body-cell">
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    className={cn(
                      'integrations__action',
                      integrationExists && 'integrations__action--edit'
                    )}
                    onClick={() =>
                      integrationExists ? onMenuEditClick(row) : onCreateClick(row)
                    }
                  >
                    <FormattedMessage
                      id={integrationExists ? 'form.edit' : 'form.create'}
                    />
                  </Button>
                </TableCell>
                <TableCell className="integrations__body-cell">{row.name}</TableCell>
                <TableCell className="integrations__body-cell">
                  {row.storageType}
                </TableCell>
                <TableCell className="integrations__body-cell">
                  {integrationExists && (
                    <span
                      className={cn(
                        'integrations__status',
                        !row.complete && 'integrations__status--in-progress'
                      )}
                    >
                      <FormattedMessage
                        id={
                          row.complete
                            ? 'marketplaceConfig.ready'
                            : 'marketplaceConfig.syncing'
                        }
                      />
                    </span>
                  )}
                </TableCell>
                <TableCell className="integrations__body-cell" align="right">
                  {integrationExists && (
                    <StudioKebabMenu
                      items={[
                        {
                          title: intl.formatMessage({id: 'form.delete'}),
                          onClick: () => onMenuDeleteClick(row),
                        },
                      ]}
                    />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
      </Paper>
    </>
  );

  return (
    <TableContainer
      className={cn('integrations', className)}
      data-testid="integrations-table"
      id="integrations-table"
    >
      <Table stickyHeader>{renderTableBody()}</Table>
    </TableContainer>
  );
};
