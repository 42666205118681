import axios from 'axios';
import {Formik} from 'formik';
import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import * as Yup from 'yup';
import ERROR from '../../../config/error';
import URL from '../../../config/url';
import {ActivationEmailSent} from '../ActivationEmailSent';
import {formikErrors} from '../../Formik/formikErrors';
import {
  AuthError,
  AuthPageBody,
  AuthPageContainer,
  AuthPageEmailField,
  AuthPageFooter,
  AuthPageForm,
  AuthPageHeader,
  AuthPageSecondaryButton,
  AuthPageSubmitButton,
} from '../AuthPage';
import {FormattedMessage, useIntl} from 'react-intl';

const validationSchema = Yup.object({
  emailAddress: Yup.string().required(ERROR.FIELD_REQUIRED()),
});

export const ResendActivation = () => {
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [hasSubmitted, setSubmitted] = useState(false);

  if (hasSubmitted) {
    return <ActivationEmailSent />;
  }

  const query = new URLSearchParams(location.search);
  const initialValues = {
    emailAddress: query.get('emailAddress') || '',
  };

  return (
    <AuthPageContainer>
      <Formik
        initialValues={initialValues}
        initialErrors={formikErrors(validationSchema, initialValues)}
        onSubmit={async fields => setError(await resendEmail(fields))}
        validationSchema={validationSchema}
      >
        <AuthPageForm>
          <AuthPageHeader>
            <FormattedMessage id="auth.activateAccount" />
          </AuthPageHeader>
          <AuthPageBody>
            <p>
              <FormattedMessage id="auth.activateFirst" />
            </p>
            {/* 
            We still need this info. Just waiting on our UX for decision on flow handling for this.
            <p>
              <FormattedMessage id="auth.contactSupport" />
            </p> */}
            <AuthError error={error} />
            <AuthPageEmailField name="emailAddress" data-testid="email-input" />
          </AuthPageBody>
          <AuthPageFooter>
            <AuthPageSecondaryButton
              value={intl.formatMessage({id: 'form.cancel'})}
              onClick={() => history.push('/login')}
            />
            <AuthPageSubmitButton
              value={intl.formatMessage({id: 'auth.sendAccountConfirmationEmail'})}
            />
          </AuthPageFooter>
        </AuthPageForm>
      </Formik>
    </AuthPageContainer>
  );

  async function resendEmail(fields) {
    try {
      const res = await axios.post(URL.RESEND_ACTIVATION, fields, {
        headers: {bypassDefaultErrorHandler: true},
      });

      if (res.data.errors) {
        return res.data.errors;
      }

      setSubmitted(true);
      return null;
    } catch (err) {
      const {data} = err.response;

      if (data?.message) {
        const matches = data.message.match(/: ([^:]*)$/);
        return matches ? [matches[1]] : [data.message];
      }

      return [data];
    }
  }
};
