import React from 'react';
import {
  FormGroup,
  Input,
  InputLabel,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import {PropTypes} from 'prop-types';
import cn from 'classnames';
import {useField} from 'formik';
import {validateField} from '../DynamicForm.helpers';
import {StudioPopover} from '../../../base-components/StudioPopover';
import {useDynamicFormField} from '../DynamicFormToFormikAdapter';
import {Add, Remove} from '@material-ui/icons';
import './DynamicShapeField.scss';

export const DynamicShapeField = ({
  label,
  className,
  name,
  required,
  disabled,
  tooltip,
}) => {
  useDynamicFormField(name);
  const [, meta, helpers] = useField({
    name,
    validate: value => validateField(value, 'SHAPE', required),
  });
  const errorText = meta.initialError || (meta.error && meta.touched) ? meta.error : '';
  const {value: values} = meta;
  const {setValue: setValues, setTouched} = helpers;

  const handleAdd = () => {
    setValues([...values, '0']);
  };

  const handleRemove = () => {
    setValues(values.slice(0, values.length - 1));
  };

  const handleChange = (event, index) => {
    const newValues = [...values];
    newValues[index] = event.target.value;
    setValues(newValues);
  };

  if (values === undefined) {
    return null;
  }

  return (
    <FormGroup className={cn('dynamic-shape-field', className)}>
      <InputLabel
        required={required}
        htmlFor={name}
        shrink
        error={!!errorText}
        disabled={disabled}
      >
        {label}
      </InputLabel>
      <div className="dynamic-shape-field__row">
        <div className="dynamic-shape-field__box-wrapper">
          {Boolean(values.length > 1) && (
            <IconButton
              color="primary"
              size="small"
              onClick={handleRemove}
              className="dynamic-shape-field__adjust"
            >
              <Remove />
            </IconButton>
          )}
          {values.map((val, index) => (
            <Input
              key={index}
              id={name}
              value={val}
              onChange={event => handleChange(event, index)}
              onBlur={() => setTouched(true)}
              disabled={disabled}
              disableUnderline
              className="dynamic-shape-field__box"
              error={!!errorText}
            />
          ))}
          <IconButton
            color="primary"
            size="small"
            onClick={handleAdd}
            className="dynamic-shape-field__adjust"
          >
            <Add />
          </IconButton>
        </div>
        <StudioPopover
          infoIconClass={cn(
            'dynamic-shape-field__tooltip',
            !tooltip && 'dynamic-shape-field__tooltip--hidden'
          )}
          infoMessage={tooltip}
        />
      </div>
      {errorText && <FormHelperText error={!!errorText}>{errorText}</FormHelperText>}
    </FormGroup>
  );
};

DynamicShapeField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
};

DynamicShapeField.defaultProps = {
  className: null,
  required: false,
  disabled: false,
  type: 'string',
};
