import React, {useContext, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import {GridViewer as ThumbViewer} from '../../DataViewer/GridViewer';
import {Paper, Toolbar, IconButton, Box, TextFieldProps} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import ImageListFilter from './ImageListFilter';
import {StudioSearch} from '../../../base-components/StudioSearch';
import GridIcon from '@material-ui/icons/Apps';
import {ImageType} from '../../../types/dataset/DatasetGetAnnotationsResponse';
import {isEmpty} from 'lodash';
import {useIntl} from 'react-intl';
import {DATASET_SUB_OP as OP} from '../../../config/url';
import {THUMBNAIL_SIZE_GRID, THUMBNAIL_SIZE_LIST} from './const';
import {StudioImageThumbnail} from '../../../base-components/StudioImageThumbnail';
import {useResponsivePageSize} from '../../../base-components/StudioResponsivePageSize/StudioResponsivePageSize';
import {PageListContext} from './PageListProvider';
import {DataPreparationOrigin} from '../../../types/dataset/DataPreparationContainer';
import {getOriginUrl} from '../helpers';
import {DetectionAnnotationDictionary} from './DetectionPrepare';

import './ImageThumbView.scss';

export type ImageStateId = 'reviewed' | 'notReviewed';
export type FilterId = 'sortBy' | 'viewBy';
export type SortId = 'recentlyAddedAsc' | 'recentlyAddedDesc' | 'recentlyAdded';
export type ViewId = 'grid' | 'list';

export type ImageDataType = {
  isNew: boolean;
  isReviewed: boolean;
  dateModified?: Date | null;
  data: ImageType;
  annotationCount?: number;
};

type ReducerState = {
  searchText: string;
  viewBy: ViewId;
  sortBy: SortId;
  imageStateBy: ImageStateId[];
};

const initialState: ReducerState = {
  searchText: '',
  viewBy: 'grid',
  sortBy: 'recentlyAddedAsc',
  imageStateBy: [],
};

const GRID_DIMENSION = {
  width: 93,
  height: 93,
  marginX: 16,
  marginY: 17,
};
const LIST_DIMENSION = {
  height: 37,
  marginY: 3,
};

const viewSlice = createSlice({
  name: 'dataset/detection/list',
  initialState,
  reducers: {
    setSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setViewBy(state, action: PayloadAction<ViewId>) {
      state.viewBy = action.payload;
    },
    setSortBy(state, action: PayloadAction<SortId>) {
      state.sortBy = action.payload;
    },
    setImageStateBy(state, action: PayloadAction<ImageStateId[]>) {
      state.imageStateBy = action.payload;
    },
    resetFilterState(state) {
      state.sortBy = initialState.sortBy;
      state.imageStateBy = [];
    },
  },
});

export type ImageThumbViewProps = {
  projectId: string;
  isNew?: boolean;
  images?: ImageType[];
  selection?: ImageType | null;
  origin: DataPreparationOrigin;
  onClick(image: ImageType): void;
  annotations?: DetectionAnnotationDictionary;
};

export const ImageThumbView = ({
  projectId,
  images,
  annotations,
  selection,
  isNew = true,
  origin,
  onClick: onParentClick,
}: ImageThumbViewProps) => {
  const [state, dispatch] = React.useReducer(viewSlice.reducer, initialState);
  const {viewBy, sortBy, imageStateBy, searchText} = state;
  const {
    setViewBy,
    setImageStateBy,
    setSortBy,
    setSearchText,
    resetFilterState,
    // setPage,
  } = viewSlice.actions;
  const [imageData, setImageData] = useState<ImageDataType[] | undefined>([]);
  const listAreaRef = useRef<HTMLDivElement>(null);
  const listCount = useResponsivePageSize({
    containerRef: listAreaRef,
    mode: viewBy,
    gridDimension: GRID_DIMENSION,
    listDimension: LIST_DIMENSION,
  });
  const {
    page,
    listContent,
    sortedList,
    setItemsPerPage,
    setPageCount,
    setListCount,
    setTotalListCount,
    setListContent,
    setSortedList,
  } = useContext(PageListContext);
  setItemsPerPage(listCount || 0);

  useEffect(() => {
    // Wrap the images to more stateful container
    if (!isEmpty(images) && annotations) {
      const imageDataArr = images?.map(image => ({
        isNew: true,
        isReviewed: !!imageData?.find(val => val.data === image && val.isReviewed),
        dateModified: image.date_captured ? new Date(image.date_captured) : null,
        data: image,
        annotationCount: annotations[image.id] ? annotations[image.id].length : 0,
      }));
      setImageData(imageDataArr);
    }
    setTotalListCount(images?.length || 0);
    // eslint-disable-next-line
  }, [images, annotations]);

  useEffect(() => {
    if (sortedList) {
      const itemCount = listCount || 0;
      const startIndex = (page - 1) * itemCount;
      const fList = sortedList?.slice(startIndex, startIndex + itemCount) || [];
      setListCount(sortedList?.length || 0);
      setPageCount(Math.ceil((sortedList?.length || 0) / itemCount));
      setListContent(fList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedList, listCount, page]);

  useEffect(() => {
    if (imageData) {
      const listing = imageData?.filter(filterImages).sort(getSortFunc());
      setSortedList(listing);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageData, sortBy, imageStateBy, searchText]);

  const onFilterChange = (groupId: string, id: string, value: string | string[]) => {
    groupId === 'sortBy' && dispatch(setSortBy(value as SortId));
    groupId === 'imageStateBy' && dispatch(setImageStateBy(value as ImageStateId[]));
  };

  const onClick = (image: ImageDataType) => {
    image.isReviewed = true;
    setImageData(imageData);
    onParentClick(image.data);
  };

  const filterImages = (el: ImageDataType) => {
    let imageStateOk = !imageStateBy.length;
    let {file_name} = el?.data;
    let searchOk = file_name?.toLowerCase().startsWith(searchText.toLowerCase());
    let i = 0;
    while (!imageStateOk && i < imageStateBy.length) {
      imageStateOk =
        (el.isReviewed && imageStateBy.includes('reviewed')) ||
        (!el.isReviewed && imageStateBy.includes('notReviewed'));
      i++;
    }
    return imageStateOk && searchOk;
  };

  const sortByRecentlyAdded = (a: ImageDataType, b: ImageDataType) => {
    return a.isNew && b.isNew ? 0 : !a.isNew ? -1 : 1;
  };
  const sortByRecentlyAddedDesc = (a: ImageDataType, b: ImageDataType) => {
    const dA = a?.dateModified?.getTime();
    const dB = b?.dateModified?.getTime();
    return dA && dB ? dB - dA : 0;
  };
  const sortByRecentlyAddedAsc = (a: ImageDataType, b: ImageDataType) => {
    const dA = a?.dateModified?.getTime();
    const dB = b?.dateModified?.getTime();
    return dA && dB ? dA - dB : 0;
  };

  const getSortFunc = () => {
    switch (sortBy) {
      case 'recentlyAdded':
        return sortByRecentlyAdded;
      case 'recentlyAddedDesc':
        return sortByRecentlyAddedDesc;
      case 'recentlyAddedAsc':
        return sortByRecentlyAddedAsc;
    }
  };

  const getUrl = (fileName: string | undefined | null, ids: string, size: number) => {
    const method = getOriginUrl(origin);
    return `${method(projectId, OP.GET_BY_ID)}?id=${ids}&resolution=${size}:${size}`;
  };

  const ids = listContent?.map(el => el.data.id).join() || '';
  return (
    <Paper className="detection-view__thumbnail_container" elevation={0}>
      <Toolbar className="detection-view__thumbnail_toolbar" variant="dense">
        <Box flexGrow={1}></Box>
        <SearchField
          value={searchText}
          onChange={e => dispatch(setSearchText(e.target.value))}
        />
        <IconButton
          size="small"
          onClick={() => {
            dispatch(setViewBy(viewBy === 'grid' ? 'list' : 'grid'));
          }}
        >
          {viewBy === 'grid' ? <GridIcon /> : <ListIcon />}
        </IconButton>
        <ImageListFilter
          sortBy={sortBy}
          imageStateBy={imageStateBy}
          onChange={onFilterChange}
          onReset={() => dispatch(resetFilterState())}
        />
      </Toolbar>
      <div className="detection-view__thumbnail_list_container" ref={listAreaRef}>
        <ThumbViewer className="detection-view__content_thumbnail">
          {!listContent?.length ? (
            <h5 className="detection-view__thumbnail_no_result"> No result</h5>
          ) : (
            listContent.map((el, i: number) => {
              return viewBy === 'grid' ? (
                <ThumbnailGridItem
                  key={i}
                  id={String(i)}
                  onClick={() => onClick(el)}
                  selected={selection ? el.data.id === selection.id : false}
                >
                  <StudioImageThumbnail
                    width={THUMBNAIL_SIZE_GRID}
                    height={THUMBNAIL_SIZE_GRID}
                    src={getUrl(el?.data.file_name, ids, THUMBNAIL_SIZE_GRID)}
                    position={i}
                  />
                  <AnnotationCount value={el.annotationCount} />
                </ThumbnailGridItem>
              ) : (
                <ThumbnailListItem
                  key={i}
                  id={String(i)}
                  onClick={() => onClick(el)}
                  selected={el.data === selection}
                  label={el.data.file_name}
                >
                  <StudioImageThumbnail
                    width={THUMBNAIL_SIZE_LIST}
                    height={THUMBNAIL_SIZE_LIST}
                    src={getUrl(el?.data.file_name, ids, THUMBNAIL_SIZE_LIST)}
                    position={i}
                  />
                </ThumbnailListItem>
              );
            })
          )}
        </ThumbViewer>
      </div>
    </Paper>
  );
};

type GridThumbnailProps = {
  id: string;
  label?: string;
  children: React.ReactNode;
  selected?: boolean;
  onClick: (id: string) => void;
};

export const ThumbnailGridItem = ({
  children,
  selected,
  onClick,
  id,
}: GridThumbnailProps) => (
  <Card
    variant="outlined"
    className={cn('detection-view__thumbnail', {
      selected: selected,
    })}
  >
    <CardActionArea
      className="detection-view__thumbnail_action_area"
      onClick={() => onClick(id)}
    >
      <CardContent className="detection-view__thumbnail_content">{children}</CardContent>
    </CardActionArea>
  </Card>
);

export const ThumbnailListItem = ({
  children,
  selected,
  onClick,
  id,
  label,
}: GridThumbnailProps) => (
  <Card
    variant="outlined"
    className={cn('detection-view__thumbnail_list', {
      selected: selected,
    })}
  >
    <CardActionArea
      className="detection-view__thumbnail_action_area"
      onClick={() => onClick(id)}
    >
      <CardContent className="detection-view__thumbnail_list_content">
        <div className="detection-view__thumbnail_list_image">{children}</div>
        <h5>{label}</h5>
      </CardContent>
    </CardActionArea>
  </Card>
);

const SearchField = (props: {value: string; onChange: TextFieldProps['onChange']}) => {
  const [isSearch, setIsSearch] = useState(false);
  const intl = useIntl();

  return (
    <div className="detection-view__thumbnail_search_input">
      {isSearch ? (
        <StudioSearch
          autoFocus
          size="small"
          placeholder={intl.formatMessage({id: 'form.search'})}
          onChange={props.onChange}
          value={props.value}
          onBlur={() => {
            !props.value.trim() && setIsSearch(false);
          }}
        />
      ) : (
        <IconButton size="small" onClick={e => setIsSearch(true)}>
          <SearchIcon />
        </IconButton>
      )}
    </div>
  );
};

const AnnotationCount = (props: {value: number | undefined}) => {
  return props.value ? (
    <div className="detection-view__thumbnail_annotation_count"> {props.value}</div>
  ) : (
    <></>
  );
};
