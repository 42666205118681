import React, {useEffect} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router';
import {
  dataPrepareActions,
  DataPrepareStep,
  datasetLabelingApply,
  loadAnnotations,
  submitAnnotations,
  originToContainerMap,
  resumeDataPrep,
  revertDatasetMerge,
} from '../../store/dataPrepare/dataPrepare';
import {DataView} from './DataUploadStep/DataView';
import {ClassificationView} from './ClassificationStep/ClassificationView';
import {Fade} from '@material-ui/core';
import {LabelView} from './LabelUploadStep/LabelView';
import Waiting from '../../base-components/StudioWaiting/Waiting';
import {MethodView} from './MethodSelectStep/MethodView';
import {
  AnnotationDataType,
  DataPrepareType,
} from '../../types/dataset/DatasetGetAnnotationsResponse';
import {RootState, useAppDispatch} from '../../store';
import {unwrapResult} from '@reduxjs/toolkit';
import {DetectionView} from './DetectionStep/DetectionView';
import {DeploymentDataView} from './DeploymentDataStep/DeploymentDataView';
import {toast} from '../../base-components/StudioToast';
import {DataPreparationOrigin} from '../../types/dataset/DataPreparationContainer';
import {LabelMethodView} from './LabelMethodStep/LabelMethodView';
import './DataPrepare.scss';

const {setStep, setName, setStatus, setDescription, setType, reset} = dataPrepareActions;

export const DataPrepare = () => {
  const intl = useIntl();
  const history = useHistory();
  const {projectId, context} = useParams<{
    projectId: string;
    context: DataPreparationOrigin;
  }>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();
  const step = useSelector((state: RootState) => state.dataPrepare.step);
  const status = useSelector((state: RootState) => state.dataPrepare.status);
  const iatBusy = useSelector((state: RootState) => state.iat.isBusy);
  const type = useSelector((state: RootState) => state.dataPrepare.type);
  const uploadProgress = useSelector(
    (state: RootState) => state.dataPrepare.uploadProgress
  );
  const containers = useSelector((state: RootState) => state.dataPrepare.containers);
  const annotationData = useSelector(
    (state: RootState) => state.dataPrepare.annotationData
  );

  const containerKey = originToContainerMap[context];
  const dataPreparationContainer = containers[containerKey];
  const previousOrigin = dataPreparationContainer?.previous?.origin;

  const newDatasetId = dataPreparationContainer?.id;
  const sourceDeploymentId = dataPreparationContainer?.sourceDeploymentId;

  const isLoading = status === 'LOADING';
  const isSubmitting = status === 'SUBMITTING';
  const isUploading = status === 'UPLOADING';
  const inProgress = isLoading || isSubmitting || isUploading || iatBusy;
  const imagesExistAlready = Boolean(annotationData?.images?.length);
  const labelsExistAlready = Boolean(annotationData?.categories?.length);

  const resume = params.get('resume') === 'true';

  useEffect(() => {
    if (projectId && context && resume) {
      dispatch(resumeDataPrep({projectId, context}));
    }
    return () => {
      dispatch(reset());
    };
  }, [projectId, context, dispatch, resume]);

  useEffect(() => {
    const origin = dataPreparationContainer?.origin;

    const initializeDataPrep = async () => {
      dispatch(setStatus('LOADING'));
      if (!origin) {
        dispatch(setStep(DataPrepareStep.SELECT_PREPARE_TYPE));
        dispatch(setStatus('IDLE'));
      } else if (
        projectId &&
        (origin === 'raw' || origin === 'deployment' || origin === 'dataset')
      ) {
        try {
          const action = await dispatch(loadAnnotations({projectId, origin}));
          const initialAnnotationData = unwrapResult(action);
          const {categories, images, info, annotations} = initialAnnotationData;
          const name = dataPreparationContainer?.name;
          const description = dataPreparationContainer?.description;
          let type =
            origin === 'dataset'
              ? annotations?.find(annotation => annotation.bbox != null)
                ? DataPrepareType.DETECTION
                : DataPrepareType.CLASSIFICATION
              : info?.type;

          name && dispatch(setName(name));
          description && dispatch(setDescription(description));
          type && dispatch(setType(type));
          if (origin === 'deployment') {
            dispatch(setStep(DataPrepareStep.DEPLOYMENT_DATA_REVIEW));
          } else if (origin === 'raw') {
            if (type == null || name == null) {
              dispatch(setStep(DataPrepareStep.SELECT_PREPARE_TYPE));
            } else if (!images.length) {
              dispatch(setStep(DataPrepareStep.DATA_UPLOAD));
            } else if (!categories.length) {
              dispatch(setStep(DataPrepareStep.LABEL_UPLOAD));
            } else if (type === DataPrepareType.CLASSIFICATION) {
              dispatch(setStep(DataPrepareStep.CLASSIFICATION_PREPARE));
            } else if (type === DataPrepareType.DETECTION) {
              dispatch(setStep(DataPrepareStep.DETECTION_PREPARE));
            }
          }
        } catch (e) {
          console.error(e);
          dispatch(setStep(DataPrepareStep.SELECT_PREPARE_TYPE));
        } finally {
          dispatch(setStatus('IDLE'));
        }
      }
    };

    initializeDataPrep();
  }, [dataPreparationContainer, projectId, dispatch]);

  const handleSubmit = async (finalAnnotations: AnnotationDataType) => {
    if (finalAnnotations && type != null) {
      try {
        const origin = dataPreparationContainer?.origin;
        dispatch(setStatus('SUBMITTING'));
        const action = await dispatch(
          origin === 'dataset'
            ? datasetLabelingApply({projectId, type})
            : submitAnnotations({projectId, annotationData: finalAnnotations, type})
        );
        // unwrapResult output is not used but it triggers the right outcome by throwing exception on a 500
        // if we dont have it the 500 result to the requests failing but goes to the design page.
        const res: any = unwrapResult<any>(action);
        if (!(res?.data?.errors && res?.data?.errors.length)) {
          dispatch(reset());
          history.push(`/project/${projectId}/upload`);
        }
      } catch (e) {
        if (e instanceof Error) {
          toast.error({
            subtitle: e.message,
          });
        }
      } finally {
        dispatch(setStatus('IDLE'));
      }
    }
  };

  const handleMergeRevert = async () => {
    try {
      await dispatch(revertDatasetMerge({projectId}));
      history.push(`/project/${projectId}/data/prepare/deployment`);
    } catch (e) {
      console.error(e);
    }
  };

  const goForward = () => {
    if (step === DataPrepareStep.SELECT_PREPARE_TYPE) {
      if (imagesExistAlready) {
        dispatch(setStep(DataPrepareStep.DATA_REVIEW));
      } else {
        dispatch(setStep(DataPrepareStep.DATA_UPLOAD));
      }
    } else if (step === DataPrepareStep.DATA_UPLOAD) {
      dispatch(setStep(DataPrepareStep.DATA_REVIEW));
    } else if (step === DataPrepareStep.DATA_REVIEW) {
      if (labelsExistAlready) {
        dispatch(setStep(DataPrepareStep.LABEL_REVIEW));
      } else {
        dispatch(setStep(DataPrepareStep.LABEL_UPLOAD));
      }
    } else if (step === DataPrepareStep.LABEL_UPLOAD) {
      dispatch(setStep(DataPrepareStep.LABEL_REVIEW));
    } else if (step === DataPrepareStep.LABEL_REVIEW) {
      dispatch(setStep(DataPrepareStep.SELECT_LABELLING_TYPE));
    } else if (step === DataPrepareStep.SELECT_LABELLING_TYPE) {
      dispatch(
        setStep(
          type === DataPrepareType.DETECTION
            ? DataPrepareStep.DETECTION_PREPARE
            : DataPrepareStep.CLASSIFICATION_PREPARE
        )
      );
    }
  };

  const goBack = () => {
    if (step === DataPrepareStep.DATA_UPLOAD) {
      dispatch(setStep(DataPrepareStep.SELECT_PREPARE_TYPE));
    } else if (step === DataPrepareStep.DATA_REVIEW) {
      if (imagesExistAlready) {
        dispatch(setStep(DataPrepareStep.SELECT_PREPARE_TYPE));
      } else {
        dispatch(setStep(DataPrepareStep.DATA_UPLOAD));
      }
    } else if (step === DataPrepareStep.LABEL_UPLOAD) {
      dispatch(setStep(DataPrepareStep.DATA_REVIEW));
    } else if (step === DataPrepareStep.LABEL_REVIEW) {
      if (labelsExistAlready) {
        dispatch(setStep(DataPrepareStep.DATA_REVIEW));
      } else {
        dispatch(setStep(DataPrepareStep.LABEL_UPLOAD));
      }
    } else if (step === DataPrepareStep.SELECT_LABELLING_TYPE) {
      dispatch(setStep(DataPrepareStep.LABEL_REVIEW));
    } else if (
      step === DataPrepareStep.CLASSIFICATION_PREPARE ||
      step === DataPrepareStep.DETECTION_PREPARE
    ) {
      dispatch(setStep(DataPrepareStep.SELECT_LABELLING_TYPE));
    }
  };

  const renderStep = () => {
    if (step === DataPrepareStep.DEPLOYMENT_DATA_REVIEW) {
      return (
        <DeploymentDataView
          projectId={projectId}
          inProgress={inProgress}
          deploymentId={sourceDeploymentId}
        />
      );
    } else if (step === DataPrepareStep.SELECT_PREPARE_TYPE) {
      return (
        <MethodView
          projectId={projectId}
          datasetId={newDatasetId}
          inProgress={inProgress}
          onForward={goForward}
          showRevert={previousOrigin === 'deployment'}
          onRevert={handleMergeRevert}
        />
      );
    } else if (
      step === DataPrepareStep.DATA_UPLOAD ||
      step === DataPrepareStep.DATA_REVIEW
    ) {
      return (
        <DataView
          projectId={projectId}
          resourceId={dataPreparationContainer?.id}
          inProgress={inProgress}
          origin={dataPreparationContainer?.origin || 'raw'}
          onForward={goForward}
          onBack={goBack}
        />
      );
    } else if (
      step === DataPrepareStep.LABEL_UPLOAD ||
      step === DataPrepareStep.LABEL_REVIEW
    ) {
      return (
        <LabelView
          projectId={projectId}
          datasetId={newDatasetId}
          inProgress={inProgress}
          onForward={goForward}
          onBack={goBack}
        />
      );
    } else if (step === DataPrepareStep.SELECT_LABELLING_TYPE) {
      return (
        <LabelMethodView
          datasetId={newDatasetId}
          onForward={goForward}
          onBack={goBack}
          projectId={projectId}
        />
      );
    } else if (step === DataPrepareStep.DETECTION_PREPARE) {
      return (
        <DetectionView
          projectId={projectId}
          datasetId={newDatasetId}
          inProgress={inProgress}
          origin={dataPreparationContainer?.origin || 'raw'}
          onBack={goBack}
          onSubmit={handleSubmit}
        />
      );
    } else if (step === DataPrepareStep.CLASSIFICATION_PREPARE) {
      return (
        <ClassificationView
          projectId={projectId}
          datasetId={newDatasetId}
          inProgress={inProgress}
          origin={dataPreparationContainer?.origin || 'raw'}
          onBack={goBack}
          onSubmit={handleSubmit}
        />
      );
    }
  };

  return (
    <div className="data-prepare">
      <Fade in={inProgress}>
        <div className="data-prepare__loading">
          <Waiting
            diameter={100}
            loadingText={intl.formatMessage({
              id: isSubmitting
                ? 'dataPrep.submitting'
                : isUploading
                ? 'dataPrep.uploading.image'
                : 'dataPrep.loading',
            })}
          >
            {isUploading && uploadProgress && (
              <FormattedMessage
                id="dataPrep.uploading.progress"
                values={uploadProgress}
              />
            )}
          </Waiting>
        </div>
      </Fade>
      {renderStep()}
    </div>
  );
};
