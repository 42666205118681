import {List, ListItem, ListItemText, TextFieldProps} from '@material-ui/core';
import {Check, ViewComfyRounded, ViewListRounded, Sort} from '@material-ui/icons';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as React from 'react';
import {StudioSearch} from '../../../base-components/StudioSearch';
import {StudioTextField} from '../../../base-components/StudioTextField';
import {DataPreparationOrigin} from '../../../types/dataset/DataPreparationContainer';
import {RootState, useAppDispatch} from '../../../store';
import {getPredictions} from '../../../store/iat';
import {
  AnnotationType,
  CategoryType,
  ImageType,
} from '../../../types/dataset/DatasetGetAnnotationsResponse';
import {
  FileItem,
  FileStyle,
  FileViewer,
  ImageAdornment,
  ViewId,
} from '../FileViewer/FileViewer';
import {useSelector} from 'react-redux';
import {filter} from 'lodash';
import './ClassificationPrepare.scss';

type SelectedImages = {[id: string]: boolean};

type ReducerState = {
  labelField: string;
  page: number;
  sidebarLabel: string;
  view: ViewId;
  selectedImages: SelectedImages;
  listContent: number[];
};

const PREDICTION_STYLE: FileStyle = {
  color: 'black',
  background: '#fcdefc',
};

const initialState: ReducerState = {
  labelField: '',
  page: 0,
  sidebarLabel: '',
  view: 'grid',
  selectedImages: {},
  listContent: [],
};

const viewerSlice = createSlice({
  name: 'dataset/viewer',
  initialState,
  reducers: {
    setLabelField(state, action: PayloadAction<string>) {
      state.labelField = action.payload;
    },
    setSidebarLabel(state, action: PayloadAction<string>) {
      state.sidebarLabel = action.payload;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setView(state, action: PayloadAction<ViewId>) {
      state.view = action.payload;
    },
    setSelectedImages(state, action: PayloadAction<SelectedImages>) {
      state.selectedImages = action.payload;
    },
    setListContent(state, action: PayloadAction<number[]>) {
      state.listContent = action.payload;
    },
  },
});

export type ClassificationPrepareProps = {
  projectId: string;
  datasetId?: string;
  images: ImageType[];
  labels: CategoryType[];
  annotations: AnnotationType[];
  origin: DataPreparationOrigin;
  onChange: (updatedAnnotations: AnnotationType[]) => void;
  onAutoLabel: (annotations: AnnotationType[], isPrediction: boolean) => void;
  onPredictionChange: (predictions: AnnotationType[]) => void;
};

export const ClassificationPrepare = ({
  projectId,
  datasetId,
  images,
  labels,
  annotations,
  origin,
  onChange,
  onAutoLabel,
  onPredictionChange,
}: ClassificationPrepareProps) => {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [state, dispatch] = React.useReducer(viewerSlice.reducer, initialState);
  const [predictions, setPredictions] = React.useState<AnnotationType[]>([]);
  const appDispatch = useAppDispatch();
  const isAutoLabel = useSelector((state: RootState) => state.dataPrepare.isAutoLabel);
  const autoLabelReady = useSelector((state: RootState) => state.iat.autoLabelReady);
  const status = useSelector((state: RootState) => state.dataPrepare.status);
  const {labelField, sidebarLabel, view, selectedImages, page, listContent} = state;
  const {
    setLabelField,
    setSidebarLabel,
    setView,
    setSelectedImages,
    setListContent,
    setPage,
  } = viewerSlice.actions;

  const labelIdToName = labels.reduce<{[id: string]: string}>((acc, curr) => {
    acc[curr.id] = curr.name;
    return acc;
  }, {});

  const imageIdToAnnotation = annotations.reduce<{
    [imageId: string]: AnnotationType;
  }>((acc, curr) => {
    acc[curr.image_id] = curr;
    return acc;
  }, {});

  const filteredItems: FileItem[] = images
    .map(image => {
      const category_id = imageIdToAnnotation[image.id]?.category_id;
      return {
        id: image.id.toString(),
        selected: selectedImages[image.id],
        name: image.file_name,
        size: image.size,
        height: image.height,
        width: image.width,
        lastModified: new Date(image.date_captured),
        label: category_id != null ? labelIdToName[category_id] : undefined,
        style: !!predictions.find((el: AnnotationType) => el.image_id === image.id)
          ? PREDICTION_STYLE
          : undefined,
      };
    })
    .filter(
      item => !item.label || item.label.toLowerCase().startsWith(labelField.toLowerCase())
    );

  const handleLabelClick = (label: CategoryType) => {
    const maxAnnotationId = annotations.reduce(
      (acc, curr) => Math.max(acc, curr.id ?? 0),
      0
    );
    let updatedAnnotations: AnnotationType[] = [];
    annotations.forEach(
      annotation =>
        selectedImages[annotation.image_id] &&
        updatedAnnotations.push({...annotation, category_id: label.id})
    );

    const newAnnotations = images
      .filter(image => selectedImages[image.id] && !imageIdToAnnotation[image.id])
      .map<AnnotationType>((image, index) => ({
        id: maxAnnotationId + index + 1,
        image_id: image.id,
        category_id: label.id,
      }));

    dispatch(setSelectedImages({}));
    const newList = [...updatedAnnotations, ...newAnnotations];
    setPredictions(
      filter(predictions, el => !newList.find(item => item.image_id === el.image_id))
    );
    onChange(newList);
  };

  const handleFileSelect = (id: string) => {
    const isSelected = Boolean(!selectedImages[id]);
    dispatch(setSelectedImages({...selectedImages, [id]: isSelected}));
  };

  const getAutoLabels = async (ids: number[]) => {
    if (isAutoLabel && autoLabelReady && ids.length && annotations.length) {
      appDispatch(
        getPredictions({projectId, resourceId: datasetId, ids, samples: 1})
      ).then(({payload}) => {
        // Predictions ids are regenerated since they start from 0-n which creates conflicts with the BE once it is saved back
        if (payload.body) {
          const nIds = payload.body.annotations.map((el: any) => ({
            ...el,
            id: Math.floor(Math.random() * 100000),
          }));
          setPredictions(nIds);
          onAutoLabel && onAutoLabel(nIds as AnnotationType[], true);
        }
      });
    }
  };

  const onPageChange = (newPage: number, ids: any[]) => {
    if (newPage !== page && ids && ids.length) {
      dispatch(setPage(newPage));
      const imageIds = annotations.map(el => el.image_id);
      const fIds = ids.map(id => id * 1).filter(id => !imageIds.includes(id));
      dispatch(setListContent(fIds));
      if (!!predictions.length) {
        onChange(predictions);
        setPredictions([]);
      }
    }
  };

  React.useEffect(() => {
    if (page && listContent.length && status === 'IDLE') {
      getAutoLabels(listContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, listContent, autoLabelReady, status]);

  React.useEffect(() => onPredictionChange(predictions), [
    predictions,
    onPredictionChange,
  ]);

  return (
    <div className="classification-prepare" ref={parentRef}>
      <div className="classification-prepare__content">
        <div className="classification-prepare__menu">
          <div className="classification-prepare__count">
            <span>{images.length} images</span>{' '}
            <span>({annotations.length} labelled)</span>
          </div>
          <LabelSearch
            labelField={labelField}
            onChange={e => dispatch(setLabelField(e.target.value))}
          />
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={(_, newView) => newView && dispatch(setView(newView))}
            aria-label="text alignment"
          >
            <ToggleButton value="grid" aria-label="grid view">
              <ViewComfyRounded />
            </ToggleButton>
            <ToggleButton value="list" aria-label="list view">
              <ViewListRounded />
            </ToggleButton>
            <ToggleButton value="label" aria-label="sort by label">
              <Sort />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="classification-prepare__body">
          {labels.length > 0 && (
            <List className="classification-prepare__labels">
              <ListItem className="classification-prepare__sidebar-search">
                <StudioTextField
                  placeholder="Search..."
                  onChange={e => dispatch(setSidebarLabel(e.target.value))}
                  value={sidebarLabel}
                />
              </ListItem>
              {labels
                .filter(label =>
                  label.name.toLowerCase().startsWith(sidebarLabel.toLowerCase())
                )
                .sort((first, second) => {
                  const isFirstSelected = annotations.some(
                    annotation => annotation.category_id === first.id
                  );
                  const isSecondSelected = annotations.some(
                    annotation => annotation.category_id === second.id
                  );
                  if (
                    (isFirstSelected && isSecondSelected) ||
                    (!isFirstSelected && !isSecondSelected)
                  ) {
                    return first.name.localeCompare(second.name);
                  } else if (isFirstSelected) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map((label: CategoryType) => {
                  const selected = annotations.some(
                    annotation =>
                      annotation.category_id === label.id &&
                      selectedImages[annotation.image_id]
                  );

                  return (
                    <ListItem
                      key={label.id}
                      button
                      divider
                      onClick={() => handleLabelClick(label)}
                    >
                      <ListItemText>{label.name}</ListItemText>
                      {selected ? <Check /> : null}
                    </ListItem>
                  );
                })}
            </List>
          )}
          <FileViewer
            projectId={projectId}
            items={filteredItems}
            view={view}
            onSelect={handleFileSelect}
            renderAdornment={({selected, label, style}) => (
              <ImageAdornment selected={selected} label={label} style={style} />
            )}
            origin={origin}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
};

const LabelSearch = (props: {
  labelField: string;
  onChange: TextFieldProps['onChange'];
}) => {
  return (
    <div className="classification-prepare__search">
      <StudioSearch
        placeholder="Search by label"
        onChange={props.onChange}
        value={props.labelField}
      />
    </div>
  );
};
