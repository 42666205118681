import React from 'react';
import {isEmpty} from 'lodash';
import {StudioTooltip} from '../../../base-components/StudioTooltip';
import {StudioTextField} from '../../../base-components/StudioTextField';
import {getIn} from 'formik';
import cn from 'classnames';
import * as yup from 'yup';
import {get} from 'lodash';
import ERROR from '../../../config/error';
import {StudioSelect} from '../../../base-components/StudioSelect';

function getErr(name, errors, label) {
  let errMessage = getIn(errors, name);
  return !!errMessage && errMessage.replace(name, label);
}

export const FormInputNumber = ({field, data, className, form: {errors, ...rest}}) => {
  const errMessage = getErr(field.name, errors, data.param_display_name);

  return (
    <StudioTextField
      label={data.param_display_name}
      fullWidth
      type="number"
      data-testid={data.param_name}
      id={data.param_name}
      className={cn('isp-form__form-control', className)}
      value={data.param_value}
      error={!!errMessage}
      helperText={errMessage}
      disabled={data.readonly}
      InputProps={{
        ...field,
      }}
      {...rest}
    />
  );
};

export const FormInputString = ({field, data, className, form: {errors, ...rest}}) => {
  const errMessage = getErr(field.name, errors, data.param_display_name);

  return (
    <StudioTextField
      label={data.param_display_name}
      fullWidth
      data-testid={data.param_name}
      className={cn('isp-form__form-control', className)}
      value={data.param_value}
      error={!!errMessage}
      helperText={errMessage}
      disabled={data.readonly}
      InputProps={{
        ...field,
      }}
      {...rest}
    />
    // </StudioTooltip>
  );
};

const FormInputEnum = ({field, data, form: {errors, values, setFieldValue}}) => {
  const onChange = React.useCallback(
    val => {
      setFieldValue &&
        field.name &&
        setFieldValue(field.name, data.param_value_list[val]);
    },
    [setFieldValue, field.name, data.param_value_list]
  );

  const getValue = val => {
    return !val ? data.param_value_default : data.param_value_list.indexOf(val);
  };

  const errMessage = getErr(field.name, errors, data.param_display_name);

  return (
    <StudioTooltip title={data.param_description} placement="left">
      <StudioSelect
        fullWidth
        label={data.param_display_name}
        helperText={errMessage}
        options={data.param_value_list}
        disabled={data.readonly}
        SelectProps={{
          value: getValue(data.param_value),
          onChange: e => onChange(e.target.value),
        }}
      />
    </StudioTooltip>
  );
};

export const getLazyValidation = () => {
  const lazyValidation = yup.lazy((value, param) => {
    const oParam = param.parent;
    let type;
    let t =
      param.parent.param_value_type ||
      get(param.originalValue, param.path.replace('param_value', 'param_value_type'));

    switch (t) {
      case 'u8':
      case 'float32':
      case 'int':
      case 'int32':
        type = yup.number().required();
        if (oParam.param_value_min) type = type.min(oParam.param_value_min * 1);
        if (oParam.param_value_max) type = type.max(oParam.param_value_max * 1);
        return type;
      case 'CATEGORICAL': //this may need to be updated on the backend
      case 'string':
        return yup.string().required();
      case 'repeated_float32':
        return yup
          .string()
          .matches(
            /^(\s*-?\d+(\.\d+)?)(\s*,\s*-?\d+(\.\d+)?)*$/,
            ERROR.INVALID_FLOAT_VALUES()
          );
      default:
        return yup.object();
    }
  });
  return lazyValidation;
};

export function getFormFieldComponent(param) {
  switch (param.param_value_type) {
    case 'CATEGORICAL':
    case 'repeated_float32':
    case 'string':
      return isEmpty(param.param_value_list) ? FormInputString : FormInputEnum;
    default:
      return FormInputNumber;
  }
}
