import {
  STAGES_FETCH_PENDING,
  STAGES_FETCH_SUCCESS,
  STAGES_FETCH_ERROR,
  SET_STAGES_PROMPTS,
} from './actions';

const initialState = {
  isPending: false,
  data: null,
  errors: null,
  warnings: null,
  prompts: {
    isOpen: false,
    notifyType: null,
    isCancelled: false,
  },
};

export const stagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case STAGES_FETCH_PENDING:
      return {...state, isPending: true};
    case STAGES_FETCH_SUCCESS:
      const {payload} = action;
      return {
        ...state,
        isPending: false,
        data: payload.body,
        errors: payload.errors,
        warnings: payload.warnings,
      };
    case STAGES_FETCH_ERROR:
      return {
        ...state,
        isPending: false,
        errors: action.payload,
      };
    case SET_STAGES_PROMPTS:
      return {
        ...state,
        prompts: {
          isOpen: action.payload.isOpen,
          notifyType: action.payload.notifyType,
          isCancelled: Boolean(action.payload.isCancelled),
        },
      };
    default:
      return state;
  }
};
