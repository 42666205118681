import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  AnnotationDataType,
  AnnotationType,
  DataPrepareTypeStr,
} from '../../types/dataset/DatasetGetAnnotationsResponse';
import axios from 'axios';
import URL_CONST from '../../config/url';

export type IatState = {
  isBusy: boolean;
  autoLabelReady: boolean;
  annotationData: AnnotationType[] | null;
};

const initialState: IatState = {
  isBusy: false,
  autoLabelReady: false,
  annotationData: null,
};

export const startAutoLabel = createAsyncThunk(
  'iat/autoLabel/start',
  async (args: {projectId: string; resourceId?: string; type: DataPrepareTypeStr}) => {
    const {projectId, resourceId, type} = args;
    const {data} = await axios.get(URL_CONST.IAT_INIT(projectId), {
      params: {
        problemType: type,
        resourceId,
      },
    });
    return data;
  }
);

export const stopAutoLabel = createAsyncThunk(
  'iat/autoLabel/stop',
  async (args: {projectId: string; resourceId?: string}) => {
    const {projectId, resourceId} = args;
    const {data} = await axios.get<AnnotationDataType>(
      URL_CONST.IAT_SHUTDOWN(projectId),
      {
        params: {
          resourceId,
        },
      }
    );
    return data;
  }
);

export const getPredictions = createAsyncThunk(
  'iat/autoLabel/predictions',
  async (args: {
    projectId: string;
    resourceId?: string;
    ids: number[];
    samples: number;
  }) => {
    const {projectId, resourceId, samples, ids} = args;
    const {data} = await axios.post(
      URL_CONST.IAT_PREDICTIONS(projectId),
      {
        image_ids: ids,
        num_samples: samples,
      },
      {
        params: {
          resourceId,
        },
      }
    );
    return data;
  }
);

const setIsBusy = (state: IatState) => {
  state.isBusy = true;
};

const iatSlice = createSlice({
  name: 'iat/autoLabel',
  initialState,
  reducers: {
    setAutoLabelReady(state, action: PayloadAction<boolean>) {
      state.autoLabelReady = action.payload;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(startAutoLabel.pending, setIsBusy)
      .addCase(stopAutoLabel.pending, setIsBusy)
      .addCase(getPredictions.pending, setIsBusy)
      .addCase(startAutoLabel.fulfilled, (state, action) => {
        state.autoLabelReady = !(action.payload.errors && action.payload.errors.length);
        state.isBusy = false;
      })
      .addCase(startAutoLabel.rejected, (state, action) => {
        state.autoLabelReady = false;
        state.isBusy = false;
      })
      .addCase(stopAutoLabel.fulfilled, (state, action) => {
        state.autoLabelReady = false;
        state.isBusy = false;
      })
      .addCase(getPredictions.fulfilled, (state, action) => {
        state.annotationData =
          action.payload.errors && action.payload.errors.length
            ? initialState.annotationData
            : action.payload;
        state.annotationData = action.payload;
        state.isBusy = false;
      })
      .addCase(getPredictions.rejected, (state, action) => {
        state.annotationData = initialState.annotationData;
        state.isBusy = false;
      });
  },
});

export const {actions: iatActions, reducer: iatReducer} = iatSlice;
