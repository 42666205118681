import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {postInferenceDataClarify} from '../../../api/dataset/InferenceDataClarify';
import {postInferenceDataFinalize} from '../../../api/dataset/InferenceDataFinalize';
import {postInferenceDataUpload} from '../../../api/dataset/InferenceDataUpload';
import {toast} from '../../../base-components/StudioToast';
import {addListener, removeListener} from '../../../store/statusChecker';
import Util from '../../../util';
import {setInferenceTask, TASK_STATUS, useDeploy} from '../useDeploy';

export const useInferenceTask = (projectId: string) => {
  const [, dispatch] = useDeploy();
  const reduxDispatch = useDispatch();

  const finalize = useCallback(
    async deploymentId => {
      const finalizeResponse = await postInferenceDataFinalize({
        projectId,
        resourceId: deploymentId,
        answers: null,
      });
      if (finalizeResponse.errors) {
        throw new Error('Error received during inference finalize');
      }
      return finalizeResponse.body;
    },
    [projectId]
  );

  const monitorStatus = useCallback(() => {
    return new Promise<void>(resolve => {
      reduxDispatch(
        addListener('IMPORT_INFERENCE_DATA', (status: any) => {
          if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
            reduxDispatch(removeListener('IMPORT_INFERENCE_DATA'));
            resolve();
          }
        })
      );
    });
  }, [reduxDispatch]);

  const clarify = useCallback(async uploadData => {
    const clarifyResponse = await postInferenceDataClarify(uploadData);
    if (clarifyResponse.errors) {
      throw new Error('Error received during inference clarify');
    }
  }, []);

  const start = useCallback(
    async (_deploymentId, files) => {
      try {
        const uploadResponse = await postInferenceDataUpload(
          {files, projectId},
          {
            onUploadProgress: (progressEvent: ProgressEvent) => {
              dispatch(
                setInferenceTask({
                  uploadProgress: progressEvent.loaded,
                  totalSize: progressEvent.total,
                })
              );
            },
          }
        );
        if (uploadResponse.errors?.length) {
          throw new Error('Error received during inference data upload');
        }
        return uploadResponse.body;
      } catch (e) {
        toast.error('Error during file upload. Please try again.');
        throw e;
      }
    },
    [projectId, dispatch]
  );

  const run = useCallback(
    async (deploymentId: string, files: File[]) => {
      dispatch(setInferenceTask({status: TASK_STATUS.IN_PROGRESS}));
      const uploadData = await start(deploymentId, files);
      await clarify(uploadData);
      await monitorStatus();
      const inferenceData = await finalize(deploymentId);
      dispatch(setInferenceTask({status: TASK_STATUS.COMPLETE}));
      return inferenceData;
    },
    [dispatch, start, clarify, monitorStatus, finalize]
  );

  return [run];
};
