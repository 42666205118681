import {Grid, IconButton} from '@material-ui/core';
import {ArrowDownwardRounded, ArrowUpwardRounded} from '@material-ui/icons';
import cn from 'classnames';
import {debounce} from 'lodash';
import React, {useCallback, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory} from 'react-router';
import {ConfirmDialog} from '../../base-components/StudioConfirmDialog';
import {StudioInfiniteScroll} from '../../base-components/StudioInfiniteScroll';
import PopUpMenu from '../../base-components/StudioPopUpMenuLegacy/PopUpMenu';
import {StudioSearch} from '../../base-components/StudioSearch';
import {StudioSelect} from '../../base-components/StudioSelect';
import {toast} from '../../base-components/StudioToast';
import Waiting from '../../base-components/StudioWaiting/Waiting';
import {useAppDispatch} from '../../store';
import {projectDelete} from '../../store/project';
import {viewOpenProjectForm} from '../../store/view';
import {ProjectListRequest} from '../../types/project/ProjectListRequest';
import {CreateProjectButton, CreateProjectTileButton} from './CreateProjectButton';
import {ProjectGridCard} from './ProjectGridCard/ProjectGridCard';
import {ProjectListItem} from './ProjectListItem';
import {ProjectListResponse} from '../../types/project/ProjectListResponse';
import {useProjectList} from './useProjectList';
import './ProjectList.scss';

export type ProjectListProps = {};

// https://blaizeinc.atlassian.net/browse/STUDCL-13885
// const View = {
//   LIST: 'LIST',
//   GRID: 'GRID',
// } as const;

const Sort = {
  NAME: 'name',
  LAST_MODIFIED: 'lastUpdateTime',
} as const;

type ProjectListItemType = ProjectListResponse['body']['projects'][number];

function ProjectList(_props: ProjectListProps) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();

  // https://blaizeinc.atlassian.net/browse/STUDCL-13885
  // const [view, setView] = useState<keyof typeof View>(View.LIST);
  const [deleteData, setDeleteData] = useState<{
    open: boolean;
    project: ProjectListItemType | null;
  }>({
    open: false,
    project: null,
  });
  const [pendingSearchQuery, setPendingSearchQuery] = useState<
    ProjectListRequest['searchQuery']
  >('');
  const [searchQuery, setSearchQuery] = useState<ProjectListRequest['searchQuery']>('');
  const [sortBy, setSortBy] = useState<ProjectListRequest['sortBy']>('lastUpdateTime');
  const [sortDir, setSortDir] = useState<ProjectListRequest['sortDirection']>('desc');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(debounce(setSearchQuery, 400), []);

  const PAGE_SIZE = 5;
  const {totalCount, data, mutate, size, isValidating, loadMore} = useProjectList(
    PAGE_SIZE,
    searchQuery,
    sortBy,
    sortDir
  );

  return (
    <div className="project-list">
      <StudioInfiniteScroll
        hasMore={PAGE_SIZE * size < totalCount}
        onLoadMore={loadMoreProjects}
        className="project-list__container"
        data-testid="project-list-container"
      >
        <header className="project-list__header">
          <StudioSearch
            className="project-list__search"
            placeholder="Search..."
            onChange={handleSearch}
            value={pendingSearchQuery}
          />
          <StudioSelect
            className="project-list__sort-by"
            options={{
              [Sort.LAST_MODIFIED]: 'Last Edited',
              [Sort.NAME]: 'Name',
            }}
            order={['lastUpdateTime', 'name']}
            SelectProps={{
              value: sortBy,
              onChange: handleSortBy,
            }}
            data-testid="project-sort-select"
          />
          <IconButton
            className="project-list__sort-dir"
            aria-label="Sort Direction"
            onClick={handleSortDir}
            data-testid="sort-direction-btn"
          >
            {sortDir === 'asc' ? <ArrowUpwardRounded /> : <ArrowDownwardRounded />}
          </IconButton>
          {/* 
          // Removing for now
          // https://blaizeinc.atlassian.net/browse/STUDCL-13885
          <IconButton
            className="project-list__view"
            aria-label="View"
            onClick={handleViewChange}
          >
            {view === View.GRID ? <ViewListRounded /> : <Apps />}
          </IconButton> */}
        </header>

        {renderList()}
        {isValidating && size > 1 && renderLoading(true)}
      </StudioInfiniteScroll>

      <ConfirmDialog
        type="confirm"
        title={intl.formatMessage({id: 'project.deleteDialogTitle'})}
        message={intl.formatMessage(
          {id: 'project.deleteDialogContent'},
          {name: deleteData?.project?.name}
        )}
        open={deleteData.open}
        onClose={() => handleConfirmCancel()}
        onOk={() => handleConfirmDelete()}
        submitLabel={intl.formatMessage({id: 'form.delete'})}
      />
    </div>
  );

  async function loadMoreProjects(onFinish: () => void) {
    await loadMore();
    onFinish();
  }

  function renderLoading(append = false) {
    return (
      <div className={cn('stage-waiting', append && 'project-list__loading--append')}>
        <Waiting diameter={50} loadingText={intl.formatMessage({id: 'form.loading'})} />
      </div>
    );
  }

  function renderList() {
    if (isValidating && size === 1) {
      return renderLoading();
    }

    // https://blaizeinc.atlassian.net/browse/STUDCL-13885
    // const isList = view === View.LIST;
    const isList = true;
    const createButtonProps = {
      onClick: () => {
        dispatch(viewOpenProjectForm());
      },
    };
    const CreateButton = () =>
      isList ? (
        <Grid item>
          <CreateProjectButton {...createButtonProps} />
        </Grid>
      ) : (
        <CreateProjectTileButton className="project-list__tile" {...createButtonProps} />
      );
    const Item = isList ? ProjectListItem : ProjectGridCard;

    return (
      <Grid
        container
        className={cn(isList ? 'project-list__view-list' : 'project-list__view-grid')}
      >
        <CreateButton />
        {data ? (
          data.map(projectResponse =>
            projectResponse.body.projects.map(project => (
              <Item
                key={project.id}
                project={project}
                menuButton={() =>
                  renderMenuButton({
                    className: isList
                      ? 'project-list-item__menu'
                      : 'project-grid-card__menu',
                    id: project.id,
                    project,
                  })
                }
                onOpen={() => handleOpen(project.id)}
              />
            ))
          )
        ) : (
          <div className="project-list__empty-message">
            <FormattedMessage id="project.none" />
          </div>
        )}
      </Grid>
    );
  }

  function renderMenuButton(props: {
    className: string;
    id: string;
    project: ProjectListItemType;
  }) {
    const {className, id, project} = props;
    return (
      <div className={className}>
        <PopUpMenu
          id={`project-action-menu-${id}`}
          launcher="icon"
          list={[
            {
              id: '0',
              title: intl.formatMessage({id: 'form.open'}),
              action: () => handleOpen(id),
            },
            {
              id: '1',
              title: intl.formatMessage({id: 'form.newTab'}),
              action: () => handleNewTab(id),
            },
            {
              id: '2',
              title: intl.formatMessage({id: 'form.delete'}),
              action: () => handleDelete(project),
              disabled: false,
            },
          ]}
        />
      </div>
    );
  }

  function handleOpen(id: string) {
    history.push(`/project/${id}/upload`);
  }

  function handleNewTab(id: string) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: `/project/${id}/upload`,
    }).click();
  }

  function handleConfirmCancel() {
    setDeleteData({open: false, project: null});
  }

  async function handleConfirmDelete() {
    const project = deleteData.project;
    if (project !== null) {
      await dispatch(projectDelete(project.id)).then(() => {
        toast.success({
          title: intl.formatMessage({id: 'project.deleteToastTitle'}),
          subtitle: intl.formatMessage(
            {id: 'project.deleteToastContent'},
            {name: project.name}
          ),
          position: 'top',
        });
      });
      mutate();
      setDeleteData({open: false, project: null});
    }
  }

  function handleDelete(project: ProjectListItemType) {
    setDeleteData({open: true, project});
  }

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setPendingSearchQuery(event.target.value);
    delayedSearch(event.target.value);
  }

  function handleSortBy(
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) {
    setSortBy(event.target.value as string);
  }

  function handleSortDir() {
    setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
  }

  //blaizeinc.atlassian.net/browse/STUDCL-13885
  // https: function handleViewChange() {
  //   setView(view === View.LIST ? View.GRID : View.LIST);
  // }
}

export default ProjectList;
