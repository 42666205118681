import Rete from 'rete';

const rawSocket = new Rete.Socket('IMG_BAYER');
const rgbSocket = new Rete.Socket('IMG_RGB');
const yuvSocket = new Rete.Socket('IMG_YUV');

const TYPE_SOCKET_MAP = {
  Bayer: rawSocket,
  RGB: rgbSocket,
  YUV: yuvSocket,
};

class ISPComponent extends Rete.Component {
  constructor(props) {
    super(props.id);
    this.node = props;
  }

  // TODO: May need this later. Commenting out for now.
  // created(node) {
  //   // console.log("node -> created: ", node.name);
  // }
  // destroyed(node) {
  //   // console.log("node -> destroyed");
  // }
  // onconnect(io) {
  //   // input or output
  //   // console.log("node -> onconnect");
  //   // return false; // prevent the connect
  // }

  // ondisconnect(connection) {
  //   // console.log("node -> ondisconnect");
  //   // return false; // prevent the disconnect
  // }

  // connected(connection) {}

  // disconnected(connection) {}

  builder(node) {
    this.node.module_inputs.forEach((input, i) => {
      let sock = new Rete.Input('in-' + i, 'input', TYPE_SOCKET_MAP[input.type]);
      sock.module_io_type = input.type;
      node.addInput(sock);
    });

    this.node.module_outputs.forEach((output, i) => {
      let sock = new Rete.Output('out-' + i, 'output', TYPE_SOCKET_MAP[output.type]);
      sock.module_io_type = output.type;
      node.addOutput(sock);
    });

    // Add properties
    node.data = {...this.node, ...node.data};
    node.setHasError = this.node.setHasError;

    return node;
  }
}

export default ISPComponent;
