import React, {useState, createContext, PropsWithChildren} from 'react';

type ScaleProp = {
  scale: number;
  x: number;
  y: number;
};
type AnnotatorContextInterface = {
  imageLayer: ScaleProp;
  scaleToFitFactor: number;
  setImageLayer: (val: ScaleProp) => void;

  scale: () => number;
  setScale: (val: number) => void;
  setScaleToFitFactor: (val: number) => void;
};

export const NOT_SCALE_TO_FIT = -1;
const initState = {
  imageLayer: {
    scale: 1,
    x: 0,
    y: 0,
  },
  scaleToFitFactor: NOT_SCALE_TO_FIT,

  setImageLayer: (val: ScaleProp) => {},

  scale: () => 1,
  setScale: (val: number) => {},
  setScaleToFitFactor: (val: number) => {},
};
export const AnnotatorContext = createContext<AnnotatorContextInterface>(initState);

export const AnnotatorProvider = (props: PropsWithChildren<{}>) => {
  const [imageLayer, setImageLayer] = useState<ScaleProp>(initState.imageLayer);
  const [scaleToFitFactor, setScaleToFitFactor] = useState(NOT_SCALE_TO_FIT);
  const scale = () => imageLayer.scale;
  const setScale = (val: number) =>
    setImageLayer({
      ...imageLayer,
      scale: val,
    });

  return (
    <AnnotatorContext.Provider
      value={
        {
          imageLayer,
          setImageLayer,
          scale,
          setScale,
          scaleToFitFactor,
          setScaleToFitFactor,
        } as AnnotatorContextInterface
      }
    >
      {props.children}
    </AnnotatorContext.Provider>
  );
};
