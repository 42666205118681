import React from 'react';
import cn from 'classnames';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import {FormattedMessage, useIntl} from 'react-intl';
import {
  DataPrepareType,
  DataPrepareTypeStr,
} from '../../../types/dataset/DatasetGetAnnotationsResponse';
import {Box, Button, Toolbar} from '@material-ui/core';
import {StudioPopover} from '../../../base-components/StudioPopover';
import {StudioTextField} from '../../../base-components/StudioTextField';
import {Navigation} from '../Navigation';
import {
  dataPrepareActions,
  saveAnnotations,
  saveMethodUpdate,
} from '../../../store/dataPrepare';
import {RootState, useAppDispatch} from '../../../store';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {capitalize, isEmpty} from 'lodash';
import {PROJECT_SETTINGS} from './../../../config/constants';
import './MethodView.scss';

export type MethodViewValues = {
  name: string | null;
  description: string | null;
  type: DataPrepareType | null;
};

export type MethodViewProps = {
  projectId: string;
  datasetId?: string;
  inProgress: boolean;
  showRevert: boolean;
  onForward: () => void;
  onRevert: () => void;
};

const {setName, setDescription, setType} = dataPrepareActions;

export const MethodView = ({
  datasetId,
  inProgress,
  showRevert,
  onForward,
  onRevert,
  projectId,
}: MethodViewProps) => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const name = useSelector((state: RootState) => state.dataPrepare.name);
  const description = useSelector((state: RootState) => state.dataPrepare.description);
  const type = useSelector((state: RootState) => state.dataPrepare.type);
  const dataContainer = useSelector((state: RootState) => state.dataPrepare.containers);
  const isEditing =
    !isEmpty(dataContainer.datasetRelabelingContainer) ||
    !isEmpty(dataContainer.ddaPreparationContainer);

  const annotationData = useSelector(
    (state: RootState) => state.dataPrepare.annotationData
  );

  const onForwardLocal = () => {
    datasetId &&
      projectId &&
      dispatch(
        saveMethodUpdate({
          resourceId: datasetId,
          projectId,
          answers: [
            {key: 'Dataset.Name', value: name},
            {key: 'Dataset.Description', value: description},
            {
              key: 'Model.Purpose',
              value:
                type === DataPrepareType.CLASSIFICATION
                  ? capitalize(DataPrepareTypeStr.CLASSIFICATION)
                  : capitalize(DataPrepareTypeStr.DETECTION),
            },
          ],
        })
      );
    onForward();
  };

  const handleChange = (newValues: MethodViewValues) => {
    dispatch(setName(newValues.name));
    dispatch(setDescription(newValues.description));
    dispatch(setType(newValues.type));

    if (newValues.type !== type && datasetId && annotationData) {
      dispatch(
        saveAnnotations({
          resourceId: datasetId,
          projectId,
          updatedAnnotations: {
            ...annotationData,
            annotations: [],
            info: {...annotationData.info, type: newValues.type},
          },
        })
      );
    }
  };

  const isValid = Boolean(type != null && name);
  const disableForward = !isValid || inProgress;
  return (
    <div className="method-view">
      <Toolbar className="data-prepare__header" variant="dense">
        {showRevert ? (
          <Button
            className="data-prepare__previous-btn"
            variant="contained"
            disableElevation
            onClick={onRevert}
          >
            <FormattedMessage id="dataPrep.reselectImages" />
          </Button>
        ) : (
          <Button
            className="data-prepare__previous-btn"
            variant="contained"
            disableElevation
            onClick={() => history.push(`/project/${projectId}/upload`)}
          >
            <FormattedMessage id="form.returnToPreviousPage" />
          </Button>
        )}
        <Box flexGrow={1}></Box>
        <Navigation onForward={onForwardLocal} disableForward={disableForward} hideBack />
      </Toolbar>
      <div className="method-view__header">
        <h2>
          {intl.formatMessage({
            id: 'dataPrep.methodview.title',
          })}
        </h2>
        <h5>
          {intl.formatMessage({
            id: 'dataPrep.methodview.desc',
          })}
        </h5>
      </div>
      <div className="method-view__form">
        <div className="method-view__cards">
          <Card
            variant="outlined"
            className={cn('method-view__button', {
              selected: type === DataPrepareType.CLASSIFICATION,
            })}
          >
            <CardActionArea
              className="method-view__button_action_area"
              onClick={e =>
                handleChange({name, description, type: DataPrepareType.CLASSIFICATION})
              }
              disabled={isEditing && type === DataPrepareType.DETECTION}
            >
              <CardContent className="method-view__button_content">
                <h4>
                  {intl.formatMessage({
                    id: 'dataPrep.classification.title',
                  })}
                </h4>
                <StudioPopover
                  infoMessage={intl.formatMessage({
                    id: 'dataPrep.classification.desc',
                  })}
                />
              </CardContent>
            </CardActionArea>
          </Card>
          <Card
            variant="outlined"
            className={cn('method-view__button', {
              selected: type === DataPrepareType.DETECTION,
            })}
          >
            <CardActionArea
              className="method-view__button_action_area"
              onClick={e =>
                handleChange({name, description, type: DataPrepareType.DETECTION})
              }
              disabled={isEditing && type === DataPrepareType.CLASSIFICATION}
            >
              <CardContent className="method-view__button_content">
                <h4>
                  {intl.formatMessage({
                    id: 'dataPrep.detection.title',
                  })}
                </h4>
                <StudioPopover
                  infoMessage={intl.formatMessage({
                    id: 'dataPrep.detection.desc',
                  })}
                />
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
        <StudioTextField
          placeholder="Enter name..."
          onChange={e => handleChange({description, type, name: e.target.value})}
          value={name}
          label={intl.formatMessage({
            id: 'dataPrep.name',
          })}
          inputProps={{maxLength: PROJECT_SETTINGS.name.maxLength}}
          fullWidth
          required
        />
        <StudioTextField
          placeholder="Enter description..."
          onChange={e => handleChange({name, type, description: e.target.value})}
          value={description}
          label={intl.formatMessage({
            id: 'dataPrep.description',
          })}
          multiline={PROJECT_SETTINGS.description.multiline}
          fullWidth
          inputProps={{
            className: PROJECT_SETTINGS.description.className,
            maxLength: PROJECT_SETTINGS.description.maxLength,
          }}
        />
      </div>
    </div>
  );
};
