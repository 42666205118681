import React, {forwardRef} from 'react';
import {PropTypes} from 'prop-types';
import {Autocomplete} from '@material-ui/lab';
import cn from 'classnames';
import './StudioAutoComplete.scss';

export const StudioAutoComplete = forwardRef(({className, ...rest}, ref) => {
  return (
    <Autocomplete
      ref={ref}
      className={cn('studio-autocomplete-field', className)}
      autoHighlight
      autoSelect
      openOnFocus
      {...rest}
    />
  );
});

StudioAutoComplete.propTypes = {
  className: PropTypes.string,
};
