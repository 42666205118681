import React from 'react';
import {StudioTextField} from '../../../base-components/StudioTextField';
import * as Yup from 'yup';
import './NumericIntervalField.scss';

type ParsedNumericInterval = {
  min?: number;
  max?: number;
  excludeMin: boolean;
  excludeMax: boolean;
};

export const parseNumericInterval = (interval?: string) => {
  const defaultInterval: ParsedNumericInterval = {
    excludeMin: false,
    excludeMax: false,
  };

  if (!interval) {
    return defaultInterval;
  }

  try {
    const left = interval[0];
    const right = interval[interval.length - 1];

    const parsed: ParsedNumericInterval = {
      excludeMin: left === '(',
      excludeMax: right === ')',
    };
    const numbers = interval.slice(1, interval.length - 1).split(',');
    if (numbers.length === 2) {
      if (numbers[0] !== '' && !isNaN(Number(numbers[0]))) {
        parsed.min = Number(numbers[0]);
      }
      if (numbers[1] !== '' && !isNaN(Number(numbers[1]))) {
        parsed.max = Number(numbers[1]);
      }
    }
    return parsed;
  } catch (e) {
    return defaultInterval;
  }
};

export const validateMin = Yup.string().test(
  'check-min-max',
  'The min cannot be greater than the max',
  interval => {
    if (interval) {
      const parsedInterval = parseNumericInterval(interval);
      if (
        parsedInterval.min != null &&
        parsedInterval.max != null &&
        parsedInterval.min > parsedInterval.max
      ) {
        return false;
      }
    }
    return true;
  }
);

export const validateIsInteger = Yup.string().test(
  'is-integer',
  'Values must be integers',
  interval => {
    if (interval) {
      const parsedInterval = parseNumericInterval(interval);
      if (parsedInterval.min != null && !Number.isInteger(parsedInterval.min)) {
        return false;
      }
      if (parsedInterval.max != null && !Number.isInteger(parsedInterval.max)) {
        return false;
      }
    }
    return true;
  }
);

const buildIntervalString = (parsed: ParsedNumericInterval) => {
  const left = parsed.excludeMin ? '(' : '[';
  const right = parsed.excludeMax ? ')' : ']';
  const min = parsed.min != null ? parsed.min : '';
  const max = parsed.max != null ? parsed.max : '';
  return `${left}${min},${max}${right}`;
};

type NumericIntervalFieldProps = {
  dataType: 'FLOATING_POINT_NUMBER' | 'INTEGER';
  onChange: (interval: string) => void;
  interval?: string;
  errorText?: string;
  disabled?: boolean;
};

export const NumericIntervalField = ({
  dataType,
  interval,
  onChange,
  errorText,
  disabled,
}: NumericIntervalFieldProps) => {
  const parsed = parseNumericInterval(interval);

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const min = e.target.value === '' ? undefined : Number(e.target.value);
    const updated = {...parsed, min};
    onChange(buildIntervalString(updated));
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const max = e.target.value === '' ? undefined : Number(e.target.value);
    const updated = {...parsed, max};
    onChange(buildIntervalString(updated));
  };

  return (
    <div>
      <div className="numeric-interval-field">
        <StudioTextField
          type="number"
          placeholder="Min"
          value={parsed.min == null ? '' : parsed.min}
          onChange={handleMinChange}
          inputProps={{
            step: dataType === 'FLOATING_POINT_NUMBER' ? '0.1' : '1',
          }}
          disabled={disabled}
        />
        <StudioTextField
          type="number"
          placeholder="Max"
          value={parsed.max == null ? '' : parsed.max}
          onChange={handleMaxChange}
          inputProps={{
            step: dataType === 'FLOATING_POINT_NUMBER' ? '0.1' : '1',
          }}
          disabled={disabled}
        />
      </div>
      <div className="numeric-interval-field__error">{errorText}</div>
    </div>
  );
};
