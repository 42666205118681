import React, {Component, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {getLocation, push} from 'connected-react-router';
import querystring from 'query-string';
import StageAnimation from '../StageAnimation/StageAnimation';
import Util from '../../../util';
import {StageAssistantForm} from '../../StageAssistantForm';
import TaskInfoProps from '../../../model/taskInfoPropTypes';
import {toast} from '../../../base-components/StudioToast';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import axios from 'axios';
import URL, {DATASET_OP, DATASET_SUB_OP} from '../../../config/url';
import {addListener, removeListener} from '../../../store/statusChecker';
import {connect} from 'react-redux';
import {projectFetch} from '../../../store/project';
import {viewDisableTermination, viewEnableTermination} from '../../../store/view';
import {withRouter} from 'react-router';
import {compose} from 'redux';

class DataUploadAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      taskProgress: props.taskProgress,
    };

    this.fileUpload = null;
    this.stageAnimation = new StageAnimation();
    this.requestImportFinalization = this.requestImportFinalization.bind(this);
    this.addData = this.addData.bind(this);
    this.showProgress = this.showProgress.bind(this);
    this.displayDataUploadProgress = this.displayDataUploadProgress.bind(this);
    this.terminateDataUpload = this.terminateDataUpload.bind(this);
    this.monitorStatus = this.monitorStatus.bind(this);
  }

  render() {
    return null;
  }

  componentDidMount() {
    if (this.props.location.search) {
      const query = querystring.parse(this.props.location.search);
      if (query.datasetId && query.datasetName && query.datasetType) {
        this.dataUpload(query.datasetId, {
          name: query.datasetName,
          type: query.datasetType,
        });
        this.props.navigate(this.props.location.pathname);
      }
    }

    if (this.props.taskProgress && this.props.taskProgress.type === 'ADD_DATA') {
      this.props.setDatasetActionInProgress('upload');
      this.stageAnimation.shiftStages('ADD_DATA');
      this.monitorStatus();
    }

    if (this.fileUpload) {
      this.props.setDatasetActionInProgress('upload');
      this.stageAnimation.markInProgress('ADD_DATA');
      this.showProgress(this.fileUpload);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.taskProgress?.type !== 'ADD_DATA' &&
      this.props.taskProgress?.type === 'ADD_DATA'
    ) {
      this.props.setDatasetActionInProgress('upload');
      this.stageAnimation.shiftStages('ADD_DATA');
      this.monitorStatus();
    }
  }

  componentWillUnmount() {
    this.props.removeStatusListener('ADD_DATA');
  }

  monitorStatus() {
    this.props.viewEnableTermination();
    this.stageAnimation.markInProgress('ADD_DATA');
    this.props.addStatusListener('ADD_DATA', this.displayDataUploadProgress);
  }

  dataUpload(integrationId, selectedFile) {
    const cloudImportRequest = {
      projectId: this.props.projectId,
      integrationId: integrationId,
      files: [
        {
          fileName: selectedFile.name,
          type: selectedFile.type,
        },
      ],
    };

    axios
      .post(URL.DATASET_OP(DATASET_OP.IMPORT, DATASET_SUB_OP.CLOUD), cloudImportRequest)
      .then(res => this.handleClarificationResponse(res.data))
      .catch(error => toast.error(`data upload error: ${error.message}`));
  }

  addData(files, totalSize) {
    this.stageAnimation.markInProgress('ADD_DATA');
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    this.fileUpload = {
      request: source,
      loaded: 0,
      total: totalSize,
    };

    this.showProgress(this.fileUpload);
    const formData = new FormData();
    files.forEach(file => formData.append('file', file));
    formData.append('projectId', this.props.projectId);
    const onUploadProgress = this.showProgress;
    axios
      .post(URL.DATASET_OP(DATASET_OP.IMPORT, DATASET_SUB_OP.FILESYSTEM), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
        cancelToken,
      })
      .then(response => {
        if (!response.data.errors?.length) {
          this.handleFileImportResponse(response, true);
        }
      })
      .catch(error => {
        this.stageAnimation.markActiveOrInactive('ADD_DATA');
        source.cancel();
        if (this.fileUpload) {
          console.error(error);
          toast.error(`Error during data upload: ${error.message}`);
        }
      })
      .finally(() => {
        this.fileUpload = null;
      });
  }

  showProgress({total, loaded}) {
    DataUploadAction.displayClientServerUploadProgress(loaded / total);
  }

  handleFileImportResponse(res) {
    let response = res.data.body;
    if (response.errors && response.errors.length > 0) {
      this.finishUpload('ADD_DATA');
    } else {
      this.clarifyImport(response);
    }
  }

  clarifyImport(importRequest) {
    axios
      .post(URL.DATASET_OP(DATASET_OP.IMPORT, DATASET_SUB_OP.CLARIFY), importRequest)
      .then(res => this.handleClarificationResponse(res.data))
      .catch(error => toast.error(`data upload error: ${error.message}`));
  }

  handleClarificationResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.finishUpload('ADD_DATA');
    } else {
      this.monitorStatus();
    }
  }

  displayDataUploadProgress(status) {
    this.props.setDatasetActionInProgress('upload');
    DataUploadAction.displayImportProgress(status);
    if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
      this.props.removeStatusListener('ADD_DATA');
      if (status.prompts && status.prompts.length > 0) {
        this.showInitialAssistant(status);
      } else {
        this.requestImportFinalization(null);
      }
    }
  }

  showInitialAssistant(taskProgress) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.display(
        this.getQuestionsForm(taskProgress.prompts, taskProgress.defaults),
        'ADD_DATA',
        null,
        this.terminateDataUpload
      );
    }
  }

  getQuestionsForm(questions, defaults) {
    return (
      <StageAssistantForm
        prompts={questions}
        defaults={defaults}
        onSubmit={this.requestImportFinalization}
        onCancel={this.terminateDataUpload}
        title="We need a little more information to finish adding this data."
      />
    );
  }

  requestImportFinalization(answers) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.hideStage('ADD_DATA');
    }

    const finalizationRequest = {
      projectId: this.props.projectId,
      answers: answers,
    };

    axios
      .post(
        URL.DATASET_OP(DATASET_OP.IMPORT, DATASET_SUB_OP.FINALIZE),
        finalizationRequest
      )
      .then(res => this.handleFinalizationResponse(res.data, answers))
      .catch(error => toast.error(`data upload error: ${error.message}`));
  }

  handleFinalizationResponse(response, answers) {
    if (response.prompts && response.prompts.length > 0) {
      this.showInitialAssistant(response);
    } else if (response.errors) {
      this.finishUpload('ADD_DATA');
    } else if (
      // If the user selects "Dataset has no annotations", we need to redirect to the data prep.
      // This redirect should probably be triggered from the BE somehow, but we don't have a way to do that currently.
      answers?.length &&
      answers[0].key === 'Dataset.Loader' &&
      answers[0].value === 'Raw'
    ) {
      this.props.history.push(
        `/project/${this.props.projectId}/data/prepare/dataset?resume=true`
      );
    } else if (response.body) {
      DataUploadAction.getImageThumbnail(this.props.projectId, this.props.setImages);
      this.finishUpload(response.body.dataSplit ? 'IMPORT_MODEL' : 'ADD_DATA');
    }
  }

  async finishUpload(stage) {
    if (this.props.onFinish) {
      await this.props.projectFetch(this.props.projectId);
      this.props.onFinish(stage);
    }
  }

  terminateDataUpload() {
    this.props.viewDisableTermination();
    if (this.fileUpload) {
      this.fileUpload.request.cancel();
      this.fileUpload = null;
    }
    return axios
      .get(URL.OPERATION_CANCEL('ADD_DATA'), {
        params: {projectId: this.props.projectId},
      })
      .then(res => {
        this.props.assistantPanel.current.hideStage('ADD_DATA');
        this.props.removeStatusListener('ADD_DATA');
        this.finishUpload('ADD_DATA');
      })
      .catch(error =>
        toast.error(`Error when trying to cancel upload: ${error.message}`)
      );
  }

  static displayImportProgress(status) {
    let progressText = document.getElementById('add-data-action-progress');
    if (progressText) {
      progressText.innerText = status.statusMessage;
    }
  }

  static displayClientServerUploadProgress(percent) {
    let progressText = document.getElementById('add-data-action-progress');
    if (progressText && !progressText.innerText.endsWith('...')) {
      progressText.innerText = Math.round(percent * 100.0) + '% uploaded.';
    }
  }

  static getImageThumbnail(projectId, callback) {
    projectId &&
      callback(
        `/studio/dataset/${projectId}/thumbnails?size=6:3&resolution=128:128&token=${Math.floor(
          Math.random() * 10000
        )}`
      );
  }
}

DataUploadAction.propTypes = {
  projectId: PropTypes.string.isRequired,
  assistantPanel: PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)})
    .isRequired,
  onFinish: PropTypes.func.isRequired,
  setImages: PropTypes.func.isRequired,
  taskProgress: TaskInfoProps,
};

const mapStateToProps = state => ({
  location: getLocation(state),
});

const mapDispatchToProps = {
  projectFetch,
  addStatusListener: addListener,
  removeStatusListener: removeListener,
  navigate: push,
  viewEnableTermination,
  viewDisableTermination,
};

export {DataUploadAction as DataUploadActionBase};

const withRouterForwardRef = Component => {
  const WithRouter = withRouter(({forwardedRef, ...props}) => (
    <Component ref={forwardedRef} {...props} />
  ));

  return forwardRef((props, ref) => <WithRouter {...props} forwardedRef={ref} />);
};

const WrappedDataUploadAction = compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  withRouterForwardRef
)(DataUploadAction);

export default WrappedDataUploadAction;
