import {PostProcessorResponse} from '../../types/postprocessor/PostProcessorResponse';
import {request} from '../request';

export const getProjectPostProcessorTemplateUrl = (projectId: string) => {
  const path = `/postprocessor/new/${projectId}`;
  const search = new URLSearchParams({}).toString();
  return search ? `${path}?${search}` : path;
};

export type GetProjectPostProcessorTemplateParams = {
  projectId: string;
};

export function getProjectPostProcessorTemplate({
  projectId,
}: GetProjectPostProcessorTemplateParams) {
  return request<PostProcessorResponse>({
    method: 'get',
    url: getProjectPostProcessorTemplateUrl(projectId),
  });
}
