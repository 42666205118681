import {connect} from 'react-redux';
import {ExpandMore} from '@material-ui/icons';
import {useIntl, FormattedMessage} from 'react-intl';
import React, {useState, useMemo, useEffect, createContext, useCallback} from 'react';
import {
  Accordion,
  AccordionProps,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';

import {RootState} from '../../../store';
import {DynamicForm} from '../../DynamicForm';
import {Answer} from '../../../types/prompts/Answer';
import {getProjectData} from '../../../store/project';
import {Project} from '../../../types/project/Project';
import {Prompts} from '../../../types/prompts/Prompts';
import {isPyAnalyticsModel} from './../../../utils/model';
import {toast} from '../../../base-components/StudioToast';
import {useTestListByLatest} from '../../../api/test/TestList';
import {useDeploy, setDeployConfig, useDeploymentDetails} from '../useDeploy';
import {useDeploymentEnvironmentVariables} from './../../../api/deployment/DeploymentEnvironmentVariables';

import './AnalyticDeploySettings.scss';

const AccordionContext = createContext<{setHasRows?: (hasRows: boolean) => void}>({});

type AnalyticsDeploySettingsProps = Omit<AccordionProps, 'children'> & {
  className?: string;
  openProjectData: Project;
};

function AnalyticsDeploySettingsBase({
  className = '',
  openProjectData,
}: AnalyticsDeploySettingsProps) {
  const intl = useIntl();
  const [hasRows, setHasRows] = useState(false);

  const [{deployConfig, selectedDeploymentIds}, dispatch] = useDeploy();

  const testSelectedId = useMemo(
    () => (selectedDeploymentIds?.length > 1 ? void 0 : selectedDeploymentIds?.[0]),
    [selectedDeploymentIds]
  );
  const testSelectedDeployment = useDeploymentDetails(testSelectedId);

  const providerValue = useMemo(() => ({hasRows, setHasRows}), [hasRows, setHasRows]);
  const selectedApplication = useMemo(() => {
    let selectedApplication;

    if (openProjectData?.applications) {
      selectedApplication =
        openProjectData?.applications?.[
          testSelectedDeployment?.applicationId || deployConfig?.deploymentId
        ];
    }

    return selectedApplication;
  }, [
    deployConfig?.deploymentId,
    openProjectData?.applications,
    testSelectedDeployment?.applicationId,
  ]);

  const {data: historyItems} = useTestListByLatest({
    projectId: openProjectData.id,
  });

  const defaultEnvironmentVariables: Record<string, string> = useMemo(
    () => ({...selectedApplication?.model?.frameworkParameters?.environment}),
    [selectedApplication]
  );
  const {data: fetchedEnvironmentVariables, error} = useDeploymentEnvironmentVariables({
    latest: true,
    projectId: openProjectData.id,
    applicationId: selectedApplication?.id,
  });
  const historyItemSelectedEnvironment = useMemo(() => {
    const historyItemSelectedEnvironment = historyItems?.entries.find(
      deployment => deployment.id === testSelectedDeployment?.id
    )?.environment;

    if (historyItemSelectedEnvironment) {
      dispatch(
        setDeployConfig({
          environment: historyItemSelectedEnvironment,
        })
      );
    }

    return historyItemSelectedEnvironment;
  }, [dispatch, historyItems, testSelectedDeployment?.id]);

  const environmentVariables: Record<string, string> = useMemo(
    () => ({
      ...defaultEnvironmentVariables,
      ...fetchedEnvironmentVariables,
      ...(historyItemSelectedEnvironment
        ? historyItemSelectedEnvironment
        : deployConfig.environment),
    }),
    [
      historyItemSelectedEnvironment,
      deployConfig.environment,
      defaultEnvironmentVariables,
      fetchedEnvironmentVariables,
    ]
  );

  useEffect(() => {
    if (error) {
      toast.error(
        intl.formatMessage({
          id: 'analyticDeploySettings.errorGettingEnvironmentVariables',
        })
      );
    }
  }, [intl, error]);

  const {prompts, defaults} = useMemo(() => {
    const prompts: Partial<Prompts>[] = [];
    const defaults: Record<string, Partial<Answer>> = {};
    const variables = {
      ...defaultEnvironmentVariables,
      ...environmentVariables,
    };

    Object.keys(variables).forEach(key => {
      prompts.push({
        key,
        text: key,
        type: 'TEXT',
        mandatory: false,
        placeholder: defaultEnvironmentVariables[key],
      });

      defaults[key] = {
        key,
        value: variables[key],
      };
    });

    return {prompts, defaults};
  }, [environmentVariables, defaultEnvironmentVariables]);

  const onBlur = useCallback(
    (formValues: Project['environment']) => {
      const changedProperties: Record<string, string> = {};

      Object.keys(formValues).forEach(key => {
        if (formValues[key] !== defaultEnvironmentVariables[key]) {
          changedProperties[key] = formValues[key] || '';
        }
      });

      if (Object.keys(changedProperties).length) {
        dispatch(
          setDeployConfig({
            environment: changedProperties,
          })
        );
      }
    },
    [defaultEnvironmentVariables, dispatch]
  );

  return !isPyAnalyticsModel(selectedApplication?.model) ? null : (
    <AccordionContext.Provider value={providerValue}>
      <Accordion
        elevation={0}
        defaultExpanded
        className={`analytics-deploy-settings ${className}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          className="analytics-deploy-settings__title-container"
        >
          <FormattedMessage tagName="h4" id="analyticDeploySettings.title" />
        </AccordionSummary>

        <AccordionDetails className="analytics-deploy-settings__body_container">
          <DynamicForm
            onBlur={onBlur}
            defaults={defaults}
            prompts={prompts as Prompts[]}
            key={`form-${selectedApplication?.id}`}
          />
        </AccordionDetails>
      </Accordion>
    </AccordionContext.Provider>
  );
}

const mapStateToProps = (state: RootState) => ({
  openProjectData: getProjectData(state),
});

export const AnalyticsDeploySettings = connect(mapStateToProps)(
  AnalyticsDeploySettingsBase
);
