import React from 'react';
import BeforeImage1 from './images/lens_distortion-before.png';
import AfterImage from './images/lens_distortion-after.png';
import './Help.scss';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>Lens Distortion</h4>
      <div className="flow-graph__help_content_detail">
        <p>In geometric optics, distortion is a deviation from rectilinear projection; a projection in which straight lines in a scene remain straight in an image. It is a form of optical aberration. Although distortion can be irregular or follow many patterns, the most encountered distortions are radially symmetric, or approximately so, arising from the symmetry of a photographic lens. These radial distortions can usually be classified as either barrel distortions or pincushion distortions.</p>
        <h6>Barrel distortion</h6>
        <p>In barrel distortion, image magnification decreases with distance from the optical axis. The apparent effect is that of an image which has been mapped around a sphere (or barrel). Fisheye lenses, which take hemispherical views, utilize this type of distortion to map an infinitely wide object plane into a finite image area. In a zoom lens, barrel distortion appears in the middle of the lens's focal length range and is worse at the wide-angle end of the range.</p>
        <h6>Pincushion distortion</h6>
        <p>In pincushion distortion, image magnification increases with the distance from the optical axis. The visible effect is that lines that do not go through the center of the image are bowed inwards, towards the center of the image, like a pincushion.</p>
        <p>Mathematically, barrel and pincushion distortion are quadratic. They increase as the square of distance from the center. In mustache distortion the quartic (degree 4) term is significant: in the center, the degree-2-barrel distortion is dominant, while at the edge, the degree-4-distortion in the pincushion direction dominates. Other distortions are in principle possible – pincushion in center and barrel at the edge, or higher order distortions (degree 6, degree 8) – but, do not generally occur in practical lenses, and higher order distortions are small relative to the main barrel and pincushion effects.</p>
        <div className="help_images">
          <figure>
            <img src={BeforeImage1} alt="before" />
            <figcaption>Input</figcaption>
          </figure>
          <figure>
            <img src={AfterImage} alt="after" />
            <figcaption>Output</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};
