import axios from 'axios';
import {toast} from '../../base-components/StudioToast';
import URL from '../../config/url';
import {isModelDetailsPanelOpen} from './selectors';

// ACTIONS
export const MODEL_FETCH_DETAILS_SUCCESS = 'model/fetch-details-success';
export const MODEL_FETCH_DETAILS_ERROR = 'model/fetch-details-error';
export const MODEL_FETCH_DETAILS_PENDING = 'model/fetch-details-pending';
export const MODEL_UPDATE_MODELVIEWER = 'model/update-modelviewer';
export const MODEL_TOGGLE_DETAILS_PANEL = 'model/toggle-details-panel';
export const MODEL_DETAILS_PANEL_CONTEXT = 'model/details-panel-context';

// ACTION CREATORS
export const modelDelete = id => () =>
  axios
    .get(URL.MODEL_DELETE(id))
    .then(res => {
      if (res.data.errors) {
        toast.error(res.data.errors);
      }
    })
    .catch(error => toast.error(error));

export const modelDetailsPending = () => ({type: MODEL_FETCH_DETAILS_PENDING});

export const modelDetailsSuccess = payload => ({
  type: MODEL_FETCH_DETAILS_SUCCESS,
  payload,
});

export const modelDetailsError = payload => ({type: MODEL_FETCH_DETAILS_ERROR, payload});

export const modelDetails = projectId => async dispatch => {
  try {
    dispatch(modelDetailsPending());
    const res = await axios.get(URL.MODEL_DETAILS, {params: {projectId}});
    if (!res.data.errors) {
      dispatch(modelDetailsSuccess(res.data.body));
    }
  } catch (e) {
    toast.error(e);
    dispatch(modelDetailsError(e));
  }
};

export const setModelViewerStale = stale => ({
  type: MODEL_UPDATE_MODELVIEWER,
  payload: stale,
});

export const setModelDetailsContext = context => ({
  type: MODEL_DETAILS_PANEL_CONTEXT,
  payload: context,
});

export const toggleModelDetailsPanel = (projectId, context = 'FLOATING_POINT') => (
  dispatch,
  getState
) => {
  if (projectId && !isModelDetailsPanelOpen(getState())) {
    dispatch(setModelDetailsContext(context));
    dispatch(modelDetails(projectId));
  }

  dispatch({
    type: MODEL_TOGGLE_DETAILS_PANEL,
  });
};
