import React from 'react';
import {useSelector} from 'react-redux';
import ModelActionButton from '../../../base-components/StudioButton/ModelActionButton';
import {getIsTerminationEnabled} from '../../../store/view';

export type TerminateButtonProps = {
  onClick: JSX.IntrinsicElements['button']['onClick'];
};

export const TerminateButton = ({onClick}: TerminateButtonProps) => {
  const enabled = useSelector(getIsTerminationEnabled);
  return <ModelActionButton value="terminate" onClick={onClick} disabled={!enabled} />;
};
