const modelPurposes = {
  Classification: 'Classification',
  Detection: 'Detection',
  Segmentation: 'Segmentation',
  PoseDetection: 'PoseDetection',
  Other: 'Other',
};

export type ModelPurpose = keyof typeof modelPurposes;

export function modelPurposeToString(modelPurpose: ModelPurpose) {
  return modelPurposes[modelPurpose] ?? modelPurpose;
}
