import {Dialog} from '@material-ui/core';
import {debounce} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {StudioSearch} from '../../../base-components/StudioSearch';
import {getProjectId} from '../../../config/routes';
import {SortableColumn} from '../../../types/marketplace/MarketplaceFilesRequest';
import {IntegrationPurpose} from '../../../types/integrations/IntegrationResponse';
import {MarketplaceResult} from '../../../types/marketplace/MarketplaceFilesResponse';
import {MarketplaceList} from '../MarketplaceExplorer/MarketplaceList';
import {actions, useMarketplace} from '../MarketplaceExplorer/useMarketplace';
import '../ImportDialogs/ImportDialog.scss';
import {PostProcessorType} from '../../../types/postprocessor/PostProcessorType';

type AutoDetectDialogProps = {
  isOpen: boolean;
  title: string;
  purpose: IntegrationPurpose;
  postProcessorType?: PostProcessorType;
  columns: SortableColumn[];
  onClose: (projectId: string, isSubmit?: boolean) => void;
  onSubmit: (items: MarketplaceResult[]) => Promise<void> | void;
  excludedRefs?: string[];
};

const PAGE_SIZE = 5;

const {setDisplayName, setSort, setSortDir, setSelected} = actions;

export const AutoDetectDialog = ({
  isOpen,
  title,
  purpose,
  postProcessorType,
  columns,
  onClose,
  onSubmit,
  excludedRefs,
}: AutoDetectDialogProps) => {
  const intl = useIntl();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const projectId = getProjectId(location.pathname);

  const {
    state: {selectedIds, sort, sortDir, results},
    dispatch,
  } = useMarketplace({
    purpose,
    pageSize: PAGE_SIZE,
    isEnabled: isOpen,
    projectId,
    defaultFilterValues: {compatibleOnly: true},
    crossMarketplace: true,
  });

  const filteredResults = excludedRefs
    ? results?.filter(
        r =>
          !excludedRefs.includes(r.displayName) &&
          r.metadata.postProcessorType === postProcessorType
      )
    : results;

  const selectedItems = Object.values(selectedIds).filter(value => value);

  useEffect(() => {
    const resetState = () => {
      setSearch('');
    };

    if (!isOpen) {
      resetState();
    } else {
      setIsSubmitted(false);
    }
  }, [isOpen, dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetDisplayName = useCallback(
    debounce((displayName: string) => dispatch(setDisplayName(displayName)), 500),
    [dispatch]
  );

  const updateSearch = (search: string) => {
    /* We want to debounce the API fetch as the user types a search query, without
     * interrupting the user while typing. Hence, we separate the filter used for the
     * API call (displayName) with the value used for the search box (search).
     */

    debouncedSetDisplayName(search);
    setSearch(search);
  };

  const handleSubmit = () => {
    if (!isSubmitted) {
      // hack to prevent this from being executed twice after double click
      // not sure if better way exists
      setIsSubmitted(true);
      handleClose(true);
      if (selectedItems.length) {
        onSubmit(selectedItems);
      }
    }
  };

  const handleClose = (isSubmit?: boolean) => {
    if (projectId) {
      onClose(projectId, Boolean(isSubmit));
    }
  };

  const getResultKey = (result: MarketplaceResult) =>
    `${result.name}_${result.integrationId}`;

  const handleSelectedChange = (result: MarketplaceResult) => {
    if (selectedIds[getResultKey(result)]) {
      const {[getResultKey(result)]: removed, ...remaining} = selectedIds;
      dispatch(setSelected(remaining));
    } else {
      dispatch(setSelected({...selectedIds, [getResultKey(result)]: result}));
    }
  };

  const handleSelectAllChange = (selectAll: boolean) => {
    const updated = results?.reduce(
      (acc, curr) => ({...acc, [getResultKey(curr)]: selectAll ? curr : null}),
      {}
    );
    if (updated) {
      dispatch(setSelected({...selectedIds, ...updated}));
    }
  };

  return (
    <Dialog
      open={isOpen}
      BackdropProps={{
        invisible: true,
      }}
      className="import-dialog"
      onClose={() => handleClose()}
      maxWidth={false}
      data-testid="auto-import-modal"
      id="auto-import-modal"
    >
      <h2 className="import-dialog__title">{title}</h2>
      <div className="import-dialog__body">
        <div className="import-dialog__main">
          <div className="import-dialog__menu">
            <div>
              {selectedItems.length > 0 && (
                <div>{`${selectedItems.length} item(s) selected`}</div>
              )}
            </div>
            <StudioSearch
              placeholder={intl.formatMessage({id: 'marketplaceExplorer.search'})}
              onChange={e => updateSearch(e.target.value)}
              data-testid="import-dialog-search"
              id="import-dialog-search"
              value={search}
            />
          </div>
          <MarketplaceList
            multiSelect
            getKey={getResultKey}
            results={filteredResults}
            sort={sort}
            sortDir={sortDir}
            selectedIds={selectedIds}
            columns={columns}
            onSelectedChange={handleSelectedChange}
            onSelectAllChange={handleSelectAllChange}
            onSort={(column, direction) => {
              dispatch(setSort(column));
              dispatch(setSortDir(direction));
            }}
          />
        </div>
      </div>
      <div className="import-dialog__actions">
        <FormButton
          buttonRole="secondary"
          value={intl.formatMessage({id: 'form.cancel'})}
          onClick={() => handleClose()}
          type="button"
        />
        <FormButton
          buttonRole="primary"
          value={intl.formatMessage({id: 'form.submit'})}
          type="submit"
          disabled={isSubmitted || !selectedItems.length}
          onClick={handleSubmit}
        />
      </div>
    </Dialog>
  );
};
