import React from 'react';
import * as Yup from 'yup';
import {DynamicTextField} from './DynamicTextField';
import {DynamicSelect} from './DynamicSelect';
import {DynamicShapeField} from './DynamicShapeField';
import {DynamicCheckbox} from './DynamicCheckbox';
import {DynamicRadioGroup} from './DynamicRadioGroup';
import {StudioStatistics} from '../../base-components/StudioStatistics';
import {DynamicTags} from './DynamicTags';

const types = {
  MULTIPLE_CHOICES: 'MULTIPLE_CHOICES',
  MULTIPLE_CHOICES_TREE: 'MULTIPLE_CHOICES_TREE',
  URL: 'URL',
  PASSWORD: 'PASSWORD',
  FLAG: 'FLAG',
  INTEGER: 'INTEGER',
  NUMBER: 'NUMBER',
  SHAPE: 'SHAPE',
  STATISTICS: 'STATISTICS',
  TEXT: 'TEXT',
  MESSAGE: 'MESSAGE',
  TAGS: 'TAGS',
};

const buildTextField = props => (
  <DynamicTextField
    key={props.name}
    label={props.text}
    className={props.className}
    name={props.name}
    required={props.mandatory}
    disabled={props.disabled}
    type={props.type}
    placeholder={props.placeholder}
    validationType={props.validationType}
    tooltip={props.tooltip}
    interval={props.interval}
    inputProps={props.inputProps}
    multiline={props.multiline}
  />
);

export const getFormControlConfig = (type, level) => {
  switch (type) {
    case types.MULTIPLE_CHOICES:
    case types.MULTIPLE_CHOICES_TREE:
      return {
        getComponent: props => {
          let control = props.control || 'combobox';
          // TEMP fallback until backend supports new API
          if (props.radio) {
            control = 'radio';
          }
          switch (control) {
            case 'radio':
              return (
                <DynamicRadioGroup
                  key={props.name}
                  label={props.text}
                  options={props.options}
                  optionsHelpText={props.optionsHelp}
                  order={props.order}
                  subQuestions={props.subQuestions}
                  disabledOptions={props.disabledOptions}
                  name={props.name}
                  className={props.className}
                  required={props.mandatory}
                  tooltips={props.tooltips}
                  level={level}
                />
              );
            case 'combobox':
            default:
              return (
                <DynamicSelect
                  key={props.name}
                  label={props.text}
                  options={props.options}
                  order={props.order}
                  subQuestions={props.subQuestions}
                  name={props.name}
                  className={props.className}
                  required={props.mandatory}
                  disabled={props.disabled}
                  tooltip={props.tooltip}
                  note={props.note}
                  level={level}
                  multiple={!!props.multipleSelect}
                />
              );
          }
        },
        initialValue: '',
      };
    case types.URL:
      return {
        getComponent: props =>
          buildTextField({
            ...props,
            type: 'string',
            placeholder: 'URL',
            validationType: types.URL,
          }),
        initialValue: '',
      };
    case types.PASSWORD:
      return {
        getComponent: props => buildTextField({...props, type: 'password'}),
        initialValue: '',
      };
    case types.FLAG:
      return {
        getComponent: props => (
          <DynamicCheckbox
            key={props.name}
            label={props.text}
            className={props.className}
            name={props.name}
            required={props.mandatory}
            disabled={props.disabled}
            tooltip={props.tooltip}
          />
        ),
        initialValue: 'false',
      };
    case types.INTEGER:
      return {
        getComponent: props =>
          buildTextField({...props, type: 'number', validationType: types.INTEGER}),
        initialValue: 0,
      };
    case types.NUMBER:
      return {
        getComponent: props =>
          buildTextField({...props, type: 'number', validationType: types.NUMBER}),
        initialValue: 0,
      };
    case types.SHAPE:
      return {
        getComponent: props => (
          <DynamicShapeField
            key={props.name}
            label={props.text}
            className={props.className}
            name={props.name}
            required={props.mandatory}
            disabled={props.disabled}
            tooltip={props.tooltip}
          />
        ),
        initialValue: ['0', '0', '0', '0'],
      };
    case types.STATISTICS:
      // TODO: remove when statistics can be displayed a better way
      return {
        getComponent: props => {
          return (
            <StudioStatistics
              accuracy={props.accuracy}
              key={`statistics-${props.name}`}
              numberOfClasses={props.numberOfClasses}
            />
          );
        },
      };
    case types.TEXT:
      return {
        getComponent: props => buildTextField({...props, type: 'text'}),
        initialValue: '',
      };
    case types.TAGS:
      return {
        getComponent: props => (
          <DynamicTags
            key={`dynamic-tags-${props.name}`}
            className={props.className}
            name={props.name}
            label="Tags"
            tooltip={props.tooltip}
            values={props.defaultValue}
          />
        ),
      };
    case types.MESSAGE:
    default:
      return {
        getComponent: props => <p key={`message-${props.name}`}>{props.text}</p>,
      };
  }
};

const buildNumberRange = (initial, interval) => {
  let builder = initial;
  if (interval?.minValue != null && interval?.leftOpen) {
    builder = builder.moreThan(
      interval.minValue,
      `Please enter a value greater than ${interval.minValue}`
    );
  }
  if (interval?.minValue != null && !interval?.leftOpen) {
    builder = builder.min(
      interval.minValue,
      `Please enter a value greater than or equal to ${interval.minValue}`
    );
  }
  if (interval?.maxValue != null && interval?.rightOpen) {
    builder = builder.lessThan(
      interval.maxValue,
      `Please enter a value less than ${interval.maxValue}`
    );
  }
  if (interval?.maxValue != null && !interval?.rightOpen) {
    builder = builder.max(
      interval.maxValue,
      `Please enter a value less than or equal to ${interval.maxValue}`
    );
  }
  return builder;
};

export const validateField = (value, type, required, interval) => {
  let rule;

  if (type === types.URL) {
    rule = Yup.string().url('Please enter a valid URL');
  } else if (type === types.SHAPE) {
    rule = Yup.array(Yup.number().required()).test(
      'is-shape',
      'All values must be valid numbers',
      vals => vals.length && vals.reduce((acc, curr) => acc && !isNaN(curr), true)
    );
  } else if (type === types.NUMBER) {
    rule = buildNumberRange(
      Yup.number('Please enter a valid number').typeError('Please enter a valid number'),
      interval
    );
  } else if (type === types.INTEGER) {
    rule = buildNumberRange(
      Yup.number()
        .integer('Please enter a valid integer')
        .typeError('Please enter a valid integer'),
      interval
    );
  } else {
    rule = Yup.string();
  }

  if (required) {
    rule = rule.required('This field is required');
  }
  try {
    rule.validateSync(value);
    return null;
  } catch (error) {
    return error.errors[0];
  }
};
