import React, {useEffect, useRef} from 'react';
import {InferenceSession, Tensor} from '../../types-external/onnxruntime-web';
import {Parameter} from '../../types/model/framework/Parameter';
import {
  AppONNXPostProcessorScript,
  ModelONNXPostProcessorScript,
  PostProcessorScript,
} from '../../types/scripts/PostProcessorScript';
import {
  ModelONNXPreProcessorScript,
  PreProcessorScript,
} from '../../types/scripts/PreProcessorScript';
import {usePostProcessingScript} from '../PrePostProcessing/PostProcessing/usePostProcessingScript';
import {usePreProcessingScript} from '../PrePostProcessing/PostProcessing/usePreProcessingScript';
import {RuntimeModeType, setDeployConfig, useDeploy} from '../TestDeployView/useDeploy';
import {useInferenceSession} from './useInferenceSession';
import {useONNXVideoStream} from './useONNXVideoStream';
import './ONNXRuntimeContainer.scss';
import {
  RealtimePostProcessingParameters,
  RealtimePreProcessingParameters,
  TestParametersAccordion,
} from '../TestDeployView/DeployConfigPanel/TestDeployParameters';
import {useIntl} from 'react-intl';
import {InputSource} from '../../types/deployment/InferenceInputSource';
import {ApplicationProcessing} from '../../types/project/Application';

export type ONNXRuntimeContainerProps = {
  type: InputSource | '';
  projectId: string;
  application: ApplicationProcessing;
  runtimeMode: RuntimeModeType;
  inputShape: number[];
  files?: File[];
  url?: string;
  onFinish?: () => void;
  onError?: (message?: string) => void;
  configuredParams: Parameter[];
};

export const FetchPreProcessor = (
  application?: ApplicationProcessing,
  onError?: (message?: string) => void
) => {
  const onErrorRef = useRef(onError);
  const transformationScriptName = application?.transformation?.filePaths?.find(
    isPathScript
  );
  return usePreProcessingScript({
    projectId: application?.projectId,
    id: application?.id,
    hasPreProcessor:
      application?.transformation !== undefined && transformationScriptName !== undefined,
    fileName: transformationScriptName,
    onError: onErrorRef.current,
    viewOnly: true,
  });
};

export const FetchModelPostProcessor = (
  application?: ApplicationProcessing,
  onError?: (message?: string) => void
) => {
  const onErrorRef = useRef(onError);
  const modelPostProcessor = application?.postProcessors?.find(
    pp => pp.postProcessorType === 'MODEL'
  );
  return usePostProcessingScript({
    projectId: application?.projectId,
    id: application?.id,
    hasPostProcessors: application?.postProcessors !== undefined,
    fileName: modelPostProcessor?.filePaths?.find(isPathScript),
    postProcessorType: 'MODEL',
    executionContainer: 'BROWSER',
    onError: onErrorRef.current,
    viewOnly: true,
  });
};

export const FetchAppPostProcessor = (
  application?: ApplicationProcessing,
  onError?: (message?: string) => void
) => {
  const onErrorRef = useRef(onError);
  const appPostProcessor = application?.postProcessors?.find(
    pp => pp.postProcessorType === 'APPLICATION'
  );
  return usePostProcessingScript({
    projectId: application?.projectId,
    id: application?.id,
    hasPostProcessors: application?.postProcessors !== undefined,
    fileName: appPostProcessor?.filePaths?.find(isPathScript),
    postProcessorType: 'APPLICATION',
    executionContainer: 'BROWSER',
    onError: onErrorRef.current,
    viewOnly: true,
  });
};

export function supportsImages(
  modelPreProcessorScript?: PreProcessorScript,
  modelPostProcessorScript?: PostProcessorScript,
  appPostProcessorScript?: PostProcessorScript
): boolean {
  if (!modelPreProcessorScript || !modelPostProcessorScript || !appPostProcessorScript) {
    return false;
  }
  return (
    'preprocessImage' in modelPreProcessorScript &&
    'postprocessOutputData' in modelPostProcessorScript &&
    'postprocessImage' in appPostProcessorScript
  );
}

export function supportsVideo(
  modelPreProcessorScript?: PreProcessorScript,
  modelPostProcessorScript?: PostProcessorScript,
  appPostProcessorScript?: PostProcessorScript
): boolean {
  if (!modelPreProcessorScript || !modelPostProcessorScript || !appPostProcessorScript) {
    return false;
  }
  return (
    'preprocessVideoFrame' in modelPreProcessorScript &&
    'postprocessOutputDataAsync' in modelPostProcessorScript &&
    'postprocessVideo' in appPostProcessorScript
  );
}

export const ONNXRuntimeContainer = ({
  type,
  projectId,
  application,
  runtimeMode,
  inputShape,
  files,
  onFinish,
  onError,
  url,
  configuredParams,
}: ONNXRuntimeContainerProps) => {
  const intl = useIntl();
  const [, dispatch] = useDeploy();

  const applicationId = application?.id;

  const modelPreProcessorScript = FetchPreProcessor(application, onError);
  const modelPostProcessorScript = FetchModelPostProcessor(application, onError);
  const appPostProcessorScript = FetchAppPostProcessor(application, onError);

  const modelParameters = application?.postProcessors?.find(
    pp => pp.postProcessorType === 'MODEL'
  )?.parameters;
  const appParameters = application?.postProcessors?.find(
    pp => pp.postProcessorType === 'APPLICATION'
  )?.parameters;

  const containerRef = useRef<HTMLDivElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const onErrorRef = useRef(onError);
  const onFinishRef = useRef(onFinish);

  // Initialize session
  const session = useInferenceSession(
    projectId,
    applicationId,
    runtimeMode,
    onErrorRef.current
  );

  // Initialize video stream
  const {videoStream} = useONNXVideoStream(
    projectId,
    type,
    videoRef.current,
    url,
    onErrorRef.current,
    onFinishRef.current
  );

  // Run inference
  useEffect(() => {
    if (
      session &&
      modelPreProcessorScript &&
      modelPostProcessorScript &&
      appPostProcessorScript
    ) {
      if (type === 'IMAGES' && containerRef.current && files?.length && inputShape) {
        if (
          supportsImages(
            modelPreProcessorScript,
            modelPostProcessorScript,
            appPostProcessorScript
          )
        ) {
          addParametersToWindow([
            ...(modelParameters || []),
            ...(appParameters || []),
            ...(configuredParams || []),
          ]);
          // @ts-ignore
          runImageInference({
            session,
            preprocessImage: modelPreProcessorScript.preprocessImage,
            postprocessOutputData: (modelPostProcessorScript as ModelONNXPostProcessorScript)
              .postprocessOutputData,
            postprocessImage: (appPostProcessorScript as AppONNXPostProcessorScript)
              .postprocessImage,
            container: containerRef.current,
            files,
            inputShape,
            onError: onErrorRef.current,
            onFinish: onFinishRef.current,
          });
        } else {
          // post processors are not valid for images
          onErrorRef.current?.(
            'Pre and post processor(s) do not support image inference.'
          );
        }
      } else if (
        videoStream &&
        videoContainerRef.current &&
        videoRef.current &&
        inputShape
      ) {
        if (
          supportsVideo(
            modelPreProcessorScript,
            modelPostProcessorScript,
            appPostProcessorScript
          )
        ) {
          addParametersToWindow([
            ...(modelParameters || []),
            ...(appParameters || []),
            ...(configuredParams || []),
          ]);
          runVideoInference({
            session,
            preprocessVideoFrame: modelPreProcessorScript.preprocessVideoFrame,
            postprocessOutputDataAsync: (modelPostProcessorScript as ModelONNXPostProcessorScript)
              .postprocessOutputDataAsync,
            postprocessVideo: (appPostProcessorScript as AppONNXPostProcessorScript)
              .postprocessVideo,
            videoStream,
            videoContainer: videoContainerRef.current,
            video: videoRef.current,
            inputShape,
            onError: onErrorRef.current,
          });
        } else {
          // post processor are not valid for video
          onErrorRef.current?.(
            'Pre and post processor(s) do not support video streaming inference.'
          );
        }
      }
    }
  }, [
    type,
    session,
    configuredParams,
    modelPreProcessorScript,
    modelPostProcessorScript,
    appPostProcessorScript,
    files,
    inputShape,
    modelParameters,
    appParameters,
    videoStream,
  ]);

  return (
    <div className="onnx-runtime" ref={containerRef}>
      {['WEB_CAMERA', 'FILE', 'IP_CAMERA'].includes(type) && (
        <>
          <div className="onnx-runtime__video-container" ref={videoContainerRef}>
            <video
              ref={videoRef}
              autoPlay
              // Safari blocks autoplay videos that are not muted by default
              muted
              playsInline
            ></video>
            <canvas></canvas>
          </div>
          <TestParametersAccordion
            title={intl.formatMessage({id: 'test.form.rtpostprocessing'})}
            className="test-inference-results__accordion"
            expanded={true}
          >
            <RealtimePostProcessingParameters
              className="test-inference-results__form"
              projectId={projectId}
              applicationId={applicationId}
              renderField={(field, param) => (
                <div className="test-inference-results__field" key={param.name}>
                  {field}
                </div>
              )}
              emptyMessage={
                <div className="test-inference-results__empty">
                  {intl.formatMessage({id: 'test.form.postprocessing.empty'})}
                </div>
              }
              onChange={realTimeParameters => {
                dispatch(
                  setDeployConfig({postProcessorRealtimeParams: realTimeParameters})
                );
              }}
            />
          </TestParametersAccordion>
          <TestParametersAccordion
            title={intl.formatMessage({id: 'test.form.rtpreprocessing'})}
            className="test-inference-results__accordion"
            expanded={true}
          >
            <RealtimePreProcessingParameters
              className="test-inference-results__form"
              projectId={projectId}
              applicationId={applicationId}
              renderField={(field, param) => (
                <div className="test-inference-results__field" key={param.name}>
                  {field}
                </div>
              )}
              emptyMessage={
                <div className="test-inference-results__empty">
                  {intl.formatMessage({id: 'test.form.preprocessing.empty'})}
                </div>
              }
              onChange={realTimeParameters => {
                dispatch(
                  setDeployConfig({preProcessorRealtimeParams: realTimeParameters})
                );
              }}
            />
          </TestParametersAccordion>
        </>
      )}
    </div>
  );
};

function addParametersToWindow(params: Parameter[]) {
  const paramsMap = params.reduce<Record<string, string | undefined | null>>(
    (acc, curr) => ({...acc, [curr.name]: getString(curr.value)}),
    {}
  );
  window.getConfigurationParameter = (key: string) => paramsMap[key];
}

function getString(value: any): string | undefined | null {
  return value !== undefined && value !== null ? value.toString() : value;
}

type VideoInferenceConfig = {
  session: InferenceSession;
  preprocessVideoFrame: ModelONNXPreProcessorScript['preprocessVideoFrame'];
  postprocessOutputDataAsync: ModelONNXPostProcessorScript['postprocessOutputDataAsync'];
  postprocessVideo: AppONNXPostProcessorScript['postprocessVideo'];
  videoStream: MediaStream;
  videoContainer: HTMLDivElement;
  video: HTMLVideoElement;
  inputShape: number[];
  onError?: ONNXRuntimeContainerProps['onError'];
};

async function runVideoInference({
  session,
  videoStream,
  video,
  videoContainer,
  preprocessVideoFrame,
  postprocessOutputDataAsync,
  postprocessVideo,
  inputShape,
  onError,
}: VideoInferenceConfig) {
  if (preprocessVideoFrame && postprocessOutputDataAsync && postprocessVideo) {
    const screenshotCanvas = document.createElement('canvas');
    const tempCanvas = document.createElement('canvas');
    const screenshotContext = screenshotCanvas.getContext('2d');
    const tempContext = tempCanvas.getContext('2d');
    let validated = false;

    if (screenshotContext && tempContext) {
      try {
        const videoFrameLoop = async () => {
          // video is no longer playing
          if (!videoStream.active) {
            return;
          }
          const {videoHeight, videoWidth} = video;
          if (videoHeight && videoWidth) {
            console.log('videoHeight: ' + videoHeight);
            console.log('videoWidth: ' + videoWidth);
            screenshotCanvas.width = videoWidth;
            screenshotCanvas.height = videoHeight;
            screenshotContext.drawImage(video, 0, 0, videoWidth, videoHeight);
            const preprocessedData = await Promise.resolve(
              preprocessVideoFrame(inputShape, screenshotContext, tempContext)
            );
            const feeds: Record<string, Tensor> = {};
            feeds[session.inputNames[0]] = preprocessedData[0];
            let output;
            try {
              output = await session.run(feeds);
            } catch (e) {
              if (validated) {
                // We skip validation errors (because of the known issues in ONNX) but throw all others.
                throw e;
              }
            } finally {
              validated = true;
            }
            if (output) {
              const outputTensor = output[session.outputNames[0]];
              const postprocessedData = await Promise.resolve(
                postprocessOutputDataAsync(outputTensor)
              );
              postprocessVideo(videoContainer, postprocessedData);
            }
          }
          // Run at 60fps
          setTimeout(() => requestAnimationFrame(videoFrameLoop), 1000 / 60);
        };
        requestAnimationFrame(videoFrameLoop);
      } catch (e) {
        onError?.('Streaming inference error. Please try again.');
        console.error('Error during ONNX model streaming inference.', e);
      }
    }
  }
}

type ImageInferenceConfig = {
  session: InferenceSession;
  preprocessImage: ModelONNXPreProcessorScript['preprocessImage'];
  postprocessOutputData: ModelONNXPostProcessorScript['postprocessOutputData'];
  postprocessImage: AppONNXPostProcessorScript['postprocessImage'];
  container: HTMLElement;
  files: File[];
  inputShape: number[];
  onError?: ONNXRuntimeContainerProps['onError'];
  onFinish?: ONNXRuntimeContainerProps['onFinish'];
};

async function runImageInference({
  session,
  preprocessImage,
  postprocessOutputData,
  postprocessImage,
  container,
  files,
  inputShape,
  onFinish,
  onError,
}: ImageInferenceConfig) {
  if (preprocessImage && postprocessOutputData && postprocessImage) {
    try {
      await Promise.all(
        files.map(async file => {
          const fileObjectUrl = URL.createObjectURL(file);
          const preprocessedData = await Promise.resolve(
            preprocessImage(inputShape, fileObjectUrl)
          );
          const feeds: Record<string, Tensor> = {};
          feeds[session.inputNames[0]] = preprocessedData[0];
          const output = await session.run(feeds);
          const outputTensor = output[session.outputNames[0]];
          const postprocessedData = await Promise.resolve(
            postprocessOutputData(outputTensor)
          );
          postprocessImage(container, fileObjectUrl, postprocessedData);
        })
      );
      onFinish?.();
    } catch (e) {
      onError?.('Image inference error. Please try again.');
      console.error('Error during ONNX model image inference.', e);
    }
  }
}

const isPathScript = (path: string) => path !== 'metadata.yaml';
