import {ErrorMessage, Formik, useField} from 'formik';
import React, {forwardRef, useState} from 'react';
import {useHistory} from 'react-router';
import * as Yup from 'yup';
import {
  AuthError,
  AuthPageBody,
  AuthPageContainer,
  AuthPageEmailField,
  AuthPageFooter,
  AuthPageForm,
  AuthPageHeader,
  AuthPageSecondaryButton,
  AuthPageSubmitButton,
} from '..';
import {formikErrors} from '../../Formik/formikErrors';
import ERROR from '../../../config/error';
import {ActivationEmailSent} from '../ActivationEmailSent';
import {AuthPageNewPasswordField} from '../AuthPage';
import {PasswordSchema} from '../PasswordSchema';
import './Signup.scss';
import {FormattedMessage, useIntl} from 'react-intl';
import {
  StudioTextField,
  StudioTextFieldProps,
} from '../../../base-components/StudioTextField';
import {hasError} from '../../Formik/hasError';
import cn from 'classnames';
import {StudioSelect, StudioSelectProps} from '../../../base-components/StudioSelect';
import {addUser} from '../../../api/user/addUser';
import {Credentials} from '../../../types/user/Credentials';
import {ErrorMessage as StudioErrorMessage} from '../../../types/response/ErrorMessage';
import {useStudioMode} from '../../StudioMode/StudioModeProvider';

const OTHER_OPTION = 'other';

type SignupTextFieldProps = StudioTextFieldProps & {name: string};

const SignUpTextField = forwardRef<HTMLDivElement, SignupTextFieldProps>(
  ({name, className, InputProps, InputLabelProps, ...rest}, ref) => {
    const [field, meta] = useField<string>(name);

    return (
      <StudioTextField
        ref={ref}
        error={hasError(meta)}
        helperText={<ErrorMessage name={name} />}
        className={cn('auth-page__form-control', className)}
        data-testid={name}
        id={name}
        InputProps={{
          ...InputProps,
          ...field,
        }}
        InputLabelProps={{
          ...InputLabelProps,
          required: true,
        }}
        {...rest}
      />
    );
  }
);

type DomainFieldProps = StudioSelectProps & {name: string};

const DomainField = forwardRef<HTMLDivElement, DomainFieldProps>(
  ({name, className, SelectProps, onChipDelete, ...rest}, ref) => {
    const [field, meta, helpers] = useField<string[]>(name);
    const intl = useIntl();
    const lastOption = OTHER_OPTION;

    const options = {
      agriculture: intl.formatMessage({id: 'auth.domain.agriculture'}),
      automotive: intl.formatMessage({id: 'auth.domain.automotive'}),
      manufacturing: intl.formatMessage({id: 'auth.domain.manufacturing'}),
      medical: intl.formatMessage({id: 'auth.domain.medical'}),
      nature: intl.formatMessage({id: 'auth.domain.nature'}),
      retail: intl.formatMessage({id: 'auth.domain.retail'}),
      security: intl.formatMessage({id: 'auth.domain.security'}),
      smartCity: intl.formatMessage({id: 'auth.domain.smartCity'}),
      [OTHER_OPTION]: intl.formatMessage({id: 'auth.domain.other'}),
    };

    const order = Object.entries(options)
      .sort((a, b) => a[1].localeCompare(b[1]))
      .map(a => a[0])
      .filter(s => s !== lastOption)
      .concat(lastOption);

    return (
      <StudioSelect
        ref={ref}
        error={hasError(meta)}
        helperText={<ErrorMessage name={name} />}
        className={cn('auth-page__form-control', className)}
        data-testid={name}
        id={name}
        options={options}
        order={order}
        SelectProps={{
          value: field.value,
          multiple: true,
          ...SelectProps,
          onChange: (event, child) => {
            helpers.setValue(event.target.value as string[]);
            SelectProps?.onChange?.(event, child);
          },
        }}
        onChipDelete={value => {
          helpers.setValue(field.value.filter(type => type !== value));
          onChipDelete?.(value);
        }}
        {...rest}
      />
    );
  }
);

const initialValues: Credentials = {
  firstName: '',
  lastName: '',
  name: '',
  password: '',
  company: '',
  domain: [],
  domainDescription: '',
};

export function Signup() {
  const validationLiteSchema = Yup.object<Credentials>({
    firstName: Yup.string().required(ERROR.FIELD_REQUIRED()),
    lastName: Yup.string().required(ERROR.FIELD_REQUIRED()),
    name: Yup.string().required(ERROR.FIELD_REQUIRED()),
    password: PasswordSchema,
    company: Yup.string().required(ERROR.FIELD_REQUIRED()),
    domain: Yup.array()
      .of(Yup.string().required())
      .required(ERROR.FIELD_REQUIRED()),
    domainDescription: Yup.string().when(
      'domain',
      (domain: string[], schema: Yup.StringSchema) =>
        domain.includes(OTHER_OPTION) ? schema.required(ERROR.FIELD_REQUIRED()) : schema
    ),
  });

  const validationFullSchema = Yup.object<Credentials>({
    firstName: Yup.string().required(ERROR.FIELD_REQUIRED()),
    lastName: Yup.string().required(ERROR.FIELD_REQUIRED()),
    name: Yup.string().required(ERROR.FIELD_REQUIRED()),
    password: PasswordSchema,
  });

  const {mode} = useStudioMode();
  const isLiteMode = mode === 'LITE';

  const validationSchema = isLiteMode ? validationLiteSchema : validationFullSchema;

  const history = useHistory();
  const intl = useIntl();
  const [error, setError] = useState<StudioErrorMessage[] | Error | null>(null);
  const [hasSubmitted, setSubmitted] = useState(false);

  if (hasSubmitted) {
    return <ActivationEmailSent />;
  }

  return (
    <AuthPageContainer>
      <Formik<Credentials>
        initialValues={initialValues}
        initialErrors={formikErrors(validationSchema, initialValues)}
        onSubmit={async fields => setError(await signup(fields))}
        validationSchema={validationSchema}
      >
        {formikProps => (
          <AuthPageForm>
            <AuthPageHeader>
              <FormattedMessage id="auth.signUp" />
            </AuthPageHeader>
            <AuthPageBody>
              <AuthError error={error} />
              <SignUpTextField
                name="firstName"
                data-testid="first-name-input"
                label={intl.formatMessage({id: 'auth.firstName'})}
                placeholder={intl.formatMessage({id: 'auth.firstNamePlaceholder'})}
              />
              <SignUpTextField
                name="lastName"
                data-testid="last-name-input"
                label={intl.formatMessage({id: 'auth.lastName'})}
                placeholder={intl.formatMessage({id: 'auth.lastNamePlaceholder'})}
              />
              <AuthPageEmailField name="name" data-testid="name-input" />
              <AuthPageNewPasswordField name="password" data-testid="password-input" />
              {isLiteMode && (
                <>
                  <SignUpTextField
                    name="company"
                    data-testid="company-input"
                    label={intl.formatMessage({id: 'auth.company'})}
                    placeholder={intl.formatMessage({id: 'auth.companyPlaceholder'})}
                  />
                  <DomainField
                    name="domain"
                    data-testid="domain-input"
                    label={intl.formatMessage({id: 'auth.domain'})}
                    placeholder={intl.formatMessage({id: 'auth.domainPlaceholder'})}
                  />
                  {formikProps.values.domain?.includes(OTHER_OPTION) && (
                    <SignUpTextField
                      name="domainDescription"
                      data-testid="domain-description-input"
                      label={intl.formatMessage({id: 'auth.domainDescription'})}
                      placeholder={intl.formatMessage({
                        id: 'auth.domainDescriptionPlaceholder',
                      })}
                      multiline
                      minRows={3}
                      maxRows={3}
                    />
                  )}
                </>
              )}
            </AuthPageBody>
            <AuthPageFooter>
              <AuthPageSecondaryButton
                value={intl.formatMessage({id: 'auth.login'})}
                onClick={() => history.push('/login')}
              />
              <AuthPageSubmitButton value={intl.formatMessage({id: 'auth.signUp'})} />
            </AuthPageFooter>
          </AuthPageForm>
        )}
      </Formik>
    </AuthPageContainer>
  );

  async function signup(fields: Credentials) {
    try {
      const res = await addUser(fields);

      if (res.errors) {
        return res.errors;
      }

      setSubmitted(true);
      return null;
    } catch (err) {
      return err as Error;
    }
  }
}
