import useSWR from 'swr';
import {ApplicationType} from '../../types/model/framework/ApplicationType';
import {ExecutionContainer} from '../../types/model/framework/ExecutionContainer';
import {PostProcessorType} from '../../types/postprocessor/PostProcessorType';
import {assertResponseBody, request} from '../request';

export type PostProcessorListRequest = {
  projectId?: string | null;
};

export type PostProcessorInfo = {
  id: string;
  postProcessorType: PostProcessorType;
  executionContainer: ExecutionContainer;
  applicationType: ApplicationType;
  name: string;
  description: string;
  version: number;
};

export type PostProcessorList = {
  existingPostProcessors: Array<PostProcessorInfo>;
  availablePostProcessors: Array<PostProcessorInfo>;
};

export type PostProcessorListResponse = PostProcessorList;

export const PostProcessorListUrl = ({projectId}: PostProcessorListRequest) =>
  `/postprocessor/list/${projectId}`;

export async function postProcessorList(params: PostProcessorListRequest) {
  return request<PostProcessorListResponse>({
    method: 'GET',
    url: PostProcessorListUrl(params),
  });
}

export function usePostProcessorList(params: PostProcessorListRequest) {
  return useSWR(
    params.projectId ? PostProcessorListUrl(params) : null,
    () => assertResponseBody(postProcessorList(params)),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
}
