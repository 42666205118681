import React, {useState} from 'react';
import TextWithIconButton from '../../../base-components/StudioButton/TextWithIconButton';
import {StudioTextField} from '../../../base-components/StudioTextField';
import './ClickableListForm.scss';

export const ClickableListForm = ({
  prompt: {key, options, text, order, type, control},
  onSubmit,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getOrderedOptions = (options, order) => {
    const defaultComparator = (a, b) => a[1].localeCompare(b[1]);
    if (order && order.length) {
      return order.map(value => [value, options[value]]);
    }
    return Object.entries(options).sort(defaultComparator);
  };

  const handleSubmit = async answers => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      await onSubmit(answers);
      setIsSubmitting(false);
      setInputValue('');
    }
  };

  const handleInputSubmit = event => {
    event.preventDefault();
    if (inputValue) {
      handleSubmit([
        {
          key,
          value: inputValue,
          type,
          valueText: inputValue,
          question: text,
        },
      ]);
    }
  };

  const handleClickSubmit = (value, valueText) => {
    handleSubmit([
      {
        key,
        value,
        type,
        valueText,
        question: text,
      },
    ]);
  };

  return (
    <form onSubmit={handleInputSubmit} className="clickable-list-form">
      <label className="clickable-list-form__label">{text}</label>
      {control === 'hybrid' && (
        <StudioTextField
          className="clickable-list-form__input"
          onChange={event => setInputValue(event.target.value)}
          fullWidth
          focused={!isSubmitting}
          disabled={isSubmitting}
          value={inputValue}
        />
      )}
      {options &&
        getOrderedOptions(options, order).map(([value, valueText], index) => (
          <TextWithIconButton
            id={index}
            key={value}
            value={valueText}
            iconLeft={true}
            customClass="clickable-list-form__option"
            size="medium"
            buttonIcon="arrowAngledRight"
            dataTestID="clickable-list-option"
            type="button"
            disabled={isSubmitting}
            onClick={() => handleClickSubmit(value, valueText)}
          />
        ))}
    </form>
  );
};
