const optimizers = {
  Adadelta: 'Adadelta',
  Adagrad: 'Adagrad',
  Adam: 'Adam',
  AdamW: 'AdamW variant',
  SparseAdam: 'Adam for sparse tensors',
  Adamax: 'Adamax',
  ASGD: 'Averaged SGD',
  LBFGS: 'L-BFGS',
  RMSprop: 'RMS Propagation',
  Rprop: 'Resilient Back Propagation',
  SGD: 'Stochastic Gradient Descent',
};

export type Optimizer = keyof typeof optimizers;

export function optimizerToString(optimizer: Optimizer) {
  return optimizers[optimizer] ?? optimizer;
}
