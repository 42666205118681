import {Component} from 'react';
import PropTypes from 'prop-types';

class BaseStage extends Component {}

BaseStage.propTypes = {
  stage: PropTypes.any.isRequired,
  first: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  prevFinished: PropTypes.bool.isRequired,
};

export default BaseStage;
