import React from 'react';
import {useIntl} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
import FormButton from '../../base-components/StudioButton/FormButton';
import './Help.scss';

export const Help = () => {
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const anchorId = params.get('anchorId');
  const topicId = params.get('topicId');

  let src;
  if (topicId && anchorId) {
    src = `help/studio/index.htm#t=${topicId}.htm#${anchorId}`;
  } else if (topicId) {
    src = `help/studio/index.htm#t=${topicId}.htm`;
  } else {
    src = `help/studio/index.htm`;
  }

  return (
    <div className="help" data-testid="help">
      <FormButton
        buttonRole="secondary"
        value={intl.formatMessage({id: 'form.returnToPreviousPage'})}
        customClass="help__exit_button"
        onClick={() => {
          history.goBack();
        }}
      />
      <iframe
        id="helpDoc"
        title={intl.formatMessage({id: 'help.title'})}
        width="100%"
        height="100%"
        src={src}
        key={`${topicId}_${anchorId}`}
      ></iframe>
    </div>
  );
};
