import {SvgIcon} from '@material-ui/core';
import React from 'react';

const LineIcon = (props: any) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="-3 -3 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      {...props}
    >
      <path
        d="M1 12.3137L12.3137 1.00001"
        stroke="#767676"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default LineIcon;
