import {Grid} from '@material-ui/core';
import React, {useEffect} from 'react';
import {matchPath, Redirect, Route, Switch, useLocation} from 'react-router';
import {getProjectId, PrivateRoutes} from '../../config/routes';
import {ProjectForm} from '../CreateProjectForm';
import ProjectList from '../ProjectList/ProjectList';
import Interceptor from '../APIInterceptor/APIInterceptor';
import SettingsView from '../SettingsView/SettingsView';
import {ChooseIntegrationDialog} from '../ConfigureMarketplace/ChooseIntegrationDialog';
import {ConversationAssistant} from '../ConversationAssistant';
import {
  ConversationAssistantProvider,
  ConversationConsumer,
} from '../ConversationAssistant/useConversation';
import {CreateIntegrationDialog} from '../ConfigureMarketplace/CreateIntegrationDialog';
import {Dashboard} from '../Dashboard';
import {EditIntegrationDialog} from '../ConfigureMarketplace/EditIntegrationDialog';
import {Help} from '../Help';
import {MainMenu} from '../MainMenu';
import {ProjectWindow} from '../ProjectWindow';
import StatusChecker from './StatusChecker';
import {StudioConsole} from '../StudioConsole';
import {MarketplaceExplorer} from '../Marketplace';
import {useDispatch} from 'react-redux';
import {fetchMarketplaceIntegrations} from '../../store/marketplace/integrations';
import {ConversationNotifier} from '../ConversationAssistant/ConversationNotifier';
import {CreateDeployment, DeploymentDetails, ProdDashboard} from '../ProdDeployView';
import {LiteDesignPage} from '../StudioLite/LiteDesignPage';
import {useStudioMode} from '../StudioMode/StudioModeProvider';
import {LiteDeployPage} from '../StudioLite/LiteDeployPage';
import {LiteDefaultPage} from '../StudioLite/LiteDefaultPage';
import './StudioPage.scss';
import {Retrain} from '../ProdDeployView/Retrain/Retrain';

Interceptor();

export const StudioPage = () => {
  const {mode} = useStudioMode();
  const isLiteMode = mode === 'LITE';

  const {
    DESIGN,
    DASHBOARD,
    PROJECTLIST,
    TEST,
    DEPLOY,
    DEPLOY_INFERENCE_APP,
    SETTINGS,
    PROJECT,
    LANDING,
    MARKETPLACE,
    DEPLOY_ALL,
    DEPLOY_DETAILS,
    HELP,
    CREATE_INFERENCE_APP,
    INFERENCE_APP_DETAILS,
    DASHBOARD_DEPLOY,
    DASHBOARD_DEPLOY_ALL,
    VIEW_TEST_INFERENCE_APP,
    TEST_COMPARE,
    DEPLOYMENT_RETRAIN,
  } = PrivateRoutes;

  const routesToShowAssistant = isLiteMode ? [DASHBOARD] : [DESIGN, DASHBOARD];
  const routesWithDrawerAssistant = [DESIGN];
  const routesWithProjectTabs = [
    DESIGN,
    TEST,
    DEPLOY,
    DEPLOY_INFERENCE_APP,
    DEPLOY_ALL,
    CREATE_INFERENCE_APP,
    INFERENCE_APP_DETAILS,
    DEPLOYMENT_RETRAIN,
  ];
  const routesWithDeployTabs = [DASHBOARD_DEPLOY, DASHBOARD_DEPLOY_ALL];

  const liteModeWhitelist = [
    LANDING,
    DASHBOARD,
    CREATE_INFERENCE_APP,
    VIEW_TEST_INFERENCE_APP,
    INFERENCE_APP_DETAILS,
    TEST,
    TEST_COMPARE,
    SETTINGS,
    MARKETPLACE,
    HELP,
  ];

  const matchExists = (pathname: string, routes: string[]) =>
    routes.reduce(
      (match, route) => match || !!matchPath(pathname, {path: route, exact: true}),
      false
    );

  const {pathname} = useLocation();

  const showAssistant = matchExists(pathname, routesToShowAssistant);
  const showProjectTabs = matchExists(pathname, routesWithProjectTabs);
  const showDeployTabs = matchExists(pathname, routesWithDeployTabs);
  const isAssistantDrawer = matchExists(pathname, routesWithDrawerAssistant);

  const projectId = getProjectId(pathname);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMarketplaceIntegrations());
  }, [dispatch]);

  const getLiteModeDefaultPage = () => {
    if (matchPath(pathname, {path: DESIGN, exact: true})) {
      return <LiteDesignPage />;
    } else if (matchPath(pathname, {path: DEPLOY_ALL, exact: true})) {
      return <LiteDeployPage />;
    }
    return <LiteDefaultPage />;
  };

  const isLiteSupportedRoute = matchExists(pathname, liteModeWhitelist);

  return (
    <Grid container direction="column" className="studio-page" wrap="nowrap">
      <ChooseIntegrationDialog />
      <CreateIntegrationDialog />
      <EditIntegrationDialog />
      <ProjectForm />
      <MainMenu
        projectId={projectId}
        showProjectTabs={showProjectTabs}
        showDeployTabs={showDeployTabs}
      />
      <StudioConsole />
      <ConversationAssistantProvider path={projectId}>
        <ConversationNotifier projectId={projectId} pathname={pathname} />
        {/* TODO: convert StatusChecker to functional component
            so that ConversationConsumer is not needed. Instead
            the useConsumer hook can be used within StatusChecker 
        */}
        <ConversationConsumer>
          {([{status: {conversationId} = {conversationId: undefined}}]) => {
            return (
              <StatusChecker projectId={projectId} conversationId={conversationId} />
            );
          }}
        </ConversationConsumer>

        <Grid item container className="studio-page__content" wrap="nowrap" xs={12}>
          {showAssistant && <ConversationAssistant isDrawer={isAssistantDrawer} />}
          {isLiteMode && !isLiteSupportedRoute ? (
            getLiteModeDefaultPage()
          ) : (
            <Switch>
              <Route
                path={LANDING}
                exact={true}
                render={props => (
                  <Redirect
                    to={{
                      pathname: '/dashboard',
                      search: props.location.search,
                    }}
                  />
                )}
              />
              <Route path={DASHBOARD} exact={true} render={() => <Dashboard />} />
              <Route path={PROJECTLIST} render={() => <ProjectList />} />
              <Route path={PROJECT} render={props => <ProjectWindow {...props} />} />
              <Route path={SETTINGS} render={props => <SettingsView {...props} />} />
              <Route path={MARKETPLACE} render={() => <MarketplaceExplorer />} />
              <Route path={DASHBOARD_DEPLOY} render={() => <CreateDeployment />} />
              <Route path={DASHBOARD_DEPLOY_ALL} render={() => <ProdDashboard />} />
              <Route path={DEPLOY_DETAILS} render={() => <DeploymentDetails />} />
              <Route path={DEPLOYMENT_RETRAIN} render={() => <Retrain />} />
              <Route path={HELP} render={() => <Help />} />
            </Switch>
          )}
        </Grid>
      </ConversationAssistantProvider>
    </Grid>
  );
};
