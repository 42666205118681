import Axios from 'axios';
import {ExecutionContainer} from '../../types/model/framework/ExecutionContainer';
import {PostProcessorType} from '../../types/postprocessor/PostProcessorType';

type Request = {
  applicationId: string;
  projectId: string;
  fileName?: string;
  postProcessorType?: PostProcessorType;
  executionContainer?: ExecutionContainer;
};

export const getPostProcessorFileUrl = ({
  projectId,
  applicationId,
  fileName,
  postProcessorType,
  executionContainer,
}: Request) =>
  `studio/postprocessor/view/${projectId}/${applicationId}/${fileName}?type=${postProcessorType}&container=${executionContainer}`;

export async function getPostProcessorFile({
  projectId,
  applicationId,
  fileName,
  postProcessorType,
  executionContainer,
}: Request) {
  return Axios.request<Blob>({
    method: 'GET',
    url: `${getPostProcessorFileUrl({
      projectId,
      applicationId,
      fileName,
      postProcessorType,
      executionContainer,
    })}&cachebust=${Date.now()}`,
    responseType: 'blob',
  });
}
