import React from 'react';
import BeforeImage1 from './images/scale1-before.png';
import AfterImage1 from './images/scale1-after.png';
import BeforeImage2 from './images/scale2-before.png';
import AfterImage2 from './images/scale2-after.png';
import './Help.scss';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>Scale</h4>
      <div className="flow-graph__help_content_detail">
        <p>Scaling is the process of making an image larger (scale &gt; 1.0) or smaller (scale &lt; 1.0). It is nothing but resizing the image i.e, altering the size of your image without cutting anything out . However, a common problem with resizing photos larger than the original is that it changes the resolution. The resizing of data, either upscaling or downscaling is based on need for increased or decreased resolution.</p>
        <p>The scale factor is a decimal percent. Scale factor of 0.25 is the same as scaling the image to 25% its current size. When you scale an image up, the image will have more pixels. So new pixel values are to be created to fill in the spaces between the original values. This mathematical operation is called interpolation.</p>
        <p>There are different interpolation methods like bilinear, bicubic, nearest neighbor etc.</p>
        <p>The most basic method commonly used is nearest neighbor, a procedure that is not only fast but does not introduce any artificial data into the final output. It is one of the simpler ways of increasing image size, by replacing every pixel with the nearest pixel in the output . Despite the speed with which it can be calculated, this method suffers from the fact that the resulting image often contains block artifacts.</p>
        <p>Two additional techniques commonly used are bilinear and bicubic interpolation. These procedures examine the points surrounding the missing data to mathematically approximate the needed values. This estimation produces smoother output that is often both visually close and quantitatively a better representation of the actual data. However, this is at the expense of computational complexity (required for performing the mathematical evaluation) and often a blurring of edges and edge details.</p>
        <div className="help_images">
          <figure>
            <img src={BeforeImage1} alt="before" />
            <figcaption>Original</figcaption>
          </figure>
          <figure>
            <img src={AfterImage1} alt="after" className="scale_small"/>
            <figcaption>Resized to 1/4th the original</figcaption>
          </figure>
        </div>
        <p>Scaling images to higher size may result in blurry images.</p>
        <div className="help_images">
          <figure>
            <img src={BeforeImage2} alt="before" className="scale_small"/>
            <figcaption>Small image</figcaption>
          </figure>
          <figure>
            <img src={AfterImage2} alt="after" />
            <figcaption>Resized to 300px by 450px</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};
