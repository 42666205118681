import {useState, useEffect} from 'react';
import axios from 'axios';

// This hook will get the image  header values
export type XSizesItem = {
  id: string;
  w: number;
  h: number;
  s: number;
};

export type ImageMetadata = {
  fileName: string | null;
  width: number | null;
  height: number | null;
  size: number | null;
  busy: boolean;
};

export const DEFAULT_IMAGE_METADATA = {
  fileName: null,
  width: null,
  height: null,
  size: null,
  busy: false,
};

export function useStudioImageMetaData(url: string, filename?: string): ImageMetadata {
  const [metaData, setMetaData] = useState<ImageMetadata>(DEFAULT_IMAGE_METADATA);

  useEffect(() => {
    async function fetchImage() {
      try {
        const {headers} = await axios.get(url + '&resolution=1:1', {
          responseType: 'blob',
        });
        const unparsedJson = headers?.['x-sizes'];

        let header: XSizesItem | null = null;
        if (unparsedJson) {
          try {
            header = JSON.parse(unparsedJson)?.[0];
          } catch (e) {}
        }

        if (header) {
          setMetaData({
            fileName: header.id || filename || 'default',
            width: header.w,
            height: header.h,
            size: header.s,
            busy: false,
          });
        }
      } catch (e) {}
    }
    setMetaData({...metaData, busy: true});
    fetchImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return metaData;
}
