import {useEffect, useState, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../store';
import {
  EVENT_TYPE,
  getVideoStream,
  VideoStreamEvent,
} from '../TestDeployView/VideoStream';
import {InputSource} from '../../types/deployment/InferenceInputSource';

export const useONNXVideoStream = (
  projectId: string,
  type: InputSource | '',
  videoElement: HTMLVideoElement | null,
  url?: string,
  onError?: (message?: string) => void,
  onFinish?: () => void
): {videoStream?: MediaStream} => {
  const serverConfig = useSelector((state: RootState) => state.config);
  const {start, stop} = useMemo(getVideoStream, []);
  const [videoStream, setVideoStream] = useState<MediaStream>();
  const onErrorRef = useRef(onError);
  const onFinishRef = useRef(onFinish);

  useEffect(() => {
    let stream: MediaStream;

    async function startWebCamera() {
      try {
        const mediaConstraints = {
          audio: false,
          video: true,
        };
        stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
        setVideoStream(stream);
      } catch (e) {
        onErrorRef.current?.('Error initializing web camera');
        console.error('Error initializing web camera', e);
      }
    }

    if (type === 'WEB_CAMERA') {
      startWebCamera();

      return () => {
        if (stream) {
          stream.getTracks().forEach(track => track.stop());
        }
      };
    } else if (type === 'IP_CAMERA' || type === 'FILE') {
      start({
        receiveOnly: true,
        source: type,
        url,
        projectId,
        serverConfig,
        studioTaskId: null,
        monitorConfig: {monitorStream: false},
        onEvent: (event: VideoStreamEvent) => {
          if (event.type === EVENT_TYPE.REMOTE_STREAM) {
            setVideoStream(event.stream);
          } else if (event.type === EVENT_TYPE.ERROR) {
            if (event.message) {
              onErrorRef.current?.(`Streaming error: ${event.message}`);
            } else {
              onErrorRef.current?.(`Streaming error. Please try again.`);
            }
          } else if (event.type === EVENT_TYPE.STREAM_FINISHED) {
            onFinishRef.current?.();
          }
        },
        ytRestricted: true,
      });
    }
  }, [type, start, url, projectId, serverConfig]);

  useEffect(() => {
    if (videoElement) {
      videoElement.srcObject = videoStream || null;
    }

    return () => {
      if (videoStream) {
        stop();
      }
    };
  }, [videoStream, videoElement, stop]);

  return {videoStream};
};
