import {maxBy} from 'lodash';
import React, {useCallback, useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import URL, {MODEL_SUB_OP} from '../../../config/url';
import {UnparsedJson} from '../../../types/utility/UnparsedJson';
import './PoseDetectionResult.scss';
import {Box, Typography} from '@material-ui/core';
import {DeploymentPoseDetectionResult} from '../../../types/deployment/DeploymentPoseDetectionResult';
import {CachedImageCard} from '../SimpleImageCard/CachedImageCard';

const IMAGE_WIDTH = 125;
const IMAGE_HEIGHT = 125;

export type PoseDetectionResultProps = {
  search: string;
  data: UnparsedJson;
  projectId: string;
  deploymentId: string;
};

export function PoseDetectionResult({
  search,
  data,
  projectId,
  deploymentId,
}: PoseDetectionResultProps) {
  const images = useMemo(() => {
    // TODO: fix backend for empty inferenceResults
    if (data === '""') {
      return [];
    }

    let images: DeploymentPoseDetectionResult[] = JSON.parse(data);
    if (search) {
      images = images.filter(image => {
        const topResult = maxBy(image.stats, row => row.progress ?? 0);
        return topResult?.label?.toLowerCase().includes(search.toLowerCase()) ?? false;
      });
    }
    return images ?? [];
  }, [data, search]);

  const getImage = useCallback(
    (filename: string | undefined) => {
      var func = function(width?: number, height?: number) {
        var url = `${URL.DATASET(
          projectId,
          MODEL_SUB_OP.GET_BY_ID
        )}?id=${filename?.replace(
          ',',
          '%252C'
        )}&deploymentId=${deploymentId}&dataPurpose=INPUT&cachebust=${Date.now()}`;
        if (width && height) {
          url += `&resolution=${width}:${height}`;
        }
        return url;
      };
      return func;
    },
    [deploymentId, projectId]
  );

  return (
    <div className="inference-grid-container">
      <div className="view-grid">
        {images.length === 0 ? (
          <div className="classification-result__no-results">
            <FormattedMessage id="test.noResults" />
          </div>
        ) : (
          images.map((image, i) => (
            <Box>
              <CachedImageCard
                imageFunc={getImage(image.filename)}
                width={IMAGE_WIDTH}
                height={IMAGE_HEIGHT}
                points={image.stats[0].points}
              />
              <Typography
                className="image-card-horizontal_text"
                variant="caption"
                color="textSecondary"
                component="div"
                align="center"
                noWrap
              >
                {image.filename}
              </Typography>
            </Box>
          ))
        )}
      </div>
    </div>
  );
}
