import {StreamingDevice} from '../../types/deployment/StreamingDeviceResponse';
import {request} from '../request';

export type DeviceListResponse = StreamingDevice[];

export async function getDeviceList() {
  return request<DeviceListResponse>({
    method: 'get',
    url: '/device/list',
  });
}

export async function deleteDevice(id: string) {
  return request<{}>({
    method: 'get',
    url: `/device/${id}/delete`,
  });
}
