import React from 'react';
import axios from 'axios';
import {useEffect, useState} from 'react';
import URL from '../../config/url';
import {StudioVersionResponse} from '../../types/operation/StudioVersionResponse';
import {FormattedMessage} from 'react-intl';
import {Typography} from '@material-ui/core';
import cn from 'classnames';

export const AdditionalInformation = ({show = true}: {show?: boolean}) => {
  const [version, setVersion] = useState({
    studioVersion: '',
    versionType: '',
    runtimeVersions: {},
    componentVersions: {},
    serviceVersions: {},
  });

  useEffect(() => {
    const fetchSDKVersion = async () => {
      try {
        const {data} = await axios.get<StudioVersionResponse>(URL.NETDEPLOY_VERSION);
        const serverVersions = data.body;
        setVersion(serverVersions);
      } catch (e) {}
    };
    fetchSDKVersion();
  }, []);

  return (
    version && (
      <div
        className={cn(
          'additional_info_container',
          show ? 'additional_info_container_show' : 'additional_info_container_hide'
        )}
      >
        <Typography variant="subtitle1" key="runtimeVersions">
          <FormattedMessage id="about.runtimeVersions" />
        </Typography>
        {Object.entries(version.runtimeVersions).map(([k, v]) => {
          return (
            <Typography variant="subtitle2" key={k}>
              <FormattedMessage
                id="about.versionEntry"
                values={{title: k, value: v as string}}
              />
            </Typography>
          );
        })}
        <Typography variant="subtitle1" key="componentVersions">
          <FormattedMessage id="about.componentVersions" />
        </Typography>
        {Object.entries(version.componentVersions).map(([k, v]) => {
          return (
            <Typography variant="subtitle2" key={k}>
              <FormattedMessage
                id="about.versionEntry"
                values={{title: k, value: v as string}}
              />
            </Typography>
          );
        })}
        <Typography variant="subtitle1" key="serviceVersions">
          <FormattedMessage id="about.serviceVersions" />
        </Typography>
        {Object.entries(version.serviceVersions).map(([k, v]) => {
          return (
            <Typography variant="subtitle2" key={k}>
              <FormattedMessage
                id="about.versionEntry"
                values={{title: k, value: v as string}}
              />
            </Typography>
          );
        })}
      </div>
    )
  );
};
