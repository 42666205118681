import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ReplaceIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="16px"
          height="20px"
          viewBox="0 0 16 20"
          version="1.1"
        >
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-849.000000, -9094.000000)"
            >
              <g id="Model-Action-Icons" transform="translate(56.000000, 7940.000000)">
                <g id="row" transform="translate(0.000000, 1113.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="model-actions-/-replace-/-Enabled,-Hover,-Focus"
                      transform="translate(148.000000, 39.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24" />
                      <path
                        d="M6,8 L6,20 L16,20 L16,22 L6,22 C4.9456382,22 4.08183488,21.1841222 4.00548574,20.1492623 L4,20 L4,8 L6,8 Z M18,2 C19.0543618,2 19.9181651,2.81587779 19.9945143,3.85073766 L20,4 L20,16 C20,17.0543618 19.1841222,17.9181651 18.1492623,17.9945143 L18,18 L15,18 L15,16 L18,16 L18,4 L10,4 L10,10 L8,10 L8,4 C8,2.9456382 8.81587779,2.08183488 9.85073766,2.00548574 L10,2 L18,2 Z M14.400116,11.0000028 C14.0400126,12.4453186 13.4149235,13.6819315 12.5856516,14.7185213 C12.092522,15.3349333 11.5954648,15.8068099 11.149263,16.1496924 L13,18 L8,18 L8,13 L10.0710127,15.0701401 C10.1042244,15.0462658 10.1389205,15.0208096 10.175,14.99375 C10.5950181,14.6787364 11.0180353,14.2768701 11.4143484,13.7814787 C12.0404691,12.9988279 12.5305731,12.0768442 12.846277,11.00129 L14.400116,11.0000028 Z"
                        id="replace-icon"
                        // fill="#3F3F3F"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

ReplaceIcon.propTypes = {
  className: PropTypes.string,
};

export default ReplaceIcon;
