import React, {Component} from 'react';
import PropTypes from 'prop-types';

class RestartIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="18px"
          height="8px"
          viewBox="0 0 18 8"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-847.000000, -6957.000000)"
            >
              <g id="Model-Action-Icons" transform="translate(56.000000, 6308.000000)">
                <g id="row" transform="translate(0.000000, 603.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="model-actions-/-restart-/-Enabled,-Focus"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M17.546875,11.0078125 L20.515625,8 L20.515625,15.5 L13.015625,15.5 L16.0625,12.4921875 C14.8124938,11.4505156 13.3802164,10.9296875 11.765625,10.9296875 C10.3072844,10.9296875 8.99870371,11.3593707 7.83984375,12.21875 C6.68098379,13.0781293 5.88021055,14.1718684 5.4375,15.5 L3.484375,14.875 C4.05729453,13.1041578 5.10546113,11.6588598 6.62890625,10.5390625 C8.15235137,9.41926523 9.86457383,8.859375 11.765625,8.859375 C13.9531359,8.859375 15.8802,9.57551367 17.546875,11.0078125 Z"
                        id="restart"
                        fill="currentColor"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

RestartIcon.propTypes = {
  className: PropTypes.string,
};

export default RestartIcon;
