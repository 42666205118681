import React from 'react';
import {PropTypes} from 'prop-types';
import {useField} from 'formik';
import {DynamicFormBody} from '../DynamicForm';
import {useDynamicFormField} from '../DynamicFormToFormikAdapter';
import {validateField} from '../DynamicForm.helpers';
import {StudioSelect as Select} from '../../../base-components/StudioSelect';

export const DynamicSelect = ({
  label,
  options,
  order,
  subQuestions,
  name,
  className,
  required,
  disabled,
  tooltip,
  note,
  level,
  ...props
}) => {
  useDynamicFormField(name);
  const [field, meta] = useField({
    name,
    validate: value => validateField(value, 'STRING', required),
  });

  if (field.value === undefined) {
    return null;
  }

  const errorText = meta.initialError || (meta.error && meta.touched) ? meta.error : '';
  return (
    <>
      <Select
        className={className}
        required={required}
        helperText={errorText}
        note={note}
        error={!!errorText}
        disabled={disabled}
        name={name}
        label={label}
        placeholder={
          label?.toLowerCase()?.startsWith('select') ? label : 'Select ' + label
        }
        options={options}
        order={order}
        tooltip={tooltip}
        fixedTooltip
        SelectProps={{
          ...field,
          multiple: props.multiple,
          onChange: e => {
            /**
             * TODO fix this for 1.5.4
             * The dynamic form changes after the onChange event is executed.
             * Using setTimeout, we manage to put the execution in the evenloop stack pile,
             * waiting for the necessary fields to be rendered first.
             * Then, the onChange is executed, including the field validation,
             * which captures the event with the modified form and achieves correct validation
             */
            if (field.onChange) {
              setTimeout(() => {
                field.onChange(e);
              }, 0);
            }
          },
        }}
        {...props}
      />
      {subQuestions && field.value && (
        <DynamicFormBody prompts={subQuestions[field.value]} level={level + 1} />
      )}
    </>
  );
};

DynamicSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  subQuestions: PropTypes.object,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  note: PropTypes.string,
};

DynamicSelect.defaultProps = {
  subQuestions: null,
  className: null,
  required: false,
  disabled: false,
};
