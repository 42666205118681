import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {matchPath, useHistory, useLocation} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import FormButton from '../../base-components/StudioButton/FormButton';
import {PrivateRoutes} from '../../config/routes';
import {isModelViewerStale, setModelViewerStale} from '../../store/model';
import '../DialogLegacy/ModalWindow.scss';
import './ModelViewer.scss';

export type ModelViewerProps = {
  projectId: string;
};

export function ModelViewer(props: ModelViewerProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const [firstRender, setFirstRender] = useState(true);
  const [key, setKey] = useState(1);
  const isStale = useSelector(isModelViewerStale);
  const routeMatches = Boolean(matchPath(location.pathname, PrivateRoutes.MODELVIEWER));

  useEffect(() => {
    if (routeMatches && isStale) {
      dispatch(setModelViewerStale(false));
      setKey(key + 1);
    }
  }, [routeMatches, isStale, key, dispatch]);

  if (firstRender) {
    if (routeMatches) {
      setFirstRender(false);
    } else {
      return null;
    }
  }

  return (
    <div
      key={key.toString()}
      style={{display: routeMatches ? 'block' : 'none'}}
      className="modal-window"
      id="model-viewer"
    >
      <div className="container" id="model-viewer-container" data-testid="model-viewer">
        <FormButton
          buttonRole="secondary"
          value={intl.formatMessage({id: 'form.returnToStage'})}
          dataTestid="exit-model-viewer"
          id="exit-model-viewer"
          onClick={() => {
            history.push(`/project/${props.projectId}/upload`);
          }}
          customClass="modal-window__return-btn"
          disabled={false}
        />
        <iframe
          src={`/netron/index.html?projectId=${props.projectId}`}
          title="model-viewer"
          height={100}
          width={100}
          style={{zIndex: 0}}
        />
      </div>
    </div>
  );
}
