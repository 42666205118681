// NOTE: Work in progress!!!
const THEME = {
  typography: {
    fontFamily: ['Proxima Nova', 'Arial', 'Helvetica', 'sans-serif'].join(','),
    fontSize: 14,
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    type: 'light',
    primary: {
      light: '#7986cb',
      main: '#59198c',
      dark: '#303f9f',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'inherit',
        borderRadius: 4,
      },
    },
    MuiPaper: {
      root: {
        borderRadius: 0,
      },
    },
    MuiPaperOutlines: {
      root: {
        borderRadius: 0,
      },
    },
    MuiDialogContent: {
      root: {
        background: '#f9f9fa',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'var(--white)',
        // Work in progress
        // '&$focus': {
        //   backgroundColor: 'var(--white)',
        // },
        // '&:hover': {
        //   backgroundColor: 'var(--white)',
        // },
        // '&$selected': {
        //   backgroundColor: 'var(--white)',
        // },
      },
    },
  },
  props: {
    // MuiFilledInput: {
    //   margin: 'dense',
    // },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
};

export default THEME;
