import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  SelectProps,
  TextFieldProps,
} from '@material-ui/core';
import {
  ArrowDownwardRounded,
  ArrowUpwardRounded,
  Search,
  ViewComfyRounded,
  ViewListRounded,
} from '@material-ui/icons';
import {Pagination} from '@material-ui/lab';
import {bindActionCreators, createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {chain, fromPairs, orderBy, range, uniq} from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory} from 'react-router';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon';
import {StudioSearch} from '../../base-components/StudioSearch';
import {StudioSelect} from '../../base-components/StudioSelect';
import {StudioTab, StudioTabs} from '../../base-components/StudioTab';
import URL from '../../config/url';
import {
  DatasetDistribution,
  DatasetDistributionResponse,
} from '../../types/dataset/DatasetDistributionResponse';
import {
  AnnotationType,
  DatasetGetAnnotationsResponse,
  ImageType,
} from '../../types/dataset/DatasetGetAnnotationsResponse';
import {DatasetLabelsRequest} from '../../types/dataset/DatasetLabelsRequest';
import {DatasetListRequest} from '../../types/dataset/DatasetListRequest';
import {DatasetLabelsResponse} from '../../types/dataset/DatasetLabelsResponse';
import {DatasetListResponse} from '../../types/dataset/DatasetListResponse';
import {DatasetSummaryResponse} from '../../types/dataset/DatasetSummaryResponse';
import {DataViewerItemThumbnail} from './DataViewerItemThumbnail';
import {DataViewerSummary} from './DataViewerSummary';
import {DataViewerTooltip} from './DataViewerTooltip';
import {GridViewer} from './GridViewer';
import {GridViewerItem} from './GridViewerItem';
import {LabelViewer} from './LabelViewer';
import {
  ItemType,
  ListViewer,
  ListViewerFilename,
  ListViewerFilesize,
  ListViewerDimensions,
  ListViewerLabel,
  useListViewerRow,
  ListViewerItem,
} from './ListViewer';
import {DataSplitType} from '../../types/dataset/Dataset';
import {DataViewerListItemThumbnail} from './DataViewerListItemThumbnail';
import {Response} from '../../types/response/Response';
import {Project} from '../../types/project/Project';
import './DataViewer.scss';
import {GridSkeleton} from '../Skeleton';

const LABEL_FILE_COUNT = 20;
const LABEL_PAGE_SIZE = 5;
const LIST_PAGE_SIZE = 50;
const GRID_THUMB_SIZE = 93;
const LIST_THUMB_SIZE = 55;
const MAX_FILENAMES_LENGTH = 3900;

export type DataViewerProps = {
  projectId: string;
};

export const getImageUrl = (
  projectId: string,
  filenames: string[],
  splitType?: DataSplitType,
  resourceId?: string,
  width?: number,
  height?: number
) => {
  const joinedFilenames = filenames.map(x => x.replace(',', '%2C')).join(',');
  return URL.DATASET_GET_BY_FILENAMES(projectId, {
    filenames: joinedFilenames,
    splitType,
    resolution: width ? `${width}:${height}` : undefined,
    resourceId,
  });
};

export type ImageDimensionMap = {
  [id: string]: {
    height: number;
    width: number;
    size: number;
  };
};

export const DataViewer = ({projectId}: DataViewerProps) => {
  const {
    label,
    labelField,
    page,
    setLabel,
    setLabelField,
    setPage,
    setSort,
    sort,
    sortDir,
    splitType,
    setSplitType,
    toggleSortDir,
    toggleSortHeading,
    toggleView,
    view,
  } = useDataViewer();
  const intl = useIntl();

  const getImageThumbnailUrl = (
    filenames: string[],
    splitType?: DataSplitType,
    resourceId?: string,
    size?: number
  ) =>
    getImageUrl(
      projectId,
      filenames,
      splitType,
      resourceId,
      size || GRID_THUMB_SIZE,
      size || GRID_THUMB_SIZE
    );

  const fetcher = async (url: string) => {
    const res = await axios.get(url);
    return res.data;
  };

  const {data: projectResponse} = useSWR<Response<Project>>(
    URL.PROJECT_GET(projectId),
    fetcher
  );

  const resourceId = projectResponse?.body.dataSet?.id;
  const dataLoader = projectResponse?.body.dataSet?.dataLoader;
  const noMetadata = dataLoader === 'Raw';
  const rawImagesOnly = noMetadata || dataLoader === 'Custom';

  React.useEffect(() => {
    setSort(rawImagesOnly ? 'filename' : 'label');
    setSplitType(rawImagesOnly ? undefined : 'TRAIN');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawImagesOnly]);

  const columns: Array<{
    id: 'image' | 'filename' | 'size' | 'width' | 'lastEdited' | 'label';
    label: string;
    sortable: boolean;
  }> = [
    {id: 'image', label: '', sortable: false},
    {
      id: 'filename',
      label: intl.formatMessage({id: 'dataViewer.fileName'}),
      sortable: true,
    },
    {id: 'size', label: intl.formatMessage({id: 'dataViewer.fileSize'}), sortable: false},
    {
      id: 'width',
      label: intl.formatMessage({id: 'dataViewer.dimensions'}),
      sortable: false,
    },
  ];

  if (!rawImagesOnly) {
    columns.push({
      id: 'label',
      label: intl.formatMessage({id: 'dataViewer.label'}),
      sortable: true,
    });
  }

  const SortOptions = (props: {onSort(sort: SortId): void; sort: SortId}) => (
    <StudioSelect
      options={fromPairs(
        columns
          .filter(column => column.label.length > 0)
          .map(column => [column.id, column.label])
      )}
      SelectProps={{
        value: props.sort,
        onChange: (event: Parameters<NonNullable<SelectProps['onChange']>>[0]) => {
          props.onSort(event.target.value as SortId);
        },
      }}
    />
  );

  const {data: summaryData} = useSWRImmutable<DatasetSummaryResponse>(
    !rawImagesOnly && resourceId ? resourceId + URL.DATASET_SUMMARY(projectId) : null,
    () => fetcher(URL.DATASET_SUMMARY(projectId))
  );
  const {data: distributionData} = useSWRImmutable<DatasetDistributionResponse>(
    !noMetadata && resourceId ? resourceId + URL.DATASET_DISTRIBUTION({projectId}) : null,
    () => fetcher(URL.DATASET_DISTRIBUTION({projectId}))
  );

  const totalFileCount = getFileCount(distributionData);
  const labelNameMapping = distributionData?.body?.labelNameMapping || {};
  const labelNameMapper = (lbl: string) => labelNameMapping[lbl] || lbl;

  const formatTab = ({
    selectedValue,
    tabLabel,
    distribution,
    proportion,
  }: {
    selectedValue: DataSplitType;
    tabLabel: string;
    distribution?: DatasetDistributionResponse['body']['trainDistribution'];
    proportion?: number;
  }) => ({
    value: selectedValue,
    label: intl.formatMessage({id: tabLabel}),
    count: distribution?.imagesCount,
    countLabel: intl.formatMessage(
      {id: 'dataViewer.tabCount'},
      {
        distributionCount: distribution?.imagesCount || 0,
        totalCount: totalFileCount,
      }
    ),
    percentageLabel: intl.formatMessage(
      {id: 'dataViewer.tabPercentage'},
      {
        percentage: intl.formatNumber((proportion || 0.0) / 100.0, {
          style: 'percent',
          maximumFractionDigits: 0,
        }),
      }
    ),
  });

  type SplitTab = {
    value: DataSplitType;
    label: string;
    count?: number;
    countLabel: string;
    percentageLabel: string;
  };

  const SPLIT_TABS: SplitTab[] = [
    formatTab({
      selectedValue: 'TRAIN',
      tabLabel: 'dataViewer.trainingTab',
      distribution: distributionData?.body.trainDistribution,
      proportion: distributionData?.body.trainProportion,
    }),
    formatTab({
      selectedValue: 'VALIDATION',
      tabLabel: 'dataViewer.validationTab',
      distribution: distributionData?.body.validationDistribution,
      proportion: distributionData?.body.validationProportion,
    }),
    formatTab({
      selectedValue: 'TEST',
      tabLabel: 'dataViewer.testingTab',
      distribution: distributionData?.body.testDistribution,
      proportion: distributionData?.body.testProportion,
    }),
  ];

  const {data: annotationData} = useSWRImmutable<DatasetGetAnnotationsResponse>(
    rawImagesOnly || SPLIT_TABS.find(tab => tab.value === splitType)?.count
      ? URL.DATASET_GET_ANNOTATIONS(projectId, {splitType, resourceId})
      : null,
    fetcher
  );

  const isLabelView = sort === 'label' && view === 'grid' && label.length === 0;
  const pageSize = isLabelView ? LABEL_PAGE_SIZE : LIST_PAGE_SIZE;
  const startFrom = (page - 1) * pageSize;

  const listQuery: DatasetListRequest = {
    label,
    pageSize,
    sort,
    sortDir,
    splitType,
    startFrom,
  };
  const labelsQuery: DatasetLabelsRequest = {
    fileCount: LABEL_FILE_COUNT,
    pageSize,
    sortDir,
    splitType,
    startFrom,
  };

  const labelsData = getLabelData(annotationData, labelsQuery);
  const listData = getListData(annotationData, listQuery, sort === 'label');
  const itemCount = (isLabelView ? labelsData?.count : listData?.count) || 0;
  const pageCount = Math.max(1, Math.ceil(itemCount / pageSize));

  const currentFileNames = isLabelView
    ? labelsData?.labels.flatMap(data => {
        const files = data.files.slice(0, LABEL_FILE_COUNT);
        return files.map(file => file.filename);
      })
    : listData?.files.map(file => file.filename);

  const {data: imageDimensions} = useSWRImmutable<ImageDimensionMap>(
    currentFileNames?.length && view ? currentFileNames.join() + view : null,
    async () => {
      const url = getImageThumbnailUrl(
        currentFileNames,
        splitType,
        resourceId,
        view === 'list' ? LIST_THUMB_SIZE : undefined
      );

      if (url.length > MAX_FILENAMES_LENGTH) {
        let allFileNames: string[] = [];
        let details: {w: number; h: number; s: number}[] = [];
        for (let i = 0; i < labelsData?.labels?.length; i++) {
          const label = labelsData?.labels[i];
          if (label) {
            const files = label?.files
              ?.slice(0, LABEL_FILE_COUNT)
              .map(file => file.filename);

            allFileNames = allFileNames.concat(files);
            let singleUrl = getImageThumbnailUrl(
              files,
              splitType,
              resourceId,
              view === 'list' ? LIST_THUMB_SIZE : undefined
            );
            const {headers} = await axios.get(singleUrl, {responseType: 'blob'});
            const xSizesHeader = headers?.['x-sizes'];
            let imageDetails: {w: number; h: number; s: number}[] = JSON.parse(
              xSizesHeader
            );
            details = details.concat(imageDetails);
          }
        }

        return allFileNames.reduce<ImageDimensionMap>((acc, curr, i) => {
          acc[curr] = {
            height: details[i].h,
            width: details[i].w,
            size: details[i].s,
          };
          return acc;
        }, {});
      } else {
        const {headers} = await axios.get(url, {responseType: 'blob'});
        const xSizesHeader = headers?.['x-sizes'];
        let imageDetails: {w: number; h: number; s: number}[] = JSON.parse(xSizesHeader);
        return currentFileNames.reduce<ImageDimensionMap>((acc, curr, i) => {
          acc[curr] = {
            height: imageDetails[i].h,
            width: imageDetails[i].w,
            size: imageDetails[i].s,
          };
          return acc;
        }, {});
      }
    }
  );

  const scaleTooltipAnnotation = (item: ItemType) =>
    scaleAnnotation(
      item.annotation,
      {
        width: item.width ?? 0,
        height: item.height ?? 0,
      },
      {
        width: imageDimensions?.[item.filename]?.width ?? item.width ?? 0,
        height: imageDimensions?.[item.filename]?.height ?? item.height ?? 0,
      }
    );

  const scaleThumbAnnotation = (item: ItemType) =>
    scaleAnnotation(
      item.annotation,
      {
        width: item.width ?? 0,
        height: item.height ?? 0,
      },
      {
        width: GRID_THUMB_SIZE,
        height: GRID_THUMB_SIZE,
      }
    );

  const SplitTabs = () => (
    <div className="data-viewer2__tabs">
      <StudioTabs
        value={splitType}
        onChange={(_event, index) => setSplitType(index)}
        className="dashboard__tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        {SPLIT_TABS.map(splitTab => (
          <StudioTab
            className="data-viewer2__tab"
            key={splitTab.label}
            label={
              <span className="data-viewer2__tab-content">
                <span className="data-viewer2__tab-title">{splitTab.label}</span>
                <span className="data-viewer2__tab-stats">
                  <span>{splitTab.countLabel}</span>
                  <span>{splitTab.percentageLabel}</span>
                </span>
              </span>
            }
            value={splitTab.value}
          />
        ))}
      </StudioTabs>
    </div>
  );

  const renderViewer = () => {
    if (isLabelView) {
      return labelsData?.labels?.length ? (
        <LabelViewer
          labels={labelsData.labels}
          labelNameMapper={labelNameMapper}
          limit={LABEL_FILE_COUNT}
          onLabelClick={setLabel}
        >
          {(label, file, filenames, pos) => {
            return (
              <DataViewerTooltip
                filename={file.filename}
                label={labelNameMapper(label)}
                size={imageDimensions?.[file.filename]?.size || file.size}
                width={imageDimensions?.[file.filename]?.width || file.width}
                height={imageDimensions?.[file.filename]?.height || file.height}
                url={getImageUrl(projectId, [file.filename], splitType, resourceId)}
                annotation={scaleTooltipAnnotation(file)}
              >
                <DataViewerItemThumbnail
                  url={getImageThumbnailUrl(filenames, splitType, resourceId)}
                  pos={pos}
                  annotation={scaleThumbAnnotation(file)}
                />
              </DataViewerTooltip>
            );
          }}
        </LabelViewer>
      ) : (
        <GridSkeleton
          tileHeight={128}
          tileWidth={128}
          tileMarginX={14}
          tileMarginY={14}
          minTiles={30}
        />
      );
    } else if (view === 'grid') {
      return (
        <GridViewer count={itemCount} label={labelNameMapper(label)}>
          {listData.files.map((file: ItemType, index) => (
            <GridViewerItem key={file.id}>
              <DataViewerTooltip
                filename={file.filename}
                label={labelNameMapper(file.label || '')}
                lastEdited={file.lastEdited}
                size={imageDimensions?.[file.filename]?.size || file.size}
                width={imageDimensions?.[file.filename]?.width || file.width}
                height={imageDimensions?.[file.filename]?.height || file.height}
                url={getImageUrl(projectId, [file.filename], splitType, resourceId)}
                annotation={scaleTooltipAnnotation(file)}
              >
                <DataViewerItemThumbnail
                  pos={index}
                  annotation={scaleThumbAnnotation(file)}
                  url={getImageThumbnailUrl(
                    listData?.files.map(file => file.filename),
                    splitType,
                    resourceId
                  )}
                />
              </DataViewerTooltip>
            </GridViewerItem>
          ))}
        </GridViewer>
      );
    } else {
      const ListViewerTooltipThumbnail = ({pos}: {pos: number}) => {
        const {item} = useListViewerRow();
        const filenames = listData?.files.map(file => file.filename);
        return (
          <DataViewerTooltip
            filename={item.filename}
            label={labelNameMapper(item.label!)}
            lastEdited={item.lastEdited}
            size={imageDimensions?.[item.filename]?.size || item.size}
            width={imageDimensions?.[item.filename]?.width || item.width}
            height={imageDimensions?.[item.filename]?.height || item.height}
            url={getImageUrl(projectId, [item.filename], splitType, resourceId)}
            annotation={scaleTooltipAnnotation(item)}
          >
            <DataViewerListItemThumbnail
              url={getImageThumbnailUrl(
                filenames,
                splitType,
                resourceId,
                LIST_THUMB_SIZE
              )}
              pos={pos}
              width={LIST_THUMB_SIZE}
              height={LIST_THUMB_SIZE}
            />
          </DataViewerTooltip>
        );
      };

      return listData?.files ? (
        <ListViewer
          columns={columns}
          items={listData.files.map(file => ({
            ...file,
            height: imageDimensions?.[file.filename]?.height || file.height,
            width: imageDimensions?.[file.filename]?.width || file.width,
            size: imageDimensions?.[file.filename]?.size || file.size,
          }))}
          onSort={id => id !== 'image' && toggleSortHeading(id)}
          projectId={projectId}
          sort={sort}
          sortDir={sortDir}
        >
          {(item, pos) => (
            <>
              <ListViewerItem>
                <ListViewerTooltipThumbnail pos={pos} />
              </ListViewerItem>
              <ListViewerFilename />
              <ListViewerFilesize />
              <ListViewerDimensions />
              <ListViewerLabel label={labelNameMapper(item.label!)} />
            </>
          )}
        </ListViewer>
      ) : (
        <GridSkeleton
          tileHeight={128}
          tileWidth={128}
          tileMarginX={14}
          tileMarginY={14}
          minTiles={itemCount}
        />
      );
    }
  };

  return (
    <div className="data-viewer2">
      <BackButton />
      <div className="data-viewer2__content">
        <DataViewerSummary
          pending={false}
          name={
            rawImagesOnly
              ? projectResponse?.body.dataSet?.name
              : distributionData?.body.datasetName
          }
          date={summaryData?.creationDate}
          numberOfClasses={getNumberOfClasses(distributionData)}
          fileCount={rawImagesOnly ? annotationData?.images.length : totalFileCount}
          sizeOfDataset={summaryData?.size}
          filesByLabel={getLabelCounts(distributionData).map(({label, count}) => ({
            label: labelNameMapper(label),
            count,
          }))}
          onBarClick={setLabel}
          purposeOfDataset={projectResponse?.body.dataSet?.purpose}
        />
        <div className="data-viewer2__header">
          {!rawImagesOnly && <SplitTabs />}
          <div className="data-viewer2__controls">
            <>
              {!rawImagesOnly && (
                <LabelSearch
                  labelField={labelField}
                  onChange={e => setLabelField(e.target.value)}
                  onEnter={() => setLabel(labelField.trim())}
                />
              )}
              <PageOptions page={page} pageCount={pageCount} onChange={setPage} />
              <SortOptions sort={sort} onSort={setSort} />
              <SortDirToggle sortDir={sortDir} onToggle={toggleSortDir} />
              <ViewToggle view={view} onToggle={toggleView} />
            </>
          </div>
        </div>
        <AllLabelsButton visible={label.length > 0} onClick={() => setLabel('')} />
        <LabelSelected visible={label.length > 0} label={labelNameMapper(label)} />

        <div className="data-viewer2__viewer">{renderViewer()}</div>

        {pageCount > 1 && (
          <PageNumbers page={page} pageCount={pageCount} onChange={setPage} />
        )}
      </div>
    </div>
  );
};

const AllLabelsButton = (props: {onClick: ButtonProps['onClick']; visible: boolean}) =>
  props.visible ? (
    <Button className="data-viewer2__all-labels" onClick={props.onClick}>
      <ArrowLeftIcon /> <FormattedMessage id="dataViewer.allLabels" />
    </Button>
  ) : null;

const BackButton = () => {
  const history = useHistory();
  return (
    <Button
      variant="contained"
      className="data-viewer2__back-button"
      size="large"
      onClick={history.goBack}
    >
      <FormattedMessage id="dataViewer.returnToStage" />
    </Button>
  );
};

const LabelSearch = (props: {
  labelField: string;
  onChange: TextFieldProps['onChange'];
  onEnter(): void;
}) => {
  const intl = useIntl();
  const handleKeyUp: TextFieldProps['onKeyUp'] = e => {
    if (e.key === 'Enter') {
      props.onEnter();
    }
  };

  return (
    <div className="data-viewer2__search">
      <StudioSearch
        placeholder={intl.formatMessage({id: 'dataViewer.search'})}
        onChange={props.onChange}
        onKeyUp={handleKeyUp}
        value={props.labelField}
      />
    </div>
  );
};

const LabelSelected = (props: {label: string; visible: boolean}) =>
  props.visible ? (
    <div className="data-viewer2__label">
      <Search className="data-viewer2__search-icon" />{' '}
      <span className="data-viewer2__current-label">
        <FormattedMessage id="dataViewer.filesLabeled" /> {props.label}
      </span>
    </div>
  ) : null;

const PageNumbers = (props: {
  onChange(page: number): void;
  page: number;
  pageCount: number;
}) => (
  <div className="data-viewer2__pagination-container">
    <Pagination
      count={props.pageCount}
      page={props.page}
      shape="rounded"
      className="data-viewer2__pagination"
      onChange={(_, p) => props.onChange(p)}
    />
  </div>
);

const PageOptions = (props: {
  onChange(page: number): void;
  page: number;
  pageCount: number;
}) => {
  const options = getPageOptions(props.pageCount);

  return (
    <StudioSelect
      options={options}
      order={Object.keys(options)}
      SelectProps={{
        value: props.page,
        onChange: (event: Parameters<NonNullable<SelectProps['onChange']>>[0]) => {
          props.onChange(Number(event.target.value as string));
        },
      }}
    />
  );
};

const SortDirToggle = (props: {
  onToggle: IconButtonProps['onClick'];
  sortDir: SortDir;
}) => (
  <IconButton onClick={props.onToggle} className="data-viewer2__icon-button">
    {props.sortDir === 'asc' ? <ArrowUpwardRounded /> : <ArrowDownwardRounded />}
  </IconButton>
);

const ViewToggle = (props: {onToggle: IconButtonProps['onClick']; view: ViewId}) => (
  <IconButton onClick={props.onToggle} className="data-viewer2__icon-button">
    {props.view === 'grid' ? <ViewListRounded /> : <ViewComfyRounded />}
  </IconButton>
);

type SortId = DatasetListRequest['sort'];
type SortDir = DatasetListRequest['sortDir'];
type ViewId = 'grid' | 'list';

type ReducerState = {
  label: string;
  labelField: string;
  page: number;
  sort: SortId;
  sortDir: SortDir;
  splitType?: DataSplitType;
  view: ViewId;
};

const initialState: ReducerState = {
  label: '',
  labelField: '',
  page: 1,
  sort: 'label',
  sortDir: 'asc',
  splitType: 'TRAIN',
  view: 'grid',
};

const reverseSort = (sortDir: SortDir) => (sortDir === 'asc' ? 'desc' : 'asc');

const viewerSlice = createSlice({
  name: 'dataset/viewer',
  initialState,
  reducers: {
    setLabel(state, action: PayloadAction<string>) {
      state.label = action.payload;
      state.labelField = action.payload;
      state.page = 1;
    },
    setLabelField(state, action: PayloadAction<string>) {
      state.labelField = action.payload;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSort(state, action: PayloadAction<SortId>) {
      state.sort = action.payload;
      state.page = 1;
    },
    setSplitType(state, action: PayloadAction<DataSplitType | undefined>) {
      state.splitType = action.payload;
      state.page = 1;
    },
    toggleSortDir(state) {
      state.sortDir = reverseSort(state.sortDir);
      state.page = 1;
    },
    toggleSortHeading(state, action: PayloadAction<SortId>) {
      if (action.payload === state.sort) {
        state.sortDir = reverseSort(state.sortDir);
      } else {
        state.sort = action.payload;
      }
      state.page = 1;
    },
    toggleView(state) {
      state.view = state.view === 'grid' ? 'list' : 'grid';
      if (state.sort === 'label') {
        state.page = 1;
      }
    },
  },
});

const getLabelData = (
  annotationData: DatasetGetAnnotationsResponse | undefined,
  query: DatasetLabelsRequest
) => {
  const labels = chain(getLabelFiles(annotationData))
    .groupBy(file => file.label)
    .map((files, label) => ({
      label,
      count: files.length,
      files,
    }))
    .orderBy(file => file.label, [query.sortDir])
    .value();

  const response: DatasetLabelsResponse = {
    count: labels.length,
    labels: labels.slice(query.startFrom, query.startFrom + query.pageSize),
  };

  return response;
};

const getListData = (
  annotationData: DatasetGetAnnotationsResponse | undefined,
  query: DatasetListRequest,
  isLabelView: boolean = false
) => {
  const files = isLabelView ? getLabelFiles(annotationData) : getFiles(annotationData);

  const filesFound =
    query.label.length > 0
      ? files.filter(f => f.label?.toLowerCase() === query.label.toLowerCase())
      : files;

  const response: DatasetListResponse = {
    count: filesFound.length,
    files: orderBy(filesFound, file => file[query.sort], [query.sortDir]).slice(
      query.startFrom,
      query.startFrom + query.pageSize
    ),
  };

  return response;
};

type Annotation = DatasetGetAnnotationsResponse['annotations'][number];
const getAnnotationMap = (annotationData: DatasetGetAnnotationsResponse) => {
  const annotationMap = new Map<number, Annotation[]>();
  for (const annotation of annotationData.annotations) {
    if (annotationMap.has(annotation.image_id)) {
      annotationMap.set(annotation.image_id, [
        ...(annotationMap.get(annotation.image_id) as Annotation[]),
        annotation,
      ]);
    } else {
      annotationMap.set(annotation.image_id, [annotation]);
    }
  }
  return annotationMap;
};

type Category = DatasetGetAnnotationsResponse['categories'][number];
const getCategoryMap = (annotationData: DatasetGetAnnotationsResponse) => {
  const categoryMap = new Map<number, Category>();
  for (const category of annotationData.categories) {
    categoryMap.set(category.id, category);
  }
  return categoryMap;
};

type File = DatasetListResponse['files'][number] & {
  image?: DatasetGetAnnotationsResponse['images'][number];
  annotation?: AnnotationType[];
};

const packageFile = (
  annotations: AnnotationType[],
  image: ImageType,
  category?: string
) => {
  return {
    filename: image.file_name,
    id: image.id,
    label: category,
    lastEdited: image.date_captured,
    size: image.size || 0,
    height: image.height || 0,
    width: image.width || 0,
    image,
    annotation: annotations,
  };
};

const getFiles = (annotationData?: DatasetGetAnnotationsResponse) => {
  if (!annotationData) {
    return [];
  }

  const annotationMap = getAnnotationMap(annotationData);
  const categoryMap = getCategoryMap(annotationData);

  const files: File[] = [];
  for (const image of annotationData.images) {
    const annotation = annotationMap.get(image.id) || [];

    const catId = annotation[0]?.category_id;
    const category = catId != null ? categoryMap.get(catId) : null;

    const annotations = annotation?.map(item => ({
      ...item,
      label: categoryMap.get(item.category_id as number)?.name as string,
      bbox: item.bbox,
    }));

    files.push(packageFile(annotations, image, category?.name));
  }

  return files;
};

const getLabelFiles = (annotationData?: DatasetGetAnnotationsResponse) => {
  if (!annotationData) {
    return [];
  }
  const annotationMap = getAnnotationMap(annotationData);
  const categoryMap = getCategoryMap(annotationData);

  const files: File[] = [];
  for (const image of annotationData.images) {
    const annotation = annotationMap.get(image.id);
    if (!annotation) {
      continue;
    }

    const labels = uniq(annotation.map(item => item.category_id));
    labels.forEach(category_id => {
      const category = category_id != null && categoryMap.get(category_id);
      if (category) {
        const annotations = annotation
          .filter(item => item.category_id === category_id)
          .map(item => ({
            ...item,
            label: categoryMap.get(item.category_id as number)?.name as string,
            bbox: item.bbox,
          }));
        files.push(packageFile(annotations, image, category.name));
      }
    });
  }

  return files;
};

const scaleAnnotation = (
  annotations: DatasetGetAnnotationsResponse['annotations'] | undefined,
  from: {
    width: number;
    height: number;
  },
  to: {
    width: number;
    height: number;
  }
) => {
  return annotations?.map(annotation => {
    // Get Scale Factors
    const scaleX = to.width / from.width;
    const scaleY = to.height / from.height;
    const scaledData = {...annotation};
    if (annotation.bbox) {
      const {bbox} = annotation;
      scaledData.bbox = [
        (bbox[0] || 0) * scaleX,
        bbox[1] * scaleY,
        bbox[2] * scaleX,
        bbox[3] * scaleY,
      ];
    } else {
      scaledData.bbox = undefined;
    }
    return scaledData;
  });
};

const getDistributions = (distributionData?: DatasetDistributionResponse) => {
  return [
    distributionData?.body.trainDistribution,
    distributionData?.body.validationDistribution,
    distributionData?.body.testDistribution,
    distributionData?.body.unsplitDistribution,
    distributionData?.body.unknownDistribution,
  ].filter((distribution): distribution is DatasetDistribution => Boolean(distribution));
};

const getLabelCounts = (distributionData?: DatasetDistributionResponse) => {
  const labelCounts: {[label: string]: number} = {};

  for (const distribution of getDistributions(distributionData)) {
    for (const [label, count] of Object.entries(distribution.annotationCounts)) {
      if (labelCounts[label]) {
        labelCounts[label] += count;
      } else {
        labelCounts[label] = count;
      }
    }
  }

  return Object.entries(labelCounts)
    .sort()
    .map(([label, count]) => ({label, count}));
};

const getImageCount = (distributionData?: DatasetDistributionResponse) => {
  const arr = getDistributions(distributionData);
  return arr.length
    ? getDistributions(distributionData)
        .map(distribution => distribution?.imagesCount || 0)
        .reduce((x, y) => x + y)
    : 0;
};

const getFileCount = (distributionData?: DatasetDistributionResponse) => {
  let sum = 0;

  const imageCount: number = getImageCount(distributionData);
  if (imageCount > 0) {
    return imageCount;
  }

  for (const {count} of getLabelCounts(distributionData)) {
    sum += count;
  }

  return sum;
};

const getNumberOfClasses = (distributionData?: DatasetDistributionResponse) => {
  return Math.max(
    0,
    ...getDistributions(distributionData).map(
      distribution => distribution.labelNames.length
    )
  );
};

const useDataViewer = () => {
  const [state, dispatch] = React.useReducer(viewerSlice.reducer, initialState);
  return {
    ...state,
    ...bindActionCreators(viewerSlice.actions, dispatch as Dispatch),
  };
};

const getPageOptions = (pageCount: number) =>
  fromPairs(range(1, pageCount + 1).map(n => [n, `Page ${n}`]));

DataViewer.propTypes = {
  projectId: PropTypes.string.isRequired,
};
