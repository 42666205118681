import axios from 'axios';

const getURL = (projectId: string, postProcessorType: string) =>
  `/studio/postprocessor/import/cancel/${projectId}/${postProcessorType}`;

export const cancelPostProcessorImport = async (
  projectId: string,
  postProcessorType: string
) => {
  await axios.get(getURL(projectId, postProcessorType));
};
