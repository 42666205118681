import React, {Component} from 'react';
import PropTypes from 'prop-types';

class MoreIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="4px"
          height="15px"
          viewBox="0 0 4 15"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <path
              d="M13.1523438,8.7109375 C12.8398438,9.02343906 12.4557314,9.1796875 12,9.1796875 C11.5442686,9.1796875 11.1536475,9.01692871 10.828125,8.69140625 C10.5026025,8.36588379 10.3398438,7.96875234 10.3398438,7.5 C10.3398438,7.03124766 10.5026025,6.63411621 10.828125,6.30859375 C11.1536475,5.98307129 11.5442686,5.8203125 12,5.8203125 C12.4557314,5.8203125 12.8463525,5.98307129 13.171875,6.30859375 C13.4973975,6.63411621 13.6601562,7.03124766 13.6601562,7.5 C13.6601562,7.96875234 13.4908871,8.37239414 13.1523438,8.7109375 Z M10.8085938,11.328125 C11.1471371,10.9895816 11.5442686,10.8203125 12,10.8203125 C12.4557314,10.8203125 12.8463525,10.9830713 13.171875,11.3085938 C13.4973975,11.6341162 13.6601562,12.0312477 13.6601562,12.5 C13.6601562,12.9687523 13.4973975,13.3658838 13.171875,13.6914062 C12.8463525,14.0169287 12.4557314,14.1796875 12,14.1796875 C11.5442686,14.1796875 11.1536475,14.0169287 10.828125,13.6914062 C10.5026025,13.3658838 10.3398438,12.9687523 10.3398438,12.5 C10.3398438,12.0312477 10.4960922,11.6406266 10.8085938,11.328125 Z M10.8085938,16.328125 C11.1471371,15.9895816 11.5442686,15.8203125 12,15.8203125 C12.4557314,15.8203125 12.8463525,15.9830713 13.171875,16.3085938 C13.4973975,16.6341162 13.6601562,17.0312477 13.6601562,17.5 C13.6601562,17.9687523 13.4973975,18.3658838 13.171875,18.6914062 C12.8463525,19.0169287 12.4557314,19.1796875 12,19.1796875 C11.5442686,19.1796875 11.1536475,19.0169287 10.828125,18.6914062 C10.5026025,18.3658838 10.3398438,17.9687523 10.3398438,17.5 C10.3398438,17.0312477 10.4960922,16.6406266 10.8085938,16.328125 Z"
              id="moreIcon"
            ></path>
          </defs>
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-854.000000, -4459.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 3663.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-more-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#moreIcon"></use>
                      </mask>
                      <use id="more" fill="currentColor" xlinkHref="#moreIcon"></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

MoreIcon.propTypes = {
  className: PropTypes.string,
};

export default MoreIcon;
