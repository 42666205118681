import {Typography} from '@material-ui/core';
import React, {useEffect, useRef} from 'react';
import cn from 'classnames';
import {TASK_STATUS} from '../useDeploy';
import './VideoStream.scss';

export type VideoStreamStatus =
  | 'idle'
  | 'inProgress'
  | 'ready'
  | 'connected'
  | 'complete'
  | 'error';

export type VideoStreamMetadataProps = {
  width: number;
  height: number;
  clientWidth: number;
  clientHeight: number;
  duration: number;
};
type VideoStreamProps = {
  status: VideoStreamStatus;
  receiveOnly: boolean;
  userStream: MediaStream | null;
  remoteStream: MediaStream | null;
  className?: string;
  canIPlayVideo?: boolean;
  onLoadedMetadata?: (args: VideoStreamMetadataProps) => void;
};

export type VideoConnectionStatus = {
  connectSent: boolean;
};

export const VideoStream = ({
  status,
  receiveOnly,
  userStream,
  remoteStream,
  className,
  canIPlayVideo = true,
  onLoadedMetadata = () => {},
}: VideoStreamProps) => {
  const userVideoRef = useRef<HTMLVideoElement>(null);
  const remoteVideoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (userVideoRef.current) {
      userVideoRef.current.srcObject = canIPlayVideo ? userStream : null;
    }
  }, [canIPlayVideo, userStream]);

  useEffect(() => {
    if (remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = canIPlayVideo ? remoteStream : null;
    }
  }, [canIPlayVideo, remoteStream]);

  const getStatus = () => {
    if (status === TASK_STATUS.ERROR) {
      return <Typography>Error occured. Please try again.</Typography>;
    } else if (status === TASK_STATUS.READY) {
      return <Typography>Waiting...</Typography>;
    } else if (status === TASK_STATUS.COMPLETE) {
      return <Typography>Stream complete.</Typography>;
    }
  };

  const onLoadedMetadataLocal = (e: any) => {
    const video = remoteVideoRef.current;
    if (video) {
      onLoadedMetadata({
        width: video.videoWidth,
        height: video.videoHeight,
        clientWidth: video.clientWidth,
        clientHeight: video.clientHeight,
        duration: video.duration,
      });
    }
  };

  return (
    <div className={cn('video-stream', className)}>
      <div className="video-stream__body">
        <div className="video-stream__status">{getStatus()}</div>
        <div className="video-stream__video-container">
          <video
            className="video-stream__remote"
            ref={remoteVideoRef}
            autoPlay
            // Safari blocks autoplay videos that are not muted by default
            muted
            playsInline
            onLoadedMetadata={onLoadedMetadataLocal}
          ></video>
          {!receiveOnly && (
            <video
              className="video-stream__user"
              ref={userVideoRef}
              autoPlay
              // Safari blocks autoplay videos that are not muted by default
              muted
              playsInline
            />
          )}
        </div>
      </div>
    </div>
  );
};
