import React, {Component} from 'react';
import PropTypes from 'prop-types';

class LockedIcon extends Component {
  render() {
    return (
      <div className={this.props.className} data-testid={this.props['data-testid']}>
        <svg
          width="16px"
          height="20px"
          viewBox="0 0 16 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <path
              d="M17.5214844,9.09765625 C18.008466,9.09765625 18.4309878,9.27669092 18.7890625,9.63476562 C19.1471372,9.99284033 19.3261719,10.4153621 19.3261719,10.9023438 L19.3261719,20.0976562 C19.3261719,20.5846379 19.1471372,21.0071597 18.7890625,21.3652344 C18.4309878,21.7233091 18.008466,21.9023438 17.5214844,21.9023438 L6.52148438,21.9023438 C6.0058568,21.9023438 5.56901221,21.7233091 5.2109375,21.3652344 C4.85286279,21.0071597 4.67382812,20.5846379 4.67382812,20.0976562 L4.67382812,10.9023438 C4.67382812,10.4153621 4.85286279,9.99284033 5.2109375,9.63476562 C5.56901221,9.27669092 6.0058568,9.09765625 6.52148438,9.09765625 L7.42382812,9.09765625 L7.42382812,7.25 C7.42382812,5.98957703 7.87499549,4.90820764 8.77734375,4.00585938 C9.67969201,3.10351111 10.7610614,2.65234375 12.0214844,2.65234375 C13.2819073,2.65234375 14.3561154,3.10351111 15.2441406,4.00585938 C16.1321659,4.90820764 16.5761719,5.98957703 16.5761719,7.25 L16.5761719,9.09765625 L17.5214844,9.09765625 Z M13.9980469,5.2734375 C13.4537733,4.70051797 12.7877644,4.4140625 12,4.4140625 C11.2122356,4.4140625 10.5462267,4.69335658 10.0019531,5.25195312 C9.45767957,5.81054967 9.18554688,6.47655863 9.18554688,7.25 L9.27148438,7.25 L9.27148438,9.09765625 L14.8574219,9.09765625 L14.8574219,7.25 C14.8574219,6.47655863 14.5709664,5.81771105 13.9980469,5.2734375 Z M17.5214844,20.0976562 L17.5214844,10.9023438 L6.52148438,10.9023438 L6.52148438,20.0976562 L17.5214844,20.0976562 Z M13.2675781,16.8320312 C12.9238264,17.175783 12.5013046,17.3476562 12,17.3476562 C11.4986954,17.3476562 11.0690122,17.1686216 10.7109375,16.8105469 C10.3528628,16.4524722 10.1738281,16.0156276 10.1738281,15.5 C10.1738281,14.9843724 10.3528628,14.5475278 10.7109375,14.1894531 C11.0690122,13.8313784 11.4986954,13.6523438 12,13.6523438 C12.5013046,13.6523438 12.9309878,13.8313784 13.2890625,14.1894531 C13.6471372,14.5475278 13.8261719,14.9843724 13.8261719,15.5 C13.8261719,16.0156276 13.6399758,16.4596336 13.2675781,16.8320312 Z"
              id="lockedIcon"
            ></path>
          </defs>
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-848.000000, -7916.000000)"
            >
              <g id="Stage-Icons" transform="translate(56.000000, 7273.000000)">
                <g id="row" transform="translate(0.000000, 603.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="stage-/-large-/-locked"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#lockedIcon"></use>
                      </mask>
                      <use id="locked" fill="currentColor" xlinkHref="#lockedIcon"></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

LockedIcon.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
};

export default LockedIcon;
