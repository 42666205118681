import React from 'react';
import {PropTypes} from 'prop-types';
import {useField} from 'formik';
import {useDynamicFormField} from '../DynamicFormToFormikAdapter';
import {StudioCheckbox as Checkbox} from '../../../base-components/StudioCheckbox';

export const DynamicCheckbox = ({
  label,
  className,
  name,
  required,
  disabled,
  tooltip,
}) => {
  useDynamicFormField(name);
  const [field, meta, helpers] = useField({name, type: 'checkbox'});
  const {value} = meta;
  const {setValue} = helpers;
  const checked = value === 'true';
  const onChange = () => setValue(value === 'true' ? 'false' : 'true');

  const checkBoxProps = {...field, id: name, checked, onChange};
  return (
    <Checkbox
      className={className}
      label={label}
      required={required}
      disabled={disabled}
      tooltip={tooltip}
      fixedTooltip
      {...checkBoxProps}
    />
  );
};

DynamicCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

DynamicCheckbox.defaultProps = {
  className: null,
  required: false,
  disabled: false,
};
