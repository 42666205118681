import React from 'react';
import {ConnectedRouter} from 'connected-react-router';
import {MuiThemeProvider, createTheme} from '@material-ui/core';
import {StylesProvider} from '@material-ui/core/styles';
import {IntlProvider} from 'react-intl';
import {Provider as ReduxProvider} from 'react-redux';
import THEME from '../../config/theme';
import {history, store} from '../../store';
import {AuthProvider} from '../AuthPage';
import jss from './JSSStyleInsertionPoint';
import messages from '../en.json';
import {StudioModeProvider} from '../StudioMode/StudioModeProvider';

const muiTheme = createTheme(THEME);
const styleNode = document.createComment('jss-insertion-point');
document.head.insertBefore(styleNode, document.head.firstChild);

export const ProviderWrapper = ({children}) => (
  <IntlProvider locale={navigator.language} messages={messages}>
    <ReduxProvider store={store}>
      <ConnectedRouter history={history}>
        <AuthProvider>
          <StudioModeProvider>
            <StylesProvider jss={jss}>
              <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
            </StylesProvider>
          </StudioModeProvider>
        </AuthProvider>
      </ConnectedRouter>
    </ReduxProvider>
  </IntlProvider>
);
