import React, {useContext, useEffect, useState} from 'react';
// TODO: enable original Rete package from below when this PR is merged https://github.com/retejs/rete/pull/473
// Otherwise temp Rete with fixes is used.
// import Rete from 'rete';
import Rete from 'rete-tomgutz';
import {cloneDeep} from 'lodash';
import ReactRenderPlugin from 'rete-react-render-plugin';
import ConnectionPlugin from 'rete-connection-plugin';
import ISPComponent from './ISPComponent';
import {ISPNode} from './ISPNode';
import {ISPFlowgraphContext} from './ISPFlowgraphProvider';
import {StageContext} from '../Provider';
import {
  getEditorMetadata,
  getFlowgraphNodeArr,
  getIspModules,
  hasNoBranches,
} from '../util';
import {ISPFormContext} from '../ISPForm';
import HistoryPlugin from 'rete-history-plugin';
import {ERROR_MESSAGES} from '../messages';
import {renderPath, zoom} from './overrides';
import ContextMenu from './ContextMenu';
import {toast} from '../../../base-components/StudioToast';
import './Editor.scss';

const MODULE_SPACING = 125;
const MODULE_WIDTH = 300;
const MODULE_START = 100;
const EDITOR_CENTER = window.innerWidth;

export const Editor = ({template, data, isNew}) => {
  const [editorObj, setEditorObj] = useState(null);
  const [componentsReady, setComponentsReady] = useState(false);
  let editorContainer = null;
  const {
    setHasError: setHasFlowgraphError,
    setModules,
    rawModules,
    setRawModules,
    addModule,
    setAddModule,
    clear,
    setErrorMessage,
    setSelectedModule,
    availableModules,
    setOrigEditorData,
  } = useContext(ISPFlowgraphContext);
  const {setHasError} = useContext(ISPFormContext);
  const {setEditor} = useContext(StageContext);

  useEffect(() => {
    createEditor(editorContainer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (template && editorObj) {
      const modules = getIspModules(template.transformations);
      createFlowComponents(editorObj, modules);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorObj, template]);

  useEffect(() => {
    isNew && editorObj && editorObj.clear();
  }, [isNew, editorObj]);

  useEffect(() => {
    editorObj && availableModules && editorObj.trigger('process', {availableModules});
  }, [availableModules, editorObj]);

  useEffect(() => {
    if (addModule) {
      setRawModules([...rawModules, addModule]);
      addNode(addModule);
      // reset node staging after it has been added to the editor.
      setAddModule(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addModule]);

  useEffect(() => {
    return () => {
      editorObj && editorObj.trigger('process', {clear: true});
    };
  }, [editorObj]);

  // Load data
  useEffect(() => {
    if (data && componentsReady) {
      clear();
      editorObj.clear();
      const editorMeta = getEditorMetadata(data);
      if (editorMeta) {
        const sortedModuleArr = getFlowgraphNodeArr(Object.values(editorMeta.nodes));
        setRawModules(sortedModuleArr);
        sortedModuleArr.forEach(async m => {
          const newComponent = new ISPComponent(formatModule(m));
          const newNode = await newComponent.createNode();
          const prevNode =
            editorObj.nodes.length > 0
              ? editorObj.nodes[editorObj.nodes.length - 1]
              : null;

          newNode.position = [...editorMeta.nodes[m.uiMeta.id].position];
          editorObj.addNode(newNode);
          if (prevNode) {
            const prevOut = prevNode.outputs.get('out-0');
            const newIn = newNode.inputs.get('in-0');
            editorObj.connect(prevOut, newIn);
          }
        });
      }
      setTimeout(() => {
        setOrigEditorData(cloneDeep(editorObj.toJSON()));
      }, 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, componentsReady]);

  function formatModule(module) {
    return {
      ...module,
      setHasError,
    };
  }

  async function addNode(node) {
    const newComponent = new ISPComponent(formatModule(node));
    const newNode = await newComponent.createNode();
    const prevNode =
      editorObj.nodes.length > 0 ? editorObj.nodes[editorObj.nodes.length - 1] : null;

    newNode.position = [
      prevNode ? prevNode.position[0] : EDITOR_CENTER / 2 - MODULE_WIDTH / 2,
      prevNode ? prevNode.position[1] + MODULE_SPACING : MODULE_START,
    ];

    editorObj.addNode(newNode);

    // Connect when last "out" socket is the same as new "in" socket
    if (prevNode) {
      const prevOut = prevNode.outputs.get('out-0');
      const newIn = newNode.inputs.get('in-0');
      prevOut.module_io_type === newIn.module_io_type &&
        editorObj.connect(prevOut, newIn);
    }
  }

  function createFlowComponents(editor, ispModules) {
    ispModules.forEach(node => editor.register(new ISPComponent(formatModule(node))));
    setComponentsReady(true);
  }

  function refreshStatus(editor) {
    const rawData = editor.toJSON();
    const nodes = Object.values(rawData.nodes);
    const sortedNodes = getFlowgraphNodeArr(nodes);

    const isValid = validate(sortedNodes, nodes);
    setHasFlowgraphError(!isValid);
    setModules(sortedNodes);
  }

  function onNodeRemoved(editor) {
    setSelectedModule(null);
    refreshStatus(editor);
  }

  function validatePortConnection({output, input}) {
    if (output.module_io_type !== input.module_io_type) {
      toast.error({
        duration: 15000,
        subtitle: ERROR_MESSAGES.CONNECT_MODULE(
          input.module_io_type,
          output.module_io_type
        ),
      });
    }
  }

  function validate(modules, rawModules) {
    const nodeCount = rawModules.length;
    let isValid = true;
    // Should have at least one node if it is existing graph
    if (!isNew && nodeCount === 0) {
      isValid = false;
      setErrorMessage(ERROR_MESSAGES.AT_LEAST_ONE_MODULE);
    }
    // Should have one flow only, no isolated, no parallel flows
    if (isValid) {
      isValid = modules.length === nodeCount;
      setErrorMessage(isValid ? null : ERROR_MESSAGES.PARALLEL_GRAPH_NOT_SUPPORTED);
    }
    // Should not have a loop
    if (isValid) {
      try {
        const fNode = modules[0];
        const lNode = modules[modules.length - 1];
        isValid = fNode.uiMeta.inId !== lNode.uiMeta.id;
        setErrorMessage(isValid ? null : ERROR_MESSAGES.LOOP_GRAPH_NOT_SUPPORTED);
      } catch (err) {
        isValid = true;
      }
    }
    // check if there are branches in the graph
    if (isValid && modules.length > 1) {
      isValid = hasNoBranches(modules);
      setErrorMessage(isValid ? null : ERROR_MESSAGES.BRANCH_GRAPH_NOT_SUPPORTED);
    }
    // Check if any of the modules are qualified vs the Color Space selections
    // https://blaizeinc.atlassian.net/browse/STUDCL-5118

    return isValid;
  }

  function createEditor(container) {
    let editor = new Rete.NodeEditor('demo@0.1.0', container);

    // PLUGIN INIT
    editor.use(ReactRenderPlugin, {
      component: ISPNode,
      data: {
        setHasError,
      },
    });

    editor.use(ConnectionPlugin);
    editor.use(HistoryPlugin, {keyboard: true});

    // OVERRIDES
    editor.on('connectionpath', renderPath);
    editor.on('zoom', zoom);

    // EVENTS
    editor.on('nodecreated', () => refreshStatus(editor));
    editor.on('noderemoved', () => onNodeRemoved(editor));
    editor.on('connectioncreated', () => refreshStatus(editor));
    editor.on('connectionremoved', () => refreshStatus(editor));
    editor.on('nodeselected', setSelectedModule);
    editor.on('click', () => {
      const selectedNodes = [...editor.selected.list];
      // Clear selected nodes
      editor.selected.clear();
      selectedNodes.forEach(n => n.update());
      setSelectedModule(null);
    });
    editor.on('connectioncreate', validatePortConnection);

    setEditorObj(editor);
    setEditor(editor);
  }

  return (
    <>
      <div
        className="flow-editor"
        data-testid="flow-graph-editor"
        id="flow-graph-editor"
        ref={ref => (editorContainer = ref)}
      ></div>
      <ContextMenu ispModules={availableModules} />
    </>
  );
};
