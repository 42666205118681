import React from 'react';
import cn from 'classnames';
import {InputAdornment, TextFieldProps} from '@material-ui/core';
import {Search as SearchIcon} from '@material-ui/icons';
import {StudioTextField} from '../StudioTextField';
import './StudioSearch.scss';

export type StudioSearchProps = TextFieldProps;

export const StudioSearch = ({className, ...props}: StudioSearchProps) => {
  return (
    <StudioTextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      className={cn('studio-search', className)}
      type="search"
      {...props}
    />
  );
};
