import React, {useEffect, useContext, useState} from 'react';
import {ISPFormProvider} from './ISPForm';
import {Editor} from './Editor';
import FormButton from '../../base-components/StudioButton/FormButton';
import {ISPFormContext} from './ISPForm/ISPFormProvider';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {isEqual} from 'lodash';
import {
  ispFetch,
  getIspData,
  ispUpdate,
  getIspIsNew,
  getIspIsPending,
  getIspTemplate,
  ispGetTemplate,
} from '../../store/isp';
import {viewCloseEditISP, viewOpenEditISP} from '../../store/view';
import {ModuleList} from './ModuleList';
import {getIspModules, convertReteToBlazeISPTransformation} from './util';
import {toast} from '../../base-components/StudioToast';
import ERROR from '../../config/error';
import {StageProvider, StageContext} from './Provider';
import {ISPFlowgraphProvider, ISPFlowgraphContext} from './Editor/ISPFlowgraphProvider';
import {Paper, ButtonGroup, Snackbar} from '@material-ui/core';
// TODO: Activate once redux part for history management is implemented
// import UndoIcon from '@material-ui/icons/Undo';
// import RedoIcon from '@material-ui/icons/Redo';
// import ClearIcon from '@material-ui/icons/Clear';
import {useLocation, matchPath} from 'react-router';
import {PrivateRoutes} from '../../config/routes';
import {ModifySPFormDrawer} from '../ISPForm';
import Waiting from '../../base-components/StudioWaiting/Waiting';
import {Alert} from '@material-ui/lab';
import Help from './Help/Help';
import {get, isEmpty} from 'lodash';
import {MODULE_FILTER_MAP} from './util';
import {useIntl} from 'react-intl';
import StudioRouteLeavingGuardProps from '../../base-components/StudioRouteLeavingGuardProps/StudioRouteLeavingGuardProps';
import './FlowGraph.scss';

export const FlowGraphBase = ({
  data,
  isNew,
  dispatchFetchIsp,
  dispatchUpdateIsp,
  dispatchViewOpenEditISP,
  dispatchViewCloseEditISP,
  dispatchGetIspTemplate,
  history,
  isPending,
  template,
}) => {
  const intl = useIntl();
  const {hasError: hasFormError} = useContext(ISPFormContext);
  const {
    hasError: hasFlowgraphError,
    modules,
    setAddModule,
    errorMessage,
    setMemData,
    memData,
    setAvailableModules,
    origEditorData,
    isDirty: isFormDirty,
  } = useContext(ISPFlowgraphContext);
  const {editor} = useContext(StageContext);
  const [ispModules, setIspModules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [ispId, setIspId] = useState(null);
  const [safeRedirect, setSafeRedirect] = useState(false);

  const onSubmit = () => {
    if (isNew && !modules.length) {
      setSafeRedirect(true);
    } else {
      const tData = convertReteToBlazeISPTransformation(
        template,
        modules,
        editor.toJSON()
      );
      const name = memData?.name;
      const ispData = {
        ...template,
        name,
        format: 'flowgraph',
        projectId,
        id: ispId,
        transformations: {
          ...(memData && memData.transformations),
          libraries: [tData],
        },
      };
      if (name) {
        setIsLoading(true);
        dispatchUpdateIsp(ispData)
          .then(res => {
            if (res && !res.errors) {
              toast.success({
                title: 'Success!',
                subtitle: `Successfully saved ${name}`,
                position: 'top',
              });
              setSafeRedirect(true);
            }
          })
          .finally(() => setIsLoading(false));
      } else {
        toast.warn({title: ERROR.ISP_NAME_REQUIRED(), position: 'top'});
        dispatchViewOpenEditISP();
      }
    }
  };

  const isDirty = location => {
    const currentData = editor.toJSON();
    return !isEqual(currentData, origEditorData) || isFormDirty;
  };
  const {pathname} = useLocation();

  // Get Selected ISP data
  useEffect(() => {
    if (projectId && ispId && template && !isLoading && !isNew) {
      dispatchFetchIsp(ispId, projectId).then(({payload}) => {
        if (!isEmpty(payload.errors)) {
          setSafeRedirect(true);
        } else {
          !isEmpty(payload.body) && setMemData(payload.body);
        }
      });
    }
  }, [ispId, projectId, dispatchFetchIsp, setMemData, template, isLoading, isNew]);

  // Alert user before refresh
  useEffect(() => {
    const warnUser = event => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', warnUser);
    return () => {
      window.removeEventListener('beforeunload', warnUser);
      dispatchViewCloseEditISP();
    };
  }, [dispatchViewCloseEditISP]);

  useEffect(() => {
    if (safeRedirect) {
      history.push(`/project/${projectId}/upload`);
      setSafeRedirect(false);
    }
  }, [safeRedirect, projectId, history]);

  useEffect(() => {
    if (pathname) {
      const params = matchPath(pathname, PrivateRoutes.FLOWGRAPH).params;
      setIspId(params.ispId);
      setProjectId(params.projectId);
    }
  }, [pathname]);

  // Get ISP template and modules
  useEffect(() => {
    if (!template) {
      setIsLoading(true);
      dispatchGetIspTemplate &&
        projectId &&
        dispatchGetIspTemplate({projectId}).finally(() => setIsLoading(false));
    }
  }, [projectId, template, dispatchGetIspTemplate]);

  useEffect(() => {
    setCanSave(!(isNew && !modules.length));
  }, [isNew, modules]);

  // Available modules to use for the Editor
  useEffect(() => {
    const input = get(memData, 'transformations.input');
    if (template && input && setAvailableModules) {
      const filteredModules = getIspModules(template.transformations).filter(
        module =>
          module.module_enabled &&
          MODULE_FILTER_MAP[input.input_color_space].includes(module.module_category_code)
      );
      setIspModules(filteredModules);
      setAvailableModules(filteredModules);
    }
  }, [memData, template, setAvailableModules]);

  // TODO: Activate once redux part for history management is implemented
  // const onUndo = () => editor.trigger('undo');
  // const onRedo = () => editor.trigger('redo');
  // const onClear = () => editor.clear();
  return (
    <div id="flow-graph" className="flow-graph stage-space" data-testid="flow-graph">
      <StudioRouteLeavingGuardProps
        when={!safeRedirect}
        navigate={path => history.push(path)}
        shouldBlockNavigation={isDirty}
        title={intl.formatMessage({id: 'general.title.nosave'})}
        message={intl.formatMessage({id: 'isp.unsavedChanges'})}
        confirmLabel={intl.formatMessage({id: 'form.confirm'})}
      />
      <FormButton
        buttonRole="secondary"
        value={intl.formatMessage({
          id: canSave ? 'isp.saveAndReturn' : 'isp.return',
        })}
        customClass="modal-window__return-btn flow-graph__exit_button"
        onClick={onSubmit}
        disabled={hasFormError || hasFlowgraphError || isPending}
      />
      <Help />
      <Paper elevation={3} className="flow-graph__action_buttons">
        <ButtonGroup
          size="large"
          variant="text"
          color="default"
          aria-label="text primary button group"
        >
          {/* TODO: Activate once redux part for history management is implemented */}
          {/* <Button startIcon={<UndoIcon />} onClick={onUndo}>
            Undo
          </Button>
          <Button startIcon={<RedoIcon />} onClick={onRedo}>
            Redo
          </Button>
          <Button startIcon={<ClearIcon />} onClick={onClear}>
            Clear
          </Button> */}
        </ButtonGroup>
      </Paper>
      <ModuleList
        ispModules={ispModules}
        handler={setAddModule}
        loading={!ispModules && isLoading}
      />
      <Editor template={template} data={data} isNew={isNew} />
      <ModifySPFormDrawer data={data} projectId={projectId} />
      {isPending && (
        <Waiting
          diameter={40}
          loadingText={'Please wait...'}
          className="flow-graph__loading"
        />
      )}
      <Snackbar open={!!errorMessage} className="Flo">
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = state => ({
  data: getIspData(state),
  template: getIspTemplate(state),
  isNew: getIspIsNew(state),
  isPending: getIspIsPending(state),
});

const mapDispatchToProps = {
  dispatchFetchIsp: ispFetch,
  dispatchUpdateIsp: ispUpdate,
  dispatchViewOpenEditISP: viewOpenEditISP,
  dispatchViewCloseEditISP: viewCloseEditISP,
  dispatchGetIspTemplate: ispGetTemplate,
};
const FlowGraphWrapped = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(FlowGraphBase);

export const FlowGraph = () => (
  <StageProvider>
    <ISPFlowgraphProvider>
      <ISPFormProvider>
        <FlowGraphWrapped />
      </ISPFormProvider>
    </ISPFlowgraphProvider>
  </StageProvider>
);
