import {AxiosRequestConfig} from 'axios';
import {LocalImportRequest} from '../../types/importing/LocalImportRequest';
import {request} from '../request';

export type InferenceDataUploadRequest = {
  files: File[];
  projectId: string;
};

export type InferenceDataUploadResponse = LocalImportRequest;

export async function postInferenceDataUpload(
  {files, projectId}: InferenceDataUploadRequest,
  config: AxiosRequestConfig
) {
  const data = new FormData();
  files.forEach((file: File) => data.append('file', file));
  data.append('projectId', projectId);
  return request<InferenceDataUploadResponse>({
    method: 'post',
    url: '/dataset/import/inference/filesystem',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    ...config,
  });
}
