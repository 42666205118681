import PropTypes from 'prop-types';
import StatisticsInfoProps from './statisticsInfoPropTypes';
import {QuestionProps} from './assistantPropTypes';

const TaskInfoProps = PropTypes.shape({
  type: PropTypes.string.isRequired,
  percentCompleted: PropTypes.number.isRequired,
  statusMessage: PropTypes.string.isRequired,
  log: PropTypes.string,
  jobManagerTaskId: PropTypes.string,
  gspBoxId: PropTypes.string,
  deploymentId: PropTypes.string,
  prompts: PropTypes.arrayOf(QuestionProps),
  warnings: PropTypes.arrayOf(PropTypes.string).isRequired,
  statistics: StatisticsInfoProps,
  defaults: PropTypes.object.isRequired,
  failed: PropTypes.bool,
});

export default TaskInfoProps;
