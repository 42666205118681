import {useEffect, useRef, useState} from 'react';
import {PreProcessorScript} from '../../../types/scripts/PreProcessorScript';

type usePreProcessingScriptProps = {
  projectId?: string;
  id?: string;
  hasPreProcessor?: boolean;
  fileName?: string;
  onError?: (message?: string) => void;
  viewOnly?: boolean;
};

export const usePreProcessingScript = ({
  projectId,
  id,
  hasPreProcessor,
  fileName,
  onError,
  viewOnly = false,
}: usePreProcessingScriptProps) => {
  const [module, setModule] = useState<PreProcessorScript>();
  const onErrorRef = useRef(onError);

  useEffect(() => {
    const fetchPreProcessor = async () => {
      try {
        const module = await import(
          /* webpackIgnore: true */ `/studio/preprocessor/${
            viewOnly ? 'view' : 'edit'
          }/${projectId}/${id}/${fileName}`
        );
        setModule(module);
      } catch (e) {
        onErrorRef.current?.('Error fetching pre processor.');
        console.error(e);
      }
    };
    if (projectId && id && hasPreProcessor) {
      fetchPreProcessor();
    }
  }, [projectId, id, hasPreProcessor, fileName, viewOnly]);

  return module;
};
