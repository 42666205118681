import {Button, Card} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router';
import {ConfirmDialog} from '../../../base-components/StudioConfirmDialog';
import {RootState, useAppDispatch} from '../../../store';
import {
  deleteDeployment,
  deleteInferenceApp,
  fetchInferenceApps,
} from '../../../store/prodDeployments/dashboard';
import {Metadata, MetadataList} from '../MetadataList/MetadataList';
import {toast} from '../../../base-components/StudioToast';
import {Link} from 'react-router-dom';
import {useStudioMode} from '../../StudioMode/StudioModeProvider';
import './InferenceDetails.scss';
import {
  DeploymentPostProcessingParameters,
  PreProcessingParameters,
} from '../../TestDeployView/DeployConfigPanel/TestDeployParameters';
import {InferenceAppDetails} from '../InferenceAppDetails/InferenceAppDetails';
import {InferenceApplicationInfo} from '../../../types/deployment/InferenceApplicationsResponse';
import {InferenceApplicationsRequest} from '../../../types/deployment/InferenceApplicationsRequest';

export const InferenceDetails = () => {
  const {mode} = useStudioMode();
  const isLiteMode = mode === 'LITE';
  const {projectId, applicationId, deploymentId} = useParams<{
    projectId: string;
    applicationId: string;
    deploymentId: string | undefined;
  }>();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();
  const userId = useSelector((state: RootState) => state.user.userId);
  const groupId = useSelector((state: RootState) => state.user.groupId);
  const inferenceApps: Array<InferenceApplicationInfo> = useSelector(
    (state: RootState) => state.deploymentDashboard.inferenceApps.data
  );
  const inferenceApplication = inferenceApps.find(app => app.id === applicationId);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    isOpen: boolean;
  }>({isOpen: false});
  const preProcessor =
    inferenceApplication?.transformation?.transformationType === 'SCRIPT'
      ? inferenceApplication.transformation
      : null;
  const modelPostProcessor = inferenceApplication?.postProcessors?.find(
    pp => pp.postProcessorType === 'MODEL'
  );
  const appPostProcessor = inferenceApplication?.postProcessors?.find(
    pp => pp.postProcessorType === 'APPLICATION'
  );

  useEffect(() => {
    if (applicationId && groupId) {
      const requestBody: InferenceApplicationsRequest = {
        filterOutNonInferenceApps: true,
        firstPage: 0,
        pageSize: 100,
        paginated: false,
        orderDefinitions: [],
        params: {},
        applicationId: applicationId,
        groupId,
      };

      dispatch(fetchInferenceApps(requestBody));
    }
  }, [projectId, applicationId, dispatch, groupId]);

  return inferenceApplication ? (
    <div className="inference-details" data-testid="inference-details">
      <div className="inference-details__menu">
        <Button
          className="prod-deploy-details__back"
          variant="contained"
          disableElevation
          onClick={() => history.goBack()}
        >
          <FormattedMessage id="form.returnToPreviousPage" />
        </Button>
        <div>
          <Button
            onClick={() => history.push(`/deployments?tab=inference`)}
            className="prod-deploy-details__back"
            variant="contained"
            disableElevation
            disabled={isLiteMode}
          >
            {intl.formatMessage({id: 'dashboard.viewAll'})}
          </Button>
          {!deploymentId && (
            <Button
              onClick={() => {
                if (deploymentId) {
                  history.push(
                    `/project/${projectId}/inference/${applicationId}/deployment/${deploymentId}/configure`
                  );
                } else {
                  history.push(`/project/${projectId}/inference/${applicationId}/create`);
                }
              }}
              color="primary"
              variant="contained"
              disableElevation
            >
              {intl.formatMessage({id: 'form.edit'})}
            </Button>
          )}
        </div>
      </div>
      <div className="inference-details__body">
        <div className="inference-details__info">
          <div className="inference-details__header">
            <h3>{inferenceApplication.name}</h3>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              onClick={() => setDeleteConfirmation({isOpen: true})}
              disabled={userId !== inferenceApplication?.ownerId}
            >
              {intl.formatMessage({id: 'form.delete'})}
            </Button>
          </div>
          <MetadataList>
            <Metadata label="prodDeployment.project">
              {inferenceApplication?.projectName}
            </Metadata>
            <Metadata label="prodDeployment.dataset">
              {inferenceApplication?.datasetName}
            </Metadata>
            <Metadata label="prodDeployment.model">
              {inferenceApplication?.modelName}
            </Metadata>
            <InferenceAppDetails
              deploymentId={deploymentId}
              applicationId={applicationId}
              projectId={projectId}
            />
            <Metadata label="prodDeployment.preProcessor">
              {preProcessor && (
                <Link
                  to={`/project/${projectId}/preprocessor/edit/${preProcessor.id}?applicationId=${applicationId}&returnTo=spaDetails&viewOnly`}
                >
                  {preProcessor.name}
                </Link>
              )}
            </Metadata>
            <Metadata label="prodDeployment.modelPostProcessor">
              {modelPostProcessor && (
                <Link
                  to={`/project/${projectId}/postprocessor/edit/${modelPostProcessor.id}?applicationId=${applicationId}&returnTo=spaDetails&viewOnly`}
                >
                  {modelPostProcessor.name}
                </Link>
              )}
            </Metadata>
            <Metadata label="prodDeployment.appPostProcessor">
              {appPostProcessor && (
                <>
                  <span hidden={userId !== inferenceApplication?.ownerId}>
                    <Link
                      to={`/project/${projectId}/postprocessor/edit/${appPostProcessor.id}?applicationId=${applicationId}&returnTo=spaDetails&viewOnly`}
                    >
                      {appPostProcessor.name}
                    </Link>
                  </span>
                  <span hidden={userId === inferenceApplication?.ownerId}>
                    {appPostProcessor.name}
                  </span>
                </>
              )}
            </Metadata>
            <Metadata label="prodDeployment.parameters">
              <PreProcessingParameters
                projectId={projectId}
                applicationId={applicationId}
                renderField={(field, param) => (
                  <div className="inference-details__param" key={param.name}>
                    {field}
                  </div>
                )}
                titleMessageId="test.form.preprocessing"
                wrapperClassName="inference-details__accordion"
                onValidityChange={() => {}}
                onChange={() => {}}
                disabledForm={true}
              />
              <DeploymentPostProcessingParameters
                projectId={projectId}
                applicationId={applicationId}
                renderField={(field, param) => (
                  <div className="inference-details__param" key={param.name}>
                    {field}
                  </div>
                )}
                titleMessageId="test.form.postprocessing"
                wrapperClassName="inference-details__accordion"
                onValidityChange={() => {}}
                onChange={() => {}}
                disabledForm={true}
              />
            </Metadata>
          </MetadataList>
        </div>
        <Card className="inference-details__results">
          <div id="inference-results-placeholder"></div>
        </Card>
      </div>
      {deploymentId ? (
        <ConfirmDialog
          type="confirm"
          title={intl.formatMessage({id: 'prodDeployment.deleteConfirmTitle'})}
          message={intl.formatMessage({id: 'prodDeployment.deleteConfirmMessage'})}
          submitLabel={intl.formatMessage({
            id: 'prodDeployment.deleteConfirmSubmitLabel',
          })}
          open={deleteConfirmation.isOpen}
          onClose={() => setDeleteConfirmation({isOpen: false})}
          onOk={async () => {
            await dispatch(deleteDeployment(deploymentId));
            setDeleteConfirmation({isOpen: false});
            toast.success(`Successfully deleted deployment`);
            history.push(`/deployments?tab=inference`);
          }}
        />
      ) : (
        <ConfirmDialog
          type="confirm"
          title={intl.formatMessage({id: 'prodDeployment.deleteAppConfirmTitle'})}
          message={intl.formatMessage({id: 'prodDeployment.deleteAppConfirmMessage'})}
          submitLabel={intl.formatMessage({
            id: 'prodDeployment.deleteConfirmSubmitLabel',
          })}
          open={deleteConfirmation.isOpen}
          onClose={() => setDeleteConfirmation({isOpen: false})}
          onOk={async () => {
            await dispatch(
              deleteInferenceApp({
                projectId: projectId,
                id: applicationId,
              })
            );
            setDeleteConfirmation({isOpen: false});
            toast.success(`Successfully deleted application`);
            history.push(`/deployments?tab=inference`);
          }}
        />
      )}
    </div>
  ) : null;
};
