import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router';
import {
  AuthPageBody,
  AuthPageContainer,
  AuthPageFooter,
  AuthPageHeader,
  AuthPageSecondaryButton,
  AuthPageSummary,
} from '../AuthPage';

export function ActivationEmailSent() {
  const history = useHistory();

  return (
    <AuthPageContainer>
      <AuthPageHeader>
        <FormattedMessage id="auth.activationSent" />
      </AuthPageHeader>
      <AuthPageBody>
        <AuthPageSummary>
          <FormattedMessage id="auth.activationSentSummary" />
        </AuthPageSummary>
      </AuthPageBody>
      <AuthPageFooter>
        <AuthPageSecondaryButton
          value="Return to Log In"
          onClick={() => history.push('/login')}
        />
      </AuthPageFooter>
    </AuthPageContainer>
  );
}
