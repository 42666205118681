import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ArrowUpIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="10px"
          height="15px"
          viewBox="0 0 10 15"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <polygon
              id="ArrowUpIcon"
              points="11.1796875 20 11.1796875 8.203125 8.171875 11.171875 7 10 12 5 17 10 15.828125 11.171875 12.8203125 8.203125 12.8203125 20"
            ></polygon>
          </defs>
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-851.000000, -1501.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 705.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-arrow-up-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#ArrowUpIcon"></use>
                      </mask>
                      <use
                        id="arrow-up"
                        fill="currentColor"
                        xlinkHref="#ArrowUpIcon"
                      ></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

ArrowUpIcon.propTypes = {
  className: PropTypes.string,
};

export default ArrowUpIcon;
