import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import URL from '../../../config/url';
import Util from '../../../util';
import StageAnimation from '../StageAnimation/StageAnimation';
import {StageProps} from '../../../model/stagePropTypes';
import {toast} from '../../../base-components/StudioToast';
import {StageAssistantForm} from '../../StageAssistantForm';
import {connect} from 'react-redux';
import {addListener, removeListener} from '../../../store/statusChecker';
import {
  viewSetProject,
  viewDisableTermination,
  viewEnableTermination,
} from '../../../store/view';

class FinetuneAction extends Component {
  constructor(props) {
    super(props);

    this.stageAnimation = new StageAnimation();
    this.update = this.update.bind(this);
    this.initiateFinetuning = this.initiateFinetuning.bind(this);
    this.handleInitiationResponse = this.handleInitiationResponse.bind(this);
    this.finishFinetuning = this.finishFinetuning.bind(this);
    this.cancelFinetuning = this.cancelFinetuning.bind(this);
    this.showAssistant = this.showAssistant.bind(this);
    this.getQuestionsForm = this.getQuestionsForm.bind(this);
    this.clarifyModelFinetuning = this.clarifyModelFinetuning.bind(this);
    this.handleFinetuningResponse = this.handleFinetuningResponse.bind(this);
    this.terminateFinetuning = this.terminateFinetuning.bind(this);
    this.finalizeFinetuning = this.finalizeFinetuning.bind(this);
    this.monitorStatus = this.monitorStatus.bind(this);
    this.stopMonitoringStatus = this.stopMonitoringStatus.bind(this);
    this.displayFinetuningInProgress = this.displayFinetuningInProgress.bind(this);
  }

  render() {
    return null;
  }

  componentDidMount() {
    this.update(this.props.stage);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.stage.progress && this.props.stage.progress) {
      this.update(this.props.stage);
    }
  }

  componentWillUnmount() {
    this.props.removeStatusListener('FINETUNE_MODEL');
    this.props.viewSetProject({disableModelOptimizationStage: false});
  }

  update(stage) {
    if (stage.status === 'FINISHED' && stage.statistics) {
      this.props.updateStatistics(stage.statistics, '', 100);
    } else if (stage.progress) {
      if (stage.progress.prompts && stage.progress.prompts.length) {
        this.stageAnimation.shiftStages('FINETUNE_MODEL');
        this.showAssistant(stage.progress);
      } else {
        this.monitorStatus();
      }
    }
  }

  initiateFinetuning(caAnswers) {
    if (Array.isArray(caAnswers)) {
      this.clarifyModelFinetuning(null, caAnswers);
    } else {
      axios
        .get(URL.MODEL_FINETUNE, {
          params: {
            projectId: this.props.projectId,
          },
        })
        .then(response => this.handleInitiationResponse(response.data))
        .catch(error => toast.error(`Fine-tuning initiation error: ${error.message}`));
    }
  }

  handleInitiationResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.finishFinetuning();
    } else {
      this.showAssistant(response);
    }
  }

  finishFinetuning() {
    if (this.props.onFinish) {
      this.props.onFinish('CREATE_DEPLOYMENT');
    }
  }

  cancelFinetuning() {
    if (this.props.onFinish) {
      this.props.onFinish('FINETUNE_MODEL');
    }
  }

  showAssistant(response) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.display(
        this.getQuestionsForm(response.prompts, response.defaults),
        'FINETUNE_MODEL',
        null,
        this.terminateFinetuning
      );
    }
  }

  getQuestionsForm(questions, defaults) {
    return (
      <StageAssistantForm
        prompts={questions}
        defaults={defaults}
        onSubmit={this.clarifyModelFinetuning}
        onCancel={this.terminateFinetuning}
        title="Select fine-tuning methods"
        notifyType="FINETUNE_MODEL"
      />
    );
  }

  clarifyModelFinetuning(answersArray, caAnswers) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.hideStage('FINETUNE_MODEL');
    }
    const clarificationRequest = {
      projectId: this.props.projectId,
      answers: answersArray,
      caAnswers,
    };

    axios
      .post(URL.MODEL_FINETUNE_CLARIFY, clarificationRequest, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(response => this.handleFinetuningResponse(response.data))
      .catch(error => toast.error(`model fine-tuning error: ${error.message}`));
  }

  handleFinetuningResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.cancelFinetuning();
    } else if (!response.body && response.warnings && response.warnings.length > 0) {
      this.cancelFinetuning();
    } else if (response.prompts) {
      this.showAssistant(response);
    } else {
      this.monitorStatus();
    }
  }

  terminateFinetuning() {
    this.props.viewDisableTermination();
    return axios
      .get(URL.OPERATION_CANCEL('FINETUNE_MODEL'), {
        params: {projectId: this.props.projectId},
      })
      .then(() => {
        if (this.props.assistantPanel.current) {
          this.props.assistantPanel.current.hideStage('FINETUNE_MODEL');
        }
        this.stopMonitoringStatus();
        this.props.viewSetProject({disableModelOptimizationStage: false});
        this.cancelFinetuning();
      })
      .catch(error => toast.error(`cancel fine-tuning error: ${error.message}`));
  }

  finalizeFinetuning() {
    this.props.viewDisableTermination();
    this.stopMonitoringStatus();

    axios
      .get(URL.MODEL_FINETUNE_FINALIZE, {
        params: {
          projectId: this.props.projectId,
        },
      })
      .then(res => {
        if (res.data.body && !res.data.errors) {
          toast.success({
            title: 'Success',
            subtitle: `${res.data.body.name} successfully fine-tuned`,
            position: 'top',
          });
        }
        if (this.props.onFinish) {
          this.props.onFinish('FINETUNE_MODEL');
        }
        this.props.viewSetProject({disableModelOptimizationStage: false});
      })
      .catch(error => {
        toast.error(`Fine-tuning finalization error: ${error.message}`);
      });
  }

  monitorStatus() {
    this.props.viewEnableTermination();
    this.stageAnimation.markInProgress('FINETUNE_MODEL');
    const name = document.getElementById('finetune-panel-name');
    name.innerText = `Fine-tuning Model`;
    this.props.addStatusListener('FINETUNE_MODEL', this.displayFinetuningInProgress);
    this.props.viewSetProject({disableModelOptimizationStage: true});
  }

  stopMonitoringStatus() {
    this.props.removeStatusListener('FINETUNE_MODEL');
  }

  displayFinetuningInProgress(status) {
    const message = status.statusMessage;
    const detailSentence = message && message.length > 0 ? message + '. ' : '';
    this.props.updateStatistics(
      status.statistics,
      detailSentence,
      status.percentCompleted
    );

    if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
      this.finalizeFinetuning();
    }
  }
}

FinetuneAction.propTypes = {
  projectId: PropTypes.string.isRequired,
  stage: StageProps,
  updateStatistics: PropTypes.func,
  onFinish: PropTypes.func,
};

const mapDispatchToProps = {
  addStatusListener: addListener,
  removeStatusListener: removeListener,
  viewSetProject,
  viewEnableTermination,
  viewDisableTermination,
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(
  FinetuneAction
);
