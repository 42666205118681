import React, {useEffect, useRef} from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import validationSchema from './ValidationSchema';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {
  projectCreate,
  getIsProjectPending,
  getProjectPendingStatus,
} from '../../store/project';
import {
  viewCloseProjectForm,
  getIsNewProjectFormOpen,
  getViewProjectForm,
} from '../../store/view';
import Waiting from '../../base-components/StudioWaiting/Waiting';
import {FormattedMessage, useIntl} from 'react-intl';
import {useStudioMode} from '../StudioMode/StudioModeProvider';
import {StudioTextField} from './../../base-components/StudioTextField';
import {PROJECT_SETTINGS} from './../../config/constants';
import './ProjectForm.scss';

function ProjectForm({
  open,
  onClose,
  isPending,
  onSubmit,
  dataset,
  model,
  isp,
  pp,
  solution,
  status,
}) {
  const intl = useIntl();
  const {mode} = useStudioMode();
  const isLiteMode = mode === 'LITE';
  const resetAfterSubmit = useRef(null);
  const prevIsPending = useRef(isPending);
  useEffect(() => {
    if (open && prevIsPending.current === true && !isPending) {
      resetAfterSubmit.current?.();
    }
    prevIsPending.current = isPending;
  }, [isPending, onClose, open]);

  useEffect(() => {
    if (isLiteMode && open && solution) {
      resetAfterSubmit.current = () => {
        onClose();
      };
      onSubmit({
        description: solution.description,
        name: solution.displayName,
        solution,
        mode: 'LITE',
      });
    }
  }, [isLiteMode, open, solution, onSubmit, onClose]);

  return (
    <div>
      <Formik
        validateOnChange={true}
        initialValues={{
          name: solution?.displayName,
          description: solution?.description,
        }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (data, {resetForm}) => {
          resetAfterSubmit.current = () => {
            resetForm();
            onClose();
          };
          onSubmit({
            ...validationSchema.cast(data),
            solution,
            dataset,
            model,
            isp,
            pp,
          });
        }}
      >
        {({
          dirty,
          errors,
          handleChange,
          values: {name, description},
          handleBlur,
          handleSubmit,
          handleReset,
        }) => (
          <React.Fragment>
            <Dialog
              open={open}
              onClose={() => {
                handleReset();
                onClose();
              }}
              aria-labelledby="form-dialog-title"
              maxWidth="sm"
              fullWidth
              className="project-form-container"
              data-testid="create-project-form"
              id="create-project-form"
            >
              <DialogContent className="content">
                <Box component="div" m={3}>
                  <form onSubmit={handleSubmit} noValidate autoComplete="off">
                    <StudioTextField
                      inputProps={{maxLength: PROJECT_SETTINGS.name.maxLength}}
                      placeholder={intl.formatMessage({
                        id: 'projectPanel.namePlaceholder',
                      })}
                      name="name"
                      autoFocus
                      margin="normal"
                      id="name"
                      label={intl.formatMessage({
                        id: isLiteMode
                          ? 'studioLite.solutionForm.name'
                          : 'projectPanel.nameLabel',
                      })}
                      required
                      fullWidth
                      className="name-field"
                      disabled={isPending || isLiteMode}
                      data-testid="create-project-page-name"
                      useFieldProps={false}
                      value={name}
                      strategy="formik"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <StudioTextField
                      inputProps={{
                        className: PROJECT_SETTINGS.description.className,
                        maxLength: PROJECT_SETTINGS.description.maxLength,
                      }}
                      placeholder={intl.formatMessage({
                        id: 'projectPanel.descriptionPlaceholder',
                      })}
                      name="description"
                      margin="normal"
                      id="description"
                      label={intl.formatMessage({id: 'projectPanel.descriptionLabel'})}
                      fullWidth
                      multiline={PROJECT_SETTINGS.description.multiline}
                      variant="filled"
                      disabled={isPending || isLiteMode}
                      data-testid="create-project-page-description"
                      useFieldProps={false}
                      value={description}
                      strategy="formik"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </form>
                  {isPending && (
                    <Waiting
                      diameter={40}
                      loadingText={status || intl.formatMessage({id: 'project.loading'})}
                      className="project-form-container__loading"
                    />
                  )}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleReset();
                    onClose();
                  }}
                  color="primary"
                  size="large"
                  variant="outlined"
                >
                  <FormattedMessage id="form.cancel" />
                </Button>
                {!isLiteMode && (
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    size="large"
                    variant="contained"
                    type="submit"
                    disabled={isPending || !!Object.keys(errors).length}
                    data-testid="create-project-page-submit"
                    id="create-project-page-submit"
                  >
                    <FormattedMessage id="form.create" />
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </React.Fragment>
        )}
      </Formik>
    </div>
  );
}

ProjectForm.propTypes = {
  open: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  templateId: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  status: PropTypes.string,
};

ProjectForm.defaultProps = {
  templateId: null,
  onSubmit: () => {},
  onClose: () => {},
  status: null,
};

const mapStateToProps = state => ({
  open: getIsNewProjectFormOpen(state),
  dataset: getViewProjectForm(state).dataset,
  model: getViewProjectForm(state).model,
  isp: getViewProjectForm(state).isp,
  pp: getViewProjectForm(state).pp,
  solution: getViewProjectForm(state).solution,
  isPending: getIsProjectPending(state),
  status: getProjectPendingStatus(state),
});

const mapDispatchToProps = {
  onSubmit: projectCreate,
  onClose: viewCloseProjectForm,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ProjectForm);
