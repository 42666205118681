import {Button, Dialog} from '@material-ui/core';
import React, {useState} from 'react';
import Editor from '@monaco-editor/react';
import {DropArea} from '../../../base-components/StudioDropArea';
import cn from 'classnames';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {StudioPopover} from '../../../base-components/StudioPopover';
import {defaultPostProcessor} from '../../InferenceApp/defaultPostProcessor';
import {FileExplorer} from '../../TestDeployView/FileExplorer';
import './ScriptUploadField.scss';

type ScriptUploadFieldProps = {
  label: string;
  tooltip: string;
  filePaths: File[];
  className?: string;
  disabled?: boolean;
  onChange: (files: File[]) => void;
  errorText?: string;
  allowDefault?: boolean;
  useDefault?: boolean;
};

export const ScriptUploadField = ({
  label,
  tooltip,
  filePaths,
  className,
  disabled,
  allowDefault,
  useDefault,
  onChange,
  errorText,
}: ScriptUploadFieldProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [fileName] = useState('');
  const [fileContent] = useState('');

  if (useDefault && filePaths.length === 0) {
    onChange([new File([defaultPostProcessor], 'defaultPostProcessor.js')]);
  }

  return (
    <div className={cn('script-upload-field', className)}>
      <label>
        {label} <StudioPopover infoMessage={tooltip} />
      </label>
      {!useDefault && (
        <DropArea
          message={`Drag and drop files`}
          onChange={(newFilePaths: File[]) => {
            onChange([...filePaths, ...newFilePaths]);
          }}
          className="script-upload-field__drop-area"
          disabled={disabled}
        />
      )}
      <FileExplorer
        files={filePaths}
        onDelete={(item: File, _index: number) => {
          onChange(filePaths.filter(file => file.name !== item.name));
        }}
        onClear={() => {
          onChange([]);
        }}
        icon="delete"
        size="small"
      />
      {allowDefault && !useDefault && (
        <Button
          color="primary"
          onClick={() => {
            onChange([new File([defaultPostProcessor], 'defaultPostProcessor.js')]);
          }}
          disabled={disabled}
        >
          Use Default
        </Button>
      )}
      <ScriptEditorDialog
        isOpen={isDialogOpen}
        fileName={fileName}
        fileContent={fileContent || ''}
        onClose={() => setIsDialogOpen(false)}
      />
      <div className="script-upload-field__error">{errorText}</div>
    </div>
  );
};

type ScriptEditorDialogProps = {
  fileName: string;
  fileContent: string;
  isOpen: boolean;
  onClose: () => void;
};

const ScriptEditorDialog = ({
  fileName,
  fileContent,
  isOpen,
  onClose,
}: ScriptEditorDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      BackdropProps={{
        invisible: true,
      }}
      onClose={onClose}
      className="script-editor-dialog"
    >
      <h2 className="script-editor-dialog__title">{fileName}</h2>
      <div className="script-editor-dialog__body">
        <Editor
          height="100%"
          width="100%"
          path={fileName}
          value={fileContent}
          options={{
            readOnly: true,
          }}
        />
      </div>
      <div className="script-editor-dialog__actions">
        <FormButton buttonRole="primary" value="Close" onClick={onClose} />
      </div>
    </Dialog>
  );
};
