import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {IconButton} from '@material-ui/core';
import {GetApp} from '@material-ui/icons';
import {StudioDrawer} from '../../base-components/StudioDrawer';
import {
  getModelDetails,
  getModelDetailsContext,
  getProject,
  isModelDetailsPanelOpen,
  toggleModelDetailsPanel,
} from '../../store/model';
import './ModelDetailsDialog.scss';

import {StudioTooltip} from '../../base-components/StudioTooltip';
import URL from '../../config/url';
import {transformMessageToIntlKey} from './../../utils/language';
class ModelDetailsDialog extends Component {
  constructor(props) {
    super(props);

    this.getModelDetails = this.getModelDetails.bind(this);
    this.filterByContext = this.filterByContext.bind(this);
  }

  componentWillUnmount() {
    if (this.props.isOpen) {
      this.props.togglePanel();
    }
  }

  filterByContext(groups) {
    const groupsByContext = {
      FLOATING_POINT: ['Model parameters', 'Framework parameters', 'Initial statistics'],
      COMPRESSED: [
        'Optimization parameters',
        'Floating point statistics',
        'Compressed statistics',
      ],
      FINETUNED: ['Fine-tuning parameters', 'Finetuned statistics'],
    };

    const contextGroups = groupsByContext[this.props.context];
    return contextGroups.reduce((acc, curr) => {
      if (groups[curr]) {
        acc.push([curr, groups[curr]]);
      }
      return acc;
    }, []);
  }

  getModelDetails() {
    if (!this.props.details || !this.props.details.groups) {
      return null;
    }
    const {groups} = this.props.details;
    const nameDetails = groups['Name'].properties;
    return (
      <div data-testid="model-details-panel">
        {nameDetails && (
          <section className="model-details-panel__section">
            <div className="model-details-panel__name">
              <h3>{nameDetails['Name']}</h3>
              {this.props.project.model?.onnxFile && (
                <StudioTooltip
                  title={this.props.intl.formatMessage({
                    id: 'project.model.download.onnx',
                  })}
                >
                  <IconButton
                    className="model-details-panel__download"
                    onClick={() =>
                      window.open(URL.MODEL_DOWNLOAD_ONNX(this.props.project.id), '_self')
                    }
                    data-testid="export-button"
                  >
                    <GetApp />
                  </IconButton>
                </StudioTooltip>
              )}
            </div>
            <p>{nameDetails['Description'] || 'No description'}</p>
          </section>
        )}
        {this.filterByContext(groups).map(([groupName, groupDetails]) => {
          const properties = Object.entries(groupDetails.properties);
          if (groupName !== 'Name' && properties.length) {
            return (
              <section className="model-details-panel__section">
                <h6>{groupName}</h6>
                <dl className="model-details-panel__parameter-list">
                  {properties.map(([propertyName, propertyValue]) => (
                    <>
                      <dt>
                        <span>
                          {this.props.intl.formatMessage({
                            id: transformMessageToIntlKey(propertyName, groupName),
                            defaultMessage: propertyName,
                          })}
                        </span>
                      </dt>
                      <dd>{propertyValue}</dd>
                    </>
                  ))}
                </dl>
              </section>
            );
          }
          return null;
        })}
      </div>
    );
  }

  render() {
    const {isOpen, togglePanel} = this.props;
    return (
      <>
        {isOpen && (
          <div
            className="model-details-panel__backdrop"
            onClick={() => togglePanel()}
            data-testid="model-details-panel-backdrop"
            id="model-details-panel-backdrop"
          />
        )}
        <StudioDrawer
          isOpen={isOpen}
          anchor="right"
          onClose={() => togglePanel()}
          className="model-details-panel"
        >
          {this.getModelDetails()}
        </StudioDrawer>
      </>
    );
  }
}

ModelDetailsDialog.propTypes = {
  description: PropTypes.string,
  id: PropTypes.string,
  projectId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  context: getModelDetailsContext(state),
  details: getModelDetails(state),
  isOpen: isModelDetailsPanelOpen(state),
  project: getProject(state),
});

const mapDispatchToProps = {
  togglePanel: toggleModelDetailsPanel,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(
    ModelDetailsDialog
  ),
  {forwardRef: true}
);
