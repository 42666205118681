import React, {useState} from 'react';
import filesize from 'filesize';
import cn from 'classnames';
import {getIcon} from './FileExplorerMap';
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  TableProps,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import {FormattedMessage, useIntl} from 'react-intl';
import {ConfirmDialog} from '../../../base-components/StudioConfirmDialog';
import {TextOverflow} from '../../../base-components/StudioTextOverflow';
import './FileExplorer.scss';

export type FileExplorerProps = {
  files: File[];
  size: TableProps['size'];
  onDelete?: (item: File, index: number) => void;
  onClear?: () => void;
  icon: string | null;
  className?: string;
};

export const STATUS = {
  SELECTION: 0,
  UPLOADING: 1,
  UPLOADED: 2,
};

export function FileExplorer({
  files = [],
  size,
  onDelete: propsOnDelete,
  onClear: propsOnClear,
  icon,
  className,
}: FileExplorerProps) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const intl = useIntl();
  const onDelete = (item: File, index: number) => {
    propsOnDelete && propsOnDelete(item, index);
  };

  const handleClear = () => {
    propsOnClear && propsOnClear();
  };

  const renderIcon = (icon: string | null, row: File, index: number) => {
    if (icon === 'check') {
      return <CheckIcon data-testid="file-check-icon" />;
    } else if (icon === 'delete') {
      return (
        <IconButton
          size="small"
          color="secondary"
          aria-label="delete"
          onClick={() => onDelete(row, index)}
          data-testid="file-delete-button"
        >
          <DeleteIcon />
        </IconButton>
      );
    }
    return null;
  };

  return (
    <>
      {files.length ? (
        <TableContainer
          className={cn('file-explorer', className)}
          data-testid="file-explorer"
        >
          <Table
            aria-label="File list"
            stickyHeader
            className="file-explorer__table"
            size={size}
          >
            <TableHead>
              <TableRow>
                <TableCell className="file-explorer__file-icon" />
                <TableCell align="left" className="file-explorer__file-name">
                  <FormattedMessage id="dataViewer.fileName" />
                </TableCell>
                <TableCell align="right" className="file-explorer__file-size">
                  <FormattedMessage id="dataViewer.fileSize" />
                </TableCell>
                <TableCell align="right" className="file-explorer__delete-icon">
                  {icon === 'delete' && (
                    <IconButton
                      size="small"
                      color="secondary"
                      aria-label="clear all"
                      onClick={() => setIsConfirmOpen(true)}
                      data-testid="file-clear-button"
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((row, i) => (
                <TableRow key={i} data-testid="file-explorer-row">
                  <TableCell className="file-explorer__file-icon">
                    {getIcon(row.type)}
                  </TableCell>
                  <TableCell className="file-explorer__file-name">
                    <TextOverflow text={row.name} />
                  </TableCell>
                  <TableCell
                    align="right"
                    className="file-explorer__file-size"
                    data-testid="file-size"
                  >
                    <TextOverflow text={filesize(row.size)} />
                  </TableCell>
                  <TableCell align="right" className="file-explorer__delete-icon">
                    {renderIcon(icon, row, i)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
      <ConfirmDialog
        type="confirm"
        title={intl.formatMessage({id: 'fileExplorer.confirmTitle'})}
        message={intl.formatMessage({id: 'fileExplorer.confirmMessage'})}
        submitLabel={intl.formatMessage({id: 'fileExplorer.confirmSubmitLabel'})}
        open={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        onOk={() => {
          handleClear();
          setIsConfirmOpen(false);
        }}
      />
    </>
  );
}
