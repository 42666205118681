import {isNil} from 'lodash';
import React, {ReactElement} from 'react';
import {FormattedMessage, IntlShape} from 'react-intl';
import HumanReadableTime from '../../../base-components/StudioHumanReadableTime/HumanReadableTime';
import {DeploymentDetailsInfo} from '../../../types/deployment/DeploymentDetailsInfo';
import {baseModelToString} from '../../../types/job/BaseModel';
import {dataTypeToString} from '../../../types/model/parameter/DataType';
import {optimizerToString} from '../../../types/model/parameter/Optimizer';
import {quantizationTypeToString} from '../../../types/model/parameter/QuantizationType';
import {Application} from '../../../types/project/Application';
import {Project} from '../../../types/project/Project';
import {getTestSource, getTestType} from '../DeployConfigPanel';
import {SplitChart} from '../SplitChart';
import {transformMessageToIntlKey} from './../../../utils/language';

export type DetailsBag = {
  application?: Application;
  project?: Project;
  test: DeploymentDetailsInfo | null;
  environmentVariables?: Record<string, string>;
  intl: IntlShape;
};

export type FieldConfig =
  | {
      id: string;
      render: (props: DetailsBag) => React.ReactNode;
      renderPlain?: (props: DetailsBag) => object | string | null | undefined;
    }
  | SectionConfig;

export type SectionConfig = {
  id: string;
  accordion: boolean;
  fields: Array<FieldConfig>;
};
export type TestDetailsConfig = Array<SectionConfig>;

var checkvalue = (val1: number | undefined, val2: number | undefined) => {
  if (val1) {
    return val1;
  } else {
    return val2;
  }
};

export const detailsConfig: TestDetailsConfig = [
  {
    id: 'testCompare.metadata',
    accordion: false,
    fields: [
      {
        id: 'testCompare.name',
        render: ({test}) => test?.name,
      },
      {
        id: 'testCompare.description',
        render: ({test}) => test?.deploymentDescription,
      },
      {
        id: 'testCompare.createdOn',
        render: ({test}) =>
          test?.startTime ? <HumanReadableTime date={test.startTime} /> : null,
        renderPlain: ({test}) => test?.startTime,
      },
      {
        id: 'testCompare.version',
        render: ({test}) => test?.version,
      },
      {
        id: 'testCompare.nodeName',
        render: ({test}) => test?.nodeName,
      },
      {
        id: 'testCompare.applicationName',
        render: ({test}) => test?.applicationName,
      },
      {
        id: 'testCompare.testType',
        render: ({test}) => test && getTestType(test),
      },
      {
        id: 'testCompare.testDataSource',
        render: ({test}) => test && getTestSource(test),
      },
    ],
  },
  {
    id: 'testCompare.dataset',
    accordion: true,
    fields: [
      {
        id: 'testCompare.datasetName',
        render: ({application, test}) => (
          <>
            {test?.dataSetDistribution?.datasetName ? (
              <>
                {test?.dataSetDistribution?.datasetName}
                <br />
                {application?.dataSet?.fromMarketplace && (
                  <div className="test-details__from-marketplace">
                    <FormattedMessage id="testCompare.originalMarketplaceDataset" />
                  </div>
                )}
              </>
            ) : null}
          </>
        ),
        renderPlain: ({test}) => test?.dataSetDistribution?.datasetName,
      },
      {
        id: 'testCompare.classCount',
        render: ({test}) =>
          test?.dataSetDistribution &&
          Math.max(
            test?.dataSetDistribution?.trainDistribution?.labelNames.length ?? 0,
            test?.dataSetDistribution?.testDistribution?.labelNames.length ?? 0,
            test?.dataSetDistribution?.validationDistribution?.labelNames.length ?? 0
          ),
      },
      {
        id: 'testCompare.imageCount',
        render: ({test}) =>
          test?.dataSetDistribution &&
          ((test?.dataSetDistribution?.trainDistribution?.imagesCount ?? 0) +
            (test?.dataSetDistribution?.testDistribution?.imagesCount ?? 0) +
            (test?.dataSetDistribution?.validationDistribution?.imagesCount ?? 0) ||
            test?.dataSetDistribution?.totalImageCount),
      },
      {
        id: 'testCompare.dataSplit',
        render: ({test}) => (
          <SplitChart
            training={test?.dataSetDistribution?.trainDistribution?.imagesCount ?? 0}
            validation={
              test?.dataSetDistribution?.validationDistribution?.imagesCount ?? 0
            }
            testing={test?.dataSetDistribution?.testDistribution?.imagesCount ?? 0}
          />
        ),
        renderPlain: ({test}) => ({
          training: test?.dataSetDistribution?.trainDistribution?.imagesCount ?? 0,
          validation: test?.dataSetDistribution?.validationDistribution?.imagesCount ?? 0,
          testing: test?.dataSetDistribution?.testDistribution?.imagesCount ?? 0,
        }),
      },
    ],
  },
  {
    id: 'testCompare.model',
    accordion: true,
    fields: [
      {
        id: 'testCompare.modelName',
        render: ({application, test}) =>
          test?.modelName ? (
            <>
              {test?.modelName}
              <br />
              {application?.model?.fromMarketplace && (
                <div className="test-details__from-marketplace">
                  <FormattedMessage id="testCompare.originalMarketplaceModel" />
                </div>
              )}
            </>
          ) : null,
        renderPlain: ({test}) => test?.modelName,
      },
      {
        id: 'testCompare.baseModel',
        render: ({application}) =>
          application?.model?.baseModel
            ? baseModelToString(application?.model?.baseModel)
            : null,
      },
      {
        id: 'testCompare.modelVariant',
        render: ({application}) =>
          application?.model?.yoloType ? (
            <FormattedMessage
              tagName="span"
              id={transformMessageToIntlKey(application.model.yoloType, 'modelVariant')}
            />
          ) : null,
      },
      {
        id: 'testCompare.transferLearning',
        render: ({application}) =>
          application?.model?.modelSource === 'TL_MODEL' ? 'Yes' : 'No',
      },
      {
        id: 'testCompare.finetuned',
        render: ({application}) =>
          application?.model?.fineTuned
            ? 'Yes'
            : application?.model?.fineTuned === false
            ? 'No'
            : null,
      },
      {
        id: 'testCompare.trainingParameters',
        accordion: true,
        fields: [
          {
            id: 'testCompare.epoch',
            render: ({application}) =>
              application?.model?.trainingParameters?.trainingEpochs,
          },
          {
            id: 'testCompare.learningRate',
            render: ({application, intl}) =>
              application?.model?.trainingParameters?.trainingLearningRate
                ? intl
                    .formatNumber(
                      application?.model?.trainingParameters?.trainingLearningRate,
                      {
                        notation: 'scientific',
                      }
                    )
                    ?.toLowerCase()
                : null,
          },
          {
            id: 'testCompare.optimizer',
            render: ({application}) =>
              application?.model?.trainingParameters?.trainingOptimizer
                ? optimizerToString(
                    application?.model?.trainingParameters?.trainingOptimizer
                  )
                : null,
          },
        ],
      },
      {
        id: 'testCompare.optimizationParameters',
        accordion: true,
        fields: [
          {
            id: 'testCompare.batchSize',
            render: ({test}) => test?.optimizationParameters?.batchSize,
          },
          {
            id: 'testCompare.bitness',
            render: ({test}) =>
              test?.optimizationParameters?.dataType
                ? dataTypeToString(test?.optimizationParameters?.dataType)
                : null,
          },
          {
            id: 'testCompare.method',
            render: ({test}) =>
              test?.optimizationParameters?.quantizationType
                ? quantizationTypeToString(test?.optimizationParameters?.quantizationType)
                : null,
          },
        ],
      },
      {
        id: 'testCompare.finetuningParameters',
        accordion: true,
        fields: [
          {
            id: 'testCompare.batchSize',
            render: ({test}) =>
              test?.finetuned ? test?.finetuningParameters?.batchSize : null,
          },
          {
            id: 'testCompare.bitness',
            render: ({test}) =>
              test?.finetuned && test?.finetuningParameters?.dataType
                ? dataTypeToString(test.finetuningParameters.dataType)
                : null,
          },
          {
            id: 'testCompare.epoch',
            render: ({test}) =>
              test?.finetuned ? test?.finetuningParameters?.epochCount : null,
          },
          {
            id: 'testCompare.learningRate',
            render: ({test, intl}) =>
              test?.finetuned && test?.finetuningParameters?.learningRate
                ? intl
                    .formatNumber(test.finetuningParameters.learningRate, {
                      notation: 'scientific',
                    })
                    ?.toLowerCase()
                : null,
          },
          {
            id: 'testCompare.optimizer',
            render: ({test}) =>
              test?.finetuned && test?.finetuningParameters?.optimizer
                ? optimizerToString(test.finetuningParameters.optimizer)
                : null,
          },
        ],
      },
      {
        id: 'testCompare.modelStatistics',
        accordion: true,
        fields: [
          {
            id: 'testCompare.floatingPointPrecision',
            render: ({application, intl}) =>
              application?.model?.modelPurpose !== 'Detection'
                ? percentageField(
                    checkvalue(
                      application?.model?.statistics?.FLOAT?.precision,
                      application?.model?.statistics?.FLOAT?.accuracy
                    ),
                    intl
                  )
                : null,
          },
          {
            id: 'testCompare.floatingPointMAP',
            render: ({application, intl}) =>
              application?.model?.modelPurpose === 'Detection'
                ? percentageField(
                    checkvalue(
                      application?.model?.statistics?.FLOAT?.precision,
                      application?.model?.statistics?.FLOAT?.accuracy
                    ),
                    intl
                  )
                : null,
          },
          {
            id: 'testCompare.compressedPrecision',
            render: ({application, intl}) =>
              application?.model?.modelPurpose !== 'Detection'
                ? percentageField(
                    checkvalue(
                      application?.model?.statistics?.COMPRESSED?.precision,
                      application?.model?.statistics?.COMPRESSED?.accuracy
                    ),
                    intl
                  )
                : null,
          },
          {
            id: 'testCompare.compressedMAP',
            render: ({application, intl}) =>
              application?.model?.modelPurpose === 'Detection'
                ? percentageField(
                    checkvalue(
                      application?.model?.statistics?.COMPRESSED?.precision,
                      application?.model?.statistics?.COMPRESSED?.accuracy
                    ),
                    intl
                  )
                : null,
          },
          {
            id: 'testCompare.finetunedPrecision',
            render: ({application, intl}) =>
              application?.model?.modelPurpose !== 'Detection'
                ? percentageField(
                    application?.model?.statistics?.FINETUNED?.precision,
                    intl
                  )
                : null,
          },
          {
            id: 'testCompare.finetunedMAP',
            render: ({application, intl}) =>
              application?.model?.modelPurpose === 'Detection'
                ? percentageField(
                    application?.model?.statistics?.FINETUNED?.precision,
                    intl
                  )
                : null,
          },
          {
            id: 'testCompare.recall',
            render: ({application, intl}) =>
              percentageField(application?.model?.statistics?.FLOAT?.recall, intl),
          },
          {
            id: 'testCompare.specificity',
            render: ({application, intl}) =>
              percentageField(application?.model?.statistics?.FLOAT?.specificity, intl),
          },
          {
            id: 'testCompare.f1Score',
            render: ({application, intl}) =>
              percentageField(application?.model?.statistics?.FLOAT?.f1Score, intl),
          },
        ],
      },
    ],
  },
  {
    id: 'testCompare.testStatistics',
    accordion: true,
    fields: [
      {
        id: 'testCompare.fps',
        render: ({test, intl}) => fixedPointField(test?.deploymentStatistics?.fps, intl),
      },
      {
        id: 'testCompare.latency',
        render: ({test, intl}) =>
          millisecondField(test?.deploymentStatistics?.latency, intl),
      },
      {
        id: 'testCompare.top1Accuracy',
        render: ({application, test, intl}) =>
          application?.model?.modelPurpose !== 'Detection'
            ? percentageField(test?.deploymentStatistics?.lastTop1Accuracy, intl)
            : null,
      },
      {
        id: 'testCompare.meanAveragePrecision',
        render: ({application, test, intl}) =>
          application?.model?.modelPurpose === 'Detection'
            ? percentageField(test?.deploymentStatistics?.lastTop1Accuracy, intl)
            : null,
      },
      {
        id: 'testCompare.precision',
        render: ({test, intl}) =>
          percentageField(test?.deploymentStatistics?.precision, intl),
      },
      {
        id: 'testCompare.recall',
        render: ({test, intl}) =>
          percentageField(test?.deploymentStatistics?.recall, intl),
      },
      {
        id: 'testCompare.specificity',
        render: ({test, intl}) =>
          percentageField(test?.deploymentStatistics?.specificity, intl),
      },
      {
        id: 'testCompare.f1Score',
        render: ({test, intl}) =>
          percentageField(test?.deploymentStatistics?.f1Score, intl),
      },
    ],
  },
  {
    id: 'testCompare.isp',
    accordion: true,
    fields: [
      {
        id: 'testCompare.ispName',
        render: ({application}) => application?.transformation?.name,
      },
      {
        id: 'testCompare.ispImageDimensions',
        render: ({application}) =>
          application?.transformation?.transformations
            ? `${application.transformation.transformations.input.input_width}x${application.transformation.transformations.input.input_height}`
            : null,
      },
      {
        id: 'testCompare.ispBitsPerPixel',
        render: ({application}) =>
          application?.transformation?.transformations?.input.bpp,
      },
      {
        id: 'testCompare.ispInputColorSpace',
        render: ({application}) =>
          application?.transformation?.transformations?.input.input_color_space,
      },
      {
        id: 'testCompare.ispOutputColorSpace',
        render: ({application}) =>
          application?.transformation?.transformations?.input.output_color_space,
      },
      {
        id: 'testCompare.ispModules',
        render: ({application}) =>
          application?.transformation?.transformations ? (
            <div className="test-details__modules">
              {application.transformation.transformations.libraries.flatMap(library =>
                library.modules.map(module =>
                  module.module_parameters
                    .filter(parameter => !parameter.invisible)
                    .map((parameter, k) => (
                      <div key={k} className="test-details__module-row">
                        {k === 0 && (
                          <div className="test-details__module-name">
                            {module.module_display_name}
                          </div>
                        )}
                        <div>
                          <FormattedMessage
                            id="testCompare.ispModuleParameterListing"
                            values={{
                              name: parameter.param_display_name,
                              value: parameter.param_value,
                            }}
                          />
                        </div>
                      </div>
                    ))
                )
              )}
            </div>
          ) : null,
        renderPlain: ({application}) =>
          application?.transformation
            ? application.transformation.transformations?.libraries.flatMap(library =>
                library.modules.map(module => ({
                  name: module.module_display_name,
                  parameters: module.module_parameters
                    .filter(parameter => !parameter.invisible)
                    .map(parameter => ({
                      name: parameter.param_display_name,
                      value: parameter.param_value,
                    })),
                }))
              )
            : null,
      },
    ],
  },
  {
    id: 'testCompare.postProcessing',
    accordion: true,
    fields: [
      {
        id: 'testCompare.postProcessingModules',
        render: ({application}) =>
          application?.postProcessors ? (
            <div className="test-details__modules">
              {application.postProcessors.map(pp =>
                pp.parameters?.map((parameter, k) => (
                  <div key={k} className="test-details__module-row">
                    {k === 0 && (
                      <div className="test-details__module-name">{pp.name}</div>
                    )}
                    <div>
                      <FormattedMessage
                        id="testCompare.ispModuleParameterListing"
                        values={{
                          name: parameter.displayName,
                          value: parameter.value,
                        }}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          ) : null,
        renderPlain: ({application}) =>
          application?.postProcessors
            ? application.postProcessors.map(pp => ({
                name: pp.name,
                parameters: pp.parameters?.map((parameter, k) => ({
                  name: parameter.displayName,
                  value: parameter.value,
                })),
              }))
            : null,
      },
    ],
  },
  {
    id: 'testCompare.applicationSettings',
    accordion: true,
    fields: [
      {
        id: 'testCompare.analyticParameters',
        render: ({environmentVariables}) => {
          let variables: JSX.Element[] = [];

          Object.entries(environmentVariables || {}).forEach(([key, value], index) => {
            variables.push(
              <span key={`environment-variable-${index}`}>
                {key}: {value}
                <br />
              </span>
            );
          });

          return variables.length ? (
            <>{variables}</>
          ) : (
            <FormattedMessage id="testCompare.noneAnalyticParameters" />
          );
        },
        renderPlain: ({application, environmentVariables}) => ({
          ...application?.model?.frameworkParameters?.environment,
          ...environmentVariables,
        }),
      },
    ],
  },
];

export function renderToObject(detailsBag: DetailsBag) {
  const {intl} = detailsBag;
  const renderSection = (sectionConfig: SectionConfig) => {
    const section: Record<string, any> = {};
    sectionConfig.fields.forEach(fieldConfig => {
      section[intl.formatMessage({id: fieldConfig.id})] = renderField(fieldConfig);
    });
    return section;
  };

  const renderField = (fieldConfig: FieldConfig) => {
    if ('fields' in fieldConfig) {
      return renderSection(fieldConfig);
    } else {
      const renderedField = fieldConfig.renderPlain
        ? fieldConfig.renderPlain(detailsBag)
        : fieldConfig.render(detailsBag);

      if (React.isValidElement(renderedField)) {
        const {intl} = detailsBag;
        const reactElement: ReactElement = renderedField;

        const messageId = reactElement.props?.id;
        const values = reactElement.props.values;

        const stringValue = intl.formatMessage({id: messageId}, values);
        return stringValue;
      }
      return renderedField;
    }
  };

  const details: Record<string, any> = {};

  detailsConfig.forEach(sectionConfig => {
    details[intl.formatMessage({id: sectionConfig.id})] = renderSection(sectionConfig);
  });

  return details;
}

function isNumberAvailable(value: number | null | undefined): value is number {
  return !isNil(value) && value > 0;
}

function fixedPointField(value: number | null | undefined, intl: IntlShape) {
  return isNumberAvailable(value)
    ? intl.formatNumber(value, {
        style: 'decimal',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })
    : null;
}

function millisecondField(value: number | null | undefined, intl: IntlShape) {
  return isNumberAvailable(value)
    ? intl.formatNumber(value, {
        style: 'unit',
        unit: 'millisecond',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    : null;
}

function percentageField(value: number | null | undefined, intl: IntlShape) {
  return isNumberAvailable(value)
    ? intl.formatNumber(value / 100, {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : null;
}
