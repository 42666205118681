import {useField} from 'formik';
import React, {useState} from 'react';
import {StudioTags} from '../../../base-components/StudioTags';
import {useDynamicFormField} from '../DynamicFormToFormikAdapter';

type DynamicTagsProps = {
  className?: string;
  label: string;
  name: string;
  values?: string[];
  tooltip?: string;
  key?: string;
};

export const DynamicTags = ({
  className,
  label,
  name,
  values,
  tooltip,
  ...restProps
}: DynamicTagsProps) => {
  useDynamicFormField(name);
  const [, , helpers] = useField({name});
  const [options, setOptions] = useState<string[]>(values || []);
  return (
    <StudioTags
      {...restProps}
      className={className}
      fixedTooltip
      tooltip={tooltip}
      label={label}
      name={name}
      value={options}
      onChange={(_event, value) => {
        helpers.setValue(value);
        setOptions(value);
      }}
      options={options}
    />
  );
};
