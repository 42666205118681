import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import StagePanel from './StagePanel';
import StageAnimation from '../StageAnimation/StageAnimation';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import ModelOptimizationAction from '../StageActions/ModelOptimizationAction';
import ModelActionButton from '../../../base-components/StudioButton/ModelActionButton';
import {ModelViewer} from '../../ModelViewer/ModelViewer';
import {toast} from '../../../base-components/StudioToast';
import {StudioPopover} from '../../../base-components/StudioPopover';
import {toggleModelDetailsPanel} from '../../../store/model';
import {TerminateButton} from './TerminateButton';
import {getIsDiabledModelOptimizationStage} from '../../../store/view';
import './OptimizationPanel.scss';

class OptimizationPanel extends StagePanel {
  constructor(props) {
    super(props);
    this.state = {
      model: null,
    };
    this.initiateOptimization = this.initiateOptimization.bind(this);
    this.terminateOptimization = this.terminateOptimization.bind(this);
  }

  getPanelStyle() {
    return 'tall';
  }

  getLockedContent() {
    return (
      <div className="locked-content" data-testid="OPTIMIZE_MODEL-panel__locked">
        {this.getTooltip()}
        <p className="lock-reason">
          {this.props.shouldDisabledViewStage
            ? this.props.intl.formatMessage({
                id: 'dataViewer.optimizationPanel.runningFineTuneStage',
              })
            : this.props.stage.lockReason}
        </p>
      </div>
    );
  }

  getInactiveContent() {
    return (
      <div className="inactive-content">
        <div className="panel-optimization-inactive" />
        <div className="panel-title">
          <FormattedMessage
            tagName="span"
            className="panel-caption"
            id="terminate.optimizeStage.inactive.title"
          />
        </div>
      </div>
    );
  }

  getActiveContent() {
    return (
      <div className="stage-panel-status">
        <div className="active-content" data-testid="OPTIMIZE_MODEL-panel__active">
          <div className="panel-optimization-inactive" />

          <div className="panel-title">
            <FormattedMessage
              tagName="span"
              className="panel-caption"
              id="terminate.optimizeStage.activeContent.title"
            />
            {this.getTooltip('stage-panel__tooltip')}
          </div>
        </div>
        <div
          className="active-content optimization-panel stage-panel-buttons"
          data-testid="OPTIMIZE_MODEL-action-buttons"
          id="OPTIMIZE_MODEL-action-buttons"
        >
          <ModelActionButton
            value="run"
            onClick={this.initiateOptimization}
            data-testid="optimization-panel-button"
            id="optimization-panel-button"
          />
        </div>
      </div>
    );
  }

  getInProgressContent() {
    return (
      <div className="stage-panel-status">
        <div
          className="in-progress-content"
          data-testid="OPTIMIZE_MODEL-panel__in-progress"
          id="OPTIMIZE_MODEL-panel__in-progress"
        >
          <div className="panel-optimization-active" />

          <div className="panel-title">
            <span className="panel-caption">
              {this.props.intl.formatMessage({
                id: 'terminate.optimizeStage.inProgress.title',
              })}
              <br />
              <span className="panel-description" id="optimize-action-progress">
                {this.props.intl.formatMessage({
                  id: 'terminate.optimizeStage.inProgress.subtitle',
                })}
              </span>
            </span>
            {this.getTooltip('stage-panel__tooltip')}
          </div>
        </div>
        <div
          className="in-progress-content optimization-panel stage-panel-buttons"
          data-testid="OPTIMIZE_MODEL-action-buttons"
          id="OPTIMIZE_MODEL-action-buttons"
        >
          <TerminateButton
            onClick={() => {
              this.props.openTerminateDialog({
                terminate: this.terminateOptimization,
                title: this.props.intl.formatMessage({id: 'terminate.optimizeTitle'}),
                message: this.props.intl.formatMessage({
                  id: 'terminate.optimizeContent',
                }),
              });
            }}
          />
        </div>
      </div>
    );
  }

  getFinishedContent() {
    return (
      <div className="stage-panel-status">
        <div className="finished-content" data-testid="OPTIMIZE_MODEL-panel__finished">
          <div className="panel-optimization-inactive" />
          <div className="panel-title">
            <FormattedMessage
              tagName="span"
              className="panel-caption"
              id="terminate.optimizeStage.finished.title"
            />
            {this.getTooltip('stage-panel__tooltip')}
          </div>
        </div>
        <div
          className="finished-content optimization-panel stage-panel-buttons"
          data-testid="OPTIMIZE_MODEL-action-buttons"
          id="OPTIMIZE_MODEL-action-buttons"
        >
          <ModelActionButton
            value="details"
            onClick={() =>
              this.props.toggleModelDetailsPanel(this.props.projectId, 'COMPRESSED')
            }
          />
          <ModelActionButton
            value="reoptimize"
            onClick={this.initiateOptimization}
            data-testid={`${this.props.stage.type}-panel-popup-menu`}
            id={`${this.props.stage.type}-panel-popup-menu`}
          />
        </div>
      </div>
    );
  }

  getTooltip(className = '') {
    return (
      <StudioPopover
        infoIconClass={className}
        infoMessage={
          <FormattedMessage tagName="span" id="terminate.optimizeStage.tooltip" />
        }
      />
    );
  }

  getOverlay() {
    return (
      <div
        className={
          'stage-panel-overlay' + (this.props.stage.active ? ' active' : ' inactive')
        }
        onClick={this.activatePanel}
        id={this.props.stage.type + '-panel-overlay'}
        data-testid={'optimize-overlay'}
      />
    );
  }

  initiateOptimization() {
    let elem = document.getElementById(this.props.stage.type + '-container');
    const stageAnimation = new StageAnimation();
    if (elem && !elem.classList.contains('active')) {
      stageAnimation.updateStages(elem);
    }
    let current = this.props.modelOptimizationAction.current;
    if (current) {
      current.optimizeModel();
    }
  }

  async terminateOptimization() {
    let current = this.props.modelOptimizationAction.current;
    if (current) {
      await current.terminateOptimization();
      toast.success({position: 'top', subtitle: 'User terminated optimization'});
    }
  }
}

OptimizationPanel.propTypes = {
  projectId: PropTypes.string.isRequired,
  modelOptimizationAction: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(ModelOptimizationAction)}),
    PropTypes.shape({current: null}),
  ]).isRequired,
  assistantPanel: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)}),
  ]).isRequired,
  onFinish: PropTypes.func.isRequired,
  stage: PropTypes.any.isRequired,
  modelViewer: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(ModelViewer)}),
  ]),
};

const mapDispatchToProps = {
  toggleModelDetailsPanel,
};

const mapStateToProps = state => ({
  shouldDisabledViewStage: getIsDiabledModelOptimizationStage(state),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(
    OptimizationPanel
  ),
  {forwardRef: true}
);
