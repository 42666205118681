import _ from 'lodash';

function cleanText(text: string) {
  return text.replace(/[":,()/]/g, '');
}

export function transformMessageToIntlKey(message: string, prefix: string = '') {
  return (
    'dynamicKeyMessage.' +
    _.camelCase(cleanText(prefix)) +
    '.' +
    _.camelCase(cleanText(message))
  );
}
