import {MarketplaceFilters} from '../../types/marketplace/MarketplaceFilesRequest';
import {IntegrationPurpose} from '../../types/integrations/IntegrationResponse';
import {createIntl, createIntlCache} from 'react-intl';
import messages from '../en.json';

const cache = createIntlCache();
const intl = createIntl({locale: navigator.language, messages: messages}, cache);

type MarketplaceFlagFilter = {
  key: keyof Pick<MarketplaceFilters, 'compatibleOnly'>;
  type: 'FLAG';
  limitBy: IntegrationPurpose[];
  label: string;
};

type MarketplaceMultipleFilter = {
  key: keyof Pick<MarketplaceFilters, 'studioVersion'>;
  label: string;
  limitBy: IntegrationPurpose[];
  type: 'MULTIPLE';
  options: FilterOptions;
  tooltip?: string;
};

type MarketplaceOptionsFilter = {
  key: keyof Pick<
    MarketplaceFilters,
    | 'purpose'
    | 'dataLoader'
    | 'colorSpace'
    | 'type'
    | 'baseModel'
    | 'yoloType'
    | 'applicationType'
    | 'transformationType'
    | 'executionContainer'
    | 'postProcessorType'
  >;
  type: 'OPTIONS';
  limitBy: IntegrationPurpose[];
  label: string;
  options: FilterOptions;
  tooltip?: string;
};

type MarketplaceTagsFilter = {
  key: keyof Pick<MarketplaceFilters, 'tags' | 'labelNames'>;
  type: 'TAGS';
  limitBy: IntegrationPurpose[];
  label: string;
  tooltip?: string;
};

type MarketplaceCustomFilter = {
  key: keyof Pick<MarketplaceFilters, 'categoryName'>;
  label: string;
  limitBy: IntegrationPurpose[];
  type: 'CUSTOM';
};

export type FilterDefinitions = Array<
  | MarketplaceFlagFilter
  | MarketplaceOptionsFilter
  | MarketplaceMultipleFilter
  | MarketplaceTagsFilter
  | MarketplaceCustomFilter
>;

class FilterOptions {
  values: Record<string, string> = {};
  order: string[] = [];

  constructor(elements: FilterOption[]) {
    elements.forEach(({key, value}) => {
      this.values[key] = value;
      this.order.push(key);
    });
  }

  getValues(): Record<string, string> {
    return this.values;
  }

  getOrder(): string[] {
    return this.order;
  }

  getValue(key: string): string | undefined {
    return this.values[key];
  }

  getLength(): number {
    return this.order.length;
  }
}

type FilterOption = {
  key: string;
  value: string;
};

export const filterDefinitions: FilterDefinitions = [
  {
    key: 'compatibleOnly',
    type: 'FLAG',
    limitBy: ['DATASET_STORAGE'],
    label: 'marketplaceResult.showCompatibleDatasets',
  },
  {
    key: 'compatibleOnly',
    type: 'FLAG',
    limitBy: ['MODEL_STORAGE'],
    label: 'marketplaceResult.showCompatibleModels',
  },
  {
    key: 'compatibleOnly',
    type: 'FLAG',
    limitBy: ['ISP_STORAGE'],
    label: 'marketplaceResult.showCompatiblePreProcessors',
  },
  {
    key: 'compatibleOnly',
    type: 'FLAG',
    limitBy: ['PP_STORAGE'],
    label: 'marketplaceResult.showCompatiblePostProcessors',
  },
  {
    key: 'purpose',
    type: 'OPTIONS',
    limitBy: ['DATASET_STORAGE', 'MODEL_STORAGE', 'SOLUTION_STORAGE'],
    label: 'marketplaceResult.purpose',
    options: new FilterOptions([
      {key: 'Classification', value: 'Classification'},
      {key: 'Detection', value: 'Detection'},
      {key: 'PoseDetection', value: 'Pose Detection'},
      {key: 'Segmentation', value: 'Segmentation'},
      {key: 'Other', value: 'Other'},
    ]),
    tooltip: intl.formatMessage({
      id: 'marketplaceExplorer.filterDefinitions.purpose.tooltip',
    }),
  },
  {
    key: 'dataLoader',
    type: 'OPTIONS',
    limitBy: ['DATASET_STORAGE'],
    label: 'marketplaceResult.dataLoader',
    options: new FilterOptions([
      {key: 'Custom', value: 'Custom'},
      {key: 'ImageFolder', value: 'ImageFolder'},
      {key: 'COCO', value: 'COCO'},
    ]),
    tooltip:
      'Data loader is a piece of code that determines, based on input data format, how images and their annotations are loaded into AI Studio.',
  },
  {
    key: 'colorSpace',
    type: 'OPTIONS',
    limitBy: ['DATASET_STORAGE'],
    label: 'marketplaceResult.colorSpace',
    options: new FilterOptions([
      {key: 'bayer_rggb', value: 'Bayer RGGB'},
      {key: 'bayer_bggr', value: 'Bayer BGGR'},
      {key: 'bayer_rgbg', value: 'Bayer RGBG'},
      {key: 'bayer_grgb', value: 'Bayer GRGB'},
      {key: 'rgb', value: 'RGB'},
      {key: 'bgr', value: 'BGR'},
    ]),
    tooltip:
      'Color space specifies the encoding of the input image data. Encoding may be Bayer or Raw format or RGB/YUV formats.',
  },
  {
    key: 'labelNames',
    type: 'TAGS',
    limitBy: ['DATASET_STORAGE'],
    label: 'marketplaceResult.labelsFilter',
    tooltip: intl.formatMessage({
      id: 'marketplaceExplorer.filterDefinitions.labels.tooltip',
    }),
  },
  {
    key: 'type',
    type: 'OPTIONS',
    limitBy: ['MODEL_STORAGE'],
    label: 'marketplaceResult.type',
    options: new FilterOptions([
      {key: 'CAFFE_2', value: 'Caffe2'},
      {key: 'PY_TORCH', value: 'PyTorch'},
      {key: 'TENSOR_FLOW', value: 'TensorFlow'},
      {key: 'PY_PICASSO', value: 'PyAct'},
      {key: 'ONNX', value: 'ONNX'},
    ]),
  },
  {
    key: 'baseModel',
    type: 'OPTIONS',
    limitBy: ['MODEL_STORAGE'],
    label: 'marketplaceResult.baseModel',
    options: new FilterOptions([
      {key: 'YOLOV3', value: 'YoloV3'},
      {key: 'YOLOV3_TINY', value: 'Yolo V3 Tiny'},
      {key: 'YOLOV4', value: 'Yolo V4'},
      {key: 'YOLOV5', value: 'YoloV5'},
      {key: 'YOLOV5V7', value: 'Yolo V5V7'},
      {key: 'GOOGLENET', value: 'Googlenet'},
      {key: 'MOBILENET', value: 'Mobilenet'},
      {key: 'RESNET18', value: 'Resnet18'},
      {key: 'RESNET34', value: 'Resnet34'},
      {key: 'RESNET50', value: 'Resnet50'},
      {key: 'RESNET101', value: 'Resnet101'},
      {key: 'RESNET152', value: 'Resnet152'},
      {key: 'RESNEXT50', value: 'Resnext50'},
      {key: 'SQUEEZENET', value: 'SqueezeNet'},
      {key: 'FER_PLUS', value: 'FER+'},
      {key: 'VGG11', value: 'VGG11'},
      {key: 'VGG13', value: 'VGG13'},
      {key: 'VGG16', value: 'VGG16'},
      {key: 'VGG19', value: 'VGG19'},
      {key: 'UNET', value: 'U-Net'},
      {key: 'SWIFTNET', value: 'SwiftNet'},
      {key: 'OPENPOSE', value: 'Open Pose'},
      {key: 'POSENET', value: 'PoseNet'},
      {key: 'OTHER', value: 'Other'},
    ]),
  },
  {
    key: 'yoloType',
    type: 'OPTIONS',
    limitBy: ['MODEL_STORAGE'],
    label: 'marketplaceResult.modelVariant',
    options: new FilterOptions([
      {key: 'YOLOV5N', value: 'YOLO V5 Nano'},
      {key: 'YOLOV5S', value: 'YOLO V5 Small'},
      {key: 'YOLOV5M', value: 'YOLO V5 Medium'},
      {key: 'YOLOV5L', value: 'YOLO V5 Large'},
      {key: 'YOLOV5X', value: 'YOLO V5 Extra-Large'},
    ]),
  },
  {
    key: 'categoryName',
    label: 'marketplaceResult.category',
    type: 'CUSTOM',
    limitBy: ['SOLUTION_STORAGE'],
  },
  {
    key: 'transformationType',
    type: 'OPTIONS',
    limitBy: ['ISP_STORAGE'],
    label: 'marketplaceResult.preProcessorType',
    options: new FilterOptions([
      {key: 'ISP', value: 'ISP'},
      {key: 'SCRIPT', value: 'Script'},
    ]),
    tooltip: intl.formatMessage({
      id: 'marketplaceExplorer.filterDefinitions.processorType.tooltip',
    }),
  },
  {
    key: 'postProcessorType',
    type: 'OPTIONS',
    limitBy: ['PP_STORAGE'],
    label: 'marketplaceResult.postProcessorType',
    options: new FilterOptions([
      {key: 'MODEL', value: 'Model'},
      {key: 'APPLICATION', value: 'Application'},
      {key: 'VALIDATION', value: 'Validation'},
    ]),
    tooltip: intl.formatMessage({
      id: 'marketplaceExplorer.filterDefinitions.processorType.tooltip',
    }),
  },
  {
    key: 'applicationType',
    type: 'OPTIONS',
    limitBy: ['ISP_STORAGE', 'PP_STORAGE'],
    label: 'marketplaceResult.appType',
    options: new FilterOptions([
      {key: 'ONNX_APP', value: 'ONNX'},
      {key: 'ONNX_WEB_APP', value: 'ONNX Web'},
      {key: 'OPEN_VX_APP', value: 'OpenVX'},
      {key: 'PY_PICASSO_APP', value: 'PyAct'},
    ]),
    tooltip: intl.formatMessage({
      id: 'marketplaceExplorer.filterDefinitions.applicationType.tooltip',
    }),
  },
  {
    key: 'executionContainer',
    type: 'OPTIONS',
    limitBy: ['ISP_STORAGE', 'PP_STORAGE'],
    label: 'marketplaceResult.targetDevice',
    options: new FilterOptions([
      {key: 'BROWSER', value: 'Browser'},
      {key: 'GSP', value: 'GSP'},
      {key: 'CLIENT', value: 'External Client'},
      {key: 'NODE', value: 'Edge Node'},
    ]),
  },
  {
    key: 'studioVersion',
    type: 'MULTIPLE',
    limitBy: ['ISP_STORAGE', 'PP_STORAGE'],
    label: 'marketplaceResult.studioVersion',
    options: new FilterOptions([
      {key: '1.5.1', value: '1.5.1'},
      {key: '1.5.2', value: '1.5.2'},
      {key: '1.5.3', value: '1.5.3'},
      {key: '1.6', value: '1.6'},
    ]),
  },
  {
    key: 'tags',
    type: 'TAGS',
    limitBy: [],
    label: 'marketplaceResult.tagsFilter',
    tooltip: intl.formatMessage({
      id: 'marketplaceExplorer.filterDefinitions.tags.tooltip',
    }),
  },
];
