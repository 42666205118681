import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from '../../../base-components/StudioToast';
import {IntegrationDialogBase} from '../IntegrationDialogBase';
import {useIntl} from 'react-intl';
import {RootState} from '../../../store';
import {
  integrationNewFinalize,
  setIsChooseModalOpen,
  setIsCreateModalOpen,
} from '../../../store/marketplace/configure';
import {Answer} from '../../../types/prompts/Answer';

export const CreateIntegrationDialog = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector(
    (state: RootState) => state.configureMarketplace.isCreateModalOpen
  );
  const newPromptsData = useSelector(
    (state: RootState) => state.configureMarketplace.newPromptsData
  );
  const isPending = useSelector(
    (state: RootState) => state.configureMarketplace.isNewPromptsPending
  );
  const submissionResponse = useSelector(
    (state: RootState) => state.configureMarketplace.newFinalize
  );
  const submissionError = useSelector(
    (state: RootState) => state.configureMarketplace.newFinalizeError
  );

  const prevSuccessRef = useRef(submissionResponse);
  const prevErrorRef = useRef(submissionError);

  useEffect(() => {
    if (submissionError && submissionError !== prevErrorRef.current) {
      toast.error(`Error! Could not create integration. Please try again.`);
      prevErrorRef.current = submissionError;
    } else if (submissionResponse && submissionResponse !== prevSuccessRef.current) {
      toast.success(`Successfully created integration: ${submissionResponse.name}`);
      dispatch(setIsChooseModalOpen(false));
      dispatch(setIsCreateModalOpen(false));
      prevSuccessRef.current = submissionResponse;
    }
  }, [submissionResponse, submissionError, dispatch]);

  const handleSubmit = async (answers: Array<Answer>) => {
    const formattedAnswers = answers.map(({key, type: fieldType, value}) => ({
      invalid: false,
      key,
      type: fieldType,
      value,
      values: null,
    }));
    if (newPromptsData) {
      formattedAnswers.push({
        invalid: false,
        key: 'Integration.Type',
        type: 'MULTIPLE_CHOICES_TREE',
        value: newPromptsData.type.key,
        values: null,
      });
    }
    await dispatch(integrationNewFinalize(formattedAnswers));
  };

  return (
    <IntegrationDialogBase
      isOpen={isOpen}
      isPending={isPending}
      defaults={newPromptsData?.defaults}
      prompts={newPromptsData?.prompts}
      title={
        newPromptsData?.type &&
        intl.formatMessage(
          {id: 'marketplaceConfig.createTitle'},
          {storageType: newPromptsData.type.name}
        )
      }
      onClose={() => dispatch(setIsCreateModalOpen(false))}
      onSubmit={handleSubmit}
      submitText={intl.formatMessage({id: 'form.create'})}
      data-testid="create-integration-dialog"
    />
  );
};
