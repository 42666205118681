import {
  PROJECT_OP_STATUS_FETCH_PENDING,
  PROJECT_OP_STATUS_FETCH_SUCCESS,
  PROJECT_OP_STATUS_FETCH_ERROR,
} from './actions';

const initialState = {
  isPending: false,
  // Data will contain project data data has been massaged and sorted to per last modified.
  data: {},
  errors: null,
  prompts: null,
  warnings: null,
};

export const projectOpStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_OP_STATUS_FETCH_PENDING:
      return {
        ...state,
        isPending: true,
      };
    case PROJECT_OP_STATUS_FETCH_SUCCESS:
      const {payload} = action;
      return {
        ...state,
        data: {...initialState.data, ...payload},
        isPending: false,
        errors: payload.errors,
        prompts: payload.prompts,
        warnings: payload.warnings,
      };

    case PROJECT_OP_STATUS_FETCH_ERROR:
      return {
        ...state,
        isPending: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};
