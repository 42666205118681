import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ChevronUpIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="10px"
          height="7px"
          viewBox="0 0 10 7"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <polygon
              id="chevron-up-icon"
              points="12 9.1796875 17 14.1796875 15.828125 15.3515625 12 11.5234375 8.171875 15.3515625 7 14.1796875"
            ></polygon>
          </defs>
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-851.000000, -3137.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 2337.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-chevron-up-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#chevron-up-icon"></use>
                      </mask>
                      <use
                        id="chevron-up"
                        fill="currentColor"
                        xlinkHref="#chevron-up-icon"
                      ></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

ChevronUpIcon.propTypes = {
  className: PropTypes.string,
};

export default ChevronUpIcon;
