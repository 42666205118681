export const defaultPostProcessor = `
export function postprocessImage(
  imagePath,
  modelOutputJson,
  annotations,
  file
) {
  if (!file) {
    return;
  }

  const container = document.getElementById("inference-results-element");
  container.style =
    "display: flex; flex-wrap: wrap; align-content: flex-start; align-items: flex-start; gap: 32px; overflow-y: auto; color: #1c1c1c";
  
  const cardContainer = document.createElement("div");
  cardContainer.style = \`font-size: 0.875rem; font-family: Proxima Nova,Arial,Helvetica,sans-serif;
      color: #626262;\`;
  container.appendChild(cardContainer);

  const card = document.createElement("div");
  card.style = "display: flex;";
  cardContainer.appendChild(card);

  const image = document.createElement("img");
  image.src = URL.createObjectURL(file);
  image.style = "width: 125px; height: 125px; padding-right:5px;";
  card.appendChild(image);

  let probabilities;
  
  if (typeof modelOutputJson === "string") {
    const modelOutput = JSON.parse(modelOutputJson);

    probabilities = [];

    Object.keys(modelOutput).forEach(label => {
      probabilities.push({name: label, probability: modelOutput[label] });
    });
  }
  else {
    const outputVector = modelOutputJson[0];
    probabilities = softmax(outputVector).map((probability, index) => ({
        name: annotations[index].name,
        probability,
    }));
  }

  probabilities.sort((a, b) => b.probability - a.probability);

  const listNode = document.createElement("ul");
  listNode.style = \` text-transform: capitalize; padding: 0px 10px; 
       margin: 0; height: 125px; overflow-y: auto;\`;

  card.appendChild(listNode);

  for (let i = 0; i < probabilities.length; i++) {
    const label = probabilities[i].name;
    const probability = probabilities[i].probability;

    const listItemNode = document.createElement("li");
    listItemNode.style =
      "display: flex; gap: 5px; align-items: center; margin-bottom: 10px;";

    const labelNode = document.createElement("div");
    labelNode.innerHTML = label.toLowerCase();
    labelNode.style =
      "width: 100px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;";

    const barNode = document.createElement("div");
    barNode.style = "width: 50px; background-color: #e6e6e6; height: 10px;";

    const fillNode = document.createElement("div");
    fillNode.style = \`width: \${50 * probability}px; background-color: #00aeef; height: 100%;\`;

    const percentNode = document.createElement("div");
    percentNode.innerHTML = \`\${(probability * 100).toFixed(1)}%\`;

    barNode.appendChild(fillNode);
    listItemNode.appendChild(labelNode);
    listItemNode.appendChild(barNode);
    listItemNode.appendChild(percentNode);
    listNode.appendChild(listItemNode);
  }

  const fileNameNode = document.createElement("p");
  fileNameNode.innerText = file.name;
  cardContainer.appendChild(fileNameNode);
}

export function postProcess(rawInferenceResults, annotations, files) {
  if (!rawInferenceResults || !annotations) {
    return;
  }
  rawInferenceResults =
    typeof rawInferenceResults === "string"
      ? JSON.parse(rawInferenceResults)
      : rawInferenceResults;

  const filesMap = files.reduce(
    (map, file) => ({ ...map, [file.name]: file }),
    {}
  );

  const container = document.getElementById("inference-results-element");
  container.innerHTML = "";

  for (let i = 0; i < rawInferenceResults.length; i++) {
    const item = rawInferenceResults[i];
    const fileName = item["imagePath"];
    const output = item["modelOutputJson"];
    postprocessImage(fileName, output, annotations, filesMap[fileName]);
  }
}

export function readAnnotations(annotationsOutput) {
  // Add code that makes additional manipulations over the annotations if needed. This function calls each time the
  // inference page is rendered.
}

function softmax(vector) {
  const values = vector.map((value) => Math.pow(Math.E, value));
  const sum = values.reduce((sum, value) => sum + value);
  const probabilities = values.map((value) => value / sum);
  return probabilities;
}
`;
