import React, {useEffect, useState, useRef, useContext, useMemo} from 'react';
import {Stage as KonvaStage, Layer, Rect, Transformer} from 'react-konva';
import {
  Box,
  BoxLabelProps,
  LabelProps,
  LineLabelProps,
  PolyLabelProps,
  TooltipEvent,
} from './types';
import {genId, SCALE_FACTOR, SELECTION_BOX, TRANSFORMER} from './const';
import {Label, LABEL_NAME} from './Label';
import {KonvaPointerEvent} from 'konva/types/PointerEvents';
import {without} from 'lodash';
import {CircularProgress, Tooltip} from '@material-ui/core';
import {Image, IMAGE_NAME} from './Image';
import Konva from 'konva';
import {KonvaEventObject} from 'konva/types/Node';
import {AnnotatorContext, NOT_SCALE_TO_FIT} from './Provider';
import Hotkeys from 'react-hot-keys';
import BrokenImage from '@material-ui/icons/BrokenImage';
import grey from '@material-ui/core/colors/grey';
import {
  MAX_SCALE_FACTOR,
  MIN_SCALE_FACTOR,
} from '../../components/DataPrepare/DetectionStep/const';
import './Annotator.scss';
import {Polygon} from './Polygon';
import {Line} from './Line';
import {ImageType} from '../../types/dataset/DatasetGetAnnotationsResponse';

const STAGE_NAME = 'stage';
const LOADER_SIZE = 100;
const MIN_LABEL_DIMENSION = 5;
export const STAGE_HEIGHT_ADJ = 50;
const CURSOR_MAP: {
  [key: string]: string;
} = {
  Stage: 'default',
  Default: 'default',
  Image: 'crosshair',
  Grabbing: 'grabbing',
};

type ImageProp = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type ToolType = 'RECT' | 'LINE' | 'POLY' | 'SELECT';

// export type ImageType = {
//   id: string;
//   filename: string; //'<filename0>.<ext>'
//   height: number;
//   width: number;
//   size?: number;
// };
export interface AnnotatorProps {
  src?: string;
  lastLabelUsed?: string | null;
  image?: ImageType | null | undefined;
  width?: number;
  height?: number;
  boxLabels?: BoxLabelProps[];
  polyLabels?: PolyLabelProps[];
  lineLabels?: LineLabelProps[];
  selectedLabels: string[];
  zoomEnable?: boolean;
  fullToolset?: boolean;
  tool?: ToolType;
  onBoxLabelCreated?: (label: BoxLabelProps) => void;
  onPolyLabelCreated?: (label: PolyLabelProps) => void;
  onLineLabelCreated?: (label: LineLabelProps) => void;
  onSelect?: (ids: string[]) => void | void;
  onContextMenu?: (e: PointerEvent) => void | void;
  onDelete?: (id: string, tool?: ToolType) => void | void;
  onChange?: (target: BoxLabelProps, newProp: Partial<BoxLabelProps>) => void;
}

export function Annotator(props: AnnotatorProps) {
  const {
    src,
    lastLabelUsed,
    image,
    width = 0,
    height = 0,
    boxLabels,
    polyLabels = [],
    lineLabels = [],
    selectedLabels,
    tool = 'SELECT',
    fullToolset = false,
    onBoxLabelCreated = () => {},
    onPolyLabelCreated = () => {},
    onLineLabelCreated = () => {},
    onSelect,
    onContextMenu,
    onChange = () => {},
    onDelete,
  } = props;

  const {scale, imageLayer, setImageLayer, setScaleToFitFactor} = useContext(
    AnnotatorContext
  );

  const [tooltip, setTooltip] = useState<{title: string; x: number; y: number}>({
    title: '',
    x: 0,
    y: 0,
  });

  const [selector, setSelector] = useState<LabelProps>({
    visible: false,
  });

  const [isTransforming, setIsTransforming] = useState(false);
  const [isImageBusy, setIsImageBusy] = useState(false);
  const [cursor, setCursor] = useState('default');
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [isImageError, setIsImageError] = useState(false);
  // Used for POLY and LINE tools
  const [localPolyLabel, setLocalPolyLabel] = useState<PolyLabelProps[]>([]);
  const [localLineLabels, setLocalLineLabels] = useState<LineLabelProps[]>([]);

  const stageRef = useRef<Konva.Stage>(null);
  const layerRef = useRef(null);
  const imageLayerRef = useRef<Konva.Layer>(null);
  const transformer = useRef<Konva.Transformer>(null);

  const iWidth = image?.width || 0;
  const iHeight = image?.height || 0;
  const x = ((iWidth - width) / 2) * getScaleDir(width, iWidth);
  const y = ((iHeight - height) / 2) * getScaleDir(height, iHeight);

  const scaledImage: ImageProp = {
    x,
    y,
    width: iWidth * scale(),
    height: iHeight * scale(),
  };

  const scaledBoxLabels = getScaleBoxLabels(scaledImage, boxLabels);

  function getScaleDir(containerSize: number, objectSize: number) {
    return containerSize < objectSize ? (containerSize > objectSize ? 1 : -1) : -1;
  }

  function getScaleBoxLabel(boxLabel: BoxLabelProps) {
    return {
      ...boxLabel,
      width: boxLabel.width * imageLayer.scale,
      height: boxLabel.height * scale(),
      x: imageLayer.x + x * imageLayer.scale + boxLabel.x * imageLayer.scale,
      y: imageLayer.y + y * imageLayer.scale + boxLabel.y * imageLayer.scale,
    };
  }

  function getScaleBoxLabels(refImage: ImageProp, boxLabels: BoxLabelProps[] = []) {
    if (!!boxLabels?.length && refImage) {
      return boxLabels.map(el => getScaleBoxLabel(el));
    }
    return [];
  }

  useMemo(() => {
    if (polyLabels.length || fullToolset) {
      if (tool === 'POLY') {
        setLocalPolyLabel([
          ...polyLabels,
          {
            id: 'temp',
            points: [],
          },
        ]);
      } else {
        setLocalPolyLabel([...polyLabels]);
      }
    }
  }, [tool, polyLabels, fullToolset]);

  useMemo(() => {
    if (lineLabels.length || fullToolset) {
      if (tool === 'LINE') {
        setLocalLineLabels([
          ...lineLabels,
          {
            id: 'temp',
            points: [],
          },
        ]);
      } else {
        setLocalLineLabels([...lineLabels]);
      }
    }
  }, [tool, lineLabels, fullToolset]);

  useEffect(() => {
    transformer?.current?.nodes([]);
    clean();
    // eslint-disable-next-line
  }, [src]);

  // Initiates transform if selecton is one image, as well as newly created image
  useEffect(() => {
    if (selectedLabels.length > 1) {
      disableSingleLabelTransform();
    } else {
      const tr = transformer.current;
      if (selectedLabels.length === 1) {
        const node = findLabelObjById(selectedLabels[0]);
        node && tr && tr.nodes([node]);
        tr?.nodes()?.map(node => node.setAttrs({draggable: true}));
      } else {
        tr?.nodes([]);
      }
    }
  }, [selectedLabels]);

  // eslint-disable-next-line
  useEffect(() => refreshCursor(), [isGrabbing]);

  const clean = () => {
    setTooltip({...tooltip, title: ''}); // remove tooltip
    setIsImageError(false);
  };
  // get all groups with a function
  const findLabelObjById = (id: string | null | undefined) => {
    const stage = stageRef.current;
    return (
      stage?.find((node: Konva.Node) => {
        return node.getType() === 'Group' && node.id() === id;
      })[0] || null
    );
  };

  const findBoxLabelPropById = (id: string) => {
    return boxLabels ? boxLabels.find(el => el.id === id) : null;
  };

  function getScaledPointerPosition() {
    const stage = stageRef.current;
    const pointerPos = stage?.getPointerPosition();
    const stageAttrs = stage?.attrs;
    const x = (pointerPos?.x || 0 - stageAttrs.x) / stageAttrs.scaleX;
    const y = (pointerPos?.y || 0 - stageAttrs.y) / stageAttrs.scaleY;
    return {x, y};
  }

  const onStageMouseDown = (e: KonvaPointerEvent) => {
    const pointerPos = getScaledPointerPosition();
    if (tool === 'RECT') {
      if (
        !isGrabbing &&
        (e.target.hasName(IMAGE_NAME) || (!image && e.target.hasName(STAGE_NAME)))
      ) {
        setSelector({
          visible: true,
          width: 0,
          height: 0,
          x: pointerPos?.x,
          y: pointerPos?.y,
        });
        !e.evt.shiftKey && onSelect && onSelect([]);
      }
    }
  };

  const onMousemove = (e: KonvaPointerEvent) => {
    // do nothing if we didn't start selection
    const pointerPos = getScaledPointerPosition();
    if (tool === 'RECT') {
      if (selector.visible) {
        if (pointerPos && selector) {
          const leftLimit = imageLayer.x + x * imageLayer.scale;
          const rightLimit = leftLimit + iWidth * imageLayer.scale;
          const topLimit = imageLayer.y + y * imageLayer.scale;
          const bottomLimit = topLimit + iHeight * imageLayer.scale;
          const sX = selector.x || 0;
          const sY = selector.y || 0;
          setSelector({
            ...selector,
            width:
              pointerPos.x < leftLimit
                ? leftLimit - sX
                : pointerPos.x > rightLimit
                ? rightLimit - sX
                : pointerPos.x - sX,
            height:
              pointerPos.y < topLimit
                ? topLimit - sY
                : pointerPos.y > bottomLimit
                ? bottomLimit - sY
                : pointerPos.y - sY,
          });
        }
      }
    }
  };

  const getProperVal = (length: number = 0, pos: number = 0) => {
    return length < 0 ? pos + length : pos;
  };

  const onMouseup = (e: KonvaPointerEvent) => {
    const height = Math.abs(selector.height || 0);
    const width = Math.abs(selector.width || 0);
    if (
      selector.visible &&
      width >= MIN_LABEL_DIMENSION &&
      height >= MIN_LABEL_DIMENSION
    ) {
      const label: BoxLabelProps = {
        x:
          getProperVal(selector.width, selector.x) / imageLayer.scale -
          imageLayer.x / imageLayer.scale -
          x,
        y:
          getProperVal(selector.height, selector.y) / imageLayer.scale -
          imageLayer.y / imageLayer.scale -
          y,
        width: width / imageLayer.scale,
        height: height / imageLayer.scale,
        id: genId('RECT'),
      };

      onBoxLabelCreated(label);
      onSelect && onSelect([...selectedLabels, label.id]);

      e.target !== e.currentTarget &&
        !e.evt.shiftKey &&
        onContextMenu &&
        !lastLabelUsed &&
        onContextMenu(e.evt);
    } else {
      e.target?.hasName(IMAGE_NAME) && onSelect && onSelect([]);
    }
    setSelector({visible: false});
  };

  const disableSingleLabelTransform = () => {
    const tr = transformer.current;
    tr?.nodes().map(node => node.setAttrs({draggable: false}));
    tr?.nodes([]);
  };

  const refreshCursor = (target?: string) => {
    setCursor(CURSOR_MAP[isGrabbing ? 'Grabbing' : target || 'Stage']);
  };

  const onLabelClick = (
    e: KonvaPointerEvent,
    label: BoxLabelProps | LineLabelProps | PolyLabelProps
  ) => {
    const target = e.currentTarget;
    target.moveToTop();
    transformer.current?.moveToTop();

    if (target.hasName(LABEL_NAME)) {
      const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
      if (!metaPressed) {
        // Enable transform on single Label selection
        target.setAttrs({draggable: true});
        onSelect && onSelect([label.id]);
      } else {
        const list = selectedLabels.includes(label.id)
          ? without(selectedLabels, label.id)
          : [...selectedLabels, label.id];

        onSelect && onSelect(list);
      }
    } else if (tool === 'SELECT') {
      onSelect && onSelect([label.id]);
    }
  };

  const onStageContextMenu = (e: KonvaPointerEvent) => {
    e.evt.preventDefault();
  };

  const onLabelContextMenu = (
    e: KonvaPointerEvent,
    label: BoxLabelProps | LineLabelProps | PolyLabelProps
  ) => {
    onLabelClick(e, label);
    onContextMenu && onContextMenu(e.evt);
  };

  const onTransformStart = (e: KonvaPointerEvent) => {
    setIsTransforming(true);
    onTooltip({title: ''});
  };

  const onTransformEnd = (e: KonvaPointerEvent) => {
    const labelObj: Konva.Shape | Konva.Stage = e.target;
    let label = findBoxLabelPropById(labelObj.id());
    if (label) {
      onChange(label, {
        ...label,
        width: labelObj.scaleX() * (label.width || 0),
        height: labelObj.scaleY() * (label.height || 0),
      });
    }
    labelObj.scaleX(1);
    labelObj.scaleY(1);
    transformer.current && transformer.current.nodes([labelObj]);
    setIsTransforming(false);
  };

  const onTooltip = (e: Partial<TooltipEvent>) => {
    const ref = e.refObj;
    if (e.title) {
      setTooltip({
        x: (ref?.width || 0) / 2 + (ref?.x || 0),
        y: ref?.y || 0,
        title: e.title,
      });
      return;
    }
    clean();
  };

  const onDeleteAnnotation = (id: string, tool: ToolType) => {
    transformer?.current?.nodes([]);
    onDelete && onDelete(id, tool);
    clean();
  };

  const onWheel = (e: KonvaEventObject<WheelEvent>) => {
    e.evt.preventDefault();
    const newScale = Math.min(
      Math.max(
        e.evt.deltaY < 0 ? scale() * SCALE_FACTOR : scale() / SCALE_FACTOR,
        MIN_SCALE_FACTOR
      ),
      MAX_SCALE_FACTOR
    );
    const layerRef = imageLayerRef.current;
    const stage = e.target.getStage();
    const oldScale = layerRef?.scaleX() || 0;
    const pointerPosition = stage?.getPointerPosition() || {x: 0, y: 0};
    if (stage) {
      const mousePointTo = {
        x: (pointerPosition.x || 0) / oldScale - (layerRef?.x() || 0) / oldScale,
        y: pointerPosition.y / oldScale - (layerRef?.y() || 0) / oldScale,
      };
      setScaleToFitFactor(NOT_SCALE_TO_FIT);
      setImageLayer({
        scale: newScale,
        x: (pointerPosition.x / newScale - mousePointTo.x) * newScale,
        y: (pointerPosition.y / newScale - mousePointTo.y) * newScale,
      });
    }
  };

  const onStageMouseOver = (e: KonvaEventObject<MouseEvent>) => {
    const target: string = e.target.constructor.name;
    refreshCursor(target);
  };

  const hotKey: {[key: string]: Function} = {
    spaceUp: () => setIsGrabbing(false),
    spaceDown: () => setIsGrabbing(true),
  };

  const onLabelDrag = (label: BoxLabelProps, props: Partial<BoxLabelProps>) => {
    onChange(label, {
      ...props,
      x: ((props?.x || 0) - (imageLayer.x || 0)) / imageLayer.scale - x,
      y: ((props?.y || 0) - (imageLayer?.y || 0)) / imageLayer.scale - y,
    });
  };

  const onImageDrag = (e: KonvaEventObject<MouseEvent>) => {
    setImageLayer({
      ...imageLayer,
      x: e.currentTarget.attrs.x,
      y: e.currentTarget.attrs.y,
    });
  };

  const boundBoxFunc = (oldBoundBox: Box, newBoundBox: Box) => {
    const leftLimit = imageLayer.x + x * imageLayer.scale;
    const topLimit = imageLayer.y + y * imageLayer.scale;
    const rightLimit = leftLimit + iWidth * imageLayer.scale;
    const bottomLimit = topLimit + iHeight * imageLayer.scale;
    let width =
      oldBoundBox.x === newBoundBox.x
        ? // Check right limit
          newBoundBox.x + newBoundBox.width < rightLimit
          ? newBoundBox.width
          : rightLimit - oldBoundBox.x
        : // Check left limit
        leftLimit > newBoundBox.x
        ? oldBoundBox.width
        : newBoundBox.width;
    let height =
      oldBoundBox.y === newBoundBox.y
        ? // Check bottom limit
          newBoundBox.y + newBoundBox.height < bottomLimit
          ? newBoundBox.height
          : bottomLimit - oldBoundBox.y
        : // Check top limit
        topLimit > newBoundBox.y
        ? oldBoundBox.height
        : newBoundBox.height;
    return {
      rotation: newBoundBox.rotation,
      x: Math.max(leftLimit, newBoundBox.x),
      y: Math.max(topLimit, newBoundBox.y),
      width,
      height,
    } as Box;
  };

  return (
    <Hotkeys
      keyName="space"
      onKeyUp={(keyName: string) => hotKey[keyName + 'Up']()}
      onKeyDown={(keyName: string) => hotKey[keyName + 'Down']()}
    >
      <div className="annotator-stage_container">
        <KonvaStage
          name={STAGE_NAME}
          className="stage"
          width={width}
          height={height - STAGE_HEIGHT_ADJ}
          ref={stageRef}
          onMouseDown={onStageMouseDown}
          onMouseMove={onMousemove}
          onMouseUp={onMouseup}
          onContextMenu={onStageContextMenu}
          onWheel={onWheel}
          scaleX={1}
          scaleY={1}
          onMouseOver={onStageMouseOver}
          style={{cursor}}
        >
          <Layer
            ref={imageLayerRef}
            scaleX={imageLayer.scale}
            scaleY={imageLayer.scale}
            x={imageLayer.x}
            y={imageLayer.y}
            draggable={isGrabbing}
            onDragMove={onImageDrag}
          >
            <Image
              src={src}
              x={((iWidth - width) / 2) * getScaleDir(width, iWidth)}
              y={((iHeight - height) / 2) * getScaleDir(height, iHeight)}
              onStartLoading={() => {
                setIsImageBusy(true);
                setIsImageError(false);
              }}
              onFinishLoading={() => setIsImageBusy(false)}
              onError={() => setIsImageError(true)}
            />
          </Layer>
          <Layer ref={layerRef}>
            {/* Box Annotations */}
            {!isImageBusy &&
              scaledBoxLabels?.map((label: BoxLabelProps, i) => (
                <Label
                  key={i}
                  {...label}
                  onClick={e => onLabelClick(e, label)}
                  onContextMenu={e => onLabelContextMenu(e, label)}
                  selected={selectedLabels.includes(label.id)}
                  isTransforming={isTransforming && selectedLabels.includes(label.id)}
                  onDrag={props => onLabelDrag(label, props)}
                  onTooltip={onTooltip}
                  onDelete={() => onDeleteAnnotation(label.id, 'RECT')}
                  limit={{
                    x: imageLayer.x + x * imageLayer.scale,
                    y: imageLayer.y + y * imageLayer.scale,
                    width: iWidth * imageLayer.scale,
                    height: iHeight * imageLayer.scale,
                  }}
                />
              ))}
            {/* Polygon */}
            {!isImageBusy &&
              localPolyLabel?.map((poly: PolyLabelProps, i) => (
                <Polygon
                  key={i}
                  data={poly}
                  onComplete={onPolyLabelCreated}
                  width={width}
                  height={height}
                  selected={selectedLabels.includes(poly.id)}
                  onContextMenu={e => onLabelContextMenu(e, poly)}
                  onClick={e => onLabelClick(e, poly)}
                  onDelete={() => onDeleteAnnotation(poly.id, 'POLY')}
                  complete={poly.complete}
                />
              ))}
            {/* Lines */}
            {!isImageBusy &&
              localLineLabels?.map((line: LineLabelProps, i) => (
                <Line
                  key={i}
                  data={line}
                  onComplete={onLineLabelCreated}
                  width={width}
                  height={height}
                  selected={selectedLabels.includes(line.id)}
                  onContextMenu={e => onLabelContextMenu(e, line)}
                  onClick={e => onLabelClick(e, line)}
                  onDelete={() => onDeleteAnnotation(line.id, 'LINE')}
                  complete={line.complete}
                />
              ))}

            {/* Box Resizer */}
            <Transformer
              ref={transformer}
              {...TRANSFORMER}
              onTransformStart={onTransformStart}
              onTransformEnd={onTransformEnd}
              onMouseOver={() => setCursor('default')}
              boundBoxFunc={boundBoxFunc}
            />
            {/* Selection rectangle */}
            <Rect {...SELECTION_BOX} {...selector} />
          </Layer>
        </KonvaStage>
        <Tooltip title={tooltip.title} open={!!tooltip.title}>
          <div
            className="annotator-tooltip_placeholder"
            style={{top: tooltip.y, left: tooltip.x}}
          />
        </Tooltip>
        {isImageBusy && (
          <div
            className="annotator-progress_placeholder"
            style={{top: height / 2 - LOADER_SIZE / 2, left: width / 2 - LOADER_SIZE / 2}}
          >
            <CircularProgress size={LOADER_SIZE} />
          </div>
        )}
        {isImageError && (
          <div className="annotator-image_broken">
            <BrokenImage style={{width: 48, height: 48, color: grey[300]}} />
          </div>
        )}
      </div>
    </Hotkeys>
  );
}

export default Annotator;
