import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {injectIntl, IntlShape} from 'react-intl';
import {connect, ConnectedComponent} from 'react-redux';
import {Link} from 'react-router-dom';
import StagePanel from './StagePanel';
import FormButton from '../../../base-components/StudioButton/FormButton';
import StageAnimation from '../StageAnimation/StageAnimation';
import ImportModelAction from '../StageActions/ImportModelAction';
import ModelConversionAction from '../StageActions/ModelConversionAction';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import ModelActionButton from '../../../base-components/StudioButton/ModelActionButton';
import TaskInfoProps from '../../../model/taskInfoPropTypes';
import {toast} from '../../../base-components/StudioToast';
import {StudioPopover} from '../../../base-components/StudioPopover';
import URL_SETTINGS from '../../../config/url';
import {StageAssistantForm} from '../../StageAssistantForm';
import {toggleModelDetailsPanel} from '../../../store/model';
import {actions} from '../../../store/marketplace/add-model';
import {openPublishForm} from '../../../store/marketplace/configure';
import {RootState} from '../../../store';
import {getViewProject} from '../../../store/view';
import {getProjectModel} from '../../../store/project';
import {TaskInfo} from '../../../types/metadata/TaskInfo';
import {Model} from '../../../types/model/Model';
import {Answer} from '../../../types/prompts/Answer';
import {Prompts} from '../../../types/prompts/Prompts';
import {Stage} from '../../../types/stage/Stage';
import ModelBuildAction from '../StageActions/ModelBuildAction';
import ModelFilePrepareAction from '../StageActions/ModelFilePrepareAction';
import ModelCreateHelperFileAction from '../StageActions/ModelCreateHelperFileAction';
import {TerminateButton} from './TerminateButton';
import {TaskType} from '../../../types/task/TaskType';
import {PanelBadge} from './PanelBadge';
import {getIsDiabledEditModelStage} from '../../../store/view';
import {ConfirmDialog} from '../../../base-components/StudioConfirmDialog';
import './ModelPanel.scss';

type ProjectViewState = {
  form: {
    templateId: string;
    dataset: string;
    model: string;
    isp: string;
    name: string;
    description: string;
  };
  newFormOpen: boolean;
  editFormOpen: boolean;
  disableEditModel: boolean;
  disableEditData: boolean;
  terminate: {
    dialogOpen: boolean;
    enabled: boolean;
    title: string;
    message: string;
  };
};

type ModelAction = 'import' | 'build' | 'retrain';

type InstanceRefObject<T> = T extends new (...args: any) => any
  ? React.RefObject<InstanceType<T>>
  : React.RefObject<T>;

type RefObject<T> = T extends ConnectedComponent<infer C, any>
  ? InstanceRefObject<C>
  : InstanceRefObject<T>;

export type ModelPanelProps = {
  assistantPanel: RefObject<typeof AssistantPanel>;
  createHelperFileAction: RefObject<typeof ModelCreateHelperFileAction>;
  importModelAction: RefObject<typeof ImportModelAction>;
  intl: IntlShape;
  model?: Model;
  modelActionInProgress: ModelAction;
  modelBuildAction: RefObject<typeof ModelBuildAction>;
  modelConversionAction: RefObject<typeof ModelConversionAction>;
  onFinish(taskType: TaskType): void;
  openPublishForm: typeof openPublishForm;
  openTerminateDialog(request: ProjectViewState['terminate']): void;
  prepareModelFileAction: RefObject<typeof ModelFilePrepareAction>;
  projectId: string;
  projectView: ProjectViewState;
  readyForDataSplit: boolean;
  readyForModelBuilding: boolean;
  setDialogIsOpen: typeof actions['setIsOpen'];
  setModelActionInProgress(modelAction: ModelAction): void;
  stage: Stage;
  taskProgress: TaskInfo;
  toggleModelDetailsPanel(projectId: string): void;
};

type ModelPanelState = {
  conversionStatus: boolean;
  tooltipMessage: string | null;
  displayDeleteModelDialog: boolean;
  importTypeForm: {
    header: string;
    prompts: Prompts[];
    defaults: Record<string, Answer[]>;
    tooltip: string;
  };
  progressTooltips: Record<ModelAction, string>;
};

class ModelPanel extends StagePanel {
  // @ts-ignore
  props: ModelPanelProps;
  stageAnimation: StageAnimation;
  state: ModelPanelState;

  constructor(props: ModelPanelProps) {
    super(props);
    this.state = {
      conversionStatus: false,
      tooltipMessage: null,
      displayDeleteModelDialog: false,
      importTypeForm: {
        header: 'Select an option',
        prompts: [],
        defaults: {},
        tooltip: props.intl.formatMessage({id: 'model.defaultTooltip'}),
      },
      progressTooltips: {
        import: props.intl.formatMessage({id: 'model.importTooltip'}),
        build:
          props.model?.modelPurpose === 'Classification'
            ? props.intl.formatMessage({
                id: 'model.buildClassificationTooltip',
              })
            : props.intl.formatMessage({
                id: 'model.buildDetectionTooltip',
              }),
        retrain: props.intl.formatMessage({id: 'model.retrainTooltip'}),
      },
    };

    this.stageAnimation = new StageAnimation();
    this.getTooltip = this.getTooltip.bind(this);
    this.showAssistant = this.showAssistant.bind(this);
    this.hideAssistant = this.hideAssistant.bind(this);
    this.showInitialPrompt = this.showInitialPrompt.bind(this);
    this.importModel = this.importModel.bind(this);
    this.buildModel = this.buildModel.bind(this);
    this.initiateModelImport = this.initiateModelImport.bind(this);
    this.initiateModelBuild = this.initiateModelBuild.bind(this);
    this.terminateModelImport = this.terminateModelImport.bind(this);
    this.terminateModelBuild = this.terminateModelBuild.bind(this);
    this.fetchModelImportType = this.fetchModelImportType.bind(this);
    this.handleInputTypeSelection = this.handleInputTypeSelection.bind(this);
  }

  componentDidMount() {
    this.fetchModelImportType();
  }

  getTooltip() {
    return this.state.progressTooltips[this.props.modelActionInProgress];
  }

  showAssistant(form: React.ReactNode) {
    let elem = document.getElementById('IMPORT_MODEL-container');
    const stageAnimation = new StageAnimation();
    if (elem && !elem.classList.contains('active')) {
      stageAnimation.updateStages(elem);
    }
    let current = this.props.assistantPanel.current;
    if (current) {
      current.display(form, 'IMPORT_MODEL', null);
    }
  }

  hideAssistant() {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.hide();
    }
  }

  showInitialPrompt() {
    const {
      importTypeForm: {prompts, defaults, header, tooltip},
    } = this.state;

    let alert;
    let disabledOptions = {};
    let updatedPrompts = prompts;

    if (!this.props.readyForModelBuilding) {
      alert = 'The "Build for Me" option is only available after adding a dataset.';
    }

    if (this.props.readyForDataSplit) {
      alert =
        'The “Build for Me” option is only available after dataset is split, and training subset is there.';
    }

    if (!this.props.readyForModelBuilding || this.props.readyForDataSplit) {
      // TODO: Temporary hardcoded change until the backend supports disabled radio options.
      // Remove once backend supports it
      disabledOptions = {Build: true};
      updatedPrompts = [...prompts];
      const promptIndex = updatedPrompts.findIndex(
        prompt => prompt.key === 'Model.Import.Method'
      );
      if (promptIndex !== -1) {
        // @ts-ignore
        updatedPrompts[promptIndex] = {...updatedPrompts[promptIndex], disabledOptions};
      }
    }

    this.showAssistant(
      <StageAssistantForm
        title={header}
        tooltip={tooltip}
        prompts={updatedPrompts}
        defaults={defaults}
        alert={alert}
        onSubmit={this.handleInputTypeSelection}
        onCancel={this.hideAssistant}
        notifyType="ADD_MODEL_INITIAL"
      />
    );
  }

  handleInputTypeSelection(answers: Answer[]) {
    const actions: Record<string, (e?: Event) => void> = {
      LOAD: this.importModel,
      BUILD: this.buildModel,
    };
    const selection = answers[0]?.value;
    if (selection) {
      const action = actions[selection.toUpperCase()];
      action && action();
    }
  }

  importModel(e?: Event) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.initiateModelImport();
    this.hideAssistant();
  }

  buildModel(e?: Event) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.initiateModelBuild();
    this.hideAssistant();
  }

  getInactiveContent() {
    return (
      <div className="inactive-content">
        <div className="inactive-container">
          <FormButton
            buttonRole="secondary"
            value="Add Model"
            onClick={this.showInitialPrompt}
          />
        </div>
      </div>
    );
  }

  getActiveContent() {
    return (
      <div
        className="active-content import-model-panel"
        data-testid="IMPORT_MODEL-panel__active"
        id="IMPORT_MODEL-panel__active"
      >
        <div className="active-container">
          <FormButton
            buttonRole="primary"
            value="Add Model"
            onClick={this.showInitialPrompt}
          />
        </div>
      </div>
    );
  }

  getInProgressContent() {
    const captions = {
      import: 'Importing Model',
      build: 'Training Model',
      retrain: 'Retraining Model',
    };

    const terminators = {
      import: this.terminateModelImport,
      build: this.terminateModelBuild,
    };

    const toolTip = this.getTooltip();

    return (
      <div className="stage-panel-status">
        <div
          className="in-progress-content import-model-panel"
          data-testid="IMPORT_MODEL-panel__in-progress"
          id="IMPORT_MODEL-panel__in-progress"
        >
          <div className="import-model-in-progress-indicator" />
          <div className="panel-import-model-active" />
          <div className="panel-title">
            <span className="panel-caption">
              {captions[this.props.modelActionInProgress]}
              <br />
              <span className="panel-description" id="model-action-progress">
                Checking for completeness
              </span>
            </span>
            {toolTip && (
              <StudioPopover
                infoIconClass="stage-panel__tooltip"
                infoMessage={this.getTooltip()}
              />
            )}
          </div>
        </div>

        <div
          className="in-progress-content import-model-panel stage-panel-buttons"
          data-testid={`${this.props.stage.type}-action-buttons`}
          id={`${this.props.stage.type}-action-buttons`}
        >
          <TerminateButton
            onClick={() => {
              this.props.openTerminateDialog({
                // @ts-ignore
                terminate: terminators[this.props.modelActionInProgress],
                title: this.props.intl.formatMessage({id: 'terminate.importTitle'}),
                message: this.props.intl.formatMessage({
                  id: 'terminate.importContent',
                }),
              });
            }}
          />
        </div>
      </div>
    );
  }

  getFinishedContent() {
    return (
      <>
        <PanelBadge text={this.props?.stage?.warning} />
        <div className="stage-panel-status">
          <div
            className="finished-content import-model-panel"
            data-testid="IMPORT_MODEL-panel__finished"
            id="IMPORT_MODEL-panel__finished"
          >
            {this.state.conversionStatus || this.props.model?.type === 'ONNX' ? (
              <Link
                className="panel-import-model-active"
                to={`/project/${this.props.projectId}/model/view`}
              />
            ) : (
              <div className="panel-import-model-active" />
            )}
            <div className="panel-title">
              <span className="panel-caption" id="model-name-finished">
                {this.props.stage.entityName}
              </span>
            </div>
          </div>

          <div
            className="finished-content import-model-panel stage-panel-buttons"
            data-testid={`${this.props.stage.type}-action-buttons`}
            id={`${this.props.stage.type}-action-buttons`}
          >
            <ModelActionButton
              value="share"
              onClick={() =>
                this.props.openPublishForm({
                  projectId: this.props.projectId,
                  resourceId: this.props.model?.id,
                  // @ts-ignore
                  purpose: 'MODEL_STORAGE',
                })
              }
            />
            {this.state.conversionStatus && (
              <>
                <ModelActionButton
                  value="details"
                  onClick={() => this.props.toggleModelDetailsPanel(this.props.projectId)}
                />
              </>
            )}
            <ModelActionButton
              value="replace"
              onClick={this.showInitialPrompt}
              disabled={this.props.projectView.disableEditData}
            />
            <ModelActionButton
              value="delete"
              disabled={this.props.projectView.disableEditData}
              onClick={() => this.setState({displayDeleteModelDialog: true})}
            />
          </div>
        </div>
        <ConfirmDialog
          type="confirm"
          title={this.props.intl.formatMessage({id: 'model.deleteModel.dialogTitle'})}
          message={this.props.intl.formatMessage({
            id: 'model.deleteModel.dialogMessage',
          })}
          submitLabel={this.props.intl.formatMessage({
            id: 'model.deleteModel.dialogOk',
          })}
          open={this.state.displayDeleteModelDialog}
          onClose={() => this.setState({displayDeleteModelDialog: false})}
          onOk={() => {
            if (this.props.importModelAction.current) {
              this.props.importModelAction.current.deleteModel();
            }

            this.setState({displayDeleteModelDialog: false});
          }}
        />
      </>
    );
  }

  getOverlay() {
    return (
      <div
        className={
          'stage-panel-overlay' + (this.props.stage.active ? ' active' : ' inactive')
        }
        onClick={this.activatePanel}
        id={this.props.stage.type + '-panel-overlay'}
        data-testid={'import-model-overlay'}
      />
    );
  }

  initiateModelImport() {
    let elem = document.getElementById(this.props.stage.type + '-container');
    const stageAnimation = new StageAnimation();
    if (elem && !elem.classList.contains('active')) {
      stageAnimation.updateStages(elem);
    }
    this.props.setModelActionInProgress('import');
    this.props.setDialogIsOpen({isOpen: true});
  }

  initiateModelBuild() {
    let elem = document.getElementById(this.props.stage.type + '-container');
    const stageAnimation = new StageAnimation();
    if (elem && !elem.classList.contains('active')) {
      stageAnimation.updateStages(elem);
    }
    let current = this.props.modelBuildAction.current;
    if (current) {
      this.props.setModelActionInProgress('build');
      current.buildModel();
    }
  }

  async fetchModelImportType() {
    try {
      const {data} = await axios.get(URL_SETTINGS.MODEL_IMPORT_SELECT, {
        params: {projectId: this.props.projectId},
      });
      const {
        body: {header},
        prompts = [],
        defaults = {},
      } = data;
      this.setState((state: ModelPanelState) => ({
        importTypeForm: {...state.importTypeForm, header, prompts, defaults},
      }));
    } catch (e) {}
  }

  async terminateModelImport() {
    const importModelAction = this.props.importModelAction.current;
    const modelConversionAction = this.props.modelConversionAction.current;
    const prepareModelFileAction = this.props.prepareModelFileAction.current;
    const createHelperFileAction = this.props.createHelperFileAction.current;

    if (prepareModelFileAction && prepareModelFileAction.isPreparing()) {
      await prepareModelFileAction.terminateImport();
      toast.success({position: 'top', subtitle: 'User terminated model conversion'});
    } else if (modelConversionAction && modelConversionAction.isConverting()) {
      await modelConversionAction.terminateImport();
      toast.success({position: 'top', subtitle: 'User terminated model conversion'});
    } else if (createHelperFileAction && createHelperFileAction.isPreparing()) {
      await createHelperFileAction.terminateImport();
      toast.success({position: 'top', subtitle: 'User terminated model import'});
    } else if (importModelAction) {
      await importModelAction.terminateImport();
      toast.success({position: 'top', subtitle: 'User terminated model import'});
    }
  }

  async terminateModelBuild() {
    if (this.props.modelConversionAction.current?.isConverting()) {
      await this.props.modelConversionAction.current.terminateImport();
      toast.success({position: 'top', subtitle: 'User terminated model conversion'});
    } else if (this.props.modelBuildAction.current) {
      await this.props.modelBuildAction.current.terminateBuild();
      toast.success({position: 'top', subtitle: 'User terminated model build'});
    }
  }

  update(stage: Stage) {
    if (stage && stage.type === 'OPTIMIZE_MODEL') {
      if (stage.status === 'LOCKED') {
        this.setState({conversionStatus: false});
      } else {
        this.setState({conversionStatus: true});
      }
    }
  }
}

ModelPanel.propTypes = {
  // @ts-ignore
  projectId: PropTypes.string.isRequired,
  importModelAction: PropTypes.oneOfType([
    // @ts-ignore
    PropTypes.shape({current: PropTypes.instanceOf(ImportModelAction)}),
    // @ts-ignore
    PropTypes.shape({current: null}),
  ]).isRequired,
  modelConversionAction: PropTypes.oneOfType([
    // @ts-ignore
    PropTypes.shape({current: PropTypes.instanceOf(ModelConversionAction)}),
    // @ts-ignore
    PropTypes.shape({current: null}),
  ]).isRequired,
  assistantPanel: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)}),
  ]).isRequired,
  onFinish: PropTypes.func.isRequired,
  taskProgress: TaskInfoProps,
};

const mapDispatchToProps = {
  toggleModelDetailsPanel,
  setDialogIsOpen: actions.setIsOpen,
  openPublishForm,
};

const mapStateToProps = (state: RootState) => ({
  projectView: getViewProject(state),
  model: getProjectModel(state),
  shouldDisabledViewStage: getIsDiabledEditModelStage(state),
});

export default injectIntl(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ModelPanel),
  {forwardRef: true}
);
