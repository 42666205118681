import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {CircularProgress, Fade} from '@material-ui/core';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import './StudioImageThumbnail.scss';

type StudioImageThumbnailProps = {
  src: string;
  width: number;
  height: number;
  position: number;
  onClick?: (e: any) => void | void;
};

export const StudioImageThumbnail = ({
  src,
  width,
  height,
  position,
  onClick,
  ...rest
}: StudioImageThumbnailProps) => {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const imageLoader = new Image();
    imageLoader.src = src;
    imageLoader.onerror = () => setIsError(true);
  }, [src]);

  return (
    <div
      onClick={onClick}
      className={cn('studio-image-thumb__container')}
      style={{
        width,
        height,
      }}
    >
      {!isError && (
        <div className="studio-image-thumb__progress_container">
          <CircularProgress size={width / 3} className="studio-image-thumb__progress" />
        </div>
      )}
      <Fade in={isError} timeout={1000}>
        <BrokenImageIcon
          className="studio-image-thumb__broken-image"
          style={{
            width,
            height,
          }}
        />
      </Fade>
      <div
        className="studio-image-thumb__image"
        style={{
          width,
          height,
          backgroundImage: `url('${src}')`, // Add the secondary blank image below
          backgroundPosition: `0 -${position * height}px`,
          backgroundRepeat: 'no-repeat',
          ...rest,
        }}
      />
    </div>
  );
};
