import React from 'react';
import {PropTypes} from 'prop-types';
import './ImageViewer.scss';

export const ImageViewer = ({children}) => {
  return <div className="image-viewer">{children}</div>;
};

ImageViewer.propTypes = {
  children: PropTypes.any,
};

ImageViewer.defaultProps = {
  children: [],
};
