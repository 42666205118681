import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {
  DeploymentNodesRequest,
  getDeploymentNodes,
} from '../../api/deployment/DeploymentNodes';
import URL from '../../config/url';
import {
  ApplicationInfo,
  ApplicationsResponse,
} from '../../types/deployment/ApplicationsResponse';
import {ProductionNode} from '../../api/deployment/DeploymentNodes';
import {
  StreamingDeviceResponse,
  StreamingDevice,
} from '../../types/deployment/StreamingDeviceResponse';
import {useSelector} from 'react-redux';
import {RootState} from '../index';

type CreateDeploymentState = {
  applications: Array<ApplicationInfo>;
  selectedApplication: ApplicationInfo | undefined;
  nodes: Array<ProductionNode>;
  externalStreamingDevices: Array<StreamingDevice>;
};

const initialState: CreateDeploymentState = {
  applications: [],
  selectedApplication: undefined,
  nodes: [],
  externalStreamingDevices: [],
};

export const selectApplication = createAsyncThunk(
  'create-deployment/select-application',
  async (selectedApplicationId: string) => {
    const applications: Array<ApplicationInfo> = useSelector(
      (state: RootState) => state.createDeployment.applications
    );
    const selectedApplication = applications.find(
      app => app.id === selectedApplicationId
    );
    return selectedApplication;
  }
);
export const fetchApplications = createAsyncThunk(
  'create-deployment/fetch-applications',
  async (projectId: string) => {
    const {data} = await axios.get<ApplicationsResponse>(URL.APPLICATIONS_GET, {
      params: {projectId},
    });
    return data.body;
  }
);

export const fetchNodes = createAsyncThunk(
  'create-deployment/fetch-nodes',
  async (params?: DeploymentNodesRequest | undefined) => {
    const res = await getDeploymentNodes(params);
    return res.body;
  }
);

export const fetchExternalStreamingDevices = createAsyncThunk(
  'create-deployment/fetch-devices',
  async () => {
    const {data} = await axios.get<StreamingDeviceResponse>(URL.DEVICE_LIST);
    return data.body;
  }
);

export const cancelDeployment = createAsyncThunk(
  'create-deployment/cancel-deployment',
  async (projectId: string) => {
    await axios.get(URL.OPERATION_CANCEL('DEPLOY_APPLICATION'), {
      params: {projectId},
    });
  }
);

const createDeploymentSlice = createSlice({
  name: 'create-deployment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchApplications.fulfilled, (state, action) => {
        state.applications = action.payload;
      })
      .addCase(fetchNodes.fulfilled, (state, action) => {
        state.nodes = action.payload;
      })
      .addCase(fetchExternalStreamingDevices.fulfilled, (state, action) => {
        state.externalStreamingDevices = action.payload;
      });
  },
});

export const {reducer: createDeploymentReducer, actions} = createDeploymentSlice;
