import React from 'react';
import {toast} from '../../../base-components/StudioToast';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import cn from 'classnames';
import './CopyableURL.scss';
import {ContentCopyIcon} from '../../ContentCopyIcon/ContentCopyIcon';

type CopyableURLProps = {
  path: string;
  className?: string;
};

export const CopyableURL = ({path, className}: CopyableURLProps) => {
  const buildURL = (path: string) => {
    const {hostname, protocol, port} = window.location;
    if (port) {
      return `${protocol}//${hostname}:${port}${path}`;
    }
    return `${protocol}//${hostname}${path}`;
  };

  const url = buildURL(path);

  return (
    <div className={cn('copyable-url', className)}>
      <a href={url} target="_blank" rel="noreferrer">
        {url}
      </a>
      <CopyToClipboard
        text={url}
        onCopy={() => {
          toast.success('Copied to clipboard!');
        }}
      >
        <span>
          <ContentCopyIcon />
        </span>
      </CopyToClipboard>
    </div>
  );
};
