import {configureStore} from '@reduxjs/toolkit';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {useDispatch} from 'react-redux';
import {combineReducers} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {all} from 'redux-saga/effects';
import {buildReducer} from './build';
import {configReducer} from './config';
import {consoleReducer} from './console';
import {dataPrepareReducer} from './dataPrepare';
import {iatReducer} from './iat';
import {ispReducer} from './isp';
import {addDataDialogReducer} from './marketplace/add-data';
import {addISPDialogReducer} from './marketplace/add-isp';
import {addModelDialogReducer} from './marketplace/add-model';
import {addPPDialogReducer} from './marketplace/add-pp';
import {configureMarketplaceReducer} from './marketplace/configure';
import {marketplaceReducer} from './marketplace/integrations';
import {solutionsReducer} from './marketplace/solutions';
import {modelReducer} from './model';
import {sessionStoreReducer} from './onnx/session-store';
import {createDeploymentReducer} from './prodDeployments/create-deployment';
import {deploymentDashboardReducer} from './prodDeployments/dashboard';
import {retrainReducer} from './prodDeployments/retrain';
import {projectReducer, watchProjectFromTemplate} from './project';
import {projectListReducer} from './projectList';
import {projectOpStatusReducer} from './projectOpStatus';
import {stagesReducer} from './stages';
import {statusCheckerReducer} from './statusChecker';
import {userReducer} from './user/user';
import {viewReducer} from './view';

export const history = createBrowserHistory();
const routerReducer = connectRouter(history);

const rootReducer = combineReducers({
  config: configReducer,
  stages: stagesReducer,
  project: projectReducer,
  projectOpStatus: projectOpStatusReducer,
  projectList: projectListReducer,
  router: routerReducer,
  view: viewReducer,
  model: modelReducer,
  isp: ispReducer,
  console: consoleReducer,
  statusChecker: statusCheckerReducer,
  build: buildReducer,
  dataPrepare: dataPrepareReducer,
  marketplace: marketplaceReducer,
  addDataDialog: addDataDialogReducer,
  addModelDialog: addModelDialogReducer,
  addISPDialog: addISPDialogReducer,
  addPPDialog: addPPDialogReducer,
  configureMarketplace: configureMarketplaceReducer,
  solutions: solutionsReducer,
  createDeployment: createDeploymentReducer,
  deploymentDashboard: deploymentDashboardReducer,
  deploymentRetrainDialog: retrainReducer,
  user: userReducer,
  iat: iatReducer,
  onnxSessionStore: sessionStoreReducer,
});

function* rootSaga() {
  yield all([watchProjectFromTemplate()]);
}

const sagaMiddleware = createSagaMiddleware();

const middlewares = [routerMiddleware(history), sagaMiddleware];

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
