import React, {useEffect, useMemo, useRef, useState} from 'react';
import {LabelProps, LabelPropsEvent} from './types';
import {Rect, Group, Text, Path} from 'react-konva';
import {
  BOX_LABEL_DEFAULT,
  DELETE_ICON,
  SELECTED_FILL,
  TAG_FILL,
  TAG_ICON,
  TAG_SELECTED_FILL,
  TEXT_COLOR,
  TEXT_LABEL,
  TEXT_SELECTED_COLOR,
} from './const';
import {KonvaEventObject} from 'konva/types/Node';
import Konva from 'konva';
import {KonvaPointerEvent} from 'konva/types/PointerEvents';

const LABEL_HEIGHT = 25;
const TAG_WIDTH = 20;
const CHAR_WIDTH = 10;
const DELETE_ICON_WIDTH = 23;
const SPACER = 15;
export const LABEL_NAME = 'label';

type TagProps = {
  xBuffer?: number;
  yBuffer?: number;
};

export function Tag(props: TagProps & LabelProps & LabelPropsEvent) {
  const {
    // Reference object center
    // Tag will be ceter-aligned based on this coordinates
    x = 0,
    y = 0,
    xBuffer = 0,
    yBuffer = 0,

    label = '',
    selected = false,
    isTransforming = false,
    id = '',
    onChange = () => {},
    onTooltip = () => {},
    onDelete = () => {},
    onContextMenu = () => {},
    style = BOX_LABEL_DEFAULT,
  } = props;

  const [isHover, setIsHover] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const groupRef = useRef<Konva.Group>(null);

  useMemo(() => {
    // Calculate width based on label/text width
    const textWidth = CHAR_WIDTH * label.length;
    const labelWidth = textWidth + DELETE_ICON_WIDTH + TAG_WIDTH + SPACER + xBuffer;
    // const labelWidth = textWidth + DELETE_ICON_WIDTH + TAG_WIDTH;
    setWidth(labelWidth);
    setHeight(LABEL_HEIGHT + yBuffer);
  }, [label, xBuffer, yBuffer]);

  useEffect(() => {
    if (!selected) {
      const {x = 0, y = 0} = groupRef?.current?.attrs;
      onChange({id, x, y});
    }
  }, [selected, onChange, id]);

  const onMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
    const group = groupRef.current;
    onTooltip({title: ''});
    onTooltip({
      show: true,
      refObj: {
        x: group?.x() || 0,
        y: e.evt.offsetY,
        width,
        height: LABEL_HEIGHT,
      },
      title: label,
    });
    onMouse(true);
  };

  const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
    onTooltip({title: ''});
    onMouse(false);
  };

  const onMouse = (isHovering: boolean) => {
    setIsHover(isHovering);
  };

  const onLocalContextMenu = (e: KonvaPointerEvent) => {
    e.evt.preventDefault();
    onContextMenu && onContextMenu(e);
  };

  return (
    <Group
      ref={groupRef}
      name={LABEL_NAME}
      id={id}
      x={x - width / 2}
      y={y - height / 2}
      onClick={props.onClick}
      onContextMenu={onLocalContextMenu}
    >
      {!!label.length && (
        <>
          <Rect
            width={width}
            height={LABEL_HEIGHT}
            y={height - LABEL_HEIGHT}
            x={1}
            fill={selected || isHover ? SELECTED_FILL : style?.stroke}
            opacity={0.75}
          />
          {/* Label  */}
          <Text
            x={TAG_WIDTH}
            y={height - LABEL_HEIGHT}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            text={label}
            width={width - TAG_WIDTH}
            height={LABEL_HEIGHT}
            {...TEXT_LABEL}
            fill={selected || isHover ? TEXT_SELECTED_COLOR : TEXT_COLOR}
          />
          {/* Tag Icon */}
          <Path
            {...TAG_ICON}
            y={height - LABEL_HEIGHT + 3}
            fill={selected || isHover ? TAG_SELECTED_FILL : TAG_FILL}
          />
        </>
      )}
      {/* Delete Icon */}
      {selected && !isTransforming && (
        <Path
          {...DELETE_ICON}
          y={height - LABEL_HEIGHT + 2}
          x={width - 23}
          onClick={() => onDelete(id)}
          visible={!isTransforming}
        />
      )}
    </Group>
  );
}

export default Tag;
