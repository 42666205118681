import {Accordion, AccordionSummary, IconButton} from '@material-ui/core';
import {ExpandMore, RotateLeft} from '@material-ui/icons';
import {Form, Formik, useFormikContext} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import useSWR, {mutate} from 'swr';
import * as Yup from 'yup';
import {getApplicationPostProcessors} from '../../../api/postprocessor/GetApplicationPostProcessors';
import {getPreProcessorParameters} from '../../../api/postprocessor/GetPreProcessorParameters';
import {
  resetParameters,
  UpdateParameterRequest,
  updateParameters,
} from '../../../api/postprocessor/UpdateParameters';
import {Parameter} from '../../../types/model/framework/Parameter';
import {
  getParameterValidationRule,
  ParameterField,
} from '../../PrePostProcessing/Fields/ParameterField';
import {useIntl} from 'react-intl';
import {StudioTooltip} from '../../../base-components/StudioTooltip';
import {toast} from '../../../base-components/StudioToast';

type RealtimePostProcessingParametersProps = {
  projectId: string;
  applicationId: string;
  onChange: (postProcessors: Array<Parameter>) => void;
  className?: string;
  emptyMessage?: React.ReactNode;
  renderField?: ParametersPartialFormProps['renderField'];
};

export const RealtimePostProcessingParameters = ({
  projectId,
  applicationId,
  onChange,
  emptyMessage,
  renderField,
  className,
}: RealtimePostProcessingParametersProps) => {
  const {data: postProcessorsResponse} = useSWR(
    projectId && applicationId
      ? [projectId, applicationId, '/postprocessor/get/application']
      : null,
    () => getApplicationPostProcessors({projectId, applicationId}),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const postProcessors = postProcessorsResponse?.body || [];

  const params = postProcessors.flatMap(postProcessor => {
    const paramList =
      postProcessor.parameters?.map(parameter => ({
        ...parameter,
        name: getUniqueParamKey(postProcessor.id, parameter.name),
      })) || [];
    return paramList;
  });

  const handleChange: ParametersPartialFormProps['onChange'] = values => {
    const updatedParameters = postProcessors.flatMap(postProcessor => {
      const paramList =
        postProcessor.parameters
          ?.filter(param => param.parameterType === 'REALTIME')
          .map(parameter => ({
            ...parameter,
            value: values[
              getUniqueParamKey(postProcessor.id, parameter.name)
            ]?.toString(),
          })) || [];
      return paramList;
    });
    onChange(updatedParameters);
  };

  if (params?.length) {
    const realtimeParams = params.filter(param => param.parameterType === 'REALTIME');
    const initialValues = getInitialValues(params);
    const validationSchema = buildValidationSchema(params);

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={() => {}}
      >
        <Form className={className}>
          <ParametersPartialForm
            params={realtimeParams}
            onChange={handleChange}
            renderField={renderField}
          />
        </Form>
      </Formik>
    );
  } else {
    return <div>{emptyMessage}</div> || null;
  }
};

type RealtimePreProcessingParametersProps = {
  projectId: string;
  applicationId: string;
  onChange: (preProcessors: Array<Parameter>) => void;
  className?: string;
  emptyMessage?: React.ReactNode;
  renderField?: ParametersPartialFormProps['renderField'];
};

export const RealtimePreProcessingParameters = ({
  projectId,
  applicationId,
  onChange,
  emptyMessage,
  renderField,
  className,
}: RealtimePreProcessingParametersProps) => {
  const {data} = useSWR(
    projectId && applicationId
      ? [projectId, applicationId, '/preprocessor/parameters/get']
      : null,
    () => getPreProcessorParameters({projectId, applicationId})
  );

  const params =
    data?.body
      ?.filter(param => param.parameterType === 'REALTIME')
      .map(param => ({
        ...param,
      })) || [];

  const handleChange: ParametersPartialFormProps['onChange'] = values => {
    const updatedParameters =
      params.map(parameter => ({
        ...parameter,
        value: values[parameter.name]?.toString(),
      })) || [];
    onChange(updatedParameters);
  };

  if (params?.length) {
    const initialValues = getInitialValues(params);
    const validationSchema = buildValidationSchema(params);

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={() => {}}
      >
        <Form className={className}>
          <ParametersPartialForm
            params={params}
            onChange={handleChange}
            renderField={renderField}
          />
        </Form>
      </Formik>
    );
  } else {
    return <div>{emptyMessage}</div> || null;
  }
};

type DeploymentPostProcessingParametersProps = {
  projectId: string;
  applicationId: string;
  onChange: (parameters: Array<UpdateParameterRequest>) => void;
  titleMessageId: string;
  wrapperClassName: string;
  persistChanges?: boolean;
  onValidityChange?: ParametersPartialFormProps['onValidityChange'];
  disabledForm?: boolean;
  renderField?: ParametersPartialFormProps['renderField'];
  className?: string;
};

export const getDeployParamsKey = (projectId: string, applicationId: string) => [
  projectId,
  applicationId,
  '/postprocessor/get/application',
];

export const DeploymentPostProcessingParameters = ({
  projectId,
  applicationId,
  onValidityChange,
  onChange,
  titleMessageId,
  wrapperClassName,
  persistChanges,
  renderField,
  disabledForm,
  className,
}: DeploymentPostProcessingParametersProps) => {
  const intl = useIntl();
  const {data: postProcessorsResponse} = useSWR(
    projectId && applicationId ? getDeployParamsKey(projectId, applicationId) : null,
    () => getApplicationPostProcessors({projectId, applicationId}),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const postProcessors = postProcessorsResponse?.body || [];

  const type = 'DEPLOYMENT';
  const params = postProcessors
    .flatMap(postProcessor => {
      const paramList =
        postProcessor.parameters?.map(parameter => ({
          ...parameter,
          name: getUniqueParamKey(postProcessor.id, parameter.name),
          readOnly: disabledForm,
        })) || [];
      return paramList;
    })
    .filter(param => param.parameterType === type);

  const prepareUpdateParameterRequests = (
    values: Record<string, string | number | undefined>
  ): UpdateParameterRequest[] => {
    return postProcessors.flatMap(postProcessor => {
      const {postProcessorType, executionContainer, parameters} = postProcessor;
      if (postProcessorType && executionContainer && parameters) {
        return parameters
          .filter(param => param.parameterType === type)
          .filter(x => values[getUniqueParamKey(postProcessor.id, x.name)])
          .map<UpdateParameterRequest>(parameter => ({
            parameterType: 'POST_PROCESSOR',
            postProcessorType,
            executionContainer,
            name: parameter.name,
            value: (
              values[getUniqueParamKey(postProcessor.id, parameter.name)] ?? ''
            ).toString(),
          }));
      }
      return [];
    });
  };

  if (params?.length) {
    const initialValues = getInitialValues(params);
    const validationSchema = buildValidationSchema(params);
    return (
      <TestParametersAccordion
        title={intl.formatMessage({id: titleMessageId})}
        className={wrapperClassName}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={() => {}}
        >
          <div className={className}>
            <ParametersPartialForm
              params={params}
              onChange={p => onChange(prepareUpdateParameterRequests(p))}
              renderField={renderField}
              onValidityChange={onValidityChange}
              onFieldUpdateFinished={p => {
                if (!persistChanges) {
                  return;
                }
                const parameters = prepareUpdateParameterRequests(p);
                updateParameters({
                  projectId,
                  applicationId,
                  parameters,
                })
                  .then(() => mutate(getDeployParamsKey(projectId, applicationId)))
                  .catch(e => toast.error(e));
              }}
            />
          </div>
        </Formik>
        {persistChanges && (
          <StudioTooltip title={intl.formatMessage({id: 'deployments.resetParameters'})}>
            <span>
              <IconButton
                className={wrapperClassName + '_reset'}
                onClick={() =>
                  resetParameters(projectId, applicationId, false, true)
                    .then(() => mutate(getDeployParamsKey(projectId, applicationId)))
                    .catch(e => toast.error(e))
                }
                disabled={!params.some(x => x.originalValue)}
              >
                <RotateLeft />
              </IconButton>
            </span>
          </StudioTooltip>
        )}
      </TestParametersAccordion>
    );
  } else {
    return <></>;
  }
};

type PreProcessingParametersProps = {
  projectId: string;
  applicationId: string;
  onChange: (preProcessingParameters: Array<UpdateParameterRequest>) => void;
  persistChanges?: boolean;
  onValidityChange?: ParametersPartialFormProps['onValidityChange'];
  titleMessageId: string;
  wrapperClassName: string;
  renderField?: ParametersPartialFormProps['renderField'];
  disabledForm?: boolean;
};

export const PreProcessingParameters = ({
  projectId,
  applicationId,
  onValidityChange,
  onChange,
  titleMessageId,
  persistChanges,
  wrapperClassName,
  renderField,
  disabledForm,
}: PreProcessingParametersProps) => {
  const intl = useIntl();
  const {data} = useSWR(
    projectId && applicationId
      ? [projectId, applicationId, '/preprocessor/parameters/get']
      : null,
    () => getPreProcessorParameters({projectId, applicationId}),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const params = data?.body
    ?.filter(param => param.parameterType === 'DEPLOYMENT')
    .map(param => ({
      ...param,
      readOnly: disabledForm,
    }));

  const prepareUpdateParameterRequests = (
    values: Record<string, string | number | undefined>
  ): UpdateParameterRequest[] => {
    return (
      params?.map<UpdateParameterRequest>(param => ({
        parameterType: 'PRE_PROCESSOR',
        name: param.name,
        value: (values[param.name] ?? '').toString(),
      })) || []
    );
  };

  if (params?.length) {
    const initialValues = getInitialValues(params);
    const validationSchema = buildValidationSchema(params);
    return (
      <TestParametersAccordion
        title={intl.formatMessage({id: titleMessageId})}
        className={wrapperClassName}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={() => {}}
        >
          <ParametersPartialForm
            params={params}
            onChange={p => onChange(prepareUpdateParameterRequests(p))}
            renderField={renderField}
            onValidityChange={onValidityChange}
            onFieldUpdateFinished={p => {
              if (!persistChanges) {
                return;
              }
              const parameters = prepareUpdateParameterRequests(p);
              updateParameters({
                projectId,
                applicationId,
                parameters,
              })
                .then(() =>
                  mutate([projectId, applicationId, '/preprocessor/parameters/get'])
                )
                .catch(e => toast.error(e));
            }}
          />
        </Formik>
        {persistChanges && (
          <StudioTooltip title={intl.formatMessage({id: 'deployments.resetParameters'})}>
            <span>
              <IconButton
                className={wrapperClassName + '_reset'}
                onClick={() =>
                  resetParameters(projectId, applicationId, true, false)
                    .then(() =>
                      mutate([projectId, applicationId, '/preprocessor/parameters/get'])
                    )
                    .catch(e => toast.error(e))
                }
                disabled={!params.some(x => x.originalValue)}
              >
                <RotateLeft />
              </IconButton>
            </span>
          </StudioTooltip>
        )}
      </TestParametersAccordion>
    );
  } else {
    return <></>;
  }
};

type ParametersPartialFormProps = {
  params: Array<Parameter & {readOnly?: boolean}>;
  onChange: (values: Record<string, string | number | undefined>) => void;
  onValidityChange?: (isValid: boolean) => void;
  renderField?: (field: React.ReactNode, parameter: Parameter) => React.ReactNode;
  onFieldUpdateFinished?: (updated: Record<string, string | number | undefined>) => void;
};

const ParametersPartialForm = ({
  params,
  onChange,
  onValidityChange,
  renderField = (field: React.ReactNode) => field,
  onFieldUpdateFinished,
}: ParametersPartialFormProps) => {
  const {values, isValid, validateForm} = useFormikContext<
    Record<string, string | number | undefined>
  >();
  const onChangeRef = useRef(onChange);
  const onValidityChangeRef = useRef(onValidityChange);
  const [prev, setPrev] = useState(values);

  useEffect(() => {
    onValidityChangeRef.current?.(isValid);
  }, [isValid]);

  useEffect(() => {
    validateForm().then(errors => {
      const numErrors = Object.entries(errors).length;
      if (numErrors === 0) {
        onChangeRef.current?.(values);
      }
    });
  }, [values, validateForm]);

  const focusOut = () => {
    if (!isValid) {
      return;
    }
    const updated = {} as Record<string, string | number | undefined>;
    for (const key in values) {
      if (prev[key] !== values[key]) {
        updated[key] = values[key];
      }
    }
    if (Object.keys(updated).length === 0) {
      return;
    }
    onFieldUpdateFinished?.(updated);
    setPrev(values);
  };

  return (
    <div onBlur={focusOut}>
      {params?.map(param => {
        const {readOnly, ...parameter} = param;
        return renderField(
          <ParameterField
            key={param.name}
            parameter={parameter}
            fieldName={avoidFormikNesting(parameter.name)}
            disabled={Boolean(readOnly)}
          />,
          param
        );
      })}
    </div>
  );
};

const buildValidationSchema = (params: Array<Parameter>) => {
  return Yup.object(
    params.reduce<Record<string, Yup.Schema<unknown>>>((acc, curr) => {
      const rule = getParameterValidationRule(curr);
      if (rule) {
        acc[curr.name] = rule.label(curr.displayName || curr.name).required();
      }
      return acc;
    }, {})
  );
};

const getInitialValues = (params: Array<Parameter>) =>
  params.reduce<Record<string, string | number | undefined>>((acc, curr) => {
    if (curr.dataType === 'FLOATING_POINT_NUMBER' || curr.dataType === 'INTEGER') {
      acc[curr.name] = Number(curr.value);
    } else {
      acc[curr.name] = curr.value;
    }
    return acc;
  }, {});

type TestParametersAccordionProps = {
  className?: string;
  title: string;
  children: React.ReactNode;
  expanded?: boolean | undefined;
};

export const TestParametersAccordion = ({
  className,
  title,
  children,
  expanded,
}: TestParametersAccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Accordion
      className={className}
      expanded={expanded === undefined ? isExpanded : expanded}
      elevation={0}
      onChange={() => setIsExpanded(curr => !curr)}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>{title}</AccordionSummary>
      {children}
    </Accordion>
  );
};

const getUniqueParamKey = (id: string, name: string) => `${id}.${name}`;
const avoidFormikNesting = (name: string) => `['${name}']`;
