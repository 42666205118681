import React from 'react';
import {LinearProgress, withStyles} from '@material-ui/core';
import cn from 'classnames';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import './SplitChart.scss';

type SplitChartProps = {
  training: number;
  validation: number;
  testing: number;
  className?: string;
};

export function SplitChart({className, training, validation, testing}: SplitChartProps) {
  return (
    <div className={cn('split-chart', className)}>
      <Row id="testCompare.trainingSplit" count={training} />
      <Row id="testCompare.validationSplit" count={validation} />
      <Row id="testCompare.testingSplit" count={testing} />
    </div>
  );

  function Row({
    count,
    ...messageProps
  }: {count: number} & React.ComponentProps<typeof FormattedMessage>) {
    const percentage = count / (training + validation + testing + 1e-15);
    return (
      <div className="split-chart__row">
        <Heading {...messageProps} />
        <div className="split-chart__distribution">
          <Bar value={percentage} />
          <div className="split-chart__stats">
            {/* eslint-disable-next-line react/style-prop-object */}
            <FormattedNumber style="percent" value={percentage} /> ({count})
          </div>
        </div>
      </div>
    );
  }
}

function Bar({value}: {value: number}) {
  return (
    <BorderLinearProgress
      className="split-chart__bar"
      variant="determinate"
      value={100 * value}
    />
  );
}

function Heading({
  className,
  ...messageProps
}: {className?: string} & React.ComponentProps<typeof FormattedMessage>) {
  return (
    <Item className={cn('split-chart__heading', className)}>
      <FormattedMessage {...messageProps} />
    </Item>
  );
}

function Item({children, className}: {children?: React.ReactNode; className?: string}) {
  return <div className={cn('split-chart__item', className)}>{children}</div>;
}

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: 'var(--greyscale2)',
  },
  bar: {
    borderRadius: 0,
    backgroundColor: 'var(--primary3)',
  },
})(LinearProgress);
