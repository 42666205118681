import {Typography} from '@material-ui/core';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useBuild} from './useBuild';

export function BuildVersion() {
  const build = useBuild();

  return (
    <>
      <Typography variant="subtitle1">
        <FormattedMessage
          id="about.version"
          values={{version: build.studioVersion, type: build.versionType}}
        />
      </Typography>
      <Typography variant="subtitle2">
        Build:{' '}
        <a href={build.buildUrl}>
          <FormattedMessage
            id="about.build"
            values={{number: build.buildNumber, date: build.buildTime}}
          />
        </a>
      </Typography>
      <Typography variant="subtitle2">
        <FormattedMessage
          id="about.commit"
          values={{hash: build.lastGitCommitHash, date: build.lastGitCommitTime}}
        />
      </Typography>
      <Typography variant="subtitle2">{build.lastGitCommitMessage}</Typography>
      <Typography variant="subtitle2">
        <FormattedMessage id="about.branch" values={{name: build.targetBranch}} />
      </Typography>
    </>
  );
}
