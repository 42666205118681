import Axios from 'axios';

export async function getPreProcessorFile(
  projectId: string,
  id: string,
  fileName: string
) {
  return Axios.request<Blob>({
    method: 'GET',
    url: `/studio/preprocessor/view/${projectId}/${id}/${fileName}?cachebust=${Date.now()}`,
    responseType: 'blob',
  });
}
