import React, {Component} from 'react';
import PropTypes from 'prop-types';

class PropertiesIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="16px"
          height="15px"
          viewBox="0 0 16 15"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <path
              d="M4.5,16.6796875 L9.5,16.6796875 L9.5,18.3203125 L4.5,18.3203125 L4.5,16.6796875 Z M4.5,6.6796875 L12.8203125,6.6796875 L12.8203125,8.3203125 L4.5,8.3203125 L4.5,6.6796875 Z M12.8203125,20 L11.1796875,20 L11.1796875,15 L12.8203125,15 L12.8203125,16.6796875 L19.5,16.6796875 L19.5,18.3203125 L12.8203125,18.3203125 L12.8203125,20 Z M7.8203125,10 L9.5,10 L9.5,15 L7.8203125,15 L7.8203125,13.3203125 L4.5,13.3203125 L4.5,11.6796875 L7.8203125,11.6796875 L7.8203125,10 Z M19.5,13.3203125 L11.1796875,13.3203125 L11.1796875,11.6796875 L19.5,11.6796875 L19.5,13.3203125 Z M14.5,10 L14.5,5 L16.1796875,5 L16.1796875,6.6796875 L19.5,6.6796875 L19.5,8.3203125 L16.1796875,8.3203125 L16.1796875,10 L14.5,10 Z"
              id="properties-icon"
            ></path>
          </defs>
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-848.000000, -4867.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 4071.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-properties-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#properties-icon"></use>
                      </mask>
                      <use
                        id="properties"
                        fill="currentColor"
                        xlinkHref="#properties-icon"
                      ></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

PropertiesIcon.propTypes = {
  className: PropTypes.string,
};

export default PropertiesIcon;
