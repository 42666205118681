import {Box, BoxProps} from '@material-ui/core';
import React, {useCallback} from 'react';
import './ImageCard.scss';
import {ImageCard} from './ImageCard';

export type CachedImageCardProps = BoxProps & {
  imageFunc: (w?: number, h?: number) => string;
  width?: number;
  height?: number;
  points: number[][][];
};

export const CachedImageCard = ({
  imageFunc,
  width,
  height,
  points,
}: CachedImageCardProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getImage = useCallback(imageFunc, []);

  return (
    <Box className="image-card-horizontal">
      <Box>
        <ImageCard
          imageFunc={getImage}
          width={width}
          height={height}
          points={points}
          isPopup={true}
        />
      </Box>
    </Box>
  );
};
