import React from 'react';
import {useHistory, useParams} from 'react-router';
import useSWR from 'swr';
import {toast} from '../../../base-components/StudioToast';
import {useIntl} from 'react-intl';
import {
  preProcessorNew,
  PreProcessorNewRequest,
  PreProcessorNewUrl,
} from '../../../api/preprocessor/PreProcessorNew';
import {
  preProcessorUpdate,
  PreProcessorUpdateRequest,
} from '../../../api/preprocessor/PreProcessorUpdate';
import {PreProcessingForm} from './PreProcessingForm';

export const CreatePreProcessor = () => {
  const {projectId} = useParams<{projectId: string}>();
  const requestParams: PreProcessorNewRequest = {
    projectId,
    transformationType: 'SCRIPT',
  };
  const cacheKey = PreProcessorNewUrl(requestParams);
  const {data} = useSWR(cacheKey, () => preProcessorNew(requestParams), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const intl = useIntl();
  const history = useHistory();

  const handleCancel = () => {
    history.push(`/project/${projectId}/upload`);
  };

  const handleSubmit = async (values: PreProcessorUpdateRequest) => {
    try {
      const resp = await preProcessorUpdate(values);
      if (!resp.errors?.length) {
        toast.success(intl.formatMessage({id: 'createPreProcessor.success'}));
        history.push(`/project/${projectId}/upload`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <PreProcessingForm
      title={intl.formatMessage({id: 'createPreProcessor.header'})}
      projectId={projectId}
      template={data?.body}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    />
  );
};
