import {Card, CardContent} from '@material-ui/core';
import React from 'react';
import {useIntl} from 'react-intl';
import {RuntimeStatistic} from '../RuntimeStatistic';
import './RuntimeSummary.scss';

export type RuntimeSummaryProps = {
  name: string | null;
  status: string | null;
  fps: number | null;
  latency: number | null;
  accuracy: number | null;
  runtime: number | null;
  componentRef: any;
};

export function RuntimeSummary({
  name,
  status,
  fps,
  latency,
  accuracy,
  runtime,
  componentRef,
}: RuntimeSummaryProps) {
  const intl = useIntl();

  return (
    <div className="runtime-summary" ref={componentRef}>
      <Card className="runtime-summary__card" elevation={0}>
        <CardContent className="runtime-summary__card-content">
          <div className="runtime-summary__info">
            <div className="runtime-summary__name">{name}</div>
            <div className="runtime-summary__status">{status}</div>
          </div>
          <div className="runtime-summary__stats">
            {fps !== null && (
              <RuntimeStatistic
                title="FPS"
                value={intl.formatNumber(fps, {
                  style: 'decimal',
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                })}
              />
            )}
            {latency !== null && (
              <RuntimeStatistic
                title="Latency"
                value={intl.formatNumber(latency, {
                  style: 'unit',
                  unit: 'millisecond',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              />
            )}
            {accuracy !== null && (
              <RuntimeStatistic
                title="Accuracy"
                value={intl.formatNumber(accuracy, {
                  style: 'unit',
                  unit: 'percent',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              />
            )}
            {runtime !== null && (
              <RuntimeStatistic
                title="Runtime"
                value={intl.formatNumber(runtime, {
                  style: 'unit',
                  unit: 'second',
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                })}
              />
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
