import {useEffect, useState} from 'react';
import {GRID_ITEM_DIMENSIONS, LIST_ITEM_DIMENSIONS} from './MarketplaceExplorer';

type ResponsivePageSizeConfig = {
  containerRef: React.RefObject<HTMLElement>;
  mode: 'grid' | 'list';
};

const MIN_PAGE_SIZE = 2;

export const useResponsivePageSize = ({containerRef, mode}: ResponsivePageSizeConfig) => {
  const [pageSize, setPageSize] = useState<number | null>(null);

  const container = containerRef.current;

  useEffect(() => {
    if (container) {
      const updatePageSize = () => {
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        if (mode === 'grid') {
          const {width, height, marginX, marginY} = GRID_ITEM_DIMENSIONS;
          const itemsPerRow = Math.floor(containerWidth / (width + marginX));
          const rowsPerPage = Math.round(containerHeight / (height + marginY));
          const newPageSize = Math.max(itemsPerRow * rowsPerPage, MIN_PAGE_SIZE);
          setPageSize(newPageSize);
        } else if (mode === 'list') {
          const {height} = LIST_ITEM_DIMENSIONS;
          const newPageSize = Math.max(
            // remove one item to account for table header
            Math.floor(containerHeight / height) - 1,
            MIN_PAGE_SIZE
          );
          setPageSize(newPageSize);
        }
      };

      updatePageSize();
      const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        entries.forEach(entry => {
          if (entry.target === container) {
            updatePageSize();
          }
        });
      });

      resizeObserver.observe(container, {box: 'border-box'});
      return () => {
        resizeObserver.unobserve(container);
      };
    }
  }, [container, mode]);

  return pageSize;
};
