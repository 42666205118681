import * as yup from 'yup';
import ERROR from '../../config/error';

const validationSchema = yup.object({
  name: yup.string().required(ERROR.FIELD_REQUIRED()),
  input_width: yup
    .number()
    .min(1, ERROR.MIN_NUMBER)
    .max(3840, ERROR.MAX_NUMBER)
    .required(ERROR.FIELD_REQUIRED()),
  input_height: yup
    .number()
    .min(1, ERROR.MIN_NUMBER)
    .max(2160, ERROR.MAX_NUMBER)
    .required(ERROR.FIELD_REQUIRED()),
  bpp: yup.string().required(ERROR.FIELD_REQUIRED()),
  input_color_space: yup.string().required(ERROR.FIELD_REQUIRED()),
});

export default validationSchema;
