import {Grid, IconButton} from '@material-ui/core';
import {KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import FormButton from '../../base-components/StudioButton/FormButton';
import {ConfigureMarketplace} from '../ConfigureMarketplace';
import {StudioTab, StudioTabPanel, StudioTabs} from '../../base-components/StudioTab';
import {toast} from '../../base-components/StudioToast';
import URL from '../../config/url';
import {injectIntl} from 'react-intl';
import {ServerVersion} from './ServerVersion';
import {AdditionalInformation} from './AdditionalInformation';
import {StudioModeConsumer} from '../StudioMode/StudioModeProvider';
import './SettingsView.scss';
import {UserDetails} from './UserDetails';

class SettingsView extends React.Component {
  constructor(props) {
    super(props);

    this.getAccountContent = this.getAccountContent.bind(this);
    this.redirectToLogin = this.redirectToLogin.bind(this);
    this.logout = this.logout.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleAdditionalInfoDropDown = this.handleAdditionalInfoDropDown.bind(this);
    this.state = {
      selectedTabIndex: 0,
      additionalInfoDropDown: false,
    };
  }

  getAccountContent() {
    return (
      <div className="settings-options">
        <div className="login-button">
          <FormButton
            buttonRole="primary"
            value={this.props.intl.formatMessage({id: 'form.login'})}
            onClick={this.redirectToLogin}
          />
        </div>
        <div className="logout-button">
          <FormButton
            buttonRole="primary"
            value={this.props.intl.formatMessage({id: 'form.logout'})}
            onClick={this.logout}
          />
        </div>
      </div>
    );
  }

  redirectToLogin() {
    this.props.history.push('/login');
  }

  logout() {
    axios
      .get(URL.LOGOUT)
      .then(({data}) => this.finishLogout(data))
      .catch(err => toast.error(`Error logging out: ${err}`));
  }

  finishLogout(result) {
    if (!result.errors) {
      window.location.replace('/login');
    }
  }

  handleTabChange(event, newValue) {
    this.setState({
      selectedTabIndex: newValue,
    });
  }

  handleAdditionalInfoDropDown() {
    this.setState({
      additionalInfoDropDown: !this.state.additionalInfoDropDown,
    });
  }

  render() {
    const {selectedTabIndex, additionalInfoDropDown} = this.state;

    return (
      <StudioModeConsumer>
        {({mode}) => (
          <Grid container>
            <Grid item xs={2} />
            <Grid item xs={8} className="settings__tabs">
              <StudioTabs
                value={this.state.selectedTabIndex}
                onChange={this.handleTabChange}
                data-testid="settings-tabs"
              >
                <StudioTab
                  label={this.props.intl.formatMessage({id: 'settings.accountTab'})}
                  className="settings__tab"
                ></StudioTab>
                {mode !== 'LITE' && (
                  <StudioTab
                    label={this.props.intl.formatMessage({
                      id: 'settings.marketplacesTab',
                    })}
                    className="settings__tab"
                  ></StudioTab>
                )}
              </StudioTabs>
              <StudioTabPanel
                value={selectedTabIndex}
                index={0}
                onChange={this.handleTabChange}
              >
                <div className="settings-options">
                  <div className="settings-options-body">
                    <h5 className={'MuiTypography-root'}>User Information</h5>
                    <UserDetails />
                  </div>
                  <div className="settings-options-body">
                    <h5 className={'MuiTypography-root'}>System Information</h5>
                    <ServerVersion />
                  </div>
                  <div className="settings-options-body">
                    <div className="additional-info-head">
                      <h5 className={'MuiTypography-root'}>Additional Information</h5>
                      <IconButton
                        type="button"
                        onClick={this.handleAdditionalInfoDropDown}
                        tabIndex={-1}
                      >
                        {additionalInfoDropDown ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </div>
                    <AdditionalInformation show={additionalInfoDropDown} />
                  </div>
                  <div className="logout-button">
                    <FormButton
                      buttonRole="primary"
                      value={this.props.intl.formatMessage({id: 'form.logout'})}
                      onClick={this.logout}
                    />
                  </div>
                </div>
              </StudioTabPanel>
              <StudioTabPanel value={selectedTabIndex} index={1}>
                <div className="settings-options">
                  <ConfigureMarketplace />
                </div>
              </StudioTabPanel>
            </Grid>
            <Grid item xs={2}>
              <FormButton
                buttonRole="secondary"
                value={this.props.intl.formatMessage({id: 'form.returnToPreviousPage'})}
                customClass="settings__return"
                onClick={() => this.props.history.goBack(-1)}
              />
            </Grid>
          </Grid>
        )}
      </StudioModeConsumer>
    );
  }
}

SettingsView.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
};

export default injectIntl(SettingsView);
