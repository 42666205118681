import JSZip from 'jszip';
import {Button, IconButton} from '@material-ui/core';
import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';
import React, {useEffect, useState} from 'react';
import {StudioPopover} from '../../../base-components/StudioPopover';
import {StudioSelect} from '../../../base-components/StudioSelect';
import {StudioTextField} from '../../../base-components/StudioTextField';
import {UpdatePostProcessorRequest} from '../../../types/postprocessor/UpdatePostProcessorRequest';
import {ScriptUploadField} from '../Fields/ScriptUploadField';
import {ExecutionContainer} from '../../../types/model/framework/ExecutionContainer';
import * as Yup from 'yup';
import {useIntl} from 'react-intl';
import {PostProcessorResponse} from '../../../types/postprocessor/PostProcessorResponse';
import {getPostProcessorFile} from '../../../api/postprocessor/ViewPostProcessorFile';
import {
  parameterArrayValidation,
  ParameterFieldArray,
} from '../Fields/ParameterFieldArray';
import '../index.scss';
import {GetApp as GetAppIcon} from '@material-ui/icons';
import {StudioTooltip} from '../../../base-components/StudioTooltip';
import {PRE_AND_POST_PROCESSORS_SETTINGS} from '../../../config/constants';

const defaultTemplate: PostProcessorResponse = {
  id: '',
  name: '',
  description: '',
  projectId: '',
  applicationId: '',
  version: 0,
  studioVersion: 0,
  filePaths: [],
  parameters: [],
  executionContainerOptions: {},
  applicationTypeOptions: {},
  postProcessorTypeOptions: {},
  useDefaultFile: false,
};

const validationSchema = Yup.object({
  id: Yup.string().required('Required'),
  projectId: Yup.string().required('Required'),
  postProcessorType: Yup.string().required('Required'),
  executionContainer: Yup.string().required('Required'),
  applicationType: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  filePaths: Yup.object()
    .test('file-exists', 'Required', files => Boolean(files && Object.keys(files).length))
    .test('file-not-empty', 'File is empty', files =>
      Boolean(!files || Object.values(files)?.[0])
    ),
  parameters: parameterArrayValidation,
});

type PostProcessingFormProps = {
  title: string;
  projectId: string;
  returnText?: string;
  viewOnly?: boolean;
  template?: PostProcessorResponse;
  filePaths?: Record<string, string>;
  onSubmit: (values: UpdatePostProcessorRequest) => Promise<void>;
  onCancel: () => void;
};

const DEFAULT_FILE_PATHS = {};

export const PostProcessingForm = ({
  title,
  projectId,
  returnText,
  viewOnly,
  template,
  filePaths = DEFAULT_FILE_PATHS,
  onSubmit,
  onCancel,
}: PostProcessingFormProps) => {
  const intl = useIntl();
  const [scripts, setScripts] = useState<File[]>([]);

  const {
    executionContainerOptions,
    applicationTypeOptions,
    postProcessorTypeOptions,
    id,
    postProcessorType,
    executionContainer,
    applicationType,
    useDefaultFile,
    name,
    description,
    parameters,
  } = template || defaultTemplate;

  const initialValues: UpdatePostProcessorRequest = {
    id,
    postProcessorType,
    executionContainer,
    applicationType,
    name,
    description,
    parameters,
    projectId,
    filePaths,
  };

  async function downloadAllFiles() {
    const archive = new JSZip();

    for (let file of scripts) {
      const fileContent = file.slice(0, file.size, file.type);
      await archive.file(file.name, fileContent);
    }
    const zipBlob = await archive.generateAsync({type: 'blob'});
    const fileName = 'post-processor.zip';
    const url = URL.createObjectURL(
      new File([zipBlob], fileName, {type: 'application/zip'})
    );
    const a = document.createElement('a');
    a.style.display = 'none';
    a.setAttribute('download', fileName);
    a.setAttribute('href', url);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  const resetOnExecutionContainerChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    values: UpdatePostProcessorRequest,
    form: FormikProps<UpdatePostProcessorRequest>
  ) => {
    const newExecutionContainer = e.target.value as ExecutionContainer;
    if (
      values.executionContainer &&
      values.executionContainer !== newExecutionContainer
    ) {
      form.setFieldValue('filePaths', {});
    }
  };

  useEffect(() => {
    getFiles();

    async function getFiles() {
      const allFiles = [];

      for (const [key] of Object.entries(filePaths)) {
        const content = await getPostProcessorFile({
          applicationId: id,
          projectId,
          fileName: key,
          postProcessorType,
          executionContainer,
        });
        allFiles.push(new File([content.data], key));
      }

      setScripts(allFiles);
    }
  }, [filePaths, id, projectId, postProcessorType, executionContainer]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({values, isSubmitting}) => (
        <div className="processing-form">
          <header>
            <Button
              className="processing-form__back"
              variant="contained"
              disableElevation
              onClick={onCancel}
            >
              {returnText ??
                intl.formatMessage({
                  id: 'form.returnToStage',
                })}
            </Button>
            <div>
              <StudioTooltip
                title={intl.formatMessage({
                  id: 'project.postprocessor.downloadFile',
                })}
              >
                <IconButton onClick={downloadAllFiles}>
                  <GetAppIcon />
                </IconButton>
              </StudioTooltip>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                form="post-processor-form"
                disabled={isSubmitting || viewOnly}
                disableElevation
              >
                {intl.formatMessage({
                  id: isSubmitting ? 'form.submitting' : 'form.submit',
                })}
              </Button>
            </div>
          </header>
          <div className="processing-form__main">
            <h2>{title}</h2>
            <Form id="post-processor-form" noValidate>
              <div className="processing-form__form-top">
                <Field name="name">
                  {({field, meta}: FieldProps<UpdatePostProcessorRequest['name']>) => (
                    <div>
                      <StudioTextField
                        className="processing-form__field"
                        label={
                          <React.Fragment>
                            {intl.formatMessage({id: 'sharedProcessor.form.name'})}
                            <StudioPopover
                              infoMessage={intl.formatMessage({
                                id: 'editPostProcessor.name.tooltip',
                              })}
                            />
                          </React.Fragment>
                        }
                        placeholder={intl.formatMessage({
                          id: 'sharedProcessor.form.name.placeholder',
                        })}
                        InputProps={field}
                        error={Boolean(meta.error && meta.touched)}
                        helperText={meta.error && meta.touched ? meta.error : ''}
                        disabled={viewOnly}
                        inputProps={{
                          maxLength: PRE_AND_POST_PROCESSORS_SETTINGS.name.maxLength,
                        }}
                      />
                    </div>
                  )}
                </Field>
                <Field name="description">
                  {({field}: FieldProps<UpdatePostProcessorRequest['description']>) => (
                    <StudioTextField
                      className="processing-form__field"
                      label={
                        <React.Fragment>
                          {intl.formatMessage({id: 'sharedProcessor.form.description'})}
                          <StudioPopover
                            infoMessage={intl.formatMessage({
                              id: 'editPostProcessor.description.tooltip',
                            })}
                          />
                        </React.Fragment>
                      }
                      placeholder={intl.formatMessage({
                        id: 'sharedProcessor.form.description.placeholder',
                      })}
                      InputProps={field}
                      disabled={viewOnly}
                      multiline={PRE_AND_POST_PROCESSORS_SETTINGS.description.multiline}
                      inputProps={{
                        className: PRE_AND_POST_PROCESSORS_SETTINGS.description.className,
                        maxLength: PRE_AND_POST_PROCESSORS_SETTINGS.description.maxLength,
                      }}
                    />
                  )}
                </Field>
                <Field name="applicationType">
                  {({
                    field,
                    meta,
                  }: FieldProps<UpdatePostProcessorRequest['applicationType']>) => (
                    <StudioSelect
                      className="processing-form__field"
                      label={intl.formatMessage({
                        id: 'sharedProcessor.form.appType',
                      })}
                      additionalLabel={
                        <StudioPopover
                          infoMessage={intl.formatMessage({
                            id: 'editPostProcessor.applicationType.tooltip',
                          })}
                        />
                      }
                      placeholder={intl.formatMessage({
                        id: 'sharedProcessor.form.appType.placeholder',
                      })}
                      options={applicationTypeOptions}
                      disablePlaceholder
                      SelectProps={{...field, value: field.value || ''}}
                      error={Boolean(meta.error && meta.touched)}
                      helperText={meta.error && meta.touched ? meta.error : ''}
                      disabled={viewOnly}
                    />
                  )}
                </Field>
                <Field name="postProcessorType">
                  {({
                    field,
                    meta,
                  }: FieldProps<UpdatePostProcessorRequest['postProcessorType']>) => (
                    <StudioSelect
                      className="processing-form__field"
                      label={intl.formatMessage({
                        id: 'sharedProcessor.form.ppType',
                      })}
                      additionalLabel={
                        <StudioPopover
                          infoMessage={intl.formatMessage({
                            id: 'editPostProcessor.postProcessorType.tooltip',
                          })}
                        />
                      }
                      placeholder={intl.formatMessage({
                        id: 'sharedProcessor.form.ppType.placeholder',
                      })}
                      options={postProcessorTypeOptions}
                      disablePlaceholder
                      SelectProps={{...field, value: field.value || ''}}
                      error={Boolean(meta.error && meta.touched)}
                      helperText={meta.error && meta.touched ? meta.error : ''}
                      disabled={viewOnly}
                    />
                  )}
                </Field>
                <Field name="executionContainer">
                  {({
                    field,
                    form,
                    meta,
                  }: FieldProps<UpdatePostProcessorRequest['executionContainer']>) => (
                    <StudioSelect
                      className="processing-form__field"
                      label={intl.formatMessage({
                        id: 'sharedProcessor.form.target',
                      })}
                      additionalLabel={
                        <StudioPopover
                          infoMessage={intl.formatMessage({
                            id: 'editPostProcessor.executionContainer.tooltip',
                          })}
                        />
                      }
                      placeholder={intl.formatMessage({
                        id: 'sharedProcessor.form.target.placeholder',
                      })}
                      options={executionContainerOptions}
                      SelectProps={{
                        ...field,
                        value: field.value || '',
                        onChange: e => {
                          resetOnExecutionContainerChange(e, values, form);
                          field.onChange(e);
                        },
                      }}
                      disablePlaceholder
                      error={Boolean(meta.error && meta.touched)}
                      helperText={meta.error && meta.touched ? meta.error : ''}
                      disabled={viewOnly}
                    />
                  )}
                </Field>
                <Field name="filePaths">
                  {({
                    field,
                    form,
                    meta,
                  }: FieldProps<UpdatePostProcessorRequest['filePaths']>) => {
                    const handleFileUpload = async (files: File[]) => {
                      const newFilePaths: Record<string, string> = {};

                      for (const file of files) {
                        newFilePaths[file.name] = await file.text();
                      }
                      setScripts(files);
                      form.setFieldValue('filePaths', newFilePaths);
                      form.setFieldTouched('filePaths');
                      form.validateField('filePaths');
                    };

                    const {
                      executionContainer,
                      postProcessorType,
                      applicationType,
                    } = values;
                    return executionContainer ? (
                      <ScriptUploadField
                        label={intl.formatMessage({id: 'sharedProcessor.form.script'})}
                        tooltip={intl.formatMessage({
                          id: 'editPostProcessor.filePaths.tooltip',
                        })}
                        className="processing-form__field"
                        filePaths={scripts}
                        onChange={handleFileUpload}
                        errorText={meta.error && meta.touched ? meta.error : ''}
                        disabled={viewOnly}
                        allowDefault={
                          executionContainer === 'BROWSER' &&
                          postProcessorType === 'APPLICATION' &&
                          applicationType === 'OPEN_VX_APP'
                        }
                        useDefault={useDefaultFile}
                      />
                    ) : null;
                  }}
                </Field>
              </div>
              <hr />
              <h5>{intl.formatMessage({id: 'postProcessor.params.header'})}</h5>
              <ParameterFieldArray
                name="parameters"
                parameters={values.parameters || []}
                disabled={viewOnly}
              />
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};
