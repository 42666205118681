import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import './DataDriftRawSnapshots.scss';
import {ImageCardDetail, ImageCardHorizontal} from '../../TestDeployView/ImageCard';
import {Pagination} from '@material-ui/lab';
import axios from 'axios';
import {
  DataDriftRawSnapshot,
  DataResult,
  DeploymentDataDriftItem,
  DeploymentDataDriftResponse,
} from '../../../types/deployment/DataDriftRawSnapshot';
import URL from '../../../config/url';
import {BoundingBoxType} from '../../../base-components/StudioOverlay/StudioCanvasOverlay';
import {DetectionResult, Image, ImageData} from '../../TestDeployView/DetectionResult';

const IMAGE_WIDTH = 125;
const IMAGE_HEIGHT = 125;
const IMAGE_TITLE_HEIGHT = 20;

export const DataDriftRawSnapshots = ({
  projectId,
  deploymentId,
  modelPurpose,
}: {
  projectId: string;
  deploymentId: string;
  modelPurpose: string | undefined;
}) => {
  const intl = useIntl();
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [currentDetections, setCurrentDetections] = useState<Array<Image>>();
  const [pageData, setPageData] = useState<DeploymentDataDriftResponse>();
  const [totalCount, setTotalCount] = useState(0);
  const [selectedDataDriftItem, setSelectedDataDriftItem] = React.useState<
    DeploymentDataDriftItem
  >();

  const PAGE_SIZE = 15;

  const convertVectorToStats = (vector: Object): Array<DataResult> => {
    const stats: Array<DataResult> = new Array<DataResult>();
    Object.entries(vector).map(value => {
      const label = value[0];
      const progress = value[1];
      stats.push({
        label: label,
        progress: progress,
      });
      return stats;
    });
    return stats;
  };

  const getImageUrl = useCallback(
    (item: DeploymentDataDriftItem): string => {
      return `/studio/images/data_drift/getbyfilenames?projectId=${projectId}&resourceId=${deploymentId}&filenames=${item.fileName}`;
    },
    [deploymentId, projectId]
  );

  const fetcherDataDriftData = useCallback(async () => {
    const url = URL.DEPLOYMENT_DATA_DRIFT({
      deploymentId: deploymentId,
      pageSize: PAGE_SIZE,
      pageIndex: pageIndex,
    });
    const {data} = await axios.get(url);

    if (data.body.count) {
      setTotalCount(data.body.count);
    }

    const items: Array<DeploymentDataDriftItem> = new Array<DeploymentDataDriftItem>();

    data.body.entries.map((snapshot: DataDriftRawSnapshot) => {
      const basename = snapshot.inputFramePath.split('/').reverse()[0];
      if (snapshot.modelOutputJson) {
        const vector = JSON.parse(snapshot.modelOutputJson);
        let item: DeploymentDataDriftItem = {
          fileName: basename,
          timestamp: snapshot.timestamp,
        };

        if (modelPurpose === 'Classification') {
          const results: Array<DataResult> = new Array<DataResult>();
          convertVectorToStats(vector).forEach(result => {
            results.push(result);
          });
          item = {...item, classifications: results};
        } else if (modelPurpose === 'Detection') {
          const results: Array<BoundingBoxType> = new Array<BoundingBoxType>();
          vector.forEach((result: BoundingBoxType) => {
            results.push(result);
          });
          item = {...item, detections: results};
        }
        items.push(item);
      }
      return null;
    });

    data.body.items = items;
    setPageData(data.body);
    if (items.length > 0 && !selectedDataDriftItem) {
      setSelectedDataDriftItem(items[0]);
    }
  }, [modelPurpose, deploymentId, pageIndex, selectedDataDriftItem]);

  useEffect(() => {
    fetcherDataDriftData();
  }, [pageIndex, fetcherDataDriftData]);

  useEffect(() => {
    if (modelPurpose === 'Detection') {
      const images: Array<Image> = new Array<Image>();
      if (pageData) {
        pageData.items.map((value: DeploymentDataDriftItem) => {
          const itemImageData = new Array<ImageData>();
          if (value.detections) {
            value.detections.map(detection => {
              const label = detection.label || 'unknown';
              const progress = detection.progress || 0;
              if (detection.bbox && detection.bbox.length === 4) {
                const x = detection.bbox[0];
                const y = detection.bbox[1];
                const w = detection.bbox[2];
                const h = detection.bbox[3];
                const bbox: [number, number, number, number] = [x, y, w, h];
                const imageData: ImageData = {
                  label: label,
                  progress: progress,
                  bbox: bbox,
                };
                itemImageData.push(imageData);
              }
              return null;
            });
          }
          const item: Image = {
            url: getImageUrl(value),
            title: value.fileName,
            data: itemImageData,
          };
          images.push(item);
          return null;
        });
      }
      if (images.length > 0) {
        setCurrentDetections(images);
      }
    }
  }, [getImageUrl, modelPurpose, pageData]);
  return (
    <div className="deployment-data-drift">
      <h5>
        {intl.formatMessage({
          id: 'prodDeployment.inferenceResults',
        })}
      </h5>
      {modelPurpose === 'Classification' && (
        <div className="classification-result">
          <div className="inference-grid-container">
            <div className="view-grid">
              {pageData ? (
                pageData.items.map((item, j) => (
                  <ImageCardHorizontal
                    key={`inference-image-${j}`}
                    image={getImageUrl(item)}
                    width={IMAGE_WIDTH}
                    height={IMAGE_HEIGHT}
                    detailComponent={
                      item.classifications && (
                        <ImageCardDetail
                          data={item.classifications}
                          height={IMAGE_HEIGHT + IMAGE_TITLE_HEIGHT}
                          countObjects={false}
                        />
                      )
                    }
                    data-testid="classification-inference-result"
                    className="inference-result__image-card"
                  />
                ))
              ) : (
                <div className="classification-result__no-results">
                  <FormattedMessage id="test.noResults" />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {modelPurpose === 'Detection' && currentDetections && (
        <DetectionResult
          data={currentDetections}
          deploymentId={deploymentId}
          projectId={projectId}
          search={''}
          searchEnabled={false}
        ></DetectionResult>
      )}
      {totalCount > PAGE_SIZE && (
        <Pagination
          className="prod-deploy-dashboard__pagination"
          count={Math.ceil(totalCount / PAGE_SIZE)}
          page={pageIndex + 1}
          shape="rounded"
          onChange={(_, page) => setPageIndex(page - 1)}
          data-testid="prod-deploy-dashboard-pagination"
          id="prod-deploy-dashboard-pagination"
        />
      )}
    </div>
  );
};
