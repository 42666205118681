import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchBuild, getBuild} from '../../store/build';

export function useBuild(shouldFetch = true) {
  const dispatch = useDispatch();
  const {data, fetched} = useSelector(getBuild);

  useEffect(() => {
    if (shouldFetch && !fetched) {
      dispatch(fetchBuild());
    }
  }, [dispatch, shouldFetch, fetched]);

  return data;
}
