import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './RoundButton.scss';

import ArrowRightIcon from '../../assets/icons/ArrowRightIcon';
import FeedBackLikeIcon from '../../assets/icons/FeedBackLikeIcon';
import PlayIcon from '../../assets/icons/PlayIcon';
import PlusIcon from '../../assets/icons/PlusIcon';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import MoreIcon from '../../assets/icons/MoreIcon';
import SearchIcon from '../../assets/icons/SearchIcon';
import ArrowDownIcon from '../../assets/icons/ArrowDownIcon';
import ViewCollapseIcon from '../../assets/icons/ViewCollapseIcon';
import CloseIcon from '../../assets/icons/CloseIcon';
import DuplicateIcon from '../../assets/icons/DuplicateIcon';
import ChevronLeftIcon from '../../assets/icons/ChevronLeftIcon';
import ChevronRightIcon from '../../assets/icons/ChevronRightIcon';
import ArrowUpIcon from '../../assets/icons/ArrowUpIcon';

class RoundButton extends Component {
  render() {
    const {
      id,
      buttonIcon,
      buttonRole,
      size,
      customClass,
      onClick,
      disabled,
      ...rest
    } = this.props;
    return (
      <button
        {...(id ? {id: id} : {})}
        className={`round-button ${buttonRole} ${size} ${customClass || ''}`}
        onClick={onClick}
        disabled={disabled}
        data-testid={this.props['data-testid']}
        id={this.props['data-testid']}
        {...rest}
      >
        {this.getIcon(buttonIcon)}
      </button>
    );
  }

  getIcon(name) {
    const icons = {
      arrowRight: <ArrowRightIcon />,
      arrowDown: <ArrowDownIcon />,
      arrowUp: <ArrowUpIcon />,
      like: <FeedBackLikeIcon />,
      play: <PlayIcon />,
      plus: <PlusIcon />,
      delete: <DeleteIcon id={this.props.id} />,
      more: <MoreIcon />,
      search: <SearchIcon id={this.props.id} />,
      viewCollapse: <ViewCollapseIcon />,
      close: <CloseIcon />,
      duplicate: <DuplicateIcon />,
      chevronLeft: <ChevronLeftIcon />,
      chevronRight: <ChevronRightIcon />,
    };
    return icons[name];
  }
}

RoundButton.propTypes = {
  buttonIcon: PropTypes.oneOf([
    'arrowRight',
    'arrowDown',
    'arrowUp',
    'like',
    'play',
    'plus',
    'delete',
    'more',
    'search',
    'viewCollapse',
    'close',
    'duplicate',
    'chevronLeft',
    'chevronRight',
  ]).isRequired,
  buttonRole: PropTypes.oneOf(['primary', 'secondary', 'naked-light', 'naked-dark'])
    .isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  id: PropTypes.string,
  customClass: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

RoundButton.defaultProps = {
  id: null,
  customClass: null,
  onClick: () => null,
  disabled: false,
};

export default RoundButton;
