import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {IconButton} from '@material-ui/core';
import {Edit} from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, {MouseEvent} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {
  PostProcessorInfo,
  usePostProcessorList,
} from '../../../api/postprocessor/GetPostProcessorList';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {StudioSelect} from '../../../base-components/StudioSelect';
import {useAppDispatch} from '../../../store';
import {actions} from '../../../store/marketplace/add-pp';
import {openPublishForm} from '../../../store/marketplace/configure';
import {getProjectData} from '../../../store/project';
import {Model} from '../../../types/model/Model';
import URL_SETTINGS from '../../../config/url';
import axios from 'axios';
import {toast} from '../../../base-components/StudioToast';
import {StagesState} from '../../../types/stage/StagesState';
import {getStagesData} from '../../../store/stages';

import './ISPMenu.scss';

type ProcessorSelectDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onPublish: (resourceId: string) => void;
  processors: PostProcessorInfo[];
};

const ProcessorSelectDialog = ({
  isOpen,
  onClose,
  onPublish,
  processors,
}: ProcessorSelectDialogProps) => {
  const intl = useIntl();
  const [activeId, setActiveId] = React.useState(processors[0].id);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="menu.postProcessing.select" />
      </DialogTitle>
      <DialogContent>
        <StudioSelect
          className="processor_select"
          options={processors.reduce((obj, p) => ({...obj, [p.id]: p.name}), {})}
          SelectProps={{
            value: activeId,
            onChange: event => {
              setActiveId(event.target.value as string);
            },
          }}
        />
        <DialogActions>
          <FormButton
            buttonRole="secondary"
            value={intl.formatMessage({id: 'form.cancel'})}
            onClick={onClose}
            type="button"
          />
          <FormButton
            buttonRole="primary"
            value={intl.formatMessage({id: 'form.continue'})}
            type="button"
            onClick={() => {
              onClose();
              onPublish(activeId);
            }}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export type PostProcessingListProps = {
  projectId: string;
  refetch: () => {};
  data: PostProcessorInfo[];
  model?: Model;
  runtimeTypes?: string[];
  createNew: () => void;
  onImport: () => void;
  onPublish: (resourceId: string) => void;
  onSelect: (id: string) => void;
};

const PostProcessingList = ({
  projectId,
  refetch,
  data,
  model,
  createNew,
  onImport,
  onPublish,
  onSelect,
}: PostProcessingListProps) => {
  const intl = useIntl();
  const stages: StagesState = useSelector(getStagesData);
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const _onSelect = (id: string) => {
    onSelect && onSelect(id);
    handleClose();
  };
  const onCreateNew = () => {
    createNew && createNew();
    handleClose();
  };
  const onInternalImport = () => {
    onImport();
    handleClose();
  };
  const onInternalPublish = (resourceId: string) => {
    onPublish(resourceId);
    handleClose();
  };

  const [openSelection, setOpenSelection] = React.useState(false);

  const handleOnDelete = async (id: string, name: string) => {
    const {data} = await axios.get(URL_SETTINGS.DELETE_POST_PROCESSOR(projectId, id));
    if (data) {
      refetch();
      toast.success({
        title: intl.formatMessage({id: 'menu.delete.title'}),
        subtitle: intl.formatMessage({id: 'menu.delete'}, {name}),
        position: 'top',
      });
    }
  };

  return (
    <div className="side-bar-menu" data-testid="post-processor-navigation">
      {stages?.canAddPostProcessors && model && (
        <Button
          onClick={handleClick}
          className="side-bar-menu__button"
          data-testid="post-processor-navigation-button"
          id="post-processor-navigation-button"
        >
          <FormattedMessage id="menu.postProcessing" />
        </Button>
      )}
      <Menu
        className="side-bar-menu__container"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        data-testid="post-processor-navigation-menu"
        id="post-processor-navigation-menu"
      >
        {data.map((val, i) => (
          <div key={i}>
            <div className="side-bar-menu__container__menu">
              <MenuItem onClick={() => _onSelect(val.id)}>{val.name}</MenuItem>
              <div className="side-bar-menu__container__menu__button">
                <IconButton
                  size="small"
                  onClick={() => _onSelect(val.id)}
                  color="primary"
                >
                  <Edit />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleOnDelete(val.id, val.name)}
                  color="secondary"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
            {i !== data.length - 1 && <Divider />}
          </div>
        ))}
        <div>
          <Divider className="side-bar-menu__divider" />
          <MenuItem
            onClick={onCreateNew}
            className="side-bar-menu__create"
            disabled={!model}
            data-testid="post-processor-create-button"
            id="post-processor-create-button"
          >
            <FormattedMessage id="menu.postProcessing.new" />
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={onInternalImport}
            disabled={!model}
            data-testid="post-processor-import-button"
            id="post-processor-import-button"
          >
            <FormattedMessage id="menu.postProcessing.import" />
          </MenuItem>
          <MenuItem
            onClick={() => setOpenSelection(true)}
            data-testid="post-processor-import-button"
            id="post-processor-import-button"
            disabled={!data.length}
          >
            <FormattedMessage id="menu.postProcessing.publish" />
          </MenuItem>
        </div>
      </Menu>
      {openSelection && (
        <ProcessorSelectDialog
          isOpen={openSelection}
          onClose={() => setOpenSelection(false)}
          onPublish={onInternalPublish}
          processors={data}
        />
      )}
    </div>
  );
};

export type PostProcessingMenuProps = {
  projectId: string;
};

export function PostProcessingMenu({projectId}: PostProcessingMenuProps) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {model, runtimeTypes} = useSelector(getProjectData);
  const {data: postProcessorList, mutate} = usePostProcessorList({
    projectId: model && runtimeTypes?.length ? projectId : null,
  });

  const onPublish = (resourceId: string) => {
    dispatch(
      openPublishForm({
        projectId,
        resourceId,
        purpose: 'PP_STORAGE',
      })
    );
  };

  return (
    <PostProcessingList
      projectId={projectId}
      refetch={mutate}
      data={postProcessorList?.existingPostProcessors ?? []}
      model={model}
      runtimeTypes={runtimeTypes}
      createNew={() => history.push(`/project/${projectId}/postprocessor/new`)}
      onImport={() => {
        dispatch(actions.setIsOpen({isOpen: true}));
      }}
      onPublish={onPublish}
      onSelect={id => history.push(`/project/${projectId}/postprocessor/edit/${id}`)}
    />
  );
}
