import React, {Component} from 'react';
import PropTypes from 'prop-types';

class CheckCircleIcon extends Component {
  render() {
    return (
      <div className={this.props.className} data-testid="toast-success">
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-846.000000, -2518.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 1725.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-check-circle-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M17.5722656,7.43359375 L9.59179688,15.4140625 L6.46289062,12.25 L5.40820312,13.3046875 L9.59179688,17.4882812 L18.5917969,8.48828125 L17.5722656,7.43359375 Z"
                        id="checkbox"
                        fill="currentColor"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

CheckCircleIcon.propTypes = {
  className: PropTypes.string,
};

export default CheckCircleIcon;
