import {KonvaEventObject} from 'konva/types/Node';
import {KonvaPointerEvent} from 'konva/types/PointerEvents';
import {flatMap} from 'lodash';
import React, {useMemo, useState} from 'react';
import {Circle, Line, Rect} from 'react-konva';
import {BOX_LABEL_DEFAULT, BOX_LABEL_SELECTED, genId} from './const';
import Tag from './Tag';
import {LabelPropsEvent, LinePropsEvent, Point, PolyLabelProps} from './types';

type AnchorProps = {
  point: Point;
  fill: string;
  onClick: () => void;
  onMouseOver: () => void;
  onMouseOut: () => void;
};

function Anchor(props: AnchorProps) {
  const [strokeWidth, setStrokeWidth] = useState(2);

  return (
    <Circle
      x={props.point.x}
      y={props.point.y}
      radius={10}
      stroke="#666"
      fill={props.fill}
      strokeWidth={strokeWidth}
      onMouseOver={() => {
        document.body.style.cursor = 'pointer';
        setStrokeWidth(3);
        props.onMouseOver();
      }}
      onMouseOut={() => {
        document.body.style.cursor = 'default';
        setStrokeWidth(2);
        props.onMouseOut();
      }}
      onClick={() => {
        document.body.style.cursor = 'default';
        props.onClick();
      }}
    />
  );
}

type PolygonOriginAnchorProps = {
  point: Point;
  onValidClick: () => void;
  onValidMouseOver: () => void;
  validateMouseEvents: () => boolean;
};

function PolygonOriginAnchor(props: PolygonOriginAnchorProps) {
  const isValid = props.validateMouseEvents();
  const [fill, setFill] = useState('transparent');

  return (
    <Anchor
      point={props.point}
      fill={fill}
      onClick={() => {
        if (isValid) {
          props.onValidClick();
        }
      }}
      onMouseOver={() => {
        if (isValid) {
          document.body.style.cursor = 'pointer';
          setFill('green');
          props.onValidMouseOver();
        } else {
          document.body.style.cursor = 'not-allowed';
          setFill('red');
        }
      }}
      onMouseOut={() => {
        setFill('transparent');
      }}
    />
  );
}

type PolygonProps = {
  data?: PolyLabelProps;
  closeTargetRadius?: number;
  width?: number;
  height?: number;
  selected?: boolean;
  onComplete: (data: PolyLabelProps) => void;
  complete?: boolean;
};

export function Polygon({
  data = {id: '', points: []},
  width,
  height,
  selected = false,
  onComplete = () => {},
  onDelete = () => {},
  onClick = () => {},
  onContextMenu = () => {},
  complete = false,
}: PolygonProps & LabelPropsEvent & LinePropsEvent) {
  const [points, setPoints] = useState<Point[]>([]);
  const [nextPoint, setNextPoint] = useState<Point>({x: 0, y: 0});
  const [tagCenter, setTagCenter] = useState<Point>({x: 0, y: 0});

  const [labelStyle, setLabelStyle] = useState(BOX_LABEL_DEFAULT);
  const [isHover, setIsHover] = useState(false);

  const [isComplete, setIsComplete] = useState(complete);

  const onPointDraw = ({x, y}: Point) => setPoints(points.concat({x, y}));

  const onLocalComplete = () => {
    onComplete({...data, id: genId('POLY'), points: points, complete: true});
    setNextPoint(points[0]);
    setIsComplete(true);
  };

  const onLocalContextMenu = (e: KonvaPointerEvent) => {
    e.evt.preventDefault();
    onContextMenu && onContextMenu(e);
  };

  const onPolyClick = (e: KonvaEventObject<MouseEvent>) => {
    e.evt.preventDefault();
    onClick(e);
  };

  const onMouse = (isHovering: boolean) => setIsHover(isHovering);

  useMemo(() => {
    if (data) {
      const pArr = data.points;
      setPoints(pArr);
      console.log('!pArr: ', pArr);
      const allX = flatMap(pArr, o => o.x);
      const allY = flatMap(pArr, o => o.y);
      const rWidth = Math.max(...allX) - Math.min(...allX);
      const rHeight = Math.max(...allX) - Math.min(...allX);

      const cX = Math.min(...allX) + rWidth / 2;
      const cY = Math.min(...allY) + rHeight / 2;
      setTagCenter({x: cX, y: cY});
    }
  }, [data]);

  useMemo(() => {
    setLabelStyle(isHover || selected ? BOX_LABEL_SELECTED : BOX_LABEL_DEFAULT);
  }, [isHover, selected]);

  return (
    <>
      <Line
        lineJoin="round"
        {...labelStyle}
        closed={isComplete}
        points={points
          .flatMap(point => [point.x, point.y])
          .concat(isComplete ? [] : [nextPoint.x, nextPoint.y])}
        onMouseOver={() => onMouse(true)}
        onMouseOut={() => onMouse(false)}
        onContextMenu={onLocalContextMenu}
        onClick={onPolyClick}
      />

      {/* Hit Area */}
      {!isComplete && (
        <Rect
          x={0}
          y={0}
          width={width}
          height={height}
          onClick={event => {
            if (!isComplete) {
              const x = event.evt.offsetX;
              const y = event.evt.offsetY;
              onPointDraw({x, y});
            }
          }}
          onMouseMove={event => {
            if (!isComplete) {
              const x = event.evt.offsetX;
              const y = event.evt.offsetY;
              setNextPoint({x, y});
            }
          }}
          style={{border: '3px solid red'}}
        />
      )}

      {points[0] && !isComplete && (
        <PolygonOriginAnchor
          point={points[0]}
          onValidClick={onLocalComplete}
          onValidMouseOver={() => {
            setNextPoint(points[0]);
          }}
          validateMouseEvents={() => {
            return points.length > 2;
          }}
        />
      )}
      {isComplete && (
        <Tag
          label={data.label}
          {...tagCenter}
          selected={selected || isHover}
          yBuffer={20}
          onDelete={onDelete}
          onClick={onPolyClick}
          onContextMenu={onLocalContextMenu}
        />
      )}
    </>
  );
}
