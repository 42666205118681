import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import StageAnimation from '../StageAnimation/StageAnimation';
import Util from '../../../util';
import TaskInfoProps from '../../../model/taskInfoPropTypes';
import {toast} from '../../../base-components/StudioToast';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import {StageAssistantForm} from '../../StageAssistantForm';
import axios from 'axios';
import URL, {MODEL_OP, MODEL_SUB_OP} from '../../../config/url';
import {addListener, removeListener} from '../../../store/statusChecker';
import {setModelViewerStale, toggleModelDetailsPanel} from '../../../store/model';
import {viewDisableTermination, viewEnableTermination} from '../../../store/view';

const TASK_TYPE = 'PREPARE_HELPER';
class ModelCreateHelperFileAction extends Component {
  state = {
    busy: false,
  };

  constructor(props) {
    super(props);

    this.stageAnimation = new StageAnimation();
    this.createHelperFile = this.createHelperFile.bind(this);

    this.clarifyModelCreateHelperFile = this.clarifyModelCreateHelperFile.bind(this);
    this.handleCreationResponse = this.handleCreationResponse.bind(this);
    this.handleClarifyResponse = this.handleClarifyResponse.bind(this);
    this.processCreateHelperFileProgress = this.processCreateHelperFileProgress.bind(
      this
    );
    this.handleFinalizeResponse = this.handleFinalizeResponse.bind(this);
    this.terminateImport = this.terminateImport.bind(this);
  }

  componentDidMount() {
    if (this.props.readyForHelperPreparation) {
      this.createHelperFile();
    } else if (this.props.taskProgress && this.props.taskProgress.type === TASK_TYPE) {
      this.props.setModelActionInProgress('import');
      if (this.props.taskProgress.prompts && this.props.taskProgress.prompts.length) {
        this.stageAnimation.shiftStages('IMPORT_MODEL');
        this.showAssistant(this.props.taskProgress);
      } else {
        this.monitorStatus();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.taskProgress?.type !== TASK_TYPE &&
      this.props.taskProgress?.type === TASK_TYPE
    ) {
      this.props.setModelActionInProgress('import');
      if (this.props.taskProgress.prompts && this.props.taskProgress.prompts.length) {
        this.stageAnimation.shiftStages('IMPORT_MODEL');
        this.showAssistant(this.props.taskProgress);
      } else {
        this.monitorStatus();
      }
    }
  }

  componentWillUnmount() {
    this.props.removeStatusListener(TASK_TYPE);
  }

  render() {
    return null;
  }

  createHelperFile() {
    this.stageAnimation.markInProgress('IMPORT_MODEL');
    return axios
      .get(URL.MODEL(MODEL_OP.PREPARE_HELPER), {
        params: {projectId: this.props.projectId},
      })
      .then(res => this.handleCreationResponse(res.data))
      .catch(error => {
        toast.error(`model create helper error: ${error.message}`);
        this.terminateImport();
      });
  }

  handleCreationResponse(res) {
    let response = this.extract(res);
    if (response.errors && response.errors.length > 0) {
      this.terminateImport();
      this.finish(response?.body?.name, false);
    } else if (response.prompts) {
      // Show questions
      this.showAssistant(response);
    } else {
      this.clarifyModelCreateHelperFile([]);
    }
  }

  extract(res) {
    let response = res;
    return response;
  }

  finish(modelName, isSuccess) {
    if (this.props.onFinish) {
      // Hold on this since conversion comes up next
      if (isSuccess) {
        toast.success({
          title: 'Success!',
          subtitle: `Successfully created helper file for ${modelName || 'Model'}`,
          position: 'top',
          actions: [
            {
              label: 'View Details',
              callback: () => this.props.toggleModelDetailsPanel(this.props.projectId),
            },
          ],
          duration: 7000,
        });
        this.props.setModelViewerStale(true);
      }
      this.props.onFinish('IMPORT_MODEL');
    }
    this.setState({busy: false});
  }

  showAssistant(response) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.display(
        this.getQuestionsForm(response.prompts, response.defaults),
        'IMPORT_MODEL',
        null,
        this.terminateImport
      );
    }
  }

  getQuestionsForm(questions, defaults) {
    return (
      <StageAssistantForm
        prompts={questions}
        defaults={defaults}
        onSubmit={this.clarifyModelCreateHelperFile}
        onCancel={this.terminateImport}
        title="We need a little more information to transform this model."
      />
    );
  }

  clarifyModelCreateHelperFile(answersArray) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.hideStage('IMPORT_MODEL');
    }

    // get model ID
    const clarificationRequest = {
      projectId: this.props.projectId,
      modelId: this.props.model.id,
      answers: answersArray,
    };

    axios
      .post(
        URL.MODEL_OP(MODEL_OP.PREPARE_HELPER, MODEL_SUB_OP.CLARIFY),
        clarificationRequest
      )
      .then(res => this.handleClarifyResponse(res.data));
  }

  handleClarifyResponse(res) {
    let response = this.extract(res);
    if (response.errors && response.errors.length > 0) {
      this.terminateImport();
      this.finish(response?.body?.name, false);
    } else if (response.prompts) {
      this.showAssistant(response);
    } else {
      this.monitorStatus();
    }
  }

  monitorStatus() {
    this.props.viewEnableTermination();
    this.stageAnimation.markInProgress('IMPORT_MODEL');
    this.props.addStatusListener(TASK_TYPE, this.processCreateHelperFileProgress);
    this.setState({busy: true});
  }

  isPreparing() {
    return this.state.busy;
  }

  processCreateHelperFileProgress(status) {
    ModelCreateHelperFileAction.displayProgress(status);
    if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
      this.props.removeStatusListener(TASK_TYPE);
      this.setState({busy: false});
      this.finalize();
    }
  }

  static displayProgress(status) {
    let progressText = document.getElementById('model-action-progress');
    if (progressText) {
      progressText.innerText = status.statusMessage;
    }
  }

  finalize() {
    this.props.viewDisableTermination();
    axios
      .get(URL.MODEL_OP(MODEL_OP.PREPARE_HELPER, MODEL_SUB_OP.FINALIZE), {
        params: {projectId: this.props.projectId},
      })
      .then(({data}) => this.handleFinalizeResponse(data))
      .catch(error => toast.error(`model helper creation error: ${error.message}`));
  }

  handleFinalizeResponse(response) {
    const isSuccess = !response.errors || response.errors.length === 0;
    !isSuccess && this.terminateImport();
    this.finish(response?.body?.name, isSuccess);
  }

  terminateImport() {
    this.props.viewDisableTermination();
    return axios
      .get(URL.OPERATION_CANCEL(TASK_TYPE), {
        params: {projectId: this.props.projectId},
      })
      .then(res => {
        if (this.props.assistantPanel.current) {
          this.props.assistantPanel.current.hideStage('IMPORT_MODEL');
        }
        this.props.removeStatusListener(TASK_TYPE);
        this.finish();
      })
      .catch(error => toast.error(`cancel Create Helper File error: ${error.message}`));
  }
}

ModelCreateHelperFileAction.propTypes = {
  projectId: PropTypes.string.isRequired,
  model: PropTypes.any,
  assistantPanel: PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)})
    .isRequired,
  onFinish: PropTypes.func.isRequired,
  readyForHelperPreparation: PropTypes.bool.isRequired,
  taskProgress: TaskInfoProps,
};

const mapDispatchToProps = {
  addStatusListener: addListener,
  removeStatusListener: removeListener,
  setModelViewerStale,
  toggleModelDetailsPanel,
  viewEnableTermination,
  viewDisableTermination,
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(
  ModelCreateHelperFileAction
);
