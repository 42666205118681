import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import moment from 'moment';

export const CALENDAR_STRINGS = {
  lastDay: '[Yesterday], LT',
  sameDay: '[Today], LT',
  lastWeek: '[last] dddd, LT',
  sameElse: 'MM/DD/YYYY HH:mm',
};
const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
class HumanReadableTime extends Component {
  render() {
    if (!this.props.date) {
      return null;
    }
    const date = !this.props.utc
      ? moment(this.props.date).format(DATE_FORMAT)
      : moment(moment.utc(this.props.date).toDate())
          .local()
          .format(DATE_FORMAT);

    return (
      <Moment
        calendar={CALENDAR_STRINGS}
        className={this.props.className || ''}
        date={date}
      />
    );
  }
}

HumanReadableTime.propTypes = {
  className: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  parse: PropTypes.string,
};

export default HumanReadableTime;
