import React, {useReducer, useContext, useMemo, useCallback} from 'react';

const initialStatePerPath = {
  status: {},
  history: [],
};

const getMergedMessages = (existingMessages, newMessages) => {
  const existingMessageMap = existingMessages.reduce((acc, curr, index) => {
    acc[curr.id] = index;
    return acc;
  }, {});
  return newMessages.reduce(
    (acc, curr) => {
      const existingMessageIndex = existingMessageMap[curr.id];
      if (existingMessageIndex == null) {
        // append if new message id
        acc.push(curr);
      } else {
        // replace if message id already exists
        acc[existingMessageIndex] = {...acc[existingMessageIndex], ...curr};
      }
      return acc;
    },
    [...existingMessages]
  );
};

const reducerPerPath = (state = initialStatePerPath, action) => {
  switch (action.type) {
    case 'SET_STATUS': {
      const existingMessages = state.status.messages || [];
      const newMessages = action.status.messages || [];
      const mergedMessages = getMergedMessages(existingMessages, newMessages);
      const status = {...action.status, messages: mergedMessages};
      return {
        ...state,
        status,
      };
    }
    case 'SET_STATUS_FROM_UPDATE': {
      return {
        ...state,
        status: {
          ...action.status,
          prompts: [],
        },
      };
    }
    case 'SET_FEEDBACK_IN_MESSAGES': {
      const updatedMessages = [...state.status.messages];
      updatedMessages[action.index] = {
        ...updatedMessages[action.index],
        isHelpful: action.isHelpful,
      };
      const status = {...state.status, messages: updatedMessages};
      return {
        ...state,
        status,
      };
    }
    case 'SET_FEEDBACK_IN_HISTORY': {
      const updatedHistory = [...state.history];
      updatedHistory[action.index] = {
        ...updatedHistory[action.index],
        isHelpful: action.isHelpful,
      };
      return {
        ...state,
        history: updatedHistory,
      };
    }
    case 'APPEND_HISTORY': {
      const history = [...state.history, ...action.history];
      return {...state, history};
    }
    default:
      return state;
  }
};

const initialState = {
  isDrawerOpen: true,
  paths: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHAT_DRAWER_OPEN':
      return {...state, isDrawerOpen: true};
    case 'CHAT_DRAWER_CLOSE':
      return {...state, isDrawerOpen: false};
    case 'SET_STATUS':
    case 'SET_STATUS_FROM_UPDATE':
    case 'SET_FEEDBACK_IN_MESSAGES':
    case 'SET_FEEDBACK_IN_HISTORY':
    case 'APPEND_HISTORY':
      return {
        ...state,
        paths: {
          ...state.paths,
          [action.path]: reducerPerPath(state.paths[action.path], action),
        },
      };
    default:
      return state;
  }
};

const ConversationAssistantContext = React.createContext();

export const ConversationAssistantProvider = ({path, children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const pathState = useMemo(() => {
    const {paths, ...commonState} = state;
    return {...commonState, ...paths[path]};
  }, [state, path]);

  const pathDispatch = useCallback(
    action => {
      dispatch({...action, path});
    },
    [path]
  );

  return (
    <ConversationAssistantContext.Provider value={[pathState, pathDispatch]}>
      {children}
    </ConversationAssistantContext.Provider>
  );
};

export const useConversation = () => {
  const [state, dispatch] = useContext(ConversationAssistantContext);

  if (!state || !dispatch) {
    throw new Error(
      'useConversation must be used within a ConversationAssistantProvider.'
    );
  }
  return [state, dispatch];
};

// Consumer component that allows class components to access
// the context
export const ConversationConsumer = ({children}) => {
  const context = useConversation();
  return children(context);
};
