import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import cn from 'classnames';
import HumanReadableTime from '../../../base-components/StudioHumanReadableTime/HumanReadableTime';
import {DeviceStatus} from '../DeploymentStatus/DeviceStatus';
import {StudioKebabMenu} from '../../../base-components/StudioKebabMenu';
import {
  DeploymentNodesRequest,
  getDeploymentNodes,
  GET_DEPLOYMENT_NODES_URL,
  NodesSortableColumn,
} from '../../../api/deployment/DeploymentNodes';
import useSWR from 'swr';
import fileSize from 'filesize';
import {ConfirmDialog} from '../../../base-components/StudioConfirmDialog';
import {deleteDeploymentNode} from '../../../api/deployment/DeleteDeploymentNode';

const UPDATE_INTERVAL = 5000;

type GSPConfigurationsTableProps = {
  search: string;
};

export const GSPConfigurationsTable = ({search}: GSPConfigurationsTableProps) => {
  const intl = useIntl();
  const [sort, setSort] = useState<NodesSortableColumn>('name');
  const [sortDir, setSortDir] = useState<-1 | 1>(1);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    isOpen: boolean;
    nodeId: string | null;
  }>({isOpen: false, nodeId: null});

  const {data: nodesResponse, mutate} = useSWR(
    [GET_DEPLOYMENT_NODES_URL, search, sort, sortDir],
    async (url, search, sort, sortDir) => {
      const params: DeploymentNodesRequest = {
        filter: search,
        page: 0,
        sort: [sort, sortDir === -1 ? 'DESC' : 'ASC'],
      };
      return await getDeploymentNodes(params);
    },
    {refreshInterval: UPDATE_INTERVAL}
  );

  const nodes = nodesResponse?.body
    .map(node => node.boards?.map(board => ({...node, board})))
    .filter(node => node)
    .flat();

  const handleSort = (column: NodesSortableColumn) => {
    if (column === sort) {
      setSortDir(sortDir === 1 ? -1 : 1);
    } else {
      setSort(column);
      setSortDir(1);
    }
  };

  const handleDeleteNode = async (nodeId: string) => {
    try {
      await deleteDeploymentNode(nodeId);
      mutate({
        body: nodesResponse?.body.filter(node => node.id !== nodeId) || [],
        errors: [],
        warnings: [],
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getSortDirection = (): 'asc' | 'desc' => {
    return sortDir === 1 ? 'asc' : 'desc';
  };

  return (
    <>
      <TableContainer
        component={Paper}
        className="prod-deploy-dashboard__deployments"
        elevation={0}
      >
        <Table stickyHeader className="prod-deploy-dashboard__table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sort === 'status'}
                  direction={getSortDirection()}
                  onClick={() => handleSort('status')}
                  IconComponent={ArrowDropDown}
                >
                  <FormattedMessage id="prodDeployment.state" />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort === 'name'}
                  direction={getSortDirection()}
                  onClick={() => handleSort('name')}
                  IconComponent={ArrowDropDown}
                >
                  <FormattedMessage id="prodDeployment.node" />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.board" />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort === 'lastUpdateTime'}
                  direction={getSortDirection()}
                  onClick={() => handleSort('lastUpdateTime')}
                  IconComponent={ArrowDropDown}
                >
                  <FormattedMessage id="prodDeployment.lastUpdate" />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort === 'nodeUrl'}
                  direction={getSortDirection()}
                  onClick={() => handleSort('nodeUrl')}
                  IconComponent={ArrowDropDown}
                >
                  <FormattedMessage id="prodDeployment.networkDetails" />
                </TableSortLabel>
              </TableCell>
              <TableCell colSpan={5}>
                <FormattedMessage id="prodDeployment.resourceUsage" />
              </TableCell>
            </TableRow>
            <TableRow className="prod-deploy-dashboard__subheader">
              <TableCell colSpan={4} />
              <TableCell>
                <FormattedMessage id="prodDeployment.hostIP" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.memUtil" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.memTotal" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.storageFree" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.storageTotal" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.gspCount" />
              </TableCell>
            </TableRow>
          </TableHead>
          {nodes?.length ? (
            nodes.map((row, index) => {
              return (
                row && (
                  <TableBody
                    key={`${row.id}_${row.board.id}`}
                    className={cn(
                      'prod-deploy-dashboard__row-group',
                      index % 2 === 1 && 'prod-deploy-dashboard__row-group--odd'
                    )}
                  >
                    <TableRow>
                      <TableCell>
                        {row.status != null && (
                          <DeviceStatus
                            status={row.status === 'ONLINE' ? 'Online' : 'Offline'}
                          />
                        )}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.board.deviceName}</TableCell>
                      <TableCell>
                        {row.lastUpdateTime != null && (
                          <HumanReadableTime date={row.lastUpdateTime} />
                        )}
                      </TableCell>
                      <TableCell>{row.nodeUrl}</TableCell>
                      <TableCell>
                        {row.board.resources?.['RAM_USED'] != null &&
                          fileSize(row.board.resources?.['RAM_USED'], {base: 10})}
                      </TableCell>
                      <TableCell>
                        {row.board.resources?.['RAM_TOTAL'] != null &&
                          fileSize(row.board.resources?.['RAM_TOTAL'], {base: 10})}
                      </TableCell>
                      <TableCell>
                        {row.resources?.['STORAGE_AVAILABLE'] != null &&
                          fileSize(row.resources['STORAGE_AVAILABLE'], {base: 10})}
                      </TableCell>
                      <TableCell>
                        {row.resources?.['STORAGE_TOTAL'] != null &&
                          fileSize(row.resources['STORAGE_TOTAL'], {base: 10})}
                      </TableCell>
                      <TableCell>{row.boards?.length}</TableCell>
                      <TableCell>
                        <div className="prod-deploy-dashboard__actions">
                          <StudioKebabMenu
                            items={[
                              {
                                title: intl.formatMessage({
                                  id: 'prodDeployment.removeDevice',
                                }),
                                onClick: () => {
                                  setDeleteConfirmation({isOpen: true, nodeId: row.id});
                                },
                                disabled: row.status !== 'OFFLINE',
                              },
                            ]}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              );
            })
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={100} className="prod-deploy-dashboard__no-results">
                  <FormattedMessage id="dashboard.noResults" />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <ConfirmDialog
        type="confirm"
        title={intl.formatMessage({id: 'prodDeployment.deleteConfirmNodeTitle'})}
        message={intl.formatMessage({id: 'prodDeployment.deleteConfirmNodeMessage'})}
        submitLabel={intl.formatMessage({id: 'prodDeployment.deleteConfirmSubmitLabel'})}
        open={deleteConfirmation.isOpen}
        onClose={() => setDeleteConfirmation({isOpen: false, nodeId: null})}
        onOk={() => {
          if (deleteConfirmation.nodeId) {
            handleDeleteNode(deleteConfirmation.nodeId);
          }
          setDeleteConfirmation({isOpen: false, nodeId: null});
        }}
      />
    </>
  );
};
