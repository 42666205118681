import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {IconButton} from '@material-ui/core';
import {Edit} from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import React, {MouseEvent} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {usePreProcessorList} from '../../../api/preprocessor/PreProcessorList';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {StudioSelect} from '../../../base-components/StudioSelect';
import {useAppDispatch} from '../../../store';
import {actions} from '../../../store/marketplace/add-isp';
import {openPublishForm} from '../../../store/marketplace/configure';
import {getProjectData} from '../../../store/project';
import {viewOpenNewISP, viewSetSelectedISP} from '../../../store/view';
import {TransformationInfo} from '../../../types/metadata/TransformationInfo';
import {Model} from '../../../types/model/Model';
import URL_SETTINGS from '../../../config/url';
import axios from 'axios';
import {toast} from '../../../base-components/StudioToast';

import './ISPMenu.scss';

type ProcessorSelectDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onPublish: (resourceId: string) => void;
  processors: TransformationInfo[];
};

const ProcessorSelectDialog = ({
  isOpen,
  onClose,
  onPublish,
  processors,
}: ProcessorSelectDialogProps) => {
  const intl = useIntl();
  const [activeId, setActiveId] = React.useState(processors[0].id);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="menu.preProcessing.select" />
      </DialogTitle>
      <DialogContent>
        <StudioSelect
          className="processor_select"
          options={processors.reduce((obj, p) => ({...obj, [p.id]: p.name}), {})}
          SelectProps={{
            value: activeId,
            onChange: event => {
              setActiveId(event.target.value as string);
            },
          }}
        />
        <DialogActions>
          <FormButton
            buttonRole="secondary"
            value={intl.formatMessage({id: 'form.cancel'})}
            onClick={onClose}
            type="button"
          />
          <FormButton
            buttonRole="primary"
            value={intl.formatMessage({id: 'form.continue'})}
            type="button"
            onClick={() => {
              onClose();
              onPublish(activeId);
            }}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export type PreProcessingListProps = {
  projectId: string;
  refetch: () => void;
  data: TransformationInfo[];
  model: Model;
  createNew: () => void;
  createNewScript: () => void;
  onImport: () => void;
  onPublish: (resourceId: string) => void;
  onSelect: (info: TransformationInfo) => void;
  isNewAllowed: boolean;
};

const PreProcessingList = ({
  projectId,
  refetch,
  data,
  model,
  createNew,
  createNewScript,
  onImport,
  onPublish,
  onSelect,
  isNewAllowed,
}: PreProcessingListProps) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const _onSelect = (info: TransformationInfo) => {
    onSelect && onSelect(info);
    handleClose();
  };
  const onCreateNew = () => {
    createNew && createNew();
    handleClose();
  };
  const onInternalImport = () => {
    onImport();
    handleClose();
  };
  const onInternalPublish = (resourceId: string) => {
    onPublish(resourceId);
    handleClose();
  };

  const [openSelection, setOpenSelection] = React.useState(false);
  const onCreateNewScript = () => {
    createNewScript?.();
    handleClose();
  };

  const handleOnDelete = async (id: string, name: string) => {
    const {data} = await axios.get(URL_SETTINGS.DELETE_PRE_PROCESSOR(projectId, id));
    if (data) {
      refetch();
      toast.success({
        title: intl.formatMessage({id: 'menu.delete.title'}),
        subtitle: intl.formatMessage({id: 'menu.delete'}, {name}),
        position: 'top',
      });
    }
  };

  return (
    <div className="side-bar-menu" data-testid="pre-processor-navigation">
      <Button
        onClick={handleClick}
        className="side-bar-menu__button"
        disabled={!(isNewAllowed || data?.length)}
        data-testid="pre-processor-navigation-button"
        id="pre-processor-navigation-button"
      >
        <FormattedMessage id="menu.preProcessing" />
      </Button>
      <Menu
        className="side-bar-menu__container"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        data-testid="pre-processor-navigation-menu"
        id="pre-processor-navigation-menu"
      >
        {data.map((val, i) => {
          return (
            (model || val.transformationType !== 'SCRIPT') && (
              <div key={val.id}>
                <div className="side-bar-menu__container__menu">
                  <MenuItem onClick={() => _onSelect(val)}>{val.name}</MenuItem>
                  <div className="side-bar-menu__container__menu__button">
                    <IconButton
                      size="small"
                      onClick={() => _onSelect(val)}
                      color="primary"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleOnDelete(val.id, val.name)}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
                {i !== data.length - 1 && <Divider />}
              </div>
            )
          );
        })}
        {isNewAllowed && (
          <div>
            <Divider className="side-bar-menu__divider" />
            <MenuItem
              onClick={onCreateNew}
              className="side-bar-menu__create"
              data-testid="isp-create-button"
              id="isp-create-button"
            >
              <FormattedMessage id="isp.createNew" />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={onCreateNewScript}
              disabled={!model}
              className="side-bar-menu__create"
            >
              <FormattedMessage id="menu.preProcessing.new" />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={onInternalImport}
              data-testid="pre-processor-import-button"
            >
              <FormattedMessage id="menu.preProcessing.import" />
            </MenuItem>
            <MenuItem
              onClick={() => setOpenSelection(true)}
              data-testid="pre-processor-import-button"
              id="pre-processor-import-button"
              disabled={!data.length}
            >
              <FormattedMessage id="menu.preProcessing.publish" />
            </MenuItem>
          </div>
        )}
      </Menu>
      {openSelection && (
        <ProcessorSelectDialog
          isOpen={openSelection}
          onClose={() => setOpenSelection(false)}
          onPublish={onInternalPublish}
          processors={data}
        />
      )}
    </div>
  );
};

export type PreProcessingMenuProps = {
  isFlowgraphPage: boolean;
  projectId: string;
};

export function PreProcessingMenu({
  isFlowgraphPage = true,
  projectId,
}: PreProcessingMenuProps) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {model} = useSelector(getProjectData);
  const {data: preProcessorList, mutate} = usePreProcessorList({projectId});

  const onSelect = (info: TransformationInfo) => {
    if (info.transformationType === 'ISP') {
      dispatch(viewSetSelectedISP(info.id));
      setTimeout(() => history.push(`/project/${projectId}/flowgraph/${info.id}`), 10);
    } else if (info.transformationType === 'SCRIPT') {
      history.push(`/project/${projectId}/preprocessor/edit/${info.id}`);
    }
  };

  const onPublish = (resourceId: string) => {
    dispatch(
      openPublishForm({
        projectId,
        resourceId,
        purpose: 'ISP_STORAGE',
      })
    );
  };

  return (
    <PreProcessingList
      projectId={projectId}
      refetch={mutate}
      data={preProcessorList?.existingPreProcessors ?? []}
      model={model}
      createNew={() => {
        dispatch(viewOpenNewISP());
      }}
      createNewScript={() => history.push(`/project/${projectId}/preprocessor/new`)}
      onImport={() => {
        dispatch(
          actions.setIsOpen({
            isOpen: true,
            initialMarketplaceState: {
              filters: {
                compatibleOnly: true,
                ...(!model ? {transformationType: 'ISP'} : {}),
              },
            },
          })
        );
      }}
      onPublish={onPublish}
      onSelect={onSelect}
      isNewAllowed={!isFlowgraphPage}
    />
  );
}
