import React, {Component} from 'react';
import PropTypes from 'prop-types';
import StageAnimation from '../StageAnimation/StageAnimation';
import Util from '../../../util';
import {toast} from '../../../base-components/StudioToast';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import {StageAssistantForm} from '../../StageAssistantForm';
import axios from 'axios';
import URL from '../../../config/url';
import {connect} from 'react-redux';
import {addListener, removeListener} from '../../../store/statusChecker';
import {viewDisableTermination, viewEnableTermination} from '../../../store/view';
import {projectFetch} from '../../../store/project';

class CreateDeploymentAction extends Component {
  constructor(props) {
    super(props);
    this.stageAnimation = new StageAnimation();
    this.initiateCreateDeployment = this.initiateCreateDeployment.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.finishCreateDeployment = this.finishCreateDeployment.bind(this);
    this.showAssistant = this.showAssistant.bind(this);
    this.getQuestionsForm = this.getQuestionsForm.bind(this);
    this.clarifyCreateDeployment = this.clarifyCreateDeployment.bind(this);
    this.processCreateDeploymentProgress = this.processCreateDeploymentProgress.bind(
      this
    );
    this.finalizeCreateDeployment = this.finalizeCreateDeployment.bind(this);
    this.terminateCreateDeployment = this.terminateCreateDeployment.bind(this);
  }

  componentDidMount() {
    if (this.props.taskProgress) {
      if (this.props.taskProgress.prompts?.length) {
        this.stageAnimation.shiftStages('CREATE_DEPLOYMENT');
        this.showAssistant(this.props.taskProgress);
      } else {
        this.monitorStatus();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.taskProgress && this.props.taskProgress) {
      if (this.props.taskProgress.prompts?.length) {
        this.stageAnimation.shiftStages('CREATE_DEPLOYMENT');
        this.showAssistant(this.props.taskProgress);
      } else {
        this.monitorStatus();
      }
    }
  }

  componentWillUnmount() {
    this.props.removeStatusListener('CREATE_DEPLOYMENT');
  }

  render() {
    return null;
  }

  initiateCreateDeployment(caAnswers) {
    this.stageAnimation.markInProgress('CREATE_DEPLOYMENT');

    if (caAnswers) {
      this.clarifyCreateDeployment(null, caAnswers);
    } else {
      return axios
        .get(URL.MODEL_CREATE_DEPLOYMENT, {params: {projectId: this.props.projectId}})
        .then(res => this.handleResponse(res.data))
        .catch(error => toast.error(`Create application error: ${error.message}`));
    }
  }

  handleResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.finishCreateDeployment();
    } else {
      this.showAssistant(response);
    }
  }

  monitorStatus() {
    this.props.viewEnableTermination();
    this.stageAnimation.markInProgress('CREATE_DEPLOYMENT');
    this.props.addStatusListener(
      'CREATE_DEPLOYMENT',
      this.processCreateDeploymentProgress
    );
  }

  finishCreateDeployment() {
    if (this.props.onFinish) {
      this.props.onFinish('CREATE_DEPLOYMENT');
    }
  }

  showAssistant(response) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.display(
        this.getQuestionsForm(response.prompts, response.defaults),
        'CREATE_DEPLOYMENT',
        null,
        this.terminateCreateDeployment
      );
    }
  }

  getQuestionsForm(questions, defaults) {
    return (
      <StageAssistantForm
        prompts={questions}
        defaults={defaults}
        onSubmit={this.clarifyCreateDeployment}
        onCancel={this.terminateCreateDeployment}
        title="Create Application"
        notifyType="CREATE_DEPLOYMENT"
      />
    );
  }

  clarifyCreateDeployment(answers, caAnswers) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.hideStage('CREATE_DEPLOYMENT');
    }
    const modelClarificationRequest = {
      projectId: this.props.projectId,
      answers: answers,
      caAnswers,
    };

    axios
      .post(URL.MODEL_CREATE_DEPLOYMENT_CLARIFY, modelClarificationRequest)
      .then(({data}) => this.handleClarifyResponse(data))
      .catch(error => toast.error(`Create application error: ${error.message}`));
  }

  handleClarifyResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.finishCreateDeployment();
    } else if (response.prompts) {
      this.showAssistant(response);
    } else {
      this.monitorStatus();
    }
  }

  processCreateDeploymentProgress(status) {
    this.displayCreateDeploymentStatus(status);
    if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
      this.props.removeStatusListener('CREATE_DEPLOYMENT');
      this.finalizeCreateDeployment(this.props.projectId);
    }
  }

  finalizeCreateDeployment(projectId) {
    this.props.viewDisableTermination();
    return axios
      .get(URL.MODEL_CREATE_DEPLOYMENT_FINALIZE, {params: {projectId}})
      .then(res => {
        if (res.data.body && !res.data.errors && !res.data.prompts) {
          this.finishCreateDeployment();
          this.props
            .projectFetch(this.props.projectId)
            .then(() => this.props.navigateToTestView());
        } else if (res.data.prompts) {
          this.showAssistant(res);
        } else {
          this.finishCreateDeployment();
        }
      })
      .catch(error => toast.error(`Create application error: ${error.message}`));
  }

  displayCreateDeploymentStatus(status) {
    let progressText = document.getElementById('deployment-action-progress');
    if (progressText) {
      progressText.innerText = status.statusMessage;
    }
  }

  terminateCreateDeployment() {
    this.props.viewDisableTermination();
    axios
      .get(URL.OPERATION_CANCEL('CREATE_DEPLOYMENT'), {
        params: {projectId: this.props.projectId},
      })
      .then(res => {
        if (this.props.assistantPanel.current) {
          this.props.assistantPanel.current.hideStage('CREATE_DEPLOYMENT');
        }
        this.props.removeStatusListener('CREATE_DEPLOYMENT');
        this.finishCreateDeployment();
      })
      .catch(error =>
        toast.error(`Terminate create application error: ${error.message}`)
      );
  }
}

CreateDeploymentAction.propTypes = {
  projectId: PropTypes.string.isRequired,
  assistantPanel: PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)})
    .isRequired,
  onFinish: PropTypes.func.isRequired,
  navigateToTestView: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addStatusListener: addListener,
  removeStatusListener: removeListener,
  viewEnableTermination,
  viewDisableTermination,
  projectFetch,
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(
  CreateDeploymentAction
);
