import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import StageAnimation from '../StageAnimation/StageAnimation';
import Util from '../../../util';
import TaskInfoProps from '../../../model/taskInfoPropTypes';
import {toast} from '../../../base-components/StudioToast';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import {StageAssistantForm} from '../../StageAssistantForm';
import axios from 'axios';
import URL, {MODEL_OP, MODEL_SUB_OP} from '../../../config/url';
import {addListener, removeListener} from '../../../store/statusChecker';
import {setModelViewerStale, toggleModelDetailsPanel} from '../../../store/model';
import {viewDisableTermination, viewEnableTermination} from '../../../store/view';

class ModelConversionAction extends Component {
  state = {
    converting: false,
  };

  constructor(props) {
    super(props);

    this.stageAnimation = new StageAnimation();
    this.convertModel = this.convertModel.bind(this);
    this.clarifyModelConversion = this.clarifyModelConversion.bind(this);
    this.handleConversionResponse = this.handleConversionResponse.bind(this);
    this.handleClarifyResponse = this.handleClarifyResponse.bind(this);
    this.processConversionProgress = this.processConversionProgress.bind(this);
    this.handleFinalizeResponse = this.handleFinalizeResponse.bind(this);
    this.terminateImport = this.terminateImport.bind(this);
  }

  componentDidMount() {
    if (
      this.props.readyForConversion &&
      !(this.props.taskProgress?.type === 'PREPARE_MODEL_FILE')
    ) {
      this.convertModel();
    } else if (
      this.props.taskProgress &&
      this.props.taskProgress.type === 'CONVERT_MODEL'
    ) {
      this.props.setModelActionInProgress('import');
      if (this.props.taskProgress.prompts && this.props.taskProgress.prompts.length) {
        this.stageAnimation.shiftStages('IMPORT_MODEL');
        this.showAssistant(this.props.taskProgress);
      } else {
        this.monitorStatus();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.readyForConversion &&
      prevProps.readyForConversion !== this.props.readyForConversion &&
      !this.state.converting
    ) {
      this.convertModel();
    } else if (
      prevProps.taskProgress?.type !== 'CONVERT_MODEL' &&
      this.props.taskProgress?.type === 'CONVERT_MODEL'
    ) {
      this.props.setModelActionInProgress('import');
      if (this.props.taskProgress.prompts && this.props.taskProgress.prompts.length) {
        this.stageAnimation.shiftStages('IMPORT_MODEL');
        this.showAssistant(this.props.taskProgress);
      } else {
        this.monitorStatus();
      }
    }
  }

  componentWillUnmount() {
    this.props.removeStatusListener('CONVERT_MODEL');
  }

  render() {
    return null;
  }

  isConverting() {
    return this.state.converting;
  }

  convertModel() {
    this.props.setModelActionInProgress('import');
    this.stageAnimation.markInProgress('IMPORT_MODEL');
    return axios
      .get(URL.MODEL_CONVERT, {params: {projectId: this.props.projectId}})
      .then(res => this.handleConversionResponse(res.data))
      .catch(error => toast.error(`model conversion error: ${error.message}`));
  }

  handleConversionResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.finishConversion(response.body ? response.body.name : '', false);
    } else if (response.body?.messages?.length) {
      this.props.prepareModelFileAction.current &&
        this.props.prepareModelFileAction.current.showAssistant(response);
    } else {
      this.showAssistant(response);
    }
  }

  finishConversion(modelName, isSuccess) {
    if (!modelName || modelName.length === 0) {
      modelName = 'Model';
    }
    if (this.props.onFinish) {
      if (isSuccess) {
        toast.success({
          title: 'Success!',
          subtitle: `${modelName} successfully imported`,
          position: 'top',
          actions: [
            {
              label: 'View Details',
              callback: () => this.props.toggleModelDetailsPanel(this.props.projectId),
            },
          ],
          duration: 7000,
        });
        this.props.setModelViewerStale(true);
        this.props.onFinish('OPTIMIZE_MODEL');
      } else {
        this.props.onFinish('IMPORT_MODEL');
      }
    }
    this.setState({converting: false});
  }

  showAssistant(response) {
    if (this.props.assistantPanel.current) {
      if (response.prompts?.length) {
        this.props.assistantPanel.current.display(
          this.getQuestionsForm(
            response.prompts,
            response.defaults,
            response.body?.header
          ),
          'IMPORT_MODEL',
          null,
          this.terminateImport
        );
      } else {
        this.clarifyModelConversion([]);
      }
    }
  }

  getQuestionsForm(questions, defaults, title) {
    return (
      <StageAssistantForm
        prompts={questions}
        defaults={defaults}
        onSubmit={this.clarifyModelConversion}
        onCancel={this.terminateImport}
        title={title}
      />
    );
  }

  clarifyModelConversion(answersArray) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.hideStage('IMPORT_MODEL');
    }

    const clarificationRequest = {
      projectId: this.props.projectId,
      answers: answersArray,
    };

    axios
      .post(URL.MODEL_OP(MODEL_OP.CONVERT, MODEL_SUB_OP.CLARIFY), clarificationRequest)
      .then(res => this.handleClarifyResponse(res.data))
      .catch(error => toast.error(`model conversion error: ${error.message}`));
  }

  handleClarifyResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.finishConversion(response.body ? response.body.name : '', false);
    } else if (response.prompts) {
      this.showAssistant(response);
    } else {
      this.monitorStatus();
    }
  }

  monitorStatus() {
    this.props.viewEnableTermination();
    this.stageAnimation.markInProgress('IMPORT_MODEL');
    this.props.addStatusListener('CONVERT_MODEL', this.processConversionProgress);
    this.setState({converting: true});
  }

  processConversionProgress(status) {
    ModelConversionAction.displayConversionProgress(status);
    if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
      this.props.removeStatusListener('CONVERT_MODEL');
      this.finalizeConversion();
    }
  }

  static displayConversionProgress(status) {
    let progressText = document.getElementById('model-action-progress');
    if (progressText) {
      progressText.innerText = status.statusMessage;
    }
  }

  finalizeConversion() {
    this.props.viewDisableTermination();
    axios
      .get(URL.MODEL_CONVERT_FINALIZE, {
        params: {projectId: this.props.projectId},
      })
      .then(({data}) => this.handleFinalizeResponse(data))
      .catch(error => toast.error(`model conversion error: ${error.message}`));
  }

  handleFinalizeResponse(response) {
    this.finishConversion(
      response.body ? response.body.name : '',
      !response.errors || response.errors.length === 0
    );
  }

  terminateImport() {
    this.props.viewDisableTermination();
    return axios
      .get(URL.OPERATION_CANCEL('CONVERT_MODEL'), {
        params: {projectId: this.props.projectId},
      })
      .then(res => {
        this.props.assistantPanel.current.hideStage('IMPORT_MODEL');
        this.props.removeStatusListener('CONVERT_MODEL');
        this.finishConversion();
      })
      .catch(error => toast.error(`cancel Conversion error: ${error.message}`));
  }
}

ModelConversionAction.propTypes = {
  projectId: PropTypes.string.isRequired,
  assistantPanel: PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)})
    .isRequired,
  onFinish: PropTypes.func.isRequired,
  readyForConversion: PropTypes.bool.isRequired,
  taskProgress: TaskInfoProps,
};

const mapDispatchToProps = {
  addStatusListener: addListener,
  removeStatusListener: removeListener,
  setModelViewerStale,
  toggleModelDetailsPanel,
  viewEnableTermination,
  viewDisableTermination,
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(
  ModelConversionAction
);
