import React, {Component} from 'react';
import PropTypes from 'prop-types';

class AlertOutlineIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="18px"
          height="18px"
          viewBox="0 0 18 18"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-847.000000, -1091.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 297.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-alert-outline-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M12,19 C8.13400675,19 5,15.8659932 5,12 C5,8.13400675 8.13400675,5 12,5 C15.8659932,5 19,8.13400675 19,12 C19,15.8659932 15.8659932,19 12,19 Z M11,15 L13,15 L13,17 L11,17 L11,15 Z M11,7 L13,7 L13,13 L11,13 L11,7 Z"
                        id="alert-outline"
                        fill="currentColor"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

AlertOutlineIcon.propTypes = {
  className: PropTypes.string,
};

export default AlertOutlineIcon;
