import {debounce} from 'lodash';
import {InputSource} from '../../../types/deployment/InferenceInputSource';
import {Parameter} from '../../../types/model/framework/Parameter';
import {ConfigState} from '../../../store/config';
import {VideoConnectionStatus} from './VideoStream';

export enum EVENT_TYPE {
  REGISTERED = 'REGISTERED',
  RECEIVE_ONLY_READY = 'RECEIVE_ONLY_READY',
  CONNECTING = 'CONNECTING',
  ERROR = 'ERROR',
  CONNECTED = 'CONNECTED',
  REMOTE_STREAM = 'REMOTE_STREAM',
  USER_STREAM = 'USER_STREAM',
  DATA = 'DATA',
  STREAM_FINISHED = 'STREAM_FINISHED',
}

export enum ERROR_TYPE {
  INITIALIZATION = 'INITIALIZATION',
  CONNECTION_TERMINATED = 'CONNECTION_TERMINATED',
}

export type VideoStreamEvent = {
  type: EVENT_TYPE;
  stream?: MediaStream;
  error?: ERROR_TYPE;
  receiveOnly?: boolean;
  sessionId?: string;
  message?: string | null;
  monitorConfig?: MonitorConfig;
  data?: Record<string, any>;
};

export type VideoStreamConfig = {
  receiveOnly?: boolean;
  source?: InputSource | '';
  url?: string;
  targetSession?: string;
  serverConfig: ConfigState;
  projectId: string;
  studioTaskId: string | null;
  ytRestricted?: boolean;
  monitorConfig: MonitorConfig;
  onEvent: (event: VideoStreamEvent) => void;
};

export type MonitorConfig = {
  monitorStream: boolean;
  projectId?: string;
  deploymentId?: string;
  onMonitoringEvent?: (event: MonitorProps) => void;
};

export type MonitorProps = {
  timestamp?: number;
  bytesReceived?: number;
  kbps?: number;
  fps?: number;
  handle?: NodeJS.Timeout;
};

const HEARTBEAT_INTERVAL = 60 * 1000;

const sendMessage = (socket: WebSocket, msg: object) => {
  socket.send(JSON.stringify(msg));
};

const openSocketConnection = (url: string) =>
  new Promise<WebSocket>((resolve, reject) => {
    const ws = new WebSocket(url);
    ws.onopen = () => {
      resolve(ws);
    };
    ws.onerror = e => {
      reject(e);
    };
  });

const initializeReceiveOnlyStream = async (
  socket: WebSocket,
  peer: RTCPeerConnection,
  onEvent: (event: VideoStreamEvent) => void,
  monitor: MonitorProps,
  monitorConfig: MonitorConfig
) => {
  try {
    peer.addTransceiver('video', {direction: 'recvonly'});
    stopMonitoring(monitor);
    if (monitorConfig.monitorStream) {
      monitorStream(socket, peer, monitor, monitorConfig);
    }
    // Since this is a receive only connection, we do not add any media to the
    // peer connection. Instead, to trigger negotiation, we use a dummy data channel
    peer.createDataChannel('dummy');
  } catch (e) {
    stopMonitoring(monitor);
    console.debug('Error initializing receive only stream', e);
    onEvent({
      type: EVENT_TYPE.ERROR,
      error: ERROR_TYPE.INITIALIZATION,
    });
  }
};

const initializeMediaStream = async (
  socket: WebSocket,
  peer: RTCPeerConnection,
  onEvent: (event: VideoStreamEvent) => void,
  monitor: MonitorProps,
  monitorConfig: MonitorConfig
) => {
  try {
    const mediaConstraints = {
      audio: false,
      video: true,
    };
    const userStream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
    userStream.getTracks().forEach(track => peer.addTrack(track, userStream));
    stopMonitoring(monitor);
    if (monitorConfig.monitorStream) {
      monitorStream(socket, peer, monitor, monitorConfig);
    }
    onEvent({
      type: EVENT_TYPE.USER_STREAM,
      stream: userStream,
    });
  } catch (e) {
    stopMonitoring(monitor);
    if (e instanceof DOMException) {
      console.debug('Error initializing media stream', e);
      const message =
        e.name === 'NotAllowedError'
          ? "Unable to access camera or microphone. Please check your browser's settings."
          : null;
      onEvent({
        type: EVENT_TYPE.ERROR,
        error: ERROR_TYPE.INITIALIZATION,
        message,
      });
    }
  }
};

const stopMonitoring = (monitor: MonitorProps) => {
  if (monitor.handle) {
    clearInterval(monitor.handle);
    monitor.handle = undefined;
    monitor.bytesReceived = undefined;
    monitor.timestamp = undefined;
    monitor.kbps = undefined;
    monitor.fps = undefined;
  }
};

const monitorStream = (
  socket: WebSocket,
  peer: RTCPeerConnection,
  monitor: MonitorProps,
  monitorConfig: MonitorConfig
) => {
  monitor.handle = setInterval(() => {
    peer.getStats(null).then(stats => {
      stats.forEach(res => {
        if (res.type === 'inbound-rtp' && res.mediaType === 'video') {
          if (monitor.timestamp && monitor.bytesReceived) {
            monitor.kbps = Math.floor(
              ((res.bytesReceived - monitor.bytesReceived) * 8) /
                (res.timestamp - monitor.timestamp)
            );
          }
          if (res.framesPerSecond) {
            monitor.fps = res.framesPerSecond;
          }
          monitor.timestamp = res.timestamp;
          monitor.bytesReceived = res.bytesReceived;
          if (monitorConfig.onMonitoringEvent) {
            monitorConfig.onMonitoringEvent(monitor);
          }
          if (monitorConfig.deploymentId && monitor.kbps && monitor.fps) {
            sendMessage(socket, {
              id: 'statistics',
              projectId: monitorConfig.projectId,
              deploymentId: monitorConfig.deploymentId,
              kbps: monitor.kbps,
              fps: monitor.fps,
            });
          }
        }
      });
    });
  }, 3000);
};

const addSocketListeners = (
  socket: WebSocket,
  peer: RTCPeerConnection,
  onEvent: (event: VideoStreamEvent) => void
) => {
  socket.onmessage = async message => {
    const event = JSON.parse(message.data);
    switch (event.id) {
      case 'data':
        onEvent({
          type: EVENT_TYPE.DATA,
          data: event.data,
        });
        break;
      case 'registerResponse':
        if (event.response === 'accepted') {
          onEvent({type: EVENT_TYPE.REGISTERED, sessionId: event.sessionId});
        } else {
          console.debug('Register offer not accepted by peer', event);
          onEvent({
            type: EVENT_TYPE.ERROR,
            error: ERROR_TYPE.INITIALIZATION,
          });
        }
        break;
      case 'startCommunication':
        try {
          await peer.setRemoteDescription(
            new RTCSessionDescription({
              type: 'answer',
              sdp: event.sdpAnswer,
            })
          );
        } catch (e) {
          onEvent({
            type: EVENT_TYPE.ERROR,
            error: ERROR_TYPE.INITIALIZATION,
          });
        }
        break;
      case 'stopCommunication':
        console.debug('Communication ended by server');
        if (event.errorMessage) {
          onEvent({
            type: EVENT_TYPE.ERROR,
            error: ERROR_TYPE.CONNECTION_TERMINATED,
            message: event.errorMessage,
          });
        } else {
          onEvent({
            type: EVENT_TYPE.STREAM_FINISHED,
          });
        }
        break;
      case 'iceCandidate':
        const candidate = new RTCIceCandidate(event.candidate);
        try {
          await peer.addIceCandidate(candidate);
        } catch (e) {
          console.debug('Error adding ice candidate', event.candidate);
        }
        break;
      case 'error':
        console.debug('Received error from server', event);
        onEvent({
          type: EVENT_TYPE.ERROR,
          error: ERROR_TYPE.CONNECTION_TERMINATED,
          message: event.message,
        });
        break;
      default:
        break;
    }
  };
};

const addPeerListeners = (
  socket: WebSocket,
  peer: RTCPeerConnection,
  receiveOnly: boolean,
  source: InputSource | '',
  url: string,
  targetSession: string,
  projectId: string,
  studioTaskId: string | null,
  connectionStatus: VideoConnectionStatus,
  onEvent: (event: VideoStreamEvent) => void,
  ytRestricted?: boolean
) => {
  peer.onnegotiationneeded = async () => {
    try {
      const offer = await peer.createOffer();
      await peer.setLocalDescription(offer);
      if (receiveOnly) {
        onEvent({type: EVENT_TYPE.RECEIVE_ONLY_READY});
        if (connectionStatus.connectSent === false) {
          sendMessage(socket, {
            id: 'connect',
            sdpOffer: peer.localDescription?.sdp,
            source: source,
            videoUrl: url,
            targetSession: targetSession,
            projectId: projectId,
            studioTaskId: studioTaskId,
            ytRestricted: ytRestricted,
          });
          connectionStatus.connectSent = true;
        }
      } else {
        sendMessage(socket, {
          id: 'register',
          sdpOffer: peer.localDescription?.sdp,
          projectId: projectId,
          studioTaskId: studioTaskId,
        });
      }
    } catch (e) {
      console.debug('Error during negotiation', e);
      onEvent({
        type: EVENT_TYPE.ERROR,
        error: ERROR_TYPE.INITIALIZATION,
      });
    }
  };

  peer.onicecandidate = event => {
    if (event.candidate) {
      sendMessage(socket, {
        id: 'onIceCandidate',
        candidate: event.candidate,
      });
    }
  };

  peer.ontrack = event => {
    onEvent({type: EVENT_TYPE.REMOTE_STREAM, stream: event.streams[0]});
  };

  peer.onconnectionstatechange = () => {
    switch (peer.iceConnectionState) {
      case 'connected':
        onEvent({
          type: EVENT_TYPE.CONNECTED,
        });
        break;
      default:
        break;
    }
  };

  peer.oniceconnectionstatechange = () => {
    switch (peer.iceConnectionState) {
      case 'failed':
      case 'closed':
        console.debug('ICE connection state error', peer.iceConnectionState);
        onEvent({
          type: EVENT_TYPE.ERROR,
          error: ERROR_TYPE.INITIALIZATION,
        });
        break;
      default:
        break;
    }
  };

  peer.onsignalingstatechange = () => {
    if (peer.signalingState === 'closed') {
      console.debug('Signaling state error', peer.signalingState);
      onEvent({
        type: EVENT_TYPE.ERROR,
        error: ERROR_TYPE.INITIALIZATION,
      });
    }
  };
};

export const getVideoStream = () => {
  let socket: WebSocket | null,
    peer: RTCPeerConnection | null,
    userStream: MediaStream | null,
    remoteStream: MediaStream | null,
    heartbeatInterval: NodeJS.Timeout,
    monitor: MonitorProps = {};

  const stop = (notifyServer = true) => {
    stopMonitoring(monitor);

    if (userStream) {
      userStream.getTracks().forEach(track => track.stop());
    }

    if (remoteStream) {
      remoteStream.getTracks().forEach(track => track.stop());
    }

    if (heartbeatInterval) {
      clearInterval(heartbeatInterval);
    }

    if (socket) {
      if (notifyServer) {
        sendMessage(socket, {id: 'disconnect'});
      }
      socket.close();
    }

    if (peer) {
      peer.ontrack = null;
      peer.onicecandidate = null;
      peer.oniceconnectionstatechange = null;
      peer.onsignalingstatechange = null;
      peer.onnegotiationneeded = null;
      peer.onconnectionstatechange = null;
      peer.close();
    }
    peer = null;
    socket = null;
  };

  const start = async ({
    receiveOnly = false,
    source = '',
    url = '',
    targetSession = '',
    serverConfig,
    projectId,
    studioTaskId,
    ytRestricted,
    monitorConfig,
    onEvent = () => {},
  }: VideoStreamConfig) => {
    const ICE_SERVERS = [
      {
        urls: [`stun:${serverConfig.turnServerUrl}`],
      },
      {
        urls: [
          `turn:${serverConfig.turnServerUrl}?transport=tcp`,
          `turn:${serverConfig.turnServerUrl}?transport=udp`,
        ],
        username: `${serverConfig.turnServerUsername}`,
        credential: `${serverConfig.turnServerPassword}`,
      },
    ];

    const handleEvent = (event: VideoStreamEvent) => {
      switch (event.type) {
        case EVENT_TYPE.USER_STREAM:
          if (event.stream) {
            userStream = event.stream;
          }
          break;
        case EVENT_TYPE.REMOTE_STREAM:
          if (event.stream) {
            remoteStream = event.stream;
          }
          break;
        case EVENT_TYPE.ERROR:
          const notifyServer = event.error !== ERROR_TYPE.CONNECTION_TERMINATED;
          stop(notifyServer);
          break;
        case EVENT_TYPE.STREAM_FINISHED:
          stop(false);
          break;
        default:
          break;
      }
      event.monitorConfig = monitorConfig;
      onEvent(event);
    };
    try {
      handleEvent({
        type: EVENT_TYPE.CONNECTING,
        receiveOnly,
      });

      socket = await openSocketConnection(
        serverConfig.videoServerUrl ? serverConfig.videoServerUrl : ''
      );
      peer = new RTCPeerConnection({
        iceServers: ICE_SERVERS,
      });
      heartbeatInterval = setInterval(() => {
        if (socket) {
          sendMessage(socket, {
            id: 'heartbeat',
          });
        }
      }, HEARTBEAT_INTERVAL);

      let connectionStatus: VideoConnectionStatus = {connectSent: false};
      addSocketListeners(socket, peer, handleEvent);
      addPeerListeners(
        socket,
        peer,
        receiveOnly,
        source,
        url,
        targetSession,
        projectId,
        studioTaskId,
        connectionStatus,
        handleEvent,
        ytRestricted
      );

      if (receiveOnly) {
        initializeReceiveOnlyStream(socket, peer, handleEvent, monitor, monitorConfig);
      } else {
        initializeMediaStream(socket, peer, handleEvent, monitor, monitorConfig);
      }
    } catch (e) {
      console.debug('Error creating socket', e);
      handleEvent({
        type: EVENT_TYPE.ERROR,
        error: ERROR_TYPE.INITIALIZATION,
      });
    }
  };

  const updateParameters = debounce((parameters: Array<Parameter>) => {
    if (socket) {
      sendMessage(socket, {
        id: 'parameters',
        parameters,
      });
    }
  }, 500);

  return {start, stop, updateParameters};
};
