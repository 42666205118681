import React, {useState} from 'react';
import {Paper} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {StudioTab, StudioTabs} from '../../../base-components/StudioTab';
import {Modules} from '../Modules';
import {FormattedMessage, useIntl} from 'react-intl';
import {
  IntegrationPurpose,
  IntegrationRole,
} from '../../../types/integrations/IntegrationResponse';
import {useMarketplace} from '../../Marketplace';
import {RoleTabs} from '../RoleTabs';
import './Marketplace.scss';
import {useStudioMode} from '../../StudioMode/StudioModeProvider';
import {LiteMarketplaceInfo} from '../../StudioLite/LiteMarketplaceInfo';
import {LITE_TABS} from '../../StudioLite/LiteMarketplaceTabs';

const TABS: Array<{name: string; purpose: IntegrationPurpose}> = [
  {
    name: 'dashboard.marketplace.data',
    purpose: 'DATASET_STORAGE',
  },
  {
    name: 'dashboard.marketplace.model',
    purpose: 'MODEL_STORAGE',
  },
  {
    name: 'dashboard.marketplace.isp',
    purpose: 'ISP_STORAGE',
  },
  {
    name: 'dashboard.marketplace.pp',
    purpose: 'PP_STORAGE',
  },
  {
    name: 'dashboard.marketplace.solution',
    purpose: 'SOLUTION_STORAGE',
  },
];
const PAGE_SIZE = 3;

export const Marketplace = () => {
  const intl = useIntl();
  const {mode} = useStudioMode();
  const isLiteMode = mode === 'LITE';

  const [role, setRole] = useState<IntegrationRole>('PUBLIC');
  const [purpose, setPurpose] = useState<IntegrationPurpose>(
    isLiteMode ? 'SOLUTION_STORAGE' : 'DATASET_STORAGE'
  );

  const showInfoOnly =
    isLiteMode && !(purpose === 'SOLUTION_STORAGE' && role === 'PUBLIC');

  const {
    state: {results},
  } = useMarketplace({
    role,
    purpose,
    pageSize: PAGE_SIZE,
    isEnabled: !showInfoOnly,
  });

  const tabDefinitions = isLiteMode ? LITE_TABS : TABS;

  return (
    <>
      <Paper className="dashboard__paper">
        <RoleTabs role={role} onChange={setRole} />
        <div className="dashboard__item-body">
          <StudioTabs
            value={tabDefinitions.findIndex(tab => tab.purpose === purpose)}
            onChange={(event, index) => {
              const purpose = tabDefinitions[index].purpose;
              setPurpose(purpose);
            }}
            variant="scrollable"
            className="dashboard__tabs"
          >
            {tabDefinitions.map((module, index) => (
              <StudioTab
                key={index}
                label={intl.formatMessage({id: module.name})}
                data-test-id="module-tab"
              />
            ))}
          </StudioTabs>
          {isLiteMode && showInfoOnly ? (
            <div className="marketplace__lite-tab">
              <LiteMarketplaceInfo />
            </div>
          ) : (
            <Modules list={results} type={purpose} />
          )}
        </div>
      </Paper>
      <div className="dashboard__view-all">
        <Link
          to={
            isLiteMode
              ? `/marketplace?role=PUBLIC&purpose=SOLUTION_STORAGE`
              : `/marketplace?role=${role}&purpose=${purpose}`
          }
          data-testid="dashbord-marketplace-view-all"
          id="dashbord-marketplace-view-all"
        >
          <FormattedMessage id="dashboard.viewAll" />
        </Link>
      </div>
    </>
  );
};
