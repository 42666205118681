import {Paper} from '@material-ui/core';
import React from 'react';
import cn from 'classnames';
import HumanReadableTime from '../../../base-components/StudioHumanReadableTime/HumanReadableTime';
import {ApplicationInfo} from '../../../types/deployment/ApplicationsResponse';
import {DatasetDistributionStatistics} from '../../../types/dataset/DatasetDistributionResponse';
import './ApplicationDetails.scss';
import {dataTypeToString} from '../../../types/model/parameter/DataType';
import {optimizerToString} from '../../../types/model/parameter/Optimizer';
import {quantizationTypeToString} from '../../../types/model/parameter/QuantizationType';

export type ApplicationDetailsProps = {
  className?: string;
  description?: string;
  applicationInfo?: ApplicationInfo;
};

export const ApplicationDetails = ({
  className,
  description,
  applicationInfo,
}: ApplicationDetailsProps) => {
  const getDataSplitString = (
    datasetDistributionStatistics: DatasetDistributionStatistics
  ) => {
    const trainCount = datasetDistributionStatistics.trainDistribution?.imagesCount || 0;
    const testCount = datasetDistributionStatistics.testDistribution?.imagesCount || 0;
    const valCount =
      datasetDistributionStatistics.validationDistribution?.imagesCount || 0;
    const total = trainCount + testCount + valCount;
    if (total <= 0 || trainCount < 0 || valCount < 0 || testCount < 0) {
      return 'N/A';
    }
    const trainPercentage = (100 * trainCount) / total;
    const valPercentage = (100 * valCount) / total;
    const testPercentage = 100 - trainPercentage - valPercentage;
    return `${trainPercentage.toFixed(1)}/${valPercentage.toFixed(
      1
    )}/${testPercentage.toFixed(1)}`;
  };

  return applicationInfo ? (
    <Paper className={cn(className, 'application-details')}>
      {Boolean(description) && (
        <div className="application-details__property">
          <div className="application-details__label">Description</div>
          <p>{description}</p>
        </div>
      )}
      {Boolean(applicationInfo.lastUpdated) && (
        <div className="application-details__property">
          <div className="application-details__label">Last Updated</div>
          <p>{<HumanReadableTime date={applicationInfo.lastUpdated} />}</p>
        </div>
      )}
      {Boolean(applicationInfo.datasetDistributionStatistics?.datasetName) && (
        <div className="application-details__property">
          <div className="application-details__label">Training Data</div>
          <p>{applicationInfo.datasetDistributionStatistics?.datasetName}</p>
        </div>
      )}
      {applicationInfo.datasetDistributionStatistics != null && (
        <div className="application-details__property">
          <div className="application-details__label">Data Split (Train/Val/Test %)</div>
          <p>{getDataSplitString(applicationInfo.datasetDistributionStatistics)}</p>
        </div>
      )}
      {Boolean(applicationInfo.modelName) && (
        <div className="application-details__property">
          <div className="application-details__label">Model</div>
          <p>{applicationInfo.modelName}</p>
        </div>
      )}
      {Boolean(applicationInfo.modelPurpose) && (
        <div className="application-details__property">
          <div className="application-details__label">Model Purpose</div>
          <p>{applicationInfo.modelPurpose}</p>
        </div>
      )}
      {applicationInfo.modelOptimizationParameters != null && (
        <div className="application-details__property">
          <div className="application-details__label">Optimization Parameters</div>
          <ul>
            {applicationInfo.modelOptimizationParameters.batchSize != null && (
              <li>
                <span className="application-details__list-key">Batch Size</span>
                <span>{applicationInfo.modelOptimizationParameters.batchSize}</span>
              </li>
            )}
            {applicationInfo.modelOptimizationParameters.dataType != null && (
              <li>
                <span className="application-details__list-key">Bitness</span>
                <span>
                  {dataTypeToString(applicationInfo.modelOptimizationParameters.dataType)}
                </span>
              </li>
            )}
            {applicationInfo.modelOptimizationParameters.quantizationType != null && (
              <li>
                <span className="application-details__list-key">Quantization Type</span>
                <span>
                  {quantizationTypeToString(
                    applicationInfo.modelOptimizationParameters.quantizationType
                  )}
                </span>
              </li>
            )}
          </ul>
        </div>
      )}
      {applicationInfo.modelFinetuningParameters != null && (
        <div className="application-details__property">
          <div className="application-details__label">Finetuning Parameters</div>
          <ul>
            {applicationInfo.modelFinetuningParameters.batchSize != null && (
              <li>
                <span className="application-details__list-key">Batch Size</span>
                <span>{applicationInfo.modelFinetuningParameters.batchSize}</span>
              </li>
            )}
            {applicationInfo.modelFinetuningParameters.dataType != null && (
              <li>
                <span className="application-details__list-key">Bitness</span>
                <span>
                  {dataTypeToString(applicationInfo.modelFinetuningParameters.dataType)}
                </span>
              </li>
            )}
            {applicationInfo.modelFinetuningParameters.epochCount != null && (
              <li>
                <span className="application-details__list-key">Epoch Count</span>
                <span>{applicationInfo.modelFinetuningParameters.epochCount}</span>
              </li>
            )}
            {applicationInfo.modelFinetuningParameters.learningRate != null && (
              <li>
                <span className="application-details__list-key">Learning Rate</span>
                <span>{applicationInfo.modelFinetuningParameters.learningRate}</span>
              </li>
            )}
            {applicationInfo.modelFinetuningParameters.optimizer != null && (
              <li>
                <span className="application-details__list-key">Optimizer</span>
                <span>
                  {optimizerToString(applicationInfo.modelFinetuningParameters.optimizer)}
                </span>
              </li>
            )}
          </ul>
        </div>
      )}
    </Paper>
  ) : null;
};
