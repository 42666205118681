import {LocalImportRequest} from '../../types/importing/LocalImportRequest';
import {request} from '../request';

export type InferenceDataClarifyRequest = LocalImportRequest;

export type InferenceDataClarifyResponse = {};

export async function postInferenceDataClarify(data: InferenceDataClarifyRequest) {
  return request<InferenceDataClarifyResponse>({
    method: 'post',
    url: '/dataset/import/inference/clarify',
    data,
  });
}
