import React from 'react';
import PropTypes from 'prop-types';
import './ValidationCard.scss';
import {FormattedMessage} from 'react-intl';

class ValidationCard extends React.Component {
  render() {
    return (
      <div className="validation-card">
        <h6>
          {this.props.modelPurpose === 'Detection' ||
          this.props.modelPurpose === 'Segmentation' ||
          this.props.modelPurpose === 'PoseDetection'
            ? 'Mean Average Precision'
            : 'Precison'}
        </h6>
        <div className="validation-card-stats" data-testid="validation-card-stats">
          <div className="validation-card-stat float">
            {this.props.floatAccuracy != null ? (
              <>
                <span className="stat-num">{Math.round(this.props.floatAccuracy)}</span>
                <span className="stat-symbol">%</span>
              </>
            ) : (
              <div className="stat-symbol">
                <FormattedMessage id="general.na.short" />
              </div>
            )}
            <br />
            <span className="stat-label">Floating Point</span>
          </div>
          <div className="validation-card-stat comp">
            {this.props.compressedAccuracy != null ? (
              <>
                <span className="stat-num">
                  {Math.round(this.props.compressedAccuracy)}
                </span>
                <span className="stat-symbol">%</span>
              </>
            ) : (
              <div className="stat-symbol">
                <FormattedMessage id="general.na.short" />
              </div>
            )}
            <br />
            <span className="stat-label">Compressed</span>
          </div>
          {this.props.finetunedAccuracy ? (
            <div className="validation-card-stat train">
              <span className="stat-num">{Math.round(this.props.finetunedAccuracy)}</span>
              <span className="stat-symbol">%</span>
              <br />
              <span className="stat-label">Fine-tuned</span>
            </div>
          ) : (
            <div className="validation-card-stat other"></div>
          )}
        </div>
      </div>
    );
  }
}

ValidationCard.propTypes = {
  id: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  floatAccuracy: PropTypes.number,
  compressedAccuracy: PropTypes.number,
  finetunedAccuracy: PropTypes.number,
  trainAccuracy: PropTypes.number,
  modelPurpose: PropTypes.string,
};

export default ValidationCard;
