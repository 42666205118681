import {matchPath} from 'react-router';

export const PrivateRoutes = {
  LANDING: '/',
  CREATE_INFERENCE_APP: '/project/:projectId/inference/:applicationId/create',
  CONFIGURE_INFERENCE_APP:
    '/project/:projectId/inference/:applicationId/deployment/:deploymentId/configure',
  VIEW_TEST_INFERENCE_APP: '/project/:projectId/inference/:applicationId/view',
  VIEW_DEPLOYMENT_INFERENCE_APP:
    '/project/:projectId/inference/:applicationId/deployment/:deploymentId/view',
  INFERENCE_APP_DETAILS: '/project/:projectId/inference/:applicationId/details',
  DEPLOYMENT_INFERENCE_APP_DETAILS:
    '/project/:projectId/inference/:applicationId/deployment/:deploymentId/details',
  DASHBOARD: '/dashboard',
  DATAVIEWER: '/project/:projectId/data/view',
  DATAPREPARE: '/project/:projectId/data/prepare/:context',
  DESIGN: '/project/:projectId/upload',
  TEST: '/project/:projectId/test',
  TEST_COMPARE: '/project/:projectId/test/compare',
  DEPLOY: '/project/:projectId/deployments/new',
  DEPLOY_INFERENCE_APP: '/project/:projectId/inference/:applicationId/deployments/new',
  DEPLOY_ALL: '/project/:projectId/deployments',
  DEPLOY_DETAILS: '/deployment/:deploymentId/details',
  DEPLOYMENT_RETRAIN: '/project/:projectId/retrain',
  DASHBOARD_DEPLOY: '/deployments/new',
  DASHBOARD_DEPLOY_ALL: '/deployments',
  MODELVIEWER: '/project/:projectId/model/view',
  PROJECT: '/project/:projectId/*',
  PROJECTLIST: '/project/list',
  SETTINGS: '/settings',
  FLOWGRAPH: '/project/:projectId/flowgraph/:ispId',
  MARKETPLACE: '/marketplace',
  POSTPROCESSOR_NEW: '/project/:projectId/postprocessor/new',
  POSTPROCESSOR_EDIT: '/project/:projectId/postprocessor/edit/:postProcessorId',
  PREPROCESSOR_NEW: '/project/:projectId/preprocessor/new',
  PREPROCESSOR_EDIT: '/project/:projectId/preprocessor/edit/:preProcessorId',
  HELP: '/guide',
};

export const getProjectId = pathname => {
  const match = matchPath(pathname, PrivateRoutes.PROJECT);
  return match?.params.projectId;
};
