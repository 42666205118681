import React, {Component} from 'react';
import PropTypes from 'prop-types';

class CancelIcon extends Component {
  render() {
    return (
      <div className={this.props.className} data-testid="cancel-icon">
        <svg
          width="18px"
          height="18px"
          viewBox="0 0 18 18"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-847.000000, -2009.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 1215.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-cancel-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M12,19 C8.13400675,19 5,15.8659932 5,12 C5,8.13400675 8.13400675,5 12,5 C15.8659932,5 19,8.13400675 19,12 C19,15.8659932 15.8659932,19 12,19 Z M14.1679688,8.68 L15.3398438,9.851875 L13.1523438,12.0003125 L15.3398438,14.14875 L14.1679688,15.320625 L11.9804688,13.1721875 L9.83203125,15.320625 L8.66015625,14.14875 L10.8085938,12.0003125 L8.66015625,9.851875 L9.83203125,8.68 L11.9804688,10.8284375 L14.1679688,8.68 Z"
                        id="cancel"
                        fill="currentColor"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

CancelIcon.propTypes = {
  className: PropTypes.string,
};

export default CancelIcon;
