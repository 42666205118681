import React from 'react';
import './Help.scss';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>RGB and YUV Color Space Conversion</h4>
      <div className="flow-graph__help_content_detail line2x">
        <p>RGB and YUV color space conversion is used to translate color representation from one color space to the other.</p>
        <p>The RGB color space has many various representations, but they all have one in common: three separate color values are stored for three predefined colors: red, green, and blue. The colors can be ordered starting from red (RGB) or starting from blue (BGR). Most used format is RGB24 (or RGB24p or RGB24bpp) means 24 bits for pixel’s color information (i.e. 8 bits for red, green and for blue).</p>
        <p>In the YUV color space, main channel – luminance (denoted as Y channel) or "luma" (denoted as Y’) describes the intensity of light and the two additional channels - chrominance components called "U" and "V" carry the color information (e.g. as blue-luminance and red-luminance, respectively, for a digital signal – in case of YCbCr).</p>
        <p>The problem with RGB, however, is that it is not the best for representing visual perception. YUV color-spaces are more efficient and reduce the bandwidth more than RGB. Most video cards, therefore, render directly using YUV or luminance/chrominance images. Y(luminance) should have the highest sampling rate, or the same rate as the other components.</p>
        <p>Generally, 4:4:4 YUV format (equal sample per component) is a waste of bandwidth because the chrominance can be adequately sampled at half the sample rate of the luminance without the eye being able to notice the difference. YUV 4:2:2 and 4:2:0 formats are generally used with the preference on 4:2:2. 4:2:2 means the chrominance components are sampled horizontally at half the rate of the luminance. 4:2:0 means the Cb (blue chrominance) and Cr (red chrominance) components are sub-sampled at a factor of 2 in the vertical horizontal directions. Color spaces YUV, YIQ, YCbCr, and YPbPr all belong to the YUV family.</p>
       
        <table>
          <thead>
            <tr>
              <td>RGB to YUV conversion</td>
              <td>YUV to RGB conversion</td>
            </tr>
          </thead>
            <tr>
              <td>Y = (R * 0.299) + (G * 0.587) + (B *0.114)</td>
              <td>R = 1.0 * Y - 0.00000715*U + 1.401997 * V;</td>
            </tr>
            <tr>
              <td>U = ((R * -0.168) + (G * -0.331) + (B * 0.5)) + 128</td>
              <td>G = 1.0 * Y - 0.3441 * U - 0.7141 * V;</td>
            </tr>
            <tr>
              <td>V = ((R * 0.500) + (G * -0.418) + (B * -0.08)) + 128</td>
              <td>B = 1.0 * Y + 1.7720 * U + 0.00001542 * V;</td>
            </tr>
            <tr>
              <td></td>
              <td>R -= 179.45; G+=135.45; B-=226.818</td>
            </tr>
        </table>
      </div>
    </div>
  );
};
