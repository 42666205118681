import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Box, Button, Dialog, IconButton, Toolbar} from '@material-ui/core';
import {Close, AddCircleRounded} from '@material-ui/icons';
import cn from 'classnames';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {StudioSearch} from '../../../base-components/StudioSearch';
import {StudioTextField} from '../../../base-components/StudioTextField';
import {DropArea} from '../../../base-components/StudioDropArea';
import {StudioPopover} from '../../../base-components/StudioPopover';
import {Navigation} from '../Navigation';
import {RootState, useAppDispatch} from '../../../store';
import {useSelector} from 'react-redux';
import {
  dataPrepareActions,
  DataPrepareStep,
  mergeCategories,
} from '../../../store/dataPrepare';
import {
  AnnotationDataType,
  CategoryType,
} from '../../../types/dataset/DatasetGetAnnotationsResponse';
import './LabelView.scss';

type LabelViewProps = {
  projectId: string;
  datasetId?: string;
  inProgress: boolean;
  onForward: () => void;
  onBack: () => void;
};

const {setStep, setIsAddMoreDialogOpen} = dataPrepareActions;

export const LabelView = ({
  datasetId,
  inProgress,
  projectId,
  onForward,
  onBack,
}: LabelViewProps) => {
  const dispatch = useAppDispatch();
  const step = useSelector((state: RootState) => state.dataPrepare.step);
  const annotationData = useSelector(
    (state: RootState) => state.dataPrepare.annotationData
  );
  const isAddMoreDialogOpen = useSelector(
    (state: RootState) => state.dataPrepare.isAddMoreDialogOpen
  );

  const currentCategories = annotationData?.categories || [];
  const currentCategoriesMap = currentCategories.reduce<{
    [key: string]: CategoryType;
  }>((acc, curr) => ({...acc, [curr.name]: curr}), {});

  const handleSave = (newDatasetId: string, updatedAnnotations: AnnotationDataType) =>
    dispatch(
      mergeCategories({
        resourceId: newDatasetId,
        updatedAnnotations,
      })
    );

  const handleAdditionalLabels = (labels: string[]) => {
    if (datasetId && annotationData) {
      const maxCategoryId = currentCategories.reduce(
        (acc, curr) => Math.max(acc, curr.id),
        0
      );
      const dedupedLabels = labels.filter(label => !currentCategoriesMap[label]);
      const newCategories = dedupedLabels.map<CategoryType>((label, index) => ({
        id: maxCategoryId + index + 1,
        name: label,
      }));
      dispatch(setStep(DataPrepareStep.LABEL_REVIEW));
      handleSave(datasetId, {
        ...annotationData,
        categories: [...currentCategories, ...newCategories],
      });
    }
  };

  const handleUpdatedLabels = (labels: string[]) => {
    if (datasetId && annotationData) {
      const maxCategoryId = currentCategories.reduce(
        (acc, curr) => Math.max(acc, curr.id),
        0
      );
      const preExistingCategories = labels
        .filter(label => currentCategoriesMap[label])
        .map(label => currentCategoriesMap[label]);

      const newCategories = labels
        .filter(label => !currentCategoriesMap[label])
        .map((label, index) => ({
          id: maxCategoryId + index + 1,
          name: label,
        }));

      handleSave(datasetId, {
        ...annotationData,
        categories: [...preExistingCategories, ...newCategories],
      });
    }
  };

  const renderStep = () => {
    if (step === DataPrepareStep.LABEL_UPLOAD) {
      return (
        <LabelUpload
          onManual={() => dispatch(setIsAddMoreDialogOpen(true))}
          onChange={additionalLabels => {
            handleAdditionalLabels(additionalLabels);
          }}
        />
      );
    } else if (step === DataPrepareStep.LABEL_REVIEW) {
      return (
        <LabelReview
          labels={annotationData?.categories?.map(category => category.name) || []}
          onChange={handleUpdatedLabels}
          onAddMoreClick={() => dispatch(setIsAddMoreDialogOpen(true))}
        />
      );
    }
  };

  const isValid = Boolean(annotationData?.categories?.length);
  const disableForward = !isValid || inProgress;

  return (
    <>
      <Toolbar className="data-prepare__header" variant="dense">
        <Box flexGrow={1}></Box>
        <Navigation
          onForward={onForward}
          onBack={onBack}
          disableForward={disableForward}
          disableBack={inProgress}
        />
      </Toolbar>
      {renderStep()}
      <AddLabelDialog
        isOpen={isAddMoreDialogOpen}
        onClose={() => dispatch(setIsAddMoreDialogOpen(false))}
        onSubmit={handleAdditionalLabels}
      />
    </>
  );
};

interface LabelUploadProps {
  onChange: (newLabels: string[]) => void;
  onManual: () => void;
}

export const LabelUpload = ({onChange, onManual}: LabelUploadProps) => {
  const handleLabelUpload = (files: File[]) => {
    // TEMP: parse file on client side for now.
    // This is just for demo, need to handle on server side for real implementation
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        const text = reader.result as string;
        if (text) {
          const newLabels = text.split(/[\r\n]+/g).filter(text => text);
          onChange(newLabels);
        }
      };
      reader.readAsText(file);
    });
  };

  return (
    <div className="label-upload">
      <FormattedMessage tagName="h5" id="dataPrep.labelUpload.step" />

      <div>
        <FormattedMessage tagName="span" id="dataPrep.labelUpload.title" />
      </div>
      <DropArea
        className="label-upload__drop"
        onChange={handleLabelUpload}
        accept=".txt"
        size="xlg"
      />
      <Button color="primary" className="label-upload__manual-btn" onClick={onManual}>
        <FormattedMessage tagName="span" id="dataPrep.labelUpload.manualButton" />
      </Button>
    </div>
  );
};

interface LabelReviewProps {
  labels: string[];
  onChange: (labels: string[]) => void;
  onAddMoreClick: () => void;
}

export const LabelReview = ({labels, onChange, onAddMoreClick}: LabelReviewProps) => {
  const intl = useIntl();
  const [search, setSearch] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const getGroups = (labels: string[]) => {
    const groups: {[key: string]: string[]} = {};
    labels.forEach(label => {
      const group = label.charAt(0).toUpperCase();
      if (group) {
        if (!groups[group]) {
          groups[group] = [];
        }
        groups[group].push(label);
      }
    });
    return groups;
  };

  const filteredLabels = labels.filter(label =>
    label.toUpperCase().startsWith(search.toUpperCase())
  );
  const groups = getGroups(filteredLabels);

  return (
    <div className="label-review">
      <div className="label-review__menu">
        <div className="label-review__count">
          <FormattedMessage
            tagName="span"
            id="dataPrep.labelReview.labelsCount"
            values={{labelsCount: filteredLabels.length}}
          />
          <StudioPopover
            ttPlacement="right"
            infoIconClass="label-review__tooltip"
            infoMessage={intl.formatMessage({id: 'dataPrep.labelReview.tooltip'})}
          />
        </div>
        <div className="label-review__btn-group">
          <StudioSearch
            value={search}
            onChange={handleSearchChange}
            placeholder={intl.formatMessage({
              id: 'dataPrep.labelReview.searchPlaceholder',
            })}
          />
          <FormButton
            buttonRole="secondary"
            onClick={onAddMoreClick}
            value={intl.formatMessage({id: 'dataPrep.labelReview.addMore'})}
          />
        </div>
      </div>
      <ul className="label-review__editor">
        {Object.entries(groups).length ? (
          Object.entries(groups)
            .sort(([group1], [group2]) => group1.localeCompare(group2))
            .map(([group, groupLabels], index) => (
              <li className="label-review__group" key={index}>
                <h4 className="label-review__group-header">
                  <span>{group}</span>
                </h4>
                <LabelList
                  labels={groupLabels}
                  onDelete={removedLabel =>
                    onChange(labels.filter(label => label !== removedLabel))
                  }
                />
              </li>
            ))
        ) : (
          <div className="label-review__empty">
            <FormattedMessage tagName="span" id="dataPrep.labelReview.noLabelsFound" />
          </div>
        )}
      </ul>
    </div>
  );
};

interface AddLabelDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (labels: string[]) => void;
}

export const AddLabelDialog = ({isOpen, onClose, onSubmit}: AddLabelDialogProps) => {
  const [newLabel, setNewLabel] = useState('');
  const [labels, setLabels] = useState<string[]>([]);

  const handleLabelDelete = (removedLabel: string) => {
    setLabels(labels => labels.filter(label => label !== removedLabel));
  };

  const handleLabelAdd: React.FormEventHandler = event => {
    event.preventDefault();
    if (newLabel) {
      setLabels(mergeLabelCollections(labels, [newLabel]));
      setNewLabel('');
    }
  };

  const handleSubmit = () => {
    onSubmit(labels);
    // reset form
    setLabels([]);
    setNewLabel('');
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      BackdropProps={{
        invisible: true,
      }}
      className="add-label-dialog"
      onClose={onClose}
    >
      <h2 className="add-label-dialog__title">Add Labels</h2>
      <div className="add-label-dialog__body">
        <form onSubmit={handleLabelAdd}>
          <StudioTextField
            className="add-label-dialog__input"
            autoFocus
            InputProps={{
              placeholder: 'Type a label name',
              onChange: event => {
                setNewLabel(event.target.value);
              },
              value: newLabel,
            }}
          />
          <div className="add-label-dialog__submit">
            <IconButton type="submit" color="primary" disabled={!newLabel}>
              <AddCircleRounded />
            </IconButton>
            <span>or press enter</span>
          </div>
        </form>
        <LabelList labels={labels} onDelete={handleLabelDelete} />
      </div>
      <div className="add-label-dialog__actions">
        <FormButton
          buttonRole="secondary"
          value={'Cancel'}
          onClick={onClose}
          type="button"
        />
        <FormButton
          buttonRole="primary"
          value="Submit"
          type="submit"
          disabled={!labels.length}
          onClick={handleSubmit}
        />
      </div>
    </Dialog>
  );
};

interface LabelListProps {
  className?: string;
  labels: string[];
  onDelete: (label: string) => void;
}

const LabelList = ({labels, onDelete, className}: LabelListProps) => (
  <ul className={cn('label-list', className)}>
    {labels
      .sort((a, b) => a.localeCompare(b))
      .map((label, index) => (
        <li key={index} className="label-list__chip">
          {label}
          <div
            className="label-list__delete"
            role="button"
            onClick={() => onDelete(label)}
          >
            <Close />
          </div>
        </li>
      ))}
  </ul>
);

const mergeLabelCollections = (existingLabels: string[], newLabels: string[]) => {
  const merged: string[] = [...existingLabels];
  newLabels.forEach(newLabel => {
    const isExistingLabel = merged.find(
      label => label.toUpperCase() === newLabel.toUpperCase()
    );
    if (!isExistingLabel) {
      merged.push(newLabel);
    }
  });
  return merged;
};
