import {PostProcessor} from '../../types/postprocessor/PostProcessor';
import {UpdatePostProcessorRequest} from '../../types/postprocessor/UpdatePostProcessorRequest';
import {request} from '../request';

export type UpdatePostProcessorResponse = PostProcessor;

export function updatePostProcessor(data: UpdatePostProcessorRequest) {
  return request<UpdatePostProcessorResponse>({
    method: 'post',
    url: '/postprocessor/update',
    data,
  });
}
