/* eslint-disable react/style-prop-object */
import {Field, FieldProps, Form, Formik, useField, useFormikContext} from 'formik';
import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {useHistory, useLocation, useParams} from 'react-router';
import {Button, Card, IconButton} from '@material-ui/core';
import HumanReadableTime from '../../../base-components/StudioHumanReadableTime/HumanReadableTime';
import {DeployStatus} from '../DeploymentStatus/DeploymentStatus';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {
  EVENT_TYPE,
  getVideoStream,
  VideoStream,
  VideoStreamEvent,
  VideoStreamStatus,
} from '../../TestDeployView/VideoStream';
import {SplitChart} from '../../TestDeployView/SplitChart/SplitChart';
import {RootState, useAppDispatch} from '../../../store';
import {
  fetchDeploymentDetails,
  startDeployment,
  stopDeployment,
} from '../../../store/prodDeployments/dashboard';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from '../../../base-components/StudioToast';
import {actions as retrainActions} from '../../../store/prodDeployments/retrain';
import {Metadata, MetadataList} from '../MetadataList/MetadataList';
import './DeploymentDetails.scss';
import fileSize from 'filesize';
import {InferenceAppDetails} from '../InferenceAppDetails/InferenceAppDetails';
import {InputSource} from '../../../types/deployment/InferenceInputSource';
import {StreamingDevice} from '../../../types/deployment/StreamingDeviceResponse';
import * as Yup from 'yup';
import {StudioSelect} from '../../../base-components/StudioSelect';
import {
  fetchExternalStreamingDevices,
  fetchNodes,
} from '../../../store/prodDeployments/create-deployment';
import {ProductionNode} from '../../../api/deployment/DeploymentNodes';
import {deleteDevice} from '../../../api/device/DeviceList';
import {Clear} from '@material-ui/icons';
import {ConfirmDialog} from '../../../base-components/StudioConfirmDialog';
import {InferenceRequest} from '../../../types/deployment/InferenceRequest';
import {DeploymentSourceInfo} from '../../../types/deployment/DeploymentSourceInfo';
import {DeploymentStatusType} from '../../../types/deployment/DeploymentResponse';
import {DataDriftRawSnapshots} from '../DataDriftFrames/DataDriftRawSnapshots';
import cn from 'classnames';

import DeploymentButton from './DeploymentButton';

const {setRetrainData} = retrainActions;
const UPDATE_INTERVAL = 5000;
const RETRY_INTERVAL = 3000;
const RETRIES = 3;
const {start, stop} = getVideoStream();

const InputSourceTypeOptions: Record<InputChoice, string> = {
  DEVICE: 'On Device',
  EXTERNAL: 'External',
};

export type InputChoice = 'EXTERNAL' | 'DEVICE';
export type InputFormValues = {
  inputSourceType: InputChoice | null;
  inputSource: string | null;
  externalSourceType: InputSource | null;
};

export type SetUp = {
  srcType: boolean;
  extType: boolean;
  srcDev: boolean;
};

type InputFieldParameters = {
  disabled: boolean;
  setup: SetUp;
  setSetup: React.Dispatch<React.SetStateAction<SetUp>>;
};

const InputSourceTypeField = ({disabled, setup, setSetup}: InputFieldParameters) => {
  const intl = useIntl();
  const {values, setFieldValue} = useFormikContext<InputFormValues>();

  return (
    <Field name="inputSourceType">
      {({field, meta}: FieldProps<InputFormValues['inputSourceType']>) => (
        <StudioSelect
          className={cn(
            values.inputSourceType
              ? 'create-prod-deploy__select__selected'
              : 'create-prod-deploy__select__unselected'
          )}
          dataTestId="create-prod-deploy-source-type"
          label={intl.formatMessage({
            id: 'createDeploy.inputSourceType',
          })}
          placeholder={intl.formatMessage({
            id: 'createDeploy.selectSource',
          })}
          disablePlaceholder
          options={InputSourceTypeOptions}
          SelectProps={{
            ...field,
            open: setup.srcType,
            onOpen: () => setSetup({...setup, srcType: true}),
            onClose: () => setSetup({...setup, srcType: false}),
            value: field.value === null ? '' : field.value,
            onChange: event => {
              if (values.inputSourceType !== event.target.value) {
                setFieldValue('inputSourceType', event.target.value);
                setFieldValue('externalSourceType', '');
                setFieldValue('inputSource', '');
              }
            },
          }}
          error={Boolean(meta.error && meta.touched)}
          helperText={meta.error && meta.touched ? meta.error : ''}
          disabled={disabled}
          required
        />
      )}
    </Field>
  );
};

const ExternalSourceTypeField = ({disabled, setup, setSetup}: InputFieldParameters) => {
  const intl = useIntl();
  const {values, setFieldValue} = useFormikContext<InputFormValues>();
  const deployment = useSelector((state: RootState) => state.deploymentDashboard.details);

  // these 3 are unavailable on this screen
  const {
    // @ts-ignore
    TEXT_INPUT,
    // @ts-ignore
    WEB_CAMERA,
    // @ts-ignore
    IMAGES,
    ...extSrcTypOptions
  } = deployment?.supportedExternalInputSources;

  return deployment && values.inputSourceType === 'EXTERNAL' ? (
    <Field name="externalSourceType">
      {({field, meta}: FieldProps<InputFormValues['externalSourceType']>) => (
        <StudioSelect
          className={cn(
            values.externalSourceType
              ? 'create-prod-deploy__select__selected'
              : 'create-prod-deploy__select__unselected'
          )}
          dataTestId="create-prod-deploy-external-source-type"
          label={intl.formatMessage({id: 'createDeploy.externalSourceType'})}
          placeholder={intl.formatMessage({
            id: 'createDeploy.selectSource',
          })}
          disablePlaceholder
          options={extSrcTypOptions}
          SelectProps={{
            ...field,
            open: setup.extType,
            onOpen: () => setSetup({...setup, extType: true}),
            onClose: () => setSetup({...setup, extType: false}),
            value: field.value === null ? '' : field.value,
            onChange: event => {
              if (values.externalSourceType !== event.target.value) {
                setFieldValue('externalSourceType', event.target.value);
                setFieldValue('inputSource', '');
              }
            },
          }}
          error={Boolean(meta.error && meta.touched)}
          helperText={meta.error && meta.touched ? meta.error : ''}
          disabled={disabled}
          required
        />
      )}
    </Field>
  ) : null;
};

type InputSourceFieldParameters = InputFieldParameters & {
  nodes: Array<ProductionNode>;
  externalStreamingDevices: Array<StreamingDevice>;
};

const InputSourceField = ({
  nodes,
  externalStreamingDevices,
  disabled,
  setup,
  setSetup,
}: InputSourceFieldParameters) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    values: {inputSourceType, externalSourceType},
    setFieldValue,
  } = useFormikContext<InputFormValues>();
  const deployment = useSelector((state: RootState) => state.deploymentDashboard.details);

  const [confirmDeleteDevice, setConfirmDeleteDevice] = useState<{
    isOpen: boolean;
    id: string | null;
  }>({isOpen: false, id: null});

  // const inputSourceTypeRef = useRef(inputSourceType);
  const [field, meta] = useField('inputSource');

  const onDeviceSources =
    nodes.find(node => node.id === deployment?.nodeId)?.sources || [];
  const onDeviceSourceOptions = onDeviceSources.reduce<Record<string, string>>(
    (acc, {sourceId, sourceName}) => {
      acc[sourceId] = sourceName;
      return acc;
    },
    {}
  );
  const externalDeviceOptions = externalStreamingDevices.reduce<Record<string, string>>(
    (acc, curr) => {
      if (curr.type === externalSourceType) {
        acc[curr.id] = curr.name;
      }
      return acc;
    },
    {}
  );

  const inputSourceOptions =
    inputSourceType === 'DEVICE' ? onDeviceSourceOptions : externalDeviceOptions;

  if (field.value) {
    // If the upper input type's are changed its possible that the selected
    // input source no longer applies.  If a selected value exists ensure that it is one of the options
    // if not clear it.
    const matchedOption = Object.keys(inputSourceOptions).find(
      element => element === field.value
    );

    if (!matchedOption) {
      setFieldValue('inputSource', '');
    }
  }

  const handleDeviceDelete = async (id: string) => {
    try {
      const response = await deleteDevice(id);
      if (!response?.errors?.length) {
        await dispatch(fetchExternalStreamingDevices());
        toast.success(intl.formatMessage({id: 'test.deleteDeviceSuccessMessage'}));
      }
    } catch (e) {}
  };

  const renderDeleteDevice = (id: string) => {
    return (
      <IconButton
        size="small"
        color="secondary"
        onClick={event => {
          event.stopPropagation();
          setConfirmDeleteDevice({isOpen: true, id});
        }}
      >
        <Clear />
      </IconButton>
    );
  };

  const handleInputSourceChange = () => {};

  return Boolean(
    inputSourceType === 'DEVICE' ||
      (inputSourceType === 'EXTERNAL' &&
        (externalSourceType === 'IP_CAMERA' || externalSourceType === 'FILE'))
  ) ? (
    <>
      <StudioSelect
        className={cn(
          field.value
            ? 'create-prod-deploy__select__selected'
            : 'create-prod-deploy__select__unselected'
        )}
        label={intl.formatMessage({
          id:
            inputSourceType === 'DEVICE'
              ? 'createDeploy.deviceSource'
              : 'createDeploy.externalSource',
        })}
        placeholder="Select a source"
        dataTestId="create-prod-deploy-input-source"
        options={inputSourceOptions}
        disablePlaceholder
        SelectProps={{
          ...field,
          open: setup.srcDev,
          onOpen: () => setSetup({...setup, srcDev: true}),
          onClose: () => setSetup({...setup, srcDev: false}),
          value: field.value === null ? '' : field.value,
        }}
        error={Boolean(meta.error && meta.touched)}
        onChange={handleInputSourceChange}
        helperText={meta.error && meta.touched ? meta.error : ''}
        disabled={disabled}
        renderEndAdornment={inputSourceType === 'EXTERNAL' ? renderDeleteDevice : null}
        required
      />
      <ConfirmDialog
        type="confirm"
        title={intl.formatMessage({id: 'test.deleteDeviceConfirmTitle'})}
        message={intl.formatMessage({id: 'test.deleteDeviceConfirmMessage'})}
        submitLabel={intl.formatMessage({
          id: 'form.delete',
        })}
        open={confirmDeleteDevice.isOpen}
        onClose={() => setConfirmDeleteDevice({isOpen: false, id: null})}
        onOk={() => {
          confirmDeleteDevice.id && handleDeviceDelete(confirmDeleteDevice.id);
          setConfirmDeleteDevice({isOpen: false, id: null});
        }}
      />
    </>
  ) : null;
};

export const DeploymentDetails = () => {
  const [isPlayingSolution, setIsPlayingSolution] = useState(false);
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
  const [streamStatus, setStreamStatus] = useState<VideoStreamStatus>('idle');
  const [isDeploying, setIsDeploying] = useState(false);
  const [retries, setRetries] = useState(RETRIES);
  const [setup, setSetup] = useState<SetUp>({
    srcType: false,
    extType: false,
    srcDev: false,
  });

  const history = useHistory();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {deploymentId} = useParams<{
    deploymentId: string;
  }>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const projectContext = params.get('projectContext');
  const origin = params.get('origin');

  const serverConfig = useSelector((state: RootState) => state.config);
  const deployment = useSelector((state: RootState) => state.deploymentDashboard.details);
  const optimisticallyUpdatedStatus = useSelector(
    (state: RootState) =>
      state.deploymentDashboard.optimisticallyUpdatedStatuses[deploymentId]
  );
  const nodes = useSelector((state: RootState) =>
    state.createDeployment.nodes?.filter(node => node?.status === 'ONLINE')
  );
  const externalStreamingDevices = useSelector(
    (state: RootState) => state.createDeployment.externalStreamingDevices
  );

  const modelPurpose = useMemo(() => deployment?.applicationPurpose, [
    deployment?.applicationPurpose,
  ]);
  const status = useMemo(() => optimisticallyUpdatedStatus ?? deployment?.status, [
    optimisticallyUpdatedStatus,
    deployment?.status,
  ]);
  const projectId = useMemo(() => deployment?.projectId, [deployment?.projectId]);
  const storedDeploymentId = useMemo(() => deployment?.id, [deployment?.id]);
  const signalingServerUrl = useMemo(() => serverConfig.videoServerUrl, [
    serverConfig.videoServerUrl,
  ]);
  const videoOutputRtspUrl = useMemo(() => deployment?.videoOutputRtspUrl, [
    deployment?.videoOutputRtspUrl,
  ]);

  const inputSourceLocationType = useMemo(
    () => deployment?.source?.inputSourceLocationType,
    [deployment?.source?.inputSourceLocationType]
  );
  const inputSourceId = useMemo(() => deployment?.source?.inputSourceId, [
    deployment?.source?.inputSourceId,
  ]);
  const inputSourceType = useMemo(() => deployment?.source?.inputSourceType, [
    deployment?.source?.inputSourceType,
  ]);
  const streamingUrl = useMemo(() => deployment?.source?.streamingUrl, [
    deployment?.source?.streamingUrl,
  ]);

  const [expectedStatus, setExpectedStatus] = useState<DeploymentStatusType | null>();

  useEffect(() => {
    if (expectedStatus === 'RUNNING' && status === 'RUNNING') {
      toast.success(`Successfully started deployment ${deployment?.name}`);
      setExpectedStatus(null);
    }

    if (expectedStatus === 'IDLE' && status === 'IDLE') {
      toast.success(`Successfully stopped deployment ${deployment?.name}`);
      setExpectedStatus(null);
    }

    if (expectedStatus === 'RUNNING' && status === 'IDLE') {
      const errorMessage = deployment?.errorMessage;
      if (errorMessage) {
        toast.error('Failed to start deployment: ' + errorMessage);
      } else {
        toast.error('Failed to start deployment.');
      }
    }
  }, [expectedStatus, status, deployment?.name, deployment?.errorMessage]);

  const areDeploymentSourcesValid = useCallback(() => {
    if (inputSourceLocationType === 'DEVICE' && inputSourceId) {
      return true;
    }

    if (
      inputSourceLocationType === 'EXTERNAL' &&
      inputSourceType &&
      (inputSourceId || streamingUrl)
    ) {
      return true;
    }

    return false;
  }, [inputSourceLocationType, inputSourceId, inputSourceType, streamingUrl]);

  const isSettingUp = useCallback(() => setup.srcDev || setup.extType || setup.srcType, [
    setup,
  ]);

  useEffect(() => {
    if (isDeploying || isSettingUp()) return;

    const nodesPromise = dispatch(fetchNodes());
    const deviceListPromise = dispatch(fetchExternalStreamingDevices());
    let deploymentsPromise = dispatch(fetchDeploymentDetails(deploymentId));
    let interval: NodeJS.Timeout;

    if ((areDeploymentSourcesValid() && status !== 'ERROR') || status === 'DEPLOYING') {
      interval = setInterval(() => {
        deploymentsPromise = dispatch(fetchDeploymentDetails(deploymentId));
      }, UPDATE_INTERVAL);
    }

    return () => {
      nodesPromise.abort();
      deviceListPromise.abort();
      deploymentsPromise.abort();
      clearInterval(interval);
    };
  }, [
    deploymentId,
    dispatch,
    status,
    modelPurpose,
    isDeploying,
    isSettingUp,
    areDeploymentSourcesValid,
  ]);

  const handleStreamEvent = useCallback(
    (event: VideoStreamEvent) => {
      switch (event.type) {
        case EVENT_TYPE.CONNECTING:
          setStreamStatus('inProgress');
          break;
        case EVENT_TYPE.RECEIVE_ONLY_READY:
          setStreamStatus(isPlayingSolution ? 'ready' : 'complete');
          break;
        case EVENT_TYPE.REMOTE_STREAM:
          if (event.stream) {
            setRemoteStream(event.stream);
          }
          break;
        case EVENT_TYPE.STREAM_FINISHED:
          setStreamStatus('complete');
          break;
        case EVENT_TYPE.CONNECTED:
          setStreamStatus('connected');
          break;
        case EVENT_TYPE.ERROR:
          if (retries <= 0) {
            setStreamStatus('error');

            if (event.message) {
              toast.error(`Streaming error: ${event.message}`);
            } else {
              toast.error(`Streaming error. Please try again.`);
            }
          } else {
            setTimeout(
              () => setRetries(retriesQuantity => retriesQuantity - 1),
              RETRY_INTERVAL
            );
          }
          break;
        default:
          break;
      }
    },
    [retries, isPlayingSolution]
  );

  useEffect(() => {
    if (
      videoOutputRtspUrl &&
      signalingServerUrl &&
      projectId &&
      status === 'RUNNING' &&
      retries >= 0
    ) {
      start({
        receiveOnly: true,
        // Hardcode as "IP_CAMERA" for now, but if we add Web Camera support in the future, we will
        // need to retrieve the source type dynamically from the BE.
        source: 'IP_CAMERA',
        url: videoOutputRtspUrl,
        projectId,
        serverConfig,
        studioTaskId: null,
        monitorConfig: {
          monitorStream: true,
          projectId,
          deploymentId: storedDeploymentId,
        },
        onEvent: handleStreamEvent,
      });

      const cleanup = () => stop();
      window.addEventListener('beforeunload', cleanup);

      return () => {
        window.removeEventListener('beforeunload', cleanup);
        cleanup();
      };
    }
  }, [
    videoOutputRtspUrl,
    serverConfig,
    signalingServerUrl,
    status,
    projectId,
    storedDeploymentId,
    retries,
    handleStreamEvent,
  ]);

  const handleDetailsDeployAction = async (values: InputFormValues) => {
    if (status === 'IDLE') {
      if (isDeploying) {
        return;
      }
      setIsDeploying(true);
      setStreamStatus('idle');
      setRetries(RETRIES);
      if (deployment) {
        if (!values.inputSource) {
          toast.error('input source is missing');
          return;
        }
        const deploymentStartRequest: InferenceRequest = {
          deploymentId: deploymentId,
          inputSource: {
            resourceId: values.inputSource,
            inputSourceType:
              values.inputSourceType === 'DEVICE'
                ? 'DEVICE_CAMERA'
                : values.externalSourceType || null,
            regions: externalStreamingDevices.find(
              device => device.id === values.inputSource
            )?.regions,
            lines: externalStreamingDevices.find(
              device => device.id === values.inputSource
            )?.lines,
          },
        };

        const result = await dispatch(startDeployment(deploymentStartRequest));
        if (result.meta.requestStatus === 'fulfilled') {
          setExpectedStatus('RUNNING');
        }
        setIsDeploying(false);
      }
    }
  };

  const handleStop = async () => {
    setIsPlayingSolution(false);

    const result = await dispatch(stopDeployment(deploymentId));

    if (result.meta.requestStatus === 'fulfilled') {
      setStreamStatus('complete');
      setExpectedStatus('IDLE');
    }
  };

  const handleStart = () => {
    setIsPlayingSolution(true);
  };

  const initialValues: InputFormValues = {
    inputSourceType: null,
    inputSource: null,
    externalSourceType: null,
  };

  if (deployment && deployment.source) {
    const source: DeploymentSourceInfo = deployment.source;
    if (deployment.source.inputSourceLocationType === 'DEVICE') {
      initialValues.inputSourceType = 'DEVICE';
      initialValues.inputSource = source.inputSourceId;
      initialValues.externalSourceType = 'DEVICE_CAMERA';
    } else if (deployment.source.inputSourceLocationType === 'EXTERNAL') {
      initialValues.inputSourceType = 'EXTERNAL';
      if (source.inputSourceId) {
        initialValues.inputSource = source.inputSourceId;
      } else if (source.streamingUrl) {
        initialValues.inputSource =
          externalStreamingDevices.find(device => device.url === source.streamingUrl)
            ?.id || null;
      }
      initialValues.externalSourceType = source.inputSourceType;
    }
  }
  const inputFormSchema = Yup.object().shape({
    inputSourceType: Yup.string()
      .required('Required')
      .typeError('Required'),
    inputSource: Yup.string()
      .required('Required')
      .typeError('Required'),
    externalSourceType: Yup.string()
      .nullable(true)
      .when('inputSourceType', {
        is: 'EXTERNAL',
        then: Yup.string()
          .nullable(false)
          .required('Required')
          .typeError('Required'),
      }),
  });

  return (
    <>
      {deployment ? (
        <Formik
          initialValues={initialValues}
          validationSchema={inputFormSchema}
          validateOnMount
          onSubmit={handleDetailsDeployAction}
          enableReinitialize
        >
          <div className="prod-deploy-details" data-testid="prod-deploy-details">
            <div className="prod-deploy-details__menu">
              {origin === 'dashboard' && (
                <Button
                  className="prod-deploy-dashboard__back"
                  variant="contained"
                  disableElevation
                  onClick={() => history.push('/dashboard')}
                >
                  {<FormattedMessage id={'prodDeployment.returnToDashboard'} />}
                </Button>
              )}
              <Button
                className="prod-deploy-details__back"
                variant="contained"
                disableElevation
                onClick={() =>
                  history.push(
                    projectContext
                      ? `/project/${projectContext}/deployments`
                      : '/deployments'
                  )
                }
              >
                <FormattedMessage id="createDeploy.viewAll" />
              </Button>
            </div>
            <div className="prod-deploy-details__body">
              <div className="prod-deploy-details__info">
                <div className="prod-deploy-details__header">
                  <h3>{deployment.name}</h3>
                  <div className="prod-deploy-details__status">
                    {status && <DeployStatus status={status} />}
                    {deployment.startTime ? (
                      <div>
                        <HumanReadableTime
                          className="prod-deploy-details__time"
                          date={deployment.startTime}
                        />
                      </div>
                    ) : null}
                  </div>
                  <DeploymentButton
                    onStop={handleStop}
                    onStart={handleStart}
                    deployment={deployment}
                    statusToDisplay={status}
                  />
                  {deployment?.retrainAvailable && (
                    <Button
                      color="primary"
                      variant="contained"
                      disableElevation
                      className="prod-deploy-details__retrain"
                      onClick={() => {
                        dispatch(
                          setRetrainData({
                            projectId,
                            deploymentId,
                          })
                        );
                        history.push(`/project/${projectId}/retrain`);
                      }}
                    >
                      <FormattedMessage id="prodDeployment.retrain" />
                    </Button>
                  )}
                </div>
                <MetadataList>
                  <Metadata label="prodDeployment.description">
                    {deployment.deploymentDescription}
                  </Metadata>
                  {deployment && deployment.internalInputSourcesSupported && (
                    <>
                      <Form
                        id="deployment-details-form"
                        className="prod-deploy-details__form"
                        noValidate
                      >
                        <InputSourceTypeField
                          disabled={status !== 'IDLE'}
                          setup={setup}
                          setSetup={setSetup}
                        />
                        <ExternalSourceTypeField
                          disabled={status !== 'IDLE'}
                          setup={setup}
                          setSetup={setSetup}
                        />
                        <InputSourceField
                          nodes={nodes}
                          externalStreamingDevices={externalStreamingDevices}
                          disabled={status !== 'IDLE'}
                          setup={setup}
                          setSetup={setSetup}
                        />
                      </Form>
                    </>
                  )}
                  {deployment && modelPurpose === 'Classification' && (
                    <>
                      <h5>{intl.formatMessage({id: 'prodDeployment.inferenceApp'})}</h5>
                      <InferenceAppDetails
                        deploymentId={deploymentId}
                        applicationId={deployment.applicationId}
                        projectId={deployment.projectId}
                      />
                    </>
                  )}
                  <Metadata label="prodDeployment.device">{deployment.nodeName}</Metadata>
                  <Metadata label="prodDeployment.board">{deployment.boardName}</Metadata>
                  <Metadata label="prodDeployment.application">
                    {deployment.applicationName}
                  </Metadata>
                  <Metadata label="prodDeployment.model">{deployment.modelName}</Metadata>
                  <Metadata label="prodDeployment.dataDrift">
                    {deployment.dataDrift && (
                      <ul>
                        {deployment.dataDrift.skewPercent != null && (
                          <li>
                            <span className="prod-deploy-details__label">
                              {intl.formatMessage({id: 'prodDeployment.skew'})}
                            </span>
                            <FormattedNumber
                              style="percent"
                              value={deployment.dataDrift.skewPercent / 100}
                            />
                          </li>
                        )}
                        {deployment.dataDrift.confidencePercent != null && (
                          <li>
                            <span className="prod-deploy-details__label">
                              {intl.formatMessage({id: 'prodDeployment.confidence'})}
                            </span>
                            <FormattedNumber
                              style="percent"
                              value={deployment.dataDrift.confidencePercent / 100}
                            />
                          </li>
                        )}
                      </ul>
                    )}
                  </Metadata>
                  {deployment.gspUtilization && (
                    <Metadata label="prodDeployment.resourceUsage">
                      <ul>
                        {deployment.gspUtilization?.gspUtilization != null && (
                          <li>
                            <span className="prod-deploy-details__label">
                              {intl.formatMessage({id: 'prodDeployment.gspUtil'})}
                            </span>
                            <FormattedNumber
                              style="percent"
                              value={deployment.gspUtilization.gspUtilization / 100}
                            />
                          </li>
                        )}
                        {deployment.gspUtilization?.memoryUsage != null && (
                          <li>
                            <span className="prod-deploy-details__label">
                              {intl.formatMessage({id: 'prodDeployment.memUtil'})}
                            </span>
                            <span>
                              {fileSize(deployment.gspUtilization.memoryUsage, {
                                base: 10,
                              })}
                            </span>
                          </li>
                        )}
                        {deployment.gspUtilization?.memoryTotal != null && (
                          <li>
                            <span className="prod-deploy-details__label">
                              {intl.formatMessage({id: 'prodDeployment.memTotal'})}
                            </span>
                            <span>
                              {fileSize(deployment.gspUtilization.memoryTotal, {
                                base: 10,
                              })}
                            </span>
                          </li>
                        )}
                        {deployment.gspUtilization?.gspTemperature != null && (
                          <li>
                            <span className="prod-deploy-details__label">
                              {intl.formatMessage({id: 'prodDeployment.temp'})}
                            </span>
                            <FormattedNumber
                              style="unit"
                              unit="celsius"
                              value={deployment.gspUtilization.gspTemperature}
                            />
                          </li>
                        )}
                        {deployment.gspUtilization?.powerConsumption != null && (
                          <li>
                            <span className="prod-deploy-details__label">
                              {intl.formatMessage({id: 'prodDeployment.power'})}
                            </span>
                            <span>
                              {`${deployment.gspUtilization?.powerConsumption}W`}{' '}
                            </span>
                          </li>
                        )}
                      </ul>
                    </Metadata>
                  )}
                  {deployment.deploymentStatistics?.fps ? (
                    <Metadata label="prodDeployment.appStatistics">
                      <ul>
                        {deployment.deploymentStatistics?.fps != null && (
                          <li>
                            <span className="prod-deploy-details__label">
                              {intl.formatMessage({id: 'testCompare.throughput'})}
                            </span>
                            <FormattedNumber
                              value={deployment.deploymentStatistics.fps}
                              maximumFractionDigits={1}
                            />
                          </li>
                        )}
                        {deployment.deploymentStatistics?.latency != null && (
                          <li>
                            <span className="prod-deploy-details__label">
                              {intl.formatMessage({id: 'testCompare.latency'})}
                            </span>
                            <FormattedNumber
                              value={deployment.deploymentStatistics.latency}
                              maximumFractionDigits={0}
                            />
                            {' ms'}
                          </li>
                        )}
                        {deployment.deploymentStatistics?.inputVideoBps != null && (
                          <li>
                            <span className="prod-deploy-details__label">
                              {intl.formatMessage({id: 'testCompare.inputVideoBps'})}
                            </span>
                            <FormattedNumber
                              value={deployment.deploymentStatistics.inputVideoBps}
                              maximumFractionDigits={0}
                            />
                            {' bps'}
                          </li>
                        )}
                        {deployment.deploymentStatistics?.inputVideoFps != null && (
                          <li>
                            <span className="prod-deploy-details__label">
                              {intl.formatMessage({id: 'testCompare.inputVideoFps'})}
                            </span>
                            <FormattedNumber
                              value={deployment.deploymentStatistics.inputVideoFps}
                              maximumFractionDigits={1}
                            />
                            {' fps'}
                          </li>
                        )}
                        {deployment.deploymentStatistics?.outputVideoBps != null && (
                          <li>
                            <span className="prod-deploy-details__label">
                              {intl.formatMessage({id: 'testCompare.outputVideoBps'})}
                            </span>
                            <FormattedNumber
                              value={deployment.deploymentStatistics.outputVideoBps}
                              maximumFractionDigits={0}
                            />
                            {' bps'}
                          </li>
                        )}
                        {deployment.deploymentStatistics?.outputVideoFps != null && (
                          <li>
                            <span className="prod-deploy-details__label">
                              {intl.formatMessage({id: 'testCompare.outputVideoFps'})}
                            </span>
                            <FormattedNumber
                              value={deployment.deploymentStatistics.outputVideoFps}
                              maximumFractionDigits={1}
                            />
                            {' fps'}
                          </li>
                        )}
                      </ul>
                    </Metadata>
                  ) : null}
                  <Metadata label="prodDeployment.dataset">
                    {deployment.dataSetDistribution?.datasetName}
                  </Metadata>
                  <Metadata label="prodDeployment.dataDist">
                    {deployment.dataSetDistribution && (
                      <SplitChart
                        className="prod-deploy-details__chart"
                        training={
                          deployment.dataSetDistribution.trainDistribution?.imagesCount ||
                          0
                        }
                        testing={
                          deployment.dataSetDistribution.testDistribution?.imagesCount ||
                          0
                        }
                        validation={
                          deployment.dataSetDistribution.validationDistribution
                            ?.imagesCount || 0
                        }
                      />
                    )}
                  </Metadata>
                </MetadataList>
              </div>
              <Card className="prod-deploy-details__results">
                {deployment && projectId && streamStatus === 'idle' ? (
                  <>
                    <DataDriftRawSnapshots
                      projectId={projectId}
                      deploymentId={deploymentId}
                      modelPurpose={modelPurpose}
                    />
                  </>
                ) : (
                  <>
                    <h4>{intl.formatMessage({id: 'prodDeployment.inferenceResults'})}</h4>
                    <VideoStream
                      status={streamStatus}
                      receiveOnly
                      userStream={null}
                      remoteStream={remoteStream}
                      canIPlayVideo={isPlayingSolution}
                      className="prod-deploy-details__video"
                    />
                  </>
                )}
              </Card>
            </div>
          </div>
        </Formik>
      ) : null}
    </>
  );
};
