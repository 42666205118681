const modelSources = {
  BASE_MODEL: 'Base Model',
  FILE_UPLOAD: 'Custom Model',
  TL_MODEL: 'Transfer Learning',
};

export type ModelSource = keyof typeof modelSources;

export function modelSourceToString(modelSource: ModelSource) {
  return modelSources[modelSource] ?? modelSource;
}
