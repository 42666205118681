import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {FormattedMessage} from 'react-intl';
import FormButton from '../../../base-components/StudioButton/FormButton';

export type DuplicateDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onRename: () => void;
};

export const DuplicateDialog = ({isOpen, onClose, onRename}: DuplicateDialogProps) => {
  return (
    <Dialog open={isOpen} data-testid="deploy-export-dialog">
      <DialogTitle className="dplmt-export-title">
        <div className="dplmt-export-title-top">
          <div className="dplmt-export-title-text">
            <Typography color="primary" variant="h6">
              <FormattedMessage id="form.duplicates" />
            </Typography>
          </div>
          <IconButton
            onClick={onClose}
            className="dplmt-export-title-text"
            data-testid="deploy-export-close-button"
            id="deploy-export-close-button"
          >
            <Close />
          </IconButton>
        </div>
        <DialogContentText>
          <FormattedMessage id="deployments.duplicates" />
        </DialogContentText>
      </DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <FormButton buttonRole="primary" value="Cancel" onClick={onClose} />
        <FormButton
          type="submit"
          buttonRole="primary"
          value="Rename"
          onClick={onRename}
        />
      </DialogActions>
    </Dialog>
  );
};
