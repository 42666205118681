import {Parameter} from '../../../types/model/framework/Parameter';
import {Field, FieldProps} from 'formik';
import React from 'react';
import {StudioCheckbox} from '../../../base-components/StudioCheckbox';
import {StudioSelect} from '../../../base-components/StudioSelect';
import {StudioTextField} from '../../../base-components/StudioTextField';
import * as Yup from 'yup';
import {parseNumericInterval} from './NumericIntervalField';

export type ParameterFieldProps = {
  parameter: Pick<Parameter, 'dataType' | 'options' | 'displayName' | 'description'>;
  fieldName: string;
  disabled?: boolean;
};

export const getParameterValidationRule = (param: Parameter) => {
  if (param.dataType === 'FLAG') {
    return Yup.boolean();
  } else if (
    param.dataType === 'TEXT' ||
    param.dataType === 'MULTILINE_TEXT' ||
    param.dataType === 'MULTIPLE_CHOICES'
  ) {
    return Yup.string();
  } else if (param.dataType === 'URL') {
    return Yup.string().url();
  } else if (param.dataType === 'FLOATING_POINT_NUMBER' || param.dataType === 'INTEGER') {
    let validationRule = Yup.number();
    if (param.dataType === 'INTEGER') {
      validationRule = validationRule.integer();
    }

    if (param.numericInterval) {
      const interval = parseNumericInterval(param.numericInterval);
      if (interval.min != null) {
        validationRule = interval.excludeMin
          ? validationRule.moreThan(interval.min)
          : validationRule.min(interval.min);
      }
      if (interval.max != null) {
        validationRule = interval.excludeMax
          ? validationRule.lessThan(interval.max)
          : validationRule.max(interval.max);
      }
    }
    return validationRule;
  }
};

export const ParameterField = ({parameter, fieldName, disabled}: ParameterFieldProps) => {
  const {dataType, options, displayName, description} = parameter;
  if (dataType === 'MULTIPLE_CHOICES') {
    return (
      <Field name={fieldName}>
        {({field, meta}: FieldProps<string>) => {
          const errorText =
            meta.initialError || (meta.error && meta.touched) ? meta.error : '';
          return (
            <StudioSelect
              options={options}
              label={displayName}
              tooltip={description}
              helperText={errorText}
              error={!!errorText}
              disabled={disabled}
              fullWidth
              SelectProps={{
                ...field,
              }}
              fixedTooltip
            />
          );
        }}
      </Field>
    );
  } else if (
    dataType === 'FLOATING_POINT_NUMBER' ||
    dataType === 'INTEGER' ||
    dataType === 'TEXT' ||
    dataType === 'MULTILINE_TEXT' ||
    dataType === 'URL'
  ) {
    const typeMap = {
      FLOATING_POINT_NUMBER: 'number',
      INTEGER: 'number',
      TEXT: 'text',
      MULTILINE_TEXT: 'text',
      URL: 'url',
    };

    return (
      <Field name={fieldName}>
        {({field, meta}: FieldProps<string>) => {
          const errorText =
            meta.initialError || (meta.error && meta.touched) ? meta.error : '';
          return (
            <StudioTextField
              label={displayName}
              tooltip={description}
              type={typeMap[dataType]}
              multiline={dataType === 'MULTILINE_TEXT'}
              helperText={errorText}
              error={!!errorText}
              fixedTooltip
              disabled={disabled}
              inputProps={{
                step:
                  dataType === 'FLOATING_POINT_NUMBER'
                    ? '0.1'
                    : dataType === 'INTEGER'
                    ? '1'
                    : null,
              }}
              {...field}
            />
          );
        }}
      </Field>
    );
  } else if (dataType === 'FLAG') {
    return (
      <Field name={fieldName} type="checkbox">
        {({field, form}: FieldProps<string>) => {
          const checkboxProps = {
            ...field,
            id: fieldName,
            checked: field.value === 'true',
            onChange: () =>
              form.setFieldValue(fieldName, field.value === 'true' ? 'false' : 'true'),
          };
          return (
            <StudioCheckbox
              label={displayName}
              tooltip={description}
              fixedTooltip
              disabled={disabled}
              {...checkboxProps}
            />
          );
        }}
      </Field>
    );
  }
  return null;
};
