import React from 'react';
import cn from 'classnames';
import './ProjectButton.scss';

export const ProjectButton = ({children, className, ...rest}) => (
  <div className={cn('project-button', className)} {...rest}>
    <div className="project-button__container">{children}</div>
  </div>
);

export const ProjectButtonColumn = ({children, className, ...rest}) => (
  <div className={cn('project-button__column', className)} {...rest}>
    {children}
  </div>
);

export const ProjectButtonDescription = ({children, className, ...rest}) => (
  <div className={cn('project-button__description', className)} {...rest}>
    {children}
  </div>
);

export const ProjectButtonName = ({children, className, ...rest}) => (
  <div className={cn('project-button__name', className)} {...rest}>
    {children}
  </div>
);
