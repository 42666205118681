import React, {RefObject} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {IntegrationPurpose} from '../../../types/integrations/IntegrationResponse';
import {actions} from '../../../store/marketplace/add-isp';
import {ISPUploadActionBase} from '../../StageView/StageActions/ISPUploadAction';
import {ImportDialog} from './ImportDialog';
import {SortableColumn} from '../../../types/marketplace/MarketplaceFilesRequest';

export type AddISPDialogProps = {
  actionRef?: RefObject<InstanceType<typeof ISPUploadActionBase>>;
};

const PURPOSE: IntegrationPurpose = 'ISP_STORAGE';
const {setIsOpen} = actions;
const columns: SortableColumn[] = [
  'displayName',
  'transformationType',
  'applicationType',
  'executionContainer',
  'provider',
  'creationDateTime',
  'size',
  'studioVersion',
];

export const AddISPDialog = ({actionRef}: AddISPDialogProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.addISPDialog.isOpen);
  const initialMarketplaceState = useSelector(
    (state: RootState) => state.addISPDialog.initialMarketplaceState
  );

  return (
    <ImportDialog
      isOpen={isOpen}
      title="Add Pre Processor"
      purpose={PURPOSE}
      columns={columns}
      onClose={() => dispatch(setIsOpen({isOpen: false}))}
      onSubmit={item =>
        actionRef?.current?.ispUpload([
          {
            name: item.name,
            type: item.type,
            integrationId: item.integrationId,
          },
        ])
      }
      initialState={initialMarketplaceState}
      defaultFilterValues={{compatibleOnly: true}}
    />
  );
};
