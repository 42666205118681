import React, {useEffect} from 'react';
import cn from 'classnames';
import {ErrorMessage, useField, useFormikContext} from 'formik';
import {StudioTextField} from '../../base-components/StudioTextField';
import FormButton from '../../base-components/StudioButton/FormButton';
import {Grid} from '@material-ui/core';
import {StudioAutoComplete} from '../../base-components/StudioAutoComplete';
import {StudioPopover} from '../../base-components/StudioPopover';
import './CreateISPFormFields.scss';
import {useIntl} from 'react-intl';
import {ISP_SETTINGS} from './../../config/constants';

const hasError = meta => {
  return Boolean(meta.touched && meta.error);
};

export const ISPTextField = ({name, label, className, InputProps, ...rest}) => {
  const [field, meta] = useField(name);

  return (
    <StudioTextField
      label={label}
      error={hasError(meta)}
      helperText={<ErrorMessage name={name} />}
      className={cn('isp-form__form-control', className)}
      fullWidth
      data-testid={name}
      id={name}
      InputProps={{
        ...InputProps,
        ...field,
      }}
      {...rest}
    />
  );
};

export const ISPAutoComplete = ({name, label, className, options, testId}) => {
  const [field, meta, helpers] = useField(name);
  const [fieldValue, setFieldValue] = React.useState(null);
  const {setValue} = helpers;

  useEffect(() => {
    options &&
      field?.value &&
      setFieldValue(options.find(item => field.value === item.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, options]);

  return (
    <StudioAutoComplete
      {...field}
      className={cn('isp-form__form-control', className)}
      value={fieldValue}
      options={options}
      getOptionLabel={option => option.label}
      onChange={(e, obj) => {
        obj && setValue(obj.value);
      }}
      data-testid={testId}
      id={testId}
      renderInput={params => (
        <StudioTextField
          {...params}
          {...field}
          error={hasError(meta)}
          helperText={<ErrorMessage name={name} />}
          className={cn('studio-text-field')}
          label={label}
        />
      )}
    />
  );
};

export const CancelButton = ({children, className, ...rest}) => {
  const intl = useIntl();
  return (
    <FormButton
      type="button"
      buttonRole="naked"
      value={intl.formatMessage({id: 'form.cancel'})}
      {...rest}
    />
  );
};

export const SubmitButton = ({children, className, ...rest}) => {
  const {dirty, isSubmitting, isValid, handleSubmit} = useFormikContext();
  const intl = useIntl();

  return (
    <FormButton
      type="submit"
      buttonRole="primary"
      value={intl.formatMessage({id: 'form.submit'})}
      onClick={handleSubmit}
      disabled={!dirty || !isValid || isSubmitting}
      {...rest}
    />
  );
};

export const ISPForm = ({className, colorSpaceOptions}) => {
  const intl = useIntl();
  const inputColorSpaceOptions = colorSpaceOptions;

  return (
    <Grid container className={className}>
      <Grid container item xs={12}>
        <ISPTextField
          name="name"
          className="modify-isp-form__name"
          placeholder={intl.formatMessage({id: 'isp.namePlaceholder'})}
          autoFocus
          inputProps={{
            maxLength: ISP_SETTINGS.name.maxLength,
          }}
        />
      </Grid>
      <Grid container item xs={12}>
        <ISPTextField
          name="description"
          label={intl.formatMessage({id: 'isp.descriptionLabel'})}
          placeholder={intl.formatMessage({id: 'isp.descriptionPlaceholder'})}
          multiline={ISP_SETTINGS.description.multiline}
          inputProps={{
            className: ISP_SETTINGS.description.className,
            maxLength: ISP_SETTINGS.description.maxLength,
          }}
        />
      </Grid>
      <Grid container item xs={5}>
        <ISPTextField
          name="input_width"
          label={intl.formatMessage({id: 'isp.width'})}
          type="number"
        />
      </Grid>
      <Grid container item xs={2} className="isp-form__wh_divider">
        X
      </Grid>
      <Grid container item xs={5}>
        <ISPTextField
          name="input_height"
          label={intl.formatMessage({id: 'isp.height'})}
          type="number"
        />
      </Grid>
      <Grid container item xs={12}>
        <ISPTextField
          name="bpp"
          label={intl.formatMessage({id: 'isp.bpp'})}
          type="number"
          disabled
        />
        <StudioPopover
          infoIconClass="isp-form__tooltip"
          infoMessage={intl.formatMessage({id: 'isp.bppTooltip'})}
        />
      </Grid>
      <Grid container item xs={12}>
        <ISPAutoComplete
          name="input_color_space"
          label={intl.formatMessage({id: 'isp.inputColorSpace'})}
          options={inputColorSpaceOptions}
          testId="create-isp-form-input-color-space"
        />
      </Grid>
    </Grid>
  );
};
