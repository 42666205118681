import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ChevronRightIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="7px"
          height="12px"
          viewBox="0 0 7 12"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <polygon
              id="chevronRightIcon"
              points="9.21 7.7890625 10.4990625 6.5 15.9990625 12 10.4990625 17.5 9.21 16.2109375 13.4209375 12"
            ></polygon>
          </defs>
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-853.000000, -3032.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 2235.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-chevron-right-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#chevronRightIcon"></use>
                      </mask>
                      <use
                        id="chevron-right"
                        fill="currentColor"
                        xlinkHref="#chevronRightIcon"
                      ></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

ChevronRightIcon.propTypes = {
  className: PropTypes.string,
};

export default ChevronRightIcon;
