import React from 'react';

export const ISPPlaceholderIcon = props => {
  return (
    <div>
      <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="37.5" y="44.5" width="1" height="37" fill="#D8D8D8" stroke="#979797" />
        <g filter="url(#filter0_d_2_4843)">
          <rect x="22" y="28" width="32" height="16" rx="2" fill="white" />
        </g>
        <g filter="url(#filter1_d_2_4843)">
          <rect x="22" y="52" width="32" height="16" rx="2" fill="white" />
        </g>
        <path
          d="M54 84H56C58.2091 84 60 82.2091 60 80V40C60 37.7909 61.7909 36 64 36H66"
          stroke="#979797"
          strokeWidth="2"
          strokeLinecap="square"
        />
        <g filter="url(#filter2_d_2_4843)">
          <rect x="22" y="76" width="32" height="16" rx="2" fill="white" />
        </g>
        <rect x="81.5" y="38.5" width="1" height="19" fill="#D8D8D8" stroke="#979797" />
        <rect
          x="66"
          y="28"
          width="32"
          height="16"
          rx="2"
          fill="url(#paint0_linear_2_4843)"
        />
        <g filter="url(#filter3_d_2_4843)">
          <rect x="66" y="52" width="32" height="16" rx="2" fill="white" />
        </g>
        <defs>
          <filter
            id="filter0_d_2_4843"
            x="8"
            y="16"
            width="60"
            height="44"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="7" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2_4843"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2_4843"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_2_4843"
            x="8"
            y="40"
            width="60"
            height="44"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="7" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2_4843"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2_4843"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_2_4843"
            x="8"
            y="64"
            width="60"
            height="44"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="7" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2_4843"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2_4843"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_2_4843"
            x="52"
            y="40"
            width="60"
            height="44"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="7" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2_4843"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2_4843"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_2_4843"
            x1="51.7998"
            y1="51.1348"
            x2="112.179"
            y2="51.1348"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00F6FB" />
            <stop offset="1" stopColor="#59198C" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
