import React from 'react';

export const ModelPlaceholderIcon = props => {
  return (
    <div>
      <svg
        width="95px"
        height="87px"
        viewBox="0 0 95 87"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={props.style}
      >
        <title>670BCB55-D3DD-44D0-914D-D67E81FF74AD</title>
        <defs>
          <rect id="path-1" x="0" y="6" width="26" height="16" rx="2"></rect>
          <filter
            x="-84.6%"
            y="-125.0%"
            width="269.2%"
            height="375.0%"
            filterUnits="objectBoundingBox"
            id="filter-2"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="7"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <rect id="path-3" x="39" y="47" width="26" height="12" rx="2"></rect>
          <filter
            x="-84.6%"
            y="-166.7%"
            width="269.2%"
            height="466.7%"
            filterUnits="objectBoundingBox"
            id="filter-4"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="7"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <rect id="path-5" x="39" y="32" width="26" height="12" rx="2"></rect>
          <filter
            x="-84.6%"
            y="-166.7%"
            width="269.2%"
            height="466.7%"
            filterUnits="objectBoundingBox"
            id="filter-6"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="7"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <rect id="path-7" x="37" y="0" width="30" height="12" rx="2"></rect>
          <filter
            x="-73.3%"
            y="-166.7%"
            width="246.7%"
            height="466.7%"
            filterUnits="objectBoundingBox"
            id="filter-8"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="7"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
        </defs>
        <g
          id="Illustrations"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g transform="translate(-494.000000, -822.000000)" id="Model-Illustration">
            <g transform="translate(56.000000, 400.000000)">
              <g transform="translate(0.000000, 344.000000)">
                <g
                  id="Illustration-/-Model-/-Default"
                  transform="translate(416.000000, 52.000000)"
                >
                  <g id="model-icon_active" transform="translate(36.000000, 38.000000)">
                    <rect
                      id="Container"
                      fillOpacity="0"
                      fill="#FFFFFF"
                      x="0"
                      y="0"
                      width="72"
                      height="72"
                    ></rect>
                    <path
                      d="M27,36 L27,55.5236967 C27,58.5612628 29.4624339,61.0236967 32.5,61.0236967 L32.5,61.0236967 L34.0236967,61.0236967 C36.0985091,61.0236967 37.9263744,62.081003 38.9978905,63.6862136 C40.0716755,62.067392 41.9111864,61 44,61 L47,61 C49.209139,61 51,59.209139 51,57 L51,56 L53,56 L53,57 C53,60.3137085 50.3137085,63 47,63 L44,63 C41.790861,63 40,64.790861 40,67 L40,68 L38,68 L38,67 C38,64.8039483 36.2197484,63.0236967 34.0236967,63.0236967 L34.0236967,63.0236967 L32.5,63.0236967 C28.3578644,63.0236967 25,59.6658323 25,55.5236967 L25,55.5236967 L25,36 L27,36 Z M53.05,41 L53.05,48 L51.05,48 L51.05,41 L53.05,41 Z M14,21 L14,22 C14,24.2480323 15.8223902,26.0704225 18.0704225,26.0704225 L18.0704225,26.0704225 L21.0704225,26.0704225 C23.1349235,26.0704225 24.9529991,27.1254948 26.0148642,28.7258542 C27.0572582,27.2074306 28.8066621,26.2112676 30.7887324,26.2112676 L46.7887324,26.2112676 C49.1145513,26.2112676 51,24.3258189 51,22 L51,21 L53.05,21 L53.05,33 L51.05,33 L51.049811,26.5192103 C49.9376232,27.5682545 48.4382697,28.2112676 46.7887324,28.2112676 L30.7887324,28.2112676 C28.7616626,28.2112676 27.106404,29.8031803 27.0049299,31.8050323 L27,32 L27,33 L25,33 L25,32 C25,29.8297543 23.2406682,28.0704225 21.0704225,28.0704225 L21.0704225,28.0704225 L18.0704225,28.0704225 C14.7178207,28.0704225 12,25.3526018 12,22 L12,22 L12,21 L14,21 Z M53.05,11 L53.05,18 L51.05,18 L51.05,11 L53.05,11 Z"
                      id="Combined-Shape"
                      fill="#9C9C9C"
                    ></path>
                    <rect
                      id="color"
                      fill="#C8C8C8"
                      x="34"
                      y="67"
                      width="10"
                      height="5"
                      rx="2.5"
                    ></rect>
                    <rect
                      id="color"
                      fill="#C8C8C8"
                      x="21"
                      y="32"
                      width="10"
                      height="5"
                      rx="2.5"
                    ></rect>
                    <rect
                      id="color"
                      fill="#C8C8C8"
                      x="47"
                      y="17"
                      width="10"
                      height="5"
                      rx="2.5"
                    ></rect>
                    <g id="Shadow" fill="black" fillOpacity="1">
                      <use filter="url(#filter-2)"></use>
                    </g>
                    <g id="Shadow" fill="black" fillOpacity="1">
                      <use filter="url(#filter-4)"></use>
                    </g>
                    <g id="Shadow" fill="black" fillOpacity="1">
                      <use filter="url(#filter-6)"></use>
                    </g>
                    <g id="Shadow" fill="black" fillOpacity="1">
                      <use filter="url(#filter-8)"></use>
                    </g>
                    <rect
                      id="color"
                      fill="#FFFFFF"
                      x="0"
                      y="6"
                      width="26"
                      height="16"
                      rx="2"
                    ></rect>
                    <rect
                      id="color"
                      fill="#FFFFFF"
                      x="39"
                      y="47"
                      width="26"
                      height="12"
                      rx="2"
                    ></rect>
                    <rect
                      id="color"
                      fill="#FFFFFF"
                      x="39"
                      y="32"
                      width="26"
                      height="12"
                      rx="2"
                    ></rect>
                    <rect
                      id="color"
                      fill="#FFFFFF"
                      x="37"
                      y="0"
                      width="30"
                      height="12"
                      rx="2"
                    ></rect>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
