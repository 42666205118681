import Axios from 'axios';
import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router';
import {Field, FieldProps, Form, Formik, useFormikContext} from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import axios from 'axios';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, IconButton} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';

import URL from '../../../config/url';
import Util from '../../../util';
import {useTestListByLatest} from '../../../api/test/TestList';
import {StudioSelect} from '../../../base-components/StudioSelect';
import {StudioTextField} from '../../../base-components/StudioTextField';
import {getProjectData, projectFetch} from '../../../store/project';
import {ApplicationDetails} from '../ApplicationDetails/ApplicationDetails';
import {
  fetchApplications,
  fetchExternalStreamingDevices,
} from '../../../store/prodDeployments/create-deployment';
import {ApplicationInfo} from '../../../types/deployment/ApplicationsResponse';
import {DeploymentInitiateRequest} from '../../../types/deployment/DeploymentInitiateRequest';
import {Points} from '../../../types/deployment/StreamingDeviceResponse';
import {DeploymentResponse} from '../../../types/deployment/DeploymentResponse';
import {Project} from '../../../types/project/Project';
import {RootState} from '../../../store';
import {StatusResponse} from '../../../types/operation/StatusResponse';
import {useProjectList} from '../../ProjectList/useProjectList';
import {BaseModel} from '../../../types/job/BaseModel';
import {ProductionNode} from '../../../api/deployment/DeploymentNodes';
import {DEPLOY_PANEL_SETTINGS} from '../../../config/constants';
import {toast} from '../../../base-components/StudioToast';
import {useDeploymentEnvironmentVariables} from './../../../api/deployment/DeploymentEnvironmentVariables';
import './CreateDeployment.scss';

type ProdDeployFormValues = {
  projectId: string;
  name: string;
  description: string | null;
  applicationId: string;
  nodeId: string;
  boardId: string;
  samplingFrequency: number | null;
  nonMaximumSuppression: number | null;
  ddaDataMaxSize: number | null;
  environment?: {[key: string]: string} | {};
  confThreshold: number | null;
  regions?: Points[];
  lines?: Points[];
};

const DATA_EXTRACTION_PERIOD = 5000;
const DEPLOY_TASK_TYPE = 'DEPLOY_APPLICATION';

async function getNodes(applicationId: string) {
  const {data} = await Axios.get(URL.COMPATIBLE_NODES_GET, {
    params: {applicationId: applicationId, onlineOnly: true},
  });
  return data.body;
}

export const CreateDeployment = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const origin = params.get('origin');
  const deployId = params.get('deployId');

  // application Id here is from path /project/${projectId}/inference/${applicationId}/deployments/new
  const {projectId: initialProjectId, applicationId: inferenceApplicationId} = useParams<{
    projectId: string;
    applicationId: string;
  }>();
  // application Id here is from query params
  const paramsApplicationId = params.get('applicationId');

  // checking for application ID using the 2 above
  const applicationId = inferenceApplicationId
    ? inferenceApplicationId
    : paramsApplicationId;

  const isProjectContext = Boolean(initialProjectId);

  const [isDeploying, setIsDeploying] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const applications: Array<ApplicationInfo> = useSelector(
    (state: RootState) => state.createDeployment.applications
  );

  const monitorProgress = useCallback((projectId: string) => {
    return new Promise<void>(resolve => {
      let isPolling = true;

      const pollForStatus = async () => {
        try {
          const {data} = await axios.get<StatusResponse>(
            URL.PROJECT_OP_STATUS(projectId)
          );
          const tasks = data.body?.tasks;
          const status = tasks?.find(task => task.type === DEPLOY_TASK_TYPE);
          if (status) {
            status.statusMessage && setStatusMessage(status.statusMessage);
            if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
              isPolling = false;
              resolve();
            }
          }
        } finally {
          if (isPolling) {
            setTimeout(pollForStatus, 2000);
          }
        }
      };

      pollForStatus();
    });
  }, []);

  const finalizeDeployment = useCallback(
    async projectId => {
      const {data} = await axios.post<DeploymentResponse>(URL.FINALIZE_DEPLOYMENT, null, {
        params: {projectId},
      });
      if (data.errors?.length) {
        throw new Error('Finalize error');
      }
      history.push(
        isProjectContext
          ? `/deployment/${data.body.id}/details?projectContext=${initialProjectId}`
          : `/deployment/${data.body.id}/details`
      );
    },
    [isProjectContext, initialProjectId, history]
  );

  useEffect(() => {
    const resumeDeployment = async () => {
      if (initialProjectId) {
        try {
          const {data} = await axios.get<StatusResponse>(
            URL.PROJECT_OP_STATUS(initialProjectId)
          );
          const {tasks} = data.body;
          const exists = tasks?.find(task => task.type === DEPLOY_TASK_TYPE);
          if (!exists) {
            return;
          }
          setIsDeploying(true);
          await monitorProgress(initialProjectId);
          await finalizeDeployment(initialProjectId);
        } catch (e) {
          console.error(e);
        } finally {
          setIsDeploying(false);
          setStatusMessage('');
        }
      }
    };
    resumeDeployment();
  }, [finalizeDeployment, monitorProgress, initialProjectId]);

  const projectData: Project = useSelector(getProjectData);

  let [selectedApplication, setSelectedApplication] = useState<
    ApplicationInfo | undefined | null
  >(null);

  const {data: historyItems} = useTestListByLatest({
    avoidFetch: !deployId,
    projectId: projectData.id,
  });
  const deployHistoryItem = useMemo(
    () => historyItems?.entries.find(deployItem => deployItem.id === deployId),
    [historyItems, deployId]
  );

  const {
    data: originalEnvironment,
    error: errorFetchingEnvironmentVariables,
  } = useDeploymentEnvironmentVariables({
    latest: !deployId, // If no specific item from history, get latest variables. If there's a specific item, use default variables and deploy variables.
    projectId: projectData.id,
    applicationId: selectedApplication?.id,
  });

  let [compatibleNodes, setCompatibleNodes] = useState<
    Array<ProductionNode> | undefined | null
  >(null);

  const selectApplication = async (app: ApplicationInfo | undefined | null) => {
    setSelectedApplication(app);
    if (!app) {
      setCompatibleNodes([]);
    } else {
      console.log('getNodes: ');
      let nodes = await getNodes(app.id);
      console.log(nodes);
      setCompatibleNodes(nodes);
    }
  };

  let environment = Object.assign(
    {},
    originalEnvironment,
    deployHistoryItem?.environment || {}
  );

  const initialValues: ProdDeployFormValues = {
    name: origin === 'test' ? selectedApplication?.name! : '',
    description: '',
    applicationId: applicationId || '',
    projectId: initialProjectId || '',
    nodeId: '',
    boardId: '',
    samplingFrequency: 60,
    nonMaximumSuppression: 0.5,
    ddaDataMaxSize: 1000,
    environment: {},
    confThreshold: 0.25,
  };

  const handleSubmit = async (values: ProdDeployFormValues) => {
    const {projectId} = values;
    const initiateRequestBody: DeploymentInitiateRequest = {
      projectId,
      name: values.name,
      applicationId: values.applicationId,
      nodeId: values.nodeId,
      boardId: values.boardId,
      description: values.description,
      deploymentType: 'PRODUCTION',
      parameters: {},
    };

    initiateRequestBody.parameters = {
      samplingFrequency: values.samplingFrequency,
      nonMaximumSuppression: values.nonMaximumSuppression,
      ddaExtractionPeriod: DATA_EXTRACTION_PERIOD,
      ddaDataMaxSize: values.ddaDataMaxSize,
      streamingUrl: null,
      streamingDeviceId: null,
      environment: values.environment,
      confThreshold: values.confThreshold,
    };

    try {
      setIsDeploying(true);
      const {data} = await axios.post<DeploymentResponse>(
        URL.INITIATE_DEPLOYMENT,
        initiateRequestBody
      );

      if (data.errors?.length) {
        throw new Error('Error initiating deployment');
      }

      await monitorProgress(projectId);
      await finalizeDeployment(projectId);
      environment = Object.assign({}, originalEnvironment);
    } catch (e) {
      console.error(e);
    } finally {
      setIsDeploying(false);
      setStatusMessage('');
    }
  };

  const prodDeployFormSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().nullable(true),
    projectId: Yup.string().required('Required'),
    applicationId: Yup.string().required('Required'),
    nodeId: Yup.string().required('Required'),
    boardId: Yup.string().required('Required'),
    samplingFrequency: Yup.number()
      .integer('Please enter an integer')
      .nullable(true)
      .required('Required'),
    nonMaximumSuppression: Yup.number()
      .min(0, 'Please enter a value between 0 and 1')
      .max(1, 'Please enter a value between 0 and 1')
      .nullable(true),
    ddaDataMaxSize: Yup.number()
      .integer('Please enter an integer')
      .nullable(true),
    confThreshold: Yup.number()
      .min(0, 'Please enter a value between 0 and 1')
      .max(1, 'Please enter a value between 0 and 1')
      .nullable(true),
  });

  useEffect(() => {
    dispatch(fetchExternalStreamingDevices());
    if (applicationId && selectedApplication == null) {
      const application: ApplicationInfo | undefined = applications.find(
        app => app.id === applicationId
      );
      selectApplication(application);
    }
  }, [dispatch, applications, applicationId, selectedApplication]);

  useEffect(() => {
    if (errorFetchingEnvironmentVariables) {
      toast.error(errorFetchingEnvironmentVariables);
    }
  }, [errorFetchingEnvironmentVariables]);

  return (
    <div className="create-prod-deploy" data-testid="create-prod-deploy">
      <Formik
        initialValues={initialValues}
        validationSchema={prodDeployFormSchema}
        validateOnMount
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({dirty, isValid}) => (
          <>
            <div className="create-prod-deploy__menu">
              {isProjectContext && origin === 'test' && (
                <Button
                  className="create-prod-deploy__back"
                  variant="contained"
                  disableElevation
                  onClick={() => history.push(`/project/${initialProjectId}/test`)}
                >
                  <FormattedMessage id="prodDeployment.returnToTest" />
                </Button>
              )}
              <Button
                className="create-prod-deploy__back"
                variant="contained"
                disableElevation
                onClick={() =>
                  history.push(
                    isProjectContext
                      ? `/project/${initialProjectId}/deployments`
                      : '/deployments'
                  )
                }
              >
                <FormattedMessage id="createDeploy.viewAll" />
              </Button>
              <Button
                className="create-prod-deploy__submit"
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
                form="prod-deploy-form"
                disabled={!isValid || isDeploying || !dirty}
              >
                <FormattedMessage id="form.submit" />
              </Button>
            </div>
            {isDeploying && (
              <p
                className="create-prod-deploy__status"
                data-testid="create-prod-deploy-status"
                id="create-prod-deploy-status"
              >
                {statusMessage || <FormattedMessage id={'prodDeployment.inProgress'} />}
              </p>
            )}
            <div className="create-prod-deploy__main">
              <h3>
                <FormattedMessage id="createDeploy.create" />
              </h3>
              <CreateDeploymentForm
                environment={environment}
                projectData={projectData}
                isDeploying={isDeploying}
                isProjectContext={isProjectContext}
                selectApplication={selectApplication}
                getCompatibleNodes={() => compatibleNodes}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

const CreateDeploymentForm = ({
  environment,
  projectData,
  isDeploying,
  isProjectContext,
  selectApplication,
  getCompatibleNodes,
}: {
  environment: Record<string, string>;
  projectData: Project;
  isDeploying: boolean;
  isProjectContext: boolean;
  selectApplication: Function;
  getCompatibleNodes: () => Array<ProductionNode> | undefined | null;
}) => {
  const PROJECT_LIST_PAGE_SIZE = 5;
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    values: {projectId, applicationId, nodeId, boardId},
    setFieldValue,
  } = useFormikContext<ProdDeployFormValues>();
  const [revealAppBar, setRevealAppBar] = useState(false);

  const [targetValueSelected, setTargetValueSelected] = useState(false);

  useEffect(() => {
    if (projectId) {
      dispatch(projectFetch(projectId));
      dispatch(fetchApplications(projectId));
    }
  }, [projectId, dispatch, setFieldValue]);

  const showEnv = Object.keys(environment).length > 0;

  useEffect(() => {
    setFieldValue('environment', environment);
  }, [environment, setFieldValue]);

  const applications = useSelector(
    (state: RootState) => state.createDeployment.applications
  );
  const {data: projectListData, totalCount, loadMore: loadMoreProjects} = useProjectList(
    PROJECT_LIST_PAGE_SIZE,
    '',
    'name',
    'asc'
  );

  const projectList =
    projectListData?.flatMap(projectResponse => projectResponse?.body?.projects) || [];
  const projectOptions = projectList.reduce<Record<string, string>>((acc, curr) => {
    acc[curr.id] = curr.name;
    return acc;
  }, {});
  let projectOptionsOrder: string[];
  if (projectId && projectData) {
    // ensure current project is available in options
    projectOptions[projectId] = projectData.name || '';
    projectOptionsOrder = [
      projectId,
      ...projectList
        .filter(project => project.id !== projectId)
        .map(project => project.id),
    ];
  }
  const [supportedNodes, supportedModels] = useMemo(() => {
    const supportedNodes = (getCompatibleNodes() || []).filter(
      node => node?.status === 'ONLINE'
    );
    const supportedModels = new Set<BaseModel>();

    supportedNodes
      .flatMap(node => node?.runtimes)
      .flatMap(node => node?.supportedModels)
      .flatMap(node => node?.baseModel)
      .forEach(node => node && supportedModels.add(node));

    return [supportedNodes, supportedModels];
  }, [getCompatibleNodes]);

  const disabledApplications = applications.reduce<Record<string, boolean>>(
    (acc, curr) => {
      acc[curr.id] = !curr.compatibleRuntimes
        ?.map(x => x.type)
        .some(x => x !== 'ONNX_RUNTIME_WEB');
      return acc;
    },
    {}
  );

  const applicationOptions = applications.reduce<Record<string, string>>((acc, curr) => {
    acc[curr.id] = curr.name;
    return acc;
  }, {});
  const nodesOptions = supportedNodes?.reduce<Record<string, string>>((acc, curr) => {
    acc[curr.id] = curr.name;
    return acc;
  }, {});
  const boardOptions = (
    supportedNodes?.find(node => node.id === nodeId)?.boards || []
  ).reduce<Record<string, string>>((acc, curr) => {
    acc[curr.id] = curr.deviceName;
    return acc;
  }, {});
  let selectedApplication: ApplicationInfo | null | undefined = applications.find(
    app => app.id === applicationId
  );

  const supportedModelsAlertList = useMemo(() => {
    let supportList = '';
    const modelsList = Array.from(supportedModels);
    const filteredYoloModels = modelsList.filter(model => model.includes('YOLO'));

    if (filteredYoloModels.length) {
      const yoloVersions = Array.from(
        new Set(filteredYoloModels.map(model => model.match(/V(\d+)/)?.[1]))
      ).join('/');

      supportList = `${supportList}\n${intl.formatMessage(
        {
          id: 'createDeploy.modelsCompatibilityAlert.yolo',
        },
        {
          yoloVersions,
        }
      )}`;
    }

    if (
      modelsList.find(
        supportedModel => ['RESNET50', 'SWIFTNET'].indexOf(supportedModel) !== -1
      )
    ) {
      supportList = `${supportList}\n${intl.formatMessage({
        id: 'createDeploy.modelsCompatibilityAlert.resnet50',
      })}`;
    }

    if (modelsList.includes('PYACT_APP')) {
      supportList = `${supportList}\n${intl.formatMessage({
        id: 'createDeploy.modelsCompatibilityAlert.pyActApp',
      })}`;
    }

    if (modelsList.includes('PYTHON_APP')) {
      supportList = `${supportList}\n${intl.formatMessage({
        id: 'createDeploy.modelsCompatibilityAlert.pythonApp',
      })}`;
    }

    return supportList ? `\n${supportList}` : supportList;
  }, [supportedModels, intl]);

  return (
    <Form className="create-prod-deploy__form" id="prod-deploy-form" noValidate>
      <div className="create-prod-deploy__row">
        {!isProjectContext && (
          <Field name="projectId">
            {({field, meta}: FieldProps<ProdDeployFormValues['projectId']>) => (
              <StudioSelect
                className="create-prod-deploy__field"
                dataTestId="create-prod-deploy-project"
                label={intl.formatMessage({id: 'createDeploy.project'})}
                options={projectOptions}
                order={projectOptionsOrder}
                onLoadMore={loadMoreProjects}
                hasMore={totalCount > projectList.length}
                placeholder={intl.formatMessage({
                  id: 'createDeploy.selectProject',
                })}
                disablePlaceholder
                SelectProps={{
                  ...field,
                  onChange: e => {
                    field.onChange(e);
                    setFieldValue('applicationId', '');
                  },
                }}
                error={Boolean(meta.error && meta.touched)}
                helperText={meta.error && meta.touched ? meta.error : ''}
                disabled={isDeploying}
                required
              />
            )}
          </Field>
        )}
        <Field name="applicationId">
          {({field, meta}: FieldProps<ProdDeployFormValues['applicationId']>) => (
            <>
              <StudioSelect
                className={cn(
                  applicationId
                    ? 'create-prod-deploy__select__selected'
                    : 'create-prod-deploy__select__unselected'
                )}
                dataTestId="create-prod-deploy-application"
                label={intl.formatMessage({id: 'createDeploy.application'})}
                options={applicationOptions}
                placeholder={intl.formatMessage({
                  id: 'createDeploy.selectApplication',
                })}
                disablePlaceholder
                SelectProps={{
                  ...field,
                  onChange: (e: React.ChangeEvent<any>) => {
                    field.onChange(e);
                    const newApplicationId = e.target.value;
                    selectedApplication = applications.find(
                      app => app.id === newApplicationId
                    );
                    selectApplication(selectedApplication);
                    const newSupportedNodes = getCompatibleNodes();
                    if (!newSupportedNodes?.find(node => node.id === nodeId)) {
                      setFieldValue('nodeId', '');
                    }
                  },
                }}
                error={Boolean(meta.error && meta.touched)}
                helperText={meta.error && meta.touched ? meta.error : ''}
                disabled={isDeploying}
                required
                disabledOptions={disabledApplications}
              />
              <IconButton
                className={cn(
                  'create-prod-deploy__reveal',
                  field.value && !meta.error && 'create-prod-deploy__reveal--visible'
                )}
                onClick={() => setRevealAppBar(reveal => !reveal)}
                disabled={isDeploying}
              >
                {revealAppBar ? <VisibilityOff /> : <Visibility />}
              </IconButton>
              {selectedApplication && (
                <Alert severity="warning" className="create-prod-deploy__alert">
                  {supportedModels.size ? (
                    <>
                      <FormattedMessage
                        tagName="span"
                        id="createDeploy.compatibilityAlert.Some"
                      />
                      <span>{supportedModelsAlertList}</span>
                    </>
                  ) : (
                    <FormattedMessage
                      tagName="span"
                      id="createDeploy.compatibilityAlert.None"
                    />
                  )}
                </Alert>
              )}
            </>
          )}
        </Field>
      </div>
      <div className="create-prod-deploy__app-info">
        <Field name="applicationId">
          {({field, meta}: FieldProps<ProdDeployFormValues['applicationId']>) =>
            Boolean(field.value && !meta.error && revealAppBar) && (
              <ApplicationDetails
                className="create-prod-deploy__details"
                applicationInfo={applications.find(
                  application => application.id === field.value
                )}
                description={projectData?.description}
              />
            )
          }
        </Field>
      </div>
      <div className="create-prod-deploy__columns">
        <div className="create-prod-deploy__column">
          <Field name="name">
            {({field, meta}: FieldProps<ProdDeployFormValues['name']>) => (
              <StudioTextField
                id="create-deploy-deployment-name"
                className="create-prod-deploy__field"
                label={intl.formatMessage({id: 'createDeploy.deploymentName'})}
                placeholder={intl.formatMessage({id: 'createDeploy.enterName'})}
                InputProps={{...field}}
                error={Boolean(meta.error && meta.touched)}
                helperText={meta.error && meta.touched ? meta.error : ''}
                disabled={isDeploying}
                required
                inputProps={{
                  maxLength: DEPLOY_PANEL_SETTINGS.name.maxLength,
                }}
              />
            )}
          </Field>
          <Field name="description">
            {({field, meta}: FieldProps<ProdDeployFormValues['description']>) => (
              <StudioTextField
                id="create-deploy-deployment-descripition"
                className={cn(
                  'create-prod-deploy__field',
                  'create-prod-deploy__field--large'
                )}
                label={intl.formatMessage({id: 'createDeploy.description'})}
                placeholder={intl.formatMessage({id: 'createDeploy.enterDesc'})}
                InputProps={{...field}}
                error={Boolean(meta.error && meta.touched)}
                helperText={meta.error && meta.touched ? meta.error : ''}
                disabled={isDeploying}
                multiline={DEPLOY_PANEL_SETTINGS.description.multiline}
                inputProps={{
                  className: DEPLOY_PANEL_SETTINGS.description.className,
                  maxLength: DEPLOY_PANEL_SETTINGS.description.maxLength,
                }}
              />
            )}
          </Field>
          {selectedApplication && selectedApplication.modelPurpose !== 'Classification' && (
            <>
              <Field name="samplingFrequency">
                {({
                  field,
                  meta,
                }: FieldProps<ProdDeployFormValues['samplingFrequency']>) => (
                  <StudioTextField
                    required
                    disabled={isDeploying}
                    id="create-deploy-deployment-sampling-frequency"
                    className="create-prod-deploy__field"
                    label={intl.formatMessage({
                      id: 'createDeploy.samplingInterval',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'createDeploy.frameInterval',
                    })}
                    type="number"
                    InputProps={{...field}}
                    error={Boolean(meta.error && meta.touched)}
                    helperText={meta.error && meta.touched ? meta.error : ''}
                    tooltip={intl.formatMessage({
                      id: 'createDeploy.samplingInterval.tooltip',
                    })}
                  />
                )}
              </Field>
              <Field name="ddaDataMaxSize">
                {({field, meta}: FieldProps<ProdDeployFormValues['ddaDataMaxSize']>) => (
                  <StudioTextField
                    id="create-deploy-deployment-dda-data-max-size"
                    className="create-prod-deploy__field"
                    label={intl.formatMessage({id: 'createDeploy.ddaDataMaxSize'})}
                    placeholder={intl.formatMessage({id: 'createDeploy.numFrames'})}
                    type="number"
                    InputProps={{...field}}
                    error={Boolean(meta.error && meta.touched)}
                    helperText={meta.error && meta.touched ? meta.error : ''}
                    disabled={isDeploying}
                    tooltip={intl.formatMessage({
                      id: 'createDeploy.ddaDataMaxSize.tooltip',
                    })}
                  />
                )}
              </Field>
              <Field name="nonMaximumSuppression">
                {({
                  field,
                  meta,
                }: FieldProps<ProdDeployFormValues['nonMaximumSuppression']>) => (
                  <StudioTextField
                    id="create-deploy-deployment-non-maximum-suppression"
                    className="create-prod-deploy__field"
                    label={intl.formatMessage({id: 'createDeploy.nmsThreshold'})}
                    placeholder={intl.formatMessage({
                      id: 'createDeploy.enterThreshold',
                    })}
                    type="number"
                    inputProps={{
                      step: 0.1,
                    }}
                    InputProps={{...field}}
                    error={Boolean(meta.error && meta.touched)}
                    helperText={meta.error && meta.touched ? meta.error : ''}
                    disabled={isDeploying}
                    tooltip={intl.formatMessage({
                      id: 'createDeploy.nmsThreshold.tooltip',
                    })}
                  />
                )}
              </Field>
              <Field name="confThreshold">
                {({field, meta}: FieldProps<ProdDeployFormValues['confThreshold']>) => (
                  <StudioTextField
                    id="create-deploy-deployment-conf-threshold"
                    className="create-prod-deploy__field"
                    label={intl.formatMessage({id: 'createDeploy.confThreshold'})}
                    placeholder={intl.formatMessage({
                      id: 'createDeploy.enterThreshold',
                    })}
                    type="number"
                    inputProps={{
                      step: 0.1,
                    }}
                    InputProps={{...field}}
                    error={Boolean(meta.error && meta.touched)}
                    helperText={meta.error && meta.touched ? meta.error : ''}
                    disabled={isDeploying}
                    tooltip={intl.formatMessage({
                      id: 'createDeploy.confThreshold.tooltip',
                    })}
                  />
                )}
              </Field>
            </>
          )}
        </div>
        <div className="create-prod-deploy__column">
          {applicationId && selectedApplication && (
            <>
              <Field name="nodeId">
                {({field, meta}: FieldProps<ProdDeployFormValues['nodeId']>) => (
                  <StudioSelect
                    className={cn(
                      targetValueSelected
                        ? 'create-prod-deploy__select__selected'
                        : 'create-prod-deploy__select__unselected'
                    )}
                    dataTestId="create-prod-deploy-gsp"
                    label={intl.formatMessage({id: 'createDeploy.gspTarget'})}
                    placeholder={intl.formatMessage({
                      id: 'createDeploy.selectTarget',
                    })}
                    disablePlaceholder
                    options={nodesOptions}
                    SelectProps={{
                      ...field,
                      onChange: (e: React.ChangeEvent<any>) => {
                        field.onChange(e);
                        setTargetValueSelected(true);
                        setFieldValue('boardId', '');
                      },
                    }}
                    error={Boolean(meta.error && meta.touched)}
                    helperText={meta.error && meta.touched ? meta.error : ''}
                    disabled={isDeploying}
                    required
                    placeholderClassName="create-prod-deploy__select__placeholder"
                  />
                )}
              </Field>
              {nodeId && (
                <Field name="boardId">
                  {({field, meta}: FieldProps<ProdDeployFormValues['boardId']>) => (
                    <StudioSelect
                      className={cn(
                        boardId
                          ? 'create-prod-deploy__select__selected'
                          : 'create-prod-deploy__select__unselected'
                      )}
                      dataTestId="create-prod-deploy-board"
                      label={intl.formatMessage({id: 'createDeploy.board'})}
                      placeholder={intl.formatMessage({
                        id: 'createDeploy.selectBoard',
                      })}
                      disablePlaceholder
                      options={boardOptions}
                      SelectProps={{...field}}
                      error={Boolean(meta.error && meta.touched)}
                      helperText={meta.error && meta.touched ? meta.error : ''}
                      disabled={isDeploying}
                      required
                    />
                  )}
                </Field>
              )}
            </>
          )}
        </div>
      </div>
      {showEnv && (
        <div className="create-prod-deploy__columns">
          {Object.keys(environment).map(envVar => (
            <div
              key={envVar}
              className="create-prod-deploy__column create-prod-deploy__environment_item"
            >
              <Field name={'environment.' + envVar}>
                {({field, meta}: FieldProps<ProdDeployFormValues['environment']>) => (
                  <StudioTextField
                    id="create-deploy-deployment-environment"
                    className="create-prod-deploy__field"
                    label={envVar}
                    placeholder={environment[envVar]}
                    InputProps={{...field}}
                    disabled={isDeploying}
                  />
                )}
              </Field>
            </div>
          ))}
        </div>
      )}
    </Form>
  );
};
