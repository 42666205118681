export const getStagesData = state => state.stages.data;

export const getIsReadyForConversion = state =>
  state.stages.data && state.stages.data.readyForConversion;

export const getIsReadyForCreateHelperFile = state =>
  state.stages.data && state.stages.data.readyForHelperPreparation;

export const getIsReadyForCreatePyTorchModelFile = state =>
  state.stages.data && state.stages.data.readyForPyTorchModelPreparation;

export const getStagesErrors = state => state.stages.errors;

export const getStagesWarnings = state => state.stages.warnings;
