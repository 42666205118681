import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import axios from 'axios';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import FormButton from '../../base-components/StudioButton/FormButton';
import {toast} from '../../base-components/StudioToast';
import Waiting from '../../base-components/StudioWaiting/Waiting';
import URL from '../../config/url';
import {RootState} from '../../store';
import {setIsShareModalOpen} from '../../store/marketplace/configure';
import {addListener, removeListener} from '../../store/statusChecker';
import {Answer} from '../../types/prompts/Answer';
import {MarketplacePublishFinalizeRequest} from '../../types/marketplace/MarketplacePublishFinalizeRequest';
import {IntegrationPurpose} from '../../types/integrations/IntegrationResponse';
import {MarketplacePublishClarifyResponse} from '../../types/marketplace/MarketplacePublishClarifyResponse';
import Util from '../../util';
import {DynamicForm} from '../DynamicForm';
import './MarketplacePublishDialog.scss';

type MarketplacePublishDialogProps = {
  projectId: string;
};

const purposeMap: {
  [key in IntegrationPurpose]: {eventType: string; submitId: string};
} = {
  DATASET_STORAGE: {eventType: 'PUBLISH_DATA', submitId: 'publish.dataset'},
  MODEL_STORAGE: {eventType: 'PUBLISH_MODEL', submitId: 'publish.model'},
  ISP_STORAGE: {eventType: 'PUBLISH_ISP_FLOW', submitId: 'publish.isp'},
  PP_STORAGE: {eventType: 'PUBLISH_PP', submitId: 'publish.pp'},
  SOLUTION_STORAGE: {eventType: 'PUBLISH_TEMPLATE', submitId: 'publish.solution'},
};

type ProgressStatus = {
  statusMessage: string;
  percentCompleted: number;
};

export const MarketplacePublishDialog = ({projectId}: MarketplacePublishDialogProps) => {
  const [inProgress, setInProgress] = useState(false);
  const [progressStatus, setProgressStatus] = useState<ProgressStatus | null>(null);

  const dispatch = useDispatch();
  const intl = useIntl();
  const isOpen = useSelector(
    (state: RootState) => state.configureMarketplace.share.isOpen
  );
  const defaults = useSelector(
    (state: RootState) => state.configureMarketplace.share.defaults
  );
  const prompts = useSelector(
    (state: RootState) => state.configureMarketplace.share.prompts
  );
  const resourceId = useSelector(
    (state: RootState) => state.configureMarketplace.share.resourceId
  );
  const purpose = useSelector(
    (state: RootState) => state.configureMarketplace.share.purpose
  );

  const {eventType, submitId} = purposeMap[purpose];

  const publishFinalizeRequest = (params: MarketplacePublishFinalizeRequest) => {
    return axios.get(URL.MARKETPLACE_PUBLISH_FINALIZE, {params}).then(res => {
      dispatch(setIsShareModalOpen({isOpen: false}));
      if (!res.data.errors?.length) {
        toast.success(
          intl.formatMessage({id: 'form.published'}, {name: res.data.body.displayName})
        );
      }
    });
  };

  const listenForPublication = (eventType: string, resourceId: string) => {
    setInProgress(true);
    dispatch(
      addListener(eventType, (status: ProgressStatus) => {
        setProgressStatus(status);
        if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
          dispatch(removeListener(eventType));
          setInProgress(false);
          setProgressStatus(null);
          publishFinalizeRequest({projectId, resourceId});
        }
      })
    );
  };

  const handleSubmit = async (answers: Answer[]) => {
    if (!resourceId) {
      return;
    }

    try {
      const {data} = await axios.post<MarketplacePublishClarifyResponse>(
        URL.MARKETPLACE_PUBLISH_CLARIFY,
        {
          answers,
          projectId,
          resourceId,
        }
      );

      if (data.errors?.length) {
        dispatch(setIsShareModalOpen({isOpen: false}));
      } else if (data.prompts?.length) {
        dispatch(
          setIsShareModalOpen({
            isOpen: true,
            data: {
              prompts: data.prompts,
              defaults: data.defaults,
            },
            params: {
              projectId,
              resourceId,
              purpose,
            },
          })
        );
      } else {
        listenForPublication(eventType, resourceId);
      }
    } catch (e) {
      dispatch(setIsShareModalOpen({isOpen: false}));
    }
  };

  const handleClose = async () => {
    if (inProgress) {
      try {
        await axios.get(URL.OPERATION_CANCEL(eventType), {
          params: {projectId},
        });
        setInProgress(false);
        setProgressStatus(null);
      } catch (e) {
        console.error(e);
      }
    }
    dispatch(setIsShareModalOpen({isOpen: false, isCancelled: true}));
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} data-testid="marketplace-publish-dialog">
      <DialogTitle>Publish to Marketplace</DialogTitle>
      <DialogContent className="marketplace-publish__content">
        <DialogContentText>
          Publish data, model, or ISP flows to the Marketplace for securely sharing within
          your organization
        </DialogContentText>
        <DynamicForm
          defaults={defaults}
          prompts={prompts}
          onSubmit={handleSubmit}
          renderButtons={() => (
            <>
              {inProgress && (
                <div className="marketplace-publish__loading">
                  <Waiting
                    diameter={40}
                    loadingText={
                      progressStatus?.statusMessage ||
                      intl.formatMessage({id: 'form.publishing'})
                    }
                  />
                </div>
              )}
              <DialogActions>
                <FormButton
                  buttonRole="secondary"
                  value={intl.formatMessage({id: 'form.cancel'})}
                  onClick={handleClose}
                  type="button"
                />
                <FormButton
                  buttonRole="primary"
                  disabled={inProgress}
                  value={intl.formatMessage({id: submitId})}
                  type="submit"
                />
              </DialogActions>
            </>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};
