// ACTIONS
export const VIEW_SET_PROJECT_FORM = 'view/set-project-form';
export const VIEW_CLOSE_PROJECT_FORM = 'view/close-project-form';
export const VIEW_OPEN_PROJECT_FORM = 'view/open-project-form';
export const VIEW_RESET_PROJECT_FORM = 'view/reset-project-form';
export const VIEW_OPEN_EDIT_PROJECT_FORM = 'view/open-edit-project-form';
export const VIEW_CLOSE_EDIT_PROJECT_FORM = 'view/close-edit-project-form';
export const VIEW_TOGGLE_EDIT_PROJECT_FORM = 'view/toggle-edit-project-form';
export const VIEW_SET_PROJECT = 'view/set-project';

export const VIEW_SET_ISP = 'view/set-isp';
export const VIEW_TOGGLE_EDIT_ISP = 'view/toggle-edit-isp';

export const VIEW_SET_CONSOLE = 'view/set-console';

export const VIEW_OPEN_TERMINATE_DIALOG = 'view/open-terminate-dialog';
export const VIEW_CLOSE_TERMINATE_DIALOG = 'view/close-terminate-dialog';
export const VIEW_ENABLE_TERMINATION = 'view/enable-termination';
export const VIEW_DISABLE_TERMINATION = 'view/disable-termination';

// PROJECT
export const viewSetProject = payload => ({
  type: VIEW_SET_PROJECT,
  payload,
});

export const viewSetProjectForm = payload => ({
  type: VIEW_SET_PROJECT_FORM,
  payload,
});

export const viewCloseProjectForm = () => ({
  type: VIEW_CLOSE_PROJECT_FORM,
});

export const viewOpenProjectForm = () => ({
  type: VIEW_OPEN_PROJECT_FORM,
});

export const viewResetProjectForm = () => ({
  type: VIEW_RESET_PROJECT_FORM,
});

export const viewOpenEditProjectForm = () => ({
  type: VIEW_OPEN_EDIT_PROJECT_FORM,
});

export const viewCloseEditProjectForm = () => ({
  type: VIEW_CLOSE_EDIT_PROJECT_FORM,
});

export const viewToggleEditProjectForm = () => ({
  type: VIEW_TOGGLE_EDIT_PROJECT_FORM,
});

// ISP
export const viewSetISP = payload => ({
  type: VIEW_SET_ISP,
  payload,
});

export const viewSetSelectedISP = id => ({
  type: VIEW_SET_ISP,
  payload: {selectedId: id},
});

export const viewOpenNewISP = () => ({
  type: VIEW_SET_ISP,
  payload: {newFormOpen: true},
});

export const viewCloseNewISP = () => ({
  type: VIEW_SET_ISP,
  payload: {newFormOpen: false},
});

// ISP Form under ISP Page trigger
export const viewToggleEditISP = () => ({
  type: VIEW_TOGGLE_EDIT_ISP,
});

export const viewOpenEditISP = () => ({
  type: VIEW_SET_ISP,
  payload: {editFormOpen: true},
});

export const viewCloseEditISP = () => ({
  type: VIEW_SET_ISP,
  payload: {editFormOpen: false},
});

// Error Console
export const viewSetConsole = payload => ({
  type: VIEW_SET_CONSOLE,
  payload,
});

export const viewCloseConsole = () => ({
  type: VIEW_SET_CONSOLE,
  payload: {open: false},
});

export const viewOpenConsole = () => ({
  type: VIEW_SET_CONSOLE,
  payload: {open: true},
});

export const viewOpenTerminateDialog = ({title, message}) => ({
  type: VIEW_OPEN_TERMINATE_DIALOG,
  payload: {title, message},
});

export const viewCloseTerminateDialog = () => ({
  type: VIEW_CLOSE_TERMINATE_DIALOG,
});

export const viewEnableTermination = () => ({
  type: VIEW_ENABLE_TERMINATION,
});

export const viewDisableTermination = () => ({
  type: VIEW_DISABLE_TERMINATION,
});
