import React, {Component} from 'react';
import PropTypes from 'prop-types';

class TilingIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="3" y="3" width="8" height="8" fill="#3F3F3F" />
          <rect x="3" y="13" width="8" height="8" fill="#3F3F3F" />
          <rect x="13" y="3" width="8" height="8" fill="#3F3F3F" />
          <rect x="13" y="13" width="8" height="8" fill="#3F3F3F" />
        </svg>
      </div>
    );
  }
}

TilingIcon.propTypes = {
  className: PropTypes.string,
};

export default TilingIcon;
