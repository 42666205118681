import React from 'react';
import Button from '@material-ui/core/Button';

class ModuleButton extends React.Component {
  render() {
    const buttonProps = {...this.props.buttonProps};
    return (
      <div className="module-button">
        <Button
          size="small"
          fullWidth
          variant="outlined"
          onClick={() => buttonProps.handler(buttonProps.node)}
        >
          {buttonProps.name || 'Name here'}
        </Button>
      </div>
    );
  }
}

export default ModuleButton;
