export const ADD_LISTENER = 'statusChecker/add-listener';
export const REMOVE_LISTENER = 'statusChecker/remove-listener';
export const RESET = 'statusChecker/initialize';

export const addListener = (eventType, handler) => ({
  type: ADD_LISTENER,
  eventType,
  handler: handler,
});

export const removeListener = eventType => ({
  type: REMOVE_LISTENER,
  eventType,
});

export const resetStatusChecker = () => ({
  type: RESET,
});
