import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {InitialImportDialogState} from '../../components/Marketplace/ImportDialogs/ImportDialog';

type DialogState = {
  isOpen: boolean;
  initialMarketplaceState: InitialImportDialogState;
};

const initialState: DialogState = {
  isOpen: false,
  initialMarketplaceState: {},
};

const addISPDialogSlice = createSlice({
  name: 'marketplace-add-isp',
  initialState,
  reducers: {
    setIsOpen: (
      state,
      action: PayloadAction<{
        isOpen: DialogState['isOpen'];
        initialMarketplaceState?: InitialImportDialogState;
      }>
    ) => {
      state.isOpen = action.payload.isOpen;
      state.initialMarketplaceState =
        action.payload.isOpen && action.payload.initialMarketplaceState
          ? action.payload.initialMarketplaceState
          : initialState.initialMarketplaceState;
    },
  },
});

export const {reducer: addISPDialogReducer, actions} = addISPDialogSlice;
