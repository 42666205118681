import PropTypes from 'prop-types';

const StatisticsInfoProps = PropTypes.shape({
  floatAccuracy: PropTypes.number.isRequired,
  compressedAccuracy: PropTypes.number,
  finetunedAccuracy: PropTypes.number,
  trainAccuracy: PropTypes.number,
  inferenceAccuracy: PropTypes.number.isRequired,
  inferenceFPS: PropTypes.number.isRequired,
});

export default StatisticsInfoProps;
