import {PostProcessorResponse} from '../../types/postprocessor/PostProcessorResponse';
import {request} from '../request';

export const getPostProcessorURL = (projectId: string, postProcessorId: string) =>
  `/postprocessor/get/project/${projectId}?postProcessorId=${postProcessorId}`;

export function getPostProcessor(url: string) {
  return request<PostProcessorResponse>({
    method: 'get',
    url,
  });
}
