import React from 'react';
import {FormattedMessage} from 'react-intl';
import './StudioStatistics.scss';

export const StudioStatistics = ({accuracy, numberOfClasses, restProps}) => (
  <div className="studio-statistics__all-stats" {...restProps}>
    <div className="studio-statistics__stat-column">
      <div className="studio-statistics__stat-num">{numberOfClasses}</div>
      <div className="studio-statistics__stat">
        <FormattedMessage id="finetune.classes" />
      </div>
    </div>
    <div className="studio-statistics__stat-column">
      <div className="studio-statistics__stat-num">{accuracy}</div>
      <div className="studio-statistics__stat">
        <FormattedMessage id="finetune.accuracy" />
      </div>
    </div>
  </div>
);
