import React from 'react';
import PropTypes from 'prop-types';
import {
  ProjectButton,
  ProjectButtonColumn,
  ProjectButtonDescription,
  ProjectButtonName,
} from '../ProjectButton';
import './CreateProjectButton.scss';
import {FormattedMessage} from 'react-intl';

function CreateProjectButton(props) {
  return (
    <ProjectButton className="create-new-project" onClick={() => props.onClick(true)}>
      <div className="new-project-icon project-status-icon">&nbsp;</div>
      <ProjectButtonColumn>
        <ProjectButtonDescription>
          <FormattedMessage id="project.createDescription" />
        </ProjectButtonDescription>
        <ProjectButtonName>
          <FormattedMessage id="project.createAction" />
        </ProjectButtonName>
      </ProjectButtonColumn>
    </ProjectButton>
  );
}

CreateProjectButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CreateProjectButton;
