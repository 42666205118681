import React from 'react';
import './Help.scss';
import AfterImage from './images/defogging-after.png';
import BeforeImage1 from './images/defogging-before.png';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>RGB Defogging</h4>
      <div className="flow-graph__help_content_detail">
        <p>The presence of fog or haze in the atmosphere degrades the quality of images captured by visible camera sensors. The removal of fog, called defogging, is typically performed under the physical degradation model, which necessitates a solution of an ill-posed inverse problem. To relieve the difficulty of the inverse problem, a novel prior called dark channel prior (DCP) was recently proposed and has received a great deal of attention. The DCP is derived from the characteristic of natural outdoor images that the intensity value of at least one-color channel within a local window is close to zero. Based on the DCP, the defogging is accomplished through four major steps: atmospheric light estimation, transmission map estimation, transmission map refinement, and image reconstruction. This four-step defogging process makes it possible to provide a step-by-step approach to the complex solution of the ill-posed inverse problem.</p>
        <p>Due to absorption and scattering by atmospheric particles in fog, outdoor images have poor visibility under inclement weather. Poor visibility negatively impacts not only consumer photography but also computer vision applications for outdoor environments, such as object detection and video surveillance. Fog removal, which is referred to as defogging, is considered an important process because fog-free images are visually pleasing and can significantly improve the performance of computer vision tasks.</p>
        <p>After observing the property of fog-free outdoor images, the novel prior—dark channel prior (DCP) was proposed. The DCP is based on the property of “dark pixels,” which have a very low intensity in at least one-color channel, except for the sky region. The algorithm implemented is single image fog removal from the histogram over the entire image. After computation of histogram based on histogram values the pixel value with saturation value and the pixel value corresponding to median data and to ninety percent of data are computed and from them, the target pixel is computed. From this the target pixel value for atmospheric light, transmission gain, luminance of input image and atmospheric light, the new luminance is computed. Using the new luminance value (Y value) and the input image luminance values (R value), the gains are computed, which contribute towards computation of chrominance (G,B values). Thus, RGB image is taken as input, and the output is also an RGB image.</p>
        <div className="help_images">
          <figure>
            <img src={BeforeImage1} alt="before" />
            <figcaption>Input</figcaption>
          </figure>
          <figure>
            <img src={AfterImage} alt="after" />
            <figcaption>Output</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};
