import axios from 'axios';
import URL from "../../config/url";

export const FETCH_BUILD_PENDING = 'build/fetch-pending';
export const FETCH_BUILD_SUCCESS = 'build/fetch-success';
export const FETCH_BUILD_ERROR = 'build/fetch-error';

export const fetchBuild = () => dispatch => {
  dispatch(fetchBuildPending());

  axios
    .get(URL.NETDEPLOY_VERSION)
    .then(res => {
      if (res.data.body) {
        dispatch(fetchBuildSuccess(res.data.body));
      } else if (res.data.errors?.length) {
        dispatch(fetchBuildError(res.data.errors[0]));
      }
    })
    .catch(err => {
      dispatch(fetchBuildError(err));
    });
};

const fetchBuildPending = () => {
  return {
    type: FETCH_BUILD_PENDING,
  };
};

const fetchBuildSuccess = data => {
  return {
    type: FETCH_BUILD_SUCCESS,
    payload: data,
  };
};

const fetchBuildError = error => {
  return {
    type: FETCH_BUILD_ERROR,
    error,
  };
};
