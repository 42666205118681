import moment from 'moment';
import React, {useRef} from 'react';
import cn from 'classnames';
import {useSelector} from 'react-redux';
import {getProjectData} from '../../../store/project';
import {DeploymentDetailsInfo} from '../../../types/deployment/DeploymentDetailsInfo';
import {Project} from '../../../types/project/Project';
import {RuntimeSummary} from '../RuntimeSummary';
import {TestInferenceResults} from '../TestInferenceResults';
import {setONNXTask, TASK_STATUS, useDeploy} from '../useDeploy';
import './TestResults.scss';
import {ONNXRuntimeContainer} from '../../ONNXRuntimeContainer/ONNXRuntimeContainer';
import {toast} from '../../../base-components/StudioToast';
import {Parameter} from '../../../types/model/framework/Parameter';
import {AnalyticsDeploySettings} from './../AnalyticDeploySettings';

export type TestResultsProps = {
  deployment: DeploymentDetailsInfo | null;
  isTaskInProgress: boolean;
};

export const TestResults = ({deployment, isTaskInProgress}: TestResultsProps) => {
  const [
    {deployConfig, deployTask, streamingTask, onnxTask, streamingDevices},
    dispatch,
  ] = useDeploy();

  const previousOnnxResults = useRef<JSX.Element>();

  const projectData = useSelector(getProjectData);

  const runtimeSummaryRef = useRef(null) as any;

  const deploymentApplication = deployment?.applicationId
    ? findApplication(projectData, deployment?.applicationId)
    : null;
  const taskStatistics = deployTask?.monitoringResponse?.statistics;
  const deploymentStatistics = deployment?.deploymentStatistics;
  const isStreamingInference = streamingTask.status !== TASK_STATUS.IDLE;
  const isONNXInference = onnxTask.status !== TASK_STATUS.IDLE;
  const isONNXStreamingFinished =
    onnxTask.params?.inferenceSource !== 'IMAGES' &&
    onnxTask.status === TASK_STATUS.COMPLETE;

  const renderPreviousResultsOrNewResults = () => {
    const onnxRuntimeContainer = renderONNXRuntimeContainer();
    if (onnxRuntimeContainer) {
      previousOnnxResults.current = onnxRuntimeContainer;
    }
    return previousOnnxResults.current;
  };

  const renderONNXRuntimeContainer = () => {
    const {status, params, id} = onnxTask;
    const isONNXInference = status !== TASK_STATUS.IDLE;
    if (isONNXInference && params) {
      const selectedApplication = findApplication(projectData, params.deploymentId);
      const onnxVideoURL =
        params.inferenceSource === 'IP_CAMERA'
          ? streamingDevices.find(camera => camera.id === params.ipCamera)?.url
          : params.inferenceSource === 'FILE'
          ? streamingDevices.find(file => file.id === params.videoFile)?.url
          : undefined;

      const configuredParameters = (
        deployConfig.postProcessorParams?.map<Parameter>(param => ({
          name: param.name,
          value: param.value,
        })) || []
      )
        .concat(deployConfig.postProcessorRealtimeParams || [])
        .concat(
          deployConfig.preProcessorParams?.map<Parameter>(param => ({
            name: param.name,
            value: param.value,
          })) || []
        )
        .concat(deployConfig.preProcessorRealtimeParams || []);

      return selectedApplication &&
        !isONNXStreamingFinished &&
        status === TASK_STATUS.IN_PROGRESS ? (
        <ONNXRuntimeContainer
          key={id}
          projectId={projectData.id}
          application={selectedApplication}
          inputShape={selectedApplication.model.frameworkParameters.inputShape}
          files={params.files}
          runtimeMode={params.runtimeMode}
          type={params.inferenceSource}
          url={onnxVideoURL}
          configuredParams={configuredParameters}
          onError={message => {
            if (message) {
              toast.error(message);
            }
            dispatch(setONNXTask({status: TASK_STATUS.IDLE}));
          }}
          onFinish={() => dispatch(setONNXTask({status: TASK_STATUS.COMPLETE}))}
        />
      ) : null;
    }
    return null;
  };

  return (
    <div className="test-results">
      {!isONNXInference && (
        <>
          {(isTaskInProgress || deployment) && (
            <RuntimeSummary
              name={deployTask?.monitoringResponse?.name ?? deployment?.nodeName ?? null}
              status={deployTask?.monitoringResponse?.statusMessage ?? null}
              fps={taskStatistics?.inferenceFPS ?? deploymentStatistics?.fps ?? null}
              latency={
                taskStatistics?.inferenceLatency ?? deploymentStatistics?.latency ?? null
              }
              accuracy={
                taskStatistics?.inferenceAccuracy ??
                deploymentStatistics?.lastTop1Accuracy ??
                deploymentStatistics?.accuracy ??
                null
              }
              runtime={
                !isTaskInProgress && deployment
                  ? moment(deployment?.endTime).diff(
                      deployment?.startTime,
                      'milliseconds'
                    ) / 1000
                  : null
              }
              componentRef={runtimeSummaryRef}
            />
          )}
          <div
            className={cn(
              'test-results__split-inference-results',
              isStreamingInference && 'test-results__split-inference-results--streaming'
            )}
          >
            <TestInferenceResults
              deployment={deployment}
              hasTransformation={Boolean(deploymentApplication?.transformation)}
              isStreamingInference={isStreamingInference}
              isTaskInProgress={isTaskInProgress}
              projectData={projectData}
            />
            <AnalyticsDeploySettings />
          </div>
        </>
      )}
      {renderPreviousResultsOrNewResults()}
    </div>
  );
};

function findApplication(projectData: Project, applicationId: string) {
  const applications = Object.values(projectData?.applications || []);
  return applications.find(application => application.id === applicationId);
}
