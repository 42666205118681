import {takeEvery, call, put, delay, take, race, select} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import {
  PROJECT_FROM_TEMPLATE,
  PROJECT_ERROR,
  projectError,
  projectPending,
  projectSuccess,
  projectPendingStatus,
} from './actions';
import axios from 'axios';
import URL from '../../config/url';
import Util from '../../util';
import {VIEW_CLOSE_PROJECT_FORM} from '../view';
import {getIsProjectPending, getProjectId} from './selectors';
import {toast} from '../../base-components/StudioToast';
import messages from '../../components/en.json';
import {createIntl, createIntlCache} from 'react-intl';

const TASK_NAME = 'CREATE_NEW_PROJECT';

const cache = createIntlCache();
const intl = createIntl({locale: navigator.language, messages: messages}, cache);

function* cancelTask(projectId) {
  yield axios.get(URL.OPERATION_CANCEL(TASK_NAME), {
    params: {
      projectId,
    },
  });
  yield put(projectError());
}

function* finalizeTask(projectId) {
  try {
    const {data} = yield axios.get(URL.PROJECT_IMPORT_FINALIZE, {
      params: {id: projectId},
    });
    if (data.warnings && data.warnings.length) {
      data.warnings.forEach(toast.warn);
    }
    if (data.errors && data.errors.length) {
      data.errors.forEach(toast.error);
      yield put(projectError(data.errors));
    } else {
      yield put(projectSuccess(data));
    }
  } catch (e) {
    yield put(projectError(e));
  }
}

function* pollTaskStatus(projectId) {
  while (true) {
    try {
      const {data} = yield axios.get(URL.PROJECT_OP_STATUS(projectId));
      const task = data.body.tasks.find(task => task.type === TASK_NAME);
      if (task) {
        yield put(projectPendingStatus(task.statusMessage));
        if (Util.isRealNumberEqual(task.percentCompleted, 100)) {
          return;
        }
      }
      yield delay(3000);
    } catch (e) {
      yield put(cancelTask, projectId);
    }
  }
}

function* startTask({description, name, solution}) {
  try {
    const {data} = yield axios.post(URL.PROJECT_IMPORT, {
      integrationId: solution.metadata.integrationId,
      reference: solution.metadata.reference,
      displayName: name,
      description: description,
    });
    yield put(projectPending(data));
    return data.body.id;
  } catch (e) {
    yield put(projectError(e));
  }
}

function* createProject(payload) {
  const projectId = yield call(startTask, payload);
  yield call(pollTaskStatus, projectId);
  yield call(finalizeTask, projectId);
  return projectId;
}

function* getMatchingProject(payload) {
  try {
    const {solution} = payload;
    const {data} = yield axios.get(URL.PROJECT_LITE_LATEST, {
      params: {
        integrationId: solution.integrationId,
        searchQuery: solution.displayName,
      },
    });

    return data.body;
  } catch (e) {
    console.error(e);
    return null;
  }
}

function* navigateToProject(projectId, isLiteMode) {
  yield put(
    push(isLiteMode ? `/project/${projectId}/test` : `/project/${projectId}/upload`)
  );
  toast.success({
    title: intl.formatMessage({id: 'project.createToastTitle'}),
    subtitle: intl.formatMessage({id: 'project.createToastContent'}),
    position: 'top',
  });
}

function* projectFromTemplateSaga({payload}) {
  const isLiteMode = payload.mode === 'LITE';

  if (isLiteMode && payload.solution) {
    // In studio lite, we should only maintain one project per solution.
    // This checks to see if any projects already exist with matching solution name
    const existingProject = yield call(getMatchingProject, payload);
    if (existingProject) {
      yield call(navigateToProject, existingProject.id, isLiteMode);
      return;
    }
  }

  const {success, cancelled} = yield race({
    success: call(createProject, payload),
    error: take(PROJECT_ERROR),
    cancelled: take(VIEW_CLOSE_PROJECT_FORM),
  });

  const projectId = yield select(getProjectId);
  const isTaskPending = yield select(getIsProjectPending);
  if (cancelled && isTaskPending) {
    yield call(cancelTask, projectId);
  } else if (success) {
    yield call(navigateToProject, projectId, isLiteMode);
  }
}

export function* watchProjectFromTemplate() {
  yield takeEvery(PROJECT_FROM_TEMPLATE, projectFromTemplateSaga);
}
