import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import ERROR from '../../../config/error';
import {StudioFormikSelect} from '../../Formik/StudioFormikSelect';
import FormButton from '../../../base-components/StudioButton/FormButton';
import URL from '../../../config/url';
import {formikErrors} from '../../Formik/formikErrors';
import {FormattedMessage, useIntl} from 'react-intl';

const download = ({deploymentId, projectId}) => {
  window.open(
    URL.GSP_DOWNLOAD({
      deploymentId,
      projectId,
    }),
    '_self'
  );
};
const validationSchema = Yup.object({
  deploymentId: Yup.string().required(ERROR.FIELD_REQUIRED()),
});

export const DeployExportDialog = ({
  applicationNames,
  onClose,
  isOpen,
  projectId,
  deploymentId,
}) => {
  const intl = useIntl();
  const initialValues = {
    deploymentId,
    projectId,
  };

  return (
    <Dialog open={isOpen} data-testid="deploy-export-dialog">
      <Formik
        initialValues={initialValues}
        initialErrors={formikErrors(validationSchema, initialValues)}
        onSubmit={args => {
          download(args);
          onClose();
        }}
        validationSchema={validationSchema}
      >
        {({isValid}) => (
          <Form>
            <DialogTitle className="dplmt-export-title">
              <div className="dplmt-export-title-top">
                <div className="dplmt-export-title-text">
                  <Typography color="primary" variant="h6">
                    <FormattedMessage id="form.exportApplication" />
                  </Typography>
                </div>
                <IconButton
                  onClick={onClose}
                  className="dplmt-export-title-text"
                  data-testid="deploy-export-close-button"
                  id="deploy-export-close-button"
                >
                  <Close />
                </IconButton>
              </div>
              <DialogContentText>
                <FormattedMessage id="deployments.exportSummary" />
              </DialogContentText>
            </DialogTitle>
            <DialogContent>
              <Grid container justifyContent="center">
                <Grid item className="dplmt-export-form">
                  <StudioFormikSelect
                    name="deploymentId"
                    label={intl.formatMessage({id: 'deployments.availableDeployments'})}
                    placeholder={intl.formatMessage({
                      id: Object.keys(applicationNames).length
                        ? 'deployments.selectDeployment'
                        : 'deployments.noDeployments',
                    })}
                    disablePlaceholder
                    className="dplmt-export-field"
                    options={applicationNames}
                  />
                  <Field name="projectId" type="hidden" />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <FormButton
                type="submit"
                buttonRole="primary"
                value={intl.formatMessage({id: 'form.download'})}
                disabled={!isValid}
                dataTestid="deploy-export-download-button"
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
