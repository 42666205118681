import URL from '../../config/url';
import {DataPreparationOrigin} from '../../types/dataset/DataPreparationContainer';

export function getOriginUrl(origin: DataPreparationOrigin) {
  return origin === 'deployment'
    ? URL.DATASET_DEPLOYMENT
    : origin === 'dataset'
    ? URL.DATASET_DATASET
    : URL.DATASET_RAW;
}
