import React from 'react';
import Image from 'material-ui-image';
import {
  StudioCanvasOverlay,
  StudioCanvasOverlayProps,
} from '../../../../../base-components/StudioOverlay/StudioCanvasOverlay';
import {
  ImageMetadata,
  useStudioImageMetaData,
} from '../../../../../base-components/StudioImageMetaData';
import './ImageOverlay.scss';
import {Skeleton} from '../../../../Skeleton';

export type ImageOverlayProps = {
  image: string;
  filename?: string;
  size: number;
  width: number;
  height: number;
  highlightIndex?: number | null;
} & StudioCanvasOverlayProps;

const getScaleFactor = (from: number, to: number) => to / from;

export function ImageOverlay({
  image: url,
  filename,
  width: targetWidth,
  height: targetHeight,
  size = 300,
  highlightIndex,
  data,
  ...rest
}: ImageOverlayProps) {
  const metaData: ImageMetadata = useStudioImageMetaData(url);
  const {width: aWidth, height: aHeight} = metaData;

  if (metaData.fileName && !metaData.busy) {
    const scaleX = metaData.width ? getScaleFactor(metaData.width, size) : 1;
    const scaleY = metaData.height ? getScaleFactor(metaData.height, size) : 1;
    const scaleFactor = Math.min(scaleX, scaleY);
    const width = Math.floor(scaleFactor * (aWidth || 0));
    const height = Math.floor(scaleFactor * (aHeight || 0));
    const bboxData = data.map(el => ({
      ...el,
      bbox: [
        (el.bbox?.[0] ?? 0) * (width || 0),
        (el.bbox?.[1] ?? 0) * (height || 0),
        (el.bbox?.[2] ?? 0) * (width || 0),
        (el.bbox?.[3] ?? 0) * (height || 0),
      ],
    }));
    return (
      <div className="image-overlay" style={{width, height}}>
        <>
          <Image
            src={url + `&resolution=${width}:${height}`}
            imageStyle={{width, height}}
            loading={<Skeleton variant="rect" style={{width, height}} />}
          />
          <StudioCanvasOverlay
            width={width}
            height={height}
            highlightIndex={highlightIndex}
            data={bboxData}
            boxColor={'yellow'}
            showAll={true}
            selectOnMouseOver={false}
            {...rest}
          />
        </>
      </div>
    );
  }

  return <></>;
}
