import React, {MouseEvent, useState, ChangeEvent} from 'react';
import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';
import {Box, Checkbox, FormGroup, IconButton, Link, Toolbar} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {THUMBNAIL_FILTER} from './const';
import {ImageStateId, SortId} from './ImageThumbView';
import {useIntl} from 'react-intl';
import './ImageListFilter.scss';

export type FilterTypeId = 'checkbox' | 'radio';

export type FilterItem = {
  label: string;
  value: string;
};

export type FilterItemGroup = {
  id: string;
  label: string;
  type: FilterTypeId;
  items: FilterItem[];
};

export default function ImageListFilter({
  sortBy = 'recentlyAddedAsc',
  imageStateBy = [],
  onChange = () => {},
  onReset = () => {},
}: {
  sortBy: SortId;
  imageStateBy: ImageStateId[];
  onChange?: (groupId: string, id: string, value: string | string[]) => void;
  onReset?: () => void;
}) {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | any>(null);
  const open = Boolean(anchorEl);
  const handleClick = (e: MouseEvent) => setAnchorEl(e?.currentTarget);
  const onClick = () => setAnchorEl(null);

  const onRadioFilterSelect = (groupId: string, value: string) => {
    onChange(groupId, '', value);
  };

  const onCheckBoxSelect = (groupId: string, id: string, checked: boolean) => {
    onChange(
      groupId,
      id,
      checked
        ? imageStateBy.concat([id as ImageStateId])
        : imageStateBy.filter(i => i !== id)
    );
  };

  const getValue = (filter: FilterItemGroup) => {
    switch (filter.id) {
      case 'sortBy':
        return sortBy;
    }
  };
  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <FilterListIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClick}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        className="detection-view__thumbnail_filter_menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Toolbar className="detection-view__thumbnail_toolbar" variant="dense">
          <h6>{intl.formatMessage({id: 'general.filters'})}</h6>
          <Box flexGrow={1}></Box>
          <Link component="button" variant="body2" onClick={onReset}>
            {intl.formatMessage({id: 'general.filter.clear'})}
          </Link>
        </Toolbar>
        {THUMBNAIL_FILTER.map((filter, i) => {
          return filter.type === 'checkbox' ? (
            <CheckboxList
              key={i}
              group={filter as FilterItemGroup}
              value={imageStateBy}
              onSelect={onCheckBoxSelect}
            />
          ) : (
            <RadioList
              key={i}
              group={filter as FilterItemGroup}
              value={getValue(filter as FilterItemGroup)}
              onSelect={onRadioFilterSelect}
            />
          );
        })}
      </Menu>
    </>
  );
}

export const RadioList = ({
  group,
  value = '',
  onSelect = (group: string, value: string) => {},
}: {
  group: FilterItemGroup;
  value?: string;
  onSelect: (group: string, value: string) => void;
}) => {
  const intl = useIntl();
  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    onSelect(group.id, (e.target as HTMLInputElement).value);
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{intl.formatMessage({id: group.label})}</FormLabel>
      <RadioGroup name={group.id} value={value} onChange={onChange}>
        {group.items.map((item: FilterItem, i) => (
          <FormControlLabel
            key={i}
            control={<Radio color="primary" />}
            {...item}
            label={intl.formatMessage({id: item.label})}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export const CheckboxList = ({
  group,
  value = [],
  onSelect = (group: string, id: string, value: boolean) => {},
}: {
  group: FilterItemGroup;
  value?: string[];
  onSelect: (group: string, id: string, value: boolean) => void;
}) => {
  const intl = useIntl();
  const onChange = (id: string, value: boolean) => onSelect(group.id, id, value);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{intl.formatMessage({id: group.label})}</FormLabel>
      <FormGroup>
        {group.items.map((item: FilterItem, i) => (
          <FormControlLabel
            key={i}
            control={
              <Checkbox
                checked={value.includes(item.value)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onChange(item.value, (e.target as HTMLInputElement).checked as boolean)
                }
                color="primary"
              />
            }
            label={intl.formatMessage({id: item.label})}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};
