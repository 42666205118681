import React from 'react';
import cn from 'classnames';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import {FormattedMessage, useIntl} from 'react-intl';
import {Box, Button, Toolbar} from '@material-ui/core';
import {Navigation} from '../Navigation';
import {dataPrepareActions} from '../../../store/dataPrepare';
import {RootState, useAppDispatch} from '../../../store';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';

import './LabelMethodView.scss';

export type LabelMethodViewProps = {
  datasetId?: string;
  projectId: string;
  onForward: () => void;
  onBack: () => void;
};

const {setIsAutoLabel} = dataPrepareActions;

export const LabelMethodView = ({onForward, onBack}: LabelMethodViewProps) => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isAutoLabel = useSelector((state: RootState) => state.dataPrepare.isAutoLabel);

  const handleChange = (autoLabel: boolean) => {
    dispatch(setIsAutoLabel(autoLabel));
    onForward();
  };

  return (
    <div className="label_method-view">
      <Toolbar className="data-prepare__header" variant="dense">
        <Button
          className="data-prepare__previous-btn"
          variant="contained"
          disableElevation
          onClick={() => history.goBack()}
        >
          <FormattedMessage id="form.returnToPreviousPage" />
        </Button>
        <Box flexGrow={1}></Box>
        <Navigation onForward={onForward} onBack={onBack} />
      </Toolbar>
      <div className="label_method-view__header">
        <h5 className="label_method-view__header_title">
          {intl.formatMessage({
            id: 'dataPrep.labelMethodView.title',
          })}
        </h5>
        <h5 className="label_method-view__header_desc">
          {intl.formatMessage({
            id: 'dataPrep.labelMethodView.desc',
          })}
        </h5>
      </div>
      <div className="label_method-view__form">
        <h5 className="label_method-view__note">
          {intl.formatMessage({
            id: 'dataPrep.labelMethodView.note',
          })}
        </h5>
        <div className="label_method-view__cards">
          <div className="label_method-view__single_card">
            <Card
              variant="outlined"
              className={cn('label_method-view__button', {
                selected: isAutoLabel,
              })}
            >
              <CardActionArea
                className="label_method-view__button_action_area"
                onClick={e => handleChange(true)}
              >
                <CardContent className="label_method-view__button_content">
                  <h4>
                    {intl.formatMessage({
                      id: 'dataPrep.labelMethodView.auto.title',
                    })}
                  </h4>
                  <h5>
                    {intl.formatMessage({
                      id: 'dataPrep.labelMethodView.auto.desc',
                    })}
                  </h5>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <Card
            variant="outlined"
            className={cn('label_method-view__button', {
              selected: !isAutoLabel,
            })}
          >
            <CardActionArea
              className="label_method-view__button_action_area"
              onClick={e => handleChange(false)}
            >
              <CardContent className="label_method-view__button_content">
                <h4>
                  {intl.formatMessage({
                    id: 'dataPrep.labelMethodView.manual.title',
                  })}
                </h4>
                <h5>
                  {intl.formatMessage({
                    id: 'dataPrep.labelMethodView.manual.desc',
                  })}
                </h5>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </div>
    </div>
  );
};
