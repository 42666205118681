import React from 'react';
import {LinearProgress, Typography} from '@material-ui/core';
import fileSize from 'filesize';
import {FormattedMessage} from 'react-intl';

export type FileUploadProgressProps = {
  total: number;
  progress: number;
};

export function FileUploadProgress({total = 0, progress = 0}: FileUploadProgressProps) {
  const percent = Math.round((progress / total) * 100) || 0;
  return (
    <div data-testid="file-upload-progress">
      <LinearProgress variant="determinate" value={percent} />
      <Typography variant="caption" color="textSecondary">
        <FormattedMessage
          id="deployments.fileProgress"
          values={{
            progress: fileSize(progress),
            total: fileSize(total),
            percent,
          }}
        />
      </Typography>
    </div>
  );
}
