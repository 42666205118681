import {yupToFormErrors} from 'formik';

export const formikErrors = (validationSchema, values) => {
  try {
    validationSchema.validateSync(values, {abortEarly: false});
    return undefined;
  } catch (err) {
    return yupToFormErrors(err);
  }
};
