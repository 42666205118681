import React, {RefObject} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {IntegrationPurpose} from '../../../types/integrations/IntegrationResponse';
import {actions} from '../../../store/marketplace/add-model';
import {ImportModelActionBase} from '../../StageView/StageActions/ImportModelAction';
import {ImportDialog} from './ImportDialog';
import {SortableColumn} from '../../../types/marketplace/MarketplaceFilesRequest';

export type AddModelDialogProps = {
  actionRef?: RefObject<InstanceType<typeof ImportModelActionBase>>;
};

const PURPOSE: IntegrationPurpose = 'MODEL_STORAGE';
const {setIsOpen} = actions;
const columns: SortableColumn[] = [
  'displayName',
  'provider',
  'creationDateTime',
  'purpose',
  'type',
  'baseModel',
  'size',
  'studioVersion',
];

export const AddModelDialog = ({actionRef}: AddModelDialogProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.addModelDialog.isOpen);
  const initialMarketplaceState = useSelector(
    (state: RootState) => state.addModelDialog.initialMarketplaceState
  );

  return (
    <ImportDialog
      isOpen={isOpen}
      title="Add Model"
      purpose={PURPOSE}
      columns={columns}
      onClose={isSubmit => dispatch(setIsOpen({isOpen: false, isCancelled: !isSubmit}))}
      onSubmit={(item, integration) =>
        actionRef?.current?.importModel(integration?.id, {
          name: item.name,
          type: item.type,
        })
      }
      onUploadSubmit={(files, totalSize) =>
        actionRef?.current?.importModelFromFiles(files, totalSize)
      }
      initialState={initialMarketplaceState}
      defaultFilterValues={{compatibleOnly: true}}
    />
  );
};
