import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ViewToggleIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="16px"
          height="16px"
          viewBox="0 0 16 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-848.000000, -5784.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 4989.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-view-toggle-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M12,17.96875 L18.1328125,13.1640625 L19.5,14.21875 L12,20.0390625 L4.5,14.21875 L5.8671875,13.1640625 L12,17.96875 Z M12,15.8203125 L5.8671875,11.0546875 L4.5,10 L12,4.1796875 L19.5,10 L18.1328125,11.0546875 L12,15.8203125 Z"
                        id="toggle"
                        fill="currentColor"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

ViewToggleIcon.propTypes = {
  className: PropTypes.string,
};

export default ViewToggleIcon;
