import {ApplicationType} from '../../types/model/framework/ApplicationType';
import {ExecutionContainer} from '../../types/model/framework/ExecutionContainer';
import {PreProcessor} from '../../types/preprocessor/PreProcessor';
import {TransformationType} from '../../types/preprocessor/TransformationType';
import {request} from '../request';

export type PreProcessorNewRequest = {
  projectId: string;
  transformationType: TransformationType;
  executionContainer?: ExecutionContainer;
  applicationType?: ApplicationType;
};

export type PreProcessorNewResponse = PreProcessor;

export const PreProcessorNewUrl = ({
  projectId,
  executionContainer,
  transformationType,
  applicationType,
}: PreProcessorNewRequest) =>
  `/preprocessor/new?${new URLSearchParams({
    projectId,
    executionContainer: executionContainer || '',
    transformationType,
    applicationType: applicationType || '',
  }).toString()}`;

export async function preProcessorNew(data: PreProcessorNewRequest) {
  return request<PreProcessorNewResponse>({
    method: 'get',
    url: PreProcessorNewUrl(data),
  });
}
