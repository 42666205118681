import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import FormButton from '../../../base-components/StudioButton/FormButton';
import StageAnimation from '../StageAnimation/StageAnimation';
import Util from '../../../util';
import TaskInfoProps from '../../../model/taskInfoPropTypes';
import {toast} from '../../../base-components/StudioToast';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import {setModelViewerStale} from '../../../store/model';
import axios from 'axios';
import URL from '../../../config/url';
import {StageAssistantForm} from '../../StageAssistantForm';
import {addListener, removeListener} from '../../../store/statusChecker';
import {projectFetch} from '../../../store/project';
import {viewDisableTermination, viewEnableTermination} from '../../../store/view';

class ModelBuildAction extends Component {
  constructor(props) {
    super(props);

    this.stageAnimation = new StageAnimation();
    this.buildModel = this.buildModel.bind(this);
    this.handleBuildResponse = this.handleBuildResponse.bind(this);
    this.extract = this.extract.bind(this);
    this.finishBuild = this.finishBuild.bind(this);
    this.cancelBuild = this.cancelBuild.bind(this);
    this.hideAssistant = this.hideAssistant.bind(this);
    this.showPrompts = this.showPrompts.bind(this);
    this.clarifyModelBuild = this.clarifyModelBuild.bind(this);
    this.handleClarifyResponse = this.handleClarifyResponse.bind(this);
    this.showAssistant = this.showAssistant.bind(this);
    this.processBuildProgress = this.processBuildProgress.bind(this);
    this.finalizeBuild = this.finalizeBuild.bind(this);
    this.terminateBuild = this.terminateBuild.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.deleteModel = this.deleteModel.bind(this);
    this.monitorStatus = this.monitorStatus.bind(this);
  }

  componentDidMount() {
    if (this.props.taskProgress && this.props.taskProgress.type === 'BUILD_MODEL') {
      this.props.setModelActionInProgress('build');
      this.stageAnimation.shiftStages('IMPORT_MODEL');
      if (this.props.taskProgress.prompts?.length) {
        this.showPrompts();
      } else {
        this.monitorStatus();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.taskProgress?.type !== 'BUILD_MODEL' &&
      this.props.taskProgress?.type === 'BUILD_MODEL'
    ) {
      this.props.setModelActionInProgress('build');
      this.stageAnimation.shiftStages('IMPORT_MODEL');
      if (this.props.taskProgress.prompts?.length) {
        this.showPrompts();
      } else {
        this.monitorStatus();
      }
    }
  }

  componentWillUnmount() {
    this.props.removeStatusListener('BUILD_MODEL');
  }

  monitorStatus() {
    this.props.viewEnableTermination();
    this.stageAnimation.markInProgress('IMPORT_MODEL');
    this.props.addStatusListener('BUILD_MODEL', this.processBuildProgress);
  }

  render() {
    return null;
  }

  buildModel(caAnswers) {
    if (Array.isArray(caAnswers)) {
      this.clarifyModelBuild(null, caAnswers);
    } else {
      axios
        .get(URL.MODEL_BUILD, {
          params: {
            projectId: this.props.projectId,
          },
        })
        .then(response => this.handleBuildResponse(response.data))
        .catch(error => toast.error(`model build error: ${error.message}`));
    }
  }

  handleBuildResponse(res) {
    const response = this.extract(res);

    if (response.errors && response.errors.length > 0) {
      this.cancelBuild();
    } else if (response.prompts?.length) {
      this.showPrompts(response);
    } else {
      this.clarifyModelBuild([]);
    }
  }

  showPrompts(response) {
    this.showAssistant(
      <StageAssistantForm
        prompts={response.prompts}
        defaults={response.defaults}
        onSubmit={this.clarifyModelBuild}
        onCancel={this.cancelBuild}
        title={'Select Build Model Parameters'}
        notifyType="BUILD_MODEL"
      />,
      this.cancelBuild
    );
  }

  clarifyModelBuild(answersArray = [], caAnswers) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.hideStage('IMPORT_MODEL');
    }

    const clarificationRequest = {
      projectId: this.props.projectId,
      answers: answersArray,
      caAnswers,
    };

    axios
      .post(URL.MODEL_BUILD_CLARIFY, clarificationRequest, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(response => this.handleClarifyResponse(response.data))
      .catch(error => toast.error(`model build error: ${error.message}`));
  }

  handleClarifyResponse(res) {
    if (res.errors?.length) {
      this.cancelBuild();
    } else if (res.prompts) {
      this.showPrompts(res);
    } else {
      this.monitorStatus();
    }
  }

  extract(res) {
    if (res.body?.messages) {
      res.body.messages.forEach((msg, n) => {
        // TODO: add keys to backend messages
        msg.key = n.toString();
      });
    }

    return res;
  }

  async finishBuild() {
    this.hideAssistant();

    if (this.props.onFinish) {
      await this.props.projectFetch(this.props.projectId);
      this.props.onFinish('IMPORT_MODEL');
    }
  }

  cancelBuild() {
    this.hideAssistant();
    this.props.setModelActionInProgress(null);

    if (this.props.onFinish) {
      this.props.onFinish('IMPORT_MODEL');
    }
  }

  hideAssistant() {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.hide();
    }
  }

  showAssistant(newForm, exit) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.display(newForm, 'IMPORT_MODEL', null, exit);
    }
  }

  processBuildProgress(status) {
    ModelBuildAction.displayBuildProgress(status);
    if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
      this.props.removeStatusListener('BUILD_MODEL');
      this.finalizeBuild();
    }
  }

  static displayBuildProgress(status) {
    let progressText = document.getElementById('model-action-progress');
    if (progressText) {
      progressText.innerText = status.statusMessage;
    }
  }

  finalizeBuild() {
    this.props.viewDisableTermination();
    axios
      .post(URL.MODEL_BUILD_FINALIZE, {
        projectId: this.props.projectId,
      })
      .then(res => {
        res = this.extract(res.data);

        if (res.body && !res.errors && !res.body.messages) {
          this.props.setModelViewerStale(true);
          this.finishBuild();
        } else if (res.body?.messages) {
          this.props.setModelViewerStale(true);

          // TODO: find a way to display model statistics without DynamicForm
          res.body.messages.push({
            key: res.body.messages.length.toString(),
            type: 'STATISTICS',
            accuracy: res.body.data['Top-1 accuracy'],
            numberOfClasses: res.body.data['Number of classes'],
          });

          this.showAssistant(
            <StageAssistantForm
              prompts={res.body.messages}
              defaults={res.body.defaults}
              onSubmit={this.finishBuild}
              onCancel={this.confirmDelete}
              title={res.body.header}
            />,
            this.confirmDelete
          );
        } else {
          this.cancelBuild();
        }
      })
      .catch(error => toast.error(`model build error: ${error.message}`));
  }

  terminateBuild() {
    this.props.viewDisableTermination();
    this.hideAssistant();

    return axios
      .get(URL.OPERATION_CANCEL('BUILD_MODEL'), {
        params: {projectId: this.props.projectId},
      })
      .then(res => {
        this.props.assistantPanel.current.hideStage('IMPORT_MODEL');
        this.props.removeStatusListener('BUILD_MODEL');
        this.cancelBuild();
      })
      .catch(error => toast.error(`cancel model build error: ${error.message}`));
  }

  confirmDelete() {
    // TODO: Reuse StageAssistantForm somehow
    this.showAssistant(
      <div className="stage-assistant-form" data-testid="stage-assistant-form">
        <h2 className="stage-assistant-form__title">Delete Model?</h2>
        <form className="dynamic-form stage-assistant-form__form" novalidate="">
          <p>The trained model will be permanently deleted.</p>
          <div className="stage-assistant-form__actions">
            <FormButton
              buttonRole="secondary"
              value="Cancel"
              onClick={this.finishBuild}
            />
            <FormButton buttonRole="delete" value="Delete" onClick={this.deleteModel} />
          </div>
        </form>
      </div>,
      this.deleteModel
    );
  }

  deleteModel() {
    this.hideAssistant();

    return axios
      .get(URL.MODEL_DELETE(this.props.projectId))
      .then(() => {
        toast.success({position: 'top', subtitle: 'User deleted model'});
        this.cancelBuild();
      })
      .catch(error => toast.error(`delete model error: ${error.message}`));
  }
}

ModelBuildAction.propTypes = {
  projectId: PropTypes.string.isRequired,
  assistantPanel: PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)})
    .isRequired,
  onFinish: PropTypes.func.isRequired,
  taskProgress: TaskInfoProps,
};

const mapDispatchToProps = {
  projectFetch,
  addStatusListener: addListener,
  removeStatusListener: removeListener,
  setModelViewerStale,
  viewEnableTermination,
  viewDisableTermination,
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(
  ModelBuildAction
);
