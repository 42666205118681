const baseModels = {
  YOLOV3: 'Yolo V3',
  YOLOV3_TINY: 'Yolo V3 Tiny',
  YOLOV4: 'Yolo V4',
  YOLOV5: 'Yolo V5',
  GoogleNet: 'GoogleNet',
  MOBILENET: 'mobilenet',
  RESNET18: 'ResNet 18',
  RESNET34: 'ResNet 34',
  RESNET50: 'ResNet 50',
  RESNET101: 'ResNet 101',
  RESNET152: 'ResNet 152',
  RESNEXT50: 'RESNEXT50',
  VGG11: 'VGG 11',
  VGG13: 'VGG 13',
  VGG16: 'VGG 16',
  VGG19: 'VGG 19',
  SQUEEZENET: 'Squeezenet',
  FER_PLUS: 'FER+',
  UNET: 'U-Net',
  SWIFTNET: 'SwiftNet',
  OPENPOSE: 'Open Pose',
  POSENET: 'PoseNet',
  PYACT_APP: 'PyAct Application',
  PYTHON_APP: 'Python Application',
  OTHER: 'Other',
};

export type BaseModel = keyof typeof baseModels;

export function baseModelToString(baseModel: BaseModel) {
  return baseModels[baseModel] ?? baseModel;
}
