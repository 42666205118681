import {useEffect, useRef} from 'react';
import {useHistory} from 'react-router';
import {useConversation} from '../useConversation';
import {useDispatch, useSelector} from 'react-redux';
import {actions as addDataActions} from '../../../store/marketplace/add-data';
import {actions as addModelActions} from '../../../store/marketplace/add-model';
import {getProjectData} from '../../../store/project';
import {openPublishForm} from '../../../store/marketplace/configure';

export const ConversationActionListener = ({
  dataUploadAction,
  importModelAction,
  modelBuildAction,
  dataSplitAction,
  optimizeModelAction,
  finetuneAction,
  createDeploymentAction,
  setModelActionInProgress,
  setDatasetActionInProgress,
}) => {
  const [{status: {action: uiAction, projectId} = {}}] = useConversation();
  const reduxDispatch = useDispatch();
  const prevUIAction = useRef(uiAction);
  const history = useHistory();
  const projectData = useSelector(getProjectData);

  useEffect(() => {
    // deep compare prev action with current
    if (uiAction && JSON.stringify(uiAction) !== JSON.stringify(prevUIAction.current)) {
      switch (uiAction.id) {
        case 'REROUTE':
          history.push(uiAction.params.path);
          break;
        case 'USER_GUIDE':
          const {topicId, anchorId} = uiAction.params;
          history.push(`/guide?topicId=${topicId}&anchorId=${anchorId}`);
          break;
        case 'ADD_OWN_DATA':
          reduxDispatch(
            addDataActions.setIsOpen({
              isOpen: true,
              initialMarketplaceState: uiAction.params,
            })
          );
          break;
        case 'ADD_OWN_MODEL':
          reduxDispatch(
            addModelActions.setIsOpen({
              isOpen: true,
              initialMarketplaceState: uiAction.params,
            })
          );
          break;
        case 'PUBLISH':
          switch (uiAction.params.type) {
            case 'DATASET':
              reduxDispatch(
                openPublishForm({
                  projectId,
                  resourceId: projectData.dataSet?.id,
                  purpose: 'DATASET_STORAGE',
                })
              );
              break;
            case 'MODEL':
              reduxDispatch(
                openPublishForm({
                  projectId,
                  resourceId: projectData.model?.id,
                  purpose: 'MODEL_STORAGE',
                })
              );
              break;
            case 'SOLUTION':
              reduxDispatch(
                openPublishForm({
                  projectId,
                  resourceId: projectId,
                  purpose: 'SOLUTION_STORAGE',
                })
              );
              break;
            default:
              break;
          }
          break;
        case 'TASK_STARTED':
          switch (uiAction.params.operation) {
            case 'ADD_DATA':
              setDatasetActionInProgress('upload');
              dataUploadAction && dataUploadAction.current.monitorStatus();
              break;
            case 'IMPORT_MODEL':
              setModelActionInProgress('import');
              importModelAction && importModelAction.current.monitorStatus();
              break;
            case 'BUILD_MODEL':
              setModelActionInProgress('build');
              modelBuildAction && modelBuildAction.current.monitorStatus();
              break;
            default:
              break;
          }
          break;
        case 'SHOW_PROMPTS':
          switch (uiAction.params.operation) {
            case 'BUILD_MODEL':
              setModelActionInProgress('build');
              modelBuildAction &&
                modelBuildAction.current.buildModel(uiAction.params.caAnswers);
              break;
            case 'DATA_SPLIT':
              dataSplitAction &&
                dataSplitAction.current.initiateDataSplit(uiAction.params.caAnswers);
              break;
            case 'OPTIMIZE_MODEL':
              optimizeModelAction &&
                optimizeModelAction.current.optimizeModel(uiAction.params.caAnswers);
              break;
            case 'FINETUNE_MODEL':
              finetuneAction &&
                finetuneAction.current.initiateFinetuning(uiAction.params.caAnswers);
              break;
            case 'CREATE_DEPLOYMENT':
              createDeploymentAction &&
                createDeploymentAction.current.initiateCreateDeployment(
                  uiAction.params.caAnswers
                );
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }
    prevUIAction.current = uiAction;
  });

  return null;
};
