import React from 'react';
import {Box, Button, Toolbar} from '@material-ui/core';
import {Navigation} from '../Navigation';
import {DataReview} from '../DataReview/DataReview';
import {RootState, useAppDispatch} from '../../../store';
import {useSelector} from 'react-redux';
import {
  dataPrepareActions,
  loadAnnotations,
  saveAnnotations,
} from '../../../store/dataPrepare';
import {
  DataPrepareType,
  ImageType,
} from '../../../types/dataset/DatasetGetAnnotationsResponse';
import {useMergeData} from './useMergeData';
import {unwrapResult} from '@reduxjs/toolkit';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory} from 'react-router';

const {setStatus, setDataPrep} = dataPrepareActions;

type DeploymentDataViewProps = {
  projectId: string;
  deploymentId?: string;
  inProgress: boolean;
};

export const DeploymentDataView = ({
  projectId,
  inProgress,
  deploymentId,
}: DeploymentDataViewProps) => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const annotationData = useSelector(
    (state: RootState) => state.dataPrepare.annotationData
  );

  const initiateMergeData = useMergeData({
    projectId,
    onResume: () => dispatch(setStatus('LOADING')),
    onStart: () => dispatch(setStatus('LOADING')),
    onFinish: async dataPreparationContainer => {
      const newDatasetId = dataPreparationContainer?.id;
      if (newDatasetId) {
        const action = await dispatch(loadAnnotations({projectId, origin: 'raw'}));
        const rawAnnotationData = unwrapResult(action);
        // Hack to determine type. Need to get BE to pass this info as part of the data prep container
        const type = rawAnnotationData.annotations.some(annotation => annotation.bbox)
          ? DataPrepareType.DETECTION
          : DataPrepareType.CLASSIFICATION;

        await dispatch(
          saveAnnotations({
            resourceId: newDatasetId,
            projectId,
            updatedAnnotations: {
              ...rawAnnotationData,
              info: {...rawAnnotationData.info, type},
            },
          })
        );
        dispatch(setDataPrep({container: dataPreparationContainer, context: 'raw'}));
        dispatch(setStatus('IDLE'));
        history.push(`/project/${projectId}/data/prepare/raw?resume=true`);
      }
    },
    onError: () => dispatch(setStatus('IDLE')),
  });

  const isValid = annotationData?.images?.some(image => image.selected);
  const disableForward = !isValid || inProgress;

  const handleForward = () => {
    if (annotationData) {
      initiateMergeData(annotationData);
    }
  };

  const handleSelect = (id: string) => {
    if (annotationData && deploymentId) {
      const updatedAnnotations = {
        ...annotationData,
        images: annotationData.images.map<ImageType>(image => {
          if (image.id === Number(id)) {
            return {
              ...image,
              selected: image.selected === 1 ? 0 : 1,
            };
          }
          return image;
        }),
      };
      dispatch(
        saveAnnotations({
          resourceId: deploymentId,
          projectId,
          updatedAnnotations,
        })
      );
    }
  };

  const handleSelectAll = () => {
    if (annotationData && deploymentId) {
      const updatedAnnotations = {
        ...annotationData,
        images: annotationData.images.map<ImageType>(image => ({
          ...image,
          selected: 1,
        })),
      };
      dispatch(
        saveAnnotations({
          resourceId: deploymentId,
          projectId,
          updatedAnnotations,
        })
      );
    }
  };

  const handleClearAll = () => {
    if (annotationData && deploymentId) {
      const updatedAnnotations = {
        ...annotationData,
        images: annotationData.images.map<ImageType>(image => ({
          ...image,
          selected: 0,
        })),
      };
      dispatch(
        saveAnnotations({resourceId: deploymentId, projectId, updatedAnnotations})
      );
    }
  };

  const dataToShow =
    annotationData?.images?.map(image => ({
      id: image.id.toString(),
      name: image.file_name,
      size: image.size,
      selected: Boolean(image.selected),
      lastModified: new Date(image.date_captured),
    })) || [];

  return (
    <>
      <Toolbar className="data-prepare__header" variant="dense">
        <Button
          className="data-prepare__previous-btn"
          variant="contained"
          disableElevation
          onClick={() => history.goBack()}
        >
          <FormattedMessage id="form.returnToPreviousPage" />
        </Button>
        <Box flexGrow={1}></Box>
        <Navigation onForward={handleForward} disableForward={disableForward} hideBack />
      </Toolbar>
      <DataReview
        title={intl.formatMessage({id: 'dataPrep.select.images'})}
        projectId={projectId}
        fileItems={dataToShow}
        origin="deployment"
        onSelect={id => handleSelect(id)}
        onSelectAll={handleSelectAll}
        onClearAll={handleClearAll}
        readOnly
      />
    </>
  );
};
