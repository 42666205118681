import {GspListItem} from '../../types/gsp/GspListItem';
import {request} from '../request';

export type GspListResponse = GspListItem[];

export async function getGspList(projectId: string, applicationId: string) {
  return request<GspListResponse>({
    method: 'get',
    url: `/gsp/list?projectId=${projectId}&applicationId=${applicationId}`,
  });
}
