import {maxBy} from 'lodash';
import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import URL, {MODEL_SUB_OP} from '../../../config/url';
import {UnparsedJson} from '../../../types/utility/UnparsedJson';
import './SegmentationResult.scss';
import {DoubleImageCard} from '../SimpleImageCard/DoubleImageCard';
import {Box, Typography} from '@material-ui/core';
import {DeploymentSegmentationResult} from '../../../types/deployment/DeploymentSegmentationResult';

const IMAGE_WIDTH = 125;
const IMAGE_HEIGHT = 125;

export type SegmentationResultProps = {
  search: string;
  data: UnparsedJson;
  projectId: string;
  deploymentId: string;
};

export function SegmentationResult({
  search,
  data,
  projectId,
  deploymentId,
}: SegmentationResultProps) {
  const [images, legend] = useMemo(() => {
    // TODO: fix backend for empty inferenceResults
    if (data === '""') {
      return [[], {}];
    }

    let results: DeploymentSegmentationResult = JSON.parse(data);
    if (results == null) {
      return [[], {}];
    }

    let legend = results.legend;
    let images = results.data;
    if (search) {
      images = images.filter(image => {
        const topResult = maxBy(image.stats, row => parseFloat(row.progress ?? '0'));
        return topResult?.label?.toLowerCase().includes(search.toLowerCase()) ?? false;
      });
    }

    return [images ?? [], legend];
  }, [data, search]);

  function ImageUrl(
    filename: string | undefined,
    direction: string,
    width?: number,
    height?: number
  ) {
    var url = `${URL.DATASET(projectId, MODEL_SUB_OP.GET_BY_ID)}?id=${filename?.replace(
      ',',
      '%252C'
    )}&deploymentId=${deploymentId}&dataPurpose=${direction}&cachebust=${Date.now()}`;
    if (width && height) {
      url += `&resolution=${width}:${height}`;
    }
    return url;
  }

  function toColor(rgb: number[]): string {
    return `rgb(${rgb[2]} ${rgb[1]} ${rgb[0]})`;
  }

  function getContrastColor(r: number, g: number, b: number): string {
    const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);
    const textColour = brightness > 125 ? 'black' : 'white';
    return textColour;
  }

  return (
    <div className="legend-grid-container">
      <div className="view-grid">
        {Object.entries(legend).map(([txt, clr]) => (
          <Box
            style={{backgroundColor: toColor(clr)}}
            color={getContrastColor(clr[2], clr[1], clr[0])}
          >
            {txt}
          </Box>
        ))}
      </div>
      <div className="inference-grid-container">
        <div className="view-grid">
          {images.length === 0 ? (
            <div className="segmentation-result__no-results">
              <FormattedMessage id="test.noResults" />
            </div>
          ) : (
            images.map((image, i) => (
              <Box>
                <DoubleImageCard
                  key={i}
                  imageL={(w?: number, h?: number) =>
                    ImageUrl(image.filename, 'OUTPUT', w, h)
                  }
                  imageR={(w?: number, h?: number) =>
                    ImageUrl(image.filename, 'INPUT', w, h)
                  }
                  width={IMAGE_WIDTH}
                  height={IMAGE_HEIGHT}
                  data-testid="segmentation-inference-result"
                  className="segmentation-result__image-card"
                />
                <Typography
                  className="image-card-horizontal_text"
                  variant="caption"
                  color="textSecondary"
                  component="div"
                  align="center"
                  noWrap
                >
                  {image.filename}
                </Typography>
              </Box>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
