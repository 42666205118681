import React, {useEffect, useRef, useState} from 'react';
import {LabelProps, LabelPropsEvent} from './types';
import {Rect, Group, Text, Path, Line} from 'react-konva';
import {
  BOX_LABEL_DEFAULT,
  BOX_LABEL_SELECTED,
  BOX_LABEL_TRANSFORM,
  DELETE_ICON,
  SELECTED_FILL,
  TAG_FILL,
  TAG_ICON,
  TAG_SELECTED_FILL,
  TEXT_COLOR,
  TEXT_LABEL,
  TEXT_SELECTED_COLOR,
} from './const';
import {KonvaEventObject} from 'konva/types/Node';
import Konva from 'konva';
import {KonvaPointerEvent} from 'konva/types/PointerEvents';

const LABEL_HEIGHT = 25;
const TAG_WIDTH = 20;
export const LABEL_NAME = 'label';

export function Label(props: LabelProps & LabelPropsEvent) {
  const {
    width = 0,
    height = 0,
    x = 0,
    y = 0,
    label = '',
    selected = false,
    isTransforming = false,
    id = '',
    onChange = () => {},
    onTooltip = () => {},
    onDelete = () => {},
    onDrag = () => {},
    onContextMenu = () => {},
    style = BOX_LABEL_DEFAULT,
    limit = {x: 0, y: 0, width: 0, height: 0},
  } = props;

  const [labelStyle, setLabelStyle] = useState(style);
  const [isHover, setIsHover] = useState(false);

  const groupRef = useRef<Konva.Group>(null);

  useEffect(
    () =>
      setLabelStyle(
        isTransforming ? BOX_LABEL_TRANSFORM : selected ? BOX_LABEL_SELECTED : style
      ),
    [selected, isTransforming, style]
  );

  useEffect(() => {
    if (!selected) {
      const {x = 0, y = 0} = groupRef?.current?.attrs;
      onChange({id, x, y});
    }
  }, [selected, onChange, id]);

  const onDragEnd = (e: KonvaEventObject<MouseEvent>) => {
    onDrag({id, x: e.target.x() as number, y: e.target.y() as number});
  };

  const onDragMove = (e: KonvaEventObject<MouseEvent>) => {
    groupRef.current?.x(
      Math.min(Math.max(limit.x, groupRef.current?.x()), limit.x + limit.width - width)
    );
    groupRef.current?.y(
      Math.min(Math.max(limit.y, groupRef.current?.y()), limit.y + limit.height - height)
    );
  };

  // let timeoutId: any;

  const onMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
    const group = groupRef.current;
    onTooltip({title: ''});
    onTooltip({
      show: true,
      refObj: {
        x: group?.x() || 0,
        y: e.evt.offsetY,
        width,
        height: LABEL_HEIGHT,
      },
      title: label,
    });
    onMouse(true);
  };

  const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
    onTooltip({title: ''});
    onMouse(false);
  };

  const onMouse = (isHovering: boolean) => {
    setIsHover(isHovering);
    setLabelStyle(isHovering ? BOX_LABEL_SELECTED : BOX_LABEL_DEFAULT);
  };

  const onLocalContextMenu = (e: KonvaPointerEvent) => {
    e.evt.preventDefault();
    onContextMenu && onContextMenu(e);
  };

  return (
    <Group
      ref={groupRef}
      draggable={props.draggable}
      visible={!isTransforming}
      name={LABEL_NAME}
      id={id}
      x={x}
      y={y}
      onClick={props.onClick}
      onDragEnd={onDragEnd}
      onDragMove={onDragMove}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onContextMenu={onLocalContextMenu}
    >
      {/* Bounding box */}
      <Line
        x={0}
        y={0}
        {...labelStyle}
        // points
        points={[0, 0, width, 0, width, height, 0, height, 0, 0]}
        strokeWidth={1}
        hitStrokeWidth={20}
        shadowForStrokeEnabled
        onMouseOver={() => onMouse(true)}
        onMouseOut={() => onMouse(false)}
      />
      {!!label.length && (
        <>
          {/* Selected Icon */}
          {/* We may want to defer this since the changes the transformer dimensions */}
          {/* {selected && (
            <Path
              {...TAG_ICON}
              y={-TAG_WIDTH}
              x={-TAG_WIDTH}
              fill={selected ? DEFAULT_FILL : SELECTED_FILL}
            />
          )} */}
          {/* Label Background */}
          <Rect
            width={width - 1}
            height={LABEL_HEIGHT}
            y={height - LABEL_HEIGHT}
            x={1}
            fill={selected || isHover ? SELECTED_FILL : style?.stroke}
            opacity={0.75}
          />
          {/* Label  */}
          <Text
            x={TAG_WIDTH}
            y={height - LABEL_HEIGHT}
            text={label}
            width={width - TAG_WIDTH}
            height={LABEL_HEIGHT}
            {...TEXT_LABEL}
            fill={selected || isHover ? TEXT_SELECTED_COLOR : TEXT_COLOR}
          />
          {/* Tag Icon */}
          <Path
            {...TAG_ICON}
            y={height - LABEL_HEIGHT + 3}
            fill={selected || isHover ? TAG_SELECTED_FILL : TAG_FILL}
          />
        </>
      )}
      {/* Delete Icon */}
      {selected && !isTransforming && (
        <Path
          {...DELETE_ICON}
          y={height - LABEL_HEIGHT + 2}
          x={width - 23}
          onClick={() => onDelete(id)}
          visible={!isTransforming}
        />
      )}
    </Group>
  );
}

export default Label;
