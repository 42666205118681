import {BaseModel} from '../job/BaseModel';
import {Resource} from '../metadata/Resource';
import {UnparsedDate} from '../utility/UnparsedDate';
import {ModelPurpose} from './ModelPurpose';
import {ModelSource} from './ModelSource';
import {ModelType} from './ModelType';
import {FinetuningParameters} from './parameter/FinetuningParameters';
import {FrameworkParameters} from './parameter/FrameworkParameters';
import {OptimizationParameters} from './parameter/OptimizationParameters';
import {TrainingParameters} from './parameter/TrainingParameters';
import {Statistics} from './Statistics';

export const modelSourceMap = {
  BASE_MODEL: 'Base Model',
  FILE_UPLOAD: 'Custom Model',
  TL_MODEL: 'Transfer Learning',
};

export type Model = Resource & {
  type: ModelType;
  baseModel: BaseModel;
  name: string;
  lastUpdateTime: UnparsedDate;
  description: string;
  frameworkParameters: FrameworkParameters;
  trainingParameters: TrainingParameters;
  optimizationParameters: OptimizationParameters;
  finetuningParameters: FinetuningParameters;
  onnxFile: string;
  blaizeFile: string;
  modelHelperFile: string;
  blaizeFileOptimized: string;
  modelPurpose: ModelPurpose;
  modelSource?: ModelSource;
  marketplaceTags?: string[];
  statistics?: Partial<Record<Statistics['type'], Statistics>>;
  fineTuned: boolean;
  compatibleDatasets: string[];
  numberOfClasses: number;
  yoloType: string;
};
