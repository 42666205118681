import axios from 'axios';
import {useCallback, useEffect, useRef} from 'react';
import URL_CONST from '../../../config/url';
import {useAppDispatch} from '../../../store';
import {addListener, removeListener} from '../../../store/statusChecker';
import {DataPreparationContainer} from '../../../types/dataset/DataPreparationContainer';
import {Status} from '../../../types/operation/Status';
import {StatusResponse} from '../../../types/operation/StatusResponse';
import Util from '../../../util';
import {AnnotationDataType} from '../../../types/dataset/DatasetGetAnnotationsResponse';
import {DatasetMergeResponse} from '../../../types/dataset/DatasetMergeResponse';
import {DatasetMergeFinalizeResponse} from '../../../types/dataset/DatasetMergeFinalizeResponse';

type MergeDataProps = {
  projectId: string;
  onResume?: () => void;
  onStart?: () => void;
  onStatus?: (status: Status) => void;
  onFinish?: (dataPreparationContainer: DataPreparationContainer) => void;
  onError?: (error: unknown) => void;
};

const TASK_TYPE = 'MERGE_RAW_DATA';

export const useMergeData = ({
  projectId,
  onResume,
  onStart,
  onStatus,
  onFinish,
  onError,
}: MergeDataProps) => {
  const dispatch = useAppDispatch();
  const onResumeRef = useRef(onResume);
  const onStartRef = useRef(onStart);
  const onStatusRef = useRef(onStatus);
  const onFinishRef = useRef(onFinish);
  const onErrorRef = useRef(onError);

  useEffect(() => {
    onResumeRef.current = onResume;
    onStartRef.current = onStart;
    onStatusRef.current = onStatus;
    onFinishRef.current = onFinish;
    onErrorRef.current = onError;
  }, [onResume, onStart, onStatus, onFinish, onError]);

  const monitorProgress = useCallback(() => {
    return new Promise<void>(resolve => {
      const handleStatusUpdate = (status: Status) => {
        onStatusRef.current?.(status);
        if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
          dispatch(removeListener(TASK_TYPE));
          resolve();
        }
      };
      dispatch(addListener(TASK_TYPE, handleStatusUpdate));
    });
  }, [dispatch]);

  const finalize = useCallback(async () => {
    const {data} = await axios.get<DatasetMergeFinalizeResponse>(
      URL_CONST.DATASET_MERGE_FINALIZE(projectId)
    );
    if (data.errors?.length) {
      throw new Error('Error finalizing raw image upload');
    }
    if (data.body && onFinishRef.current) {
      onFinishRef.current(data.body);
    }
  }, [projectId]);

  useEffect(() => {
    const resumeTaskIfExists = async () => {
      try {
        const {data} = await axios.get<StatusResponse>(
          URL_CONST.PROJECT_OP_STATUS(projectId)
        );
        const {tasks} = data.body;
        const exists = tasks?.find(task => task.type === TASK_TYPE);
        if (!exists) {
          return;
        }
        onResumeRef.current?.();
        await monitorProgress();
        await finalize();
      } catch (e) {
        onErrorRef.current?.(e);
      }
    };
    resumeTaskIfExists();
  }, [finalize, monitorProgress, projectId]);

  const initiateMerge = async (annotationData: AnnotationDataType) => {
    onStartRef.current?.();
    const {data} = await axios.post<DatasetMergeResponse>(
      URL_CONST.DATASET_MERGE(projectId),
      annotationData
    );
    if (data.errors?.length) {
      throw new Error('Error initiating merge data');
    }
    return data.body;
  };

  const initiate = async (annotationData?: AnnotationDataType) => {
    if (annotationData) {
      try {
        await initiateMerge(annotationData);
        await monitorProgress();
        await finalize();
      } catch (e) {
        onErrorRef.current?.(e);
      }
    }
  };

  return initiate;
};
