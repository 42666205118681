import React, {Component} from 'react';
import PropTypes from 'prop-types';

class PercentIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="14px"
          height="14px"
          viewBox="0 0 14 14"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-849.000000, -6193.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 5397.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-warning-outline-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M8.35127479,11.7460087 C10.3937677,11.7460087 11.7223796,10.2830189 11.7223796,8.39332366 C11.7223796,6.48330914 10.3937677,5 8.35127479,5 C6.3286119,5 5,6.48330914 5,8.39332366 C5,10.2830189 6.3286119,11.7460087 8.35127479,11.7460087 Z M8.54957507,18.7561684 L17.0169972,5.20319303 L15.509915,5.20319303 L7.06232295,18.7561684 L8.54957507,18.7561684 Z M8.35127479,10.161103 C7.45892351,10.161103 6.78470255,9.47024673 6.78470255,8.39332366 C6.78470255,7.27576197 7.45892351,6.58490566 8.35127479,6.58490566 C9.26345609,6.58490566 9.93767705,7.27576197 9.93767705,8.39332366 C9.93767705,9.47024673 9.26345609,10.161103 8.35127479,10.161103 Z M15.6288952,19 C17.6515581,19 19,17.5370102 19,15.6473149 C19,13.7373004 17.6515581,12.233672 15.6288952,12.233672 C13.6062323,12.233672 12.2776204,13.7373004 12.2776204,15.6473149 C12.2776204,17.5370102 13.6062323,19 15.6288952,19 Z M15.6288952,17.4150943 C14.7167139,17.4150943 14.0623229,16.7445573 14.0623229,15.6473149 C14.0623229,14.5297533 14.7167139,13.838897 15.6288952,13.838897 C16.5410765,13.838897 17.2152975,14.5297533 17.2152975,15.6473149 C17.2152975,16.7445573 16.5410765,17.4150943 15.6288952,17.4150943 Z"
                        id="percentage"
                        fill="#3F3F3F"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

PercentIcon.propTypes = {
  className: PropTypes.string,
};

export default PercentIcon;
