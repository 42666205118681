import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useDispatch} from 'react-redux';
import Image from 'material-ui-image';
import ArrowRightIcon from '../../../assets/icons/ArrowRightIcon';
import CubePlaceholderIcon from '../../../assets/icons/CubePlaceholderIcon';
import URL from '../../../config/url';
import {viewSetProjectForm} from '../../../store/view';
import {IntegrationPurpose} from '../../../types/integrations/IntegrationResponse';
import {MarketplaceResult} from '../../../types/marketplace/MarketplaceFilesResponse';
import {ISPPlaceholderIcon} from '../../../assets/icons/ISPPlaceholderIcon';
import './Modules.scss';

type ModulesProps = {
  list?: MarketplaceResult[];
  type: IntegrationPurpose;
};

const NUM_LIST = 3;
const THUMBNAIL_GRID_SIZE = 1;
const THUMBNAIL_SIZE = 70;

const PURPOSE_MAP = {
  DATASET_STORAGE: 'dataset',
  MODEL_STORAGE: 'model',
  ISP_STORAGE: 'isp',
  PP_STORAGE: 'pp',
  SOLUTION_STORAGE: 'solution',
};

export const Modules = ({list, type}: ModulesProps) => {
  const dispatch = useDispatch();

  const onClick = (module: MarketplaceResult) => {
    if (type !== 'PP_STORAGE' && type !== 'ISP_STORAGE') {
      dispatch(
        viewSetProjectForm({
          newFormOpen: true,
          form: {[PURPOSE_MAP[type]]: module},
        })
      );
    }
  };

  return (
    <div className="mp-module__category">
      <ul className="mp-module__items">
        {list && list.length ? (
          list.slice(0, NUM_LIST).map((module, index) => {
            return (
              <li
                className="mp-module__item"
                key={index}
                onClick={() => onClick(module)}
                data-testid="mp-module-item"
                id="mp-module-item"
              >
                {type === 'DATASET_STORAGE' ||
                (type === 'SOLUTION_STORAGE' && module.metadata.dataSet) ? (
                  <div
                    className="mp-module__item-thumbnail"
                    style={{width: THUMBNAIL_SIZE, height: THUMBNAIL_SIZE}}
                  >
                    <Image
                      aspectRatio={1 / 1}
                      alt={module.displayName}
                      color="transparent"
                      errorIcon={<CubePlaceholderIcon />}
                      loading={<CubePlaceholderIcon />}
                      imageStyle={{width: THUMBNAIL_SIZE, height: THUMBNAIL_SIZE}}
                      src={URL.DATASET_THUMBNAILS_FROM_MARKETPLACE({
                        datasetName: module.name,
                        marketplaceId: module.integrationId,
                        rows: THUMBNAIL_GRID_SIZE,
                        columns: THUMBNAIL_GRID_SIZE,
                        width: THUMBNAIL_SIZE,
                        height: THUMBNAIL_SIZE,
                      })}
                    />
                  </div>
                ) : (
                  <div className="mp-module__item-thumbnail-placeholder">
                    {['MODEL_STORAGE', 'SOLUTION_STORAGE'].includes(type) ? (
                      <CubePlaceholderIcon />
                    ) : (
                      <ISPPlaceholderIcon />
                    )}
                  </div>
                )}
                <div className="mp-module__item-body">
                  <div className="mp-module__item-title">
                    {module.displayName}
                    <ArrowRightIcon className="mp-module__icon" />
                  </div>
                  <p className="mp-module__item-description">{module.description}</p>
                </div>
              </li>
            );
          })
        ) : list === undefined ? (
          <div className="mp-module__not-configured">
            <h5>
              <FormattedMessage id="marketplaceExplorer.not.configured" />
            </h5>
          </div>
        ) : (
          <div className="mp-module__empty">
            <h5>
              <FormattedMessage id="dashboard.noResults" />
            </h5>
          </div>
        )}
      </ul>
    </div>
  );
};
