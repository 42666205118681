
import React from 'react';
import BeforeImage1 from './images/bad_pixel_correction-before.png';
import AfterImage from './images/bad_pixel_correction-after.png';
import './Help.scss';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>Bad Pixel Correction</h4>
      <div className="flow-graph__help_content_detail">
        <p>Modern image sensors usually have much more than megapixel resolution and we could expect that all these pixels are fully functional. Unfortunately, this is not the case and among millions of pixels there will be defective pixels too. It is a super complicated task to manufacture megapixel image sensors without such defective pixels. These pixels are usually called "bad pixels" and they could be classified according to the following:</p>
        <ul>
          <li>Dead pixels do not give any feedback to incoming light (usually output constant value)</li>
          <li>Hot pixels generate max values to incoming light (usually output is significantly higher than it should be)</li>
          <li>Bright pixels generate excessive values to incoming light (usually output is slightly higher than it should be)</li>
          <li>Black pixels - these are pixels with weak response (usually output less value than it should be)</li>
        </ul>
        <p>Each image sensor manufacturer knows that there are some bad pixels and that number will eventually grow during sensor usage. To consider any image sensor to be good, we could compare actual positions and several bad pixels with the specification. Usually bad pixels cannot be in the vicinity of the image sensor center, but closer to the borders.</p>
        <p>Camera manufacturers usually sort image sensors before manufacturing cameras as different camera applications have not the same requirements for  image  quality. If we compare a surveillance camera with a camera for a microscope, they just cannot have the same number of bad pixels.</p>
        <p>Image sensor manufacturers grade their devices according to a number and type of defects present. Bad pixels need to be removed before image segmentation, image recognition, stitching, 3D modelling, etc.</p>
        <p>There are several ways to remove the bad pixels. We could fix them inside the camera on FPGA according to the known list of coordinates. They could be fixed in RAW data using neighborhood analysis and filter-based approaches.</p>
        <div className="help_images">
          <figure>
            <img src={BeforeImage1} alt="before" />
            <figcaption>Input</figcaption>
          </figure>
          <figure>
            <img src={AfterImage} alt="after" />
            <figcaption>Output</figcaption>
          </figure>
        </div>
        <p><strong>Note:</strong> As they are bayer raw images, much difference cannot be seen by visualization. Their impact can be judged only after applying debayer operation.</p>
      </div>
    </div>
  );
};
