import React, {Component} from 'react';
import PropTypes from 'prop-types';

class AddIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="12px"
          height="11px"
          viewBox="0 0 12 11"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <polygon
              id="path-add-icon"
              points="17.2558594 12.9882812 12.7558594 12.9882812 12.7558594 17.4882812 11.2441406 17.4882812 11.2441406 12.9882812 6.74414062 12.9882812 6.74414062 11.5117188 11.2441406 11.5117188 11.2441406 7.01171875 12.7558594 7.01171875 12.7558594 11.5117188 17.2558594 11.5117188"
            ></polygon>
          </defs>
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Blaize-Studio---Icons" transform="translate(-850.000000, -891.000000)">
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 93.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-add-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-add-icon"></use>
                      </mask>
                      <use id="add" fill="currentColor" xlinkHref="#path-add-icon"></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

AddIcon.propTypes = {
  className: PropTypes.string,
};

export default AddIcon;
