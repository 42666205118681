import React, {useState, createContext} from 'react';

export const StageContext = createContext();

export const StageProvider = ({children}) => {
  const [editor, setEditor] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);

  return (
    <StageContext.Provider
      value={{
        editor,
        setEditor,
        openDetails,
        setOpenDetails,
      }}
    >
      {children}
    </StageContext.Provider>
  );
};
