import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import {ImageViewerHeader, ImageViewer} from '../../ImageViewer';
import {Pagination} from '@material-ui/lab';
import axios from 'axios';
import Image from 'material-ui-image';
import URL, {MODEL_SUB_OP as OP} from '../../../config/url';
import {toast} from '../../../base-components/StudioToast';
import './ISPResult.scss';
import {FormattedMessage} from 'react-intl';

const PAGE_SIZE = 100;
const IMAGE_SIZE = 93; // in pixels

export function ISPResult({projectId, deploymentId}) {
  const [images, setImages] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = React.useState(PAGE_SIZE);
  const [page, setPage] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [imageCount, setImageCount] = React.useState(0);

  // Get image count
  useEffect(() => {
    if (projectId && deploymentId) {
      axios
        .get(URL.DATASET(projectId, OP.COUNT), {
          params: {dataPurpose: 'OUTPUT', deploymentId},
        })
        .then(({data}) => {
          const count = data.body.dataset.count;
          setImageCount(count);
          setPageCount(Math.ceil(count / pageSize));
          setPage(1);
        })
        .catch(err => {
          // Will have to revert this back. BE would not want to change this misformatted response
          // blaizeinc.atlassian.net/browse/STUDCL-4132
          toast.error(JSON.stringify(err.response.data));
        });
    } else {
      setPageCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, deploymentId]);

  // Generate image array
  useEffect(() => {
    if (imageCount && page && projectId && pageCount && pageSize) {
      const _images = [];
      for (let i = (page - 1) * pageSize; i <= page * pageSize && i < imageCount; i++) {
        _images.push(
          <div className="isp-result__image" key={i} data-testid="isp-result">
            <Image
              src={`${URL.DATASET(
                projectId,
                OP.GET_BY_ID
              )}?id=${i}&deploymentId=${deploymentId}&dataPurpose=OUTPUT&cachebust=${Date.now()}`}
              imageStyle={{
                width: IMAGE_SIZE,
                height: IMAGE_SIZE,
              }}
              disableSpinner={true}
              color=""
            />
          </div>
        );
      }
      setImages(_images);
    } else {
      setImages([]);
    }
  }, [page, pageCount, pageSize, setImages, projectId, deploymentId, imageCount]);

  const hasResults = !!images.length;
  return (
    <div className="isp-result">
      <div className="isp-result__content">
        <ImageViewerHeader
          left={
            <Typography variant="h5" className="isp-result__title">
              <FormattedMessage id="deployments.ispOutput" />
            </Typography>
          }
          right={
            hasResults && (
              <h5 className="isp-result__page">
                <FormattedMessage
                  id="deployments.ispPagination"
                  values={{page, pageCount}}
                />
              </h5>
            )
          }
        />
        {hasResults ? (
          <>
            <ImageViewer>{images}</ImageViewer>
            <div className="isp-result__pagination_container">
              <Pagination
                count={pageCount}
                shape="rounded"
                className="isp-result__pagination"
                onChange={(e, p) => setPage(p)}
              />
            </div>
          </>
        ) : (
          <h5>
            <FormattedMessage id="form.noResults" />
          </h5>
        )}
      </div>
    </div>
  );
}

ISPResult.propTypes = {
  title: PropTypes.string,
  projectId: PropTypes.string,
  deploymentId: PropTypes.string,
};

ISPResult.defaultProps = {
  title: null,
  projectId: null,
  deploymentId: null,
};
