import React from 'react';
import {useEffect, useState, useRef} from 'react';
import {Image as KImage} from 'react-konva';

export const IMAGE_NAME = 'image';
export type ImageDetails = {
  width: number;
  height: number;
};

export type ImageProps = {
  src?: string;
  scale?: number;
  x?: number;
  y?: number;
  onStartLoading?: () => void;
  onFinishLoading?: (e: ImageDetails) => void;
  onError?: (e: Event) => void;
};

export function Image(props: ImageProps) {
  const {
    src = '',
    onFinishLoading = () => {},
    onStartLoading = () => {},
    onError = () => {},
    scale = 1,
    x = 0,
    y = 0,
  } = props;
  const imageRef = useRef<HTMLImageElement>();
  const [image, setImage] = useState<HTMLImageElement>();
  const [isBusy, setIsBusy] = useState(false);

  const onErrorLocal = (e: Event) => {
    onError(e);
    onFinishLoading({width: 0, height: 0});
  };

  const loadImage = () => {
    if (!!src) {
      setIsBusy(true);
      onStartLoading();
      const img = new window.Image();
      imageRef.current = img;
      imageRef.current.addEventListener('load', onImageLoad);
      imageRef.current.addEventListener('error', onErrorLocal);
      img.src = src;
      img.crossOrigin = 'Anonymous';
    }
  };

  const onImageLoad = (e: Event) => {
    const img = e.target as HTMLImageElement;
    setImage(imageRef.current);
    const iDimensions = {
      width: img.width,
      height: img.height,
    };
    setIsBusy(false);
    onFinishLoading(iDimensions);
  };

  useEffect(() => {
    loadImage();
    return () => {
      const iRef: any = imageRef?.current;
      if (iRef) {
        iRef.removeEventListener('load', onFinishLoading);
        iRef.removeEventListener('error', onErrorLocal);
      }
    };
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  useEffect(() => loadImage(), [src]);

  return (
    <KImage
      name={IMAGE_NAME}
      x={x}
      y={y}
      image={image}
      scaleX={scale}
      scaleY={scale}
      shadowColor="rgba(0,0,0,0.5)"
      shadowOffset={{x: 0, y: 0}}
      shadowBlur={4}
      opacity={isBusy ? 0 : 100}
    />
  );
}
