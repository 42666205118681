import {LinearProgress, withStyles} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {Skeleton} from '@material-ui/lab';
import {startCase, toLower} from 'lodash';
import React from 'react';
import {FormattedNumber} from 'react-intl';
import {VariableSizeList} from 'react-window';
import {StudioTooltip} from '../../../base-components/StudioTooltip';
import './ImageCardDetail.scss';

const ITEM_SIZE = 35;

type ImageCardDetailData = {
  label: string;
  progress: number;
};

export type ImageCardDetailProps = {
  data: ImageCardDetailData[];
  height: number;
  onHover?: (stats: ImageCardDetailData, index: number | null) => void;
  countObjects: boolean;
};

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: 'var(--greyscale2)',
  },
  bar: {
    borderRadius: 0,
    backgroundColor: 'var(--primary3)',
  },
})(LinearProgress);

export function ImageCardDetail({
  data,
  height,
  onHover,
  countObjects,
}: ImageCardDetailProps) {
  const labelsCount = countObjects
    ? data.reduce(function(rv, x) {
        const number = rv.get(x.label);
        if (!number) {
          rv.set(x.label, 1);
        } else {
          rv.set(x.label, number + 1);
        }
        return rv;
      }, new Map<string, number>())
    : new Map<string, number>();

  return (
    <React.Fragment>
      {data.length ? (
        <VariableSizeList
          height={Math.floor(height / ITEM_SIZE) * ITEM_SIZE}
          itemCount={data.length}
          itemSize={x => ITEM_SIZE}
          width="100%"
        >
          {({index, style}) => {
            const row = data[index];
            const label = startCase(toLower(row.label ?? ''));
            const title = countObjects
              ? `${label} (${labelsCount.get(row.label)})`
              : label;
            return (
              <div
                key={index}
                style={style}
                className="image-card-detail"
                onPointerEnter={_event => {
                  onHover?.(row, index);
                }}
                onPointerLeave={_event => {
                  onHover?.(row, null);
                }}
              >
                <StudioTooltip
                  title={title}
                  PopperProps={{popperOptions: {positionFixed: true}}}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className="image-card-detail__title"
                    noWrap
                  >
                    {label}
                  </Typography>
                </StudioTooltip>
                <div className="image-card-detail__progress">
                  <BorderLinearProgress
                    variant="determinate"
                    value={row.progress * 100}
                  />
                </div>
                <Typography
                  className="image-card-detail__stats"
                  variant="body2"
                  align="right"
                  color="textSecondary"
                >
                  <FormattedNumber
                    style="percent" // eslint-disable-line react/style-prop-object
                    maximumFractionDigits={1}
                    value={row.progress}
                  />
                </Typography>
              </div>
            );
          }}
        </VariableSizeList>
      ) : (
        <Skeleton animation="wave" variant="rect" height={20} />
      )}
    </React.Fragment>
  );
}
