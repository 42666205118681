import React from 'react';
import ModelActionButton from '../StudioButton/ModelActionButton';
import {ConfirmDialog} from '../StudioConfirmDialog';
import {useIntl} from 'react-intl';
import './DeleteDialog.scss';

type DeleteDialogProps = {
  onDelete: () => void;
  message: string;
  title: string;
};

function DeleteDialog({onDelete, message, title}: DeleteDialogProps) {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const confirmDelete = () => {
    onDelete();
    handleClose();
  };
  return (
    <div className="delete-dialog">
      <ModelActionButton
        type="button"
        customClass="delete-button"
        value="delete"
        nested
        onClick={handleClickOpen}
      />
      <ConfirmDialog
        type="confirm"
        title={title}
        message={message}
        open={open}
        onClose={handleClose}
        onOk={confirmDelete}
        submitLabel={intl.formatMessage({id: 'form.delete'})}
      />
    </div>
  );
}

export default DeleteDialog;
