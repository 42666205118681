import {Dialog} from '@material-ui/core';
import {Field, FieldProps, Form, Formik} from 'formik';
import React, {useState, useRef, useEffect} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {StudioCheckbox} from '../../../base-components/StudioCheckbox';
import './DownloadDetailsDialog.scss';

export type DownloadDetailsDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onDownload: (values: DownloadDetailsForm) => Promise<void>;
  disableInference?: boolean;
};

export type DownloadDetailsForm = {
  inference: boolean;
  appDetails: boolean;
  fullApplication: boolean;
};

export const DownloadDetailsDialog = ({
  isOpen,
  onDownload,
  onClose,
  disableInference,
}: DownloadDetailsDialogProps) => {
  const intl = useIntl();
  const isMounted = useRef(true);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    setIsDownloading(false);

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        if (!isDownloading) {
          onClose();
        }
      }}
      BackdropProps={{
        invisible: isDownloading ? false : true,
      }}
      className="download-details-dialog"
    >
      <h2 className="download-details-dialog__title">
        {intl.formatMessage({
          id: isDownloading ? 'test.downloadingHeader' : 'test.downloadHeader',
        })}
      </h2>
      <Formik
        initialValues={{
          inference: false,
          appDetails: false,
          fullApplication: false,
        }}
        validate={values => {
          if (!values.inference && !values.appDetails && !values.fullApplication) {
            return {inference: 'Please select at least one value'};
          }
        }}
        onSubmit={thingsToDownload => {
          setIsDownloading(true);

          onDownload(thingsToDownload).finally(() => {
            if (isMounted.current) {
              setIsDownloading(false);
            }
          });
        }}
      >
        {({isValid, dirty}) => (
          <Form>
            <FormattedMessage tagName="p" id="test.downloadDetails.comments" />
            <Field name="inference" type="checkbox">
              {({field}: FieldProps<DownloadDetailsForm['inference']>) => (
                <StudioCheckbox
                  {...field}
                  label={intl.formatMessage({id: 'test.inferenceResults'})}
                  disabled={Boolean(disableInference) || isDownloading}
                />
              )}
            </Field>
            <Field name="appDetails">
              {({field}: FieldProps<DownloadDetailsForm['appDetails']>) => (
                <StudioCheckbox
                  {...field}
                  disabled={isDownloading}
                  label={intl.formatMessage({id: 'test.appDetails'})}
                />
              )}
            </Field>
            <Field name="fullApplication">
              {({field}: FieldProps<DownloadDetailsForm['fullApplication']>) => (
                <StudioCheckbox
                  {...field}
                  disabled={isDownloading}
                  label={intl.formatMessage({id: 'test.fullApplication'})}
                />
              )}
            </Field>
            <div className="download-details-dialog__actions">
              <FormButton
                buttonRole="secondary"
                value={intl.formatMessage({id: 'form.cancel'})}
                onClick={onClose}
                type="button"
              />
              <FormButton
                buttonRole="primary"
                value={intl.formatMessage({id: 'form.submit'})}
                type="submit"
                disabled={!dirty || !isValid || isDownloading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
