export const getProjectData = state => state.project.data;

export const getProjectTransformations = state => state.project.data.transformations;

export const getProjectModel = state => state.project.data.model;

export const getProjectDataSet = state => state.project.data.dataSet;

export const getProjectDescription = state => state.project.description;

export const getProjectPrompts = state => state.project.prompts;

export const getProjectErrors = state => state.project.errors;

export const getIsProjectPending = state => state.project.isPending;

export const getProjectPendingStatus = state => state.project.pendingStatus;

export const getProjectId = state => state.project.data?.id;
