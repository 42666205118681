import axios from 'axios';
import {Field, Formik, useFormikContext} from 'formik';
import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import * as Yup from 'yup';
import {
  AuthPageBody,
  AuthPageContainer,
  AuthPageFooter,
  AuthPageForm,
  AuthPageHeader,
  AuthPagePasswordCriteriaField,
  AuthPageSubmitButton,
} from '..';
import {formikErrors} from '../../Formik/formikErrors';
import ERROR from '../../../config/error';
import URL from '../../../config/url';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {AuthError, AuthPageNewPasswordField} from '../AuthPage';
import {PasswordSchema} from '../PasswordSchema';
import './ResetPassword.scss';
import {FormattedMessage, useIntl} from 'react-intl';

const validationSchema = Yup.object({
  password: PasswordSchema,
  passwordConfirmed: Yup.string()
    .oneOf([Yup.ref('password')])
    .required(),
});

export function ResetPassword() {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const [error, setError] = useState(null);
  const intl = useIntl();

  const initialValues = {
    email: query.get('emailAddress'),
    token: query.get('token'),
    password: '',
    passwordConfirmed: '',
  };

  return (
    <AuthPageContainer>
      <Formik
        initialValues={initialValues}
        initialErrors={formikErrors(validationSchema, initialValues)}
        onSubmit={async fields => setError(await resetPassword(fields))}
        validationSchema={validationSchema}
      >
        <AuthPageForm>
          <AuthPageHeader>
            <FormattedMessage id="auth.resetPassword" />
          </AuthPageHeader>
          <AuthPageBody>
            <AuthError error={error} />
            <Field name="emailAddress" type="hidden" />
            <Field name="token" type="hidden" />
            <PasswordField name="password" label="New Password" />
            <ConfirmPasswordField name="passwordConfirmed" label="Confirm Password" />
          </AuthPageBody>
          <AuthPageFooter>
            <FormButton
              value={intl.formatMessage({id: 'form.cancel'})}
              buttonRole="naked"
              customClass="reset-password__cancel"
              onClick={() => history.push('/login')}
            />
            <AuthPageSubmitButton
              value={intl.formatMessage({id: 'auth.resetPassword'})}
            />
          </AuthPageFooter>
        </AuthPageForm>
      </Formik>
    </AuthPageContainer>
  );

  function PasswordField(props) {
    return <AuthPageNewPasswordField {...props} />;
  }

  function ConfirmPasswordField({name, ...rest}) {
    const {values} = useFormikContext();

    return (
      <AuthPagePasswordCriteriaField
        name={name}
        criteria={[
          {
            message: ERROR.PASSWORDS_MUST_MATCH,
            valid: isValidSyncAt(name, values),
          },
        ]}
        {...rest}
      />
    );
  }

  function isValidSyncAt(path, values) {
    try {
      validationSchema.validateSyncAt(path, values);
      return true;
    } catch (err) {
      return false;
    }
  }

  async function resetPassword(fields) {
    try {
      const res = await axios.post(
        URL.RESET_PASSWORD,
        {
          emailAddress: fields.email,
          token: fields.token,
          password: fields.password,
          passwordConfirmed: fields.passwordConfirmed,
        },
        {headers: {bypassDefaultErrorHandler: true}}
      );

      if (!res.data.errors) {
        window.location.replace('/');
        return null;
      }

      return res.data.errors;
    } catch (err) {
      return err;
    }
  }
}
