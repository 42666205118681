import React from 'react';
import {useIntl} from 'react-intl';
import {LiteEmptyPage} from './LiteEmptyPage';

export const LiteDeployPage = () => {
  const intl = useIntl();
  return (
    <LiteEmptyPage>
      <h5>{intl.formatMessage({id: 'studioLite.deploy.header'})}</h5>
      <p>{intl.formatMessage({id: 'studioLite.deploy.1'})}</p>
      <p>
        {intl.formatMessage(
          {id: 'studioLite.deploy.2'},
          {
            learnLink: (
              <a
                href="https://www.blaize.com/products/ai-studio/#deployment"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            ),
          }
        )}
      </p>
    </LiteEmptyPage>
  );
};
