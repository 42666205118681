import React from 'react';
import {v4} from 'uuid';
import {Box, Toolbar} from '@material-ui/core';
import {Navigation} from '../Navigation';
import {
  dataPrepareActions,
  DataPrepareStep,
  loadAnnotations,
  saveAnnotations,
} from '../../../store/dataPrepare';
import {RootState, useAppDispatch} from '../../../store';
import {useSelector} from 'react-redux';
import {useAddRawData} from './useAddRawData';
import {unwrapResult} from '@reduxjs/toolkit';
import {DropArea} from '../../../base-components/StudioDropArea';
import {DataReview} from '../DataReview/DataReview';
import {FileItem} from '../FileViewer/FileViewer';
import {useIntl} from 'react-intl';
import {DataPreparationOrigin} from '../../../types/dataset/DataPreparationContainer';
import './DataView.scss';

const {setStatus, setUploadedFiles, setUploadProgress, setDataPrep} = dataPrepareActions;

type DataViewProps = {
  projectId: string;
  inProgress: boolean;
  origin: DataPreparationOrigin;
  resourceId?: string;
  onForward: () => void;
  onBack: () => void;
};

export const DataView = ({
  projectId,
  inProgress,
  origin,
  resourceId,
  onForward,
  onBack,
}: DataViewProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const step = useSelector((state: RootState) => state.dataPrepare.step);
  const name = useSelector((state: RootState) => state.dataPrepare.name);
  const description = useSelector((state: RootState) => state.dataPrepare.description);
  const type = useSelector((state: RootState) => state.dataPrepare.type);
  const uploadedFiles = useSelector(
    (state: RootState) => state.dataPrepare.uploadedFiles
  );
  const annotationData = useSelector(
    (state: RootState) => state.dataPrepare.annotationData
  );

  const initiateAddRawData = useAddRawData({
    projectId,
    resourceId,
    onResume: () => dispatch(setStatus('UPLOADING')),
    onStart: () => dispatch(setStatus('UPLOADING')),
    onUploadProgress: event => dispatch(setUploadProgress(event)),
    onFinish: async dataPreparationContainer => {
      dispatch(setUploadedFiles([]));
      dispatch(setUploadProgress(null));
      const newDatasetId = dataPreparationContainer?.id;
      if (newDatasetId) {
        const action = await dispatch(loadAnnotations({projectId, origin}));
        const rawAnnotationData = unwrapResult(action);
        await dispatch(
          saveAnnotations({
            resourceId: newDatasetId,
            projectId,
            updatedAnnotations: {
              ...rawAnnotationData,
              info: {...rawAnnotationData.info, type},
            },
          })
        );
        dispatch(setDataPrep({container: dataPreparationContainer, context: origin}));
        dispatch(setStatus('IDLE'));
        onForward();
      }
    },
    onError: () => dispatch(setStatus('IDLE')),
  });

  const fileToImage = (file: File) => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const fr = new FileReader();

      fr.onload = () => {
        const image = new Image();
        image.onload = () => {
          resolve(image);
        };

        image.onerror = () => {
          reject();
        };

        if (typeof fr.result === 'string') {
          image.src = fr.result;
        } else {
          reject();
        }
      };

      fr.onerror = () => {
        reject();
      };

      fr.readAsDataURL(file);
    });
  };

  const handleUploadedFiles = async (files: File[], append = false) => {
    const newUploadedFiles: FileItem[] = await Promise.all(
      files.map(async file => {
        let image: HTMLImageElement | undefined;
        try {
          image = await fileToImage(file);
        } catch (e) {}

        return {
          file,
          id: v4(),
          selected: false,
          name: file.name,
          size: file.size,
          height: image?.height,
          width: image?.width,
          lastModified: new Date(file.lastModified),
        };
      })
    );
    if (append) {
      dispatch(setUploadedFiles([...uploadedFiles, ...newUploadedFiles]));
    } else {
      dispatch(setUploadedFiles(newUploadedFiles));
    }
  };

  const handleUploadedFilesDelete = () =>
    dispatch(setUploadedFiles(uploadedFiles.filter(item => !item.selected)));

  const handleUploadedFileSelect = (id: string) => {
    dispatch(
      setUploadedFiles(
        uploadedFiles.map(item => ({
          ...item,
          selected: item.id === id ? !item.selected : item.selected,
        }))
      )
    );
  };

  const handleForward = () => {
    if (step === DataPrepareStep.DATA_REVIEW) {
      if (uploadedFiles.length) {
        initiateAddRawData(uploadedFiles, name, description);
      } else {
        onForward();
      }
    } else {
      onForward();
    }
  };

  const renderStep = () => {
    if (step === DataPrepareStep.DATA_UPLOAD) {
      return (
        <DataUpload
          onChange={(files: File[]) => {
            handleUploadedFiles(files);
            onForward();
          }}
        />
      );
    } else if (step === DataPrepareStep.DATA_REVIEW) {
      const existingImages =
        annotationData?.images?.map(image => ({
          id: image.id.toString(),
          name: image.file_name,
          size: image.size,
          height: image.height,
          width: image.width,
          selected: false,
          lastModified: new Date(image.date_captured),
        })) || [];
      const dataToShow = [...existingImages, ...uploadedFiles];
      return (
        <DataReview
          title={intl.formatMessage({id: 'dataPrep.review.images'})}
          projectId={projectId}
          fileItems={dataToShow}
          readOnly={false}
          origin={origin}
          onChange={(files: File[]) => handleUploadedFiles(files, true)}
          onDelete={() => handleUploadedFilesDelete()}
          onSelect={(id: string) => handleUploadedFileSelect(id)}
          hideSelectAll
        />
      );
    }
  };

  const isValid = Boolean(uploadedFiles?.length || annotationData?.images?.length);
  const disableForward = !isValid || inProgress;

  return (
    <>
      <Toolbar className="data-prepare__header" variant="dense">
        <Box flexGrow={1}></Box>
        <Navigation
          onForward={handleForward}
          onBack={onBack}
          disableForward={disableForward}
          disableBack={inProgress}
        />
      </Toolbar>
      {renderStep()}
    </>
  );
};

export type DataUploadProps = {
  onChange(files: File[]): void;
};

export const DataUpload = ({onChange}: DataUploadProps) => {
  return (
    <div className="data-upload">
      <h2 className="data-upload__header">Let's get started!</h2>
      <div className="data-upload__caption">With 3 simple steps</div>
      <h4 className="data-upload__step-title">STEP 1/3</h4>
      <div>Add images</div>
      <DropArea
        className="data-upload__drop"
        onChange={onChange}
        size="xlg"
        accept="image/*"
      />
    </div>
  );
};
