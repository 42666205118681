import {Box, BoxProps, Popover, PopoverProps} from '@material-ui/core';
import cn from 'classnames';
import React, {useCallback} from 'react';
import './ImageCard.scss';
import {ImageCard} from './ImageCard';
import {ImageProps} from 'material-ui-image/lib/components/Image/Image';

export type ImageCardProps = BoxProps & {
  className?: string;
  title?: string;
  imageL: (w?: number, h?: number) => string;
  imageR: (w?: number, h?: number) => string;
  width?: number;
  height?: number;
};

export const DoubleImageCard = ({
  className,
  imageL,
  imageR,
  width,
  height,
  ...rest
}: ImageCardProps) => {
  const [anchorEl, setAnchorEl] = React.useState<PopoverProps['anchorEl']>(null);
  const onOpen: ImageProps['onClick'] = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const onClose: ImageProps['onClick'] = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getImageL = useCallback(imageL, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getImageR = useCallback(imageR, []);

  var lw = width * 3;
  var lh = height * 3;

  return (
    <Box
      className={cn('image-card-horizontal', className)}
      {...rest}
      style={{width: width * 2}}
      onClick={onOpen}
    >
      <Box className="image-card-horizontal_containerL">
        <ImageCard imageFunc={getImageL} width={width} height={height} isPopup={false} />
      </Box>
      <Box className="image-card-horizontal_containerR">
        <ImageCard imageFunc={getImageR} width={width} height={height} isPopup={false} />
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
        PaperProps={{
          style: {
            width: lw * 2,
            height: lh,
            overflow: 'hidden',
          },
        }}
      >
        <Box>
          <Box sx={{width: lw, height: lh, position: 'absolute'}}>
            <ImageCard imageFunc={getImageL} width={lw} height={lh} isPopup={false} />
          </Box>
          <Box sx={{width: lw, height: lh, left: lw, position: 'absolute'}}>
            <ImageCard imageFunc={getImageR} width={lw} height={lh} isPopup={false} />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
