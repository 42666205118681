import React, {useState, createContext, useEffect} from 'react';
import ls from 'local-storage';
import {LS_KEY} from '../../../config/constants';

export const ISPFlowgraphContext = createContext();

export const ISPFlowgraphProvider = ({children}) => {
  const [hasError, setHasError] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modules, setModules] = useState([]);
  const [availableModules, setAvailableModules] = useState([]);
  const [rawModules, setRawModules] = useState([]);
  const [addModule, setAddModule] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [memData, setMemData] = useState(null);
  const [origEditorData, setOrigEditorData] = useState(null);
  const [isDirty, setIsDirty] = useState(false);

  const clear = () => {
    setModules([]);
    setRawModules([]);
    setAddModule(null);
    setHasError(false);
  };

  useEffect(() => {
    const lsData = ls(LS_KEY.ISP);
    lsData && setMemData(lsData);
  }, []);

  return (
    <ISPFlowgraphContext.Provider
      value={{
        hasError,
        setHasError,
        errorMessage,
        setErrorMessage,
        clear,
        addModule,
        setAddModule,
        // processed/sorted modules. Will omit modules that are not connected to main graph
        modules,
        setModules,
        // raw added modules | used for form validation
        rawModules,
        setRawModules,
        // selected Module
        selectedModule,
        setSelectedModule,
        // Modified ISP data
        memData,
        setMemData,
        // ISP modules based on color space
        availableModules,
        setAvailableModules,
        // Initial editor data. Used for comparison when graph is dirty
        origEditorData,
        setOrigEditorData,
        // Modify ISP form Dirty
        isDirty,
        setIsDirty,
      }}
    >
      {children}
    </ISPFlowgraphContext.Provider>
  );
};
