import React, {useRef, useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import axios from 'axios';
import cn from 'classnames';
import PropTypes from 'prop-types';
import AssistantIcon from '../../assets/icons/AssistantIcon';
import {StudioDrawer} from '../../base-components/StudioDrawer';
import {Conversation} from './Conversation';
import {useConversation} from './useConversation';
import {addListener, removeListener} from '../../store/statusChecker';
import URL from '../../config/url';
import './ConversationAssistant.scss';

export const ConversationAssistant = ({isDrawer}) => {
  const [
    {isDrawerOpen, status: {conversationId, messages, projectId} = {}},
    dispatch,
  ] = useConversation();

  const reduxDispatch = useDispatch();

  const addStatusListener = useCallback(
    (eventType, handler) => reduxDispatch(addListener(eventType, handler)),
    [reduxDispatch]
  );

  const removeStatusListener = useCallback(
    eventType => reduxDispatch(removeListener(eventType)),
    [reduxDispatch]
  );

  const setStatus = useCallback(
    response => {
      const status = response.payload?.length ? response.payload[0] : {};
      dispatch({
        type: 'SET_STATUS',
        status,
      });
    },
    [dispatch]
  );

  const setStatusAfterUpdate = useCallback(
    response => {
      const status = response.data.body;
      if (status) {
        dispatch({
          type: 'SET_STATUS_FROM_UPDATE',
          status,
        });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch({
      type: 'CHAT_DRAWER_OPEN',
    });
  }, [projectId, dispatch]);

  useEffect(() => {
    addStatusListener('CA', setStatus);
    return () => removeStatusListener('CA');
  }, [setStatus, addStatusListener, removeStatusListener]);

  const updateL3History = answers => {
    let history = (messages ?? []).map(message => ({...message, source: 'ca'}));
    answers.forEach(({question, valueText}) => {
      question && history.push({text: question, source: 'ca'});
      history.push({text: valueText, source: 'user'});
    });

    dispatch({
      type: 'APPEND_HISTORY',
      history,
    });
  };

  const handleSubmit = async answers => {
    try {
      // while a submit is in progress, do not poll for status updates
      removeStatusListener('CA');
      const response = await axios.post(URL.CA_UPDATE, {
        projectId,
        conversationId,
        answers,
      });
      // submit is done, start polling again
      addStatusListener('CA', setStatus);
      updateL3History(answers);
      setStatusAfterUpdate(response);
    } catch (e) {
      // submit is done, start polling again
      addStatusListener('CA', setStatus);
    }
  };

  const handleFeedback = async ({type, id, isHelpful, index}) => {
    dispatch({
      type,
      isHelpful,
      index,
    });

    try {
      await axios.post(
        URL.CA_FEEDBACK,
        {
          messageId: id,
          helpful: isHelpful,
          projectId,
        },
        {headers: {bypassDefaultErrorHandler: true}}
      );
    } catch (e) {}
  };

  const toggle = () => {
    if (isDrawer) {
      dispatch({
        type: isDrawerOpen ? 'CHAT_DRAWER_CLOSE' : 'CHAT_DRAWER_OPEN',
      });
    }
  };

  const isOpen = !isDrawer || isDrawerOpen;

  return (
    <>
      <StudioDrawer
        isOpen={isOpen}
        anchor="left"
        className={cn(
          'conversation-assistant__drawer',
          isOpen && 'conversation-assistant__drawer--open',
          isDrawer && 'conversation-assistant__drawer--drawer'
        )}
        data-testid="conversation-assistant__drawer"
      >
        <ScrollToBottom className="conversation-assistant__scroll-container">
          <div
            className={cn(
              'conversation-assistant__body',
              isDrawer && 'conversation-assistant__body--drawer'
            )}
            data-testid="conversation-assistant-panel"
          >
            <Conversation
              theme={isDrawer ? 'dark' : 'light'}
              onSubmit={handleSubmit}
              onFeedback={handleFeedback}
            />
          </div>
        </ScrollToBottom>
      </StudioDrawer>
      <AssistantIcon
        className={cn(
          'conversation-assistant__icon',
          isDrawer && 'conversation-assistant__icon--drawer'
        )}
        onClick={toggle}
      />
    </>
  );
};

ConversationAssistant.propTypes = {
  isDrawer: PropTypes.bool,
};

ConversationAssistant.defaultProps = {
  isDrawer: false,
};

function ScrollToBottom({children, className}) {
  const scrollAnchorRef = useRef(null);

  const setResizeObserver = useCallback(node => {
    const resizeObserver = new ResizeObserver(() => {
      if (scrollAnchorRef.current) {
        scrollAnchorRef.current.scrollIntoView({behavior: 'smooth'});
      }
    });

    node && resizeObserver.observe(node);
  }, []);

  return (
    <div className={cn(className, 'scroll-to-bottom')}>
      <div className="scroll-to-bottom__inner" ref={setResizeObserver}>
        {children}
        <div ref={scrollAnchorRef} />
      </div>
    </div>
  );
}
