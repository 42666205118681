import {REQUEST_STATUS} from './reducers';

export const getProjectListData = state => state.projectList.projects;

export const getProjectListErrors = state => state.projectList.errors;

export const isProjectListAppendPending = state =>
  state.projectList.appendStatus === REQUEST_STATUS.PENDING;

export const isProjectListAppendCancelled = state =>
  state.projectList.appendStatus === REQUEST_STATUS.CANCELLED;

export const isProjectListReplacePending = state =>
  state.projectList.replaceStatus === REQUEST_STATUS.PENDING;

export const getNextStartFrom = state => state.projectList.nextStartFrom;

export const getTotalCount = state => state.projectList.totalCount;

export const hasMore = state => getNextStartFrom(state) < getTotalCount(state);
