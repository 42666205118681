import {createSlice, createAsyncThunk, Draft} from '@reduxjs/toolkit';
import axios from 'axios';
import {getDeploymentDetails} from '../../api/deployment/DeploymentDetails';
import {
  DeploymentNodesConfigurationRequest,
  getDeploymentNodesConfiguration,
} from '../../api/deployment/DeploymentNodesConfiguration';
import {
  updatePermissions,
  UPDATE_PERMISSIONS_URL,
} from '../../api/postprocessor/UpdatePermissions';
import URL from '../../config/url';
import {DeploymentDashboardRequest} from '../../types/deployment/DeploymentDashboardRequest';
import {
  DeploymentDashboardInfo,
  DeploymentDashboardResponse,
} from '../../types/deployment/DeploymentDashboardResponse';
import {DeploymentDetailsInfo} from '../../types/deployment/DeploymentDetailsInfo';
import {
  Deployment,
  DeploymentResponse,
  DeploymentStatusType,
} from '../../types/deployment/DeploymentResponse';
import {GSPConfigurationInfo} from '../../types/deployment/GSPConfigurationInfo';
import {InferenceApplicationsRequest} from '../../types/deployment/InferenceApplicationsRequest';
import {
  InferenceApplicationInfo,
  InferenceApplicationsResponse,
} from '../../types/deployment/InferenceApplicationsResponse';
import {InferenceRequest} from '../../types/deployment/InferenceRequest';

type DeploymentDashboardState = {
  details: DeploymentDetailsInfo | null;
  gspConfigurations: {
    data: Array<GSPConfigurationInfo>;
  };
  deployments: {
    data: Array<DeploymentDashboardInfo>;
    count: number;
  };
  inferenceApps: {
    data: Array<InferenceApplicationInfo>;
    count: number;
  };
  recentDeployments: {
    data: Array<DeploymentDashboardInfo>;
    count: number;
  };
  /* 
      The /start and /stop requests are synchronous with high latency. To give the user quick feedback, we will
      optimistically upate the status to "STARTING" or "STOPPING" when the request is initiated. 
      This map here stores the optimistic updates, which will be removed when the request completes.
    */
  optimisticallyUpdatedStatuses: Record<string, DeploymentStatusType | null>;
};

export const fetchInferenceApps = createAsyncThunk(
  'prod-deployment-dashboard/fetchInferenceApps',
  async (request: InferenceApplicationsRequest) => {
    const {data} = await axios.post<InferenceApplicationsResponse>(
      URL.INFERENCE_APPLICATIONS,
      request,
      {
        headers: {
          bypassDefaultErrorHandler: true,
        },
      }
    );
    return data.body;
  }
);

export const fetchDeployments = createAsyncThunk(
  'prod-deployment-dashboard/fetchDeployments',
  async (request: DeploymentDashboardRequest) => {
    const {data} = await axios.post<DeploymentDashboardResponse>(
      URL.DEPLOYMENT_DASHBOARD,
      request
    );
    return data.body;
  }
);

export const fetchRecentDeployments = createAsyncThunk(
  'prod-deployment-dashboard/fetchRecentDeployments',
  async (request: DeploymentDashboardRequest) => {
    const {data} = await axios.post<DeploymentDashboardResponse>(
      URL.DEPLOYMENT_DASHBOARD,
      {...request, params: {...request.params, status: ['RUNNING', 'IDLE', 'STOPPING']}}
    );
    return data.body;
  }
);

export const fetchDeploymentDetails = createAsyncThunk(
  'prod-deployment-dashboard/fetchDeploymentDetails',
  async (id: string) => {
    const data = await getDeploymentDetails({deploymentId: id});
    if (data.errors?.length) {
      throw new Error('Error during details fetch');
    }
    return data.body;
  }
);

export const fetchGSPConfigurations = createAsyncThunk(
  'prod-deployment-dashboard/fetchGSPConfigurations',
  async (request: DeploymentNodesConfigurationRequest) => {
    const data = await getDeploymentNodesConfiguration(request);
    if (data.errors?.length) {
      throw new Error('Error during gsp configurtion fetch');
    }
    return data.body;
  }
);

export const startDeployment = createAsyncThunk(
  'prod-deployment-dashboard/start',
  async (values: InferenceRequest) => {
    const {data} = await axios.post<DeploymentResponse>(URL.START_DEPLOYMENT, values, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        deploymentId: values.deploymentId,
      },
    });
    if (data.errors?.length) {
      throw new Error('Error during start deployment');
    }
    return data.body;
  }
);

export const restartDeployment = createAsyncThunk(
  'prod-deployment-dashboard/restart',
  async (deploymentId: string) => {
    const {data} = await axios.post<DeploymentResponse>(
      URL.RESTART_DEPLOYMENT,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          deploymentId: deploymentId,
        },
      }
    );
    if (data.errors?.length) {
      throw new Error('Error during restart deployment');
    }
    return data.body;
  }
);

export const stopDeployment = createAsyncThunk(
  'prod-deployment-dashboard/stop',
  async (id: string) => {
    const {data} = await axios.post<DeploymentResponse>(URL.STOP_DEPLOYMENT, null, {
      params: {
        deploymentId: id,
      },
    });
    if (data.errors?.length) {
      throw new Error('Error during stop deployment');
    }
    return data.body;
  }
);

export const deleteDeployment = createAsyncThunk(
  'prod-deployment-dashboard/delete',
  async (id: string) => {
    const {data} = await axios.delete<DeploymentResponse>(URL.DELETE_DEPLOYMENT, {
      params: {
        deploymentId: id,
      },
    });
    return data.body;
  }
);

export const deleteInferenceApp = createAsyncThunk(
  'prod-deployment-dashboard/delete-inference-app',
  async ({projectId, id}: {projectId: string; id: string}) => {
    try {
      await updatePermissions([
        UPDATE_PERMISSIONS_URL,
        {projectId, applicationId: id, exposeAPI: false, exposeInferencePage: false},
      ]);
    } catch (e) {
      console.error(e);
    }
  }
);

const updateStatusHelper = (
  updatedDeployment: Deployment,
  state: Draft<DeploymentDashboardState>
) => {
  if (updatedDeployment.status) {
    // update status in deployments list
    const matchedDeployment = state.deployments.data.find(
      deployment => deployment.id === updatedDeployment.id
    );
    if (matchedDeployment && updatedDeployment.status) {
      matchedDeployment.status = updatedDeployment.status;
    }

    // update status and video url in deployment details
    if (state.details?.id === updatedDeployment.id) {
      state.details.status = updatedDeployment.status;
      state.details.videoOutputRtspUrl = updatedDeployment.videoOutputRtspUrl;
    }

    // update status in gsp configurations
    for (let configuration of state.gspConfigurations.data) {
      const matchedDeployment = configuration.deployments?.find(
        deployment => deployment.id === updatedDeployment.id
      );
      if (matchedDeployment) {
        matchedDeployment.status = updatedDeployment.status;
        break;
      }
    }
  }

  // rollback optimistic update
  state.optimisticallyUpdatedStatuses[updatedDeployment.id] = null;
};

const initialState: DeploymentDashboardState = {
  details: null,
  gspConfigurations: {
    data: [],
  },
  deployments: {
    data: [],
    count: 0,
  },
  recentDeployments: {
    data: [],
    count: 0,
  },
  inferenceApps: {
    data: [],
    count: 0,
  },
  optimisticallyUpdatedStatuses: {},
};

const deploymentDashboardSlice = createSlice({
  name: 'prod-deployment-dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDeployments.fulfilled, (state, action) => {
        const {count, entries} = action.payload;
        state.deployments.count = count;
        state.deployments.data = entries;
      })
      .addCase(fetchRecentDeployments.fulfilled, (state, action) => {
        const {count, entries} = action.payload;
        state.recentDeployments.count = count;
        state.recentDeployments.data = entries;
      })
      .addCase(fetchDeploymentDetails.fulfilled, (state, action) => {
        state.details = action.payload;
      })
      .addCase(fetchGSPConfigurations.fulfilled, (state, action) => {
        state.gspConfigurations.data = action.payload;
      })
      .addCase(startDeployment.pending, (state, action) => {
        const values = action.meta.arg;
        state.optimisticallyUpdatedStatuses[values.deploymentId] = 'STARTING';
      })
      .addCase(startDeployment.rejected, (state, action) => {
        const values = action.meta.arg;
        state.optimisticallyUpdatedStatuses[values.deploymentId] = null;
      })
      .addCase(startDeployment.fulfilled, (state, action) => {
        updateStatusHelper(action.payload, state);
      })
      .addCase(stopDeployment.pending, (state, action) => {
        const deploymentId = action.meta.arg;
        state.optimisticallyUpdatedStatuses[deploymentId] = 'STOPPING';
      })
      .addCase(stopDeployment.rejected, (state, action) => {
        const deploymentId = action.meta.arg;
        state.optimisticallyUpdatedStatuses[deploymentId] = null;
      })
      .addCase(stopDeployment.fulfilled, (state, action) => {
        updateStatusHelper(action.payload, state);
      })
      .addCase(deleteDeployment.fulfilled, (state, action) => {
        const deletedDeployment = action.payload;
        const updatedDeploymentsList = state.deployments.data.filter(
          deployment => deployment.id !== deletedDeployment.id
        );
        state.deployments.data = updatedDeploymentsList;
      })
      .addCase(deleteInferenceApp.fulfilled, (state, action) => {
        const deletedInferenceAppId = action.meta.arg.id;
        state.inferenceApps.data = state.inferenceApps.data.filter(
          app => app.id !== deletedInferenceAppId
        );
      })
      .addCase(fetchInferenceApps.fulfilled, (state, action) => {
        const {count, entries} = action.payload;
        state.inferenceApps.count = count;
        state.inferenceApps.data = entries;
      });
  },
});

export const {reducer: deploymentDashboardReducer, actions} = deploymentDashboardSlice;
