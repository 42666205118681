import React from 'react';
import {Drawer} from '@material-ui/core';
import cn from 'classnames';
import './StudioDrawer.scss';

export const StudioDrawer = ({children, className, isOpen, anchor, ...props}) => (
  <Drawer
    open={isOpen}
    className={cn('studio-drawer', className)}
    anchor={anchor}
    variant="persistent"
    {...props}
  >
    {children}
  </Drawer>
);
