export const getView = state => state.view;

export const getViewProjectForm = state => state.view.project.form;
export const getViewProject = state => state.view.project;
export const getProjectFormTemplateId = state => state.view.project.form.templateId;
export const getIsNewProjectFormOpen = state => state.view.project.newFormOpen;
export const getIsEditProjectFormOpen = state => state.view.project.editFormOpen;

export const getViewISP = state => state.view.isp;
export const getIsNewISPFormOpen = state => state.view.isp.newFormOpen;
export const getIsEditIspFormOpen = state => state.view.isp.editFormOpen;
export const getIspSelectedId = state => state.view.isp.selectedId;

export const getViewConsole = state => state.view.console;
export const getIsConsoleOpen = state => state.view.console.open;

export const getIsDiabledEditDataStage = state => state.view.project.disableEditDataStage;
export const getIsDiabledEditModelStage = state =>
  state.view.project.disableAddEditModelStage;
export const getIsDiabledModelOptimizationStage = state =>
  state.view.project.disableModelOptimizationStage;
export const getIsDiabledFineTuningStage = state =>
  state.view.project.disableFineTuningStage;
export const getIsDiabledDeploymentStage = state =>
  state.view.project.disableDeploymentStage;

export const getTerminateState = state => state.view.project.terminate;
export const getIsTerminateDialogOpen = state => getTerminateState(state).dialogOpen;
export const getTerminateDialogTitle = state => getTerminateState(state).dialogTitle;
export const getTerminateDialogMessage = state => getTerminateState(state).dialogMessage;
export const getIsTerminationEnabled = state => getTerminateState(state).enabled;
