import React from 'react';
import {useIntl} from 'react-intl';

export const LiteProjects = () => {
  const intl = useIntl();
  return (
    <div>
      <p>{intl.formatMessage({id: 'studioLite.projects.1'})}</p>
      <p>
        {intl.formatMessage(
          {id: 'studioLite.projects.2'},
          {
            learnLink: (
              <a
                href="https://www.blaize.com/products/ai-studio/#projects"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            ),
          }
        )}
      </p>
    </div>
  );
};
