import PropTypes from 'prop-types';

export const QuestionProps = PropTypes.shape({
  type: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  mandatory: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.shape({
    name: PropTypes.string,
  }),
  subQuestions: PropTypes.shape({
    name: PropTypes.object,
  }),
  parentKey: PropTypes.string,
});

export const AnswerProps = PropTypes.shape({
  type: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  value: PropTypes.any,
  values: PropTypes.arrayOf(PropTypes.string),
  invalid: PropTypes.bool,
});
