import React from 'react';
import {PropTypes} from 'prop-types';
import {useField} from 'formik';
import {StudioTextField as TextField} from '../../../base-components/StudioTextField';
import {useDynamicFormField} from '../DynamicFormToFormikAdapter';
import {validateField} from '../DynamicForm.helpers';

export const DynamicTextField = ({
  label,
  className,
  name,
  type,
  required,
  disabled,
  validationType,
  placeholder,
  tooltip,
  interval,
  inputProps,
  multiline,
}) => {
  useDynamicFormField(name);
  const [field, meta] = useField({
    name,
    validate: value => validateField(value, validationType, required, interval),
  });
  const errorText = meta.initialError || (meta.error && meta.touched) ? meta.error : '';

  if (field.value === undefined) {
    return null;
  }

  return (
    <TextField
      id={name}
      name={name}
      type={type}
      label={label}
      tooltip={tooltip}
      required={required}
      disabled={disabled}
      error={!!errorText}
      multiline={multiline}
      className={className}
      helperText={errorText}
      inputProps={inputProps}
      placeholder={placeholder}
      {...field}
    />
  );
};

DynamicTextField.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  validationType: PropTypes.string,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  maxLength: PropTypes.number,
};

DynamicTextField.defaultProps = {
  className: null,
  required: false,
  disabled: false,
  type: 'string',
  validationType: 'STRING',
  placeholder: null,
};
