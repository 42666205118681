import axios from 'axios';
import {useCallback, useEffect, useRef} from 'react';
import URL_CONST, {DATASET_OP, DATASET_SUB_OP} from '../../../config/url';
import {useAppDispatch} from '../../../store';
import {DataPrepareUploadProgress} from '../../../store/dataPrepare';
import {addListener, removeListener} from '../../../store/statusChecker';
import {DataPreparationContainer} from '../../../types/dataset/DataPreparationContainer';
import {DatasetRawClarifyResponse} from '../../../types/dataset/DatasetRawClarifyResponse';
import {
  DatasetRawFilesystemResponse,
  FilesystemResponse,
} from '../../../types/dataset/DatasetRawFilesystemResponse';
import {DatasetRawFinalizeResponse} from '../../../types/dataset/DatasetRawFinalizeResponse';
import {Status} from '../../../types/operation/Status';
import {StatusResponse} from '../../../types/operation/StatusResponse';
import Util from '../../../util';
import {FileItem} from '../FileViewer/FileViewer';
import fileSize from 'filesize';

type AddRawDataProps = {
  projectId: string;
  resourceId?: string;
  onResume?: () => void;
  onStart?: () => void;
  onUploadProgress?: (event: DataPrepareUploadProgress) => void;
  onStatus?: (status: Status) => void;
  onFinish?: (dataPreparationContainer: DataPreparationContainer) => void;
  onError?: (error: unknown) => void;
};

const TASK_TYPE = 'ADD_RAW_DATA';

export const useAddRawData = ({
  projectId,
  resourceId,
  onResume,
  onStart,
  onUploadProgress,
  onStatus,
  onFinish,
  onError,
}: AddRawDataProps) => {
  const dispatch = useAppDispatch();
  const onResumeRef = useRef(onResume);
  const onStartRef = useRef(onStart);
  const onUploadProgressRef = useRef(onUploadProgress);
  const onStatusRef = useRef(onStatus);
  const onFinishRef = useRef(onFinish);
  const onErrorRef = useRef(onError);

  useEffect(() => {
    onResumeRef.current = onResume;
    onStartRef.current = onStart;
    onUploadProgressRef.current = onUploadProgress;
    onStatusRef.current = onStatus;
    onFinishRef.current = onFinish;
    onErrorRef.current = onError;
  }, [onResume, onStart, onUploadProgress, onStatus, onFinish, onError]);

  const monitorProgress = useCallback(() => {
    return new Promise<void>(resolve => {
      const handleStatusUpdate = (status: Status) => {
        onStatusRef.current?.(status);
        if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
          dispatch(removeListener(TASK_TYPE));
          resolve();
        }
      };
      dispatch(addListener(TASK_TYPE, handleStatusUpdate));
    });
  }, [dispatch]);

  const finalize = useCallback(
    async (name: string | null, description: string | null) => {
      const {data} = await axios.post<DatasetRawFinalizeResponse>(
        URL_CONST.DATASET_OP_RAW(DATASET_OP.IMPORT, DATASET_SUB_OP.FINALIZE),
        {
          projectId,
          resourceId,
          answers: [
            {
              key: 'Dataset.Name',
              value: name,
            },
            {
              key: 'Dataset.Description',
              value: description,
            },
          ],
        }
      );
      if (data.errors?.length) {
        throw new Error('Error finalizing raw image upload');
      }
      if (data.body && onFinishRef.current) {
        onFinishRef.current(data.body);
      }
    },
    [projectId, resourceId]
  );

  useEffect(() => {
    const resumeTaskIfExists = async () => {
      try {
        const {data} = await axios.get<StatusResponse>(
          URL_CONST.PROJECT_OP_STATUS(projectId)
        );
        const {tasks} = data.body;
        const exists = tasks?.find(task => task.type === TASK_TYPE);
        if (!exists) {
          return;
        }
        onResumeRef.current?.();
        await monitorProgress();
        // if resuming, we don't have access to name and description any more
        await finalize(null, null);
      } catch (e) {
        onErrorRef.current?.(e);
      }
    };
    resumeTaskIfExists();
  }, [finalize, monitorProgress, projectId]);

  const clarify = async (initiateResponse: FilesystemResponse) => {
    const {data} = await axios.post<DatasetRawClarifyResponse>(
      URL_CONST.DATASET_OP_RAW(DATASET_OP.IMPORT, DATASET_SUB_OP.CLARIFY),
      {...initiateResponse, resourceId}
    );
    if (data.errors?.length) {
      throw new Error('Error clarifying raw image upload');
    }
  };

  const uploadFileItems = async (fileItems: Array<FileItem>) => {
    onStartRef.current?.();
    const formData = new FormData();
    for (const fileItem of fileItems) {
      if (fileItem.file) {
        formData.append('file', fileItem.file, fileItem.name);
      }
    }
    formData.append('projectId', projectId);

    const {data} = await axios.post<DatasetRawFilesystemResponse>(
      URL_CONST.DATASET_OP_RAW(DATASET_OP.IMPORT, DATASET_SUB_OP.FILESYSTEM),
      formData,
      {
        onUploadProgress: progressEvent =>
          onUploadProgressRef.current?.({
            progress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
            total: fileSize(progressEvent.total),
          }),
      }
    );
    if (data.errors?.length) {
      throw new Error('Error initiating raw image upload');
    }
    return data.body;
  };

  const initiate = async (
    fileItems: Array<FileItem>,
    name: string | null,
    description: string | null
  ) => {
    if (fileItems.length) {
      try {
        const initiateResponse = await uploadFileItems(fileItems);
        if (initiateResponse) {
          await clarify(initiateResponse);
          await monitorProgress();
          await finalize(name, description);
        }
      } catch (e) {
        onErrorRef.current?.(e);
      }
    }
  };

  return initiate;
};
