import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {InferenceSession} from '../../types-external/onnxruntime-web';

type SessionStoreState = {
  cache: Record<string, InferenceSession | undefined>;
};

const initialState: SessionStoreState = {
  cache: {},
};

const sessionStoreSlice = createSlice({
  name: 'onnx-session-store',
  initialState,
  reducers: {
    addSession: (
      state,
      action: PayloadAction<{id: string; session: InferenceSession}>
    ) => {
      const updatedCache = {...state.cache, [action.payload.id]: action.payload.session};
      return {...state, cache: updatedCache};
    },
  },
});

export const {reducer: sessionStoreReducer, actions} = sessionStoreSlice;
