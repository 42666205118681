import {get, pick} from 'lodash';

// Category availability based on input colorspace
export const MODULE_FILTER_MAP = {
  bayer_rggb: ['bayer', 'bayer_rgb', 'rgb'],
  rgb: ['rgb'],
};

export const MODULE_MAP = {
  bayer: {label: 'Bayer Processing', code: 'bayer'},
  bayer_rgb: {label: 'Bayer to RGB Processing', code: 'bayer_rgb'},
  rgb: {label: 'RGB Processing', code: 'rgb'},
  rgb_yuv: {label: 'RGB to YUV Processing', code: 'rgb_yuv'},
  yuv: {label: 'YUV Processing', code: 'yuv'},
  yuv_rgb: {label: 'YUV to RGB Processing', code: 'yuv_rgb'},
};

export const getIspModules = transformations => {
  let ispModules = [];

  if (transformations.libraries && transformations.libraries.length > 0) {
    // Pick the first one; needs to be modified if there are
    // additional module libraries besides ISP
    // TODO: Enhance this to accomodate multiple libraries later
    ispModules = transformations.libraries[0].modules;
    ispModules.forEach(m => {
      const inputType = String(m.module_inputs[0].type).toLowerCase();
      const outputType = String(m.module_outputs[0].type).toLowerCase();
      const preset =
        inputType === outputType
          ? MODULE_MAP[inputType]
          : MODULE_MAP[`${inputType}_${outputType}`];
      m.label = m.module_display_name;
      m.id = `${preset.code}_${m.module_name}`;
      m.module_category_code = preset.code;
      m.module_category = preset.label;
    });
  }
  return ispModules;
};

export const hasNoBranches = arr => {
  const inIdArr = [];
  const outIdArr = [];

  arr.forEach(node => {
    const {inId, outId} = node.uiMeta;
    inId && inIdArr.push(inId);
    outId && outIdArr.push(outId);
  });

  const maxConn = arr.length - 1;
  return inIdArr.length === maxConn && outIdArr.length === maxConn;
};

export const getFlowgraphNodeArr = nodes => {
  const sortedArr = [];
  const waitArr = []; // nodes staging array while the connection is not present in the main line
  const addedIds = []; //lookup which has been added

  const getTargetIndex = (inId, outId) => {
    if (inId && addedIds.includes(inId)) {
      return addedIds.indexOf(inId) + 1;
    } else if (outId && addedIds.includes(outId)) {
      return addedIds.indexOf(outId);
    }
    return undefined;
  };
  const addToSortedArr = (tIndex, data) => {
    sortedArr.splice(tIndex, 0, data);
    addedIds.splice(tIndex, 0, data.uiMeta.id);
  };

  // Organize the modules in sequence
  nodes.forEach((node, index) => {
    const {data} = node;

    // Disregard branching, take only the first node "[0]"
    let inId = get(node, 'inputs.in-0.connections[0].node');
    let outId = get(node, 'outputs.out-0.connections[0].node');

    data.uiMeta = {
      id: node.id,
      inId,
      outId,
    };
    if (sortedArr.length) {
      let targetIndex = getTargetIndex(inId, outId);
      if (targetIndex === undefined) {
        waitArr.push(data);
      } else {
        if (targetIndex !== -1 && (inId !== undefined || outId !== undefined)) {
          addToSortedArr(targetIndex, data);
        }
      }
    } else if (index === 0 || inId > -1 || outId > -1) {
      // accept the first one node > 1 as long as it is connected
      sortedArr.push(data);
      addedIds.push(node.id);
    }

    let wIndex = 0;
    let wNode, targetIndex;
    while (wIndex < waitArr.length) {
      wNode = waitArr[wIndex];
      let {inId, outId} = wNode.uiMeta;
      targetIndex = getTargetIndex(inId, outId);
      if (targetIndex !== undefined) {
        wIndex = -1; //reset
        waitArr.shift(); //remove the element from the array
        addToSortedArr(targetIndex, wNode);
      }
      wIndex++;
    }
  });
  return sortedArr;
};

const EDITOR_META = 'editor';
const MODULE_FIELDS = [
  'module_enabled',
  'module_name',
  'module_display_name',
  'module_type',
  'module_inputs',
  'module_outputs',
  'module_parameters',
  'module_description',
  'module_prefix',
];
export const getEditorMetadata = data => {
  const meta = get(data, 'transformations.libraries[0].meta');
  if (meta) {
    const index = meta.names.indexOf(EDITOR_META);
    if (index > -1) {
      return JSON.parse(meta.values[index]);
    }
  }
  return null;
};

export const convertReteToBlazeISPTransformation = (
  origData,
  sortedModules,
  reteDefs
) => {
  const lib = pick(get(origData, 'transformations.libraries[0]'), ['label', 'name']);
  lib.meta = {
    names: [EDITOR_META],
    values: [JSON.stringify(reteDefs)],
  };
  lib.modules = sortedModules.map(module => {
    const cModule = pick(module, MODULE_FIELDS);
    const {uiMeta} = module;

    // Notes : meta values should be in string format. Otherwise BE will throw an error
    cModule.meta = {
      names: [],
      values: [],
    };
    // add module position
    cModule.meta.names.push('position');
    cModule.meta.values.push(JSON.stringify(reteDefs.nodes[uiMeta.id].position));
    return cModule;
  });

  return lib;
};
