import Konva from 'konva';
import {KonvaPointerEvent} from 'konva/types/PointerEvents';
import {compact} from 'lodash';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Stage, Layer, Rect, Text, Line, Group} from 'react-konva';
import {
  BOX_LABEL_DEFAULT,
  BOX_LABEL_SELECTED,
  TEXT_COLOR,
  TEXT_SELECTED_COLOR,
} from '../../StudioAnnotator/const';
import {BoxLabelStyleProps} from '../../StudioAnnotator/types';
import {StudioTooltip} from '../../StudioTooltip';
import './StudioCanvasOverlay.scss';

export type BoundingBoxType = {
  label?: string;
  bbox?: number[] | null | undefined;
  progress?: number;
};

export type StudioCanvasOverlayProps = {
  data: BoundingBoxType[];
  width: number;
  height: number;
  fontSize?: number | undefined;
  textColor?: string | undefined;
  boxColor?: string | undefined;
  onHover?: (index: number) => void;
  showAll?: boolean | undefined;
  showLabel?: boolean | undefined;
  selectOnMouseOver?: boolean | undefined;
  highlightIndex?: number | null;
  children?: React.ReactNode;
};

export const StudioCanvasOverlay = ({
  data,
  width = 100,
  height = 100,
  fontSize = 12,
  onHover = () => {},
  showAll = true,
  showLabel = false,
  selectOnMouseOver = false,
  highlightIndex = null,
  children,
}: StudioCanvasOverlayProps) => {
  const intl = useIntl();
  const overlayRef = useRef<HTMLDivElement>(null);
  const [selection, setSelection] = useState<number | null>(null);

  const onMouseOver = (e: KonvaPointerEvent, index: number) => {
    const target = e.currentTarget;
    target.moveToTop();
    setSelection(index);
    onHover(index);
  };

  const formatLabel = (progress?: number, label?: string, maximumFractionDigits = 0) => {
    return compact([
      label,
      progress &&
        `(${intl.formatNumber(progress, {
          style: 'percent',
          maximumFractionDigits,
        })})`,
    ]).join(' ');
  };

  const selectedX = (selection !== null && data?.[selection]?.bbox?.[0]) || 0;
  const selectedY = (selection !== null && data?.[selection]?.bbox?.[1]) || 0;
  const selectedWidth = (selection !== null && data?.[selection]?.bbox?.[2]) || 0;
  const selectedHeight = (selection !== null && data?.[selection]?.bbox?.[3]) || 0;

  const anchorEl = {
    clientWidth: selectedWidth,
    clientHeight: selectedHeight,
    getBoundingClientRect() {
      const offset = overlayRef.current?.getBoundingClientRect();
      if (!offset) {
        return null;
      }
      return {
        x: selectedX + offset.x,
        y: selectedY + offset.y,
        width: selectedWidth,
        height: selectedHeight,
        top: selectedY + offset.top,
        right: selectedX + selectedWidth + offset.left,
        bottom: selectedY + selectedHeight + offset.top,
        left: selectedX + offset.left,
      };
    },
  };

  return (
    <div ref={overlayRef}>
      <StudioTooltip
        open={selection !== null}
        title={
          selection !== null &&
          formatLabel(data?.[selection]?.progress, data?.[selection]?.label, 1)
        }
        disableFocusListener
        disableHoverListener
        disableTouchListener
        PopperProps={{
          anchorEl,
          placement: 'bottom-start',
        }}
      >
        <div />
      </StudioTooltip>
      <Stage
        className="canvas-overlay-container"
        style={{width, height}}
        width={width}
        height={height}
      >
        <Layer>
          {children}
          {data &&
            data.map((box, index) => {
              if (
                box.bbox &&
                (showAll || index === selection || index === highlightIndex)
              ) {
                const [x0, y0, bboxWidth, bboxHeight] = box.bbox;
                const selected =
                  selectOnMouseOver && (index === selection || index === highlightIndex);
                const style: BoxLabelStyleProps = selected
                  ? BOX_LABEL_SELECTED
                  : BOX_LABEL_DEFAULT;

                return (
                  <Fragment key={index}>
                    <Group
                      x={x0}
                      y={y0}
                      onMouseOver={(e: KonvaPointerEvent) => onMouseOver(e, index)}
                      onMouseOut={() => setSelection(null)}
                    >
                      {showLabel && (
                        <AnnotationLabel
                          x0={x0}
                          y0={y0}
                          bboxWidth={bboxWidth}
                          imageWidth={width}
                          label={formatLabel(box.progress, box.label)}
                          selected={selected}
                          fontSize={fontSize}
                          fill={style.stroke}
                        />
                      )}
                      <Line
                        x={0}
                        y={0}
                        points={[
                          0,
                          0,
                          bboxWidth,
                          0,
                          bboxWidth,
                          bboxHeight,
                          0,
                          bboxHeight,
                          0,
                          0,
                        ]}
                        {...style}
                        strokeWidth={1}
                        hitStrokeWidth={20}
                        shadowForStrokeEnabled
                      />
                    </Group>
                  </Fragment>
                );
              }
              return <></>;
            })}
        </Layer>
      </Stage>
    </div>
  );
};

export default StudioCanvasOverlay;

type AnnotationLabelProps = {
  x0: number;
  y0: number;
  bboxWidth: number;
  imageWidth: number;
  fontSize: number;
  selected: boolean;
  label: string;
  fill: string;
};

const AnnotationLabel = ({
  x0,
  y0,
  bboxWidth,
  imageWidth,
  fontSize,
  selected,
  label,
  fill,
}: AnnotationLabelProps) => {
  const LABEL_PADDING = 5;
  const BORDER_SIZE = 1;

  const textRef = useRef<Konva.Text>(null);
  const [textWidth, setTextWidth] = useState(0);

  const flagHeight = fontSize + LABEL_PADDING * 2;
  const flagWidth = Math.max(bboxWidth, textWidth) + 2 * BORDER_SIZE;
  const x1 = x0 + flagWidth;

  // if flag is overflowing in x axis, shift left by overflow amount
  const labelX = (x1 > imageWidth ? imageWidth - x1 : 0) - BORDER_SIZE;

  // if flag is overflowing in y axis, place flag inside the bounding box
  const labelY = y0 < flagHeight ? 0 : -1 * flagHeight;

  useEffect(() => {
    if (textRef.current) {
      const width = textRef.current.getTextWidth();
      setTextWidth(width + 2 * LABEL_PADDING);
    }
  }, []);

  return (
    <>
      <Rect
        opacity={0.75}
        x={labelX}
        y={labelY}
        width={flagWidth}
        height={flagHeight}
        fill={fill}
      />
      <Text
        ref={textRef}
        text={label}
        ellipsis
        x={labelX + LABEL_PADDING}
        y={labelY + LABEL_PADDING}
        height={flagHeight}
        fontSize={fontSize}
        fill={selected ? TEXT_SELECTED_COLOR : TEXT_COLOR}
      />
    </>
  );
};
