import {request} from '../request';

export type DeploymentDeleteRequest = {
  deploymentId: string;
};

export type DeploymentDeleteResponse = {};

export async function deleteDeploymentDelete(params: DeploymentDeleteRequest) {
  return request<DeploymentDeleteResponse>({
    method: 'delete',
    url: '/deployment/delete',
    params,
  });
}
