import * as yup from 'yup';
import ERROR from '../../config/error';

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .required(ERROR.PROJECT_NAME_REQUIRED())
    .max(32, ERROR.PROJECT_NAME_TOO_LONG()),
  description: yup.string(),
});

export default validationSchema;
