import React, {useState} from 'react';
import {Skeleton as BaseSkeleton} from '@material-ui/lab';

import './Skeleton.scss';

interface GridSkeletonProps {
  tileWidth: number;
  tileHeight: number;
  tileMarginX: number;
  tileMarginY: number;
  minTiles?: number;
  maxTiles?: number;
}

/**
 * Renders a grid of skeleton elements based on the provided tile dimensions and margins.
 *
 * @component Skeleton
 * @param {object} props - The props object.
 * @param {number} props.tileWidth - The width of each tile in pixels.
 * @param {number} props.tileHeight - The height of each tile in pixels.
 * @param {number} props.tileMarginX - The horizontal margin between tiles in pixels.
 * @param {number} props.tileMarginY - The vertical margin between tiles in pixels.
 * @param {number} [props.minTiles] - The minimum number of tiles to render.
 * @param {number} [props.maxTiles] - The maximum number of tiles to render.
 * @returns {JSX.Element} The rendered Skeleton component.
 */
export function GridSkeleton({
  tileWidth,
  tileHeight,
  tileMarginX,
  tileMarginY,
  minTiles = 0,
  maxTiles = 100,
}: GridSkeletonProps): JSX.Element {
  const [totalSkeletons, setTotalSkeletons] = useState(minTiles);

  return (
    <div
      className="grid-skeleton-container"
      data-testid="grid-skeleton-container"
      ref={node => {
        if (node?.parentElement) {
          const {
            offsetWidth: parentWidth,
            offsetHeight: parentHeight,
          } = node.parentElement;
          const numColumns = Math.floor(parentWidth / (tileWidth + tileMarginX));
          const numRows = Math.floor(parentHeight / (tileHeight + tileMarginY));
          const tilesQuantities = numColumns * numRows;
          const newTilesQuantities = Math.min(
            Math.max(tilesQuantities, minTiles),
            maxTiles
          );

          if (newTilesQuantities !== totalSkeletons) {
            setTotalSkeletons(newTilesQuantities);
          }
        }
      }}
    >
      {[...new Array(totalSkeletons)].map((_, index) => (
        <BaseSkeleton
          key={`skeleton-${index}`}
          variant="rect"
          style={{
            width: `${tileWidth}px`,
            height: `${tileHeight}px`,
            margin: `${tileMarginY}px ${tileMarginX}px`,
          }}
        />
      ))}
    </div>
  );
}
