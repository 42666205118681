import React, {useState} from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Divider} from '@material-ui/core';
import './MenuList.scss';

const FREQUENTLY_USED_MAX_COUNT = 3;
/* eslint-disable-next-line */
export interface MenuListLocation {
  x: number;
  y: number;
}
/* eslint-disable-next-line */
export interface MenuListProps {
  items?: string[];
  open?: boolean;
  enableSearch?: boolean;
  location?: MenuListLocation | null;
  onSelect?: (value: string) => void | void;
  onClose?: () => void | void;
}

type LabelFrequencyUsage = {
  label: string;
  frequency: number;
};

const initialLocation = {
  x: 0,
  y: 0,
};

export function MenuList({
  open = false,
  items = [],
  enableSearch = false,
  location = initialLocation,
  onSelect = () => {},
  onClose = () => {},
}: MenuListProps) {
  const [frequentlyUsed, setFrequentlyUsed] = useState<LabelFrequencyUsage[]>([]);

  const onClick = (event: React.MouseEvent<HTMLLIElement>, label: string) => {
    event.preventDefault();
    onSelect(label);
    onClose();

    // Setup the label frequency count
    let tLabel: LabelFrequencyUsage | undefined = frequentlyUsed.find(
      el => el.label === label
    );
    tLabel = {label, frequency: tLabel ? tLabel.frequency + 1 : 1};
    const arr = [...frequentlyUsed.filter(i => i.label !== label)];
    arr.push({...tLabel});
    setFrequentlyUsed(arr.sort((a, b) => b.frequency - a.frequency));
  };

  const top3Labels = frequentlyUsed.slice(0, FREQUENTLY_USED_MAX_COUNT);
  const otherLabels = items?.filter(item => !top3Labels.find(el => el.label === item));
  return (
    <Menu
      keepMounted
      open={open}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={{top: location?.y || 0, left: location?.x || 0}}
    >
      {!!top3Labels.length &&
        top3Labels?.map((item, i) => (
          <MenuItem key={i} onClick={e => onClick(e, item.label)}>
            {item.label}
          </MenuItem>
        ))}
      {!!top3Labels.length && !!otherLabels.length && <Divider />}
      {otherLabels.map((item, i) => (
        <MenuItem key={i} onClick={e => onClick(e, item)}>
          {item}
        </MenuItem>
      ))}
    </Menu>
  );
}

export default MenuList;
