import {useEffect, useRef, useState} from 'react';
import {getAnnotations} from '../../api/dataset/GetAnnotations';
import {deploymentExecute, testExecute} from '../../api/gsp/ExecuteUI';
import {CategoryType} from '../../types/dataset/DatasetGetAnnotationsResponse';
import {RawInferenceResponse} from '../../types/gsp/RawInferenceResponse';
import {useApplicationPostProcessor} from '../PrePostProcessing/PostProcessing/useApplicationPostProcessor';
import {usePostProcessingScript} from '../PrePostProcessing/PostProcessing/usePostProcessingScript';
import {ApplicationProcessing} from '../../types/project/Application';

type OpenVXRuntimeContainerProps = {
  projectId: string;
  application: ApplicationProcessing;
  deploymentId?: string | undefined;
  images: File[];
  onFinish?: () => void;
  onError?: (message?: string) => void;
};

export const OpenVXRuntimeContainer = ({
  projectId,
  application,
  deploymentId,
  images,
  onError,
  onFinish,
}: OpenVXRuntimeContainerProps) => {
  const onErrorRef = useRef(onError);
  const onFinishRef = useRef(onFinish);
  const [labels, setLabels] = useState<CategoryType[]>();
  const [inferenceOutput, setInferenceOutput] = useState<RawInferenceResponse>();
  const appPostProcessor = application.postProcessors?.find(
    pp => pp.postProcessorType === 'APPLICATION'
  );

  const fileName = appPostProcessor?.filePaths?.[0];

  const module = usePostProcessingScript({
    projectId,
    id: application.id,
    hasPostProcessors: appPostProcessor !== undefined,
    fileName,
    postProcessorType: 'APPLICATION',
    executionContainer: 'BROWSER',
    viewOnly: true,
  });

  useEffect(() => {
    let cancelled = false;
    const fetchAnnotations = async () => {
      try {
        const {data} = await getAnnotations({
          applicationId: application.id,
          splitType: 'TRAIN',
        });
        !cancelled && data.categories && setLabels(data.categories);
      } catch (e) {
        onErrorRef.current?.();
        console.error(e);
      }
    };

    fetchAnnotations();

    return () => {
      cancelled = true;
    };
  }, [application.id]);

  useEffect(() => {
    let cancelled = false;
    async function runInference() {
      let response;

      if (deploymentId) {
        response = await deploymentExecute({
          deploymentId,
          files: images,
        });
      } else {
        response = await testExecute({
          applicationId: application.id,
          files: images,
          targetDeviceType: 'GSP',
        });
      }

      if (!cancelled) {
        if (response?.body && !response?.errors?.length) {
          setInferenceOutput(response.body);
          onFinishRef.current?.();
        } else {
          onErrorRef.current?.();
        }
      }
    }

    runInference();

    return () => {
      cancelled = true;
    };
  }, [images, application.id, deploymentId]);

  useApplicationPostProcessor({
    module,
    type: 'inference',
    inferenceOutput,
    uploadedFiles: images,
    labels,
  });

  return null;
};
