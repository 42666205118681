import React, {Component} from 'react';
import PropTypes from 'prop-types';

class FeedBackLikeIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="22px"
          height="19px"
          viewBox="0 0 22 19"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <path
              d="M1.92382812,21 L1.92382812,10 L5.57617188,10 L5.57617188,21 L1.92382812,21 Z M22.0761719,10.9023438 L22.0761719,12.75 C22.0761719,12.9791678 22.0332036,13.2083322 21.9472656,13.4375 L19.1972656,19.8828125 C18.8821599,20.6276079 18.3235717,21 17.5214844,21 L9.27148438,21 C8.7558568,21 8.31901221,20.8209653 7.9609375,20.4628906 C7.60286279,20.1048159 7.42382812,19.6679713 7.42382812,19.1523438 L7.42382812,10 C7.42382812,9.48437242 7.61002418,9.05468922 7.98242188,8.7109375 L13.9980469,2.65234375 L14.9863281,3.640625 C15.2441419,3.89843879 15.3730469,4.2135398 15.3730469,4.5859375 L15.3300781,4.88671875 L14.4707031,9.09765625 L20.2714844,9.09765625 C20.758466,9.09765625 21.1809878,9.27669092 21.5390625,9.63476562 C21.8971372,9.99284033 22.0761719,10.4153621 22.0761719,10.9023438 Z"
              id="feedBackLikeIcon"
            ></path>
          </defs>
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-845.000000, -3844.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 3051.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-feedback-like-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#feedBackLikeIcon"></use>
                      </mask>
                      <use
                        id="like"
                        fill="currentColor"
                        xlinkHref="#feedBackLikeIcon"
                      ></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

FeedBackLikeIcon.propTypes = {
  className: PropTypes.string,
};

export default FeedBackLikeIcon;
