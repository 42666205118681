import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './SubheaderButton.scss';

import ChevronDownIcon from '../../assets/icons/ChevronDownIcon';
import ChevronUpIcon from '../../assets/icons/ChevronUpIcon';
import ViewToggleIcon from '../../assets/icons/ViewToggleIcon';
import PropertiesIcon from '../../assets/icons/PropertiesIcon';
import PlusIcon from '../../assets/icons/PlusIcon';
import ArrowRightIcon from '../../assets/icons/ArrowRightIcon';

class SubheaderButton extends Component {
  render() {
    const {id, value, buttonIcon, onClick, customClass, disabled} = this.props;

    return (
      <div className="subheader-button-holder" data-testid={this.props['data-testid']}>
        <button
          {...(id ? {id: id} : {})}
          className={`subheader-button ${customClass || ''}`}
          onClick={onClick}
          disabled={disabled}
        >
          {value && (
            <div>
              <span>{value}</span>
            </div>
          )}
          {buttonIcon && this.getIcon(buttonIcon)}
        </button>
      </div>
    );
  }

  getIcon(name) {
    const icons = {
      chevronDown: <ChevronDownIcon className="buttonIcon" />,
      chevronUp: <ChevronUpIcon className="buttonIcon" />,
      viewToggle: <ViewToggleIcon className="buttonIcon" />,
      properties: <PropertiesIcon className="buttonIcon" />,
      plus: <PlusIcon className="buttonIcon" />,
      arrowRight: <ArrowRightIcon classname="buttonIcon" />,
    };
    return icons[name];
  }
}

SubheaderButton.propTypes = {
  value: PropTypes.string,
  buttonIcon: PropTypes.oneOf([
    'chevronDown',
    'chevronUp',
    'viewToggle',
    'properties',
    'plus',
    'arrowRight',
  ]),
  id: PropTypes.string,
  onClick: PropTypes.func,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
};

SubheaderButton.defaultProps = {
  value: null,
  buttonIcon: null,
  id: null,
  onClick: () => null,
  customClass: null,
  disabled: false,
};

export default SubheaderButton;
