import React, {useEffect} from 'react';
import useSWRImmutable from 'swr/immutable';
import {DataViewerItem} from '../../DataViewer/DataViewerItem';
import {GridViewer} from '../../DataViewer/GridViewer';
import {GridViewerItem} from '../../DataViewer/GridViewerItem';
import {
  ItemType,
  ListViewer,
  ListViewerDimensions,
  ListViewerFilename,
  ListViewerFilesize,
  ListViewerItem,
  ListViewerThumbnail,
  useListViewerRow,
} from '../../DataViewer/ListViewer';
import moment from 'moment';
import {Pagination} from '@material-ui/lab';
import {DatasetListRequest} from '../../../types/dataset/DatasetListRequest';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {StudioTooltip} from '../../../base-components/StudioTooltip';
import {Check} from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {LabelViewer} from '../../DataViewer/LabelViewer';
import {Button} from '@material-ui/core';
import ArrowLeftIcon from '../../../assets/icons/ArrowLeftIcon';
import {DataViewerLabel} from '../../DataViewer/DataViewerLabel';
import {THUMBNAIL_SIZE_GRID, THUMBNAIL_SIZE_LIST} from '../ClassificationStep/const';
import {DATASET_SUB_OP as OP} from '../../../config/url';
import {DataPreparationOrigin} from '../../../types/dataset/DataPreparationContainer';
import {getOriginUrl} from '../helpers';
import axios from 'axios';
import {ImageDimensionMap} from '../../DataViewer';
import './FileViewer.scss';

const GRID_PAGE_SIZE = 50;
const LIST_PAGE_SIZE = 15;
const LABEL_PAGE_SIZE = 5;
const LABEL_FILE_COUNT = 20;

// Get image url
const getDatasetImageCacheUrl = (
  projectId: string,
  ids: string,
  size: number,
  origin: DataPreparationOrigin
) => {
  return !!ids
    ? `${getOriginUrl(origin)(
        projectId,
        OP.GET_BY_ID
      )}?id=${ids}&resolution=${size}:${size}`
    : null;
};

// Get image url by filename
const getDatasetImageByName = (
  projectId: string,
  filenames: string[],
  size: number,
  origin: DataPreparationOrigin
) => {
  const joinedFilenames = filenames.map(x => x.replace(',', '%252C')).join(',');
  return `${getOriginUrl(origin)(
    projectId,
    OP.GET_BY_FILE_NAMES
  )}?filenames=${joinedFilenames}&resolution=${size}:${size}`;
};

const columns = [
  {id: 'image', label: '', sortable: false},
  {id: 'filename', label: 'File Name', sortable: true},
  {id: 'width', label: 'Dimensions', sortable: false},
  {id: 'size', label: 'File Size', sortable: false},
] as const;

const comparators: {[key in SortId]?: (item1: FileItem, item2: FileItem) => number} = {
  filename: (item1: FileItem, item2: FileItem) => item1.name.localeCompare(item2.name),
  size: (item1: FileItem, item2: FileItem) => item1.size - item2.size,
  lastEdited: (item1: FileItem, item2: FileItem) =>
    moment(item1.lastModified).diff(moment(item2.lastModified)),
  width: (item1: FileItem, item2: FileItem) =>
    item1.width && item2.width ? item1.width - item2.width : 0,
};
type SortId = DatasetListRequest['sort'];
type SortDir = DatasetListRequest['sortDir'];

type ReducerState = {
  page: number;
  sort: SortId;
  sortDir: SortDir;
  label: string | null;
};

const initialState: ReducerState = {
  page: 1,
  sort: 'label',
  sortDir: 'asc',
  label: null,
};
const reverseSort = (sortDir: SortDir) => (sortDir === 'asc' ? 'desc' : 'asc');

const viewerSlice = createSlice({
  name: 'file/viewer',
  initialState,
  reducers: {
    setLabel(state, action: PayloadAction<string | null>) {
      state.label = action.payload;
      state.page = 1;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    toggleSortHeading(state, action: PayloadAction<SortId>) {
      if (action.payload === state.sort) {
        state.sortDir = reverseSort(state.sortDir);
      } else {
        state.sort = action.payload;
      }
      state.page = 1;
    },
  },
});

export const FILE_IMAGE_TYPES = [
  '.gif',
  '.png',
  '.bmp',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.pjpeg',
  '.pjp',
  '.webp',
];

export type FileStyle = {
  color: string;
  background: string;
};

export type ViewId = 'grid' | 'list' | 'label';
export type FileItem = {
  file?: File;
  id: string;
  name: string;
  size: number;
  selected: boolean;
  lastModified: Date;
  label?: string;
  height?: number;
  width?: number;
  style?: FileStyle;
};

export type FileViewerProps = {
  projectId: string;
  items: FileItem[];
  view: ViewId;
  origin: DataPreparationOrigin;
  onSelect: (id: string) => void;
  renderAdornment?: (
    config: Pick<FileItem, 'selected' | 'label'> & {isLocal?: boolean; style?: FileStyle},
    view: ViewId
  ) => React.ReactNode;
  onPageChange?: (page: number, ids: string[]) => void;
};

const {setLabel, setPage, toggleSortHeading} = viewerSlice.actions;

export const FileViewer = ({
  projectId,
  items,
  view,
  origin,
  onSelect,
  renderAdornment,
  onPageChange,
}: FileViewerProps) => {
  const [state, dispatch] = React.useReducer(viewerSlice.reducer, initialState);
  const {page, sort, sortDir, label} = state;
  const isLabelSet = label !== null;
  const isLabelView = view === 'label' && !isLabelSet;

  useEffect(() => {
    dispatch(setPage(1));
    dispatch(setLabel(null));
  }, [view]);

  const itemsWithSelectedLabel = [...items].filter(item =>
    isLabelSet ? (label ? item.label === label : !item.label) : true
  );

  const pageSize = isLabelView
    ? LABEL_PAGE_SIZE
    : view === 'list'
    ? LIST_PAGE_SIZE
    : GRID_PAGE_SIZE;

  const visibleItems = itemsWithSelectedLabel
    .sort((a, b) => {
      const direction = sortDir === 'asc' ? 1 : -1;
      const comparator = comparators[sort] || (() => 0);
      return direction * comparator(a, b);
    })
    .slice((page - 1) * pageSize, page * pageSize);

  const idsToFetch = visibleItems.filter(item => !item.file).map(item => item.id);
  const {data: imageDimensions} = useSWRImmutable<ImageDimensionMap>(
    getDatasetImageCacheUrl(
      projectId,
      idsToFetch.join(),
      view === 'list' ? THUMBNAIL_SIZE_LIST : THUMBNAIL_SIZE_GRID,
      origin
    ),
    async url => {
      const {headers} = await axios.get(url, {responseType: 'blob'});
      const xSizesHeader = headers?.['x-sizes'];
      let imageDetails: {w: number; h: number; s: number}[] = JSON.parse(xSizesHeader);
      return idsToFetch.reduce<ImageDimensionMap>((acc, curr, i) => {
        acc[curr] = {
          height: imageDetails[i].h,
          width: imageDetails[i].w,
          size: imageDetails[i].s,
        };
        return acc;
      }, {});
    }
  );

  const formatFileItem = (item: FileItem) => ({
    filename: item.name,
    size: imageDimensions?.[item.id]?.size ?? item.size,
    lastEdited: new Date(item.lastModified).toString(),
    label: label || item.label || undefined,
    id: item.id,
    file: item.file,
    selected: item.selected,
    height: imageDimensions?.[item.id]?.height ?? item.height,
    width: imageDimensions?.[item.id]?.width ?? item.width,
  });

  const getGroups = () => {
    const groupsMap: {[key: string]: FileItem[]} = {};

    items.forEach(item => {
      const itemLabel = item.label || '';
      if (!groupsMap[itemLabel]) {
        groupsMap[itemLabel] = [];
      }
      groupsMap[itemLabel].push(item);
    });

    return Object.entries(groupsMap).map(([label, items]) => ({
      label,
      count: items.length,
      files: items.map(formatFileItem),
    }));
  };

  const groups = getGroups();

  const pageCount = Math.max(
    1,
    Math.ceil((isLabelView ? groups.length : itemsWithSelectedLabel.length) / pageSize)
  );

  const visibleGroups = [...groups]
    .sort((a, b) => a.label.localeCompare(b.label))
    .slice((page - 1) * pageSize, page * pageSize);

  const renderViewer = () => {
    const ids = visibleItems.filter(item => !item.file).map(item => item.id);
    const url = getDatasetImageCacheUrl(
      projectId,
      ids.join(),
      view === 'list' ? THUMBNAIL_SIZE_LIST : THUMBNAIL_SIZE_GRID,
      origin
    );
    onPageChange && onPageChange(page, ids);

    if ((isLabelView && !visibleGroups.length) || !visibleItems.length) {
      return <div className="file-viewer__empty">No images found</div>;
    } else if (view === 'grid' || isLabelSet) {
      return (
        <div>
          {isLabelSet && (
            <>
              <Button
                color="primary"
                startIcon={<ArrowLeftIcon />}
                onClick={() => dispatch(setLabel(null))}
              >
                All labels
              </Button>
              <DataViewerLabel
                label={label || ''}
                count={itemsWithSelectedLabel.length}
              />
            </>
          )}
          <GridViewer>
            {visibleItems.map((item, i) => (
              <GridViewerItem key={i}>
                <DataViewerItem
                  adornment={renderAdornment?.(
                    {
                      selected: item.selected,
                      label: item.label,
                      isLocal: !!item.file,
                      style: item.style,
                    },
                    view
                  )}
                  onClick={() => onSelect(item.id)}
                  url={item.file || url}
                  position={i}
                  height={THUMBNAIL_SIZE_GRID}
                  width={THUMBNAIL_SIZE_GRID}
                  selectable={!!item.file}
                />
              </GridViewerItem>
            ))}
          </GridViewer>
        </div>
      );
    } else if (view === 'list') {
      return (
        <ListViewer
          columns={columns}
          items={visibleItems.map(formatFileItem)}
          onSort={id => id !== 'image' && dispatch(toggleSortHeading(id))}
          sort={sort}
          sortDir={sortDir}
        >
          {(item: ItemType, pos: number) => (
            <>
              <ListViewerTooltipThumbnail
                url={item?.file || url}
                position={pos}
                onClick={onSelect}
                renderAdornment={item => {
                  return renderAdornment?.(
                    {
                      selected: Boolean(item.selected),
                      label: item.label,
                      isLocal: !!item.file,
                    },
                    view
                  );
                }}
              />
              <ListViewerFilename onClick={onSelect} />
              <ListViewerDimensions />
              <ListViewerFilesize />
            </>
          )}
        </ListViewer>
      );
    } else if (isLabelView) {
      return (
        <LabelViewer
          labels={visibleGroups}
          labelNameMapper={x => x}
          limit={LABEL_FILE_COUNT}
          onLabelClick={label => dispatch(setLabel(label))}
        >
          {(label, item, ids, pos) => {
            const url = getDatasetImageByName(
              projectId,
              ids,
              THUMBNAIL_SIZE_GRID,
              origin
            );
            return (
              <DataViewerItem
                adornment={renderAdornment?.(
                  {
                    selected: Boolean(item.selected),
                    label: item.label,
                    isLocal: !!item.file,
                  },
                  view
                )}
                onClick={() => onSelect(item.id + '')}
                url={item.file || url}
                height={THUMBNAIL_SIZE_GRID}
                width={THUMBNAIL_SIZE_GRID}
                position={pos}
                selectable={!!item.file}
              />
            );
          }}
        </LabelViewer>
      );
    }
  };

  return (
    <div className="file-viewer">
      <div className="file-viewer__viewer">{renderViewer()}</div>
      {pageCount > 1 && (
        <PageNumbers
          page={page}
          pageCount={pageCount}
          onChange={page => dispatch(setPage(page))}
        />
      )}
    </div>
  );
};

const PageNumbers = (props: {
  onChange(page: number): void;
  page: number;
  pageCount: number;
}) => (
  <div className="file-viewer__pagination-container">
    <Pagination
      count={props.pageCount}
      page={props.page}
      shape="rounded"
      className="file-viewer__pagination"
      onChange={(_, p) => props.onChange(p)}
    />
  </div>
);

const ListViewerTooltipThumbnail = ({
  onClick,
  renderAdornment,
  url,
  position,
}: {
  onClick: (id: string) => void;
  renderAdornment?: (item: ItemType) => React.ReactNode;
  url: string | File | undefined | null;
  position: number;
}) => {
  const {item} = useListViewerRow();
  return (
    <ListViewerItem>
      <ListViewerThumbnail
        url={url}
        position={position}
        onClick={() => onClick(item.id as string)}
        adornment={renderAdornment?.(item)}
      />
    </ListViewerItem>
  );
};

export const ImageAdornment = ({
  isLocal,
  selected,
  label,
  style,
}: {
  isLocal?: boolean;
  selected?: boolean;
  label?: string;
  style?: FileStyle;
}) => (
  <div>
    {selected ? (
      <Check className="image-adornment__base image-adornment__selected" />
    ) : (
      isLocal && (
        <CloudUploadIcon className="image-adornment__base image-adornment__isLocal" />
      )
    )}
    {label && (
      <StudioTooltip title={label}>
        <div className="image-adornment__label" style={style}>
          {label}
        </div>
      </StudioTooltip>
    )}
  </div>
);
