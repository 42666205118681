import React, {useState} from 'react';
import RoundButton from '../StudioButton/RoundButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {PropTypes} from 'prop-types';
import './StudioKebabMenu.scss';

export const StudioKebabMenu = ({items}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <RoundButton
        customClass="kebab-menu__btn "
        data-testid="kebab-menu-btn"
        id="kebab-menu-btn"
        buttonIcon="more"
        buttonRole="naked-light"
        size="medium"
        onClick={handleClick}
        type="button"
      />
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        {items.map(({title, onClick, disabled}, index) => (
          <MenuItem
            className="kebab-menu__item"
            data-testid="kebab-menu-item"
            id={`kebab-menu-item_${index}`}
            onClick={event => {
              onClick(event);
              handleClose();
            }}
            key={title}
            disabled={disabled}
          >
            {title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

StudioKebabMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    })
  ),
};

StudioKebabMenu.defaultProps = {
  items: [],
};
