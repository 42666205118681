import {ErrorMessage, useField} from 'formik';
import React from 'react';
import {StudioSelect} from '../../base-components/StudioSelect';
import {hasError} from './hasError';

export const StudioFormikSelect = ({name, SelectProps, ...rest}) => {
  const [field, meta] = useField(name);

  return (
    <StudioSelect
      error={hasError(meta)}
      helperText={<ErrorMessage name={name} />}
      data-testid={name}
      id={name}
      SelectProps={{
        ...SelectProps,
        ...field,
      }}
      {...rest}
    />
  );
};
