import cn from 'classnames';
import * as React from 'react';
import {DataViewerLabel} from './DataViewerLabel';
import './GridViewer.scss';

export type GridViewerProps = {
  children: React.ReactNode;
  className?: string;
  count?: number;
  label?: string;
};

export const GridViewer = ({children, className, count, label}: GridViewerProps) => (
  <div className={cn('grid-viewer', className)}>
    {label && count && <DataViewerLabel label={label} count={count} />}
    <div className="grid-viewer__images">{children}</div>
  </div>
);
