import cn from 'classnames';
import {FieldHookConfig, useField} from 'formik';
import React, {forwardRef, useRef, useEffect} from 'react';
import {TextField, TextFieldProps} from '@material-ui/core';

import {StudioPopover} from '../StudioPopover';

import './StudioTextField.scss';

export type StudioTextFieldProps = TextFieldProps & {
  tooltip?: string;
  className?: string;
  fixedTooltip?: boolean;
  'data-testid'?: string;
  useFieldProps?: boolean;
  strategy?: 'studio' | 'formik';
};

const FormikStrategyTextField = ({
  placeholder,
  useFieldProps = true,
  ...props
}: StudioTextFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [field, meta] = useField(props as FieldHookConfig<any>);

  const errorText = meta.error && meta.touched ? meta.error : '';

  useEffect(() => {
    delete field?.value;
  }, [field]);

  let mainField = {};

  if (useFieldProps) {
    mainField = field;
  } else {
    mainField = {name: props.name, onChange: props.onChange, onBlur: props.onBlur};
  }

  return (
    <>
      <TextField
        inputRef={inputRef}
        placeholder={placeholder}
        {...mainField}
        helperText={errorText}
        error={!!errorText}
        minRows={props.multiline ? 10 : undefined}
        {...props}
      />
      {props.inputProps?.maxLength && (
        <div>
          <span className="studio-text-field__field-size">
            {(inputRef.current?.value || (props.value as string))?.length || 0}/
            {props.inputProps.maxLength}
          </span>
        </div>
      )}
    </>
  );
};

const StudioStrategyTextField = forwardRef<HTMLDivElement, StudioTextFieldProps>(
  (
    {
      className,
      variant = 'filled',
      InputLabelProps,
      InputProps,
      tooltip,
      fixedTooltip = false,
      ...rest
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
      <div
        className={cn(
          className,
          'studio-text-field__row',
          `studio-text-field__${variant}`
        )}
      >
        <div className="studio-text-field__container">
          <TextField
            ref={ref}
            inputRef={inputRef}
            className="studio-text-field"
            data-testid="studio-text-field"
            id={rest.id || rest.name || rest['data-testid'] || 'studio-text-field'}
            InputLabelProps={{
              shrink: true,
              ...InputLabelProps,
            }}
            InputProps={{
              disableUnderline: variant !== 'outlined' ? true : undefined,
              ...InputProps,
            }}
            minRows={rest.multiline ? 10 : undefined}
            {...rest}
          />
          {rest.inputProps?.maxLength && (
            <div>
              <span className="studio-text-field__field-size">
                {inputRef.current?.value?.length || 0}/{rest.inputProps.maxLength}
              </span>
            </div>
          )}
        </div>

        {(tooltip || fixedTooltip) && (
          <StudioPopover
            infoIconClass={cn(
              'studio-text-field__tooltip',
              fixedTooltip && !tooltip && 'studio-text-field__tooltip--hidden'
            )}
            infoMessage={tooltip}
            ttPlacement="top"
          />
        )}
      </div>
    );
  }
);

export const StudioTextField = function TextFieldStrategySelector({
  strategy,
  ...props
}: StudioTextFieldProps) {
  switch (strategy) {
    case 'formik':
      return <FormikStrategyTextField {...props} />;
    default:
      return <StudioStrategyTextField {...props} />;
  }
};
