import React from 'react';
import {PropTypes} from 'prop-types';
import cn from 'classnames';
import {getFormControlConfig} from './DynamicForm.helpers';
import {PromptProps} from '../../model/promptPropTypes';
import {DynamicFormToFormikAdapter} from './DynamicFormToFormikAdapter';
import {Form, Formik} from 'formik';
import {FormattedMessage} from 'react-intl';

import './DynamicForm.scss';

// logical container to allow for easy recursion
export const DynamicFormBody = ({prompts, level}) => (
  <>
    {prompts &&
      prompts.map(prompt =>
        getFormControlConfig(prompt.type, level).getComponent({
          ...prompt,
          className: 'dynamic-form__control_' + (level >= 2 ? 'max' : level),
        })
      )}
  </>
);

export const DynamicForm = ({
  title,
  prompts,
  className,
  onSubmit,
  onBlur,
  defaults,
  buttonPlacement,
  renderButtons,
}) => {
  return (
    <DynamicFormToFormikAdapter prompts={prompts} defaults={defaults} onSubmit={onSubmit}>
      {(prompts, initialValues, initialErrors, onSubmit) => (
        <Formik
          enableReinitialize
          validateOnMount={Object.values(initialErrors).length === 0}
          initialValues={initialValues}
          initialErrors={initialErrors}
          onSubmit={onSubmit}
        >
          {({isSubmitting, isValid, dirty, values}) => (
            <Form
              noValidate
              className={cn('dynamic-form', className)}
              onBlur={() => {
                if (onBlur) {
                  onBlur(values);
                }
              }}
            >
              {buttonPlacement === 'top' &&
                (!renderButtons
                  ? null
                  : renderButtons(isSubmitting, isValid, dirty, values))}
              {title && (
                <h2 className="dynamic-form__title">
                  <FormattedMessage id={title} />
                </h2>
              )}
              <div className="dynamic-form__body">
                <DynamicFormBody prompts={prompts} level={0} />
              </div>
              {buttonPlacement === 'bottom' &&
                (!renderButtons
                  ? null
                  : renderButtons(isSubmitting, isValid, dirty, values))}
            </Form>
          )}
        </Formik>
      )}
    </DynamicFormToFormikAdapter>
  );
};

DynamicForm.propTypes = {
  title: PropTypes.string,
  prompts: PromptProps,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  defaults: PropTypes.object,
  buttonPlacement: PropTypes.string,
  renderButtons: PropTypes.func,
};

DynamicForm.defaultProps = {
  prompts: [],
  defaults: {},
  className: null,
  onSubmit: () => {},
  onBlur: () => {},
  buttonPlacement: 'bottom',
  renderButtons: () => {},
};
