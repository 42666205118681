import {Dialog, Divider, Grid} from '@material-ui/core';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {IntegrationPurpose} from '../../../types/integrations/IntegrationResponse';
import {MarketplaceResult} from '../../../types/marketplace/MarketplaceFilesResponse';
import {metadataMap} from '../metadataMap';
import './MarketplaceDetailsDialog.scss';

const getProperties = (item: MarketplaceResult, purpose: IntegrationPurpose) => {
  const propertiesMap = {
    DATASET_STORAGE: {
      preview: 'datasetPreview',
      sections: [
        [
          'description',
          'purpose',
          'provider',
          'creationDateTime',
          'studioVersion',
          'tags',
        ],
        [
          'version',
          'size',
          'colorSpace',
          'dataLoader',
          'classCount',
          'dataSplit',
          'labels',
        ],
      ],
    },

    MODEL_STORAGE: {
      preview: 'modelPreview',
      sections: [
        [
          'description',
          'purpose',
          'provider',
          'creationDateTime',
          'studioVersion',
          'tags',
        ],
        [
          'version',
          'size',
          'type',
          'modelTL',
          'modelSource',
          'baseModel',
          'yoloType',
          'modelHelperFile',
        ],
        [
          'modelCompatibleDataset',
          'modelCompatibleDatasetImages',
          'modelClassCount',
          'modelCompatibleDatasetTags',
          'modelCompatibleDatasetSplit',
        ],
      ],
    },
    ISP_STORAGE: {
      preview: 'ispPreview',
      sections: [
        [
          'description',
          'purpose',
          'provider',
          'creationDateTime',
          'studioVersion',
          'tags',
        ],
        [
          'transformationType',
          'applicationType',
          'executionContainer',
          'version',
          'size',
          'inputColorSpace',
          'outputColorSpace',
          'ispModules',
        ],
      ],
    },
    PP_STORAGE: {
      preview: 'ppPreview',
      sections: [
        [
          'description',
          'purpose',
          'provider',
          'creationDateTime',
          'studioVersion',
          'tags',
        ],
        ['postProcessorType', 'executionContainer', 'applicationType', 'version', 'size'],
      ],
    },
    SOLUTION_STORAGE: {
      preview: item.metadata.dataSet ? 'datasetPreview' : 'solutionWithoutDatasetPreview',
      sections: [
        [
          'description',
          'solutionPurpose',
          'categoryName',
          'provider',
          'creationDateTime',
          'version',
          'studioVersion',
          'size',
          'tags',
        ],
        [
          'solutionData',
          'solutionDataTags',
          'solutionDataClassCount',
          'solutionDataSplit',
        ],
        [
          'solutionModel',
          'solutionModelTags',
          'solutionModelSource',
          'solutionModelType',
          'solutionModelBase',
          'solutionYoloType',
          item.metadata.model?.modelPurpose === 'Detection'
            ? 'solutionModelMAP'
            : 'solutionModelAccuracy',
        ],
      ],
    },
  };

  return propertiesMap[purpose];
};

type MarketplaceDetailsDialogProps = {
  isOpen: boolean;
  purpose: IntegrationPurpose;
  item?: MarketplaceResult | null;
  submitLabel: string;
  onClose: () => void;
  onSubmit: (item: MarketplaceResult) => void;
};

export const MarketplaceDetailsDialog = ({
  isOpen,
  item,
  purpose,
  submitLabel,
  onClose,
  onSubmit,
}: MarketplaceDetailsDialogProps) => {
  const intl = useIntl();

  const isPropertyNonEmpty = (property: string) =>
    item && metadataMap[property]?.render(item) != null;

  const isSectionNonEmpty = (section: string[]) => section.length > 0;

  const properties = item ? getProperties(item, purpose) : null;

  const filteredSections = properties
    ? properties.sections
        .map(section => section.filter(isPropertyNonEmpty))
        .filter(isSectionNonEmpty)
    : [];

  const renderSection = (section: string[], key: string) => {
    return (
      item && (
        <dl key={key}>
          {section.map(property => {
            const definition = metadataMap[property];
            const output = definition?.render(item);
            return (
              <div key={property} className="marketplace-details__metadata">
                <dt>
                  <FormattedMessage id={definition.label} />
                </dt>
                <dd>{output}</dd>
              </div>
            );
          })}
        </dl>
      )
    );
  };

  const renderMainSection = (section: string[]) => {
    const leftColumnItems = section.slice(0, 3);
    const baseItems = section.slice(3);

    return (
      <Grid container direction="column">
        <Grid item container wrap="wrap-reverse">
          <Grid item xs={12} md={7} className="marketplace-details__column">
            {renderSection(leftColumnItems, 'mainleft')}
          </Grid>
          <Grid item xs={5} className="marketplace-details__preview">
            {item && properties?.preview && metadataMap[properties.preview].render(item)}
          </Grid>
        </Grid>
        <Grid item>{renderSection(baseItems, 'mainbottom')}</Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={isOpen}
      BackdropProps={{
        invisible: true,
      }}
      className="marketplace-details"
      onClose={onClose}
      maxWidth={false}
    >
      <h2 className="marketplace-details__title">{item?.displayName}</h2>
      <div className="marketplace-details__body">
        {filteredSections.length > 0 && renderMainSection(filteredSections[0])}
        {filteredSections.length > 1 &&
          filteredSections.slice(1).map((section, index) => (
            <React.Fragment key={`details-section-${index}`}>
              <Divider />
              {renderSection(section, index.toString())}
            </React.Fragment>
          ))}
      </div>

      <div className="marketplace-details__actions">
        <FormButton
          buttonRole="secondary"
          value={intl.formatMessage({id: 'form.close'})}
          onClick={() => onClose()}
          type="button"
        />
        <FormButton
          buttonRole="primary"
          value={submitLabel}
          type="submit"
          onClick={() => item && onSubmit(item)}
        />
      </div>
    </Dialog>
  );
};
