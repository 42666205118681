import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import {Divider, Toolbar} from '@material-ui/core';
import {NearMe} from '@material-ui/icons';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import {remove, sortedUniq} from 'lodash';
import React, {useContext, useMemo, useState} from 'react';
import ReactResizeDetector from 'react-resize-detector';
import Annotator, {
  STAGE_HEIGHT_ADJ,
  ToolType,
} from '../../../base-components/StudioAnnotator/Annotator';
import {MenuListLocation} from '../../../base-components/StudioAnnotator/MenuList';
import {
  AnnotatorContext,
  AnnotatorProvider,
} from '../../../base-components/StudioAnnotator/Provider';
import {
  BoxLabelProps,
  LineLabelProps,
  PolyLabelProps,
} from '../../../base-components/StudioAnnotator/types';
import LabelMenu from './LabelMenu';
import './TestRegionSelection.scss';
import {StudioContentLayout} from '../../../base-components/ StudioContentLayout';
import {ImageType} from '../../../types/dataset/DatasetGetAnnotationsResponse';
import LineIcon from '../../../assets/icons/LineIcon';
// TODO: Use this icon for
// import RectangleIcon from '../../../assets/icons/RectangleIcon';
import PolygonIcon from '../../../assets/icons/PolygonIcon';
import {FormattedMessage} from 'react-intl';

export const ZOOM_PAD_HORIZONTAL = 5;
export const ZOOM_PAD_VERTICAL = 5;

export type Regions = {
  lines?: LineLabelProps[];
  boxes?: BoxLabelProps[];
  polygons?: PolyLabelProps[];
};

export type TestRegionSelectionProps = {
  ids: string[];
  hideMissingFields?: boolean;
  source?: () => React.ReactNode;
  status?: string;
  onChange: (regions: Regions) => void;
  regions: Regions;
};

export interface LabelInterface {
  id: string;
  label?: string;
}

const getScaleFactor = (from: number, to: number) => to / from;

export function TestRegionSelection({
  ids,
  hideMissingFields,
  source,
  status,
  regions,
  onChange = () => {},
}: TestRegionSelectionProps) {
  // export function TestRegionSelection() {
  const [labelMenuOpen, setlabelMenuOpen] = useState<boolean>(false);
  const [labelMenuLoc, setlabelMenuLoc] = useState<MenuListLocation>({x: 0, y: 0});
  const [tool, setTool] = useState<ToolType>('SELECT');
  const [selections, setSelections] = useState<string[]>([]);
  const [image, setImage] = useState<ImageType | undefined>();
  const [boxes, setBoxes] = useState<BoxLabelProps[]>(regions?.boxes || []);
  const [polys, setPolys] = useState<PolyLabelProps[]>(regions?.polygons || []);
  const [lines, setLines] = useState<LineLabelProps[]>(regions?.lines || []);
  let oldLabels: string[] = [];
  oldLabels = oldLabels.concat(lines.map(x => x?.label || ''));
  oldLabels = oldLabels.concat(polys.map(x => x?.label || ''));
  oldLabels = oldLabels.concat(boxes.map(x => x?.label || ''));
  const [category, setCategory] = useState<string[]>(oldLabels.filter(x => x !== ''));
  const {
    // scale,
    imageLayer,
    setImageLayer,
    // scaleToFitFactor,
    // setScaleToFitFactor,
  } = useContext(AnnotatorContext);

  // temp just to bypass checks- remove later
  false && setImage(undefined);

  const onSectionCreated = (newRegion: BoxLabelProps) => setBoxes([...boxes, newRegion]);
  const onPolyRegionCreated = (poly: PolyLabelProps) => setPolys([...polys, poly]);
  const onLineLabelCreated = (line: LineLabelProps) => setLines([...lines, line]);

  useMemo(() => {
    onChange({
      lines,
      polygons: polys,
      boxes,
    });
  }, [boxes, polys, lines, onChange]);

  const onSectionDelete = (id: string, type?: ToolType) => {
    switch (type) {
      case 'LINE':
        setLines(lines.filter(e => e.id !== id));
        break;
      case 'POLY':
        setPolys(polys.filter(e => e.id !== id));
        break;
      case 'RECT':
        setBoxes(boxes.filter(e => e.id !== id));
        break;
      default:
        return undefined;
    }
  };

  const onSelect = (selectedIds: string[]) => setSelections([...selectedIds]);

  const onContextMenu = (e: PointerEvent) => {
    setlabelMenuOpen(true);
    setlabelMenuLoc({x: e.x, y: e.y});
  };

  const onSectionChange = (target: BoxLabelProps, value: Partial<BoxLabelProps>) =>
    setBoxes(boxes.map(el => (el.id === target.id ? {...el, ...value} : el)));

  const updateItemLabel = (
    label: string,
    arr: any[],
    item?: LineLabelProps | PolyLabelProps | BoxLabelProps
  ) => {
    if (item && arr.length) {
      const isoArr = remove(arr, el => el.id !== item.id);
      return [...isoArr, {...item, label}];
    }
    return [...arr];
  };

  const onCategorySelect = (val: string) => {
    setCategory(sortedUniq([...category, val]));
    setlabelMenuOpen(false);
    if (selections.length) {
      const selection = selections[0];
      const elType: string = selection.charAt(0);
      switch (elType) {
        case 'L':
          setLines(
            updateItemLabel(
              val,
              lines,
              lines.find(el => el.id === selection)
            )
          );
          break;
        case 'R':
          setBoxes(
            updateItemLabel(
              val,
              boxes,
              boxes.find(el => el.id === selection)
            )
          );
          break;
        case 'P':
          setPolys(
            updateItemLabel(
              val,
              polys,
              polys.find(el => el.id === selection)
            )
          );
          break;
        default: {
        }
      }
    }
  };

  const reset = () => {
    setLines([]);
    setBoxes([]);
    setPolys([]);
    setCategory([]);
  };
  return (
    <StudioContentLayout
      className="test-region-selection__container"
      header={
        <Toolbar variant="dense" className="test-region-selection__toolbar">
          <h5 className="add-streaming-device__preview-title">
            <FormattedMessage id="stream.preview" />
          </h5>
          {status && <FormattedMessage id={`stream.status.${status}`} />}
          <div className="toolbar_spacer" />
          <IconButton onClick={reset} size="small" aria-label="clear all">
            <DeleteIcon />
          </IconButton>

          <Divider orientation="vertical" flexItem />
          <ToggleButtonGroup
            value={tool}
            exclusive
            onChange={(_, value) => setTool(value)}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton value="SELECT" aria-label="select">
              <NearMe />
            </ToggleButton>
            <ToggleButton value="LINE" aria-label="draw line">
              <LineIcon />
            </ToggleButton>
            {/* Disableing Rectangle tool since the migration from 1.6 to 1.5.2 code base has that
            messed up. I did not have time to fix it since I prioritize integration. However, polygon can be used to draw a rectangle. */}
            {/* <ToggleButton value="RECT" aria-label="draw rectangle" disabled>
              <RectangleIcon />
            </ToggleButton> */}
            <ToggleButton value="POLY" aria-label="draw polygon">
              <PolygonIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Toolbar>
      }
    >
      {({width, contentHeight}) => (
        <ReactResizeDetector handleWidth handleHeight>
          {({width = 0, height = 0}) => {
            const getScale = () => {
              const scaleX = getScaleFactor(
                image?.width || 0,
                width - ZOOM_PAD_HORIZONTAL
              );
              const scaleY = getScaleFactor(
                image?.height || 0,
                height - ZOOM_PAD_VERTICAL
              );
              return Math.min(scaleX, scaleY);
            };
            const scaleFactor = getScale();
            imageLayer.scale !== scaleFactor &&
              setImageLayer({
                scale: scaleFactor,
                x: (width - width * scaleFactor) / 2,
                y: (height - height * scaleFactor) / 2,
              });

            return (
              <AnnotatorProvider>
                <>
                  {source && source()}
                  <Annotator
                    width={width}
                    height={height + STAGE_HEIGHT_ADJ}
                    boxLabels={boxes}
                    polyLabels={polys}
                    lineLabels={lines}
                    selectedLabels={selections}
                    onBoxLabelCreated={onSectionCreated}
                    onPolyLabelCreated={onPolyRegionCreated}
                    onLineLabelCreated={onLineLabelCreated}
                    onSelect={onSelect}
                    onChange={onSectionChange}
                    onDelete={onSectionDelete}
                    zoomEnable={false}
                    fullToolset={true}
                    tool={tool}
                    onContextMenu={onContextMenu}
                  />
                  <LabelMenu
                    open={labelMenuOpen}
                    onSelect={onCategorySelect}
                    onClose={() => setlabelMenuOpen(false)}
                    location={labelMenuLoc}
                    items={category}
                  />
                </>
              </AnnotatorProvider>
            );
          }}
        </ReactResizeDetector>
      )}
    </StudioContentLayout>
  );
}
