import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router';
import useSWR from 'swr';
import {updatePostProcessor} from '../../../api/postprocessor/UpdatePostProcessor';
import {UpdatePostProcessorRequest} from '../../../types/postprocessor/UpdatePostProcessorRequest';
import {toast} from '../../../base-components/StudioToast';
import {useIntl} from 'react-intl';
import {PostProcessingForm} from './PostProcessingForm';
import {
  getPostProcessorURL,
  getPostProcessor,
} from '../../../api/postprocessor/GetPostProcessor';
import {getPostProcessorFile} from '../../../api/postprocessor/ViewPostProcessorFile';
import {
  assignPostProcessor,
  ASSIGN_POST_PROCESSOR_URL,
} from '../../../api/postprocessor/AssignPostProcessor';

type ReturnToOptions = 'spaCreate' | 'spaDetails' | undefined;

export const EditPostProcessor = () => {
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const [files, setFiles] = useState<Record<string, string>>({});
  const returnTo = search.get('returnTo') as ReturnToOptions;
  const assignToApplication = search.has('assignToApplication');
  const viewOnly = search.has('viewOnly');
  const applicationId = search.get('applicationId');
  const exposeAPI = search.get('exposeAPI');
  const exposeInference = search.get('exposeInference');
  const {projectId, postProcessorId} = useParams<{
    projectId: string;
    postProcessorId: string;
  }>();
  const getPostProcessorKey = getPostProcessorURL(projectId, postProcessorId);
  const {data: getPostProcessorResult} = useSWR(getPostProcessorKey, getPostProcessor, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    getFiles();

    async function getFiles() {
      const filePaths = getPostProcessorResult?.body?.filePaths ?? [];
      const allFiles: Record<string, string> = {};

      for (const fileName of filePaths) {
        const postProcessorType = getPostProcessorResult?.body?.postProcessorType;
        const executionContainer = getPostProcessorResult?.body?.executionContainer;
        const viewScriptParams = {
          projectId,
          applicationId: postProcessorId,
          fileName,
          postProcessorType,
          executionContainer,
        };

        if (!viewScriptParams) {
          continue;
        }

        const {data: viewPostProcessorFileResult} = await getPostProcessorFile(
          viewScriptParams
        );
        const text = await viewPostProcessorFileResult.text();

        allFiles[fileName] = text;
      }

      setFiles(allFiles);
    }
  }, [getPostProcessorResult, projectId, postProcessorId]);

  const intl = useIntl();
  const history = useHistory();

  const returnToPreviousPage = () => {
    if (returnTo === 'spaCreate' && applicationId) {
      history.push(
        `/project/${projectId}/inference/${applicationId}/create?exposeAPI=${exposeAPI}&exposeInference=${exposeInference}`
      );
    } else if (returnTo === 'spaDetails' && applicationId) {
      history.push(`/project/${projectId}/inference/${applicationId}/details`);
    } else {
      history.push(`/project/${projectId}/upload`);
    }
  };

  const handleSubmit = async (values: UpdatePostProcessorRequest) => {
    try {
      const resp = await updatePostProcessor(values);
      if (!resp.errors?.length) {
        if (assignToApplication && applicationId) {
          await assignPostProcessor([
            ASSIGN_POST_PROCESSOR_URL,
            {projectId, applicationId, postProcessorId},
          ]);
        }
        toast.success(intl.formatMessage({id: 'editPostProcessor.success'}));
        returnToPreviousPage();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getReturnText = () => {
    switch (returnTo) {
      case 'spaCreate':
        return intl.formatMessage({id: 'form.returnToConfigure'});
      case 'spaDetails':
        return intl.formatMessage({id: 'form.returnToDetails'});
      default:
        return undefined;
    }
  };

  return (
    <PostProcessingForm
      title={intl.formatMessage({id: 'editPostProcessor.header'})}
      returnText={getReturnText()}
      viewOnly={viewOnly}
      projectId={projectId}
      template={getPostProcessorResult?.body}
      filePaths={files}
      onSubmit={handleSubmit}
      onCancel={returnToPreviousPage}
    />
  );
};
