import useSWR from 'swr';
import QueryString from 'qs';

import {assertResponseBody} from '../request';
import {DeploymentQuery} from '../../types/deployment/DeploymentQuery';
import {TestDeploymentQueryResponse} from '../../types/deployment/TestDeploymentQueryResponse';

import URL from '../../config/url';
import Axios from 'axios';
import {Response} from '../../types/response/Response';

export type TestListRequest = DeploymentQuery;

export type TestListResponse = TestDeploymentQueryResponse;

export async function postTestList(body: TestListRequest) {
  const {data} = await Axios.request<Response<TestListResponse>>({
    method: 'post',
    url: URL.DEPLOYMENT_TEST_LIST,
    data: body,
  });
  return data;
}

export function useTestList(data: TestListRequest, avoidFetch: boolean = false) {
  const shouldFetch = !avoidFetch && data.projectId;

  return useSWR(
    shouldFetch ? [URL.DEPLOYMENT_TEST_LIST, QueryString.stringify(data)] : null,
    () => assertResponseBody(postTestList(data))
  );
}

type TestListByLatestSettings = {
  search?: string;
  projectId?: string;
  avoidFetch?: boolean;
};

export function useTestListByLatest({
  search,
  projectId,
  avoidFetch,
}: TestListByLatestSettings) {
  const params: DeploymentQuery['params'] = {};

  if (search !== '') {
    params.name = search;
  }

  params.status = [
    'IDLE',
    'COPYING_DATA',
    'DEPLOYING',
    'STARTING',
    'RUNNING',
    'STOPPING',
    'ERROR',
  ];

  return useTestList(
    {
      projectId,
      orderDefinitions: [
        {
          field: 'creationTime',
          direction: 'DESC',
        },
      ],
      params,
    },
    avoidFetch
  );
}
