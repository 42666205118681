import {Button, IconButton} from '@material-ui/core';
import {Field, FieldProps, Form, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import * as Yup from 'yup';
import {StudioPopover} from '../../../base-components/StudioPopover';
import {StudioSelect} from '../../../base-components/StudioSelect';
import {StudioTextField} from '../../../base-components/StudioTextField';
import {PreProcessorNewResponse} from '../../../api/preprocessor/PreProcessorNew';
import {PreProcessorUpdateRequest} from '../../../api/preprocessor/PreProcessorUpdate';
import {getPreProcessorFile} from '../../../api/preprocessor/PreProcessorView';
import {PreProcessor} from '../../../types/preprocessor/PreProcessor';
import {UpdatePreProcessorRequest} from '../../../types/preprocessor/UpdatePreProcessorRequest';
import {
  parameterArrayValidation,
  ParameterFieldArray,
} from '../Fields/ParameterFieldArray';
import {ScriptUploadField} from '../Fields/ScriptUploadField';
import '../index.scss';
import {GetApp as GetAppIcon} from '@material-ui/icons';
import JSZip from 'jszip';
import {StudioTooltip} from '../../../base-components/StudioTooltip';
import {PRE_AND_POST_PROCESSORS_SETTINGS} from '../../../config/constants';

const defaultTemplate: PreProcessorNewResponse = {
  id: '',
  name: '',
  description: '',
  projectId: '',
  filePaths: [],
  transformationType: 'SCRIPT',
  script: {
    parameters: [],
  },
  executionContainerOptions: {},
  applicationTypeOptions: {},
};

const validationSchema = Yup.object({
  id: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  projectId: Yup.string().required('Required'),
  filePaths: Yup.object()
    .test('file-exists', 'Required', files => Boolean(files && Object.keys(files).length))
    .test('file-not-empty', 'File is empty', files =>
      Boolean(!files || Object.values(files)?.[0])
    ),
  script: Yup.object({
    executionContainer: Yup.string().required('Required'),
    applicationType: Yup.string().required('Required'),
    parameters: parameterArrayValidation,
  }),
});

type PreProcessingFormProps = {
  title: string;
  projectId: string;
  returnText?: string;
  viewOnly?: boolean;
  template?: PreProcessorNewResponse;
  filePaths?: Record<string, string>;
  onSubmit: (values: PreProcessorUpdateRequest) => Promise<void>;
  onCancel: () => void;
};

const DEFAULT_FILE_PATHS = {};

export const PreProcessingForm = ({
  title,
  projectId,
  returnText,
  viewOnly,
  template,
  filePaths = DEFAULT_FILE_PATHS,
  onSubmit,
  onCancel,
}: PreProcessingFormProps) => {
  const intl = useIntl();
  const [scripts, setScripts] = useState<File[]>([]);

  const {
    id,
    name,
    executionContainerOptions,
    applicationTypeOptions,
    script,
    description,
    transformations,
  } = template || defaultTemplate;

  const initialValues: UpdatePreProcessorRequest = {
    id,
    name,
    projectId,
    description,
    transformationType: 'SCRIPT',
    script,
    transformations,
    filePaths,
  };

  useEffect(() => {
    getFiles();

    async function getFiles() {
      const allFiles = [];

      for (const [key] of Object.entries(filePaths)) {
        const content = await getPreProcessorFile(projectId, id, key);
        allFiles.push(new File([content.data], key));
      }

      setScripts(allFiles);
    }
  }, [filePaths, projectId, id]);

  async function downloadAllFiles() {
    const archive = new JSZip();

    for (let file of scripts) {
      const fileContent = file.slice(0, file.size, file.type);
      await archive.file(file.name, fileContent);
    }
    const zipBlob = await archive.generateAsync({type: 'blob'});
    const fileName = 'pre-processor.zip';
    const url = URL.createObjectURL(
      new File([zipBlob], fileName, {type: 'application/zip'})
    );
    const a = document.createElement('a');
    a.style.display = 'none';
    a.setAttribute('download', fileName);
    a.setAttribute('href', url);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({values, isSubmitting}) => (
        <div className="processing-form">
          <header>
            <Button
              className="processing-form__back"
              variant="contained"
              disableElevation
              onClick={onCancel}
            >
              {returnText ??
                intl.formatMessage({
                  id: 'form.returnToStage',
                })}
            </Button>
            <div>
              <StudioTooltip
                title={intl.formatMessage({
                  id: 'project.preprocessor.downloadFile',
                })}
              >
                <IconButton onClick={downloadAllFiles}>
                  <GetAppIcon />
                </IconButton>
              </StudioTooltip>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                form="pre-processor-form"
                disabled={isSubmitting || viewOnly}
                disableElevation
              >
                {intl.formatMessage({
                  id: isSubmitting ? 'form.submitting' : 'form.submit',
                })}
              </Button>
            </div>
          </header>
          <div className="processing-form__main">
            <h2>{title}</h2>
            <Form id="pre-processor-form" noValidate>
              <div className="processing-form__form-top">
                <Field name="name">
                  {({field, meta}: FieldProps<PreProcessor['name']>) => (
                    <div>
                      <StudioTextField
                        className="processing-form__field"
                        label={
                          <React.Fragment>
                            {intl.formatMessage({id: 'sharedProcessor.form.name'})}
                            <StudioPopover
                              infoMessage={intl.formatMessage({
                                id: 'editPreProcessor.name.tooltip',
                              })}
                            />
                          </React.Fragment>
                        }
                        placeholder={intl.formatMessage({
                          id: 'sharedProcessor.form.name.placeholder',
                        })}
                        InputProps={field}
                        error={Boolean(meta.error && meta.touched)}
                        helperText={meta.error && meta.touched ? meta.error : ''}
                        disabled={viewOnly}
                        inputProps={{
                          maxLength: PRE_AND_POST_PROCESSORS_SETTINGS.name.maxLength,
                        }}
                      />
                    </div>
                  )}
                </Field>
                <Field name="description">
                  {({field}: FieldProps<PreProcessor['description']>) => (
                    <StudioTextField
                      className="processing-form__field"
                      label={
                        <React.Fragment>
                          {intl.formatMessage({id: 'sharedProcessor.form.description'})}
                          <StudioPopover
                            infoMessage={intl.formatMessage({
                              id: 'editPreProcessor.description.tooltip',
                            })}
                          />
                        </React.Fragment>
                      }
                      placeholder={intl.formatMessage({
                        id: 'sharedProcessor.form.description.placeholder',
                      })}
                      InputProps={field}
                      disabled={viewOnly}
                      multiline={PRE_AND_POST_PROCESSORS_SETTINGS.description.multiline}
                      inputProps={{
                        className: PRE_AND_POST_PROCESSORS_SETTINGS.description.className,
                        maxLength: PRE_AND_POST_PROCESSORS_SETTINGS.description.maxLength,
                      }}
                    />
                  )}
                </Field>
                <Field name="script.applicationType">
                  {({
                    field,
                    meta,
                  }: FieldProps<PreProcessor['script']['applicationType']>) => (
                    <StudioSelect
                      className="processing-form__field"
                      label={intl.formatMessage({id: 'sharedProcessor.form.appType'})}
                      additionalLabel={
                        <StudioPopover
                          infoMessage={intl.formatMessage({
                            id: 'editPreProcessor.applicationType.tooltip',
                          })}
                        />
                      }
                      placeholder={intl.formatMessage({
                        id: 'sharedProcessor.form.appType.placeholder',
                      })}
                      options={applicationTypeOptions}
                      disablePlaceholder
                      SelectProps={{...field, value: field.value || ''}}
                      error={Boolean(meta.error && meta.touched)}
                      helperText={meta.error && meta.touched ? meta.error : ''}
                      disabled={viewOnly}
                    />
                  )}
                </Field>
                <Field name="script.executionContainer">
                  {({
                    field,
                    meta,
                  }: FieldProps<PreProcessor['script']['executionContainer']>) => (
                    <StudioSelect
                      className="processing-form__field"
                      label={intl.formatMessage({
                        id: 'sharedProcessor.form.target',
                      })}
                      additionalLabel={
                        <StudioPopover
                          infoMessage={intl.formatMessage({
                            id: 'editPreProcessor.executionContainer.tooltip',
                          })}
                        />
                      }
                      placeholder={intl.formatMessage({
                        id: 'sharedProcessor.form.target.placeholder',
                      })}
                      options={executionContainerOptions}
                      SelectProps={{
                        ...field,
                        value: field.value || '',
                        onChange: e => {
                          field.onChange(e);
                        },
                      }}
                      disablePlaceholder
                      error={Boolean(meta.error && meta.touched)}
                      helperText={meta.error && meta.touched ? meta.error : ''}
                      disabled={viewOnly}
                    />
                  )}
                </Field>
                <Field name="filePaths">
                  {({
                    field,
                    form,
                    meta,
                  }: FieldProps<PreProcessorUpdateRequest['filePaths']>) => {
                    const handleFileUpload = async (files: File[]) => {
                      const newFilePaths: Record<string, string> = {};

                      for (const file of files) {
                        newFilePaths[file.name] = await file.text();
                      }

                      form.setFieldValue('filePaths', newFilePaths);
                      form.setFieldTouched('filePaths');
                      form.validateField('filePaths');
                      setScripts(files);
                    };

                    const executionContainer = values.script.executionContainer;

                    return executionContainer ? (
                      <ScriptUploadField
                        label={intl.formatMessage({id: 'sharedProcessor.form.script'})}
                        tooltip={intl.formatMessage({
                          id: 'editPreProcessor.filePaths.tooltip',
                        })}
                        className="processing-form__field"
                        filePaths={scripts}
                        onChange={handleFileUpload}
                        errorText={meta.error && meta.touched ? meta.error : ''}
                        disabled={viewOnly}
                      />
                    ) : null;
                  }}
                </Field>
              </div>
              <hr />
              <h5>{intl.formatMessage({id: 'preProcessor.params.header'})}</h5>
              <ParameterFieldArray
                name="script.parameters"
                parameters={values.script.parameters || []}
                disabled={viewOnly}
              />
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};
