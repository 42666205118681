import React from 'react';
import BeforeImage1 from './images/demosaic-before.png';
import AfterImage from './images/demosaic-after.png';
import './Help.scss';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>Demosaic</h4>
      <div className="flow-graph__help_content_detail">
        <p>In a single sensor digital camera, the light intensity from the object is focused on a sensor array to capture the image through a Color Filter Array (CFA). Bayer CFA is the widely used CFA since it can preserve maximum details and resembles with Human Visual System(HVS). The 50% of Bayer CFA is engaged by green pixels since the HVS is more sensitive to luminance than chrominance. So the output of a single sensor camera (mosaic image) consists of 50% green details, 25% red details and 25% blue details. CFA acts as a coating on the sensor array that allows the photons of one-color component and blocks the photons of other two-color components</p>
        <p>A Demosaicing algorithm is a digital image process used to reproduce the complete color image from  the partial color image data, received from a Single-Sensor Camera. To reproduce the color image, the two missing colors on each pixel location is to be interpolated back by the help of available pixel values. It is also known as Color Filter Array Interpolation (CFA Interpolation).</p>
        <p>There are many different approaches to Demosaicing. One of the famous algorithms used are Nearest Neighbor Interpolation, Bilinear Interpolation, Malvar2004 etc.</p>
        <p>Demosaicing is implemented using Bilinear Interpolation. It is a simple method in which missing samples are interpolated on each plane independently. For each block of 2x2 pixels, the algorithm tries to fill in the “missing” pixels. In the case of Red and Blue channels, there are three “missing” pixels per block. As such, there are three different calculations, one for each “missing” pixel. This method is computationally very simple and very easy to implement.</p>
        <div className="help_images">
          <figure>
            <img src={BeforeImage1} alt="before" />
            <figcaption>Input</figcaption>
          </figure>
          <figure>
            <img src={AfterImage} alt="after" />
            <figcaption>Output</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};
