import React from 'react';
import cn from 'classnames';
import {GSPNodeStatus} from '../../../types/deployment/GSPConfigurationInfo';
import './DeploymentStatus.scss';

type DeviceStatusProps = {
  status: GSPNodeStatus;
};

export const DeviceStatus = ({status}: DeviceStatusProps) => {
  return (
    <span className="deploy-status">
      <span
        className={cn(
          'deploy-status__bulb',
          status === 'Offline' && 'deploy-status__bulb--stopping'
        )}
      />
      {status}
    </span>
  );
};
