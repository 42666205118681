/* eslint-disable react/style-prop-object */

import React, {useCallback, useState} from 'react';
import {Button} from '@material-ui/core';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory, useLocation, useParams} from 'react-router';
import {StudioSearch} from '../../../base-components/StudioSearch';
import {debounce} from 'lodash';
import {StudioTab, StudioTabPanel, StudioTabs} from '../../../base-components/StudioTab';
import {DeploymentsTable} from './DeploymentsTable';
import {InferenceAppsTable} from './InferenceAppsTable';
import {GSPConfigurationsTable} from './GSPConfigurationsTable';
import {InputSource} from '../../../types/deployment/InferenceInputSource';
import './ProdDashboard.scss';

const tabs = [
  {
    name: 'deployments',
    Component: DeploymentsTable,
  },
  {
    name: 'inference',
    Component: InferenceAppsTable,
  },
  {
    name: 'gsps',
    Component: GSPConfigurationsTable,
  },
];

export const sourceToTextMap: Record<'IMAGES' | InputSource, string> = {
  IMAGES: 'prodDeployment.images',
  WEB_CAMERA: 'prodDeployment.webCam',
  IP_CAMERA: 'prodDeployment.ipCam',
  FILE: 'prodDeployment.file',
  DEVICE_CAMERA: 'prodDeployment.onDevice',
  APP_INPUT: 'prodDeployment.onDevice',
  TEXT_INPUT: 'prodDeployment.chat',
};

export const ProdDashboard = () => {
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const {projectId} = useParams<{projectId: string}>();
  const isProjectContext = Boolean(projectId);

  const query = new URLSearchParams(location.search);
  const tabName = query.get('tab') || 'deployments';
  const tabIndex = tabs.findIndex(tab => tab.name === tabName);

  // When the user types in the search bar, we want to debounce the API call
  // but the not debounce the update to the search bar itself. Hence we store the
  // two separately
  const [search, setSearch] = useState('');
  const [searchForAPICall, setSearchForAPICall] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearch = useCallback(
    debounce((search: string) => {
      setSearchForAPICall(search);
    }, 500),
    []
  );

  const handleUpdateSearch = (search: string) => {
    setSearch(search);
    debouncedSetSearch(search);
  };

  return (
    <div className="prod-deploy-dashboard" data-testid="prod-deploy-dashboard">
      <div className="prod-deploy-dashboard__menu">
        {!isProjectContext && (
          <Button
            className="prod-deploy-dashboard__back"
            variant="contained"
            disableElevation
            onClick={() => history.push('/dashboard')}
          >
            {<FormattedMessage id={'prodDeployment.returnToDashboard'} />}
          </Button>
        )}
      </div>
      <div className="prod-deploy-dashboard__main">
        <div className="prod-deploy-dashboard__menu">
          <StudioTabs
            className="prod-deploy-dashboard__tabs"
            level="primary"
            value={tabIndex}
            onChange={(event, index) => {
              history.push({search: `?tab=${tabs[index].name}`});
            }}
          >
            <StudioTab label="Deployments" />
            <StudioTab label="Inference API + Single Page Apps" />
            <StudioTab label="Target Devices" />
          </StudioTabs>
          {tabs[tabIndex].name === 'deployments' && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                history.push(
                  isProjectContext
                    ? `/project/${projectId}/deployments/new`
                    : '/deployments/new'
                )
              }
            >
              <FormattedMessage id="createDeploy.create" />
            </Button>
          )}
          <StudioSearch
            className="prod-deploy-dashboard__search"
            placeholder={intl.formatMessage({id: 'prodDeployment.search'})}
            onChange={e => {
              handleUpdateSearch(e.target.value);
            }}
            value={search}
          />
        </div>
        {tabs.map(({Component}, index) => (
          <StudioTabPanel value={tabIndex} index={index} key={index}>
            <Component search={searchForAPICall} />
          </StudioTabPanel>
        ))}
      </div>
    </div>
  );
};
