import React, {RefObject} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {IntegrationPurpose} from '../../../types/integrations/IntegrationResponse';
import {actions} from '../../../store/marketplace/add-data';
import {DataUploadActionBase} from '../../StageView/StageActions/DataUploadAction';
import {ImportDialog} from './ImportDialog';
import {SortableColumn} from '../../../types/marketplace/MarketplaceFilesRequest';

export type AddDataDialogProps = {
  actionRef?: RefObject<InstanceType<typeof DataUploadActionBase>>;
};

const PURPOSE: IntegrationPurpose = 'DATASET_STORAGE';
const columns: SortableColumn[] = [
  'displayName',
  'provider',
  'creationDateTime',
  'colorSpace',
  'dataLoader',
  'purpose',
  'size',
  'studioVersion',
];

const {setIsOpen} = actions;

export const AddDataDialog = ({actionRef}: AddDataDialogProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.addDataDialog.isOpen);
  const initialMarketplaceState = useSelector(
    (state: RootState) => state.addDataDialog.initialMarketplaceState
  );

  return (
    <ImportDialog
      isOpen={isOpen}
      title="Add Data"
      purpose={PURPOSE}
      columns={columns}
      onClose={isSubmit => dispatch(setIsOpen({isOpen: false, isCancelled: !isSubmit}))}
      onSubmit={(item, integration) =>
        actionRef?.current?.dataUpload(integration?.id, {
          name: item.name,
          type: item.type,
        })
      }
      onUploadSubmit={(files, totalSize) => actionRef?.current?.addData(files, totalSize)}
      initialState={initialMarketplaceState}
      defaultFilterValues={{compatibleOnly: true}}
    />
  );
};
