import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {isEmpty} from 'lodash';
import {StudioTooltip} from '../../../base-components/StudioTooltip';

const updateValue = (moduleParam, value) => (moduleParam.param_value = value);

const FormInputString = ({data}) => {
  return (
    <StudioTooltip title={data.param_description} placement="left">
      <TextField
        value={data.param_value}
        defaultValue={data.param_value_default}
        label={data.param_display_name}
        variant="filled"
        onChange={e => updateValue(data, e.target.value)}
      />
    </StudioTooltip>
  );
};

const FormInputNumber = ({data}) => {
  return (
    <StudioTooltip title={data.param_description} placement="left">
      <TextField
        value={data.param_value}
        defaultValue={data.param_value_default * 1}
        label={data.param_display_name}
        type="number"
        variant="filled"
        onChange={e => updateValue(data, e.target.value * 1)}
      />
    </StudioTooltip>
  );
};

const FormInputEnum = ({data}) => {
  return (
    <StudioTooltip title={data.param_description} placement="left">
      <Autocomplete
        options={data.param_value_list}
        getOptionLabel={option => option}
        autoHighlight
        autoSelect
        openOnFocus
        onInputChange={(e, val) => updateValue(data, val)}
        defaultValue={data.param_value_default}
        value={data.param_value}
        renderInput={params => (
          <TextField {...params} label={data.param_display_name} variant="filled" />
        )}
      />
    </StudioTooltip>
  );
};

function getInput(data) {
  switch (data.param_value_type) {
    case 'CATEGORICAL':
    case 'string':
      return isEmpty(data.param_value_list) ? (
        <FormInputString data={data} />
      ) : (
        <FormInputEnum data={data} />
      );
    default:
      return <FormInputNumber data={data} />;
  }
}

export const FormInput = ({data}) => {
  return data.map((input, i) => <div key={i}>{getInput(input)}</div>);
};

FormInput.propTypes = {
  data: [PropTypes.any],
};

FormInput.defaultProps = {
  data: [],
};
