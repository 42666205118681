import React, {Component} from 'react';
import PropTypes from 'prop-types';

class DeleteIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="12px"
          height="15px"
          viewBox="0 0 12 15"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <path
              d="M6.98046875,18.3203125 L6.98046875,8.3203125 L16.9804688,8.3203125 L16.9804688,18.3203125 C16.9804688,18.7890648 16.81771,19.1861963 16.4921875,19.5117188 C16.166665,19.8372412 15.7825543,20 15.3398438,20 L8.66015625,20 C8.19140391,20 7.79427246,19.8372412 7.46875,19.5117188 C7.14322754,19.1861963 6.98046875,18.7890648 6.98046875,18.3203125 Z M17.8398438,5.8203125 L17.8398438,7.5 L6.16015625,7.5 L6.16015625,5.8203125 L9.08984375,5.8203125 L9.91015625,5 L14.0898438,5 L14.9101562,5.8203125 L17.8398438,5.8203125 Z"
              id="delete-icon"
            ></path>
          </defs>
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-850.000000, -6444.000000)"
            >
              <g id="Model-Action-Icons" transform="translate(56.000000, 6308.000000)">
                <g id="row" transform="translate(0.000000, 93.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="model-actions-/-delete-/-Enabled,-Hover,-Focus"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#delete-icon"></use>
                      </mask>
                      <use id="delete" fill="currentColor" xlinkHref="#delete-icon"></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

DeleteIcon.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  id: PropTypes.string,
};

export default DeleteIcon;
