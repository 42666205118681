import {useState} from 'react';
import useSWRInfinite from 'swr/infinite';
import {ProjectListRequest} from '../../types/project/ProjectListRequest';
import {ProjectListResponse} from '../../types/project/ProjectListResponse';
import URL from '../../config/url';
import axios from 'axios';

export const useProjectList = (
  pageSize: number,
  searchQuery: ProjectListRequest['searchQuery'],
  sortBy: ProjectListRequest['sortBy'],
  sortDir: ProjectListRequest['sortDirection']
) => {
  const [totalCount, setTotalCount] = useState(0);

  function getKey(pageIndex: number, previousPageData: ProjectListResponse | null) {
    const startFrom = pageSize * pageIndex;
    const totalCount = previousPageData?.body.totalCount;

    if (totalCount && startFrom >= totalCount) {
      return null;
    }

    return URL.PROJECT_LIST({
      pageSize: pageSize,
      searchQuery,
      sortBy,
      sortDirection: sortDir,
      startFrom,
    });
  }

  async function fetcher(url: string) {
    const {data} = await axios.get(url);

    if (data.body.totalCount) {
      setTotalCount(data.body.totalCount);
    }

    return data;
  }

  const responses = useSWRInfinite<ProjectListResponse>(getKey, fetcher);
  const {data, mutate, size, setSize, isValidating} = responses;
  const loadMore = async () => {
    const size = data?.length || 0;
    await setSize(size + 1);
  };

  return {
    totalCount,
    data,
    mutate,
    size,
    setSize,
    isValidating,
    loadMore,
  };
};
