import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import './PopUpMenu.scss';

import {
  MenuItemProps,
  CascadeItemProps,
  ActionSegmentProps,
} from '../../model/menuItemsPropTypes';
import RoundButton from '../StudioButton/RoundButton';
import FormButton from '../StudioButton/FormButton';
import SubheaderButton from '../StudioButton/SubheaderButton';
import CheckIcon from '../../assets/icons/CheckIcon';
import MenuCascadeIcon from '../../assets/icons/MenuCascadeIcon';
import AddIcon from '../../assets/icons/AddIcon';
import ChevronRightIcon from '../../assets/icons/ChevronRightIcon';

class PopUpMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
    this.toggleList = this.toggleList.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.instanceOfCascade = this.instanceOfCascade.bind(this);
    this.determineAlignment = this.determineAlignment.bind(this);
  }

  toggleList() {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen,
    }));
  }

  onItemClick(id, itemAction, e) {
    e.stopPropagation();
    this.toggleList();
    if (itemAction) {
      itemAction();
    }
    if (this.props.selectItem) {
      this.props.selectItem(id);
    }
  }

  instanceOfCascade(object) {
    return 'children' in object;
  }

  handleClickOutside() {
    this.setState({menuOpen: false});
  }

  determineAlignment(id) {
    let alignment = '';
    let launcher = id ? document.getElementById(id) : null;
    let launcherCoordinates = launcher ? launcher.getBoundingClientRect() : null;
    let winWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    var winHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    if (
      this.props.alignRight ||
      (launcherCoordinates && launcherCoordinates.left + 312 > winWidth)
    ) {
      alignment += 'pum-align-right';
    }
    if (
      this.props.alignBottom ||
      (launcherCoordinates &&
        launcherCoordinates.bottom + 16 + 48 * this.props.list.length > winHeight)
    ) {
      alignment += ' pum-align-bottom';
    }
    return alignment;
  }

  render() {
    const {
      list,
      selected,
      actionSegment,
      scrollHeight,
      launcher,
      alignRight,
      customClass,
      id,
    } = this.props;
    const {menuOpen} = this.state;
    const specialty = !!list[0].helpingInfo;
    return (
      <div
        className={`pum-container ${customClass ? customClass : ''}`}
        id={id}
        data-testid="popup-menu"
      >
        {this.getLauncher(launcher)}
        {menuOpen && (
          <ul
            className={`pum-list ${
              launcher === 'icon' ? 'pum-from-icon' : 'pum-from-text'
            } ${this.determineAlignment(id)}`}
          >
            <div
              style={scrollHeight ? {maxHeight: scrollHeight, overflowY: 'scroll'} : {}}
            >
              {list.map((item, index) => {
                if (this.instanceOfCascade(item)) {
                  return (
                    <li
                      className={`pum-list-item pum-cascade-launcher ${
                        specialty ? 'specialty' : ''
                      } ${item.disabled ? 'disabled' : ''}`}
                      key={item.id}
                    >
                      <div className="pum-list-item-name">{item.title}</div>
                      {specialty && (
                        <div className="pum-list-helping-info">{item.helpingInfo}</div>
                      )}
                      <div className="pum-icon-container">
                        <MenuCascadeIcon />
                      </div>
                      {index !== 0 && (
                        <div className="pum-list-item-border">
                          <div />
                        </div>
                      )}
                      <ul
                        className={`pum-cascade-list ${
                          alignRight ? 'pum-align-right' : ''
                        }`}
                      >
                        {item.children.map((child, index) => (
                          <li
                            className={`pum-list-item ${
                              selected && selected.id === child.id ? 'selected' : ''
                            } ${specialty ? 'specialty' : ''} ${
                              item.disabled ? 'disabled' : ''
                            }`}
                            key={child.id}
                            onClick={
                              child.disabled
                                ? () => null
                                : child.action
                                ? e => this.onItemClick(child.id, child.action, e)
                                : () => this.onItemClick(child.id)
                            }
                          >
                            <div className="pum-list-item-name">{child.title}</div>
                            {selected && selected.id === child.id && (
                              <div
                                className={`pum-icon-container ${
                                  specialty ? 'specialty' : ''
                                }`}
                              >
                                <CheckIcon />
                              </div>
                            )}
                            {specialty && (
                              <div className="pum-list-helping-info">
                                {child.helpingInfo}
                              </div>
                            )}
                            {index !== 0 && (
                              <div className="pum-list-item-border">
                                <div></div>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                } else {
                  return (
                    <li
                      className={`pum-list-item ${
                        selected && selected.id === item.id ? 'selected' : ''
                      } ${specialty ? 'specialty' : ''} ${
                        item.disabled ? 'disabled' : ''
                      }`}
                      key={item.id}
                      onClick={
                        item.disabled
                          ? () => null
                          : item.action
                          ? e => this.onItemClick(item.id, item.action, e)
                          : () => this.onItemClick(item.id)
                      }
                    >
                      <div className="pum-list-item-name">{item.title}</div>
                      {selected && selected.id === item.id && (
                        <div
                          className={`pum-icon-container ${specialty ? 'specialty' : ''}`}
                        >
                          <CheckIcon />
                        </div>
                      )}
                      {specialty && (
                        <div className="pum-list-helping-info">{item.helpingInfo}</div>
                      )}
                      {index !== 0 && (
                        <div className="pum-list-item-border">
                          <div></div>
                        </div>
                      )}
                    </li>
                  );
                }
              })}
            </div>
            {actionSegment && (
              <li
                className={`pum-action-segment pum-list-item ${
                  specialty ? 'specialty' : ''
                }`}
                onClick={evt => {
                  actionSegment.action(evt);
                  this.setState({menuOpen: false});
                }}
              >
                {actionSegment.title}
                {this.getIcon(actionSegment.icon)}
              </li>
            )}
          </ul>
        )}
      </div>
    );
  }

  getIcon(name) {
    const icons = {
      chevron: <ChevronRightIcon />,
      add: <AddIcon />,
    };
    return icons[name];
  }

  getLauncher(launcher) {
    const {menuOpen} = this.state;
    const {selected, placeholder} = this.props;
    const launcherTitle = selected
      ? selected.title
      : placeholder
      ? placeholder
      : 'pass a selected or placeholder prop';
    const handleClick = e => {
      e.stopPropagation();
      this.toggleList();
    };

    if (launcher === 'icon') {
      return (
        <RoundButton
          buttonIcon="more"
          buttonRole="naked-light"
          size="medium"
          customClass={`pum-launcher ${menuOpen ? 'open' : ''}`}
          onClick={handleClick}
        />
      );
    } else if (launcher === 'text') {
      return (
        <FormButton
          buttonRole="naked"
          value={launcherTitle}
          customClass={`pum-launcher ${menuOpen ? 'open' : ''}`}
          onClick={handleClick}
        />
      );
    } else if (launcher === 'subheader') {
      return (
        <SubheaderButton
          value={launcherTitle}
          customClass={`pum-launcher-bold ${menuOpen ? 'open' : ''}`}
          onClick={handleClick}
          buttonIcon={menuOpen ? 'chevronUp' : 'chevronDown'}
        />
      );
    }
  }
}

PopUpMenu.propTypes = {
  // vv REQUIRED vv
  list: PropTypes.arrayOf(PropTypes.oneOfType([MenuItemProps, CascadeItemProps]))
    .isRequired,
  launcher: PropTypes.oneOf(['icon', 'text', 'subheader']).isRequired,
  // vv optional extras vv
  id: PropTypes.string, //<<<---must pass a UNIQUE id to enable dynamic menu alignment
  selectItem: PropTypes.func,
  selected: MenuItemProps,
  placeholder: PropTypes.string,
  actionSegment: ActionSegmentProps,
  scrollHeight: PropTypes.string,
  alignRight: PropTypes.bool,
  alignBottom: PropTypes.bool,
  customClass: PropTypes.string,
  stagePanel: PropTypes.string,
};

PopUpMenu.defaultProps = {
  id: null,
  selectItem: id => null,
  selected: null,
  placeholder: null,
  actionSegment: null,
  scrollHeight: null,
  alignRight: false,
  alignBottom: false,
  customClass: null,
  stagePanel: null,
};

export default onClickOutside(PopUpMenu);
