import {updateParameters} from '../../../api/postprocessor/UpdateParameters';
import {TestInitiateRequest} from '../../../api/test/TestInitiate';
import {
  setONNXTask,
  resetAllTasks,
  resetInferenceTask,
  TASK_STATUS,
  useDeploy,
} from '../useDeploy';
import {useDeployTask} from './useDeployTask';
import {useInferenceTask} from './useInferenceTask';
import {useVideoStreamingTask} from './useVideoStreamingTask';
import {useTextTask} from './useTextTask';
import {useSelector} from 'react-redux';
import {getServerConfiguration} from './../../../store/config/selectors.js';

type DeployConfigState = ReturnType<typeof useDeploy>[0]['deployConfig'];

export const useTaskManager = ({projectId}: {projectId: string}) => {
  const [{streamingTask, onnxTask, chatConnection}, dispatch] = useDeploy();
  const {runDeploy, terminateDeploy} = useDeployTask(projectId);
  const [runInference] = useInferenceTask(projectId);
  const [runStreamingTask, stopStream] = useVideoStreamingTask(projectId);
  const serverConfig = useSelector(getServerConfiguration);
  const [runTextTask, stopTextTask] = useTextTask(projectId);

  const handleCancel = () => {
    if (onnxTask.status !== TASK_STATUS.IDLE) {
      dispatch(setONNXTask({status: TASK_STATUS.IDLE}));
    } else {
      if (streamingTask.status !== TASK_STATUS.IDLE) {
        stopStream();
      }
      if (chatConnection) {
        stopTextTask();
      }
      terminateDeploy();
    }
  };

  const handleSubmit = async (deployConfig: DeployConfigState, afterTest: Function) => {
    const {
      name,
      description,
      nodeId,
      deploymentId,
      files,
      testSource,
      outputTypes,
      inferenceSource,
      ipCamera,
      videoFile,
      isONNXRuntimeWeb,
      postProcessorParams,
      preProcessorParams,
      tiling,
      inferenceImageSetId,
      environment,
    } = deployConfig;
    dispatch(resetAllTasks());

    try {
      const parameters = [...postProcessorParams, ...preProcessorParams];
      if (parameters.length) {
        await updateParameters({
          projectId,
          applicationId: deploymentId,
          parameters,
        });
      }
    } catch (e) {
      console.error(e);
    }

    if (isONNXRuntimeWeb) {
      // For now, just set onnx model task status to in progress
      // In the future, we will have BE calls to make
      dispatch(
        setONNXTask({
          status: TASK_STATUS.IN_PROGRESS,
          id: Date.now().toString(),
          params: {
            deploymentId: deployConfig.deploymentId,
            files: deployConfig.files,
            inferenceSource: deployConfig.inferenceSource,
            runtimeMode: deployConfig.runtimeMode,
            videoFile: deployConfig.videoFile,
            ipCamera: deployConfig.ipCamera,
          },
        })
      );
    } else {
      if (
        testSource &&
        testSource !== 'USER_DATA' &&
        testSource !== 'IMAGE_SETS' &&
        testSource !== 'APP_INPUT' &&
        testSource !== 'TEXT_INPUT'
      ) {
        const modelStatistics = outputTypes.includes('modelStatistics');
        const sampleInference = outputTypes.includes('sampleInference');

        const deploymentType =
          modelStatistics && sampleInference
            ? 'VALIDATION_PLUS_INFERENCE'
            : modelStatistics
            ? 'VALIDATION'
            : sampleInference
            ? 'SAMPLE_INFERENCE'
            : 'INFERENCE';

        try {
          const deployRequest: TestInitiateRequest = {
            projectId,
            applicationId: deploymentId,
            nodeId,
            name,
            description,
            deploymentType,
            deploymentInput: {
              inputSourceType: 'IMAGES',
              splitType: testSource,
            },
            parameters: {
              tiling,
              environment,
            },
          };
          await runDeploy(deployRequest);
        } catch (e) {
          dispatch(resetAllTasks());
        }
      } else if (testSource === 'USER_DATA') {
        if (inferenceSource === 'IMAGES') {
          const deploymentType = 'INFERENCE';
          try {
            const deployRequest: TestInitiateRequest = {
              projectId,
              applicationId: deploymentId,
              nodeId,
              name,
              description,
              deploymentType,
              deploymentInput: {
                inputSourceType: 'IMAGES',
              },
              parameters: {
                tiling,
                environment,
              },
            };
            if (files.length) {
              const inferenceData: any = await runInference(deploymentId, files);
              deployRequest.inferenceDataId = inferenceData.id;
            }
            await runDeploy(deployRequest);
            dispatch(resetInferenceTask());
          } catch (e) {
            dispatch(resetAllTasks());
          }
        } else if (
          ['WEB_CAMERA', 'IP_CAMERA', 'FILE', 'APP_INPUT', 'TEXT_INPUT'].includes(
            inferenceSource
          )
        ) {
          runStreamingTask({
            projectId,
            applicationId: deploymentId,
            nodeId,
            name,
            description,
            deploymentType: 'INFERENCE',
            inferenceSource: inferenceSource || 'IP_CAMERA',
            videoFile,
            ipCamera,
            environment,
          });
        }
      } else if (testSource === 'IMAGE_SETS') {
        try {
          const deployRequest: TestInitiateRequest = {
            projectId,
            applicationId: deploymentId,
            nodeId,
            name,
            description,
            deploymentType: 'INFERENCE',
            deploymentInput: {
              inputSourceType: 'IMAGES',
            },
            parameters: {
              tiling,
              environment,
            },
            inferenceDataId: inferenceImageSetId,
          };
          await runDeploy(deployRequest);
          dispatch(resetInferenceTask());
        } catch (e) {
          dispatch(resetAllTasks());
        }
      } else if (testSource === 'APP_INPUT') {
        var src = inferenceSource === 'IMAGES' ? 'APP_INPUT' : inferenceSource;
        runStreamingTask({
          projectId,
          applicationId: deploymentId,
          nodeId,
          name,
          description,
          deploymentType: 'INFERENCE',
          inferenceSource: src || 'APP_INPUT',
          videoFile,
          ipCamera,
          environment,
        });
      } else if (testSource === 'TEXT_INPUT') {
        runTextTask({
          projectId,
          applicationId: deploymentId,
          nodeId,
          name,
          description,
          signalingServerUrl: serverConfig.videoServerUrl || '',
        });
      }
      afterTest();
    }
  };

  return {handleSubmit, handleCancel};
};
