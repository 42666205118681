import React from 'react';
import BeforeImage1 from './images/black_level_correction-before.png';
import AfterImage from './images/black_level_correction-after.png';
import './Help.scss';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>Black Level Correction</h4>
      <div className="flow-graph__help_content_detail">
        <p>Image black level is defined as the level of brightness at the darkest (black) part of a visual image or the level of brightness at which no light is emitted from a screen, resulting in a pure black screen.</p>
        <p>Image displays generally need to be calibrated so that the displayed black is true to the black information in the image signal. If the black level is not correctly adjusted, visual information in an image signal could be displayed as black, or black information could be displayed as above black information (gray).The voltage of the black level varies across different television standards. PAL sets the black level the same as the blanking level, while NTSC sets the black level approximately 54 mV above the blanking level.</p>
        <p>User mis adjustment of black level on monitors is common. It results in darker colors having their hue changed, it affects contrast, and in many cases causes some of the image detail to be lost. Black level is set by displaying a test card image and adjusting display controls.</p>
        <p>In digital image, black level usually means the range of RGB values in image signal, which can be either [0..255] (typical of a computer output).</p>
        
        <div className="help_images">
          <figure>
            <img src={BeforeImage1} alt="before" />
            <figcaption>Input</figcaption>
          </figure>
          <figure>
            <img src={AfterImage} alt="after" />
            <figcaption>Output</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};
