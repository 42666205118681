import React from 'react';
import BeforeImage1 from './images/color_correction-before.png';
import AfterImage from './images/color_correction-after.png';
import './Help.scss';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>Color Correction Matrix</h4>
      <div className="flow-graph__help_content_detail">
        <p>The color values obtained through the color filter array interpolation process are called native colors. Due to the spectral characteristics of the optics (lens, filters) used in the system, the lighting conditions (such as daylight, fluorescent, or tungsten) of the scene, and the characteristics of the color filters of the sensor, the native RGB data may not provide a faithful color rendition. For this reason, an additional step called color correction is required. In most cases, the native spectral RGB is first converted into a standard R’G’B’ (sRGB) color space by a 3x3 color correction matrix. sRGB is the standard color format for most digital imaging input and output devices. There can be different illuminant matrices for different color spaces. The two common illuminants for sRGB space are D65 (6500K) and D50 (5000K).</p>
        <p>*Color Temperatures: 6500Kelvin for daylight overcast and 5000K for horizon daylight. Color temperatures over 5000 K are called "cool colors" (bluish), while lower color temperatures (2700–3000 K) are called "warm colors" (yellowish).</p>
        <p>Generating the correct values for the 3x3 color matrix requires a great deal of knowledge in image science. The Spectral response of the CCD is different from the spectral response of an output device. In turn, the spectral response of the output device is different from the spectral response of the human eye. Therefore, “perfect” color reproduction is not possible, because we do not have the spectral information for the scene. Some companies will provide analytical matrix coefficients (aij, bij) to its customers given the characteristic of the optics and a certain lighting condition for a specific sensor. This matrix minimizes error for the range of hues of which the eye is most critical.</p>
        <div className="help_images">
          <figure>
            <img src={BeforeImage1} alt="before" />
            <figcaption>Input</figcaption>
          </figure>
          <figure>
            <img src={AfterImage} alt="after" />
            <figcaption>Output</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};
