import axios from 'axios';
import {toast} from '../../base-components/StudioToast';
import URL from '../../config/url';
import {
  getNextStartFrom,
  isProjectListReplacePending,
  isProjectListAppendPending,
  isProjectListAppendCancelled,
} from './selectors';

// ACTIONS
export const PROJECT_LIST_FETCH_REPLACE_PENDING = 'projectList/fetch-replace-pending';
export const PROJECT_LIST_FETCH_REPLACE_ERROR = 'projectList/fetch-replace-error';
export const PROJECT_LIST_FETCH_REPLACE_SUCCESS = 'projectList/fetch-replace-success';

export const PROJECT_LIST_FETCH_APPEND_PENDING = 'projectList/fetch-append-pending';
export const PROJECT_LIST_FETCH_APPEND_SUCCESS = 'projectList/fetch-append-success';
export const PROJECT_LIST_FETCH_APPEND_ERROR = 'projectList/fetch-append-error';
export const PROJECT_LIST_FETCH_APPEND_CANCEL = 'projectList/fetch-append-cancel';

const PAGE_SIZE = 15;

// REPLACE ACTION CREATORS
export const projectListFetchReplacePending = payload => ({
  type: PROJECT_LIST_FETCH_REPLACE_PENDING,
  payload,
});

export const projectListFetchReplaceSuccess = payload => ({
  type: PROJECT_LIST_FETCH_REPLACE_SUCCESS,
  payload,
});

export const projectListFetchReplaceError = payload => ({
  type: PROJECT_LIST_FETCH_REPLACE_ERROR,
  payload,
});

export const projectListFetchReplace = () => {
  return async (dispatch, getState) => {
    if (isProjectListAppendPending(getState())) {
      dispatch(projectListFetchAppendCancel());
    }

    dispatch(projectListFetchReplacePending());
    try {
      const {data} = await axios.get(URL.PROJECT_LIST(PAGE_SIZE, 0));
      dispatch(projectListFetchReplaceSuccess(data.body));
    } catch (error) {
      toast.error(`Failed to fetch project list`);
      dispatch(projectListFetchReplaceError(error));
    }
  };
};

// APPEND ACTION CREATORS
export const projectListFetchAppendPending = payload => ({
  type: PROJECT_LIST_FETCH_APPEND_PENDING,
  payload,
});

export const projectListFetchAppendSuccess = payload => ({
  type: PROJECT_LIST_FETCH_APPEND_SUCCESS,
  payload,
});

export const projectListFetchAppendError = payload => ({
  type: PROJECT_LIST_FETCH_APPEND_ERROR,
  payload,
});

export const projectListFetchAppendCancel = () => ({
  type: PROJECT_LIST_FETCH_APPEND_CANCEL,
});

export const projectListFetchAppend = () => {
  return async (dispatch, getState) => {
    if (isProjectListReplacePending(getState())) {
      return;
    }

    dispatch(projectListFetchAppendPending());
    const startFrom = getNextStartFrom(getState());
    try {
      const {data} = await axios.get(URL.PROJECT_LIST(PAGE_SIZE, startFrom));
      if (isProjectListAppendCancelled(getState())) {
        return;
      }
      dispatch(projectListFetchAppendSuccess(data.body));
    } catch (error) {
      toast.error(`Failed to fetch project list`);
      dispatch(projectListFetchAppendError(error));
    }
  };
};
