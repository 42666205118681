import React from 'react';
import './RuntimeStatistic.scss';

export type RuntimeStatisticProps = {
  title: string;
  value: string;
};

export function RuntimeStatistic({title, value}: RuntimeStatisticProps) {
  return (
    <div className="runtime-statistic" data-testid="runtime-statistic">
      <div className="runtime-statistic__title">{title}</div>
      <div className="runtime-statistic__value">{value}</div>
    </div>
  );
}
