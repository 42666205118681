import {
  Button,
  Dialog,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  IconButton,
  TableContainer,
} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import React, {useEffect, useState} from 'react';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {StudioSelect} from '../../../base-components/StudioSelect';
import {StudioTextField} from '../../../base-components/StudioTextField';
import './OptionsBuilderField.scss';

type OptionsBuilderFieldProps = {
  options?: Record<string, string>;
  onChange: (options: Record<string, string>) => void;
  errorText?: string;
  disabled?: boolean;
};

export const OptionsBuilderField = ({
  options,
  errorText,
  disabled,
  onChange,
}: OptionsBuilderFieldProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const hasOptions = options && Object.entries(options).length;
  return (
    <div>
      <div className="options-builder-field">
        {hasOptions && (
          <StudioSelect
            options={options}
            placeholder="View added options"
            disablePlaceholder
            SelectProps={{
              value: '',
            }}
            disabled={disabled}
          />
        )}
        <Button onClick={() => setIsDialogOpen(true)} disabled={disabled}>
          {hasOptions ? 'Edit' : 'Add options'}
        </Button>
      </div>
      <div className="options-builder-field__error">{errorText}</div>
      <OptionsBuilderDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        options={options}
        onSubmit={onChange}
      />
    </div>
  );
};

type OptionsBuilderDialogProps = {
  isOpen: boolean;
  options?: Record<string, string>;
  onSubmit: (options: Record<string, string>) => void;
  onClose: () => void;
};
const OptionsBuilderDialog = ({
  isOpen,
  onClose,
  options,
  onSubmit,
}: OptionsBuilderDialogProps) => {
  const [label, setLabel] = useState('');
  const [value, setValue] = useState('');
  const [updatedOptions, setUpdatedOptions] = useState(options);

  useEffect(() => {
    if (isOpen) {
      setUpdatedOptions(options);
    }
  }, [isOpen, options]);

  const handleAddOption: React.FormEventHandler = e => {
    e.preventDefault();
    if (label && value) {
      setUpdatedOptions(options => ({...options, [value]: label}));
      resetAddForm();
    }
  };

  const resetAddForm = () => {
    setLabel('');
    setValue('');
  };

  const handleDeleteOption = (value: string) => {
    setUpdatedOptions(options => {
      const newOptions = {...options};
      delete newOptions[value];
      return newOptions;
    });
  };
  const handleSubmit = () => {
    if (updatedOptions) {
      onSubmit(updatedOptions);
      resetAddForm();
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      BackdropProps={{
        invisible: true,
      }}
      onClose={onClose}
      className="options-builder-dialog"
    >
      <h2 className="options-builder-dialog__title">Define Options</h2>
      <div className="options-builder-dialog__body">
        <form onSubmit={handleAddOption} className="options-builder-dialog__add">
          <StudioTextField
            autoFocus
            InputProps={{
              placeholder: 'Option Label',
            }}
            value={label}
            onChange={e => setLabel(e.target.value)}
          />
          <StudioTextField
            InputProps={{
              placeholder: 'Option Value',
            }}
            value={value}
            onChange={e => setValue(e.target.value)}
          />
          <Button
            color="primary"
            disableElevation
            variant="contained"
            type="submit"
            disabled={!label || !value}
          >
            Add Option
          </Button>
        </form>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Label</TableCell>
                <TableCell>Value</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {updatedOptions &&
                Object.entries(updatedOptions).map(([value, label]) => (
                  <TableRow key={value}>
                    <TableCell>{label}</TableCell>
                    <TableCell>{value}</TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDeleteOption(value)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {(!updatedOptions || Object.entries(updatedOptions).length === 0) && (
            <div className="options-builder-dialog__empty">No options added</div>
          )}
        </TableContainer>
      </div>
      <div className="options-builder-dialog__actions">
        <FormButton
          buttonRole="secondary"
          value="Cancel"
          onClick={onClose}
          type="button"
        />
        <FormButton
          buttonRole="primary"
          value="Submit"
          type="submit"
          disabled={!updatedOptions || !Object.entries(updatedOptions).length}
          onClick={handleSubmit}
        />
      </div>
    </Dialog>
  );
};
