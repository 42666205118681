import {request} from '../request';

export type ImageSet = {
  id: string;
  name: string;
};

export type ImageSetsResponse = ImageSet[];

export async function getImageSets(projectId: string) {
  return request<ImageSetsResponse>({
    method: 'get',
    url: `/dataset/import/inference/list?projectId=${projectId}`,
  });
}
