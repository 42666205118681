import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import URL_CONST from '../../config/url';
import {DatasetDDAFinalizeRequest} from '../../types/dataset/DatasetDDAFinalizeRequest';
import {DatasetDDAFinalizeResponse} from '../../types/dataset/DatasetDDAFinalizeResponse';
import {DatasetDDAResponse} from '../../types/dataset/DatasetDDAResponse';
import {Defaults} from '../../types/prompts/Defaults';

export const prepareDDA = createAsyncThunk(
  'init-prepare-dda',
  async (args: {projectId: string; deploymentId: string; page?: number}) => {
    const {projectId, deploymentId, page} = args;
    const {data} = await axios.get<DatasetDDAResponse>(URL_CONST.DATASET_PREPARE_DDA, {
      params: {
        projectId,
        deploymentId,
        pageNumber: page,
      },
    });
    if (data.errors?.length) {
      throw new Error('Error preparing dataset DDA');
    }
    return data;
  }
);

export const prepareDDAFinalize = createAsyncThunk(
  'finalize-prepare-dda',
  async (args: DatasetDDAFinalizeRequest) => {
    const {data} = await axios.post<DatasetDDAFinalizeResponse>(
      URL_CONST.DATASET_PREPARE_DDA_FINALIZE,
      args
    );

    if (data.errors?.length) {
      throw new Error('Error finalizing dataset DDA');
    }
    return data.body;
  }
);

type RetrainState = {
  status: 'LOADING' | 'SUBMITTING' | 'IDLE';
  projectId?: string;
  deploymentId?: string;
  defaults: Defaults;
  prompts: any;
  page: number;
  totalPages: number;
};

const initialState: RetrainState = {
  status: 'LOADING',
  defaults: {},
  prompts: [],
  page: 0,
  totalPages: 0,
};

const retrainSlice = createSlice({
  name: 'deployment-retrain',
  initialState,
  reducers: {
    setRetrainData: (
      state,
      action: PayloadAction<{projectId?: string; deploymentId?: string}>
    ) => {
      state.projectId = action.payload.projectId;
      state.deploymentId = action.payload.deploymentId;
    },
    setPagination: (state, action: PayloadAction<{page: number}>) => {
      state.page = action.payload.page;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(prepareDDA.pending, state => {
        state.status = 'LOADING';
      })
      .addCase(prepareDDA.fulfilled, (state, action) => {
        state.status = 'IDLE';
        state.prompts = action.payload.prompts;
        state.defaults = action.payload.defaults;
        state.page = action.payload.body.page;
        state.totalPages = action.payload.body.totalPages;
      })
      .addCase(prepareDDA.rejected, state => {
        state.status = 'IDLE';
      })
      .addCase(prepareDDAFinalize.pending, state => {
        state.status = 'SUBMITTING';
      })
      .addCase(prepareDDAFinalize.fulfilled, state => {
        state.status = 'IDLE';
      })
      .addCase(prepareDDAFinalize.rejected, state => {
        state.status = 'IDLE';
      });
  },
});

export const {actions, reducer: retrainReducer} = retrainSlice;
