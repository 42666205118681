import React from 'react';
import {StudioTooltip} from '../../../base-components/StudioTooltip';
import {Badge} from '@material-ui/core';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import './PanelBadge.scss';

type PanelBadgeProps = {
  text: string;
};

export const PanelBadge = ({text}: PanelBadgeProps) => {
  return !text ? (
    <></>
  ) : (
    <StudioTooltip title={text} placement="top">
      <Badge
        color="secondary"
        className="panel-badge"
        badgeContent={<PriorityHighIcon />}
      ></Badge>
    </StudioTooltip>
  );
};
