import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getLocation, push} from 'connected-react-router';
import querystring from 'query-string';
import StageAnimation from '../StageAnimation/StageAnimation';
import Util from '../../../util';
import TaskInfoProps from '../../../model/taskInfoPropTypes';
import {toast} from '../../../base-components/StudioToast';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import {connect} from 'react-redux';
import {modelDelete} from '../../../store/model';
import {projectFetch} from '../../../store/project';
import {StageAssistantForm} from '../../StageAssistantForm';
import axios from 'axios';
import URL, {MODEL_OP, MODEL_SUB_OP} from '../../../config/url';
import {addListener, removeListener} from '../../../store/statusChecker';
import {viewDisableTermination, viewEnableTermination} from '../../../store/view';

class ImportModelAction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      taskProgress: props.taskProgress,
    };

    this.fileUpload = null;
    this.stageAnimation = new StageAnimation();
    this.requestImportFinalization = this.requestImportFinalization.bind(this);
    this.displayModelUploadProgress = this.displayModelUploadProgress.bind(this);
    this.importModelFromFiles = this.importModelFromFiles.bind(this);
    this.deleteModel = this.deleteModel.bind(this);
    this.terminateImport = this.terminateImport.bind(this);
    this.monitorStatus = this.monitorStatus.bind(this);
  }

  render() {
    return null;
  }

  componentDidMount() {
    if (this.props.location.search) {
      const query = querystring.parse(this.props.location.search);
      if (query.modelId && query.modelName && query.modelType) {
        this.props.setModelActionInProgress('import');
        this.importModel(query.modelId, {
          name: query.modelName,
          type: query.modelType,
        });
        this.props.navigate(this.props.location.pathname);
      }
    }

    if (this.props.taskProgress && this.props.taskProgress.type === 'IMPORT_MODEL') {
      this.props.setModelActionInProgress('import');
      this.stageAnimation.shiftStages('IMPORT_MODEL');
      this.monitorStatus();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.taskProgress?.type !== 'IMPORT_MODEL' &&
      this.props.taskProgress?.type === 'IMPORT_MODEL'
    ) {
      this.props.setModelActionInProgress('import');
      this.stageAnimation.shiftStages('IMPORT_MODEL');
      this.monitorStatus();
    }
  }

  componentWillUnmount() {
    this.props.removeStatusListener('IMPORT_MODEL');
  }

  monitorStatus() {
    this.props.viewEnableTermination();
    this.stageAnimation.markInProgress('IMPORT_MODEL');
    this.props.addStatusListener('IMPORT_MODEL', this.displayModelUploadProgress);
  }

  importModel(integrationId, selectedFile) {
    const cloudImportRequest = {
      projectId: this.props.projectId,
      integrationId: integrationId,
      files: [
        {
          fileName: selectedFile.name,
          type: selectedFile.type,
        },
      ],
    };

    axios
      .post(URL.MODEL_OP(MODEL_OP.IMPORT, MODEL_SUB_OP.CLOUD), cloudImportRequest)
      .then(res => this.handleClarificationResponse(res.data))
      .catch(error => toast.error(`model import error: ${error.message}`))
      .finally(() => {
        this.fileUpload = null;
      });
  }

  importModelFromFiles(files, totalSize) {
    this.stageAnimation.shiftStages('IMPORT_MODEL');
    this.stageAnimation.markInProgress('IMPORT_MODEL');

    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    this.fileUpload = {
      request: source,
      loaded: 0,
      total: totalSize,
    };

    this.showProgress(this.fileUpload);
    const formData = new FormData();
    files.forEach(file => formData.append('file', file));
    formData.append('projectId', this.props.projectId);
    const onUploadProgress = this.showProgress;
    axios
      .post(URL.MODEL_OP(MODEL_OP.IMPORT, MODEL_SUB_OP.FILESYSTEM), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
        cancelToken,
      })
      .then(response => {
        if (!response.data.errors?.length) {
          this.handleFileImportResponse(response, true);
        }
      })
      .catch(error => {
        this.stageAnimation.markActiveOrInactive('IMPORT_MODEL');
        source.cancel();
        console.error(error);
        toast.error(`Error during model import: ${error.message}`);
      })
      .finally(() => {
        this.fileUpload = null;
      });
  }

  showProgress({total, loaded}) {
    ImportModelAction.displayClientServerUploadProgress(loaded / total);
  }

  handleFileImportResponse(res) {
    let response = res.data.body;
    if (response.errors && response.errors.length > 0) {
      this.finishUpload('ADD_DATA');
    } else {
      this.clarifyImport(response);
    }
  }

  clarifyImport(importRequest) {
    axios
      .post(URL.MODEL_OP(MODEL_OP.IMPORT, MODEL_SUB_OP.CLARIFY), importRequest)
      .then(res => this.handleClarificationResponse(res.data))
      .catch(error => toast.error(`model import error: ${error.message}`));
  }

  handleClarificationResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.finishImport();
    } else {
      this.monitorStatus();
    }
  }

  displayModelUploadProgress(status) {
    this.props.setModelActionInProgress('import');
    ImportModelAction.displayImportProgress(status);
    if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
      this.props.removeStatusListener('IMPORT_MODEL');
      if (status.prompts && status.prompts.length > 0) {
        this.showInitialAssistant(status);
      } else {
        this.requestImportFinalization(null);
      }
    }
  }

  showInitialAssistant(taskProgress) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.display(
        this.getQuestionsForm(taskProgress.prompts, taskProgress.defaults),
        'IMPORT_MODEL',
        null,
        this.terminateImport
      );
    }
  }

  getQuestionsForm(questions, defaults) {
    return (
      <StageAssistantForm
        prompts={questions}
        defaults={defaults}
        onSubmit={this.requestImportFinalization}
        onCancel={this.terminateImport}
        title="We need a little more information to finish adding this model."
      />
    );
  }

  requestImportFinalization(answers) {
    this.props.viewDisableTermination();
    const finalizationRequest = {
      projectId: this.props.projectId,
      answers: answers,
    };

    axios
      .post(URL.MODEL_OP(MODEL_OP.IMPORT, MODEL_SUB_OP.FINALIZE), finalizationRequest)
      .then(({data}) => {
        if (this.props.assistantPanel.current) {
          this.props.assistantPanel.current.hideStage('IMPORT_MODEL');
        }
        this.handleFinalizationResponse(data);
      })
      .catch(error => toast.error(`model import error: ${error.message}`));
  }

  handleFinalizationResponse(response) {
    if (response.prompts && response.prompts.length > 0) {
      this.showInitialAssistant(response);
    } else if (response.body) {
      ImportModelAction.displayModel(response.body);
      this.finishImport();
    } else if (response.errors) {
      this.finishImport();
    }
  }

  async finishImport() {
    if (this.props.onFinish) {
      // Need to update the Project details since model just got added.
      await this.props.projectFetch(this.props.projectId);
      this.props.onFinish('IMPORT_MODEL');
    }
  }

  terminateImport() {
    this.props.viewDisableTermination();
    if (this.fileUpload) {
      this.fileUpload.request.cancel();
      this.fileUpload = null;
    }
    return axios
      .get(URL.OPERATION_CANCEL('IMPORT_MODEL'), {
        params: {projectId: this.props.projectId},
      })
      .then(res => {
        this.props.removeStatusListener('IMPORT_MODEL');
        this.props.assistantPanel.current.hideStage('IMPORT_MODEL');
        this.finishImport();
      })
      .catch(error => toast.error(`cancel import error: ${error.message}`));
  }

  static displayModel(model) {
    let nameText = document.getElementById('model-name-finished');
    if (nameText) {
      nameText.innerText = model.name ? model.name : 'Model';
    }
  }

  static displayImportProgress(status) {
    let progressText = document.getElementById('model-action-progress');
    if (progressText) {
      progressText.innerText = status.statusMessage;
    }
  }

  static displayClientServerUploadProgress(percent) {
    let progressText = document.getElementById('model-action-progress');
    if (progressText && !progressText.innerText.endsWith('...')) {
      progressText.innerText = Math.round(percent * 100.0) + '% uploaded.';
    }
  }

  deleteModel() {
    this.props.dispatchModelDelete(this.props.projectId).then(json => {
      this.finishImport();
    });
  }
}

ImportModelAction.propTypes = {
  projectId: PropTypes.string.isRequired,
  assistantPanel: PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)})
    .isRequired,
  onFinish: PropTypes.func.isRequired,
  taskProgress: TaskInfoProps,
};

const mapStateToProps = state => ({
  location: getLocation(state),
});

const mapDispatchToProps = {
  projectFetch,
  dispatchModelDelete: modelDelete,
  addStatusListener: addListener,
  removeStatusListener: removeListener,
  navigate: push,
  viewEnableTermination,
  viewDisableTermination,
};

export {ImportModelAction as ImportModelActionBase};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(
  ImportModelAction
);
