import {DeploymentDetailsInfo} from '../../types/deployment/DeploymentDetailsInfo';
import {request} from '../request';

export const DeploymentDetailsUrl = '/deployment/details';

export type DeploymentDetailsRequest = {
  deploymentId: string;
};

export type DeploymentDetailsResponse = DeploymentDetailsInfo;

export async function getDeploymentDetails(params: DeploymentDetailsRequest) {
  return request<DeploymentDetailsResponse>({
    method: 'get',
    url: DeploymentDetailsUrl,
    params,
  });
}
