import React, {Component} from 'react';
import PropTypes from 'prop-types';
import StageAnimation from '../StageAnimation/StageAnimation';
import Util from '../../../util';
import TaskInfoProps from '../../../model/taskInfoPropTypes';
import {toast} from '../../../base-components/StudioToast';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import axios from 'axios';
import URL from '../../../config/url';
import {StageAssistantForm} from '../../StageAssistantForm';
import {connect} from 'react-redux';
import {addListener, removeListener} from '../../../store/statusChecker';
import {viewDisableTermination, viewEnableTermination} from '../../../store/view';
import {projectFetch} from '../../../store/project';

class DataTilingAction extends Component {
  constructor(props) {
    super(props);

    this.stageAnimation = new StageAnimation();
    this.initiateDataTiling = this.initiateDataTiling.bind(this);
    this.handleDataTilingResponse = this.handleDataTilingResponse.bind(this);
    this.finishDataTiling = this.finishDataTiling.bind(this);
    this.cancelDataTiling = this.cancelDataTiling.bind(this);
    this.showAssistant = this.showAssistant.bind(this);
    this.getQuestionsForm = this.getQuestionsForm.bind(this);
    this.clarifyDataTiling = this.clarifyDataTiling.bind(this);
    this.handleDataTilingClarifyResponse = this.handleDataTilingClarifyResponse.bind(
      this
    );
    this.monitorStatus = this.monitorStatus.bind(this);
    this.processDataTilingProgress = this.processDataTilingProgress.bind(this);
    this.finalizeDataTiling = this.finalizeDataTiling.bind(this);
    this.terminateDataTiling = this.terminateDataTiling.bind(this);
  }

  componentDidMount() {
    if (this.props.taskProgress && this.props.taskProgress.type === 'TILE_DATA') {
      this.props.setDatasetActionInProgress('tiling');
      if (this.props.taskProgress.prompts && this.props.taskProgress.prompts.length) {
        this.stageAnimation.shiftStages('ADD_DATA');
        this.showAssistant(this.props.taskProgress);
      } else {
        this.monitorStatus();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.taskProgress?.type !== 'TILE_DATA' &&
      this.props.taskProgress?.type === 'TILE_DATA'
    ) {
      this.props.setDatasetActionInProgress('tiling');
      if (this.props.taskProgress.prompts && this.props.taskProgress.prompts.length) {
        this.stageAnimation.shiftStages('ADD_DATA');
        this.showAssistant(this.props.taskProgress);
      } else {
        this.monitorStatus();
      }
    }
  }

  componentWillUnmount() {
    this.props.removeStatusListener('TILE_DATA');
  }

  render() {
    return null;
  }

  initiateDataTiling(caAnswers) {
    this.stageAnimation.markInProgress('ADD_DATA');
    if (Array.isArray(caAnswers)) {
      this.clarifyDataTiling(null, caAnswers);
    } else {
      axios
        .post(URL.DATASET_TILING_INITIATE(this.props.projectId))
        .then(response => this.handleDataTilingResponse(response.data))
        .catch(error => toast.error(`data tiling error: ${error.message}`));
    }
  }

  handleDataTilingResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.finishDataTiling();
    } else {
      this.showAssistant(response);
    }
  }

  async finishDataTiling() {
    if (this.props.onFinish) {
      // Tiling the dataset results in a new id, so refresh the project details.
      await this.props.projectFetch(this.props.projectId);
      this.props.onFinish('IMPORT_MODEL');
    }
  }

  cancelDataTiling() {
    if (this.props.onFinish) {
      this.props.onFinish('ADD_DATA');
    }
  }

  showAssistant(response) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.display(
        this.getQuestionsForm(response.prompts, response.defaults),
        'ADD_DATA',
        null,
        this.terminateDataTiling
      );
    }
  }

  getQuestionsForm(questions, defaults) {
    return (
      <StageAssistantForm
        prompts={questions}
        defaults={defaults}
        onSubmit={this.clarifyDataTiling}
        onCancel={this.terminateDataTiling}
        title="Select data tiling parameters"
        notifyType="TILE_DATA"
      />
    );
  }

  clarifyDataTiling(answersArray, caAnswers) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.hideStage('ADD_DATA');
    }
    const clarificationRequest = {
      projectId: this.props.projectId,
      answers: answersArray,
      caAnswers,
    };

    axios
      .post(URL.DATASET_TILING_CLARIFY, clarificationRequest, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => this.handleDataTilingClarifyResponse(response.data))
      .catch(error => toast.error(`data tiling error: ${error.message}`));
  }

  handleDataTilingClarifyResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.cancelDataTiling();
    } else if (!response.body && response.warnings && response.warnings.length > 0) {
      this.cancelDataTiling();
    } else if (response.prompts) {
      this.showAssistant(response);
    } else {
      this.monitorStatus();
    }
  }

  monitorStatus() {
    this.props.viewEnableTermination();
    this.stageAnimation.markInProgress('ADD_DATA');
    this.props.addStatusListener('TILE_DATA', this.processDataTilingProgress);
  }

  processDataTilingProgress(status) {
    DataTilingAction.displayDataTilingProgress(status);
    if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
      this.props.removeStatusListener('TILE_DATA');
      this.finalizeDataTiling();
    }
  }

  static displayDataTilingProgress(status) {
    let progressText = document.getElementById('add-data-action-progress');
    if (progressText) {
      progressText.innerText = status.statusMessage;
    }
  }

  finalizeDataTiling() {
    this.props.viewDisableTermination();
    axios
      .post(URL.DATASET_TILING_FINALIZE(this.props.projectId))
      .then(res => {
        if (res.data.body && !res.data.errors && !res.data.prompts) {
          this.finishDataTiling();
        } else if (res.data.prompts) {
          this.showAssistant(res);
        } else {
          this.cancelDataTiling();
        }
      })
      .catch(error => toast.error(`data tiling error: ${error.message}`));
  }

  terminateDataTiling() {
    this.props.viewDisableTermination();
    return axios
      .get(URL.OPERATION_CANCEL('TILE_DATA'), {
        params: {projectId: this.props.projectId},
      })
      .then(res => {
        this.props.assistantPanel.current.hideStage('ADD_DATA');
        this.props.removeStatusListener('TILE_DATA');
        this.cancelDataTiling();
      })
      .catch(error => toast.error(`cancel data tiling error: ${error.message}`));
  }
}

DataTilingAction.propTypes = {
  projectId: PropTypes.string.isRequired,
  assistantPanel: PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)})
    .isRequired,
  onFinish: PropTypes.func.isRequired,
  taskProgress: TaskInfoProps,
};

const mapDispatchToProps = {
  projectFetch,
  addStatusListener: addListener,
  removeStatusListener: removeListener,
  viewEnableTermination,
  viewDisableTermination,
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(
  DataTilingAction
);
