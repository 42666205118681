import {mutate} from 'swr';
import {UpdatePreProcessorRequest} from '../../types/preprocessor/UpdatePreProcessorRequest';
import {request} from '../request';
import {PreProcessorListUrl} from './PreProcessorList';

export type PreProcessorUpdateRequest = UpdatePreProcessorRequest;

export type PreProcessorUpdateResponse = {};

export const PreProcessorUpdateUrl = (_data: PreProcessorUpdateRequest) =>
  `/preprocessor/update`;

export async function preProcessorUpdate(data: PreProcessorUpdateRequest) {
  const response = await request<PreProcessorUpdateResponse>({
    method: 'post',
    url: PreProcessorUpdateUrl(data),
    data,
  });
  mutate(
    PreProcessorListUrl({
      projectId: data.projectId,
    })
  );
  return response;
}
