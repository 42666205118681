import React, {Component} from 'react';
import PropTypes from 'prop-types';

class AssistantIcon extends Component {
  render() {
    return (
      <div
        className={this.props.className}
        onClick={this.props.onClick}
        data-testid="assistant-icon"
      >
        <svg
          width="80px"
          height="80px"
          viewBox="0 0 80 80"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <path
              d="M 19 24.696 L 16.621 24.68 L 16.621 16.295 C 15.46 17.92 13.575 18.993 11.456 18.993 C 7.336 18.993 4 15.628 4 11.16 C 4 6.663 7.366 3.297 11.515 3.297 C 15.75 3.297 19 6.75 19 11.16 L 19 24.696 Z M 11.515 5.56 C 8.729 5.56 6.466 8.026 6.466 11.16 C 6.466 14.293 8.729 16.701 11.515 16.701 C 14.271 16.701 16.563 14.235 16.563 11.16 C 16.563 8.055 14.242 5.56 11.515 5.56 Z"
              id="assistant-icon"
              fill="#3F3F3F"
              transform="matrix(-1, 0, 0, -1, 25, 25)"
            />
          </defs>
          <g
            id="Home-&amp;-Discovery-Questions"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Home_Full" transform="translate(-680.000000, -575.000000)">
              <g id="Element-/-Assistant" transform="translate(680.000000, 575.000000)">
                <g id="assistant">
                  <path
                    d="M79,39 C79,17.460768 61.539232,0 40,0 C18.460768,0 1,17.460768 1,39 C1,60.539232 18.460768,78 40,78 C61.539232,78 79,60.539232 79,39 Z"
                    id="blur"
                    fillOpacity="0"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M79,39.5 C79,17.6846256 61.3153744,0 39.5,0 C17.6846256,0 0,17.6846256 0,39.5 C0,61.3153744 17.6846256,79 39.5,79 C61.3153744,79 79,61.3153744 79,39.5 Z M78,39.5 C78,60.7630896 60.7630896,78 39.5,78 C18.2369104,78 1,60.7630896 1,39.5 C1,18.2369104 18.2369104,1 39.5,1 C60.7630896,1 78,18.2369104 78,39.5 Z"
                    id="stroke"
                    fill="#59198C"
                  />
                  <path
                    d="M80,40.5 C80,18.6846256 62.3153744,1 40.5,1 C18.6846256,1 1,18.6846256 1,40.5 C1,62.3153744 18.6846256,80 40.5,80 C62.3153744,80 80,62.3153744 80,40.5 Z M79,40.5 C79,61.7630896 61.7630896,79 40.5,79 C19.2369104,79 2,61.7630896 2,40.5 C2,19.2369104 19.2369104,2 40.5,2 C61.7630896,2 79,19.2369104 79,40.5 Z"
                    id="stroke"
                    fill="#00F6FB"
                  />
                  <path
                    d="M40.5,9 C23.3793951,9 9.5,22.8789105 9.5,40.0003121 C9.5,57.1210895 23.3793951,71 40.5,71 C57.6206049,71 71.5,57.1210895 71.5,40.0003121 C71.5,22.8789105 57.6206049,9 40.5,9 Z"
                    id="Circle-6---primary-2---25-percent"
                    fillOpacity="0.25"
                    fill="#00F6FB"
                  />
                  <path
                    d="M68.0844305,61.8417745 C59.3352721,74.5906386 41.9075433,77.8339186 29.1586091,69.0841837 C16.4090502,60.3350735 13.166377,42.9068159 21.9155353,30.1579518 C30.6646937,17.4090876 48.0930471,14.1664323 60.8419814,22.9155425 C73.5909156,31.6646527 76.8335888,49.0929103 68.0844305,61.8417745"
                    id="Circle-5---Primary-2---12-percent"
                    fillOpacity="0.12"
                    fill="#00F6FB"
                  />
                  <path
                    d="M61.3210964,62.190083 C52.1032331,75.6226056 33.741876,79.0389811 20.3099632,69.8211511 C6.87739216,60.603321 3.46100431,42.241372 12.6788676,28.8088494 C21.8967309,15.376985 40.2587461,11.9612675 53.6906589,21.1790976 C67.1225718,30.3962695 70.5389597,48.7582186 61.3210964,62.190083"
                    id="Circle-4---Primary-1---20-percent"
                    fillOpacity="0.2"
                    fill="#59198C"
                  />
                  <path
                    d="M58.0844722,50.8414273 C49.3353882,63.590948 31.9071827,66.8336116 19.1583568,58.0844794 C6.40890614,49.3353472 3.16626047,31.9070459 11.9159692,19.1581499 C20.6650532,6.40862911 38.092634,3.16659027 50.8420846,11.9157224 C63.5909106,20.6642299 66.8335563,38.0925312 58.0844722,50.8414273"
                    id="Circle-3---Primary-3---20-percent"
                    fillOpacity="0.2"
                    fill="#00AEEF"
                  />
                  <path
                    d="M65.9966555,52.8416254 C57.0912676,65.5904895 39.3523402,68.8337696 26.3751185,60.0846593 C13.3985327,51.3349244 10.0979566,33.9072915 19.0033445,21.1578027 C27.9087324,8.40893858 45.6482956,5.1662832 58.6248815,13.9160181 C71.6014673,22.6645037 74.9020434,40.0927613 65.9966555,52.8416254"
                    id="Circle-2---Primary-2---12-percent"
                    fillOpacity="0.12"
                    fill="#00F6FB"
                  />
                  <path
                    d="M70.0841807,51.8417745 C61.3350651,64.5906386 43.9074216,67.8339186 31.1585497,59.0841837 C18.4090531,50.3350735 15.1663957,32.9068159 23.9155113,20.1579518 C32.6646269,7.40908764 50.0928951,4.16643226 62.841767,12.9155425 C75.5906389,21.6646527 78.833921,39.0922857 70.0841807,51.8417745"
                    id="Circle-1---Primary-1---20-percent"
                    fillOpacity="0.2"
                    fill="#59198C"
                  />
                  <path
                    d="M64,40 C64,26.7447548 53.2547794,16 39.9996999,16 C26.7452206,16 16,26.7447548 16,40 C16,53.254645 26.7452206,64 39.9996999,64 C53.2547794,64 64,53.254645 64,40"
                    id="fill"
                    fill="#FFFFFF"
                  />

                  <g
                    id="icon-/-assistant-/-a"
                    transform="translate(28.000000, 28.000000)"
                  >
                    <rect id="Container" x="0" y="0" width="24" height="24" />
                    <mask id="mask-2" fill="white">
                      <use xlinkHref="#assistant-icon" />
                    </mask>
                    <use id="a" fill="currentColor" xlinkHref="#assistant-icon" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

AssistantIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default AssistantIcon;
