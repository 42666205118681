import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TestInitiateRequest} from '../../../api/test/TestInitiate';
import {toast} from '../../../base-components/StudioToast';
import {addListener} from '../../../store/statusChecker';
import {DeploymentType, DeploymentTypes} from '../../../types/deployment/DeploymentType';
import {
  resetAllTasks,
  setDeployTask,
  setStreamingTask,
  TASK_STATUS,
  useDeploy,
} from '../useDeploy';
import {
  EVENT_TYPE,
  getVideoStream,
  MonitorConfig,
  VideoStreamEvent,
} from '../VideoStream';
import {useDeployTask} from './useDeployTask';
import {RootState} from '../../../store';
import {TaskInfo} from '../../../types/metadata/TaskInfo';
import {Deployment} from '../../../types/deployment/DeploymentResponse';
import {InputSource} from '../../../types/deployment/InferenceInputSource';

export const useVideoStreamingTask = (projectId: string) => {
  const {start, stop, updateParameters} = useMemo(getVideoStream, []);
  const reduxDispatch = useDispatch();
  const [{streamingTask, deployTask, streamingDevices}, dispatch] = useDeploy();

  const serverConfig = useSelector((state: RootState) => state.config);

  const {startDeploy, terminateDeploy} = useDeployTask(projectId);

  const monitorStatus = useCallback(
    onMonitoringResponse => {
      let wereParametersFound = false;
      reduxDispatch(
        addListener('GSP_DEPLOY', (status: TaskInfo) => {
          dispatch(setDeployTask({monitoringResponse: status}));
          if (status.failed) {
            dispatch(setStreamingTask({status: TASK_STATUS.ERROR}));
            if (status.warnings.length > 0 && status.warnings[0]['detailMessage']) {
              toast.error(`Streaming error: ${status.warnings[0]['detailMessage']}`);
            } else {
              toast.error(`Streaming error. Please try again.`);
            }
            terminateDeploy();
          }
          if (!wereParametersFound && status.gspBoxOutputUrl) {
            wereParametersFound = true;
            onMonitoringResponse?.(status);
          }
        })
      );
    },
    [dispatch, reduxDispatch, terminateDeploy]
  );

  const cleanUpStream = useCallback(() => {
    if (streamingTask.status !== TASK_STATUS.IDLE) {
      stop();
      if (deployTask.status === TASK_STATUS.IN_PROGRESS) {
        terminateDeploy();
      }
    }
  }, [streamingTask.status, deployTask.status, terminateDeploy, stop]);

  const cleanUpStreamRef = useRef(cleanUpStream);

  useEffect(() => {
    cleanUpStreamRef.current = cleanUpStream;
  }, [cleanUpStream]);

  useEffect(
    function stopStreamIfWindowClosed() {
      window.addEventListener('beforeunload', cleanUpStream);
      return () => {
        window.removeEventListener('beforeunload', cleanUpStream);
      };
    },
    [cleanUpStream]
  );

  useEffect(function stopStreamIfUnmounted() {
    return () => {
      if (cleanUpStreamRef.current) {
        cleanUpStreamRef.current();
      }
    };
  }, []);

  useEffect(
    function updateRealtimeParameters() {
      if (streamingTask.status === TASK_STATUS.CONNECTED) {
        updateParameters(
          streamingTask.realtimePostProcessorParameters.concat(
            streamingTask.realtimePreProcessorParameters
          )
        );
      }
    },
    [
      streamingTask.status,
      streamingTask.realtimePostProcessorParameters,
      streamingTask.realtimePreProcessorParameters,
      updateParameters,
    ]
  );

  const runStreamingDeploy = async (
    config: TestInitiateRequest,
    onMonitoringResponse?: (monitoringResponse: TaskInfo) => void,
    onStartDeploy?: (deployment: Deployment) => void
  ) => {
    try {
      dispatch(setDeployTask({status: TASK_STATUS.IN_PROGRESS}));
      dispatch(setStreamingTask({status: TASK_STATUS.IN_PROGRESS}));
      await startDeploy(config, onStartDeploy);
      monitorStatus(onMonitoringResponse);
    } catch (e) {
      stop();
      dispatch(resetAllTasks());
    }
  };

  const runStreamingTask = ({
    projectId,
    applicationId,
    nodeId,
    name,
    description,
    deploymentType,
    inferenceSource,
    videoFile,
    ipCamera,
    environment,
  }: {
    projectId: string;
    applicationId: string;
    nodeId: string;
    name: string;
    description: string;
    deploymentType: DeploymentType;
    inferenceSource: Exclude<InputSource, 'IMAGES'>;
    videoFile: string;
    ipCamera: string;
    environment?: Record<string, string>;
  }) => {
    const handleEvent = (event: VideoStreamEvent) => {
      switch (event.type) {
        case EVENT_TYPE.CONNECTING:
          dispatch(
            setStreamingTask({
              status: TASK_STATUS.IN_PROGRESS,
              receiveOnly: event.receiveOnly,
            })
          );
          break;
        case EVENT_TYPE.REGISTERED:
          dispatch(setStreamingTask({status: TASK_STATUS.READY}));
          const sessionId = event.sessionId;
          runStreamingDeploy(
            {
              projectId,
              applicationId,
              name,
              description,
              nodeId,
              deploymentType: DeploymentTypes.INFERENCE,
              deploymentInput: {
                inputSourceType: inferenceSource,
              },
              parameters: {
                environment,
                streamingSessionId: sessionId,
              },
            },
            undefined,
            deployment => {
              if (event.monitorConfig) {
                event.monitorConfig.deploymentId = deployment.id;
              }
            }
          );
          break;
        case EVENT_TYPE.RECEIVE_ONLY_READY:
          dispatch(setStreamingTask({status: TASK_STATUS.READY}));
          break;
        case EVENT_TYPE.CONNECTED:
          dispatch(setStreamingTask({status: TASK_STATUS.CONNECTED}));
          break;
        case EVENT_TYPE.DATA:
          if (event.data) {
            dispatch(
              setStreamingTask({
                data: event.data,
              })
            );
          }
          break;
        case EVENT_TYPE.ERROR:
          dispatch(setStreamingTask({status: TASK_STATUS.ERROR}));
          if (event.message) {
            toast.error(`Streaming error: ${event.message}`);
          } else {
            toast.error(`Streaming error. Please try again.`);
          }
          terminateDeploy();
          break;
        case EVENT_TYPE.STREAM_FINISHED:
          dispatch(setStreamingTask({status: TASK_STATUS.COMPLETE}));
          dispatch(setDeployTask({status: TASK_STATUS.COMPLETE}));
          terminateDeploy();
          break;
        case EVENT_TYPE.USER_STREAM:
          dispatch(setStreamingTask({userStream: event.stream}));
          break;
        case EVENT_TYPE.REMOTE_STREAM:
          dispatch(setStreamingTask({remoteStream: event.stream}));
          break;
        default:
          break;
      }
    };

    if (inferenceSource === 'WEB_CAMERA') {
      start({
        projectId,
        serverConfig,
        studioTaskId: 'GSP_DEPLOY',
        monitorConfig: {
          monitorStream: true,
          projectId: projectId,
        },
        onEvent: handleEvent,
      });
    } else {
      const url =
        inferenceSource === 'IP_CAMERA'
          ? streamingDevices.find(camera => camera.id === ipCamera)?.url
          : streamingDevices.find(file => file.id === videoFile)?.url;
      const app_input = inferenceSource === 'APP_INPUT';
      const regions = streamingDevices.find(camera => camera.id === ipCamera)?.regions;
      const lines = streamingDevices.find(camera => camera.id === ipCamera)?.lines;
      const monitorConfig: MonitorConfig = {
        monitorStream: true,
        projectId: projectId,
      };
      runStreamingDeploy(
        {
          name,
          description,
          projectId,
          applicationId,
          nodeId,
          deploymentType,
          deploymentInput: {
            inputSourceType: inferenceSource,
          },
          parameters: {
            environment,
            ...(!app_input
              ? {
                  streamingUrl: url,
                  lines,
                  regions,
                }
              : {}),
          },
        },
        monitoringResponse => {
          start({
            receiveOnly: true,
            source: inferenceSource,
            url: monitoringResponse.gspBoxOutputUrl,
            targetSession: monitoringResponse.targetSession,
            projectId,
            studioTaskId: 'GSP_DEPLOY',
            serverConfig,
            monitorConfig,
            onEvent: handleEvent,
          });
        },
        deployment => {
          monitorConfig.deploymentId = deployment.id;
        }
      );
    }
  };

  return [runStreamingTask, stop] as const;
};
