import React from 'react';
import BeforeImage1 from './images/denoising-before.png';
import AfterImage from './images/denoising-after.png';
import './Help.scss';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>Denoising</h4>
      <div className="flow-graph__help_content_detail">
        <p>Image denoising is a relevant issue found in diverse image processing and computer vision problems. It is a challenge to preserve important features, such as edges, corners, and other sharp structures, during the denoising process. Wavelet transforms have been widely used for image denoising since they provide a suitable basis for separating noisy signal from the image signal.</p>
        <p>Digital images can be corrupted by noise during the process of acquisition and transmission, degrading their quality. A major challenge is to remove as much as possible of the noise without eliminating the most representative characteristics of the image, such as edges, corners and other sharp structures. Several approaches have been proposed to suppress the presence of noise in digital images, many of them based on spatial filters. These filters usually smooth the data to reduce noise effects, however, this process can cause image blurring or edge removal.</p>
        <p>The common idea related to the suppression of noise based on the wavelet transform is to compute the wavelet decomposition of the noisy image and to manipulate the obtained wavelet coefficients. Coefficients that are supposed to be affected by noise are replaced by  zero or an adequate value. Reconstruction from these manipulated coefficients then generates the resulting denoised image.</p>
        <p>An adaptive thresholding that combines local processing and edge strength is used to effectively reduce Gaussian noise while preserving important features of the original image. Experimental results demonstrate that the proposed method, when compared to well-known denoising approaches, is suitable for different classes of images contaminated by noise.</p>
        <p>Wavelet thresholding is a common approach for denoising due to its simplicity. There are several studies on thresholding the wavelet coefficients. The process, commonly called wavelet shrinkage, consists of the following main stages:</p>
        <ol>
          <li>Perform the discrete wavelet transform;</li>
          <li>Estimate a threshold;</li>
          <li>Apply the threshold according to a shrinkage rule;</li>
          <li>Perform the inverse wavelet transform using the thresholded coefficients.</li>
        </ol>
        <div className="help_images">
          <figure>
            <img src={BeforeImage1} alt="before" />
            <figcaption>Input</figcaption>
          </figure>
          <figure>
            <img src={AfterImage} alt="after" />
            <figcaption>Output</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};
