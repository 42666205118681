import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

const StudioTab = withStyles(theme => ({
  root: {
    textTransform: 'none',

    '&:hover': {
      color: 'var(--primary1Hover)',
      opacity: 1,
    },
    '&$selected': {
      color: 'var(--primary1)',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: 'var(--primary1)',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

export default StudioTab;
