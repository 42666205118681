import {REQUEST_STATUS} from './reducers';

export const getModelDetails = state => state.model.data;

export const isModelDetailsFetchPending = state =>
  state.model.status === REQUEST_STATUS.PENDING;

export const isModelViewerStale = state => state.model.isViewerStale;

export const isModelDetailsPanelOpen = state => state.model.isDetailsPanelOpen;

export const getModelDetailsContext = state => state.model.detailsPanelContext;

export const getProject = state => state.project.data;
