let lastId = 0;

export default class Util {
  static nextId(prefix) {
    lastId++;
    return prefix + lastId.toString();
  }

  static getSiblings(node) {
    let elements = [];
    let element = node;
    while (element.previousElementSibling != null) {
      element = element.previousElementSibling;
    }
    let i = 0;
    do {
      elements[i++] = element;
      element = element.nextElementSibling;
    } while (element != null);

    return elements;
  }

  static isRealNumberEqual(num, value) {
    return num > value - 0.0001 && num < value + 0.0001;
  }
}
