import React from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  Radio,
} from '@material-ui/core';
import cn from 'classnames';
import {StudioPopover} from '../StudioPopover';
import './StudioRadio.scss';

type StudioRadioProps = {
  value: string;
  label: string;
  className?: string;
  fixedTooltip?: boolean;
  tooltip?: string;
  helpertext?: string;
} & Partial<FormControlLabelProps>;

export const StudioRadio = ({
  className,
  fixedTooltip = false,
  tooltip,
  helpertext,
  ...rest
}: StudioRadioProps) => (
  <FormGroup className={cn('studio-radio', className)}>
    <div className="studio-radio__main">
      <FormControlLabel
        data-testid="studio-radio-option"
        control={<Radio />}
        {...rest}
        label={<span dangerouslySetInnerHTML={{__html: rest.label}}></span>}
      />
      {(tooltip || fixedTooltip) && (
        <StudioPopover
          infoIconClass={cn(
            'studio-radio__main__tooltip',
            fixedTooltip && !tooltip && 'studio-radio__main__tooltip--hidden'
          )}
          infoMessage={tooltip}
        />
      )}
    </div>
    {helpertext && <p className="studio-radio__helper">{helpertext}</p>}
  </FormGroup>
);
