import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

function StudioTabPanel(props) {
  const {children, value, index, className, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className={className}
      {...other}
    >
      {value === index && children}
    </Typography>
  );
}

StudioTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default StudioTabPanel;
