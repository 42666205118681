import {Popover, PopoverProps} from '@material-ui/core';
import axios from 'axios';
import Image from 'material-ui-image';
import React, {memo, useEffect, useState} from 'react';
import './ImageCard.scss';
import {ImageProps} from 'material-ui-image/lib/components/Image/Image';
import {PoseDetectionOverlay} from './PoseDetectionOverlay';

export type ImageCardProps = {
  imageFunc: (w?: number, h?: number) => string;
  width: number;
  height: number;
  points?: number[][][];
  isPopup: boolean;
};

type XSizesItem = {
  id: string;
  w: number;
  h: number;
  s: number;
};

type ImageMetadata = {
  filename: string;
  width: number;
  height: number;
  fileSize: number;
};

export const ImageCard = memo(
  ({imageFunc, width, height, points, isPopup = true}: ImageCardProps) => {
    const [imageMetadata, setImageMetadata] = useState<ImageMetadata>();
    const [anchorEl, setAnchorEl] = React.useState<PopoverProps['anchorEl']>(null);

    const onOpen: ImageProps['onClick'] = event => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    };

    const onClose: ImageProps['onClick'] = event => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(null);
    };

    useEffect(() => {
      async function fetchImage() {
        try {
          const {headers} = await axios.get(imageFunc(), {
            responseType: 'blob',
          });
          const unparsedJson = headers?.['x-sizes'];
          let header: XSizesItem | null = null;
          if (unparsedJson) {
            try {
              header = JSON.parse(unparsedJson)?.[0];
            } catch (e) {}
          }
          if (header) {
            setImageMetadata({
              filename: header.id,
              width: header.w,
              height: header.h,
              fileSize: header.s,
            });
          }
        } catch (e) {}
      }

      fetchImage();
    }, [imageFunc, height, width]);
    var w = imageMetadata?.width ?? width;
    var h = imageMetadata?.height ?? height;
    return (
      <div onClick={onOpen}>
        <Image
          className="image-card-horizontal_image"
          src={imageFunc(width, height)}
          imageStyle={{width, height}}
        />
        {points && points.length > 0 && (
          <PoseDetectionOverlay width={width} height={height} points={points} />
        )}
        {isPopup && (
          <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
            disableScrollLock
            PaperProps={{
              style: {
                width: w,
                height: h,
                overflow: 'hidden',
              },
            }}
          >
            <img alt={imageMetadata?.filename} src={imageFunc(w, h)} />
            {points && points.length > 0 && (
              <PoseDetectionOverlay width={w} height={h} points={points} />
            )}
          </Popover>
        )}
      </div>
    );
  }
);
