import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Waiting.scss';

class Waiting extends Component {
  render() {
    let _style = {
      _boxStyle: {
        width: '3px',
        height: '3px',
        overflow: 'visible',
        transformOrigin: 'center',
      },
      _pathStyle: {
        fill: '#007BB7',
        transform: ' scale(1)',
        transformOrigin: '50% 50%',
        transformBox: 'fill-box',
      },
    };
    let extraClass = this.props.className ? this.props.className : '';
    return (
      <div
        className={`waiting-block ${extraClass}`}
        data-testid={this.props['data-testid']}
        id={this.props['data-testid']}
      >
        <div
          className="waiting"
          style={{
            width: this.props.diameter + 'px',
            height: this.props.diameter + 'px',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-3 -3 24 24"
            preserveAspectRatio="xMidYMid meet"
          >
            <svg xmlns="http://www.w3.org/2000/svg" style={_style._boxStyle}>
              <path
                id="_1"
                style={Object.assign(_style._pathStyle)}
                className="cirlce"
                d="M15.69,8.16a1.46,1.46,0,1,1-.43,1A1.44,1.44,0,0,1,15.69,8.16Z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={_style._boxStyle}
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                id="_2"
                style={Object.assign(_style._pathStyle)}
                d="M13.48,2.82a1.42,1.42,0,0,1,1-.43,1.46,1.46,0,0,1,1,2.5,1.44,1.44,0,0,1-1,.43,1.42,1.42,0,0,1-1-.43,1.4,1.4,0,0,1-.43-1A1.4,1.4,0,0,1,13.48,2.82Z"
              />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" style={_style._boxStyle}>
              <path
                id="_3"
                style={Object.assign(_style._pathStyle)}
                d="M8.16.43a1.46,1.46,0,0,1,2.07,0,1.44,1.44,0,0,1,.43,1,1.42,1.42,0,0,1-.43,1,1.46,1.46,0,0,1-2.07,0,1.42,1.42,0,0,1-.43-1A1.44,1.44,0,0,1,8.16.43Z"
              />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" style={_style._boxStyle}>
              <path
                id="_4"
                style={Object.assign(_style._pathStyle)}
                d="M2.88,2.66a1.4,1.4,0,0,1,1-.43,1.38,1.38,0,0,1,1,.43,1.4,1.4,0,0,1,.43,1,1.38,1.38,0,0,1-.43,1,1.38,1.38,0,0,1-1,.43,1.4,1.4,0,0,1-1-.43,1.38,1.38,0,0,1-.43-1A1.4,1.4,0,0,1,2.88,2.66Z"
              />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" style={_style._boxStyle}>
              <path
                id="_5"
                style={Object.assign(_style._pathStyle)}
                d="M.43,8.16a1.44,1.44,0,0,1,1-.43,1.42,1.42,0,0,1,1,.43,1.46,1.46,0,0,1,0,2.07,1.42,1.42,0,0,1-1,.43,1.44,1.44,0,0,1-1-.43,1.46,1.46,0,0,1,0-2.07Z"
              />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" style={_style._boxStyle}>
              <path
                id="_6"
                style={Object.assign(_style._pathStyle)}
                d="M2.63,13.64a1.4,1.4,0,0,1,1-.43,1.38,1.38,0,0,1,1,.43,1.4,1.4,0,0,1,.43,1,1.38,1.38,0,0,1-.43,1,1.38,1.38,0,0,1-1,.43,1.4,1.4,0,0,1-1-.43,1.42,1.42,0,0,1-.43-1A1.44,1.44,0,0,1,2.63,13.64Z"
              />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" style={_style._boxStyle}>
              <path
                id="_7"
                style={Object.assign(_style._pathStyle)}
                d="M8.11,15.65a1.46,1.46,0,0,1,2.49,1,1.38,1.38,0,0,1-.43,1,1.45,1.45,0,0,1-2.06,0,1.38,1.38,0,0,1-.43-1A1.4,1.4,0,0,1,8.11,15.65Z"
              />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" style={_style._boxStyle}>
              <path
                id="_8"
                style={Object.assign(_style._pathStyle)}
                d="M13.59,13.61a1.42,1.42,0,0,1,1-.43,1.47,1.47,0,0,1,0,2.93,1.42,1.42,0,0,1-1-.43,1.46,1.46,0,0,1,0-2.07Z"
              />
            </svg>
          </svg>
        </div>
        {this.props.loadingText && (
          <div className="waiting-text">{this.props.loadingText}</div>
        )}
        {this.props.children}
      </div>
    );
  }
}

Waiting.propTypes = {
  diameter: PropTypes.number.isRequired,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  loadingText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Waiting;
