import cn from 'classnames';
import {ImageProps} from 'material-ui-image/lib/components/Image/Image';
import * as React from 'react';
import {StudioImageThumbnail} from '../../base-components/StudioImageThumbnail';
import './DataViewerItem.scss';

// This a copy of DataViewerItem with slight modification to allow thumbnail loading as part of the batch image download. Instead of a 1 request per image.
const SIZE_PX = 93;

export type DataViewerListItemThumbnailProps = {
  adornment?: React.ReactNode;
  className?: string;
  width?: number;
  height?: number;
  onClick?: ImageProps['onClick'];
  url: string;
  pos: number;
};

export const DataViewerListItemThumbnail = ({
  adornment,
  className,
  height = SIZE_PX,
  width = SIZE_PX,
  onClick,
  url,
  pos,
}: DataViewerListItemThumbnailProps) => (
  <div
    className={cn('data-viewer-item', className)}
    style={{width, height}}
    onClick={onClick}
  >
    <StudioImageThumbnail
      width={width}
      height={height}
      src={url}
      position={pos}
      onClick={onClick}
    />
    {adornment}
  </div>
);
