import React, {useEffect, RefObject, useState} from 'react';
import {useSelector} from 'react-redux';
import {cancelPreProcessorImport} from '../../../api/preprocessor/CancelPreProcessorImport';
import {getStagesData} from '../../../store/stages';
import {ISPUploadActionBase} from '../../StageView/StageActions/ISPUploadAction';
import {AutoDetectDialog} from './AutoDetectDialog';
import {StagesState} from '../../../types/stage/StagesState';
import {useLocation} from 'react-router';
import {getProjectId} from '../../../config/routes';
import {usePreProcessorList} from '../../../api/preprocessor/PreProcessorList';

export type AutoDetectPreProcessorProps = {
  actionRef?: RefObject<InstanceType<typeof ISPUploadActionBase>>;
};

export const AutoDetectPreProcessor = ({actionRef}: AutoDetectPreProcessorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const stages: StagesState = useSelector(getStagesData);
  const {readyForImportPreProcessors} = stages;

  useEffect(() => {
    if (readyForImportPreProcessors) {
      setIsOpen(true);
    }
  }, [readyForImportPreProcessors]);

  const location = useLocation();
  const projectId = getProjectId(location.pathname);
  const {data: ppListData} = usePreProcessorList({projectId});

  return (
    <AutoDetectDialog
      isOpen={isOpen}
      title="Add Compatible Pre Processors"
      purpose="ISP_STORAGE"
      columns={[
        'displayName',
        'transformationType',
        'applicationType',
        'executionContainer',
        'provider',
        'creationDateTime',
        'size',
        'studioVersion',
      ]}
      onClose={async (projectId: string) => {
        setIsOpen(false);
        try {
          await cancelPreProcessorImport(projectId);
        } catch (e) {
          console.error(e);
        }
      }}
      onSubmit={files => {
        actionRef?.current?.ispUpload(files);
      }}
      excludedRefs={ppListData?.existingPreProcessors?.map(pp => pp.name)}
    />
  );
};
