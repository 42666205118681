import React from 'react';
import {Dialog} from '@material-ui/core';
import {DynamicForm} from '../../DynamicForm';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {useIntl} from 'react-intl';
import {Answer} from '../../../types/prompts/Answer';
import {Defaults} from '../../../types/prompts/Defaults';
import './IntegrationDialogBase.scss';

type IntegrationDialogBaseProps = {
  isOpen: boolean;
  prompts: any;
  onClose: () => void;
  isPending: boolean;
  onSubmit: (answers: Array<Answer>) => Promise<void>;
  title?: string;
  submitText: string;
  defaults?: Defaults;
  'data-testid'?: string;
};

export const IntegrationDialogBase = ({
  isOpen,
  prompts,
  onClose,
  isPending,
  onSubmit,
  title,
  submitText,
  defaults,
  'data-testid': dataTestID,
}: IntegrationDialogBaseProps) => {
  const intl = useIntl();
  const renderButtons = (isSubmitting: boolean, isValid: boolean, isDirty: boolean) => (
    <div className="integration-dialog-base__actions">
      <FormButton
        buttonRole="secondary"
        value={intl.formatMessage({id: 'form.cancel'})}
        onClick={onClose}
        type="button"
      />
      <FormButton
        buttonRole="primary"
        value={submitText}
        type="submit"
        disabled={isSubmitting || !isValid || !isDirty}
      />
    </div>
  );

  return (
    <Dialog
      open={isOpen}
      BackdropProps={{
        invisible: true,
      }}
      maxWidth={false}
      className="integration-dialog-base"
      data-testid={dataTestID}
      id={dataTestID}
    >
      {!isPending && prompts && (
        <>
          {title && <h2 className="integration-dialog-base__title">{title}</h2>}
          <DynamicForm
            prompts={prompts}
            onSubmit={onSubmit}
            defaults={defaults}
            renderButtons={renderButtons}
          />
        </>
      )}
    </Dialog>
  );
};
