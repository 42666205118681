import React, {useState, createContext} from 'react';

export const ISPFormContext = createContext();

export const ISPFormProvider = ({children}) => {
  const [hasError, setHasError] = useState(false);

  return (
    <ISPFormContext.Provider
      value={{
        hasError,
        setHasError,
      }}
    >
      {children}
    </ISPFormContext.Provider>
  );
};
