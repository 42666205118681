import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {RootState} from '..';
import URL from '../../config/url';
import {IntegrationRole} from '../../types/integrations/IntegrationResponse';

type SolutionsState = {
  categories: {
    [k in IntegrationRole]: string[];
  };
};

const initialState: SolutionsState = {
  categories: {
    PUBLIC: [],
    ORGANIZATION: [],
    PRIVATE: [],
  },
};

export const fetchSolutionCategories = createAsyncThunk(
  'marketplace-solutions/fetch-categories',
  async (role: IntegrationRole) => {
    const {data} = await axios.get<{body: string[]}>(
      URL.MARKETPLACE_SOLUTIONS_CATEGORIES,
      {
        params: {
          marketplaceRole: role,
        },
      }
    );
    return data.body;
  }
);

const solutionsSlice = createSlice({
  name: 'marketplace-solutions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSolutionCategories.fulfilled, (state, action) => {
      const role = action.meta.arg;
      state.categories[role] = action.payload;
    });
  },
});

export const {reducer: solutionsReducer} = solutionsSlice;
export const getCategories = (state: RootState) => state.solutions.categories;
