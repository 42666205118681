import {random} from 'lodash';

export const SCALE_FACTOR = 1.02;

// https://konvajs.org/api/Konva.Rect.html
export const SELECTED_FILL = '#0091FF';
export const DEFAULT_FILL = 'yellow';
export const PREDICTION = '#FFADFF';

export const TAG_FILL = 'gray';
export const TAG_SELECTED_FILL = 'white';

export const TEXT_SELECTED_COLOR = 'white';
export const TEXT_COLOR = 'black';

export const DEFAULT = {
  stroke: DEFAULT_FILL,
  strokeWidth: 1,
  shadowBlur: 0,
  shadowColor: 'black',
  shadowOffset: {x: 1, y: 1},
  shadowOpacity: 0.5,
};

export const BOX_LABEL_DEFAULT = {
  ...DEFAULT,
};

export const BOX_LABEL_PREDICTION = {
  ...DEFAULT,
  stroke: PREDICTION,
};

export const BOX_LABEL_SELECTED = {
  ...DEFAULT,
  stroke: SELECTED_FILL,
};

export const BOX_LABEL_TRANSFORM = {
  ...DEFAULT,
  stroke: 'rgba(0,0,0,0)',
};

export const TEXT_LABEL = {
  fill: TEXT_COLOR,
  opacity: 1,
  fontFamily: 'sans-serif',
  fontSize: 14,
  padding: 5,
  ellipsis: true,
};

// https://konvajs.org/api/Konva.Path.html
export const TAG_ICON = {
  scale: {
    x: 0.75,
    y: 0.75,
  },
  x: 3,
  data:
    'M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7z',
};
export const DELETE_ICON = {
  fill: 'red',
  scale: {
    x: 0.85,
    y: 0.85,
  },
  data: 'M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z',
};

// https://konvajs.org/api/Konva.Rect.html
export const SELECTION_BOX = {
  ...DEFAULT,
  dash: [8, 8],
  opacity: 1,
};

// https://konvajs.org/api/Konva.Transformer.html
export const TRANSFORMER = {
  borderStroke: DEFAULT_FILL,
  rotateEnabled: false,
  borderDash: [8, 8],
  keepRatio: false,
  strokeWidth: 1,
  dash: [5, 5],
  flipEnabled: false, //does not work
};

export const ANNOTATOR_MIN_ID = 1000000;
// Konva works with string type id.
// Have to maintain it until it is saved on the BE.
export const genId = (prefix?: string) =>
  `${prefix}_${random(ANNOTATOR_MIN_ID, 10000000)}`;
