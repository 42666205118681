import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router';
import {PrivateRoutes} from '../../config/routes';
import {getProjectDataSet, projectFetch} from '../../store/project';
import {projectListFetchReplace} from '../../store/projectList';
import {viewCloseEditProjectForm} from '../../store/view';
import {DataPrepare} from '../DataPrepare';
import {DataViewer} from '../DataViewer';
import {FlowGraph} from '../Flowgraph';
import {CreateInferenceAppPage} from '../InferenceApp/CreateInferenceAppPage';
import {ViewInferenceAppPage} from '../InferenceApp/ViewInferenceAppPage';
import {CreateISPFormDialog} from '../ISPForm';
import {MarketplacePublishDialog} from '../MarketplacePublishDialog';
import {ModelViewer} from '../ModelViewer/ModelViewer';
import {CreateDeployment, ProdDashboard} from '../ProdDeployView';
import {InferenceDetails} from '../ProdDeployView/InferenceDetails/InferenceDetails';
import StageView from '../StageView/StageView';
import {TestDeployProvider, TestDeployView} from '../TestDeployView';
import {TestComparison} from '../TestDeployView/TestComparison';
import {CreatePostProcessor} from '../PrePostProcessing/PostProcessing/CreatePostProcessor';
import {EditPostProcessor} from '../PrePostProcessing/PostProcessing/EditPostProcessor';
import {CreatePreProcessor} from '../PrePostProcessing/PreProcessing/CreatePreProcessor';
import {EditPreProcessor} from '../PrePostProcessing/PreProcessing/EditPreProcessor';
import './ProjectWindow.scss';
import {Retrain} from '../ProdDeployView/Retrain/Retrain';

class ProjectWindow extends Component {
  getProjectId() {
    return this.props.match.params.projectId;
  }

  componentDidMount() {
    const pathname = this.props.location.pathname;
    const inferencePath = pathname.includes('/inference');

    if (!inferencePath) {
      this.props.projectFetch(this.getProjectId(), true);
      this.props.projectListFetchReplace();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.getProjectId() !== prevProps.match.params.projectId) {
      const pathname = this.props.location.pathname;
      const inferencePath = pathname.includes('/inference');

      if (!inferencePath) {
        this.props.projectFetch(this.getProjectId(), true);
      }
    }
  }

  componentWillUnmount() {
    this.props.viewCloseEditProjectForm();
  }

  render() {
    const id = this.getProjectId();
    return (
      <div className="project-window">
        <MarketplacePublishDialog projectId={this.getProjectId()} />
        <ModelViewer projectId={id} />
        <Switch>
          <Route
            path={PrivateRoutes.DESIGN}
            render={() => <StageView projectId={id} />}
          />
          <Route
            path={PrivateRoutes.DATAVIEWER}
            render={() => <DataViewer projectId={id} />}
          />
          <Route path={PrivateRoutes.DATAPREPARE} render={() => <DataPrepare />} />
          <Route path={PrivateRoutes.FLOWGRAPH} render={() => <FlowGraph />} />
          <Route
            path={PrivateRoutes.TEST_COMPARE}
            render={_props => (
              <TestDeployProvider>
                <TestComparison />
              </TestDeployProvider>
            )}
          />
          <Route
            path={PrivateRoutes.TEST}
            render={props => (
              <TestDeployProvider>
                <TestDeployView {...props} projectId={id} />
              </TestDeployProvider>
            )}
          />
          <Route
            path={PrivateRoutes.DEPLOY}
            render={props => <CreateDeployment {...props} />}
          />
          <Route
            path={PrivateRoutes.DEPLOY_INFERENCE_APP}
            render={props => <CreateDeployment {...props} />}
          />
          <Route
            path={PrivateRoutes.DEPLOY_ALL}
            render={props => <ProdDashboard {...props} />}
          />
          <Route
            path={PrivateRoutes.CONFIGURE_INFERENCE_APP}
            render={props => (
              <TestDeployProvider>
                <CreateInferenceAppPage {...props} />
              </TestDeployProvider>
            )}
          />
          <Route
            path={PrivateRoutes.CREATE_INFERENCE_APP}
            render={props => (
              <TestDeployProvider>
                <CreateInferenceAppPage {...props} />
              </TestDeployProvider>
            )}
          />
          <Route
            path={PrivateRoutes.VIEW_TEST_INFERENCE_APP}
            render={props => (
              <TestDeployProvider>
                <ViewInferenceAppPage {...props} />
              </TestDeployProvider>
            )}
          />
          <Route
            path={PrivateRoutes.VIEW_DEPLOYMENT_INFERENCE_APP}
            render={props => <ViewInferenceAppPage {...props} />}
          />
          <Route
            path={PrivateRoutes.INFERENCE_APP_DETAILS}
            render={props => <InferenceDetails {...props} />}
          />
          <Route
            path={PrivateRoutes.DEPLOYMENT_INFERENCE_APP_DETAILS}
            render={props => <InferenceDetails {...props} />}
          />
          <Route
            path={PrivateRoutes.POSTPROCESSOR_NEW}
            render={props => <CreatePostProcessor {...props} />}
          />
          <Route
            path={PrivateRoutes.POSTPROCESSOR_EDIT}
            render={props => <EditPostProcessor {...props} />}
          />
          <Route
            path={PrivateRoutes.PREPROCESSOR_NEW}
            render={props => <CreatePreProcessor {...props} />}
          />
          <Route
            path={PrivateRoutes.PREPROCESSOR_EDIT}
            render={props => <EditPreProcessor {...props} />}
          />
          <Route
            path={PrivateRoutes.DEPLOYMENT_RETRAIN}
            render={props => <Retrain {...props} />}
          />
        </Switch>
        <CreateISPFormDialog projectId={id} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dataSet: getProjectDataSet(state),
});

const mapDispatchToProps = {
  projectFetch,
  projectListFetchReplace,
  viewCloseEditProjectForm,
};

const HOC = connect(mapStateToProps, mapDispatchToProps)(ProjectWindow);

export {HOC as ProjectWindow};
