import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ViewDetailsIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="18px"
          height="17px"
          viewBox="0 0 18 17"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-847.000000, -7157.000000)"
            >
              <g id="Model-Action-Icons" transform="translate(56.000000, 6308.000000)">
                <g id="row" transform="translate(0.000000, 807.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="model-actions-/-view-details-/-Enabled,-Focus"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M12.8398438,8.140625 L12.8398438,11.5 L16.1601562,11.5 L16.1601562,13.140625 L12.8398438,13.140625 L12.8398438,16.5 L11.1601562,16.5 L11.1601562,13.140625 L7.83984375,13.140625 L7.83984375,11.5 L11.1601562,11.5 L11.1601562,8.140625 L12.8398438,8.140625 Z M6.08203125,6.4609375 C7.72266445,4.8203043 9.68879062,4 11.9804688,4 C14.2721469,4 16.238273,4.81379395 17.8789062,6.44140625 C19.5195395,8.06901855 20.3398438,10.0286344 20.3398438,12.3203125 L20.3398438,19 C20.3398438,19.4427105 20.177085,19.8268213 19.8515625,20.1523438 C19.52604,20.4778662 19.1289086,20.640625 18.6601562,20.640625 L11.9804688,20.640625 C9.68879062,20.640625 7.7291748,19.8268311 6.1015625,18.1992188 C4.4739502,16.5716064 3.66015625,14.6119906 3.66015625,12.3203125 C3.66015625,10.0286344 4.46743984,8.07552891 6.08203125,6.4609375 Z M7.29296875,17.046875 C8.59505859,18.3489648 10.1640533,19 12,19 C13.8359467,19 15.4049414,18.3489648 16.7070312,17.046875 C18.0091211,15.7447852 18.6601562,14.1692801 18.6601562,12.3203125 C18.6601562,10.4713449 18.0091211,8.89583984 16.7070312,7.59375 C15.4049414,6.29166016 13.8359467,5.640625 12,5.640625 C10.1640533,5.640625 8.59505859,6.29166016 7.29296875,7.59375 C5.99087891,8.89583984 5.33984375,10.4713449 5.33984375,12.3203125 C5.33984375,14.1692801 5.99087891,15.7447852 7.29296875,17.046875 Z"
                        id="view-details"
                        fill="currentColor"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

ViewDetailsIcon.propTypes = {
  className: PropTypes.string,
};

export default ViewDetailsIcon;
