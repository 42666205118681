import {useEffect, useState} from 'react';

type ResponsivePageSizeConfig = {
  containerRef: React.RefObject<HTMLElement>;
  mode: 'grid' | 'list';
  gridDimension: {
    width: number;
    height: number;
    marginX: number;
    marginY: number;
  };
  listDimension: {
    height: number;
    marginY: number;
  };
};

const MIN_PAGE_SIZE = 2;

export const useResponsivePageSize = ({
  containerRef,
  mode,
  gridDimension,
  listDimension,
}: ResponsivePageSizeConfig) => {
  const [pageSize, setPageSize] = useState<number | null>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const updatePageSize = () => {
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        if (mode === 'grid') {
          const {width, height, marginX, marginY} = gridDimension;
          const itemsPerRow = Math.floor(containerWidth / (width + marginX));
          const rowsPerPage = Math.floor(containerHeight / (height + marginY));
          const newPageSize = Math.max(itemsPerRow * rowsPerPage, MIN_PAGE_SIZE);
          setPageSize(newPageSize);
        } else if (mode === 'list') {
          const {height, marginY} = listDimension;
          const newPageSize = Math.max(
            Math.floor(containerHeight / (height + marginY)),
            MIN_PAGE_SIZE
          );
          setPageSize(newPageSize);
        }
      };

      updatePageSize();
      const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        entries.forEach(entry => {
          if (entry.target === container) {
            updatePageSize();
          }
        });
      });

      resizeObserver.observe(container);
      return () => {
        resizeObserver.unobserve(container);
      };
    }
  }, [containerRef, mode, gridDimension, listDimension]);

  return pageSize;
};
