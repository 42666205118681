const ERROR = {
  PROJECT_NAME_REQUIRED: () => 'Project name is a required field',
  PROJECT_NAME_TOO_LONG: () => 'Project name is too long',
  PROJECT_LOAD_FAILED: () => 'Failed to load project',
  PROJECT_UPDATE_FAILED: () => 'Failed to update project',
  PROJECT_CREATE_FAILED: name => `Failed to create project ${name}`,
  MODEL_DELETE_FAILED: () => `Failed to delete model`,
  PROJECT_DELETE_FAILED: () => `Failed to delete project`,
  ISP_NAME_REQUIRED: () => `ISP name is a required field`,
  ISP_SERVER_ERROR: () => `Failed to fetch transformations`,
  ISP_UPDATE_ERROR: name => `Failed to update ${name}.`,
  ISP_DELETE_ERROR: () => `Failed to delete transformation`,

  FIELD_REQUIRED: () => 'This field is required',
  INVALID_URL: () => 'Please enter a valid URL',
  INVALID_SHAPE: () => 'All values must be valid numbers',
  INVALID_NUMBER: () => 'Please enter a valid number',
  INVALID_INTEGER: () => 'Please enter a valid integer',
  INVALID_EMAIL: () => 'Please enter a valid email',

  MINIMUM_CHARACTERS_REQUIRED: () => '8 characters minimum',
  LOWERCASE_CHARACTER_REQUIRED: () => 'One lowercase character',
  UPPERCASE_CHARACTER_REQUIRED: () => 'One uppercase character',
  SPECIAL_CHARACTER_REQUIRED: () => 'One special character',
  NUMBER_REQUIRED: () => 'One number',
  PASSWORDS_MUST_MATCH: 'Passwords must match',

  INVALID_FLOAT_VALUES: () => 'Please enter a valid float or group of float values',

  MIN_NUMBER: obj => `Value should be greater than or equal to ${obj.min}`,
  MAX_NUMBER: obj => `Value should be less than or equal to ${obj.max}`,
};

export default ERROR;
