import React, {Component} from 'react';
import PropTypes from 'prop-types';

class StopIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="10px"
          height="10px"
          viewBox="0 0 10 10"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-851.000000, -7874.000000)"
            >
              <g id="Model-Action-Icons" transform="translate(56.000000, 7022.000000)">
                <g id="row" transform="translate(0.000000, 807.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="model-actions-/-view-details-/-Enabled,-Focus-model-actions-/-stop-/-Enabled,-Focus"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <polygon
                        id="Stop"
                        fill="currentColor"
                        points="7 7 17 7 17 17 7 17"
                      ></polygon>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

StopIcon.propTypes = {
  className: PropTypes.string,
};

export default StopIcon;
