import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {DynamicForm} from '../DynamicForm';
import cn from 'classnames';
import FormButton from '../../base-components/StudioButton/FormButton';
import {StudioPopover} from '../../base-components/StudioPopover';
import {PromptProps} from '../../model/promptPropTypes';
import {Alert} from '@material-ui/lab';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {setStagesPrompts} from '../../store/stages';
import './StageAssistantForm.scss';

export const StageAssistantForm = ({
  messages = undefined,
  prompts,
  onSubmit,
  defaults,
  title,
  tooltip,
  onCancel,
  className,
  alert,
  notifyType,
  submitMessageId,
  customRenderButtons,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleCancel = useCallback(() => {
    dispatch(
      setStagesPrompts({
        isOpen: false,
        isCancelled: true,
        notifyType,
      })
    );

    onCancel();
  }, [onCancel, notifyType, dispatch]);

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.code === 'Escape') {
        handleCancel();
      }
    };

    document.addEventListener('keydown', handleKeyPress, false);

    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  }, [handleCancel]);

  useEffect(() => {
    if (notifyType) {
      dispatch(
        setStagesPrompts({
          isOpen: true,
          notifyType,
        })
      );
      return () => {
        dispatch(
          setStagesPrompts({
            isOpen: false,
            isCancelled: false,
            notifyType,
          })
        );
      };
    }
  }, [notifyType, dispatch]);

  const handleSubmit = answers => {
    onSubmit(
      answers.map(({key, type, value, values}) => ({
        key,
        type,
        value,
        values,
      }))
    );
  };

  const renderButtons = (isSubmitting, isValid) => {
    return (
      <div className="stage-assistant-form__actions">
        {onCancel && (
          <FormButton
            buttonRole="secondary"
            value={intl.formatMessage({id: 'form.cancel'})}
            onClick={handleCancel}
            type="button"
          />
        )}
        <FormButton
          buttonRole="primary"
          value={intl.formatMessage({id: submitMessageId ?? 'form.saveAndContinue'})}
          type="submit"
          disabled={isSubmitting || !isValid}
        />
      </div>
    );
  };
  return (
    <div
      className={cn('stage-assistant-form', className)}
      data-testid="stage-assistant-form"
      id="stage-assistant-form"
    >
      {title && (
        <h2 className="stage-assistant-form__title">
          {title}
          {tooltip && <StudioPopover infoMessage={tooltip} />}
        </h2>
      )}
      {alert && (
        <Alert className="stage-assistant-form__alert" severity="warning">
          {alert}
        </Alert>
      )}
      {messages?.map((message, index) => (
        <p className="stage-assistant-form__message" key={index}>
          {message.text}
        </p>
      ))}
      <DynamicForm
        prompts={prompts}
        defaults={defaults}
        onSubmit={handleSubmit}
        renderButtons={(isSubmitting, isValid, dirty, values) => {
          if (customRenderButtons) {
            return customRenderButtons(isSubmitting, isValid, dirty, values);
          } else {
            return renderButtons(isSubmitting, isValid);
          }
        }}
        className="stage-assistant-form__form"
      />
    </div>
  );
};

StageAssistantForm.propTypes = {
  prompts: PromptProps.isRequired,
  onSubmit: PropTypes.func,
  defaults: PropTypes.object,
  title: PropTypes.node,
  onCancel: PropTypes.func,
  className: PropTypes.string,
  submitMessageId: PropTypes.string,
  customRenderButtons: PropTypes.func,
};

StageAssistantForm.defaultProps = {
  prompts: [],
  onSubmit: () => {},
  defaults: null,
  title: null,
  onCancel: null,
  className: null,
};
