import cn from 'classnames';
import {ImageProps} from 'material-ui-image/lib/components/Image/Image';
import * as React from 'react';
import {StudioImageThumbnail} from '../../base-components/StudioImageThumbnail';
import Image from 'material-ui-image';
import './DataViewerItem.scss';

export type DataViewerItemProps = {
  adornment?: React.ReactNode;
  className?: string;
  onClick?: ImageProps['onClick'];
  url: string | undefined | null | File;
  width: number;
  height: number;
  position: number;
  selectable?: boolean;
};

export const DataViewerItem = ({
  adornment,
  className,
  height,
  onClick,
  url = '',
  width,
  position,
  selectable = false,
}: DataViewerItemProps) => {
  return (
    <div
      className={cn('data-viewer-item', className, {
        'data-viewer-item_selectable': selectable,
      })}
      style={{width: `${width}px`, height: `${height}px`}}
      onClick={onClick}
    >
      {url instanceof File ? (
        <Image
          className="data-viewer-item__image"
          src={URL.createObjectURL(url)}
          imageStyle={{width, height, objectFit: 'cover'}}
          disableSpinner={true}
          color=""
        />
      ) : (
        <StudioImageThumbnail
          width={width}
          height={height}
          src={String(url)}
          position={position}
        />
      )}
      {adornment}
    </div>
  );
};
