import React, {useRef, useState} from 'react';
import {Button, IconButton} from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import GridIcon from '@material-ui/icons/Apps';
import DeleteIcon from '@material-ui/icons/Delete';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {DataViewerLabel} from '../../DataViewer/DataViewerLabel';
import {
  FileViewer,
  FileItem,
  ViewId,
  ImageAdornment,
  FILE_IMAGE_TYPES,
} from '../FileViewer/FileViewer';
import {DataPreparationOrigin} from '../../../types/dataset/DataPreparationContainer';
import {useIntl} from 'react-intl';
import './DataReview.scss';

export type DataReviewProps = {
  title: string;
  fileItems: FileItem[];
  projectId: string;
  origin: DataPreparationOrigin;
  readOnly?: boolean;
  hideSelectAll?: boolean;
  onSelectAll?: () => void;
  onClearAll?: () => void;
  onChange?(files: File[]): void;
  onSelect?(id: string): void;
  onDelete?(): void;
};

export const DataReview = ({
  title,
  projectId,
  fileItems,
  readOnly,
  origin,
  hideSelectAll,
  onSelectAll,
  onClearAll,
  onChange,
  onSelect,
  onDelete,
}: DataReviewProps) => {
  const intl = useIntl();
  const parentRef = useRef<HTMLDivElement>(null);
  const [view, setView] = useState<ViewId>('grid');

  const handleChange = (newFiles: File[]) => {
    onChange?.(newFiles);
  };

  const handleUpload = () => {
    const input = document.createElement('input');
    input.style.display = 'none';
    input.multiple = true;
    input.type = 'file';
    input.accept = FILE_IMAGE_TYPES.join();
    input.addEventListener('change', () => {
      handleChange(Array.from(input.files!));
      parentRef.current?.removeChild(input);
    });
    parentRef.current?.appendChild(input);
    input.click();
  };

  return (
    <div className="data-review" ref={parentRef}>
      <div className="data-review__content">
        <h4>{title}</h4>
        <div className="data-review__menu">
          <DataViewerLabel
            className="data-review__label"
            label={intl.formatMessage({id: 'dataPrep.review.total.images'})}
            count={fileItems.length}
          />
          <IconButton
            size="medium"
            onClick={() => {
              setView(view === 'grid' ? 'list' : 'grid');
            }}
          >
            {view === 'grid' ? <ListIcon /> : <GridIcon />}
          </IconButton>
          {!readOnly && (
            <IconButton
              size="medium"
              onClick={onDelete}
              color="secondary"
              disabled={!fileItems.find(item => item.selected)}
            >
              <DeleteIcon />
            </IconButton>
          )}
          {!readOnly && (
            <FormButton buttonRole="secondary" value="Add More" onClick={handleUpload} />
          )}
        </div>
        {!hideSelectAll && (
          <div className="data-review__sub-menu">
            <Button color="primary" onClick={() => onSelectAll?.()}>
              Select All
            </Button>
            <Button
              color="primary"
              onClick={() => onClearAll?.()}
              disabled={!fileItems.some(item => item.selected)}
            >
              Clear All
            </Button>
          </div>
        )}
        <FileViewer
          projectId={projectId}
          items={fileItems}
          view={view}
          onSelect={(id: string) => onSelect?.(id)}
          renderAdornment={({selected, isLocal}) => (
            <ImageAdornment selected={selected} isLocal={isLocal} />
          )}
          origin={origin}
        />
      </div>
    </div>
  );
};
