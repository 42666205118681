import React, {Component} from 'react';
import PropTypes from 'prop-types';
import StageAnimation from '../StageAnimation/StageAnimation';
import Util from '../../../util';
import {toast} from '../../../base-components/StudioToast';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import axios from 'axios';
import URL from '../../../config/url';
import {StageAssistantForm} from '../../StageAssistantForm';
import {connect} from 'react-redux';
import {addListener, removeListener} from '../../../store/statusChecker';
import {
  viewDisableTermination,
  viewEnableTermination,
  viewSetProject,
} from '../../../store/view';

class ModelOptimizationAction extends Component {
  constructor(props) {
    super(props);

    this.stageAnimation = new StageAnimation();
    this.optimizeModel = this.optimizeModel.bind(this);
    this.clarifyModelOptimization = this.clarifyModelOptimization.bind(this);
    this.handleOptimizationResponse = this.handleOptimizationResponse.bind(this);
    this.handleClarifyResponse = this.handleClarifyResponse.bind(this);
    this.monitorStatus = this.monitorStatus.bind(this);
    this.processOptimizationProgress = this.processOptimizationProgress.bind(this);
    this.terminateOptimization = this.terminateOptimization.bind(this);
    this.finishOptimization = this.finishOptimization.bind(this);
    this.cancelOptimization = this.cancelOptimization.bind(this);
  }

  componentDidMount() {
    if (this.props.taskProgress) {
      if (this.props.taskProgress.prompts?.length) {
        this.stageAnimation.shiftStages('OPTIMIZE_MODEL');
        this.showAssistant(this.props.taskProgress);
      } else {
        this.monitorStatus();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.taskProgress && this.props.taskProgress) {
      if (this.props.taskProgress.prompts?.length) {
        this.stageAnimation.shiftStages('OPTIMIZE_MODEL');
        this.showAssistant(this.props.taskProgress);
      } else {
        this.monitorStatus();
      }
    }
  }

  componentWillUnmount() {
    this.props.removeStatusListener('OPTIMIZE_MODEL', this.processOptimizationProgress);
    this.props.viewSetProject({disableEditData: false, disableFineTuningStage: false});
  }

  render() {
    return null;
  }

  optimizeModel(caAnswers) {
    this.stageAnimation.markInProgress('OPTIMIZE_MODEL');

    if (Array.isArray(caAnswers)) {
      this.clarifyModelOptimization(null, caAnswers);
    } else {
      axios
        .get(URL.MODEL_OPTIMIZE, {
          params: {
            projectId: this.props.projectId,
          },
        })
        .then(response => this.handleOptimizationResponse(response.data))
        .catch(error => toast.error(`model optimization error: ${error.message}`));
    }
  }

  handleOptimizationResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.cancelOptimization();
    } else {
      this.showAssistant(response);
    }
  }

  monitorStatus() {
    this.props.viewEnableTermination();
    this.stageAnimation.markInProgress('OPTIMIZE_MODEL');
    this.props.addStatusListener('OPTIMIZE_MODEL', this.processOptimizationProgress);
    this.props.viewSetProject({disableEditData: true, disableFineTuningStage: true});
  }

  finishOptimization() {
    if (this.props.onFinish) {
      this.props.onFinish('FINETUNE_MODEL');
    }
  }

  cancelOptimization() {
    if (this.props.onFinish) {
      this.props.onFinish('OPTIMIZE_MODEL');
    }
  }

  showAssistant(response) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.display(
        this.getQuestionsForm(response.prompts, response.defaults),
        'OPTIMIZE_MODEL',
        null,
        this.terminateOptimization
      );
    }
  }

  getQuestionsForm(questions, defaults) {
    return (
      <StageAssistantForm
        prompts={questions}
        defaults={defaults}
        onSubmit={this.clarifyModelOptimization}
        onCancel={this.terminateOptimization}
        title="Select optimization parameters"
        notifyType="OPTIMIZE_MODEL"
      />
    );
  }

  clarifyModelOptimization(answersArray, caAnswers) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.hideStage('OPTIMIZE_MODEL');
    }
    const clarificationRequest = {
      projectId: this.props.projectId,
      answers: answersArray,
      caAnswers,
    };

    axios
      .post(URL.MODEL_OPTIMIZE_CLARIFY, clarificationRequest, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(response => this.handleClarifyResponse(response.data))
      .catch(error => toast.error(`model optimization error: ${error.message}`));
  }

  handleClarifyResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.finishOptimization();
    } else if (response.prompts) {
      this.showAssistant(response);
    } else {
      this.monitorStatus();
    }
  }

  processOptimizationProgress(status) {
    ModelOptimizationAction.displayOptimizationProgress(status);
    if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
      this.props.removeStatusListener('OPTIMIZE_MODEL', this.processOptimizationProgress);
      this.props.viewSetProject({disableEditData: false, disableFineTuningStage: false});
      this.finalizeOptimization();
    }
  }

  static displayOptimizationProgress(status) {
    let progressText = document.getElementById('optimize-action-progress');
    if (progressText) {
      progressText.innerText = status.statusMessage;
    }
  }

  finalizeOptimization() {
    this.props.viewDisableTermination();
    axios
      .get(URL.MODEL_OPTIMIZE_FINALIZE, {
        params: {
          projectId: this.props.projectId,
        },
      })
      .then(res => {
        if (res.data.prompts) {
          this.showAssistant(res);
        } else {
          this.finishOptimization();
          this.props.viewSetProject({
            disableEditData: false,
            disableFineTuningStage: false,
          });
        }
      })
      .catch(error => toast.error(`model optimization error: ${error.message}`));
  }

  terminateOptimization() {
    this.props.viewDisableTermination();
    return axios
      .get(URL.OPERATION_CANCEL('OPTIMIZE_MODEL'), {
        params: {projectId: this.props.projectId},
      })
      .then(res => {
        this.props.assistantPanel.current.hideStage('OPTIMIZE_MODEL');
        this.props.removeStatusListener(
          'OPTIMIZE_MODEL',
          this.processOptimizationProgress
        );
        this.props.viewSetProject({
          disableEditData: false,
          disableFineTuningStage: false,
        });
        this.cancelOptimization();
      })
      .catch(error => toast.error(`cancel optimization error: ${error.message}`));
  }
}

ModelOptimizationAction.propTypes = {
  projectId: PropTypes.string.isRequired,
  assistantPanel: PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)})
    .isRequired,
  onFinish: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addStatusListener: addListener,
  removeStatusListener: removeListener,
  viewSetProject,
  viewEnableTermination,
  viewDisableTermination,
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(
  ModelOptimizationAction
);
