import React from 'react';
import BeforeImage1 from './images/wdr-before.png';
import AfterImage from './images/wdr-after.png';
import './Help.scss';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>WDR-Tone Mapping</h4>
      <div className="flow-graph__help_content_detail">
        <h6>Wide Dynamic Range Tone-Mapping</h6>
        <p>The goals of tone mapping can be differently stated depending on the application. In some cases producing just aesthetically pleasing images is the main goal, while other applications might emphasize reproducing as many image details as possible or maximizing the image contrast. The goal in realistic rendering applications might be to obtain a perceptual match between a real scene and a displayed image even though the display device is not able to reproduce the full range of luminance values.</p>
        <p>Various tone mapping operators have been developed in recent years. They all can be divided in two main types:</p>
        <ol>
          <li>Global (or spatially uniform) operators: they are nonlinear functions based on the luminance and other global variables of the image. Once the optimal function has been estimated according to the image, every pixel in the image is mapped in the same way, independent of the value of surrounding pixels in the image. Those techniques are simple and fast (since they can be implemented using look-up tables), but they can cause a loss of contrast. Examples of common global tone mapping methods are contrast reduction and color inversion.</li>
          <li>Estimate a threshold;</li>
          <li>Local (or spatially varying) operators: the parameters of the nonlinear function change in each pixel, according to features extracted from the surrounding parameters. In other words, the effect of the algorithm changes in each pixel according to the local features of the image. Those algorithms are more complicated than the global ones; they can show artifacts (e.g. halo effect and ringing); and the output can look unrealistic, but they can (if used correctly) provide the best performance, since human vision is mainly sensitive to local contrast.</li>
        </ol>
        <p>The wdr tone mapping is based on gaussian, histogram adjustment. Firstly, the gaussian blur image is computed, later the histogram values are computed correspondingly for the given image. After computation of histogram based on histogram values the pixel value with saturation value and the pixel value corresponding to median data and to ninety percentage of data are computed and from them the target pixel is computed which will further be used to calculate the gains for each pixel and then these gains are multiplied with corresponding pixels to obtain the output. The input for this algorithm is yuv image as y in yuv being the luminance will be enough to calculate the gains. Gains can also be computed through RGB image, but it should be done for each channel individually, but when YUV image is used, the gain for Y component in YUV can contribute for tone mapping of entire image. That is why the image input for the algorithm is chosen as YUV, and the output of the algorithms is also YUV image.</p>
        <div className="help_images">
          <figure>
            <img src={BeforeImage1} alt="before" />
            <figcaption>Input</figcaption>
          </figure>
          <figure>
            <img src={AfterImage} alt="after" />
            <figcaption>Output</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};
