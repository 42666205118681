import React from 'react';
import './GridViewerItem.scss';

type GridViewerItemProps = {
  children: React.ReactNode;
};

export const GridViewerItem = ({children}: GridViewerItemProps) => (
  <div className="grid-viewer-item">{children}</div>
);
