import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import FormButton from '../../../base-components/StudioButton/FormButton';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import CreateDeploymentAction from '../StageActions/CreateDeploymentAction';
import {toast} from '../../../base-components/StudioToast';
import StagePanel from './StagePanel';
import {TerminateButton} from './TerminateButton';
import {getIsDiabledDeploymentStage} from '../../../store/view';
import './DeploymentPanel.scss';
class DeploymentPanel extends StagePanel {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.terminateCreateDeployment = this.terminateCreateDeployment.bind(this);
  }

  getLockedContent() {
    return (
      <div className="locked-content" data-testid="CREATE_DEPLOYMENT-panel__locked">
        <div className="lock-icon"></div>
        <p className="lock-reason">{this.props.stage.lockReason}</p>
      </div>
    );
  }

  getInactiveContent() {
    return (
      <div className="inactive-content">
        <div className="inactive-container">
          <FormButton
            buttonRole="secondary"
            value="Create Application"
            onClick={this.onClick}
          />
        </div>
      </div>
    );
  }

  getActiveContent() {
    return (
      <div
        className="active-content deployment-panel"
        data-testid="CREATE_DEPLOYMENT-panel__active"
        id="CREATE_DEPLOYMENT-panel__active"
      >
        <div className="active-container">
          <FormButton
            buttonRole="primary"
            value="Create Application"
            onClick={this.onClick}
          />
        </div>
      </div>
    );
  }

  getInProgressContent() {
    return (
      <div className="stage-panel-status">
        <div className="in-progress-content deployment-panel-in-progress">
          <div
            className="deployment-in-progress-indicator"
            data-testid="CREATE_DEPLOYMENT-panel__in-progress"
            id="CREATE_DEPLOYMENT-panel__in-progress"
          />
          <div className="panel-deployment-active" />
          <div className="panel-title">
            <span className="panel-caption">
              Building Application
              <br />
              <span className="panel-description" id="deployment-action-progress">
                Checking for completeness
              </span>
            </span>
          </div>
        </div>
        <div
          className="in-progress-content deployment-panel stage-panel-buttons"
          data-testid="CREATE_DEPLOYMENT-action-buttons"
          id="CREATE_DEPLOYMENT-action-buttons"
        >
          <TerminateButton
            onClick={() => {
              this.props.openTerminateDialog({
                terminate: this.terminateCreateDeployment,
                title: this.props.intl.formatMessage({id: 'terminate.deployTitle'}),
                message: this.props.intl.formatMessage({
                  id: 'terminate.deployContent',
                }),
              });
            }}
          />
        </div>
      </div>
    );
  }

  getFinishedContent() {
    return (
      <div
        className="finished-content deployment-panel"
        data-testid="CREATE_DEPLOYMENT-panel__finished"
        id="CREATE_DEPLOYMENT-panel__finished"
      >
        <div className="panel-deployment-active" />
        <div className="deployment-panel__finished-container">
          <FormButton
            buttonRole="primary"
            value="Create Application"
            onClick={this.onClick}
          />
        </div>
      </div>
    );
  }

  getOverlay() {
    return (
      <div
        className={
          'stage-panel-overlay' + (this.props.stage.active ? ' active' : ' inactive')
        }
        onClick={this.activatePanel}
        id={this.props.stage.type + '-panel-overlay'}
        data-testid={'create-deploy-overlay'}
      />
    );
  }

  onClick() {
    if (this.props.createDeploymentAction.current) {
      this.props.createDeploymentAction.current.initiateCreateDeployment();
    }
  }

  async terminateCreateDeployment() {
    let current = this.props.createDeploymentAction.current;
    if (current) {
      await current.terminateCreateDeployment();
      toast.success({position: 'top', subtitle: 'User terminated application creation'});
    }
  }
}

DeploymentPanel.propTypes = {
  projectId: PropTypes.string.isRequired,
  assistantPanel: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)}),
  ]).isRequired,
  onFinish: PropTypes.func.isRequired,
  createDeploymentAction: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(CreateDeploymentAction)}),
    PropTypes.shape({current: null}),
  ]).isRequired,
};

const mapStateToProps = state => ({
  shouldDisabledViewStage: getIsDiabledDeploymentStage(state),
});

export default injectIntl(connect(mapStateToProps)(DeploymentPanel), {forwardRef: true});
