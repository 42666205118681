import {IconButton} from '@material-ui/core';
import {GetApp} from '@material-ui/icons';
import React, {useEffect, useState, useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {useLocation} from 'react-router';
import {getDeviceList} from '../../api/device/DeviceList';
import {assertResponseBody} from '../../api/request';
import {toast} from '../../base-components/StudioToast';
import {StudioTab, StudioTabPanel, StudioTabs} from '../../base-components/StudioTab';
import {RootState} from '../../store';
import {getProjectData, projectFetch} from '../../store/project';
import {DeploymentDetailsInfo} from '../../types/deployment/DeploymentDetailsInfo';
import {Project} from '../../types/project/Project';
import {UnparsedDate} from '../../types/utility/UnparsedDate';
import {DeployConfigPanel} from './DeployConfigPanel';
import {
  DownloadDetailsDialog,
  DownloadDetailsForm,
} from './DownloadDetailsDialog/DownloadDetailsDialog';
import {NoDeployment} from './NoDeployment';
import {TestDescription} from './TestDescription';
import {TestDetails} from './TestDetails';
import {renderToObject} from './TestDetails/TestDetailsConfig';
import {TestResults} from './TestResults';
import {
  setBaseModel,
  setStreamingDevices,
  setImageSets,
  setGSPList,
  TASK_STATUS,
  useDeploy,
  useDeploymentDetails,
} from './useDeploy';
import './TestDeployView.scss';
import {getImageSets} from '../../api/test/ImageSets';
import {Chat} from '../Chat';
import {ChatConnection} from '../Chat/Chat';
import {getGspList} from '../../api/gsp/GspList';
import {AnalyticsDeploySettings} from './AnalyticDeploySettings';
import {downloadFile, downloadBlob} from './../../utils/downloadFile';

export type TestDeployViewProps = {
  initialProjectData: Project;
  projectId: string;
  fetchProjectData: Function;
};

const emptyProject = {
  id: null,
  name: null,
  description: null,
  icon: 'icons/model-icon.png',
  lastUpdateTime: null,
  dataSet: {
    name: '',
    size: 0,
    creationDate: null,
    dataType: null,
    count: 0,
    histogram: null,
    pageCount: 0,
  },
  model: null,
  transformations: {},
  applications: {},
};

type InferenceResultsProps = {
  isONNXIdle: boolean;
  isTaskInProgress: boolean;
  selectedDeploymentIds: string[];
  deployment: DeploymentDetailsInfo | null;

  chatConnection?: ChatConnection;
};

function InferenceResults({
  isONNXIdle,
  deployment,
  chatConnection,
  isTaskInProgress,
  selectedDeploymentIds,
}: InferenceResultsProps) {
  if (chatConnection) {
    return <Chat connection={chatConnection} />;
  }

  if (selectedDeploymentIds.length > 1) {
    return (
      <NoDeployment message={<FormattedMessage id="test.multipleTestsSelected" />} />
    );
  }

  if (!deployment && !isTaskInProgress && isONNXIdle) {
    return (
      <>
        <NoDeployment
          imageUrl="images/emptyStateNoResults.png"
          message={<FormattedMessage id="test.resultsWillShow" />}
        />
        <AnalyticsDeploySettings />
      </>
    );
  }

  return <TestResults deployment={deployment} isTaskInProgress={isTaskInProgress} />;
}

type DeploymentDetailsProps = {
  selectedDeploymentIds: string[];
  deployment: DeploymentDetailsInfo | null;
};

function DeploymentDetails({selectedDeploymentIds, deployment}: DeploymentDetailsProps) {
  if (selectedDeploymentIds.length > 1) {
    return (
      <NoDeployment message={<FormattedMessage id="test.multipleTestsSelected" />} />
    );
  }

  if (!deployment) {
    return (
      <NoDeployment
        imageUrl="images/emptyStateNoResults.png"
        message={<FormattedMessage id="test.resultsWillShow" />}
      />
    );
  }

  return (
    <div className="test-deploy-view__details">
      <TestDetails ids={[deployment.id]} hideMissingFields />
    </div>
  );
}

const TestDeployViewBase = ({
  initialProjectData,
  projectId,
  fetchProjectData,
}: TestDeployViewProps) => {
  const intl = useIntl();
  const [
    {
      deployTask,
      streamingTask,
      inferenceTask,
      onnxTask,
      selectedDeploymentIds,
      chatConnection,
    },
    dispatch,
  ] = useDeploy();
  const {search: queryParams} = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedPanelTab, setSelectedPanelTab] = useState(0);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isExpanded, setExpanded] = useState(true);

  const projectData = useMemo(
    () =>
      initialProjectData?.id === projectId
        ? initialProjectData
        : ((emptyProject as unknown) as Project),
    [initialProjectData, projectId]
  );
  const firstDeployment = useDeploymentDetails(selectedDeploymentIds?.[0]);
  const deployment = useMemo(
    () => (selectedDeploymentIds.length > 1 ? null : firstDeployment),
    [firstDeployment, selectedDeploymentIds]
  );

  const isTaskInProgress =
    [
      deployTask.status,
      inferenceTask.status,
      streamingTask.status,
      onnxTask.status,
    ].includes(TASK_STATUS.IN_PROGRESS) || !!chatConnection;

  const baseModel = projectData?.model?.baseModel ?? '';

  useEffect(() => {
    fetchProjectData(projectId);
  }, [projectId, fetchProjectData]);

  // If the user changes to the new test deploy form configuration, then we change the tab to 'test results'
  useEffect(() => {
    if (selectedPanelTab === 0) {
      setSelectedTab(0);
    }
  }, [selectedPanelTab]);

  useEffect(() => {
    const fetchStreamingDevicesList = async () => {
      const devices = await assertResponseBody(getDeviceList());
      if (devices) {
        dispatch(setStreamingDevices(devices));
      }
    };

    fetchStreamingDevicesList();
  }, [dispatch]);

  useEffect(() => {
    // I have no idea how it works, I can only copy and paste stuff
    const fn = async () => {
      const imageSets = await assertResponseBody(getImageSets(projectId));
      if (imageSets) {
        dispatch(setImageSets(imageSets));
      }
    };
    fn();
  }, [dispatch, projectId]);

  const fetchImageSets = async () => {
    const imageSets = await assertResponseBody(getImageSets(projectId));
    if (imageSets) {
      dispatch(setImageSets(imageSets));
    }
  };

  const fetchGspList = async (projectId: string, applicationId: string) => {
    const gspList = await assertResponseBody(getGspList(projectId, applicationId));
    if (gspList) {
      dispatch(setGSPList(gspList));
    }
  };

  useEffect(() => {
    dispatch(setBaseModel(baseModel));
  }, [dispatch, baseModel]);

  useEffect(() => {
    const queryParamsMap = new URLSearchParams(queryParams);
    const initialTestId = queryParamsMap.get('testId');
    if (initialTestId) {
      // dispatch(setSelectedDeploymentIds([initialTestId]));
      setSelectedTab(1);
      setSelectedPanelTab(1);
    }

    // for tabs on page render
    const tab = queryParamsMap.get('tab');
    if (typeof Number(tab) === 'number' && Number(tab) < 2) {
      setSelectedPanelTab(Number(tab));
    } else {
      setSelectedPanelTab(0);
    }
  }, [queryParams, dispatch]);

  const handleDownload = async (itemsToDownload: DownloadDetailsForm) => {
    if (deployment) {
      if (itemsToDownload.inference) {
        let videoInputs = new Set(['WEB_CAMERA', 'IP_CAMERA', 'FILE']);
        let sourceType = deployment.deploymentInput?.inputSourceType || '-';

        if (deployment.inferenceResults && !videoInputs.has(sourceType)) {
          downloadFile(
            'inferenceResults.json',
            'application/json',
            JSON.stringify(JSON.parse(deployment.inferenceResults), null, 2)
          );
        }
      }

      if (itemsToDownload.appDetails) {
        const application = deployment.applicationId
          ? projectData?.applications?.[deployment.applicationId]
          : undefined;
        const detailsBag = {application, project: projectData, test: deployment, intl};
        const details = renderToObject(detailsBag);

        downloadFile(
          'applicationDetails.json',
          'application/json',
          JSON.stringify(details, null, 2)
        );
      }

      if (itemsToDownload.fullApplication) {
        const downloadUrl = `/studio/gsp/download?deploymentId=${deployment.id}&projectId=${projectId}`;

        try {
          await downloadBlob({
            bubbleUpError: true,
            fileName: 'application.zip',
            blobUrl: encodeURI(downloadUrl),
          });
        } catch (error) {
          toast.error(intl.formatMessage({id: 'test.errorDownloadingFullApplication'}));
        }
      }

      setShowDownloadModal(false);
    }
  };

  let videoInputs = new Set(['WEB_CAMERA', 'IP_CAMERA', 'FILE']);
  let sourceType = deployment?.deploymentInput?.inputSourceType || '-';

  return (
    <div className="test-deploy-view">
      <DownloadDetailsDialog
        isOpen={showDownloadModal}
        onClose={() => setShowDownloadModal(false)}
        onDownload={handleDownload}
        disableInference={!deployment?.inferenceResults || videoInputs.has(sourceType)}
      />
      <div
        className={`test-deploy-view__template test-deploy-view__template--${
          isExpanded ? 'sidebar' : 'no-sidebar'
        }`}
      >
        <TestDescription
          className="test-deploy-view__test-description"
          name={projectData?.name}
          // TODO: correct to project creation time
          createdOn={projectData?.lastUpdateTime as UnparsedDate}
          isExpanded={isExpanded}
          onExpand={() => {
            setExpanded(!isExpanded);
          }}
        />

        <section className="test-deploy-view__tabs">
          <StudioTabs
            className="test-deploy-view__tabs"
            value={selectedTab}
            onChange={(_event, value) => {
              setSelectedTab(value);
            }}
          >
            <StudioTab
              disabled={!Boolean(deployment)}
              label={intl.formatMessage({id: 'test.results'})}
              className="test-deploy-view__tab"
            />
            <StudioTab
              disabled={!Boolean(deployment)}
              label={intl.formatMessage({id: 'test.details'})}
              className="test-deploy-view__tab"
            />
          </StudioTabs>
          {deployment && (
            <IconButton
              className="test-deploy-view__download"
              onClick={() => setShowDownloadModal(true)}
            >
              <GetApp />
            </IconButton>
          )}
        </section>

        <DeployConfigPanel
          className="test-deploy-view__deploy-config-pa nel"
          shouldBeVisible={isExpanded}
          projectData={projectData}
          projectId={projectId}
          applications={Object.values(projectData?.applications ?? [])}
          isTaskInProgress={isTaskInProgress}
          fetchProjectData={fetchProjectData}
          selectedTab={selectedPanelTab}
          onTabChange={setSelectedPanelTab}
          fetchImageSets={fetchImageSets}
          fetchGspList={fetchGspList}
        />

        <section className="test-deploy-view__results">
          <StudioTabPanel
            className="test-deploy-view__tab-panel"
            value={selectedTab}
            index={0}
          >
            <InferenceResults
              deployment={deployment}
              chatConnection={chatConnection}
              isTaskInProgress={isTaskInProgress}
              selectedDeploymentIds={selectedDeploymentIds}
              isONNXIdle={onnxTask.status === TASK_STATUS.IDLE}
            />
          </StudioTabPanel>
          <StudioTabPanel
            className="test-deploy-view__tab-panel"
            value={selectedTab}
            index={1}
          >
            <DeploymentDetails
              deployment={deployment}
              selectedDeploymentIds={selectedDeploymentIds}
            />
          </StudioTabPanel>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  initialProjectData: getProjectData(state),
});

const mapDispatchToProps = {
  fetchProjectData: projectFetch,
};

export const TestDeployView = connect(
  mapStateToProps,
  mapDispatchToProps
)(TestDeployViewBase);
