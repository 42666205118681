import {PostProcessor} from '../../types/postprocessor/PostProcessor';
import {request} from '../request';

export async function getApplicationPostProcessors({
  projectId,
  applicationId,
}: {
  projectId: string;
  applicationId: string;
}) {
  return request<Array<PostProcessor>>({
    method: 'get',
    url: `/postprocessor/get/application/${projectId}?applicationId=${applicationId}`,
  });
}
