// ACTIONS
export const CONSOLE_ADD = 'console/add';
export const CONSOLE_CLEAR = 'console/clear';

// ACTION CREATORS
export const consoleAdd = payload => ({
  type: CONSOLE_ADD,
  payload,
});

export const consoleClear = () => ({
  type: CONSOLE_CLEAR,
});
