import {Button} from '@material-ui/core';
import * as React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {DataViewerLabel} from './DataViewerLabel';
import {GridViewer} from './GridViewer';
import {GridViewerItem} from './GridViewerItem';
import {ItemType} from './ListViewer';
import './LabelViewer.scss';

export type LabelViewerProps = {
  labels?: {label: string; count: number; files: ItemType[]}[];
  labelNameMapper(label: string): string;
  limit: number;
  onLabelClick(label: string): void;
  children: (
    label: string,
    file: ItemType,
    filenames: string[],
    pos: number
  ) => React.ReactNode;
};

export const LabelViewer = ({
  labels = [],
  labelNameMapper,
  limit,
  onLabelClick,
  children,
}: LabelViewerProps) => {
  const intl = useIntl();

  return (
    <div className="label-viewer">
      {labels.map(data => {
        const files = limit ? data.files.slice(0, limit) : data.files;
        const filenames = files.map(file => file.filename);
        const remaining = data.count - limit;
        return (
          <div key={data.label} className="label-viewer__row">
            <DataViewerLabel
              label={labelNameMapper(data.label)}
              onClick={onLabelClick}
              count={data.count}
            />
            <GridViewer>
              {files.map((file, index) => (
                <GridViewerItem key={file.id}>
                  {children(data.label, file, filenames, index++)}
                </GridViewerItem>
              ))}
            </GridViewer>
            {Boolean(limit) && (
              <Button
                onClick={() => onLabelClick(data.label)}
                color="primary"
                className="label-viewer__view-all"
              >
                {remaining <= 0 ? (
                  <FormattedMessage
                    id="dataViewer.viewOnly"
                    values={{
                      label: labelNameMapper(
                        data.label || intl.formatMessage({id: 'dataViewer.unlabeled'})
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage id="dataViewer.viewMore" values={{remaining}} />
                )}
              </Button>
            )}
          </div>
        );
      })}
    </div>
  );
};
