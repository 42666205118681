import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory} from 'react-router';
import {
  AuthPageBody,
  AuthPageContainer,
  AuthPageFooter,
  AuthPageHeader,
  AuthPageSecondaryButton,
  AuthPageSummary,
} from '../AuthPage';

export function ResetEmailSent() {
  const history = useHistory();
  const intl = useIntl();

  return (
    <AuthPageContainer>
      <AuthPageHeader>
        <FormattedMessage id="auth.resetEmail" />
      </AuthPageHeader>
      <AuthPageBody>
        <AuthPageSummary>
          <FormattedMessage id="auth.resetEmailSummary" />
        </AuthPageSummary>
      </AuthPageBody>
      <AuthPageFooter>
        <AuthPageSecondaryButton
          value={intl.formatMessage({id: 'auth.returnToLogin'})}
          onClick={() => history.push('/login')}
        />
      </AuthPageFooter>
    </AuthPageContainer>
  );
}
