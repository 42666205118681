import React, {Component} from 'react';
import PropTypes from 'prop-types';

class WarningFillIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="20px"
          height="18px"
          viewBox="0 0 20 18"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-846.000000, -6089.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 5295.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-warning-fill-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M12.021322,3 L22,21 L2,21 L12.021322,3 Z M13,16 L11,16 L11,18 L13,18 L13,16 Z M13,10 L11,10 L11,14 L13,14 L13,10 Z"
                        id="Combined-Shape"
                        fill="currentColor"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

WarningFillIcon.propTypes = {
  className: PropTypes.string,
};

export default WarningFillIcon;
