import cn from 'classnames';
import * as React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {DatasetSummaryResponse} from '../../types/dataset/DatasetSummaryResponse';
import './LabelChart.scss';

const LABEL_WIDTH = 175;

export type LabelChartProps = {
  barSize: number;
  className?: string;
  grid?: boolean;
  labels?: DatasetSummaryResponse['labels'];
  onClick?(label: string): void;
  title?: string;
  tooltip?: boolean;
  xAxis?: boolean;
  yAxis?: boolean;
  yAxisRightMargin?: number;
  leftMargin?: number;
};

export const LabelChart = ({
  barSize,
  className,
  grid = false,
  labels = [],
  onClick = () => {},
  title,
  tooltip = false,
  xAxis = false,
  yAxisRightMargin = 0,
  leftMargin = 0,
  ...props
}: LabelChartProps) => (
  <div className={cn('label-chart', className)}>
    {title && <div className="label-chart__title">{title}</div>}
    <div className="label-chart__chart">
      <ResponsiveContainer width="100%" height={labels.length * barSize}>
        <BarChart data={labels} layout="vertical" {...props} margin={{left: leftMargin}}>
          {tooltip && <Tooltip cursor={false} />}
          {grid && <CartesianGrid horizontal={false} />}
          <XAxis
            dataKey="count"
            type="number"
            axisLine={false}
            tickLine={false}
            orientation="top"
            hide={!xAxis}
          />
          <YAxis
            width={LABEL_WIDTH}
            dataKey="label"
            type="category"
            axisLine={false}
            tickLine={false}
            interval={0}
            tick={props => (
              <Text {...props} breakAll maxLines={2} width={LABEL_WIDTH}>
                {props.payload.value}
              </Text>
            )}
            tickMargin={yAxisRightMargin}
          />
          <Bar
            dataKey="count"
            onClick={data => onClick(data.payload.label)}
            isAnimationActive={false}
          >
            {labels.map((_, i) => (
              <Cell key={i} fill="#425acc" />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  </div>
);
