/* eslint-disable react/style-prop-object */

import React, {useEffect, useState} from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {useHistory, useParams} from 'react-router';
import cn from 'classnames';
import {DeployStatus} from '../DeploymentStatus/DeploymentStatus';
import {DeploymentActionButton} from '../DeploymentActionButton/DeploymentActionButton';
import {Pagination} from '@material-ui/lab';
import {StudioKebabMenu} from '../../../base-components/StudioKebabMenu';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../../../store';
import {
  DeploymentDashboardRequest,
  DeploymentSortableColumn,
} from '../../../types/deployment/DeploymentDashboardRequest';
import {
  deleteDeployment,
  fetchDeployments,
  restartDeployment,
  stopDeployment,
} from '../../../store/prodDeployments/dashboard';
import {toast} from '../../../base-components/StudioToast';
import {DeploymentDashboardInfo} from '../../../types/deployment/DeploymentDashboardResponse';
import HumanReadableTime from '../../../base-components/StudioHumanReadableTime/HumanReadableTime';
import {ConfirmDialog} from '../../../base-components/StudioConfirmDialog';
import {sourceToTextMap} from '..';
import {StudioCheckbox} from '../../../base-components/StudioCheckbox';
import {actions as retrainActions} from '../../../store/prodDeployments/retrain';
import fileSize from 'filesize';
import {DeploymentStatusType} from '../../../types/deployment/DeploymentResponse';
import {isNumber} from 'lodash';

const {setRetrainData} = retrainActions;

const PAGE_SIZE = 6;
const UPDATE_INTERVAL = 5000;

type DeploymentsTableProps = {
  search: string;
};

export const DeploymentsTable = ({search}: DeploymentsTableProps) => {
  const history = useHistory();
  const intl = useIntl();
  const {projectId: projectContext} = useParams<{projectId: string}>();
  const dispatch = useAppDispatch();
  const [userOnly, setUserOnly] = useState(false);
  const [showDeletedDeployments, setShowDeletedDeployments] = useState(false);
  const [sort, setSort] = useState<DeploymentSortableColumn>('creationTime');
  const [sortDir, setSortDir] = useState<-1 | 1>(-1);
  const [page, setPage] = useState(0);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    isOpen: boolean;
    deployment: DeploymentDashboardInfo | null;
  }>({isOpen: false, deployment: null});
  const userId = useSelector((state: RootState) => state.user.userId);
  const groupId = useSelector((state: RootState) => state.user.groupId);

  const [expectedStatus, setExpectedStatus] = useState<Map<string, DeploymentStatusType>>(
    new Map()
  );

  function startMonitoringStatus(id: string, status: DeploymentStatusType) {
    setExpectedStatus(new Map(expectedStatus).set(id, status));
  }

  function stopMonitoringStatus(id: string) {
    const map = new Map(expectedStatus);
    map.delete(id);
    setExpectedStatus(map);
  }

  useEffect(() => {
    setPage(0);
  }, [sort, sortDir, userOnly, search]);

  useEffect(() => {
    if (userId && groupId) {
      const requestBody: DeploymentDashboardRequest = {
        firstPage: page,
        pageSize: PAGE_SIZE,
        paginated: true,
        orderDefinitions: [{field: sort, direction: sortDir === -1 ? 'DESC' : 'ASC'}],
        params: {
          name: search,
          deploymentType: ['PRODUCTION'],
          status: showDeletedDeployments
            ? ['DELETED']
            : [
                'IDLE',
                'COPYING_DATA',
                'DEPLOYING',
                'STARTING',
                'RUNNING',
                'STOPPING',
                'ERROR',
              ],
        },
        groupId,
        userId: userOnly ? userId : null,
      };

      let promise = dispatch(fetchDeployments(requestBody));
      const interval = setInterval(() => {
        promise = dispatch(fetchDeployments(requestBody));
      }, UPDATE_INTERVAL);

      return () => {
        promise.abort();
        clearInterval(interval);
      };
    }
  }, [
    search,
    sort,
    sortDir,
    page,
    userOnly,
    showDeletedDeployments,
    userId,
    groupId,
    dispatch,
  ]);

  const deployments = useSelector(
    (state: RootState) => state.deploymentDashboard.deployments.data
  );
  const totalResults = useSelector(
    (state: RootState) => state.deploymentDashboard.deployments.count
  );
  const optimisticallyUpdatedStatuses = useSelector(
    (state: RootState) => state.deploymentDashboard.optimisticallyUpdatedStatuses
  );

  const paginationCount =
    Math.ceil(totalResults / PAGE_SIZE) === 0 ? 1 : Math.ceil(totalResults / PAGE_SIZE);
  const paginationPage = page + 1;

  for (const deployment of deployments) {
    if (expectedStatus.size === 0) {
      break;
    }
    const status = expectedStatus.get(deployment.id);
    if (status !== deployment.status) {
      continue;
    }
    if (status === 'RUNNING') {
      toast.success(`Successfully started deployment ${deployment.name}`);
    }
    if (status === 'IDLE') {
      toast.success(`Successfully stopped deployment ${deployment.name}`);
    }
    stopMonitoringStatus(deployment.id);
  }

  const handleSort = (column: DeploymentSortableColumn) => {
    if (column === sort) {
      setSortDir(sortDir === 1 ? -1 : 1);
    } else {
      setSort(column);
      setSortDir(1);
    }
  };

  const handleDeployAction = async (row: DeploymentDashboardInfo) => {
    if (row.status === 'IDLE') {
      const result = await dispatch(restartDeployment(row.id));
      if (result.meta.requestStatus === 'fulfilled') {
        startMonitoringStatus(row.id, 'RUNNING');
      }
    } else if (row.status !== 'ERROR') {
      const result = await dispatch(stopDeployment(row.id));
      if (result.meta.requestStatus === 'fulfilled') {
        startMonitoringStatus(row.id, 'IDLE');
      }
    }
  };

  const handleDeleteDeployment = async ({name, id}: DeploymentDashboardInfo) => {
    await dispatch(deleteDeployment(id));
    if (expectedStatus.get(id)) {
      stopMonitoringStatus(id);
    }
    toast.success(`Successfully deleted deployment ${name}`);
  };

  const getSortDirection = (): 'asc' | 'desc' => {
    return sortDir === 1 ? 'asc' : 'desc';
  };

  return (
    <>
      <div className="prod-deploy-dashboard__menu">
        <StudioCheckbox
          checked={userOnly}
          name="mineOnly"
          label={intl.formatMessage({id: 'prodDeployment.mineOnly'})}
          onChange={(_, checked) => setUserOnly(checked)}
        />
        <StudioCheckbox
          checked={showDeletedDeployments}
          name="mineOnly"
          label={intl.formatMessage({id: 'prodDeployment.showDeleted'})}
          onChange={(_, checked) => setShowDeletedDeployments(checked)}
        />
      </div>
      <TableContainer
        component={Paper}
        className="prod-deploy-dashboard__deployments"
        elevation={0}
      >
        <Table stickyHeader className="prod-deploy-dashboard__table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sort === 'creationTime'}
                  direction={getSortDirection()}
                  onClick={() => handleSort('creationTime')}
                  IconComponent={ArrowDropDown}
                >
                  <FormattedMessage id="prodDeployment.time" />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort === 'status'}
                  direction={getSortDirection()}
                  onClick={() => handleSort('status')}
                  IconComponent={ArrowDropDown}
                >
                  <FormattedMessage id="prodDeployment.status" />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort === 'deviceName'}
                  direction={getSortDirection()}
                  onClick={() => handleSort('deviceName')}
                  IconComponent={ArrowDropDown}
                >
                  <FormattedMessage id="prodDeployment.node" />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.board" />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort === 'name'}
                  direction={getSortDirection()}
                  onClick={() => handleSort('name')}
                  IconComponent={ArrowDropDown}
                >
                  <FormattedMessage id="prodDeployment.deployment" />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort === 'applicationName'}
                  direction={getSortDirection()}
                  onClick={() => handleSort('applicationName')}
                  IconComponent={ArrowDropDown}
                >
                  <FormattedMessage id="prodDeployment.application" />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.dataSource" />
              </TableCell>
              <TableCell colSpan={2}>
                <FormattedMessage id="prodDeployment.dataDrift" />
              </TableCell>
              <TableCell colSpan={4}>
                <FormattedMessage id="prodDeployment.resourceUsage" />
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow className="prod-deploy-dashboard__subheader">
              <TableCell colSpan={7} />
              <TableCell>
                <FormattedMessage id="prodDeployment.skew" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.confidence" />
              </TableCell>
              {/*<TableCell>*/}
              {/*  <FormattedMessage id="prodDeployment.gspUtil" />*/}
              {/*</TableCell>*/}
              <TableCell>
                <FormattedMessage id="prodDeployment.memUtil" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.memTotal" />
              </TableCell>
              {/*<TableCell>*/}
              {/*  <FormattedMessage id="prodDeployment.power" />*/}
              {/*</TableCell>*/}
              <TableCell>
                <FormattedMessage id="prodDeployment.temp" />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {deployments.length ? (
              deployments.map(row => {
                const format = row.source?.format;
                const inputSourceType = row.source?.inputSourceType;
                const sourceId =
                  format === 'IMAGES'
                    ? sourceToTextMap[format]
                    : inputSourceType != null
                    ? sourceToTextMap[inputSourceType]
                    : 'general.na.short';
                const optimisticallyUpdatedStatus = optimisticallyUpdatedStatuses[row.id];
                const status = optimisticallyUpdatedStatus ?? row.status;

                return (
                  <TableRow
                    key={row.id}
                    className={cn(
                      'prod-deploy-dashboard__row',
                      status === 'ERROR' && 'prod-deploy-dashboard__row--error'
                    )}
                  >
                    <TableCell>
                      {row.startTime ? (
                        <HumanReadableTime date={row.startTime} />
                      ) : (
                        <p>{intl.formatMessage({id: 'prodDeployment.not.started'})}</p>
                      )}
                    </TableCell>
                    <TableCell>
                      {status != null && <DeployStatus status={status} />}
                    </TableCell>
                    <TableCell>{row.deviceName}</TableCell>
                    <TableCell>{row.boardName ? row.boardName : 'N/A'}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      {row.applicationName ? row.applicationName : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id={sourceId} />
                    </TableCell>
                    <TableCell>
                      {row.dataDrift?.skewPercent != null ? (
                        <FormattedNumber
                          style="percent"
                          value={row.dataDrift.skewPercent / 100}
                        />
                      ) : (
                        <p>
                          {intl.formatMessage({
                            id: 'prodDeployment.not.started.empty.field',
                          })}
                        </p>
                      )}
                    </TableCell>
                    <TableCell>
                      {row.dataDrift?.confidencePercent != null ? (
                        <FormattedNumber
                          style="percent"
                          value={row.dataDrift.confidencePercent / 100}
                        />
                      ) : (
                        <p>
                          {intl.formatMessage({
                            id: 'prodDeployment.not.started.empty.field',
                          })}
                        </p>
                      )}
                    </TableCell>
                    {/*<TableCell>*/}
                    {/*  {row.gspUtilization?.gspUtilization != null && (*/}
                    {/*    <FormattedNumber*/}
                    {/*      style="percent"*/}
                    {/*      value={row.gspUtilization.gspUtilization / 100}*/}
                    {/*    />*/}
                    {/*  )}*/}
                    {/*</TableCell>*/}
                    <TableCell>
                      {row.gspUtilization?.memoryUsage != null ? (
                        fileSize(row.gspUtilization.memoryUsage, {base: 10})
                      ) : (
                        <p className="prod-deploy-dashboard__actions__empty">
                          {intl.formatMessage({
                            id: 'prodDeployment.not.started.empty.field',
                          })}
                        </p>
                      )}
                    </TableCell>
                    <TableCell>
                      {row.gspUtilization?.memoryTotal != null ? (
                        fileSize(row.gspUtilization.memoryTotal, {base: 10})
                      ) : (
                        <p className="prod-deploy-dashboard__actions__empty">
                          {intl.formatMessage({
                            id: 'prodDeployment.not.started.empty.field',
                          })}
                        </p>
                      )}
                    </TableCell>
                    {/*<TableCell>*/}
                    {/*  {row.gspUtilization?.powerConsumption != null &&*/}
                    {/*    `${row.gspUtilization.powerConsumption} W`}*/}
                    {/*</TableCell>*/}
                    <TableCell>
                      {row.gspUtilization?.gspTemperature ? (
                        <FormattedNumber
                          style="unit"
                          unit="celsius"
                          value={row.gspUtilization.gspTemperature}
                        />
                      ) : (
                        <p className="prod-deploy-dashboard__actions__empty">
                          {intl.formatMessage({
                            id: 'prodDeployment.not.started.empty.field',
                          })}
                        </p>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="prod-deploy-dashboard__actions">
                        {row.projectId && row.applicationId ? (
                          <DeploymentActionButton
                            inputSourceLocationType={row.source?.inputSourceLocationType}
                            inputSourceId={row.source?.inputSourceId}
                            inputSourceType={row.source?.inputSourceType}
                            streamingUrl={row.source?.streamingUrl}
                            status={status}
                            detailedTooltip={false}
                            onStart={() => handleDeployAction(row)}
                            onStop={() => handleDeployAction(row)}
                          />
                        ) : (
                          <p className="prod-deploy-dashboard__actions__empty">
                            {intl.formatMessage({
                              id: 'prodDeployment.not.started.empty.field',
                            })}
                          </p>
                        )}
                        <StudioKebabMenu
                          items={[
                            {
                              title: intl.formatMessage({
                                id: 'prodDeployment.viewDetails',
                              }),
                              disabled: row.status === 'DELETED',
                              onClick: () => {
                                history.push(
                                  projectContext
                                    ? `/deployment/${row.id}/details?projectContext=${projectContext}`
                                    : `/deployment/${row.id}/details`
                                );
                              },
                            },
                            ...(row.retrainAvailable
                              ? [
                                  {
                                    title: intl.formatMessage({
                                      id: 'prodDeployment.retrain',
                                    }),
                                    onClick: () => {
                                      dispatch(
                                        setRetrainData({
                                          projectId: row.projectId,
                                          deploymentId: row.id,
                                        })
                                      );
                                      history.push(`/project/${row.projectId}/retrain`);
                                    },
                                  },
                                ]
                              : []),
                            {
                              title: intl.formatMessage({id: 'prodDeployment.delete'}),
                              disabled: row.status === 'DELETED',
                              onClick: () =>
                                setDeleteConfirmation({isOpen: true, deployment: row}),
                            },
                          ]}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={100} className="prod-deploy-dashboard__no-results">
                  <FormattedMessage id="dashboard.noResults" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isNumber(totalResults) && (
        <Pagination
          className="prod-deploy-dashboard__pagination"
          count={paginationCount}
          page={paginationPage}
          shape="rounded"
          onChange={(_, page) => setPage(page - 1)}
          data-testid="prod-deploy-dashboard-pagination"
          id="prod-deploy-dashboard-pagination"
        />
      )}
      <ConfirmDialog
        type="confirm"
        title={intl.formatMessage({id: 'prodDeployment.deleteConfirmTitle'})}
        message={intl.formatMessage({id: 'prodDeployment.deleteConfirmMessage'})}
        submitLabel={intl.formatMessage({id: 'prodDeployment.deleteConfirmSubmitLabel'})}
        open={deleteConfirmation.isOpen}
        onClose={() => setDeleteConfirmation({isOpen: false, deployment: null})}
        onOk={() => {
          if (deleteConfirmation.deployment) {
            handleDeleteDeployment(deleteConfirmation.deployment);
          }
          setDeleteConfirmation({isOpen: false, deployment: null});
        }}
      />
    </>
  );
};
