import {
  PROJECT_LIST_FETCH_REPLACE_PENDING,
  PROJECT_LIST_FETCH_REPLACE_SUCCESS,
  PROJECT_LIST_FETCH_REPLACE_ERROR,
  PROJECT_LIST_FETCH_APPEND_PENDING,
  PROJECT_LIST_FETCH_APPEND_SUCCESS,
  PROJECT_LIST_FETCH_APPEND_ERROR,
  PROJECT_LIST_FETCH_APPEND_CANCEL,
} from './actions';

export const REQUEST_STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  CANCELLED: 'cancelled',
};

const initialState = {
  replaceStatus: REQUEST_STATUS.IDLE,
  projects: [],
  nextStartFrom: 0,
  totalCount: 0,
  errors: null,
  appendStatus: REQUEST_STATUS.IDLE,
};

export const projectListReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_LIST_FETCH_REPLACE_PENDING:
      return {
        ...state,
        replaceStatus: REQUEST_STATUS.PENDING,
      };
    case PROJECT_LIST_FETCH_REPLACE_SUCCESS:
      return {
        ...state,
        projects: action.payload.projects || [],
        nextStartFrom: action.payload.startFrom + action.payload.pageSize,
        totalCount: action.payload.totalCount,
        replaceStatus: REQUEST_STATUS.IDLE,
        errors: null,
      };
    case PROJECT_LIST_FETCH_REPLACE_ERROR:
      return {
        ...state,
        replaceStatus: REQUEST_STATUS.IDLE,
        errors: action.payload,
      };
    case PROJECT_LIST_FETCH_APPEND_PENDING:
      return {
        ...state,
        appendStatus: REQUEST_STATUS.PENDING,
      };
    case PROJECT_LIST_FETCH_APPEND_SUCCESS:
      const projects = [...state.projects];
      projects.splice(
        action.payload.startFrom,
        action.payload.pageSize,
        ...action.payload.projects
      );

      return {
        ...state,
        projects,
        nextStartFrom: action.payload.startFrom + action.payload.pageSize,
        totalCount: action.payload.totalCount,
        appendStatus: REQUEST_STATUS.IDLE,
        errors: null,
      };
    case PROJECT_LIST_FETCH_APPEND_CANCEL:
      return {
        ...state,
        appendStatus: REQUEST_STATUS.CANCELLED,
      };
    case PROJECT_LIST_FETCH_APPEND_ERROR:
      return {
        ...state,
        appendStatus: REQUEST_STATUS.IDLE,
        errors: action.payload,
      };
    default:
      return state;
  }
};
