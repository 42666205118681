import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import BaseStage from '../Base/BaseStage';
import './StageStep.scss';
import StageAnimation from '../StageAnimation/StageAnimation';
import Waiting from '../../../base-components/StudioWaiting/Waiting';
import LockedIcon from '../../../assets/icons/LockedIcon';
import {
  getIsDiabledEditDataStage,
  getIsDiabledEditModelStage,
  getIsDiabledModelOptimizationStage,
  getIsDiabledFineTuningStage,
  getIsDiabledDeploymentStage,
} from '../../../store/view';

class StageStep extends BaseStage {
  render() {
    const isActive = this.props.active;
    return (
      <li
        data-testid={`${this.props.stage.type}-stage-step`}
        id={this.props.stage.type + '-container'}
        className={'stage-step step-active-cursor' + (isActive ? ' active' : '')}
        onClick={StageStep.activateStage}
      >
        <div className={'section-filler' + this.getLeftFillerLineClass()} />
        <div className={'section-title ' + this.getPointLineClass()}>
          <div className="section-text">{this.props.stage.displayText}</div>
          <div
            className={'section-button' + this.getPointButtonClass()}
            id={this.props.stage.type + '-button'}
          >
            <span
              data-testid={`${this.props.stage.type}-stage-step-checkmark`}
              id={`${this.props.stage.type}-stage-step-checkmark`}
              className="section-checkmark"
            >
              
            </span>
            <LockedIcon
              data-testid={`${this.props.stage.type}-stage-step-locked`}
              id={`${this.props.stage.type}-stage-step-locked`}
              className="section-locked-icon"
            />
            <span
              data-testid={`${this.props.stage.type}-stage-step-bullet`}
              id={`${this.props.stage.type}-stage-step-bullet`}
              className="section-bullet"
            />
            <Waiting
              data-testid={`${this.props.stage.type}-stage-step-waiting`}
              id={`${this.props.stage.type}-stage-step-waiting`}
              diameter={30}
            />
          </div>
        </div>
        <div className={'section-filler' + this.getRightFillerLineClass()} />
      </li>
    );
  }

  static activateStage(event) {
    const section = document.getElementById(event.currentTarget.id);
    if (section == null) {
      return;
    }
    new StageAnimation().updateStages(section);
  }

  getPointButtonClass() {
    if (this.props.stage.status === 'LOCKED' || this.checkIfStageIsLocked()) {
      return ' section-button-locked';
    } else if (this.props.stage.status === 'FINISHED') {
      return ' section-button-finished';
    } else if (this.props.stage.status === 'IN_PROGRESS') {
      return ' section-button-in-progress';
    } else {
      return ' section-button-inactive';
    }
  }

  checkIfStageIsLocked() {
    let isLocked = false;

    switch (this.props.stage.type) {
      case 'ADD_DATA':
        isLocked = this.props.isDiabledEditDataStage;
        break;
      case 'IMPORT_MODEL':
        isLocked = this.props.isDiabledEditModelStage;
        break;
      case 'OPTIMIZE_MODEL':
        isLocked = this.props.isDiabledModelOptimizationStage;
        break;
      case 'FINETUNE_MODEL':
        isLocked = this.props.isDiabledFineTuningStage;
        break;
      case 'CREATE_DEPLOYMENT':
        isLocked = this.props.isDisableDeploymentStage;
        break;
      default:
        break;
    }

    return isLocked;
  }

  getPointLineClass() {
    const finished = this.props.stage.status === 'FINISHED';
    if (this.props.last) {
      return this.props.prevFinished ? 'stage-line-last-f' : 'stage-line-last-n';
    } else if (this.props.first) {
      return finished ? 'stage-line-first-f' : 'stage-line-first-n';
    } else {
      return this.props.prevFinished
        ? finished
          ? 'stage-line-ff'
          : 'stage-line-fn'
        : finished
        ? 'stage-line-nf'
        : 'stage-line-nn';
    }
  }

  getLeftFillerLineClass() {
    if (this.props.first) {
      return '';
    } else {
      return this.props.prevFinished ? ' stage-line-ff' : ' stage-line-nn';
    }
  }

  getRightFillerLineClass() {
    const finished = this.props.stage.status === 'FINISHED';
    if (this.props.last) {
      return '';
    } else {
      return finished ? ' stage-line-ff' : ' stage-line-nn';
    }
  }
}

StageStep.propTypes = {
  stage: PropTypes.any.isRequired,
  first: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  prevFinished: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isDiabledEditDataStage: getIsDiabledEditDataStage(state),
  isDiabledEditModelStage: getIsDiabledEditModelStage(state),
  isDiabledModelOptimizationStage: getIsDiabledModelOptimizationStage(state),
  isDiabledFineTuningStage: getIsDiabledFineTuningStage(state),
  isDisableDeploymentStage: getIsDiabledDeploymentStage(state),
});

export default connect(mapStateToProps)(StageStep);
