import {IconButton, Paper} from '@material-ui/core';
import {ChevronLeft, ChevronRight} from '@material-ui/icons';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {getProjectId} from '../../../config/routes';
import {TestDetails} from '../TestDetails';
import './TestComparison.scss';

const NUMBER_OF_TESTS = 3;

export const TestComparison = () => {
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const projectId = getProjectId(location.pathname)!;
  const query = new URLSearchParams(location.search);
  const deploymentIds = query.get('deploymentIds')?.split(',') ?? [];

  const [index, setIndex] = useState(0);
  const displayedIds = deploymentIds.slice(index, index + NUMBER_OF_TESTS);

  return (
    <div className="test-comparison">
      <FormButton
        buttonRole="secondary"
        value={intl.formatMessage({id: 'testCompare.backToTest'})}
        customClass="test-comparison__back-to-test"
        onClick={() => history.push(`/project/${projectId}/test?tab=1`)}
      />
      <Paper variant="outlined" className="test-comparison__view">
        <div className="test-comparison__header">
          <div className="test-comparison__title">
            <FormattedMessage id="testCompare.compareTests" />
          </div>
          <div className="test-comparison__navigation">
            <div className="test-comparison__navigation-info">
              <FormattedMessage
                id="testCompare.displayingTests"
                values={{
                  start: index + 1,
                  end: index + displayedIds.length,
                  testCount: deploymentIds.length,
                }}
              />
            </div>
            <IconButton
              className="test-comparison__left"
              disabled={index <= 0}
              onClick={() => {
                if (index > 0) {
                  setIndex(index - 1);
                }
              }}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              className="test-comparison__right"
              disabled={index + NUMBER_OF_TESTS >= deploymentIds.length}
              onClick={() => {
                if (index + NUMBER_OF_TESTS < deploymentIds.length) {
                  setIndex(index + 1);
                }
              }}
            >
              <ChevronRight />
            </IconButton>
          </div>
        </div>
        <div className="test-comparison__results">
          <TestDetails ids={displayedIds} hideMissingFields={false} />
        </div>
      </Paper>
    </div>
  );
};
