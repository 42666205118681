const quantizationTypes = {
  MMT_1D: 'MMT 1D',
  MMT_3D: 'Fast',
  MMT_3D_ADV: 'Advanced',
};

export type QuantizationType = keyof typeof quantizationTypes;

export function quantizationTypeToString(quantizationType: QuantizationType) {
  return quantizationTypes[quantizationType] ?? quantizationType;
}
