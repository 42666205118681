import React from 'react';
import BeforeImage1 from './images/white_balance-before.png';
import AfterImage from './images/white_balance-after.png';
import './Help.scss';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>Auto White Balance</h4>
      <div className="flow-graph__help_content_detail">
        <p>Color constancy is one of the most amazing features of the human visual system. When we look at objects under different illuminations, their colors stay relatively constant.  This helps humans to identify objects conveniently. While the precise physiological mechanism is not fully known, it has been postulated that the eyes are responsible for capturing different wavelengths of the light reflected by an object, and the brain attempts to “discount” the contribution of the illumination so that the color perception matches more closely with the object reflectance, and therefore is  mostly  constant  under different illuminations.</p>
        <p>A similar behavior is highly desirable in digital still and video cameras. This is achieved via white balancing which is an image processing step employed in a digital camera imaging pipeline to adjust the coloration of  images  captured  under  different  illuminations. This is because the ambient light has a significant effect on the color stimulus. If the color temperature of a light source is low, the object being captured will appear reddish. An example is the domestic tungsten lamp, whose color temperature is around 3000 Kelvins (K). On the other hand, with a high color temperature light source, the object will appear bluish. This includes the typical daylight, with color temperature above 6000 K.</p>
        <p>The Auto White Balance (AWB) setting helps your camera “guess” the best option or choose the one closest to what your eyes might see. Many times AWB works better when you are outdoors dealing with natural lighting, than with more complex lighting situations.</p>
        <p>Methods used for Auto White Balance (AWB) in ISP:</p>
        <ol>
          <li>Gray World Assumption</li>
          <li>White World Assumption</li>
        </ol>
        <p>The first method incorporates the gray world assumption, which argues that the average reflectance of a scene is achromatic. In other words, the mean of the red, green, and blue channels in a given scene should be roughly equal.</p>
        <p>The second method is based on the Retinex theory (also called as white world assumption or white patch) of visual color constancy, which argues that perceived white is associated with the maximum cone signals. This is also known as the white world assumption. This is because the brightest point in an image is often due to reflectance of a glossy surface, which tends to reflect the actual color of the light source. The white balancing scheme then attempts to equalize the maximum value of the three channels to produce a white patch. To avoid disturbances to the calculation caused by a few bright pixels, one can treat clusters of pixels or lowpass the image.</p>

        <div className="help_images">
          <figure>
            <img src={BeforeImage1} alt="before" />
            <figcaption>Input</figcaption>
          </figure>
          <figure>
            <img src={AfterImage} alt="after" />
            <figcaption>Output</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};
