import {mutate} from 'swr';
import {PreProcessor} from '../../types/preprocessor/PreProcessor';
import {request} from '../request';
import {PreProcessorListUrl} from './PreProcessorList';

export type PreProcessorDeleteRequest = {
  projectId: string;
  preProcessorId: string;
};

export type PreProcessorDeleteResponse = PreProcessor;

export const PreProcessorDeleteUrl = ({
  projectId,
  preProcessorId,
}: PreProcessorDeleteRequest) =>
  `/preprocessor/delete/${projectId}?${new URLSearchParams({
    preProcessorId,
  }).toString()}`;

export async function preProcessorDelete(data: PreProcessorDeleteRequest) {
  const response = await request<PreProcessorDeleteResponse>({
    method: 'get',
    url: PreProcessorDeleteUrl(data),
  });
  mutate(
    PreProcessorListUrl({
      projectId: data.projectId,
    })
  );
  return response;
}
