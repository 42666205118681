import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'html-react-parser';
import './TextWithIconButton.scss';

import ArrowRightIcon from '../../assets/icons/ArrowRightIcon';
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon';
import ArrowAngledRightIcon from '../../assets/icons/ArrowAngledRightIcon';

class TextWithIconButton extends Component {
  render() {
    const {
      id,
      value,
      size,
      buttonIcon,
      iconLeft,
      customClass,
      onClick,
      disabled,
      dataTestID,
      type,
    } = this.props;
    const customCls = customClass ? customClass : '';
    return (
      <button
        {...(id ? {id: id} : {})}
        className={`text-w-icon-button ${size} ${
          iconLeft ? 'icon-left' : 'icon-right'
        } ${customCls}`}
        onClick={onClick}
        value={value}
        disabled={disabled}
        data-testid={dataTestID}
        id={dataTestID}
        type={type}
      >
        {iconLeft && this.getIcon(buttonIcon)}
        <div className="text-w-icon-button__text">{ReactHtmlParser(value)}</div>
        {!iconLeft && this.getIcon(buttonIcon)}
      </button>
    );
  }

  getIcon(name) {
    const icons = {
      arrowLeft: <ArrowLeftIcon className="buttonIcon" />,
      arrowRight: <ArrowRightIcon className="buttonIcon" />,
      arrowAngledRight: <ArrowAngledRightIcon className="buttonIcon" />,
    };
    return icons[name];
  }
}

TextWithIconButton.propTypes = {
  value: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['medium', 'large']).isRequired,
  buttonIcon: PropTypes.oneOf([
    'arrowLeft',
    'arrowRight',
    'arrowAngledLeft',
    'arrowAngledRight',
  ]).isRequired,
  id: PropTypes.number,
  customClass: PropTypes.string,
  onClick: PropTypes.func,
  iconLeft: PropTypes.bool,
  disabled: PropTypes.bool,
  dataTestID: PropTypes.string,
};

TextWithIconButton.defaultProps = {
  id: null,
  customClass: null,
  onClick: () => null,
  iconLeft: false,
  disabled: false,
  dataTestID: null,
};

export default TextWithIconButton;
