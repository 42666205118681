import React from 'react';
import './NoDeployment.scss';

export type NoDeploymentProps = {
  imageUrl?: string; // TODO: When we create more breakpoints and discuss image optimization: create a StudioImage component that handles a picture tag with srcset
  message: React.ReactNode;
};

export const NoDeployment = ({message, imageUrl}: NoDeploymentProps) => (
  <div className="no-deployment">
    {imageUrl && <img src={imageUrl} alt="No deployment" />}
    <p className="no-deployment__message bodyB2">{message}</p>
  </div>
);
