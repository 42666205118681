import React from 'react';
import BeforeImage1 from './images/white_balance-before.png';
import AfterImage from './images/white_balance-after.png';
import './Help.scss';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>White Balance</h4>
      <div className="flow-graph__help_content_detail">
        <p>White Balance is the process of adjustment of colors to make them appear more natural in images. White Balance removes the unrealistic color casts so that the objects which appear white for human eye are rendered white after processing in your image.</p>

        <p>For an image, white balance is obtained when the color data signal for white scene elements are equal. When looking at the captured data for a white element in the image, if R = G = B, the image is “white balanced”.</p>
        <div className="help_images">
          <figure>
            <img src={BeforeImage1} alt="before" />
            <figcaption>Input</figcaption>
          </figure>
          <figure>
            <img src={AfterImage} alt="after" />
            <figcaption>Output</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};
