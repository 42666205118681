import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../../store';
import {RuntimeModeType} from '../TestDeployView/useDeploy';
import {actions} from '../../store/onnx/session-store';
import {
  getApplicationONNXFile,
  getApplicationONNXFileURL,
} from '../../api/postprocessor/GetApplicationONNXFile';
import useSWRImmutable from 'swr/immutable';

const getSessionKey = (applicationId: string, runtimeMode: RuntimeModeType) =>
  `${applicationId}_${runtimeMode}`;

export const useInferenceSession = (
  projectId: string,
  applicationId: string,
  runtimeMode: RuntimeModeType,
  onError?: (message?: string) => void
) => {
  const dispatch = useAppDispatch();
  const onErrorRef = useRef(onError);

  const sessionStore = useSelector((state: RootState) => state.onnxSessionStore.cache);
  const sessionKey = getSessionKey(applicationId, runtimeMode);
  const existingSession = sessionStore[sessionKey];

  // Retrieve onnx model
  const {data: response} = useSWRImmutable(
    !existingSession ? getApplicationONNXFileURL({projectId, applicationId}) : null,
    getApplicationONNXFile
  );
  const modelBlob = response?.data;

  // Create inference session if not already existing
  useEffect(() => {
    async function createSession() {
      if (modelBlob) {
        try {
          const modelBuffer = await modelBlob.arrayBuffer();
          const session = await ort.InferenceSession.create(modelBuffer, {
            executionProviders: [runtimeMode],
          });
          dispatch(actions.addSession({id: sessionKey, session}));
        } catch (e) {
          console.log(e);
          onErrorRef.current?.('Error loading model.');
        }
      }
    }
    if (!existingSession) {
      createSession();
    }
  }, [existingSession, sessionKey, modelBlob, sessionStore, runtimeMode, dispatch]);

  return existingSession;
};
