import {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import URL from '../../config/url';
import {getListeners} from '../../store/statusChecker';
import Util from '../../util';

const StatusChecker = ({listeners, projectId, conversationId}) => {
  const listenersRef = useRef(listeners);
  const listenersExist = Boolean(Object.keys(listeners).length);

  useEffect(() => {
    listenersRef.current = listeners;
  }, [listeners]);

  useEffect(() => {
    let isCleanedUp = false;

    const pollForStatus = async () => {
      if (listenersExist) {
        const requestOptions = {
          params: {
            conversationId,
          },
        };

        const notifyListener = response => {
          const handler = listenersRef.current[response.type];
          if (handler) {
            handler(response);
          }
        };

        try {
          const {data} = await axios.get(
            URL.PROJECT_OP_STATUS(projectId),
            requestOptions
          );

          const pollingResponse = data.body;
          if (pollingResponse.caStatuses && !isCleanedUp) {
            notifyListener({type: 'CA', payload: pollingResponse.caStatuses});
          }

          if (pollingResponse.tasks && !isCleanedUp) {
            const firstCompletedTask = pollingResponse.tasks.find(task =>
              Util.isRealNumberEqual(task.percentCompleted, 100)
            );
            // completed tasks often require user action (e.g. answer questions in assistant panel)
            // hence, process one completed task at a time to prevent overlap
            if (firstCompletedTask) {
              notifyListener(firstCompletedTask);
            } else {
              pollingResponse.tasks.forEach(notifyListener);
            }
          }
        } finally {
          if (!isCleanedUp) {
            setTimeout(pollForStatus, 2000);
          }
        }
      }
    };

    pollForStatus();
    return () => {
      isCleanedUp = true;
    };
  }, [conversationId, projectId, listenersExist]);

  return null;
};

const mapStateToProps = state => ({
  listeners: getListeners(state),
});

export default connect(mapStateToProps)(StatusChecker);
