import React, {useEffect, useContext, useState} from 'react';
import {ISPFlowgraphContext} from '../Editor/ISPFlowgraphProvider';
import { Paper, Fade } from '@material-ui/core';
import Crop from './Crop';
import Scale from './Scale';
import BadPixelCorrection from './BadPixelCorrection';
import BlackLevelCorrection from './BlackLevelCorrection';
import LensDistortion from './LensDistortion';
import Demosaic from './Demosaic';
import AutoWhiteBalance from './AutoWhiteBalance';
import ColorCorrectionMatrix from './ColorCorrectionMatrix';
import EdgeEnhancement from './EdgeEnhancement';
import GammaCorrection from './GammaCorrection';
import Denoising from './Denoising';
import RGBDefogging from './RGBDefogging';
import ToneMapping from './ToneMapping';
import ColorSpaceConversion from './ColorSpaceConversion';
import WhiteBalance from './WhiteBalance';

import './Help.scss';

const HELP_CONTENT_MAP = {
  crop: Crop,
  scale: Scale,
  bpc: BadPixelCorrection,
  blc: BlackLevelCorrection,
  ldc: LensDistortion,
  demosaic: Demosaic,
  awb: AutoWhiteBalance,
  ccm: ColorCorrectionMatrix,
  sharpening: EdgeEnhancement,
  gammacorrection: GammaCorrection,
  denoising: Denoising,
  defog: RGBDefogging,
  wdr: ToneMapping,
  rgb2yuv: ColorSpaceConversion,
  wb: WhiteBalance,
  // TODO: Need some help content on the following modules
  scn: null,
  yuv2rgb: null
}

export default () => {
  const {selectedModule} = useContext(ISPFlowgraphContext);
  const [helpContent, setHelpContent] = useState(null);

  useEffect(() => setHelpContent(HELP_CONTENT_MAP[selectedModule?.data?.module_name]), [selectedModule]);
  
  return <Fade in={!!helpContent}>
    <Paper
      className="flow-graph__help"
      elevation={3}
    >
      {helpContent}
    </Paper>
  </Fade>
}
