import {Button} from '@material-ui/core';
import axios from 'axios';
import React, {Component, forwardRef} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {ConfirmDialog} from '../../../base-components/StudioConfirmDialog';
import {toast} from '../../../base-components/StudioToast';
import URL from '../../../config/url';
import {StageAssistantForm} from '../../StageAssistantForm';

class DataPrepareAction extends Component {
  constructor(props) {
    super(props);
    this.initiate = this.initiate.bind(this);
    this.showAssistant = this.showAssistant.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this);

    this.state = {
      confirmDialog: {
        visible: false,
        values: null,
        isSubmitting: false,
      },
    };
  }

  componentDidMount() {
    if (
      this.props.dataLabelingUnfinished ||
      this.props.datasetRelabelingUnfinished ||
      this.props.ddaMergeUnfinished
    ) {
      this.initiate();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.dataLabelingUnfinished !== this.props.dataLabelingUnfinished &&
        this.props.dataLabelingUnfinished) ||
      (prevProps.datasetRelabelingUnfinished !== this.props.datasetRelabelingUnfinished &&
        this.props.datasetRelabelingUnfinished) ||
      (prevProps.ddaMergeUnfinished !== this.props.ddaMergeUnfinished &&
        this.props.ddaMergeUnfinished)
    ) {
      this.initiate();
    }
  }

  initiate() {
    this.showAssistant();
  }

  showAssistant() {
    if (this.props.assistantPanel?.current) {
      this.props.assistantPanel.current.display(this.getQuestionsForm(), 'ADD_DATA');
    }
  }

  renderButtons(isSubmitting, isValid, dirty, values) {
    return (
      <div className="stage-assistant-form__actions">
        <Button onClick={this.handleSkip} disabled={isSubmitting}>
          {this.props.intl.formatMessage({id: 'form.skip'})}
        </Button>
        <FormButton
          buttonRole="secondary"
          value={this.props.intl.formatMessage({id: 'form.delete'})}
          onClick={() => this.setState({confirmDialog: {visible: true, values}})}
          disabled={isSubmitting || !isValid}
          type="button"
        />
        <FormButton
          buttonRole="primary"
          value={this.props.intl.formatMessage({id: 'action.resume'})}
          type="submit"
          disabled={isSubmitting || !isValid}
        />
      </div>
    );
  }

  getOptions() {
    const options = {};
    if (this.props.dataLabelingUnfinished) {
      options['raw'] = 'Prepare a New Dataset';
    }
    if (this.props.datasetRelabelingUnfinished) {
      options['dataset'] = 'Edit Dataset';
    }
    if (this.props.ddaMergeUnfinished) {
      options['deployment'] = 'Data Drift Merge';
    }
    return options;
  }

  getQuestionsForm() {
    const options = this.getOptions();
    const questions = [
      {
        mandatory: true,
        key: 'ResumeDataPrep',
        text: 'Select a task to resume',
        type: 'MULTIPLE_CHOICES',
        options,
      },
    ];
    return (
      <StageAssistantForm
        prompts={questions}
        defaults={{}}
        onSubmit={this.handleSubmit}
        title="Unfinished data preparation tasks"
        customRenderButtons={this.renderButtons}
      />
    );
  }

  closeConfirmDialog() {
    this.setState({confirmDialog: {visible: false, values: null, isSubmitting: false}});
  }

  async handleCancel() {
    this.setState(prevState => ({
      confirmDialog: {...prevState.confirmDialog, isSubmitting: true},
    }));
    const values = this.state.confirmDialog.values;
    const dataPrepType = values?.['ResumeDataPrep'];
    if (dataPrepType) {
      try {
        const {projectId} = this.props;
        const {data} = await axios.get(URL.DATASET_PREP_RESUME(projectId, dataPrepType));
        const container = data.body;
        if (container?.id) {
          const {data: cancelData} = await axios.get(
            URL.OPERATION_CANCEL('PREPARE_DATA'),
            {
              params: {projectId: this.props.projectId, resourceId: container.id},
            }
          );
          if (!cancelData?.errors?.length) {
            const options = this.getOptions();
            await this.props.onFinish('ADD_DATA');
            if (Object.entries(options).length > 1) {
              this.initiate();
            } else {
              this.props.assistantPanel.current?.closeModal();
            }
            toast.success(`Successfully deleted task.`);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.closeConfirmDialog();
      }
    }
  }

  handleSkip() {
    this.props.assistantPanel.current?.closeModal();
  }

  handleSubmit(values) {
    const answer = values?.[0];
    if (answer?.value) {
      this.props.history.push(
        `/project/${this.props.projectId}/data/prepare/${answer.value}?resume=true`
      );
    }
  }

  render() {
    return (
      <ConfirmDialog
        type="confirm"
        title={'Delete task?'}
        message={'Are you sure you want to delete this task?'}
        open={this.state.confirmDialog.visible}
        onClose={this.closeConfirmDialog}
        onOk={this.handleCancel}
        submitLabel={this.props.intl.formatMessage({id: 'form.delete'})}
        disableSubmit={this.state.confirmDialog.isSubmitting}
      />
    );
  }
}

const withRouterForwardRef = Component => {
  const WithRouter = withRouter(({forwardedRef, ...props}) => (
    <Component ref={forwardedRef} {...props} />
  ));

  return forwardRef((props, ref) => <WithRouter {...props} forwardedRef={ref} />);
};

export default withRouterForwardRef(injectIntl(DataPrepareAction, {forwardRef: true}));
