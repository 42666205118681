import React from 'react';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  RadioGroup,
  RadioGroupProps,
} from '@material-ui/core';
import cn from 'classnames';
import {StudioRadio} from './StudioRadio';
import {StudioPopover} from '../StudioPopover';
import './StudioRadioGroup.scss';

type StudioRadioGroupProps = {
  label: string;
  options: Record<string, string>;
  optionsHelpText?: Record<string, string>;
  placeholder?: string;
  error?: string;
  helperText?: string;
  order?: string[];
  disabledOptions?: Record<string, boolean>;
  disabled?: boolean;
  tooltip?: string;
  tooltips?: Record<string, string>;
  RadioGroupProps?: RadioGroupProps;
  className?: string;
} & FormControlProps<'fieldset'>;

export const StudioRadioGroup = ({
  label,
  error,
  helperText,
  options = {},
  optionsHelpText = {},
  order,
  disabledOptions = {},
  disabled,
  tooltip,
  tooltips = {},
  RadioGroupProps,
  className,
  placeholder,
  id,
  ...props
}: StudioRadioGroupProps) => {
  const getOrderedOptions = (options: Record<string, string>, order?: string[]) => {
    if (order && order.length) {
      return order.map(value => [value, options[value]]);
    }
    return Object.entries(options).sort((a, b) => a[1].localeCompare(b[1]));
  };

  return (
    <FormControl
      className={cn('studio-radio-group', className)}
      data-testid="studio-radio-group"
      id={id || RadioGroupProps?.name || 'studio-radio-group'}
      component="fieldset"
      error={Boolean(error)}
      {...props}
    >
      <div className="studio-radio-group__header">
        {label && <InputLabel shrink>{label}</InputLabel>}
        {tooltip && (
          <StudioPopover
            ttPlacement="top"
            infoMessage={tooltip}
            infoIconClass={cn('studio-radio-group__tooltip')}
          />
        )}
      </div>
      <RadioGroup {...RadioGroupProps}>
        {placeholder && (
          <StudioRadio
            value={''}
            label={placeholder}
            disabled={disabled}
            id={`${id || RadioGroupProps?.name}-placeholder`}
          />
        )}
        {getOrderedOptions(options, order).map(([optionValue, text]) => (
          <StudioRadio
            id={`${id || RadioGroupProps?.name}-${optionValue}`}
            key={optionValue}
            value={optionValue}
            label={text}
            disabled={Boolean(disabledOptions[optionValue] || disabled)}
            tooltip={tooltips[optionValue]}
            helpertext={optionsHelpText[optionValue]}
          />
        ))}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
