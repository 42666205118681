import axios from 'axios';
import {getLocation, push} from 'connected-react-router';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {mutate} from 'swr';
import {PostProcessorListUrl} from '../../../api/postprocessor/GetPostProcessorList';
import {toast} from '../../../base-components/StudioToast';
import URL, {ISP_OP as PP_OP, ISP_SUB_OP as PP_SUB_OP} from '../../../config/url';
import {projectFetch} from '../../../store/project';
import {addListener, removeListener} from '../../../store/statusChecker';
import {viewDisableTermination, viewEnableTermination} from '../../../store/view';
import Util from '../../../util';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import {StageAssistantForm} from '../../StageAssistantForm';
import StageAnimation from '../StageAnimation/StageAnimation';

const PROGRESS_TYPE = 'IMPORT_PP';
class PPUploadAction extends Component {
  constructor(props) {
    super(props);

    this.stageAnimation = new StageAnimation();
    this.requestImportFinalization = this.requestImportFinalization.bind(this);
    this.displayPPUploadProgress = this.displayPPUploadProgress.bind(this);
    this.terminatePPUpload = this.terminatePPUpload.bind(this);
    this.monitorStatus = this.monitorStatus.bind(this);
  }

  render() {
    return null;
  }

  componentDidMount() {
    this.monitorStatus();
  }

  componentWillUnmount() {
    this.props.removeStatusListener(PROGRESS_TYPE);
  }

  monitorStatus() {
    this.props.viewEnableTermination();
    this.props.addStatusListener(PROGRESS_TYPE, this.displayPPUploadProgress);
  }

  ppUpload(selectedFiles) {
    const cloudImportRequest = {
      projectId: this.props.projectId,
      files: selectedFiles.map(selectedFile => ({
        fileName: selectedFile.name,
        type: selectedFile.type,
        integrationId: selectedFile.integrationId,
      })),
    };

    axios
      .post(URL.POSTPROCESSOR_OP(PP_OP.IMPORT, PP_SUB_OP.CLOUD), cloudImportRequest)
      .then(res => this.handleClarificationResponse(res.data))
      .catch(error => toast.error(`data upload error: ${error.message}`));
  }

  clarifyImport(importRequest) {
    axios
      .post(URL.POSTPROCESSOR_OP(PP_OP.IMPORT, PP_SUB_OP.CLARIFY), importRequest)
      .then(res => this.handleClarificationResponse(res.data))
      .catch(error => toast.error(`data upload error: ${error.message}`));
  }

  handleClarificationResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.finishUpload(PROGRESS_TYPE);
    } else {
      this.monitorStatus();
    }
  }

  displayPPUploadProgress(status) {
    this.props.setPPActionInProgress('upload');
    if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
      this.props.removeStatusListener(PROGRESS_TYPE);
      if (status.prompts && status.prompts.length > 0) {
        this.showInitialAssistant(status);
      } else {
        this.requestImportFinalization([], status.id);
      }
    }
  }

  showInitialAssistant(taskProgress) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.display(
        this.getQuestionsForm(taskProgress.prompts, taskProgress.defaults),
        PROGRESS_TYPE,
        null,
        this.terminatePPUpload
      );
    }
  }

  getQuestionsForm(questions, defaults) {
    return (
      <StageAssistantForm
        prompts={questions}
        defaults={defaults}
        onSubmit={this.requestImportFinalization}
        onCancel={this.terminatePPUpload}
        title="We need a little more information to finish adding this data."
      />
    );
  }

  requestImportFinalization(answers, resourceId) {
    if (this.props.assistantPanel.current) {
      // TODO: validate this since PP is not part of the stage.
      this.props.assistantPanel.current.hideStage(PROGRESS_TYPE);
    }

    const finalizationRequest = {
      projectId: this.props.projectId,
      resourceId: resourceId,
      answers: answers,
    };

    axios
      .post(URL.POSTPROCESSOR_OP(PP_OP.IMPORT, PP_SUB_OP.FINALIZE), finalizationRequest)
      .then(res => this.handleFinalizationResponse(res.data))
      .catch(error => toast.error(`post processor upload error: ${error.message}`));
  }

  handleFinalizationResponse(response) {
    this.props.viewDisableTermination();
    if (response.prompts && response.prompts.length > 0) {
      this.showInitialAssistant(response);
    } else {
      toast.success('Successfully imported Post processor');
      this.finishUpload(null);
    }
  }

  async finishUpload(stage) {
    if (this.props.onFinish) {
      this.props.onFinish(stage);
      await mutate(PostProcessorListUrl({projectId: this.props.projectId}));
      await this.props.projectFetch(this.props.projectId);
    }
  }

  terminatePPUpload() {
    this.props.viewDisableTermination();
    return axios
      .get(URL.OPERATION_CANCEL(PROGRESS_TYPE), {
        params: {projectId: this.props.projectId},
      })
      .then(res => {
        this.props.assistantPanel.current.hideStage(PROGRESS_TYPE);
        this.props.removeStatusListener(PROGRESS_TYPE);
        this.finishUpload(PROGRESS_TYPE);
      })
      .catch(error => toast.error(`cancel upload error: ${error.message}`));
  }
}

PPUploadAction.propTypes = {
  projectId: PropTypes.string.isRequired,
  assistantPanel: PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)})
    .isRequired,
  onFinish: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  location: getLocation(state),
});

const mapDispatchToProps = {
  addStatusListener: addListener,
  removeStatusListener: removeListener,
  navigate: push,
  projectFetch,
  viewEnableTermination,
  viewDisableTermination,
};

export {PPUploadAction as PPUploadActionBase};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(
  PPUploadAction
);
