import React, {Component} from 'react';
import PropTypes from 'prop-types';

class CloseIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="12px"
          height="13px"
          viewBox="0 0 12 13"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <polygon
              id="closeIcon"
              points="17.8398438 7.8515625 13.1523438 12.5 17.8398438 17.1484375 16.6679688 18.3203125 11.9804688 13.671875 7.33203125 18.3203125 6.16015625 17.1484375 10.8085938 12.5 6.16015625 7.8515625 7.33203125 6.6796875 11.9804688 11.328125 16.6679688 6.6796875"
            ></polygon>
          </defs>
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-850.000000, -3236.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 2439.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-close-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#closeIcon"></use>
                      </mask>
                      <use id="close" fill="currentColor" xlinkHref="#closeIcon"></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

CloseIcon.propTypes = {
  className: PropTypes.string,
};

export default CloseIcon;
