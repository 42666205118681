import React from 'react';
import {Card, CardContent, Typography, CardActionArea} from '@material-ui/core';
import cn from 'classnames';
import ArrowRightIcon from '../../../../assets/icons/ArrowRightIcon';
import {FormattedMessage} from 'react-intl';
import './IntegrationTile.scss';

type IntegrationTileProps = {
  imageSrc: string;
  title: string;
  description: string;
  className: string;
  onClick: () => void;
};

export const IntegrationTile = ({
  imageSrc,
  title,
  description,
  className,
  onClick,
}: IntegrationTileProps) => (
  <Card className={cn('integration-tile', className)} data-testid="integration-tile">
    <CardActionArea onClick={onClick} className="integration-tile__action-area">
      <img
        className="integration-tile__media"
        src={imageSrc}
        alt={title}
        aria-hidden="true"
      />
      <CardContent className="integration-tile__content">
        <Typography variant="h6" className="integration-tile__title">
          {title}
        </Typography>
        <Typography variant="body2" className="integration-tile__description">
          {description}
        </Typography>
      </CardContent>
      <div className="integration-tile__cta">
        <div className="integration-tile__cta-text">
          <FormattedMessage id="form.createNewIntegration" />
        </div>
        <ArrowRightIcon />
      </div>
    </CardActionArea>
  </Card>
);
