import React, {ReactNode, useState, useMemo} from 'react';
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  FormHelperText,
  ListItemIcon,
  SelectProps,
  InputLabelProps,
  FormControlProps,
  ListSubheader,
  Chip,
  Button,
} from '@material-ui/core';
import cn from 'classnames';
import ChevronDownIcon from '../../assets/icons/ChevronDownIcon';
import CheckIcon from '../../assets/icons/CheckIcon';
import {StudioPopover} from '../StudioPopover';
import {StudioCheckbox} from '../StudioCheckbox';
import './StudioSelect.scss';

export type StudioSelectProps = {
  options?: Record<string, string>;
  className?: string;
  label?: string;
  additionalLabel?: React.ReactNode;
  order?: string[];
  disabledOptions?: Record<string, boolean>;
  groupedOptions?: Array<{
    name: string;
    options: Array<{value: string; text: string}>;
  }>;
  tooltip?: string;
  tooltipPlacement?: string;
  fixedTooltip?: boolean;
  helperText?: ReactNode;
  note?: string;
  id?: string;
  InputLabelProps?: InputLabelProps;
  SelectProps?: SelectProps;
  placeholder?: string;
  disablePlaceholder?: boolean;
  dataTestId?: string;
  getAuxiliaryLabel?: ((value: string) => string) | null;
  onChipDelete?: (value: string) => void;
  renderEndAdornment?: ((value: string) => ReactNode) | null;
  onLoadMore?: () => Promise<void>;
  hasMore?: boolean;
  disabled?: boolean;
  placeholderClassName?: string;
} & FormControlProps & {'data-testid'?: string};

export const StudioSelect = ({
  options = {},
  groupedOptions,
  className,
  label,
  tooltipPlacement = 'bottom',
  additionalLabel,
  order,
  disabledOptions,
  tooltip,
  fixedTooltip = false,
  helperText,
  note,
  id,
  InputLabelProps = {},
  SelectProps = {},
  placeholder,
  disablePlaceholder,
  dataTestId,
  getAuxiliaryLabel,
  onChipDelete,
  renderEndAdornment,
  onLoadMore,
  hasMore,
  disabled,
  placeholderClassName,
  ...rest
}: StudioSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectId = useMemo(
    () => id || SelectProps.name || dataTestId || rest['data-testid'] || 'studio-select',
    [id, SelectProps.name, dataTestId, rest]
  );
  const isMultiSelect = useMemo(() => Boolean(SelectProps.multiple), [
    SelectProps.multiple,
  ]);
  const selectedValue = useMemo(() => {
    let selectedValue = SelectProps.value ?? '';

    if (isMultiSelect) {
      selectedValue = selectedValue || [];
    } else {
      const availableOptions = [
        ...Object.keys(options),
        ...(groupedOptions?.flatMap(group => group.options).map(option => option.value) ||
          []),
      ];

      if (!availableOptions.includes(String(selectedValue))) {
        selectedValue = '';
      }
    }

    return selectedValue;
  }, [SelectProps.value, isMultiSelect, options, groupedOptions]);

  const areOptionsGrouped = groupedOptions != null;
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleLoadMore = async () => {
    if (onLoadMore) {
      setIsLoadingMore(true);
      await onLoadMore();
      setIsLoadingMore(false);
    }
  };

  const getOrderedOptions = (options: Record<string, string>, order?: string[]) => {
    if (order && order.length) {
      return order.map(value => [value, options[value]]);
    }
    return Object.entries(options).sort((a, b) => a[1].localeCompare(b[1]));
  };

  const renderOption = (value: string, text: string) => {
    const isSelected =
      isMultiSelect && Array.isArray(selectedValue)
        ? selectedValue.indexOf(value) !== -1
        : selectedValue === value;
    return (
      <MenuItem
        key={value}
        value={value}
        disabled={Boolean(disabledOptions?.[value])}
        divider
        className={cn(
          'studio-select__item',
          isSelected && 'studio-select__item--selected',
          isSelected &&
            (isMultiSelect || areOptionsGrouped) &&
            'studio-select__item--plain-selected'
        )}
        data-testid="studio-select-item"
        id={`${selectId}-${value}`}
      >
        {isMultiSelect && (
          <StudioCheckbox name={value} checked={isSelected} size="small" />
        )}
        <span className="studio-select__text">{text}</span>
        {getAuxiliaryLabel && (
          <span className="studio-select__auxiliary-label">
            {getAuxiliaryLabel(value)}
          </span>
        )}
        {!isMultiSelect && isSelected && (
          <ListItemIcon className="studio-select__selected-icon">
            <CheckIcon />
          </ListItemIcon>
        )}
        {renderEndAdornment && (
          <div className="studio-select__end-icon">{renderEndAdornment(value)}</div>
        )}
      </MenuItem>
    );
  };

  const renderMultipleValues = (selected: unknown) => {
    if (Array.isArray(selected)) {
      if (selected.length === 0 && placeholder) {
        return placeholder;
      }
      return (
        <div className="studio-select__chips-container">
          {selected.map((value: string, i) => {
            const text = areOptionsGrouped
              ? groupedOptions
                  ?.flatMap(({options}) => options)
                  .find(option => option.value === value)?.text
              : options[value];
            return (
              <Chip
                disabled={disabled}
                key={i}
                label={text}
                variant="outlined"
                onMouseDown={e => {
                  e.stopPropagation();
                }}
                onDelete={onChipDelete ? () => onChipDelete(value) : undefined}
              />
            );
          })}
        </div>
      );
    }
  };

  return (
    <>
      <FormControl
        className={cn('studio-select', className)}
        disabled={disabled}
        {...rest}
      >
        {label && (
          <div className="studio-select__row">
            <InputLabel shrink {...InputLabelProps}>
              {label}
            </InputLabel>
            {additionalLabel}
            {(tooltip || fixedTooltip) && tooltipPlacement === 'top' && (
              <StudioPopover
                infoIconClass={cn(
                  'studio-select__tooltip',
                  fixedTooltip && !tooltip && 'studio-select__tooltip--hidden'
                )}
                infoMessage={tooltip}
              />
            )}
          </div>
        )}
        <div className="studio-select__row">
          <Select
            id={selectId}
            IconComponent={ChevronDownIcon}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              className: 'studio-select__menu',
            }}
            disableUnderline
            displayEmpty={!!placeholder}
            data-testid={dataTestId ?? 'studio-select'}
            renderValue={isMultiSelect ? renderMultipleValues : undefined}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            {...SelectProps}
            value={selectedValue}
          >
            {!isMultiSelect && placeholder && (
              <MenuItem
                value=""
                disabled={disablePlaceholder}
                className={cn(
                  'studio-select__item',
                  placeholderClassName ? placeholderClassName : ''
                )}
                data-testid="studio-select-item"
                id={`${selectId}-placeholder`}
              >
                {placeholder}
              </MenuItem>
            )}
            {areOptionsGrouped
              ? groupedOptions?.flatMap(({name, options}) => [
                  <ListSubheader
                    key={name}
                    disableSticky
                    className="studio-select__group-header"
                  >
                    {name}
                  </ListSubheader>,
                  ...options.map(({value, text}) => renderOption(value, text)),
                ])
              : getOrderedOptions(options, order).map(([optionValue, text]) =>
                  renderOption(optionValue, text)
                )}
            {isMultiSelect && (
              <div
                className="studio-select__menu-footer"
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={event => {
                    event.stopPropagation();
                    setIsOpen(false);
                  }}
                >
                  Done
                </Button>
              </div>
            )}
            {onLoadMore && hasMore && (
              <div
                className="studio-select__menu-footer"
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={isLoadingMore}
                  onClick={event => {
                    event.stopPropagation();
                    handleLoadMore();
                  }}
                >
                  {isLoadingMore ? 'Loading...' : 'Load More'}
                </Button>
              </div>
            )}
          </Select>
          {(tooltip || fixedTooltip) && tooltipPlacement === 'bottom' && (
            <StudioPopover
              infoIconClass={cn(
                'studio-select__tooltip',
                fixedTooltip && !tooltip && 'studio-select__tooltip--hidden'
              )}
              infoMessage={tooltip}
            />
          )}
        </div>
        {note && <FormHelperText>{note}</FormHelperText>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};
