import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {Clear, GetApp, MoreVert, RotateLeft, Delete, Edit} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';
import cn from 'classnames';
import {debounce} from 'lodash';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import {deleteDeploymentDelete} from '../../../api/deployment/DeploymentDelete';
import {deleteDevice} from '../../../api/device/DeviceList';
import {useTestListByLatest} from '../../../api/test/TestList';
import {StudioCheckbox} from '../../../base-components/StudioCheckbox';
import {ConfirmDialog} from '../../../base-components/StudioConfirmDialog';
import {DropArea} from '../../../base-components/StudioDropArea';
import HumanReadableTime from '../../../base-components/StudioHumanReadableTime/HumanReadableTime';
import {StudioSearch} from '../../../base-components/StudioSearch';
import {StudioSelect} from '../../../base-components/StudioSelect';
import {StudioTab, StudioTabPanel, StudioTabs} from '../../../base-components/StudioTab';
import {StudioTextField} from '../../../base-components/StudioTextField';
import {toast} from '../../../base-components/StudioToast';
import {StudioTooltip} from '../../../base-components/StudioTooltip';
import {DeploymentDetailsInfo} from '../../../types/deployment/DeploymentDetailsInfo';
import {TestDeploymentQueryResponse} from '../../../types/deployment/TestDeploymentQueryResponse';
import {Application} from '../../../types/project/Application';
import {Project} from '../../../types/project/Project';
import {TestDeploymentInfo} from '../../../types/test/TestDeploymentInfo';
import {AddStreamingDeviceForm} from '../AddStreamingDeviceForm/AddStreamingDeviceForm';
import {DeployExportDialog} from '../DeployExportDialog';
import {DuplicateDialog} from '../DuplicateDialog';
import {DeleteApplicationDialog} from '../DeleteApplicationDialog';
import {FileExplorer, FileUploadProgress} from '../FileExplorer';
import {
  renameDuplicates,
  resetDeploy,
  RuntimeModeType,
  setDefaultTestName,
  setDeployConfig,
  setIsAddDeviceModalOpen,
  setIsDuplicateModalOpen,
  setIsDeleteModalOpen,
  setIsExportModalOpen,
  setPendingFiles,
  setSelectedDeploymentIds,
  setStreamingDevices,
  TASK_STATUS,
  TestOutputType,
  TestSourceType,
  useDeploy,
  INFERENCE_SOURCE_TEXT,
  VIDEO_SOURCES,
  RUNTIME_MODE_OPTIONS,
} from '../useDeploy';
import {useDeployTask} from './useDeployTask';
import {useTaskManager} from './useTaskManager';
import {
  DeploymentPostProcessingParameters,
  PreProcessingParameters,
} from './TestDeployParameters';
import {RootState} from '../../../store';
import {useSelector} from 'react-redux';
import './DeployConfigPanel.scss';
import {useStudioMode} from '../../StudioMode/StudioModeProvider';
import {StreamingDevice} from '../../../types/deployment/StreamingDeviceResponse';
import {
  FetchAppPostProcessor,
  FetchModelPostProcessor,
  FetchPreProcessor,
  supportsImages,
  supportsVideo,
} from '../../ONNXRuntimeContainer/ONNXRuntimeContainer';
import {InputSource} from '../../../types/deployment/InferenceInputSource';
import {TEST_PANEL_SETTINGS} from '../../../config/constants';
import {isPyAnalyticsModel} from '../../../utils';

export const ACCEPTED_FILE_TYPES = [
  '.jpg',
  '.jpeg',
  '.jpe',
  '.jif',
  '.jfif',
  '.jfi',
  '.png',
  '.gif',
  '.webp',
  '.tiff',
  '.tif',
  '.psd',
  '.raw',
  '.arw',
  '.cr2',
  '.nrw',
  '.k25',
  '.bmp',
  '.dib',
  '.heif',
  '.heic',
  '.ind',
  '.indd',
  '.indt',
  '.jp2',
  '.j2k',
  '.jpf',
  '.jpx',
  '.jpm',
  '.mj2',
  '.svg',
  '.svgz',
  '.ai',
  '.eps',
  '.y',
];

export const getTestSource = (test: TestDeploymentInfo | DeploymentDetailsInfo) => {
  if (test.deploymentInput?.splitType === 'TEST') {
    return <FormattedMessage id="test.testSet" />;
  }
  if (test.deploymentInput?.splitType === 'VALIDATION') {
    return <FormattedMessage id="test.validationSet" />;
  }
  if (test.deploymentInput?.splitType === 'TRAIN') {
    return <FormattedMessage id="test.trainSet" />;
  }
  return <FormattedMessage id="test.userDataFormat" />;
};

export const getTestType = (test: TestDeploymentInfo | DeploymentDetailsInfo) => {
  if (test?.deploymentType === 'VALIDATION') {
    return <FormattedMessage id="test.validationRun" />;
  }
  if (test?.deploymentType === 'SAMPLE_INFERENCE') {
    return <FormattedMessage id="test.inferenceRun" />;
  }
  if (test?.deploymentType === 'VALIDATION_PLUS_INFERENCE') {
    return <FormattedMessage id="test.testInferenceRun" />;
  }
  if (test?.deploymentType === 'INFERENCE') {
    return <FormattedMessage id="test.inferenceRun" />;
  }
  return null;
};

type HistoryItemProps = {
  deployment: TestDeploymentInfo;
  application?: Application;
  onDelete: (event: {id: string}) => void;
  onSelect: (event: {ids: string[]}) => void;
  onDeploy: (event: {id: string; applicationId?: string}) => void;
  search: string;
  selectedIds: string[];
};

const HistoryItem = ({
  deployment,
  onDelete,
  onDeploy,
  onSelect,
  application,
  selectedIds,
}: HistoryItemProps) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const accuracy =
    deployment.deploymentStatistics?.accuracy == null &&
    deployment.deploymentStatistics?.lastTop1Accuracy == null
      ? null
      : Math.max(
          deployment.deploymentStatistics?.accuracy ?? 0,
          deployment.deploymentStatistics?.lastTop1Accuracy ?? 0
        );

  return (
    <Card data-testid="test-history-item" className="dplmt-history-card">
      <CardHeader
        action={
          <>
            <IconButton
              className="dplmt-history-card-action"
              onClick={event => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
              }}
            >
              <MenuItem
                onClick={async event => {
                  event.stopPropagation();
                  onDeploy({id: deployment.id, applicationId: deployment.applicationId});
                }}
              >
                Deploy
              </MenuItem>
              <MenuItem
                onClick={async event => {
                  event.stopPropagation();
                  await deleteDeploymentDelete({deploymentId: deployment.id});
                  onDelete({id: deployment.id});
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </>
        }
        className="dplmt-history-card-header"
        title={
          <StudioCheckbox
            className="dplmt-history-card-checkbox"
            id={deployment.id}
            name={deployment.id}
            label={deployment.name}
            checked={selectedIds.includes(deployment.id)}
            nativeTooltipTitle={deployment.name}
            nativeTooltip={true}
            onChange={(_event, checked) => {
              if (checked && !selectedIds.includes(deployment.id)) {
                onSelect({
                  ids: [...selectedIds, deployment.id],
                });
              } else {
                onSelect({
                  ids: selectedIds.filter(deploymentId => deploymentId !== deployment.id),
                });
              }
            }}
          />
        }
      />
      <CardContent
        className="dplmt-history-card-contents"
        onClick={_event => {
          onSelect({ids: [deployment.id]});
        }}
      >
        <FormattedMessage
          id="testHistory.createdOn"
          values={{creationTime: <HumanReadableTime date={deployment.creationTime} />}}
        />
        <br />
        <FormattedMessage
          id="testHistory.version"
          values={{version: deployment.version}}
        />
        <br />
        <FormattedMessage
          id="testHistory.nodeName"
          values={{node: deployment.gspNodeName}}
        />
        <br />
        <FormattedMessage
          id="testHistory.applicationName"
          values={{application: deployment.applicationName}}
        />
        <br />
        {deployment.modelName && (
          <>
            <FormattedMessage
              id="testHistory.modelName"
              values={{model: deployment.modelName}}
            />
            <br />
          </>
        )}
        <FormattedMessage
          id="testHistory.datasetName"
          values={{dataset: deployment.datasetName}}
        />
        <br />
        {getTestSource(deployment) && (
          <>
            <FormattedMessage
              id="testHistory.testSource"
              values={{source: getTestSource(deployment)}}
            />
            <br />
          </>
        )}
        {getTestType(deployment) && (
          <>
            <FormattedMessage
              id="testHistory.testType"
              values={{type: getTestType(deployment)}}
            />
            <br />
          </>
        )}
        {!isPyAnalyticsModel(application?.model) && (
          <div className="dplmt-history-card-stats">
            {(deployment.deploymentType === 'VALIDATION' ||
              deployment.deploymentType === 'SAMPLE_INFERENCE' ||
              deployment.deploymentType === 'VALIDATION_PLUS_INFERENCE') &&
              accuracy != null && (
                <div>
                  <FormattedMessage
                    id={
                      application?.model?.modelPurpose === 'Detection'
                        ? 'testHistory.map'
                        : 'testHistory.accuracy'
                    }
                    values={{
                      accuracy: (
                        <FormattedNumber
                          style="percent" // eslint-disable-line react/style-prop-object
                          maximumFractionDigits={2}
                          value={accuracy / 100}
                        />
                      ),
                    }}
                  />
                </div>
              )}
            <div>
              {deployment.deploymentStatistics?.fps !== undefined &&
                deployment.deploymentStatistics.fps > 0 && (
                  <FormattedMessage
                    id="testHistory.fps"
                    values={{
                      fps: intl.formatNumber(deployment.deploymentStatistics.fps, {
                        maximumFractionDigits: 0,
                      }),
                    }}
                  />
                )}
            </div>
            <div>
              {deployment.deploymentStatistics?.latency !== undefined &&
                deployment.deploymentStatistics.latency > 0 && (
                  <FormattedMessage
                    id="testHistory.latency"
                    values={{
                      latency: intl.formatNumber(
                        deployment.deploymentStatistics.latency,
                        {
                          style: 'unit',
                          unit: 'millisecond',
                        }
                      ),
                    }}
                  />
                )}
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

function useResumeDeployIfExists(projectId: string) {
  const {resumeDeploy} = useDeployTask(projectId);
  useEffect(
    function resumeDeployIfExists() {
      resumeDeploy();
    },
    [resumeDeploy]
  );
}

function useDefaultConfig({
  latestApplicationId,
  hasDefaults,
  historyItems,
  projectId,
  onSet,
}: {
  latestApplicationId: string;
  hasDefaults: boolean;
  historyItems: TestDeploymentQueryResponse | undefined;
  projectId: string;
  onSet: () => void;
}) {
  const [{deployConfig}, dispatch] = useDeploy();
  const {handleSubmit} = useTaskManager({projectId});

  useEffect(() => {
    if (!hasDefaults && latestApplicationId && deployConfig.name) {
      const newDeployConfig: DeployConfigState = {
        ...deployConfig,
        deploymentId: latestApplicationId,
        testSource: '',
      };
      dispatch(setDeployConfig(newDeployConfig));
      onSet();
    }
  }, [
    deployConfig,
    dispatch,
    latestApplicationId,
    handleSubmit,
    hasDefaults,
    historyItems,
    onSet,
  ]);
}

export type DeployConfigPanelProps = {
  className?: string;
  applications: Application[];
  isTaskInProgress: boolean;
  projectData: Project;
  projectId: string;
  selectedTab: number;
  onTabChange: (tab: number) => void;
  fetchProjectData: Function;
  fetchImageSets: Function;
  fetchGspList: Function;
  shouldBeVisible: boolean;
};

type DeployState = ReturnType<typeof useDeploy>[0];
type DeployConfigState = DeployState['deployConfig'];

export const DeployConfigPanel = ({
  className,
  applications,
  isTaskInProgress,
  projectData,
  projectId,
  selectedTab,
  onTabChange,
  fetchProjectData,
  fetchImageSets,
  fetchGspList,
  shouldBeVisible,
}: DeployConfigPanelProps) => {
  const signalingServerUrl = useSelector(
    (state: RootState) => state.config.videoServerUrl
  );
  const history = useHistory();
  const intl = useIntl();
  const [
    {
      deployTask,
      inferenceTask,
      onnxTask,
      deployConfig,
      streamingDevices,
      isExportModalOpen,
      isDuplicateModalOpen,
      isDeleteModalOpen,
      selectedDeploymentIds,
      imageSets,
      gspList,
    },
    dispatch,
  ] = useDeploy();
  const {handleSubmit, handleCancel} = useTaskManager({projectId});
  const [search, setSearch] = useState('');
  const [defaultInput, setDefaultInput] = useState('');
  const [isPreProcessingValid, setIsPreProcessingValid] = useState(true);
  const [isPostProcessingValid, setIsPostProcessingValid] = useState(true);
  const [initialDevice, setInitialDevice] = useState('');
  const [confirmDeleteDevice, setConfirmDeleteDevice] = useState<{
    isOpen: boolean;
    id: string | null;
  }>({isOpen: false, id: null});
  const [confirmEndTask, setConfirmEndTask] = useState(false);
  const [hasDefaults, setHasDefaults] = useState(false);
  const [addedAsset, setAddedAsset] = useState<{name: string; url: string} | null>(null);
  const [availableTestSources, setAvailableTestSources] = useState<
    {
      name: string;
      options: {
        text: string;
        value: string;
      }[];
    }[]
  >([]);

  const latestApplicationId = useMemo(
    () =>
      applications.sort(
        (a, b) => Date.parse(b.lastUpdated) - Date.parse(a.lastUpdated)
      )?.[0]?.id ?? '',
    [applications]
  );

  const {data: historyItems, mutate: mutateHistoryItems} = useTestListByLatest({
    projectId,
    search,
  });

  const selectLatestHistoryItem = useCallback(
    async function selectLatestHistoryItem() {
      const items = await mutateHistoryItems();
      const latestDeploymentId = items?.entries?.[0]?.id;

      if (latestDeploymentId) {
        dispatch(setSelectedDeploymentIds([latestDeploymentId]));
      }
    },
    [dispatch, mutateHistoryItems]
  );

  useResumeDeployIfExists(projectId);

  useDefaultConfig({
    latestApplicationId,
    hasDefaults,
    historyItems,
    projectId,
    onSet() {
      setHasDefaults(true);
    },
  });

  useEffect(() => {
    if (deployTask.status === TASK_STATUS.COMPLETE) {
      selectLatestHistoryItem();
    }
  }, [deployTask.status, selectLatestHistoryItem]);

  useEffect(() => {
    dispatch(
      setDefaultTestName(
        projectData?.name
          ? projectData?.name.replaceAll(' ', '_') +
              '-' +
              ((historyItems?.count ?? 0) + 1)
          : ''
      )
    );
  }, [dispatch, historyItems?.count, projectData?.name, projectData?.nextVersion]);

  const {mode} = useStudioMode();
  useEffect(() => {
    dispatch(
      setDeployConfig({
        deploymentId: latestApplicationId,
        testSource: '',
        outputTypes: [],
      })
    );
  }, [latestApplicationId, dispatch]);

  const selectedApplication = applications.find(
    application => application.id === deployConfig.deploymentId
  );

  const isSelectedApplicationIspOnly =
    Boolean(selectedApplication?.transformation?.id) &&
    !Boolean(selectedApplication?.model?.id);
  const isStatsNotSupported =
    selectedApplication?.model?.type === 'PY_PICASSO' ||
    selectedApplication?.model?.type === 'OPENCV';
  const hasIsp = Boolean(
    selectedApplication?.transformation?.transformationType === 'ISP'
  );
  const isSelectedAppONNXWeb = selectedApplication?.runtimeType === 'ONNX_RUNTIME_WEB';
  const inferenceSourceTypes = selectedApplication?.inputSources?.filter(
    is => is !== 'DEVICE_CAMERA'
  );

  useEffect(() => {
    dispatch(
      setDeployConfig(
        isSelectedAppONNXWeb
          ? {
              isONNXRuntimeWeb: true,
              testSource: 'USER_DATA',
            }
          : {isONNXRuntimeWeb: false}
      )
    );
  }, [dispatch, isSelectedAppONNXWeb]);

  useEffect(() => {
    if (selectedApplication) {
      fetchGspList(selectedApplication.projectId, selectedApplication.id);
      if (inferenceSourceTypes)
        dispatch(setDeployConfig({inferenceSource: inferenceSourceTypes[0]}));
    }
  }, [deployConfig.deploymentId, selectedTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const err = (x?: string) => {
    if (x) {
      toast.error(x);
    }
  };

  useEffect(() => {
    if (!initialDevice && gspList.length > 0) {
      const dev = gspList[0].id;
      dispatch(setDeployConfig({nodeId: dev}));
      setInitialDevice(dev);
    }
  }, [dispatch, gspList, initialDevice, setInitialDevice]);

  const pre = FetchPreProcessor(selectedApplication, err);
  const modelPost = FetchModelPostProcessor(selectedApplication, err);
  const appPost = FetchAppPostProcessor(selectedApplication, err);
  const onnxWebImagesSupported = supportsImages(pre, modelPost, appPost);
  const onnxWebVideoSupported = supportsVideo(pre, modelPost, appPost);
  const imagesSupported = !isSelectedAppONNXWeb || onnxWebImagesSupported;
  const videoSupported = !hasIsp && (!isSelectedAppONNXWeb || onnxWebVideoSupported);

  const userDataSourceTypes = inferenceSourceTypes
    ?.filter(
      (is: InputSource) =>
        (VIDEO_SOURCES.has(is) && videoSupported) || (is === 'IMAGES' && imagesSupported)
    )
    .reduce((obj, item) => {
      return {...obj, [item]: INFERENCE_SOURCE_TEXT.get(item)};
    }, {});

  const canRunWithUserData =
    userDataSourceTypes && Object.keys(userDataSourceTypes).length > 0;
  const canRunWithAppData =
    inferenceSourceTypes && inferenceSourceTypes.includes('APP_INPUT');
  const canRunWithTextData =
    inferenceSourceTypes && inferenceSourceTypes.includes('TEXT_INPUT');

  const defaultUserDataSource = isSelectedAppONNXWeb
    ? onnxWebImagesSupported
      ? 'IMAGES'
      : onnxWebVideoSupported
      ? 'WEB_CAMERA'
      : '' // Should not be visible, see getTestSourceOptions()
    : deployConfig.inferenceSource;
  useEffect(() => {
    dispatch(setDeployConfig({inferenceSource: defaultUserDataSource}));
  }, [dispatch, defaultUserDataSource]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearch = useCallback(debounce(setSearch, 500), []);

  const trainImagesExist = Boolean(
    selectedApplication?.dataSet?.labelDistribution?.TRAIN?.imagesCount
  );
  const testImagesExist = Boolean(
    selectedApplication?.dataSet?.labelDistribution?.TEST?.imagesCount
  );
  const validationImagesExist = Boolean(
    selectedApplication?.dataSet?.labelDistribution?.VALIDATION?.imagesCount
  );
  const datasetExists = trainImagesExist || testImagesExist || validationImagesExist;
  const datasetSourceSelected =
    deployConfig.testSource === 'TRAIN' ||
    deployConfig.testSource === 'VALIDATION' ||
    deployConfig.testSource === 'TEST';
  const readyToRunOnDataset =
    datasetSourceSelected && deployConfig.outputTypes.length > 0;
  const readyToRunOnImageSet =
    deployConfig.testSource === 'IMAGE_SETS' &&
    deployConfig.inferenceImageSetId !== undefined;
  const readyToRunOnUserData =
    (isSelectedAppONNXWeb || deployConfig.testSource === 'USER_DATA') &&
    ((deployConfig.inferenceSource === 'IMAGES' && deployConfig.files.length > 0) ||
      (deployConfig.inferenceSource === 'IP_CAMERA' && Boolean(deployConfig.ipCamera)) ||
      deployConfig.inferenceSource === 'WEB_CAMERA' ||
      (deployConfig.inferenceSource === 'FILE' && Boolean(deployConfig.videoFile)) ||
      deployConfig.inferenceSource === 'APP_INPUT' ||
      deployConfig.inferenceSource === 'TEXT_INPUT');
  const readyToRunOnAppInput = deployConfig.testSource === 'APP_INPUT';
  const readyToRunOnTextInput = deployConfig.testSource === 'TEXT_INPUT';
  const readyToRunInBrowser = isSelectedAppONNXWeb && readyToRunOnUserData;
  const readyToRunOnGsp =
    !isSelectedAppONNXWeb &&
    deployConfig.nodeId !== undefined &&
    (readyToRunOnDataset ||
      readyToRunOnImageSet ||
      readyToRunOnUserData ||
      readyToRunOnAppInput ||
      readyToRunOnTextInput);
  const readyToRun =
    selectedApplication !== undefined &&
    isPreProcessingValid &&
    isPostProcessingValid &&
    (readyToRunInBrowser || readyToRunOnGsp);

  const defaultEnvironmentVariables: Record<string, string> = useMemo(
    () => ({...selectedApplication?.model?.frameworkParameters?.environment}),
    [selectedApplication]
  );

  useEffect(() => {
    const testSources = [
      ...(datasetExists && !isSelectedAppONNXWeb
        ? [
            {
              name: intl.formatMessage({
                id: 'test.existingSource',
              }),
              options: [
                ...(trainImagesExist
                  ? [
                      {
                        value: 'TRAIN',
                        text: intl.formatMessage({
                          id: 'test.trainSet',
                        }),
                      },
                    ]
                  : []),
                ...(testImagesExist
                  ? [
                      {
                        value: 'TEST',
                        text: intl.formatMessage({
                          id: 'test.testSet',
                        }),
                      },
                    ]
                  : []),
                ...(validationImagesExist
                  ? [
                      {
                        value: 'VALIDATION',
                        text: intl.formatMessage({
                          id: 'test.validationSet',
                        }),
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      {
        name: intl.formatMessage({
          id: 'test.customSource',
        }),
        options: [
          ...(canRunWithUserData
            ? [
                {
                  value: 'USER_DATA',
                  text: intl.formatMessage({
                    id: 'test.userData',
                  }),
                },
              ]
            : []),
          ...(canRunWithAppData
            ? [
                {
                  value: 'APP_INPUT',
                  text: INFERENCE_SOURCE_TEXT.get('APP_INPUT') || '',
                },
              ]
            : []),
          ...(canRunWithTextData
            ? [
                {
                  value: 'TEXT_INPUT',
                  text: INFERENCE_SOURCE_TEXT.get('TEXT_INPUT') || '',
                },
              ]
            : []),
          ...(imageSets.length > 0
            ? [
                {
                  value: 'IMAGE_SETS',
                  text: intl.formatMessage({
                    id: 'test.imageSets',
                  }),
                },
              ]
            : []),
        ],
      },
    ];
    setAvailableTestSources(testSources);
  }, [
    intl,
    datasetExists,
    isSelectedAppONNXWeb,
    trainImagesExist,
    testImagesExist,
    validationImagesExist,
    canRunWithUserData,
    canRunWithAppData,
    canRunWithTextData,
    imageSets,
  ]);

  useEffect(() => {
    const isTestInOptions = availableTestSources?.[0]?.options?.findIndex(
      option => option?.value === 'TEST'
    );
    const firstOption = availableTestSources?.[0]?.options?.[0]?.value;
    const preselectedOption =
      isTestInOptions === 1 ? 'TEST' : ((firstOption || '') as TestSourceType);

    dispatch(
      setDeployConfig({
        testSource:
          deployConfig.testSource.length > 0
            ? deployConfig.testSource
            : preselectedOption,
        inferenceSource: isSelectedAppONNXWeb ? 'IMAGES' : deployConfig.inferenceSource,
      })
    );
  }, [
    dispatch,
    availableTestSources,
    deployConfig.testSource,
    isSelectedAppONNXWeb,
    deployConfig.inferenceSource,
  ]);

  const videoFiles = useMemo(
    () => streamingDevices.filter(device => device.type === 'FILE'),
    [streamingDevices]
  );
  const ipCameras = useMemo(
    () => streamingDevices.filter(device => device.type === 'IP_CAMERA'),
    [streamingDevices]
  );

  useEffect(() => {
    if (addedAsset) {
      const {name, url} = addedAsset;
      let newDeployConfig;

      switch (deployConfig.inferenceSource) {
        case 'FILE':
          const videoFile = videoFiles.find(
            device => device.name === name && device.url === url
          )?.id;

          if (videoFile) {
            newDeployConfig = {videoFile};
          }
          break;
        case 'IP_CAMERA':
          const ipCamera = ipCameras.find(
            device => device.name === name && device.url === url
          )?.id;

          if (ipCamera) {
            newDeployConfig = {ipCamera};
          }
          break;
        default:
          break;
      }

      if (newDeployConfig) {
        dispatch(setDeployConfig(newDeployConfig));
        setAddedAsset(null);
      }
    }
  }, [ipCameras, videoFiles, addedAsset, deployConfig, dispatch]);

  const imageSetsMap: Record<string, string> = {};
  const imageSetsOrder: string[] = [];
  for (const imageSet of imageSets) {
    imageSetsMap[imageSet.id] = imageSet.name;
    imageSetsOrder.push(imageSet.id);
  }

  const handleFileListChange = (newFiles: File[]) => {
    const {files} = deployConfig;
    const filesMap = new Map<string, File>();
    const uniqueNewFiles: File[] = [];

    for (const file of files) {
      filesMap.set(file.name, file);
    }

    for (const newFile of newFiles) {
      if (!filesMap.has(newFile.name)) {
        uniqueNewFiles.push(newFile);
      }
    }

    if (newFiles.length > uniqueNewFiles.length) {
      dispatch(setPendingFiles(newFiles));
      dispatch(setIsDuplicateModalOpen(true));
      return;
    }

    dispatch(
      setDeployConfig({inferenceSource: 'IMAGES', files: [...files, ...uniqueNewFiles]})
    );
  };

  const handleFileListDelete = (item: File) => {
    const {files} = deployConfig;
    const newFiles = files.filter(file => file.name !== item.name);
    dispatch(setDeployConfig({inferenceSource: 'IMAGES', files: newFiles}));
  };

  const handleFileListClear = () => {
    dispatch(setDeployConfig({inferenceSource: 'IMAGES', files: []}));
  };

  const getFileIcon = () => {
    const {status} = inferenceTask;
    if (status === TASK_STATUS.IDLE) {
      return 'delete';
    } else if (status === TASK_STATUS.COMPLETE) {
      return 'check';
    }
    return null;
  };

  const renderFileUpload = () => {
    const label = intl.formatMessage({
      id: isSelectedApplicationIspOnly
        ? 'deployments.uploadIspOnly'
        : 'deployments.upload',
    });
    return (
      <div className="dplmt-config-field" style={{flexDirection: 'column'}}>
        <div className="textFieldlabel">{label}</div>
        {!signalingServerUrl && (
          <Alert severity="warning" className="dplmt-config-field__alert">
            Video inference is not available.
          </Alert>
        )}
        <DropArea
          onChange={handleFileListChange}
          disabled={isTaskInProgress}
          accept={ACCEPTED_FILE_TYPES.join(',')}
        />
        <FileExplorer
          files={deployConfig.files}
          onDelete={handleFileListDelete}
          onClear={handleFileListClear}
          size="small"
          icon={getFileIcon()}
        />
      </div>
    );
  };

  const handleDeviceDelete = async (id: string) => {
    try {
      const response = await deleteDevice(id);
      if (!response?.errors?.length) {
        dispatch(setDeployConfig({ipCamera: ''}));
        dispatch(setDeployConfig({videoFile: ''}));
        dispatch(
          setStreamingDevices(streamingDevices.filter(device => device.id !== id))
        );
        toast.success(intl.formatMessage({id: 'test.deleteDeviceSuccessMessage'}));
      }
    } catch (e) {}
  };

  const renderDeleteDevice = (id: string, device?: StreamingDevice | null) => {
    const isYoutubeLink = (url: string) =>
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/.test(
        url
      );
    return (
      <>
        {device != null && !isYoutubeLink(device.url) ? (
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              dispatch(setDeployConfig({ipCamera: device.id}));
              dispatch(setIsAddDeviceModalOpen(true));
            }}
          >
            <Edit />
          </IconButton>
        ) : null}
        <IconButton
          size="small"
          color="secondary"
          onClick={() => {
            setConfirmDeleteDevice({isOpen: true, id});
          }}
        >
          <Clear />
        </IconButton>
      </>
    );
  };

  const renderVideoFeature = () => {
    return (
      <>
        <div className="dplmt-config-field">
          <StudioSelect
            fullWidth
            label={intl.formatMessage({id: 'test.selectDataSource'})}
            options={userDataSourceTypes}
            disabled={isTaskInProgress}
            SelectProps={{
              value: deployConfig.inferenceSource,
              onChange: event =>
                dispatch(
                  setDeployConfig({
                    ipCamera: '',
                    videoFile: '',
                    testSource: 'USER_DATA',
                    inferenceSource: event.target
                      .value as DeployConfigState['inferenceSource'],
                  })
                ),
            }}
          />
        </div>
        {deployConfig.inferenceSource === 'IMAGES' && renderFileUpload()}
        {deployConfig.inferenceSource === 'IP_CAMERA' && (
          <div className="dplmt-config-field">
            <StudioSelect
              fullWidth
              label={intl.formatMessage({id: 'test.form.ipCamera'})}
              options={ipCameras.reduce(
                (options, item) => ({...options, [item.id]: item.name}),
                {}
              )}
              disabled={isTaskInProgress || !ipCameras.length}
              placeholder={intl.formatMessage({
                id: ipCameras.length
                  ? 'deployments.selectIpCamera'
                  : 'deployments.noIpCameras',
              })}
              disablePlaceholder
              SelectProps={{
                value: deployConfig.ipCamera,
                onChange: event => {
                  dispatch(setDeployConfig({ipCamera: event.target.value as string}));
                },
              }}
              renderEndAdornment={id =>
                renderDeleteDevice(
                  id,
                  ipCameras.find(x => x.id === id)
                )
              }
            />
            <Button
              className="dplmt-config__link"
              onClick={() => {
                dispatch(setDeployConfig({ipCamera: ''}));
                dispatch(setIsAddDeviceModalOpen(true));
              }}
            >
              <FormattedMessage id="deployments.addIpCamera" />
            </Button>
          </div>
        )}
        {deployConfig.inferenceSource === 'FILE' && !isSelectedApplicationIspOnly && (
          <div className="dplmt-config-field">
            <StudioSelect
              fullWidth
              label={intl.formatMessage({id: 'test.form.videoFile'})}
              options={videoFiles.reduce(
                (options, item) => ({...options, [item.id]: item.name}),
                {}
              )}
              disabled={isTaskInProgress || !videoFiles.length}
              placeholder={intl.formatMessage({
                id: videoFiles.length
                  ? 'deployments.selectVideo'
                  : 'deployments.noVideos',
              })}
              disablePlaceholder
              SelectProps={{
                value: deployConfig.videoFile,
                onChange: event => {
                  dispatch(setDeployConfig({videoFile: event.target.value as string}));
                },
              }}
              renderEndAdornment={id => renderDeleteDevice(id, null)}
            />
            <Button
              className="dplmt-config__link"
              onClick={() => dispatch(setIsAddDeviceModalOpen(true))}
            >
              <FormattedMessage id="deployments.addVideo" />
            </Button>
          </div>
        )}
      </>
    );
  };

  const renderImageSetSelector = () => {
    return (
      <div className="dplmt-config-field">
        <StudioSelect
          fullWidth
          label={intl.formatMessage({id: 'test.selectImageSet'})}
          options={imageSetsMap}
          order={imageSetsOrder}
          disabled={isTaskInProgress}
          SelectProps={{
            value: deployConfig.inferenceImageSetId,
            onChange: event =>
              dispatch(
                setDeployConfig({
                  testSource: 'IMAGE_SETS',
                  inferenceImageSetId: event.target.value as string,
                })
              ),
          }}
        />
      </div>
    );
  };

  const handleDeployHistoryItem = ({
    applicationId,
    id: deployId,
  }: {
    id: string;
    applicationId?: string;
  }) => {
    if (applicationId) {
      history.push({
        pathname: `/project/${projectId}/deployments/new`,
        search:
          '?' +
          new URLSearchParams({
            deployId,
            origin: 'test',
            applicationId: applicationId,
          }).toString(),
      });
    } else {
      history.push(`/project/${projectId}/deployments/new`);
    }
  };

  const renderTargetDeviceField = () => {
    if (isSelectedAppONNXWeb) {
      return (
        <>
          <StudioSelect
            data-testid="target-select"
            fullWidth
            label={intl.formatMessage({id: 'test.form.availableDevice'})}
            options={{}}
            disabled
            disablePlaceholder
            placeholder={intl.formatMessage({
              id: 'test.browser',
            })}
            SelectProps={{
              value: '',
            }}
          />
          <Alert severity="warning" className="dplmt-config-field__alert">
            {intl.formatMessage({id: 'test.runtime.target.warning'})}
          </Alert>
        </>
      );
    }
    return (
      <StudioSelect
        data-testid="target-select"
        fullWidth
        label={intl.formatMessage({id: 'test.form.availableDevice'})}
        options={gspList.reduce(
          (options, item) => ({
            ...options,
            [item.id]: item.name,
          }),
          {}
        )}
        disabled={isTaskInProgress || !gspList.length}
        placeholder={intl.formatMessage({
          id: gspList.length ? 'test.selectDevice' : 'test.noDevices',
        })}
        disablePlaceholder
        SelectProps={{
          value: deployConfig.nodeId,
          onChange: event =>
            dispatch(setDeployConfig({nodeId: event.target.value as string})),
        }}
      />
    );
  };

  const calculateApplicationNames = (): Record<string, string> => {
    return applications.reduce(
      (options, item) => ({
        ...options,
        [item.id]: item.name,
      }),
      {}
    );
  };

  function calculateApplicationOrder(): string[] {
    return applications.sort((a, b) => b.version - a.version).map(x => x.id);
  }

  const getLabelForApplication = (applicationId: string): string => {
    switch (applicationId) {
      case latestApplicationId:
        return intl.formatMessage({id: 'test.mostRecentApplication'});
      default:
        return '';
    }
  };

  const refreshProjectAndResetView = () => {
    fetchProjectData(projectId);
    dispatch(resetDeploy());
  };

  if (projectData.defaultVideoInputId && defaultInput === '') {
    streamingDevices.forEach(device => {
      if (projectData.defaultVideoInputId === device.id && defaultInput === '') {
        setDefaultInput(device.id);
        dispatch(
          setDeployConfig({
            deploymentId: latestApplicationId,
            testSource: 'USER_DATA',
            inferenceSource: 'FILE',
            videoFile: device.id,
          })
        );
      }
    });
  }

  const renderCancelButton = () => (
    <Button
      variant="contained"
      fullWidth
      size="large"
      onClick={event => {
        event.preventDefault();
        handleCancel();
      }}
      color="secondary"
    >
      <FormattedMessage id="form.cancel" />
    </Button>
  );

  const tilingAvailable =
    selectedApplication?.model?.modelPurpose === 'Detection' &&
    selectedApplication?.runtimeType === 'OPENVX' &&
    (datasetSourceSelected ||
      (deployConfig.testSource === 'USER_DATA' &&
        deployConfig.inferenceSource === 'IMAGES'));

  return (
    <div
      className={cn('dplmt-config-panel', shouldBeVisible ? '' : 'hide', className)}
      data-testid="deployment-config-panel"
      id="deployment-config-panel"
    >
      <DeployExportDialog
        isOpen={isExportModalOpen}
        onClose={() => dispatch(setIsExportModalOpen(false))}
        projectId={projectId}
        deploymentId={deployConfig.deploymentId}
        applicationNames={calculateApplicationNames()}
      />
      <DuplicateDialog
        isOpen={isDuplicateModalOpen}
        onRename={() => {
          dispatch(renameDuplicates());
          dispatch(setIsDuplicateModalOpen(false));
        }}
        onClose={() => {
          dispatch(setPendingFiles([]));
          dispatch(setIsDuplicateModalOpen(false));
        }}
      />
      <ConfirmDialog
        type="confirm"
        title={intl.formatMessage({id: 'terminate.testTitle'})}
        message={intl.formatMessage({id: 'terminate.testContent'})}
        open={confirmEndTask}
        onClose={() => {
          setConfirmEndTask(false);
        }}
        onOk={() => {
          handleCancel();
          setConfirmEndTask(false);
        }}
        submitLabel={intl.formatMessage({id: 'action.terminate'})}
      />
      <DeleteApplicationDialog
        isOpen={isDeleteModalOpen}
        onClose={() => dispatch(setIsDeleteModalOpen(false))}
        projectId={projectId}
        applicationId={deployConfig.deploymentId}
        applicationNames={calculateApplicationNames()}
        onSuccess={refreshProjectAndResetView}
      />
      <AddStreamingDeviceForm
        onAddedAsset={(name: string, url: string): void => {
          setAddedAsset({name, url});
        }}
      />
      <StudioTabs
        variant="fullWidth"
        className="dplmt-tabs"
        value={selectedTab}
        onChange={(_event, index) => {
          if (index === 0) {
            if (isTaskInProgress) {
              setConfirmEndTask(true);
            } else {
              dispatch(resetDeploy());
              dispatch(
                setDeployConfig({
                  deploymentId: latestApplicationId,
                  nodeId: gspList?.[0]?.id ?? '',
                  testSource: '',
                })
              );
            }
          } else {
            selectLatestHistoryItem();
          }
          onTabChange(index);
        }}
      >
        <StudioTab
          label={intl.formatMessage({id: 'test.tabName'})}
          data-testid="new-test-tab"
        />
        <StudioTab
          label={intl.formatMessage({id: 'testHistory.tabName'})}
          data-testid="test-history-tab"
          disabled={isTaskInProgress}
        />
      </StudioTabs>
      <StudioTabPanel className="dplmt-tab-panel" value={selectedTab} index={0}>
        <form
          className="dplmt-form"
          onSubmit={event => {
            event.preventDefault();
            handleSubmit(
              {
                ...deployConfig,
                environment: {
                  ...defaultEnvironmentVariables,
                  ...deployConfig.environment,
                },
              },
              fetchImageSets
            );
          }}
        >
          <div className="dplmt-scrollable-container">
            <div className="dplmt-config-field">
              <StudioTextField
                value={deployConfig.name}
                required
                fullWidth
                label={
                  <React.Fragment>
                    {intl.formatMessage({id: 'test.form.name'})}
                    <StudioTooltip
                      title={intl.formatMessage({id: 'deployments.resetForm'})}
                    >
                      <span>
                        <IconButton
                          className="dplmt-download"
                          onClick={() => dispatch(resetDeploy())}
                          disabled={isTaskInProgress}
                        >
                          <RotateLeft />
                        </IconButton>
                      </span>
                    </StudioTooltip>
                  </React.Fragment>
                }
                data-testid="test-name-field"
                id="test-name-field"
                disabled={isTaskInProgress}
                onChange={event => {
                  dispatch(setDeployConfig({name: event.target.value}));
                }}
                inputProps={{
                  maxLength: TEST_PANEL_SETTINGS.name.maxLength,
                }}
                InputLabelProps={{
                  required: false,
                }}
              />
            </div>
            <div className="dplmt-config-field">
              <StudioTextField
                fullWidth
                disabled={isTaskInProgress}
                value={deployConfig.description}
                label={intl.formatMessage({id: 'form.description'})}
                multiline={TEST_PANEL_SETTINGS.description.multiline}
                inputProps={{
                  className: TEST_PANEL_SETTINGS.description.className,
                  maxLength: TEST_PANEL_SETTINGS.description.maxLength,
                }}
                onChange={event => {
                  dispatch(setDeployConfig({description: event.target.value}));
                }}
              />
            </div>
            <div className="dplmt-config-field dplmt-download-field">
              <StudioSelect
                fullWidth
                label={intl.formatMessage({id: 'test.availableApplication'})}
                getAuxiliaryLabel={(optionId: string) => getLabelForApplication(optionId)}
                additionalLabel={
                  <div className="dplmt-app-actions">
                    <StudioTooltip title={intl.formatMessage({id: 'test.exportTooltip'})}>
                      <span>
                        <IconButton
                          className="dplmt-download"
                          onClick={() => dispatch(setIsExportModalOpen(true))}
                          disabled={!deployConfig.deploymentId || isTaskInProgress}
                          data-testid="export-button"
                        >
                          <GetApp />
                        </IconButton>
                      </span>
                    </StudioTooltip>
                    {mode !== 'LITE' && (
                      <StudioTooltip
                        title={intl.formatMessage({id: 'test.deleteApplication'})}
                      >
                        <span>
                          <IconButton
                            className="dplmt-delete-app"
                            onClick={() => dispatch(setIsDeleteModalOpen(true))}
                            disabled={!deployConfig.deploymentId || isTaskInProgress}
                            data-testid="delete-app-button"
                          >
                            <Delete />
                          </IconButton>
                        </span>
                      </StudioTooltip>
                    )}
                  </div>
                }
                data-testid="application-select"
                id="application-select"
                options={calculateApplicationNames()}
                order={calculateApplicationOrder()}
                disabled={isTaskInProgress || !applications.length}
                placeholder={intl.formatMessage({
                  id: applications.length
                    ? 'test.selectApplication'
                    : 'test.noApplications',
                })}
                disablePlaceholder
                SelectProps={{
                  value: deployConfig.deploymentId,
                  onChange: event => {
                    const newApplicationId = event.target.value as string;
                    const newApplication = applications.find(
                      application => application.id === newApplicationId
                    );
                    const isNewAppONNXWeb =
                      newApplication?.runtimeType === 'ONNX_RUNTIME_WEB';
                    dispatch(
                      setDeployConfig({
                        deploymentId: newApplicationId,
                        isONNXRuntimeWeb: isNewAppONNXWeb,
                        testSource: isNewAppONNXWeb ? 'USER_DATA' : '',
                        outputTypes: [],
                        files: [],
                        environment: newApplication?.environment || {},
                      })
                    );
                  },
                }}
              />
              {selectedApplication?.model?.modelPurpose === 'Classification' &&
                mode !== 'LITE' && (
                  <Button className="dplmt-config__link">
                    <Link
                      to={`/project/${projectId}/inference/${deployConfig.deploymentId}/create`}
                    >
                      {intl.formatMessage({id: 'test.createInference'})}
                    </Link>
                  </Button>
                )}
            </div>
            {Boolean(deployConfig.deploymentId) && (
              <PreProcessingParameters
                projectId={projectId}
                applicationId={deployConfig.deploymentId}
                renderField={(field, param) => (
                  <div className="dplmt-config-field" key={param.name}>
                    {field}
                  </div>
                )}
                titleMessageId="test.form.preprocessing"
                wrapperClassName="dplmt-config__accordion"
                onValidityChange={isValid => setIsPreProcessingValid(isValid)}
                onChange={newParams =>
                  dispatch(setDeployConfig({preProcessorParams: newParams}))
                }
                persistChanges={true}
                disabledForm={isTaskInProgress}
              />
            )}
            {Boolean(deployConfig.deploymentId) && (
              <DeploymentPostProcessingParameters
                projectId={projectId}
                applicationId={deployConfig.deploymentId}
                renderField={(field, param) => (
                  <div className="dplmt-config-field" key={param.name}>
                    {field}
                  </div>
                )}
                titleMessageId="test.form.postprocessing"
                wrapperClassName="dplmt-config__accordion"
                onValidityChange={isValid => setIsPostProcessingValid(isValid)}
                onChange={newParams =>
                  dispatch(setDeployConfig({postProcessorParams: newParams}))
                }
                persistChanges={true}
                disabledForm={isTaskInProgress}
              />
            )}
            <div className="dplmt-config-field">{renderTargetDeviceField()}</div>
            {isSelectedAppONNXWeb && (
              <div className="dplmt-config-field">
                <StudioSelect
                  required
                  id="runtime-mode"
                  fullWidth
                  disabled={isTaskInProgress}
                  label={intl.formatMessage({id: 'test.runtime.label'})}
                  options={RUNTIME_MODE_OPTIONS}
                  SelectProps={{
                    value: deployConfig.runtimeMode,
                    onChange: event =>
                      dispatch(
                        setDeployConfig({
                          runtimeMode: event.target.value as RuntimeModeType,
                        })
                      ),
                  }}
                  tooltip={intl.formatMessage({id: 'test.runtime.tooltip'})}
                  tooltipPlacement="top"
                />
              </div>
            )}
            <div className="dplmt-config-field">
              {selectedApplication && deployConfig.nodeId && !isSelectedAppONNXWeb && (
                <>
                  <StudioSelect
                    data-testid="test-source"
                    id="test-source"
                    fullWidth
                    label={intl.formatMessage({id: 'test.form.testSource'})}
                    groupedOptions={availableTestSources}
                    disabled={isTaskInProgress}
                    placeholder={intl.formatMessage({
                      id: 'test.form.testSourcePlaceholder',
                    })}
                    disablePlaceholder
                    SelectProps={{
                      value: deployConfig.testSource,
                      onChange: event => {
                        const isApp = event.target.value === 'APP_INPUT';
                        const isTxt = event.target.value === 'TEXT_INPUT';
                        return (
                          event.target.value != null &&
                          dispatch(
                            setDeployConfig({
                              testSource: event.target.value as TestSourceType,
                              outputTypes:
                                (event.target.value as TestSourceType) === 'USER_DATA'
                                  ? []
                                  : deployConfig.outputTypes,
                              inferenceSource:
                                isApp || isTxt
                                  ? (event.target.value as InputSource)
                                  : defaultUserDataSource,
                              files: [],
                            })
                          )
                        );
                      },
                    }}
                  />
                </>
              )}
            </div>
            {datasetExists && datasetSourceSelected && !isSelectedAppONNXWeb && (
              <div className="dplmt-config-field">
                <StudioSelect
                  data-testid="test-output-type"
                  id="test-output-type"
                  fullWidth
                  disabled={isTaskInProgress}
                  label={intl.formatMessage({
                    id: 'test.outputType',
                  })}
                  options={{
                    modelStatistics: intl.formatMessage({
                      id: 'test.validationRun',
                    }),
                    sampleInference: intl.formatMessage({
                      id: 'test.inferenceRun',
                    }),
                  }}
                  disabledOptions={{
                    modelStatistics: isSelectedApplicationIspOnly || isStatsNotSupported,
                  }}
                  placeholder={intl.formatMessage({
                    id: 'form.selectOneOrMore',
                  })}
                  SelectProps={{
                    value: deployConfig.outputTypes,
                    multiple: true,
                    onChange: event =>
                      dispatch(
                        setDeployConfig({
                          outputTypes: event.target.value as TestOutputType[],
                        })
                      ),
                  }}
                  onChipDelete={value =>
                    dispatch(
                      setDeployConfig({
                        outputTypes: deployConfig.outputTypes.filter(
                          type => type !== value
                        ),
                      })
                    )
                  }
                />
              </div>
            )}
            {(deployConfig.testSource === 'USER_DATA' || isSelectedAppONNXWeb) && (
              <>
                {!signalingServerUrl && renderFileUpload()}
                {signalingServerUrl && renderVideoFeature()}
              </>
            )}
            {deployConfig.testSource === 'IMAGE_SETS' && renderImageSetSelector()}
            {tilingAvailable && (
              <div className="dplmt-config-field">
                <StudioTextField
                  value={deployConfig.tiling}
                  label={intl.formatMessage({id: 'test.form.tiling'})}
                  type="number"
                  disabled={isTaskInProgress}
                  onChange={event => {
                    dispatch(
                      setDeployConfig({
                        tiling: event.target.value,
                      })
                    );
                  }}
                />
              </div>
            )}
          </div>
          <div className="dplmt-config-action-buttons">
            {deployTask.status !== TASK_STATUS.IN_PROGRESS &&
            onnxTask.status !== TASK_STATUS.IN_PROGRESS ? (
              <Button
                variant="contained"
                fullWidth
                size="large"
                disabled={!readyToRun || isTaskInProgress}
                color="primary"
                type="submit"
              >
                <FormattedMessage id="deployments.runTest" />
              </Button>
            ) : (
              renderCancelButton()
            )}
          </div>
        </form>
      </StudioTabPanel>
      <StudioTabPanel className="dplmt-tab-panel" value={selectedTab} index={1}>
        <div className="dplmt-scrollable-container">
          <StudioSearch
            placeholder={intl.formatMessage({id: 'test.searchPlaceholder'})}
            onChange={event => debouncedOnSearch(event.target.value)}
          />
          {historyItems?.entries.map(historyItem => (
            <HistoryItem
              key={historyItem.id}
              deployment={historyItem}
              application={applications.find(
                application => application.id === historyItem.applicationId
              )}
              onDelete={({id}) => {
                mutateHistoryItems(
                  {
                    count: historyItems?.count ?? 0,
                    entries: historyItems?.entries?.filter(item => item.id !== id) ?? [],
                  },
                  false
                );
                dispatch(
                  setSelectedDeploymentIds(
                    selectedDeploymentIds.filter(deploymentId => deploymentId !== id)
                  )
                );
              }}
              onSelect={({ids}) => {
                if (isTaskInProgress) {
                  setConfirmEndTask(true);
                } else {
                  dispatch(setSelectedDeploymentIds(ids));
                  dispatch(
                    setDeployConfig({
                      environment: {
                        ...selectedApplication?.model?.frameworkParameters?.environment,
                        ...historyItem.environment,
                      },
                    })
                  );
                }
              }}
              onDeploy={handleDeployHistoryItem}
              search={search}
              selectedIds={selectedDeploymentIds}
            />
          ))}
        </div>
        <div className="dplmt-config-action-buttons">
          <Button
            variant="contained"
            fullWidth
            size="large"
            color="default"
            type="submit"
            disabled={selectedDeploymentIds.length < 2}
            onClick={() => {
              history.push(
                `/project/${projectId}/test/compare?deploymentIds=${selectedDeploymentIds.join(
                  ','
                )}`
              );
            }}
          >
            <FormattedMessage id="testCompare.compareTests" />
          </Button>
          {deployTask.status === TASK_STATUS.IN_PROGRESS && renderCancelButton()}
        </div>
      </StudioTabPanel>
      {inferenceTask.status === TASK_STATUS.IN_PROGRESS && (
        <FileUploadProgress
          total={inferenceTask.totalSize}
          progress={inferenceTask.uploadProgress}
        />
      )}
      <ConfirmDialog
        type="confirm"
        title={intl.formatMessage({id: 'test.deleteDeviceConfirmTitle'})}
        message={intl.formatMessage({id: 'test.deleteDeviceConfirmMessage'})}
        submitLabel={intl.formatMessage({
          id: 'form.delete',
        })}
        open={confirmDeleteDevice.isOpen}
        onClose={() => setConfirmDeleteDevice({isOpen: false, id: null})}
        onOk={() => {
          confirmDeleteDevice.id && handleDeviceDelete(confirmDeleteDevice.id);
          setConfirmDeleteDevice({isOpen: false, id: null});
        }}
      />
    </div>
  );
};
