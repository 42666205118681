import React from 'react';
import {Dialog, DialogActions, DialogContent} from '@material-ui/core';
import FormButton from '../../../base-components/StudioButton/FormButton';
import AlertFilledIcon from '../../../assets/icons/AlertFIlledIcon';
import {FormattedMessage, useIntl} from 'react-intl';
import {MarketplaceRow} from '../Marketplaces';
import './DeleteIntegrationDialog.scss';

type DeleteIntegrationDialogProps = {
  isOpen: boolean;
  row: MarketplaceRow | null;
  onCancelClick: () => void;
  onDeleteClick: (row: MarketplaceRow) => void;
};

export const DeleteIntegrationDialog = ({
  isOpen,
  row,
  onCancelClick,
  onDeleteClick,
}: DeleteIntegrationDialogProps) => {
  const intl = useIntl();
  return row ? (
    <Dialog
      open={isOpen}
      className="delete-integration-dialog"
      data-testid="delete-integration-dialog"
      id="delete-integration-dialog"
    >
      <h4 className="delete-integration-dialog__title">
        <AlertFilledIcon className="delete-integration-dialog__icon" />
        <FormattedMessage id="marketplaceConfig.deleteTitle" />
      </h4>
      <DialogContent className="delete-integration-dialog__content">
        <FormattedMessage
          id="marketplaceConfig.deleteContent"
          values={{name: row.name}}
        />
      </DialogContent>
      <DialogActions className="delete-integration-dialog__actions">
        <FormButton
          buttonRole="secondary"
          value={intl.formatMessage({id: 'form.cancel'})}
          onClick={onCancelClick}
        />
        <FormButton
          buttonRole="delete"
          value={intl.formatMessage({id: 'form.delete'})}
          onClick={() => onDeleteClick(row)}
        />
      </DialogActions>
    </Dialog>
  ) : null;
};
