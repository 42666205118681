import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Alert} from '@material-ui/lab';
import StagePanel from './StagePanel';
import StageAnimation from '../StageAnimation/StageAnimation';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import ValidationCard from './ValidationCard';
import FinetuneAction from '../StageActions/FinetuneAction';
import ModelActionButton from '../../../base-components/StudioButton/ModelActionButton';
import {StudioPopover} from '../../../base-components/StudioPopover';
import PropTypes from 'prop-types';
import {TerminateButton} from './TerminateButton';
import {toggleModelDetailsPanel} from '../../../store/model';
import {getIsDiabledFineTuningStage} from '../../../store/view';
import './FinetunePanel.scss';

class FinetunePanel extends StagePanel {
  constructor(props) {
    super(props);

    this.state = {
      floatAccuracy: null,
      compressedAccuracy: null,
      finetunedAccuracy: null,
      statusMessage: '...',
      percentComplete: null,
    };

    this.initiateFinetuning = this.initiateFinetuning.bind(this);
    this.terminateFinetuning = this.terminateFinetuning.bind(this);
    this.updateStatistics = this.updateStatistics.bind(this);
    this.saveStatistics = this.saveStatistics.bind(this);
    this.restoreStatistics = this.restoreStatistics.bind(this);
  }

  componentDidMount() {
    const statistics = this.props.optimizationStage?.statistics;

    if (statistics) {
      this.updateStatistics(statistics);
    }
  }

  componentDidUpdate(prevProps) {
    const statistics = this.props.optimizationStage?.statistics;
    if (statistics !== prevProps.optimizationStage?.statistics) {
      this.updateStatistics(statistics);
    }
  }

  getPanelStyle() {
    return 'tall';
  }

  getLockedContent() {
    if (this.props.optimizationStage?.statistics && !this.props.shouldDisabledViewStage) {
      var alert = <></>;
      if (this.props.modelPurpose === 'Classification' && !this.props.dataset) {
        alert = (
          <Alert severity="warning">
            {this.props.intl.formatMessage({
              id: 'project.finetune.alert.incompatible.dataset',
            })}
          </Alert>
        );
      }
      if (this.props.modelPurpose !== 'Classification') {
        alert = (
          <Alert severity="warning">
            {this.props.intl.formatMessage({
              id: 'project.finetune.alert.incompatible.model',
            })}
          </Alert>
        );
      }
      return (
        <div className="locked-content" data-testid="FINETUNE_MODEL-panel__locked">
          <ValidationCard
            projectId={this.props.projectId}
            id="finetune-active-card"
            floatAccuracy={this.state.floatAccuracy}
            compressedAccuracy={this.state.compressedAccuracy}
            finetunedAccuracy={this.state.finetunedAccuracy}
            modelPurpose={this.props.modelPurpose}
          />
          {alert}
        </div>
      );
    }

    return (
      <div className="locked-content" data-testid="FINETUNE_MODEL-panel__locked">
        <StudioPopover infoMessage="Finetune tells accuracy of the model as the model is refined or fine tuned." />
        <p className="lock-reason">
          {this.props.shouldDisabledViewStage
            ? this.props.intl.formatMessage({
                id: 'dataViewer.fineTunePane.runningOptimizationStage',
              })
            : this.props.stage.lockReason}
        </p>
      </div>
    );
  }

  getInactiveContent() {
    return (
      <div className="inactive-content">
        <div className="panel-finetune-inactive" />
        <div className="panel-title">
          <span className="panel-caption">Fine-tune Model</span>
        </div>
      </div>
    );
  }

  getActiveContent() {
    return (
      <div className="stage-panel-status">
        <div
          className="active-content finetune-panel"
          data-testid="FINETUNE_MODEL-panel__active"
          id="FINETUNE_MODEL-panel__active"
        >
          <ValidationCard
            projectId={this.props.projectId}
            id="finetune-active-card"
            floatAccuracy={this.state.floatAccuracy}
            compressedAccuracy={this.state.compressedAccuracy}
            finetunedAccuracy={this.state.finetunedAccuracy}
            modelPurpose={this.props.modelPurpose}
          />
          <div className="panel-title">
            <span className="panel-caption">Fine-tune Model</span>
            {this.getTooltip()}
          </div>
        </div>
        <div
          className="active-content finetune-panel stage-panel-buttons"
          data-testid="FINETUNE_MODEL-action-buttons"
          id="FINETUNE_MODEL-action-buttons"
        >
          <ModelActionButton
            value="fine-tune"
            onClick={this.initiateFinetuning}
            data-testid={`${this.props.stage.type}-panel-popup-menu`}
            id={`${this.props.stage.type}-panel-popup-menu`}
            disabled={!this.props.readyForFinetuning}
          />
        </div>
      </div>
    );
  }

  getInProgressContent() {
    return (
      <div className="stage-panel-status">
        <div
          className="in-progress-content finetune-panel"
          data-testid="FINETUNE_MODEL-panel__in-progress"
          id="FINETUNE_MODEL-panel__in-progress"
        >
          <ValidationCard
            projectId={this.props.projectId}
            id="finetune-in-progress-card"
            floatAccuracy={this.state.floatAccuracy}
            compressedAccuracy={this.state.compressedAccuracy}
            modelPurpose={this.props.modelPurpose}
          />
          <div className="panel-title">
            <span className="panel-caption">
              <span id="finetune-panel-name"></span>
              <br />
              <span id="finetune-status-message" className="panel-description">
                {this.state.statusMessage}
              </span>
            </span>
            {this.getTooltip()}
          </div>
        </div>
        <div
          className="in-progress-content finetune-panel stage-panel-buttons"
          data-testid="FINETUNE_MODEL-in-progress-action-buttons"
          id="FINETUNE_MODEL-in-progress-action-buttons"
        >
          <TerminateButton
            onClick={() => {
              this.props.openTerminateDialog({
                terminate: this.terminateFinetuning,
                title: this.props.intl.formatMessage({id: 'terminate.finetuneTitle'}),
                message: this.props.intl.formatMessage({
                  id: 'terminate.finetuneContent',
                }),
              });
            }}
          />
        </div>
      </div>
    );
  }

  getFinishedContent() {
    return (
      <div className="stage-panel-status">
        <div className="finished-content" data-testid="FINETUNE_MODEL-panel__finished">
          <ValidationCard
            projectId={this.props.projectId}
            id="finetune-finished-card"
            floatAccuracy={this.state.floatAccuracy}
            compressedAccuracy={this.state.compressedAccuracy}
            finetunedAccuracy={this.state.finetunedAccuracy}
            modelPurpose={this.props.modelPurpose}
          />
          <div className="panel-title">
            <span className="panel-caption">Fine-tune Model</span>
            {this.getTooltip()}
          </div>
        </div>

        <div
          className="finished-content finetune-panel stage-panel-buttons"
          data-testid="FINETUNE_MODEL-action-buttons"
          id="FINETUNE_MODEL-action-buttons"
        >
          <ModelActionButton
            value="details"
            onClick={() =>
              this.props.toggleModelDetailsPanel(this.props.projectId, 'FINETUNED')
            }
          />
          {this.props.readyForFinetuning && (
            <ModelActionButton
              value="fine-tuned"
              onClick={this.initiateFinetuning}
              data-testid={`${this.props.stage.type}-panel-popup-menu`}
              id={`${this.props.stage.type}-panel-popup-menu`}
            />
          )}
        </div>
      </div>
    );
  }

  getOverlay() {
    return (
      <div
        className={
          'stage-panel-overlay' + (this.props.stage.active ? ' active' : ' inactive')
        }
        onClick={this.activatePanel}
        id={this.props.stage.type + '-panel-overlay'}
        data-testid={'finetune-overlay'}
      />
    );
  }

  getTooltip() {
    return (
      <StudioPopover
        infoIconClass="stage-panel__tooltip"
        infoMessage={
          <>
            Fine tune the model to improve model accuracy. The fine-tuning operation
            adjusts the neural network model’s hyper parameters and evaluates model
            performance on the validation dataset to achieve a better inference accuracy.
            <br />
            <br />
            Fine tuning may be helpful to recover some of the loss in accuracy that is
            common after optimizing a neural network model.
            <br />
            <br />
            Floating point represents the accuracy of the original, un-optimized model.
            <br />
            Compressed represents the accuracy of the optimized, compressed model.
            <br />
            Fine-tuned represents the accuracy of the fine tuned model.
          </>
        }
      />
    );
  }

  update(stage) {
    if (this.props.finetuneAction.current) {
      this.props.finetuneAction.current.update(stage);
    }
  }

  updateStatistics(statistics, message = null, percent = null) {
    this.setState(() => {
      const updated = {};

      if (message !== null) {
        updated.statusMessage = message;
      }

      if (percent !== null) {
        updated.percentComplete = percent;
      }

      return {
        ...updated,
        floatAccuracy: statistics?.floatAccuracy || null,
        compressedAccuracy: statistics?.compressedAccuracy || null,
        finetunedAccuracy: statistics?.finetunedAccuracy || null,
      };
    });
  }

  saveStatistics() {
    this.setState(state => ({
      previousStatistics: {
        floatAccuracy: state.floatAccuracy,
        compressedAccuracy: state.compressedAccuracy,
        finetunedAccuracy: state.finetunedAccuracy,
      },
    }));
  }

  restoreStatistics() {
    this.setState(state => {
      if (state.previousStatistics) {
        return {
          floatAccuracy: state.previousStatistics.floatAccuracy,
          compressedAccuracy: state.previousStatistics.compressedAccuracy,
          finetunedAccuracy: state.previousStatistics.finetunedAccuracy,
        };
      }
    });
  }

  initiateFinetuning() {
    const action = this.props.finetuneAction.current;
    const elem = document.getElementById('FINETUNE_MODEL-container');
    const stageAnimation = new StageAnimation();

    if (elem && !elem.classList.contains('active')) {
      stageAnimation.updateStages(elem);
    }

    if (action) {
      this.saveStatistics();
      action.initiateFinetuning();
    }
  }

  terminateFinetuning() {
    const action = this.props.finetuneAction.current;

    if (action) {
      action.terminateFinetuning();
      this.restoreStatistics();
    }
  }
}

FinetunePanel.propTypes = {
  projectId: PropTypes.string.isRequired,
  assistantPanel: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)}),
  ]).isRequired,
  finetuneAction: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(FinetuneAction)}),
    PropTypes.shape({current: null}),
  ]).isRequired,
  onFinish: PropTypes.func.isRequired,
  modelPurpose: PropTypes.string,
};

const mapDispatchToProps = {
  toggleModelDetailsPanel,
};

const mapStateToProps = state => ({
  shouldDisabledViewStage: getIsDiabledFineTuningStage(state),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(FinetunePanel),
  {forwardRef: true}
);
