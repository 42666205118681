import React, {CSSProperties, ReactNode, useEffect, useRef} from 'react';
import cn from 'classnames';
import {MessageTailIcon} from './MessageTailIcon';
import {IconButton} from '@material-ui/core';
import {ThumbDown, ThumbUp} from '@material-ui/icons';
import {FormattedMessage} from 'react-intl';
import {matchPath, useHistory} from 'react-router';
import {PrivateRoutes} from '../../../config/routes';
import './ChatMessage.scss';

export const FEEDBACK_TYPE = {
  NONE: 'NONE',
  HELPFUL: 'HELPFUL',
  UNHELPFUL: 'UNHELPFUL',
};

export type ChatMessageProps = {
  children: ReactNode;
  source: 'ca' | 'user';
  theme: 'dark' | 'light';
  isHelpful: boolean;
  showFeedback: boolean;
  onFeedback: (newFeedBack: boolean) => void;
  style?: CSSProperties;
};

export const ChatMessage = ({
  children,
  source,
  theme = 'light',
  isHelpful,
  showFeedback = false,
  onFeedback,
  style,
}: ChatMessageProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  useRouteDetection(rootRef.current);

  const handleFeedback = (newFeedback: boolean) => {
    if (isHelpful !== newFeedback) {
      onFeedback && onFeedback(newFeedback);
    }
  };

  return (
    <div
      ref={rootRef}
      className={cn(
        'chat-message',
        source === 'ca' && 'chat-message--ca',
        theme === 'dark' && 'chat-message--dark'
      )}
      data-testid={
        source === 'ca'
          ? 'conversation-assistant-message-to-user'
          : 'conversation-assistant-message-from-user'
      }
      id={
        source === 'ca'
          ? 'conversation-assistant-message-to-user'
          : 'conversation-assistant-message-from-user'
      }
      style={style}
    >
      <div className="chat-message__body">
        {children}
        <MessageTailIcon className="chat-message__tail-icon" />
        {showFeedback && (
          <div className="chat-message__feedback">
            <FormattedMessage id="conversation.feedback">
              {value => <span>{value}</span>}
            </FormattedMessage>
            <div className="chat-message__buttons">
              <IconButton
                className={cn(
                  'chat-message__button',
                  isHelpful === true && 'chat-message__button--selected'
                )}
                onClick={() => handleFeedback(true)}
                disabled={isHelpful}
                data-testid="chat-message-thumbs-up"
                id="chat-message-thumbs-up"
              >
                <ThumbUp />
              </IconButton>
              <IconButton
                className={cn(
                  'chat-message__button',
                  isHelpful === false && 'chat-message__button--selected'
                )}
                onClick={() => handleFeedback(false)}
                disabled={isHelpful === false}
                data-testid="chat-message-thumbs-down"
                id="chat-message-thumbs-down"
              >
                <ThumbDown />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function useRouteDetection(rootElement: EventTarget | null) {
  const history = useHistory();
  useEffect(
    function addRouteDetection() {
      if (rootElement) {
        rootElement.addEventListener('click', detectRoute);
        return function removeRouteDetection() {
          rootElement.removeEventListener('click', detectRoute);
        };
      }
      function detectRoute(event: Event) {
        const link = event.target instanceof Node ? findLink(event.target) : null;
        if (isRoute(link)) {
          event.preventDefault();
          history.push(link.pathname + link.search + link.hash);
        }
      }
    },
    [rootElement, history]
  );
}

function findLink(node: Node | null) {
  while (node && !(node instanceof HTMLAnchorElement)) {
    node = node.parentNode;
  }
  return node;
}

function isRoute(link: HTMLAnchorElement | null): link is HTMLAnchorElement {
  return (
    link !== null &&
    link.hostname === window.location.hostname &&
    matchPath(link.pathname, Object.values(PrivateRoutes)) !== null
  );
}
