import React, {useEffect, useContext, useState} from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {ISPFlowgraphContext} from './ISPFlowgraphProvider';
import {Divider} from '@material-ui/core';
import {StageContext} from '../Provider';
import {FormattedMessage} from 'react-intl';

const ContextMenuItem = ({data, onClick, children}) => (
  <MenuItem onClick={() => onClick(data)}>{children}</MenuItem>
);

export default function ContextMenu({ispModules}) {
  const [menuItems, setMenuItems] = useState([]);
  const [viewMenuItems, setViewMenuItems] = useState(null);
  const {setAddModule} = useContext(ISPFlowgraphContext);
  const {editor} = useContext(StageContext);
  const [contextMenuObj, setContextMenuObj] = useState({position: [0, 0], open: false});

  const onClose = () => setContextMenuObj({open: false});
  const onSelect = node => {
    setAddModule(node);
    onClose();
  };

  const onDelete = node => {
    editor.removeNode(node);
    onClose();
  };

  const onClear = () => {
    editor.clear();
    onClose();
  };

  const onContextMenu = ({e, view, node}) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    setContextMenuObj({open: true, position: {x: e.x, y: e.y}, node, view});
  };

  useEffect(() => {
    editor && editor.on('contextmenu', onContextMenu);
  }, [editor]);

  // Prep Editor view menu items once.
  useEffect(() => {
    ispModules.length &&
      !viewMenuItems &&
      setViewMenuItems(
        // add clear stage item
        [
          <ContextMenuItem key="form.clear" onClick={onClear}>
            <FormattedMessage id="form.clear" />
          </ContextMenuItem>,
          <Divider key="divider" />,
        ].concat(
          // add isp modules
          ispModules.map((val, i) => (
            <ContextMenuItem key={i} data={val} onClick={onSelect}>
              {val.module_display_name}
            </ContextMenuItem>
          ))
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ispModules, onSelect, viewMenuItems]);

  // Editor Context Menu
  useEffect(() => {
    contextMenuObj.view && viewMenuItems && setMenuItems(viewMenuItems);
  }, [contextMenuObj, viewMenuItems]);

  // Node Context Menu
  useEffect(() => {
    if (contextMenuObj.node && ispModules.length && onSelect) {
      // filter modules to only acceptable modules for the output of the node
      const outputType = contextMenuObj.node.data.module_outputs[0].type;
      setMenuItems(
        ispModules
          .filter(val => val.module_inputs[0].type === outputType)
          .map((val, i) => (
            <ContextMenuItem key={i} data={val} onClick={onSelect}>
              {val.module_display_name}
            </ContextMenuItem>
          ))
          // add delete item
          .concat(<Divider />)
          .concat(
            <ContextMenuItem data={contextMenuObj.node} onClick={onDelete}>
              <FormattedMessage id="form.delete" />
            </ContextMenuItem>
          )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextMenuObj, ispModules, onSelect]);

  return (
    <Menu
      open={contextMenuObj?.open}
      onClose={onClose}
      keepMounted
      anchorReference="anchorPosition"
      anchorPosition={{
        left: contextMenuObj?.position?.x,
        top: contextMenuObj?.position?.y,
      }}
      data-testid="flow-graph-context-menu"
      id="flow-graph-context-menu"
    >
      {menuItems}
    </Menu>
  );
}
