import * as Yup from 'yup';
import ERROR from '../../config/error';

const validate = (validator, message) => ({
  validator,
  message,
});

const string = Yup.string();

export const PasswordRequirements = [
  validate(string.min(8).required(), ERROR.MINIMUM_CHARACTERS_REQUIRED()),
  validate(string.matches(/[a-z]/), ERROR.LOWERCASE_CHARACTER_REQUIRED()),
  validate(string.matches(/[A-Z]/), ERROR.UPPERCASE_CHARACTER_REQUIRED()),
  validate(
    // eslint-disable-next-line no-control-regex
    string.matches(/[^0-9A-Za-z\x00-\x20\x7f-\uffff]/),
    ERROR.SPECIAL_CHARACTER_REQUIRED()
  ),
  validate(string.matches(/[0-9]/), ERROR.NUMBER_REQUIRED()),
];
