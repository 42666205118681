import axios from 'axios';
import classNames from 'classnames';
import {startCase, toLower} from 'lodash';
import React, {useEffect, useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import useSWR from 'swr';
import URL, {MODEL_SUB_OP} from '../../../config/url';
import {DataViewerTooltip} from '../../DataViewer/DataViewerTooltip';
import {ImageCardDetail, ImageCardHorizontal} from '../ImageCard';
import {DeploymentDetectionResult} from '../../../types/deployment/DeploymentDetectionResult';
import {DeploymentDetectionResultStats} from '../../../types/deployment/DeploymentDetectionResultStats';
import {ImageOverlay} from './Overlay';
import {NoDeployment} from '../NoDeployment';
import {toast} from '../../../base-components/StudioToast';
import './DetectionResult.scss';

const IMAGE_SIZE = 300;

const SIDEBAR_IMAGE_WIDTH = 125;
const SIDEBAR_IMAGE_HEIGHT = 125;

export type DetectionResultProps = {
  data: Image[];
  deploymentId: string;
  projectId: string;
  search: string;
  searchEnabled: boolean;
};

export type ImageData = {
  label: string;
  bbox: [number, number, number, number];
  progress: number;
};

export type Image = {
  url: string;
  title: string;
  data: ImageData[];
};

export function DetectionResult({
  data,
  deploymentId,
  projectId,
  search = '',
  searchEnabled,
}: DetectionResultProps) {
  const [images, setImages] = React.useState<Image[]>(data);
  const [selectedImage, setSelectedImage] = React.useState<Image | null>(
    data.length ? data[0] : null
  );
  const [selectedLabelIndex, setSelectedLabelIndex] = React.useState<number | null>(null);
  const sortedData = [...(selectedImage?.data ?? [])].sort(
    (a, b) => b.progress - a.progress
  );
  const {data: imageDimensions} = useSWR<
    | {
        height: number;
        width: number;
        size: number;
      }
    | undefined
  >([selectedImage?.url], async () => {
    if (!selectedImage?.url) {
      return undefined;
    }
    const {headers} = await axios.get(selectedImage.url, {responseType: 'blob'});
    const xSizesHeader = headers?.['x-sizes'];
    const imageDetails: {w: number; h: number; s: number}[] = JSON.parse(xSizesHeader);
    return {
      height: imageDetails[0].h,
      width: imageDetails[0].w,
      size: imageDetails[0].s,
    };
  });
  const hasResults = useMemo(() => data && Array.isArray(data) && data.length, [data]);

  useEffect(() => {
    if (!hasResults) {
      toast.error('No images found for this deployment.');
    }
  }, [hasResults]);

  useEffect(() => {
    if (searchEnabled && hasResults && projectId && deploymentId) {
      const images: Image[] = (data as DeploymentDetectionResult[])
        .map(image => {
          let stats: DeploymentDetectionResultStats[] = image.stats || [];
          return {
            url: `${URL.DATASET(
              projectId,
              MODEL_SUB_OP.GET_BY_ID
            )}?id=${image.filename?.replace(
              ',',
              '%252C'
            )}&deploymentId=${deploymentId}&dataPurpose=INPUT&cachebust=${Date.now()}`,
            title: image.filename ?? '',
            data:
              stats?.map(item => ({
                label: startCase(toLower(item.label)),
                bbox: item.bbox,
                progress: item.progress,
              })) ?? [],
          };
        })
        .filter(image =>
          search.toLowerCase() === ''
            ? true
            : image.data?.some(
                item => item.label.toLowerCase().indexOf(search.toLowerCase()) >= 0
              )
        );

      setImages(images);
      setSelectedImage(images.length > 0 ? images[0] : null);
    }
  }, [searchEnabled, hasResults, data, projectId, deploymentId, search]);

  return (
    <div className="detection-result">
      {!hasResults ? (
        <NoDeployment
          imageUrl="images/errorGettingData.png"
          message={<FormattedMessage id="test.noImagesFetched" />}
        />
      ) : !images.length ? (
        <NoDeployment
          imageUrl="images/errorGettingData.png"
          message={<FormattedMessage id="test.noImagesResult" />}
        />
      ) : (
        <>
          <div className="detection-result__sidebar">
            {images.map((image, i) => (
              <ImageCardHorizontal
                key={`iamge-card-horizantal-${i}`}
                className={classNames(
                  selectedImage?.url === image.url && 'detection-result__image--selected'
                )}
                image={`${image.url}&resolution=${SIDEBAR_IMAGE_WIDTH}:${SIDEBAR_IMAGE_HEIGHT}`}
                width={SIDEBAR_IMAGE_WIDTH}
                height={SIDEBAR_IMAGE_HEIGHT}
                data-testid="detection-inference-result"
                onClick={() => {
                  setSelectedImage(image);
                }}
              />
            ))}
          </div>
          <div className="detection-result__review">
            {selectedImage?.url && (
              <>
                <DataViewerTooltip
                  filename={selectedImage?.title}
                  size={imageDimensions?.size ?? 0}
                  width={imageDimensions?.width}
                  height={imageDimensions?.height}
                  url={selectedImage.url}
                  annotation={sortedData.map(el => ({
                    ...el,
                    bbox: [
                      (el.bbox?.[0] ?? 0) * (imageDimensions?.width || 0),
                      (el.bbox?.[1] ?? 0) * (imageDimensions?.height || 0),
                      (el.bbox?.[2] ?? 0) * (imageDimensions?.width || 0),
                      (el.bbox?.[3] ?? 0) * (imageDimensions?.height || 0),
                    ],
                  }))}
                >
                  <ImageOverlay
                    size={IMAGE_SIZE}
                    width={IMAGE_SIZE}
                    height={IMAGE_SIZE}
                    image={selectedImage.url}
                    data={sortedData}
                    highlightIndex={selectedLabelIndex}
                    showLabel={true}
                    selectOnMouseOver={true}
                  />
                </DataViewerTooltip>
                <div className="detection-result__stats">
                  {selectedImage.data?.length > 0 && (
                    <ImageCardDetail
                      data={sortedData}
                      height={IMAGE_SIZE}
                      onHover={(_stats, index) => {
                        setSelectedLabelIndex(index);
                      }}
                      countObjects={true}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
