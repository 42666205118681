import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Paper,
} from '@material-ui/core';
import {Close, Launch} from '@material-ui/icons';
import {Skeleton} from '@material-ui/lab';
import * as React from 'react';
import FormButton from '../../base-components/StudioButton/FormButton';
import HumanReadableTime from '../../base-components/StudioHumanReadableTime/HumanReadableTime';
import {DatasetSummaryResponse} from '../../types/dataset/DatasetSummaryResponse';
import {LabelChart, LabelChartProps} from './LabelChart';
import fileSize from 'filesize';
import {FormattedMessage, useIntl} from 'react-intl';
import './DataViewerSummary.scss';

type DataViewerSummaryProps = {
  fileCount?: number;
  date?: number;
  numberOfClasses?: number;
  filesByLabel?: DatasetSummaryResponse['labels'];
  name?: string;
  onBarClick: FullLabelChartProps['onBarClick'];
  pending?: boolean;
  sizeOfDataset?: number;
  purposeOfDataset?: string;
};

export const DataViewerSummary = ({
  fileCount,
  date,
  numberOfClasses,
  filesByLabel,
  name,
  onBarClick,
  pending,
  sizeOfDataset,
  purposeOfDataset,
}: DataViewerSummaryProps) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const openChart = () => setOpen(true);
  const closeChart = () => setOpen(false);

  type PendingProps = {children: React.ReactNode};
  const Pending = (props: PendingProps) =>
    pending ? <Skeleton animation="wave" /> : <>{props.children}</>;

  const Stats = ({subtitle, title}: {subtitle?: string; title?: string}) => (
    <Box className="center-text" m={1}>
      <div className="data-viewer-summary__stat-number">
        <Pending>{title}</Pending>
      </div>
      <div className="data-viewer-summary__stat-label">
        <Pending>{subtitle}</Pending>
      </div>
    </Box>
  );

  let titleId =
    purposeOfDataset === 'Classification'
      ? 'dataViewer.filesByLabel'
      : 'dataViewer.objectsByLabel';
  let title = intl.formatMessage({id: titleId});

  return (
    <Paper className="data-viewer-summary">
      <Box m={1}>
        <div className="data-viewer-summary__name">
          <Pending>{name}</Pending>
        </div>
        <div className="data-viewer-summary__date">
          <Pending>
            <HumanReadableTime date={date} />
          </Pending>
        </div>
      </Box>
      <Stats
        title={numberOfClasses?.toString()}
        subtitle={intl.formatMessage({id: 'dataViewer.numberOfClasses'})}
      />
      <Stats
        title={fileCount?.toString()}
        subtitle={intl.formatMessage({id: 'dataViewer.files'})}
      />
      {sizeOfDataset !== undefined && (
        <Stats
          title={sizeOfDataset !== undefined ? fileSize(sizeOfDataset) : ''}
          subtitle={intl.formatMessage({id: 'dataViewer.sizeOfDataSet'})}
        />
      )}
      {Boolean(filesByLabel?.length) && (
        <div className="data-viewer-summary__chart-column">
          <Pending>
            <LabelChart
              barSize={24}
              className="data-viewer-summary__chart"
              title={title}
              labels={filesByLabel}
              onClick={onBarClick}
            />
          </Pending>
          {!pending && (
            <Fab className="data-viewer-summary__button" onClick={openChart}>
              <Launch />
            </Fab>
          )}
          <FullLabelChart
            labels={filesByLabel}
            open={open}
            onClose={closeChart}
            onBarClick={onBarClick}
            title={title}
          />
        </div>
      )}
    </Paper>
  );
};

type FullLabelChartProps = {
  labels?: LabelChartProps['labels'];
  onBarClick(label: string): void;
  onClose(): void;
  open: boolean;
  title: string;
};

const FullLabelChart = ({
  labels = [],
  onBarClick,
  onClose,
  open,
  title,
}: FullLabelChartProps) => {
  const intl = useIntl();
  const handleBarClick = (label: string) => {
    onClose();
    onBarClick(label);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      PaperProps={{className: 'data-viewer-summary__full-chart'}}
    >
      <DialogTitle className="data-viewer-summary__dialog-title">
        <div className="data-viewer-summary__header">
          <div className="data-viewer-summary__chart-title">
            <FormattedMessage id={title} />
          </div>
        </div>
        <div className="data-viewer-summary__rule"></div>
        <IconButton className="data-viewer-summary__chart-close" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LabelChart
          barSize={48}
          grid
          labels={labels}
          onClick={handleBarClick}
          tooltip
          xAxis
          yAxisRightMargin={16}
          leftMargin={32}
        />
      </DialogContent>
      <DialogActions>
        <FormButton
          buttonRole="primary"
          value={intl.formatMessage({id: 'form.done'})}
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};
