import React, {useRef, useState} from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Divider, Input, InputAdornment} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {Point} from '../../../base-components/StudioAnnotator/types';
import './LabelMenu.scss';

const FREQUENTLY_USED_MAX_COUNT = 3;
export interface LabelMenuProps {
  items?: string[];
  open?: boolean;
  enableSearch?: boolean;
  location?: Point | null;
  onSelect?: (value: string) => void | void;
  onClose?: () => void | void;
}

type LabelFrequencyUsage = {
  label: string;
  frequency: number;
};

const initialLocation = {
  x: 0,
  y: 0,
};

export function LabelMenu({
  open = false,
  items = [],
  enableSearch = false,
  location = initialLocation,
  onSelect = () => {},
  onClose = () => {},
}: LabelMenuProps) {
  const [labelInput, setlabelInput] = useState<string>('');
  const [frequentlyUsed, setFrequentlyUsed] = useState<LabelFrequencyUsage[]>([]);
  const inputField = useRef<HTMLInputElement>(null);

  const onKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSelect(labelInput);
      setlabelInput('');
    }
  };
  const onClick = (event: React.MouseEvent<HTMLLIElement>, label: string) => {
    event.preventDefault();
    onSelect(label);
    onClose();

    // Setup the label frequency count
    let tLabel: LabelFrequencyUsage | undefined = frequentlyUsed.find(
      el => el.label === label
    );
    tLabel = {label, frequency: tLabel ? tLabel.frequency + 1 : 1};
    const arr = [...frequentlyUsed.filter(i => i.label !== label)];
    arr.push({...tLabel});
    setFrequentlyUsed(arr.sort((a, b) => b.frequency - a.frequency));
  };

  const top3Labels = frequentlyUsed.slice(0, FREQUENTLY_USED_MAX_COUNT);
  const otherLabels = items?.filter(item => !top3Labels.find(el => el.label === item));
  const focusInput = () => inputField.current && inputField.current.focus();
  return (
    <Menu
      keepMounted
      open={open}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={{top: location?.y || 0, left: location?.x || 0}}
      className="label-menu"
      onFocus={x => focusInput()}
    >
      <div className="label-menu__input_container">
        <FormControl>
          <Input
            id="label-input"
            inputRef={inputField}
            className="label-menu__input_field"
            placeholder="Type label..."
            value={labelInput}
            onKeyPress={onKeyPress}
            onChange={e => setlabelInput(e.target.value)}
            autoFocus
            endAdornment={
              labelInput ? (
                <InputAdornment position="end">Press Enter</InputAdornment>
              ) : (
                ''
              )
            }
          />
        </FormControl>
      </div>
      {!!top3Labels.length &&
        top3Labels?.map((item, i) => (
          <>
            <Divider />
            <MenuItem key={i} disabled={true} onClick={e => onClick(e, item.label)}>
              {item.label}
            </MenuItem>
          </>
        ))}
      {!!top3Labels.length && !!otherLabels.length && <Divider />}
      {otherLabels.map((item, i) => (
        <MenuItem key={i} disabled={true} onClick={e => onClick(e, item)}>
          {item}
        </MenuItem>
      ))}
    </Menu>
  );
}

export default LabelMenu;
