import React, {useEffect, useState} from 'react';
import {PropTypes} from 'prop-types';
import {Grid} from '@material-ui/core';
import {BaseModalDialog} from './BaseModalDialog';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import {Formik, Form} from 'formik';
import {isEmpty} from 'lodash';
import {getIsNewISPFormOpen, viewCloseNewISP} from '../../store/view';
import {CancelButton, SubmitButton, ISPForm} from './CreateISPFormFields';
import validationSchema from './ValidationSchema';
import {
  ispGetTemplate,
  ispUpdateLocal,
  getIspTemplate,
  getIspColorSpaceOptions,
} from '../../store/isp';
import {ConfirmDialog} from '../../base-components/StudioConfirmDialog';
import './CreateISPFormDialog.scss';
import {useIntl} from 'react-intl';

const CreateISPFormDialogBase = ({
  dispatchViewCloseNewISP,
  dispatchGetIspTemplate,
  open,
  history,
  projectId,
  dispatchIspUpdateLocal,
  colorSpaceOptions,
  template,
}) => {
  let reset = null;
  const intl = useIntl();
  const [data, setData] = useState(null);
  const [openConfirmDiscard, setOpenConfirmDiscard] = useState(false);

  useEffect(() => {
    if (projectId && open && dispatchGetIspTemplate) {
      dispatchGetIspTemplate({projectId});
    }
  }, [projectId, open, dispatchGetIspTemplate]);

  useEffect(() => {
    template && setData(template.transformations);
  }, [template]);

  function onSubmit(values, {resetForm}) {
    const name = values.name;
    delete values.name;
    dispatchIspUpdateLocal(
      {
        body: {
          name,
          transformations: {
            input: {
              ...values,
              output_color_space: data?.input?.output_color_space,
            },
            label: data.label,
          },
        },
      },
      true
    );
    dispatchViewCloseNewISP();
    resetForm();
    history.push(`/project/${projectId}/flowgraph/${template.id}`);
  }

  function onCancel(dirty) {
    if (!dirty) {
      cleanup();
    } else {
      setOpenConfirmDiscard(true);
    }
  }

  function cleanup() {
    setOpenConfirmDiscard(false);
    reset && setTimeout(reset, 1000);
    dispatchViewCloseNewISP();
  }

  return (
    <div>
      {!isEmpty(data) && (
        <Formik
          initialValues={{
            name: '',
            description: '',
            bpp: data?.input?.bpp,
            input_color_space: data?.input?.input_color_space,
            input_width: data?.input?.input_width,
            input_height: data?.input?.input_height,
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({handleReset, dirty}) => {
            reset = handleReset;
            return (
              <Form>
                <BaseModalDialog
                  open={open}
                  onClose={() => onCancel(dirty)}
                  title={intl.formatMessage({id: 'isp.createDialogTitle'})}
                  testId="create-isp-form"
                  size="sm"
                  content={<ISPForm colorSpaceOptions={colorSpaceOptions} />}
                  buttons={
                    <Grid container>
                      <Grid item xs={6}></Grid>
                      <Grid item xs={6} className="isp-form-dialog__button_action">
                        <CancelButton onClick={() => onCancel(dirty)} />
                        <SubmitButton />
                      </Grid>
                    </Grid>
                  }
                />
              </Form>
            );
          }}
        </Formik>
      )}
      <ConfirmDialog
        type="confirm"
        title={intl.formatMessage({id: 'isp.discardDialogTitle'})}
        message={intl.formatMessage({id: 'isp.discardConfirmation'})}
        open={openConfirmDiscard}
        onClose={() => setOpenConfirmDiscard(false)}
        onOk={cleanup}
        submitLabel={intl.formatMessage({id: 'form.discard'})}
      />
    </div>
  );
};

CreateISPFormDialogBase.propTypes = {
  projectId: PropTypes.string,
};

CreateISPFormDialogBase.defaultProps = {
  projectId: null,
};

const mapStateToProps = state => ({
  open: getIsNewISPFormOpen(state),
  colorSpaceOptions: getIspColorSpaceOptions(state),
  template: getIspTemplate(state),
});

const mapDispatchToProps = {
  dispatchGetIspTemplate: ispGetTemplate,
  dispatchIspUpdateLocal: ispUpdateLocal,
  dispatchViewCloseNewISP: viewCloseNewISP,
};

export const CreateISPFormDialog = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CreateISPFormDialogBase);
