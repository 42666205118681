import {createSlice, PayloadAction, AnyAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {Dispatch, useCallback, useEffect, useReducer, useRef} from 'react';
import {useSelector} from 'react-redux';
import URL from '../../../config/url';
import {RootState} from '../../../store';
import {toast} from '../../../base-components/StudioToast';
import {
  MarketplaceFilters,
  SortableColumn,
  SortDirection,
} from '../../../types/marketplace/MarketplaceFilesRequest';
import {
  Integration,
  IntegrationPurpose,
  IntegrationRole,
} from '../../../types/integrations/IntegrationResponse';
import {
  MarketplaceFilesResponse,
  MarketplaceResult,
} from '../../../types/marketplace/MarketplaceFilesResponse';

type MarketplaceState = {
  isLoading: boolean;
  selectedIds: Record<string, MarketplaceResult>;
  displayName: string;
  sort: SortableColumn;
  sortDir: SortDirection;
  filters: MarketplaceFilters;
  results?: Array<MarketplaceResult>;
  count: number;
  page: number;
};

const initialState: MarketplaceState = {
  isLoading: false,
  selectedIds: {},
  displayName: '',
  sort: 'displayName',
  sortDir: 'ASC',
  filters: {},
  results: undefined,
  count: 0,
  page: 0,
};

const getInitialState = (defaultFilterValues?: MarketplaceFilters) => {
  return {
    ...initialState,
    filters: defaultFilterValues || initialState.filters,
  };
};

const explorerSlice = createSlice({
  name: 'marketplace-explorer',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<MarketplaceState['isLoading']>) => {
      state.isLoading = action.payload;
    },
    setDisplayName: (state, action: PayloadAction<MarketplaceState['displayName']>) => {
      state.displayName = action.payload;
    },
    setSort: (state, action: PayloadAction<MarketplaceState['sort']>) => {
      state.sort = action.payload;
    },
    setSortDir: (state, action: PayloadAction<MarketplaceState['sortDir']>) => {
      state.sortDir = action.payload;
    },
    setSelected: (state, action: PayloadAction<MarketplaceState['selectedIds']>) => {
      state.selectedIds = action.payload;
    },
    setFilters: (state, action: PayloadAction<MarketplaceState['filters']>) => {
      state.filters = action.payload;
    },
    setResults: (
      state,
      action: PayloadAction<{
        results?: {
          count: number;
          entries: MarketplaceResult[];
        };
        page: number;
      }>
    ) => {
      const {results, page} = action.payload;
      if (results) {
        const {count, entries} = results;
        state.results = entries;
        state.count = count;
        state.page = page;
      }
    },
    resetFilters: (state, action: PayloadAction<MarketplaceFilters>) => {
      state.filters = action.payload || initialState.filters;
    },
    resetSelectedIds: state => {
      state.selectedIds = {};
    },
    resetResults: state => {
      state.results = undefined;
      state.count = 0;
      state.page = 0;
      state.selectedIds = {};
    },
    resetSort: state => {
      state.sort = initialState.sort;
      state.sortDir = initialState.sortDir;
    },
    reset: (state, action: PayloadAction<MarketplaceFilters>) => {
      return getInitialState(action.payload);
    },
  },
});

export const {actions} = explorerSlice;

const {setIsLoading, setResults, resetResults, reset} = actions;

type UseMarketplaceProps = {
  role?: IntegrationRole;
  purpose?: IntegrationPurpose;
  pageSize: number;
  isEnabled?: boolean;
  projectId?: string;
  defaultFilterValues?: MarketplaceFilters;
  crossMarketplace?: boolean;
};

type UseMarketplaceResponse = {
  state: MarketplaceState;
  integration?: Integration;
  updateResults: (integration: Integration, firstPage?: number) => void;
  dispatch: Dispatch<AnyAction>;
};

export const useMarketplace: (props: UseMarketplaceProps) => UseMarketplaceResponse = ({
  role,
  purpose,
  pageSize = 10,
  isEnabled = true,
  projectId,
  defaultFilterValues = {},
  crossMarketplace = false,
}) => {
  const [state, dispatch] = useReducer(explorerSlice.reducer, initialState, state =>
    getInitialState(defaultFilterValues)
  );
  const {displayName, sort, sortDir, filters} = state;
  const groupId = useSelector((state: RootState) => state.user.groupId);
  const integration = useSelector(
    (state: RootState) =>
      role && purpose && state.marketplace.integrations[purpose]?.[role]
  );
  const defaultFilterValuesRef = useRef(defaultFilterValues);

  const updateResults = useCallback(
    async (integration, firstPage = 0, cancelToken?: {cancelled: boolean}) => {
      dispatch(setIsLoading(true));
      try {
        const {compatibleOnly, ...params} = filters;
        const {data} = await axios.post<MarketplaceFilesResponse>(URL.MARKETPLACE_FILES, {
          firstPage,
          pageSize: pageSize,
          paginated: true,
          params: {...params, displayName},
          orderDefinitions: [{field: sort, direction: sortDir}],
          projectId,
          compatibleOnly: filters.compatibleOnly,
          integrationIds: integration && [integration.id],
          marketplacePurpose: purpose,
          ownerId: groupId,
        });
        if (data.errors?.length) {
          toast.error(data.errors);
        } else {
          const results = data.body;
          if (!cancelToken?.cancelled) {
            dispatch(setResults({results, page: firstPage}));
          }
        }
      } finally {
        dispatch(setIsLoading(false));
      }
    },
    [displayName, filters, sort, sortDir, pageSize, projectId, dispatch, purpose, groupId]
  );

  useEffect(() => {
    const cancelToken = {cancelled: false};
    if (!isEnabled) {
      dispatch(reset(defaultFilterValuesRef.current));
    } else {
      if (!integration) {
        if (crossMarketplace) {
          updateResults(undefined, 0, cancelToken);
        } else {
          dispatch(resetResults());
        }
      } else {
        updateResults(integration, 0, cancelToken);
      }
    }
    return () => {
      cancelToken.cancelled = true;
    };
  }, [isEnabled, crossMarketplace, integration, updateResults]);

  return {state, integration, updateResults, dispatch};
};
