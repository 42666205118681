import React from 'react';
import {v4} from 'uuid';
import './Chat.scss';
import {
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  MessageModel,
} from '@chatscope/chat-ui-kit-react';
import {TaskInfo} from '../../types/metadata/TaskInfo';

export type MessageType = 'SYSTEM' | 'APPLICATION';

export interface ChatConnection {
  send: (s: string) => void;
  setMessageConsumer: (x: (m: string, t: MessageType) => void) => void;
  setStatusConsumer: (x: (s: ChatConnectionStatus) => void) => void;
  updateTaskInfo: (x: TaskInfo) => void;
  close: () => void;
}

export enum ChatConnectionStatus {
  CONNECTING,
  CONNECTED,
  ACTIVE,
  DISCONNECTED,
  ERROR,
}

export type ChatProps = {
  connection: ChatConnection;
};

interface MessageModelWithId extends MessageModel {
  id: string;
}

type State = {
  connection: ChatConnection;
  status: ChatConnectionStatus;
  history: MessageModelWithId[];
  messageInputValue: string;
  messageText: string;
};

export class Chat extends React.Component<ChatProps, State> {
  constructor(props: ChatProps, context: any) {
    super(props, context);
    const self = this;
    this.props.connection.setMessageConsumer((m, t) => this.onMessage(m, t, self));
    this.props.connection.setStatusConsumer(x => self.setState({status: x}));
    this.state = {
      connection: this.props.connection,
      status: ChatConnectionStatus.CONNECTING,
      history: [],
      messageInputValue: '',
      messageText: '',
    };
  }

  componentWillUnmount() {
    this.props.connection.close();
  }

  private onMessage(s: String, t: MessageType, self: Chat) {
    const date = new Date();
    const inMsg = {
      id: v4(),
      message: t === 'SYSTEM' ? '<i>' + s + '</i>' : s,
      sentTime: date.toDateString() + ' ' + date.toTimeString(),
      sender: 'Application',
      direction: 'incoming',
      position: 0,
    } as MessageModelWithId;
    self.setState({history: [...self.state.history, inMsg]});
    return;
  }

  loading(): boolean {
    return (
      this.state.status === ChatConnectionStatus.CONNECTING ||
      this.state.status === ChatConnectionStatus.CONNECTED
    );
  }

  disabled(): boolean {
    return (
      this.state.status === ChatConnectionStatus.DISCONNECTED ||
      this.state.status === ChatConnectionStatus.ERROR
    );
  }

  render() {
    return (
      <div style={{position: 'relative', width: '100%'}}>
        {/*<MainContainer style={styles.csMainContainer}>*/}
        <MainContainer>
          <ChatContainer>
            <MessageList loading={this.loading()}>
              {this.state.history.map(x => (
                <Message model={x} key={x.id}>
                  <Message.Footer sentTime={x.sentTime} />
                </Message>
              ))}
            </MessageList>
            <MessageInput
              attachButton={false}
              value={this.disabled() ? 'Not connected' : this.state.messageInputValue}
              sendButton={!this.disabled()}
              onChange={(ih, tc, it, n) =>
                this.setState({
                  messageInputValue: ih,
                  messageText: it,
                })
              }
              disabled={this.loading() || this.disabled()}
              onSend={() => {
                const message = this.state.messageText;
                this.setState({messageInputValue: '', messageText: ''});
                const date = new Date();
                const outMsg = {
                  id: v4(),
                  message: message,
                  sentTime: date.toDateString() + ' ' + date.toTimeString(),
                  sender: 'Me',
                  direction: 'outgoing',
                  position: 0,
                } as MessageModelWithId;
                this.setState({history: [...this.state.history, outMsg]});
                this.state.connection.send(message);
              }}
            />
          </ChatContainer>
        </MainContainer>
      </div>
    );
  }
}
