import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ArrowAngledRightIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g
            id="Asset-Artboard-Page"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="General-/-Text-w_icon-/-Secondary-/-Enabled">
              <g
                id="general-/-chevron_menu_right-/-Tertiary-Buton,-Hover_Active-general-/-add-to-field-/-Default"
                transform="translate(4.000000, 0.000000)"
              >
                <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                <polygon
                  id="add-to-field"
                  fill="#767676"
                  points="11.7163121 7 17 7 17 12.2836879 15.4751773 12.2836879 15.4751773 9.58865248 8.06382979 17 7 15.9361702 14.4113475 8.5248227 11.7163121 8.5248227"
                ></polygon>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

ArrowAngledRightIcon.propTypes = {
  className: PropTypes.string,
};

export default ArrowAngledRightIcon;
