import axios from 'axios';
import URL from '../../config/url';
// ACTIONS
export const PROJECT_OP_STATUS_FETCH_PENDING = 'projectOpStatus/fetch-pending';
export const PROJECT_OP_STATUS_FETCH_SUCCESS = 'projectOpStatus/fetch-success';
export const PROJECT_OP_STATUS_FETCH_ERROR = 'projectOpStatus/fetch-error';

// ACTION CREATORS
export const projectOpStatusFetchPending = payload => ({
  type: PROJECT_OP_STATUS_FETCH_PENDING,
  payload,
});

export const projectOpStatusFetchSuccess = payload => ({
  type: PROJECT_OP_STATUS_FETCH_SUCCESS,
  payload,
});

export const projectOpStatusFetchError = payload => ({
  type: PROJECT_OP_STATUS_FETCH_ERROR,
  payload,
});

export const projectOpStatusFetch = (id, params) => async dispatch => {
  try {
    dispatch(projectOpStatusFetchPending({id}));
    const status = await axios.get(URL.PROJECT_OP_STATUS(id), params);
    dispatch(projectOpStatusFetchSuccess(status.data.body));
    return status.data;
  } catch (error) {
    dispatch(projectOpStatusFetchError(error));
    return null;
  }
};
