import {RawInferenceResponse} from '../../types/gsp/RawInferenceResponse';
import {request} from '../request';

type TestExecuteUIParams = {
  files: File[];
  applicationId: string;
  targetDeviceType: string;
};

type DeploymentExecuteUIParams = {
  files: File[];
  deploymentId: string;
};

export async function testExecute({
  applicationId,
  files,
  targetDeviceType,
}: TestExecuteUIParams) {
  const data = new FormData();
  files.forEach(file => data.append('file', file));
  data.append('applicationId', applicationId);
  data.append('targetDeviceType', targetDeviceType);
  data.append('outputFormat', 'JSON');

  return await request<RawInferenceResponse>({
    method: 'POST',
    url: '/gsp/ui/execute',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
}
export async function deploymentExecute({
  deploymentId,
  files,
}: DeploymentExecuteUIParams) {
  const data = new FormData();
  files.forEach(file => data.append('file', file));
  data.append('deploymentId', deploymentId);
  data.append('outputFormat', 'JSON');

  return await request<RawInferenceResponse>({
    method: 'POST',
    url: '/deployment/ui/execute',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
}
