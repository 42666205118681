import {GSPConfigurationInfo} from '../../types/deployment/GSPConfigurationInfo';
import {SortDirection} from '../../types/marketplace/MarketplaceFilesRequest';
import {request} from '../request';

export type NodesConfigurationSortableColumn = 'name';

export type DeploymentNodesConfigurationRequest = {
  filter: string;
  page: number;
  size?: number;
  sort: [NodesConfigurationSortableColumn, SortDirection];
};

export async function getDeploymentNodesConfiguration(
  params: DeploymentNodesConfigurationRequest
) {
  return request<Array<GSPConfigurationInfo>>({
    method: 'get',
    url: '/deployment/nodes/configuration',
    params,
    // The default param serializer for axios doesn't format array params in the way
    // studio server expects. TODO: determine if this setting should be global on axios
    paramsSerializer: params => {
      const searchParams = new URLSearchParams(params);
      return searchParams.toString();
    },
  });
}
