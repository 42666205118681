import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {List, ListItem, Divider} from '@material-ui/core';
import {Link} from 'react-router-dom';
import HumanReadableTime from '../../../base-components/StudioHumanReadableTime/HumanReadableTime';
import './RecentProjects.scss';

const NUM_PROJECTS = 3;

export const RecentProjects = ({projects, className}) => {
  return (
    <List
      className={cn('recent-projects', className)}
      disablePadding
      data-testid="recent-projects"
    >
      {projects.slice(0, NUM_PROJECTS).map(({id, name, lastUpdateTime}, index) => (
        <Fragment key={index}>
          {index > 0 && <Divider />}
          <Link to={`/project/${id}/upload`}>
            <ListItem
              button
              className="recent-projects__body-row"
              key={id}
              data-test-projectid={id}
              data-testid="recent-projects-row"
            >
              <div className="recent-projects__body-cell recent-projects__name">
                {name}
              </div>
              <div
                className="recent-projects__body-cell recent-projects__date"
                align="right"
              >
                <HumanReadableTime date={lastUpdateTime} />
              </div>
            </ListItem>
          </Link>
        </Fragment>
      ))}
    </List>
  );
};

RecentProjects.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      lastUpdateTime: PropTypes.string,
    })
  ),
  className: PropTypes.string,
};

RecentProjects.defaultProps = {
  data: [],
  className: null,
  onClick: () => {},
};
