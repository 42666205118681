import axios from 'axios';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useLocation} from 'react-router';
import {Redirect} from 'react-router-dom';
import {AuthPageBody, AuthPageContainer, AuthPageHeader} from '..';
import URL from '../../../config/url';
import {Login} from '../Login';

export function ActivateAccount(props) {
  const location = useLocation();
  const [error, setError] = useState(false);
  const [activated, setActivated] = useState(false);
  const params = new URLSearchParams(location.search);
  const emailAddress = params.get('emailAddress');
  const token = params.get('token');

  if (error) {
    const newParams = new URLSearchParams();

    if (emailAddress) {
      newParams.set('emailAddress', emailAddress);
    }

    return (
      <Redirect
        to={{
          pathname: '/resend-activation',
          search: newParams.toString(),
        }}
      />
    );
  }

  if (activated) {
    return <Login {...props} activated={activated} />;
  }

  activate(emailAddress, token);

  return (
    <AuthPageContainer>
      <AuthPageHeader>
        <FormattedMessage id="auth.activateAccount" />
      </AuthPageHeader>
      <AuthPageBody>
        <p>
          <FormattedMessage id="auth.activatingAccount" />
        </p>
      </AuthPageBody>
    </AuthPageContainer>
  );

  async function activate(emailAddress, token) {
    try {
      const res = await axios.post(URL.ACTIVATE_ACCOUNT, {
        emailAddress,
        token,
      });

      if (res.data.errors) {
        setError(true);
      } else {
        setActivated(true);
      }
    } catch (err) {
      setActivated(false);
    }
  }
}
