import cn from 'classnames';
import {isNil} from 'lodash';
import {ExpandMore} from '@material-ui/icons';
import {FormattedMessage, useIntl} from 'react-intl';
import React, {createContext, useContext, useMemo, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
} from '@material-ui/core';

import {useDeploymentDetails} from '../useDeploy';
import {useProjectGet} from '../../../api/project/ProjectGet';
import {useTestListByLatest} from '../../../api/test/TestList';
import {detailsConfig, DetailsBag, FieldConfig, SectionConfig} from './TestDetailsConfig';

import './TestDetails.scss';

const AccordionContext = createContext<{setHasRows?: (hasRows: boolean) => void}>({});

type TestDetailsProps = {
  ids: string[];
  hideMissingFields?: boolean;
};

export function TestDetails({ids, hideMissingFields}: TestDetailsProps) {
  const span = {style: {gridColumnStart: `span ${ids.length + 1}`}};

  const renderField = (fieldConfig: FieldConfig) => {
    if ('fields' in fieldConfig) {
      return renderSection(fieldConfig, true);
    } else {
      return (
        <Field
          ids={ids}
          id={fieldConfig.id}
          hideMissingFields={hideMissingFields}
          key={`test-details-field-${fieldConfig.id}`}
        >
          {fieldConfig.render}
        </Field>
      );
    }
  };

  const renderSection = (sectionConfig: SectionConfig, useSpan = false) => {
    if (sectionConfig.accordion) {
      return (
        <TestAccordion
          key={`test-details-section-${sectionConfig.id}`}
          {...(useSpan ? span : null)}
        >
          <TestAccordionSummary>
            <FormattedMessage id={sectionConfig.id} />
          </TestAccordionSummary>
          <TestAccordionDetails>
            <Section ids={ids}>
              {sectionConfig.fields.map(fieldConfig => renderField(fieldConfig))}
            </Section>
          </TestAccordionDetails>
        </TestAccordion>
      );
    } else {
      return (
        <Section key={`test-details-section-${sectionConfig.id}`} ids={ids}>
          {sectionConfig.fields.map(fieldConfig => renderField(fieldConfig))}
        </Section>
      );
    }
  };

  return (
    <div className="test-details">
      {detailsConfig.map(sectionConfig => renderSection(sectionConfig))}
    </div>
  );
}

type SectionProps = {
  ids: string[];
  children: React.ReactNode;
} & React.ComponentProps<'section'>;

function Section({ids, children, className, style, ...rest}: SectionProps) {
  return (
    <section
      {...rest}
      className={cn('test-details__section', className)}
      style={{
        gridTemplateColumns: `1fr repeat(${ids.length}, 2fr)`,
        ...style,
      }}
    >
      {children}
    </section>
  );
}

type FieldProps = {
  id: string;
  ids: string[];
  key: string;
  hideMissingFields?: boolean;
  children: (props: DetailsBag) => React.ReactNode;
};

function Field(props: FieldProps) {
  const accordionContext = useContext(AccordionContext);
  const intl = useIntl();
  const [hasItem, setHasItem] = useState(false);
  const items: React.ReactElement[] = [];

  type WrappedFieldProps = {
    setHasItem: typeof setHasItem;
  };

  for (const [i, id] of Object.entries(props.ids)) {
    const WrappedField = ({setHasItem}: WrappedFieldProps) => {
      const test = useDeploymentDetails(id);
      const projectId = useMemo(() => test?.projectId, [test?.projectId]);
      const {data: project} = useProjectGet({projectId: projectId || null});
      const application = useMemo(
        () =>
          test?.applicationId ? project?.applications?.[test.applicationId] : undefined,
        [project, test]
      );
      const {data: historyItems} = useTestListByLatest({
        projectId,
      });
      const environmentVariables = useMemo(
        () => ({
          ...application?.model?.frameworkParameters?.environment,
          ...historyItems?.entries.find(deployment => deployment.id === id)?.environment,
        }),
        [application, historyItems]
      );

      const item = props.children({
        application,
        project,
        test,
        environmentVariables,
        intl,
      });

      const isMissing = isNil(item) || (typeof item === 'string' && item.length === 0);

      if (isMissing && props.hideMissingFields) {
        setHasItem(false);
        return null;
      } else if (!hasItem) {
        setHasItem(true);
      }

      return (
        <div className="test-details__item">
          {isMissing ? intl.formatMessage({id: 'testCompare.notAvailable'}) : item}
        </div>
      );
    };

    items.push(<WrappedField key={`wrapper-field-${i}`} setHasItem={setHasItem} />);
  }

  if (accordionContext?.setHasRows && hasItem) {
    accordionContext.setHasRows(true);
  }

  return (
    <>
      {hasItem ? (
        <div className="test-details__heading">
          <FormattedMessage id={props.id} />
        </div>
      ) : null}
      {items}
    </>
  );
}

type TestAccordionProps = AccordionProps & {
  className?: string;
  children: React.ReactNode;
};

function TestAccordion({children, className, ...rest}: TestAccordionProps) {
  const [hasRows, setHasRows] = useState(false);
  const providerValue = useMemo(() => ({hasRows, setHasRows}), [hasRows, setHasRows]);

  return (
    <AccordionContext.Provider value={providerValue}>
      <Accordion
        defaultExpanded
        elevation={0}
        {...rest}
        className={cn(
          'test-details__accordion',
          !hasRows && 'test-details__accordion--hidden',
          className
        )}
      >
        {children}
      </Accordion>
    </AccordionContext.Provider>
  );
}

type TestAccordionSummaryProps = AccordionSummaryProps & {
  className?: string;
  children: React.ReactNode;
};

function TestAccordionSummary({children, className, ...rest}: TestAccordionSummaryProps) {
  return (
    <AccordionSummary
      expandIcon={<ExpandMore className="test-details__expand" />}
      className={cn('test-details__accordion-summary', className)}
      {...rest}
    >
      {children}
    </AccordionSummary>
  );
}

type TestAccordionDetailsProps = AccordionDetailsProps & {
  className?: string;
  children: React.ReactNode;
};

function TestAccordionDetails({children, className, ...rest}: TestAccordionDetailsProps) {
  return (
    <AccordionDetails
      {...rest}
      className={cn('test-details__accordion-details', className)}
    >
      {children}
    </AccordionDetails>
  );
}
