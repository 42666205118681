import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';
import StagePanel from './StagePanel';
import FormButton from '../../../base-components/StudioButton/FormButton';
import StageAnimation from '../StageAnimation/StageAnimation';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import ModelActionButton from '../../../base-components/StudioButton/ModelActionButton';
import {StudioPopover} from '../../../base-components/StudioPopover';
import TaskInfoProps from '../../../model/taskInfoPropTypes';
import {toast} from '../../../base-components/StudioToast';
import {connect} from 'react-redux';
import {actions} from '../../../store/marketplace/add-data';
import {openPublishForm} from '../../../store/marketplace/configure';
import {addListener, removeListener} from '../../../store/statusChecker';
import {StageAssistantForm} from '../../StageAssistantForm';
import {getProjectDataSet} from '../../../store/project';
import {PanelBadge} from './PanelBadge';
import {getViewProject} from '../../../store/view';
import {TerminateButton} from './TerminateButton';
import {datasetLabelingStart} from '../../../store/dataPrepare';
import {unwrapResult} from '@reduxjs/toolkit';
import {dataPrepareActions} from '../../../store/dataPrepare';
import {
  viewDisableTermination,
  viewEnableTermination,
  getIsDiabledEditDataStage,
} from '../../../store/view';
import './DataPanel.scss';

class DataPanel extends StagePanel {
  constructor(props) {
    super(props);
    this.state = {
      model: null,
      imageSet: '',
      imageLoadError: false,
      disableEdit: false,
    };

    this.showInitialPrompt = this.showInitialPrompt.bind(this);
    this.showAssistant = this.showAssistant.bind(this);
    this.hideAssistant = this.hideAssistant.bind(this);
    this.handleInputTypeSelection = this.handleInputTypeSelection.bind(this);
    this.initiateDataPrepare = this.initiateDataPrepare.bind(this);
    this.initiateDataUpload = this.initiateDataUpload.bind(this);
    this.terminateDataUpload = this.terminateDataUpload.bind(this);
    this.initiateDataSplit = this.initiateDataSplit.bind(this);
    this.terminateDataSplit = this.terminateDataSplit.bind(this);
    this.initiateDataTiling = this.initiateDataTiling.bind(this);
    this.terminateDataTiling = this.terminateDataTiling.bind(this);
    this.initiateDataEdit = this.initiateDataEdit.bind(this);
    this.setImageSet = this.setImageSet.bind(this);
    this.getImageThumbnail = this.getImageThumbnail.bind(this);
    this.handleImageLoadError = this.handleImageLoadError.bind(this);
    this.resumeDataPrep = this.resumeDataPrep.bind(this);
  }

  componentDidMount() {
    if (this.props.stage.status === 'FINISHED') {
      this.getImageThumbnail(this.props.projectId, this.setImageSet);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.dataSet !== prevProps.dataSet) {
      this.getImageThumbnail(this.props.projectId, this.setImageSet);
    }
  }

  showInitialPrompt() {
    this.showAssistant(
      <StageAssistantForm
        title="Select an option"
        tooltip={this.props.intl.formatMessage({id: 'dataUpload.defaultTooltip'})}
        prompts={[
          {
            '@class': 'com.blaize.studio.core.model.assistant.MultipleChoiceQuestion',
            text: '',
            id: 'db884439-935e-45e7-8ff2-5380d39809ce',
            type: 'MULTIPLE_CHOICES',
            key: 'Data.Upload.Method',
            mandatory: true,
            disabled: false,
            radio: false,
            options: {
              UPLOAD: this.props.intl.formatMessage({id: 'dataUpload.loadExisting'}),
              PREPARE: this.props.intl.formatMessage({id: 'dataUpload.labelAndPrepare'}),
            },
            control: 'radio',
          },
        ]}
        defaults={{}}
        onSubmit={this.handleInputTypeSelection}
        onCancel={this.hideAssistant}
        notifyType="ADD_DATA_INITIAL"
      />
    );
  }

  showAssistant(form) {
    let elem = document.getElementById('ADD_DATA-container');
    const stageAnimation = new StageAnimation();
    if (elem && !elem.classList.contains('active')) {
      stageAnimation.updateStages(elem);
    }
    let current = this.props.assistantPanel.current;
    if (current) {
      current.display(form, 'ADD_DATA', null);
    }
  }

  hideAssistant() {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.hide();
    }
  }

  handleInputTypeSelection(answers) {
    const actions = {
      UPLOAD: this.initiateDataUpload,
      PREPARE: this.initiateDataPrepare,
    };
    const selection = answers[0]?.value;
    if (selection) {
      const action = actions[selection.toUpperCase()];
      if (action) {
        this.hideAssistant();
        action();
      }
    }
  }

  setImageSet(images) {
    this.setState({imageSet: images, imageLoadError: false}, () => {});
  }

  getImageThumbnail(projectId, callback) {
    projectId &&
      callback(
        `/studio/dataset/${projectId}/thumbnails?size=6:3&resolution=128:128&token=${Math.floor(
          Math.random() * 10000
        )}`
      );
  }

  handleImageLoadError(e) {
    this.setState({imageLoadError: true});
  }

  getInactiveContent() {
    return (
      <div className="inactive-content">
        <div className="inactive-container">
          <FormButton
            buttonRole="secondary"
            value="Add Data"
            onClick={this.showInitialPrompt}
          />
        </div>
      </div>
    );
  }

  getActiveContent() {
    return (
      <div className="active-content add-data-panel" data-testid="ADD_DATA-panel__active">
        <div className="active-container">
          <FormButton
            buttonRole="primary"
            value="Add Data"
            onClick={this.showInitialPrompt}
          />
        </div>
      </div>
    );
  }

  getInProgressContent() {
    const type = this.props.datasetActionInProgress;

    const captions = {
      upload: 'Adding Data',
      split: 'Splitting Data',
      tiling: 'Tiling Data',
      edit: 'Preparing Data for Editing',
    };

    const terminators = {
      upload: this.terminateDataUpload,
      split: this.terminateDataSplit,
      tiling: this.terminateDataTiling,
    };

    return (
      <div className="stage-panel-status">
        <div
          className="in-progress-content add-data-panel"
          data-testid="ADD_DATA-panel__in-progress"
        >
          <div className="add-data-in-progress-indicator" />
          <div className="panel-add-data-active" />
          <div className="panel-title">
            <span className="panel-caption">
              {captions[type]}
              <br />
              <span className="panel-description" id="add-data-action-progress">
                Checking for completeness
              </span>
            </span>
            <StudioPopover
              infoIconClass="stage-panel__tooltip"
              infoMessage="Data is being copied to the repository on the Studio server. Actual number of processed bytes can differ from size in marketplace."
            />
          </div>
        </div>
        <div
          className="in-progress-content add-data-panel stage-panel-buttons"
          data-testid={`${this.props.stage.type}-action-buttons`}
        >
          <TerminateButton
            onClick={() => {
              this.props.openTerminateDialog({
                terminate: terminators[this.props.datasetActionInProgress],
                title: this.props.intl.formatMessage({id: 'terminate.dataUploadTitle'}),
                message: this.props.intl.formatMessage({
                  id: 'terminate.dataUploadContent',
                }),
              });
            }}
          />
        </div>
      </div>
    );
  }

  getFinishedContent() {
    const {imageSet, imageLoadError} = this.state;
    return (
      <>
        {this.props.datasetActionInProgress !== 'split' && (
          <PanelBadge text={this.props?.stage?.warning} />
        )}
        <div className="stage-panel-status">
          <div
            className="finished-content add-data-panel"
            data-testid="ADD_DATA-panel__finished"
          >
            <div className="panel-add-data-active" />
            <Link
              className="finished-content__viewer-link"
              to={`/project/${this.props.projectId}/data/view`}
            >
              {!imageLoadError && (
                <img
                  src={imageSet}
                  alt="Dataset thumbnail images"
                  className="thumbnail"
                  onError={this.handleImageLoadError}
                />
              )}
            </Link>
            <div className="panel-title">
              <span className="panel-caption" id="add-data-finished">
                {this.props.stage.entityName}
              </span>
            </div>
          </div>
          <div
            className="finished-content add-data-panel stage-panel-buttons"
            data-testid={`${this.props.stage.type}-action-buttons`}
          >
            <ModelActionButton
              value="share"
              onClick={() =>
                this.props.openPublishForm({
                  projectId: this.props.projectId,
                  resourceId: this.props.dataSet?.id,
                  purpose: 'DATASET_STORAGE',
                })
              }
            />
            {this.props.dataPrepUnfinished ? (
              <ModelActionButton value="resume" onClick={this.resumeDataPrep} />
            ) : (
              <ModelActionButton
                value="edit"
                onClick={this.initiateDataEdit}
                disabled={!this.props.dataSet?.isEditable}
              />
            )}
            <ModelActionButton value="replace" onClick={this.showInitialPrompt} />
            {this.props.resplitAvailable &&
              this.props.modelActionInProgress !== 'build' && (
                <ModelActionButton value="split data" onClick={this.initiateDataSplit} />
              )}
            {this.props.tilingAvailable && (
              <ModelActionButton value="tile data" onClick={this.initiateDataTiling} />
            )}
          </div>
        </div>
      </>
    );
  }

  getOverlay() {
    return (
      <div
        className={
          'stage-panel-overlay' + (this.props.stage.active ? ' active' : ' inactive')
        }
        onClick={this.activatePanel}
        id={this.props.stage.type + '-panel-overlay'}
      />
    );
  }

  resumeDataPrep() {
    if (this.props.dataPrepAction?.current) {
      this.props.dataPrepAction.current.initiate();
    }
  }

  initiateDataPrepare() {
    this.props.history.push(`/project/${this.props.projectId}/data/prepare/raw`);
  }

  initiateDataUpload() {
    let elem = document.getElementById('ADD_DATA-container');
    const stageAnimation = new StageAnimation();
    if (elem && !elem.classList.contains('active')) {
      stageAnimation.updateStages(elem);
    }
    this.props.setDatasetActionInProgress('upload');
    this.props.setDialogIsOpen({isOpen: true});
  }

  async terminateDataUpload() {
    let current = this.props.dataUploadAction.current;
    if (current) {
      await current.terminateDataUpload();
      toast.success({position: 'top', subtitle: 'User terminated data upload'});
    }
  }

  initiateDataSplit() {
    if (this.props.dataSplitAction.current) {
      this.props.setDatasetActionInProgress('split');
      this.props.dataSplitAction.current.initiateDataSplit();
    }
  }

  async terminateDataSplit() {
    if (this.props.dataSplitAction.current) {
      await this.props.dataSplitAction.current.terminateDataSplit();
      toast.success({position: 'top', subtitle: 'User terminated data split'});
    }
  }

  initiateDataTiling() {
    if (this.props.dataTilingAction.current) {
      this.props.setDatasetActionInProgress('tiling');
      this.props.dataTilingAction.current.initiateDataTiling();
    }
  }

  async terminateDataTiling() {
    if (this.props.dataTilingAction.current) {
      await this.props.dataTilingAction.current.terminateDataTiling();
      toast.success({position: 'top', subtitle: 'User terminated data tiling'});
    }
  }

  async initiateDataEdit() {
    const stageAnimation = new StageAnimation();
    stageAnimation.markInProgress('ADD_DATA');
    this.props.setDatasetActionInProgress('edit');
    this.props.viewDisableTermination();
    try {
      const action = await this.props.datasetLabelingStart({
        projectId: this.props.projectId,
      });
      const errors = action.errors;
      if (!errors?.length) {
        const dataPrepContainer = unwrapResult(action)?.body;
        if (dataPrepContainer) {
          this.props.setDataPrep({container: dataPrepContainer, context: 'dataset'});
          this.props.history.push(
            `/project/${this.props.projectId}/data/prepare/dataset`
          );
        }
      }
    } catch (e) {
    } finally {
      stageAnimation.markFinished('ADD_DATA');
      this.props.viewEnableTermination();
    }
  }
}

DataPanel.propTypes = {
  projectId: PropTypes.string.isRequired,
  assistantPanel: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)}),
  ]).isRequired,
  onFinish: PropTypes.func.isRequired,
  taskProgress: TaskInfoProps,
};

const mapDispatchToProps = {
  setDialogIsOpen: actions.setIsOpen,
  openPublishForm,
  addStatusListener: addListener,
  removeStatusListener: removeListener,
  datasetLabelingStart: datasetLabelingStart,
  setDataPrep: dataPrepareActions.setDataPrep,
  viewDisableTermination,
  viewEnableTermination,
};

const mapStateToProps = state => ({
  dataSet: getProjectDataSet(state),
  projectView: getViewProject(state),
  shouldDisabledViewStage: getIsDiabledEditDataStage(state),
});

const withRouterForwardRef = Component => {
  const WithRouter = withRouter(({forwardedRef, ...props}) => (
    <Component ref={forwardedRef} {...props} />
  ));

  return forwardRef((props, ref) => <WithRouter {...props} forwardedRef={ref} />);
};

const WrappedDataPanel = compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  withRouterForwardRef
)(DataPanel);

export default injectIntl(WrappedDataPanel, {forwardRef: true});
