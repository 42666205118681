import React from 'react';
import {useResizeDetector} from 'react-resize-detector';
import {ReactResizeDetectorDimensions} from 'react-resize-detector/build/ResizeDetector';
import './StudioContentLayout.scss';

export const isFunction = (fn: any): boolean => typeof fn === 'function';
export const isSSR = (): boolean => typeof window === 'undefined';
export const isDOMElement = (element: any): boolean =>
  element instanceof Element || element instanceof HTMLDocument;

export type StudioContentLayoutDimensions = {
  contentHeight?: number;
  headerHeight?: number;
  footerHeight?: number;
};

export type StudioContentLayoutProps = {
  children?: (
    props: ReactResizeDetectorDimensions & StudioContentLayoutDimensions
  ) => React.ReactNode | React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
};

export function StudioContentLayout({
  children,
  header,
  footer,
  className,
}: StudioContentLayoutProps) {
  const {width, height, ref} = useResizeDetector();
  const {height: headerHeight, ref: headerRef} = useResizeDetector();
  const {height: footerHeight, ref: footerRef} = useResizeDetector();
  const {height: contentHeight, ref: contentRef} = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 1000,
  });

  return (
    <div ref={ref} className={`studio-content-layout ${className}`}>
      {!!header && (
        <header ref={headerRef} className="row">
          {header}
        </header>
      )}
      {!!children && (
        <section ref={contentRef} className="row">
          {children({width, height, contentHeight, headerHeight, footerHeight})}
        </section>
      )}
      {!!footer && (
        <footer ref={footerRef} className="row">
          {footer}
        </footer>
      )}
    </div>
  );
}
