import {Dialog, IconButton} from '@material-ui/core';
import {OpenInNew} from '@material-ui/icons';
import React, {ReactNodeArray, useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import CloseIcon from '../../assets/icons/CloseIcon';
import FormButton from '../../base-components/StudioButton/FormButton';
import {ReactComponent as BackButton} from './assets/back_button.svg';
import {ReactComponent as NextButton} from './assets/next_button.svg';
import {ReactComponent as Step1Button} from './assets/step1_button.svg';
import {ReactComponent as Step1Img} from './assets/step1_img.svg';
import {ReactComponent as Step2Img} from './assets/step2_img.svg';
import {ReactComponent as Step3Img} from './assets/step3_img.svg';
import {ReactComponent as Step4Img} from './assets/step4_img.svg';
import {ReactComponent as Step5Img} from './assets/step5_img.svg';
import './StudioWizard.scss';

export type StudioWizardProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function StudioWizard({isOpen, onClose}: StudioWizardProps) {
  const intl = useIntl();
  const [step, setStep] = useState(0);
  const steps = [Step1, Step2, Step3, Step4, Step5];
  const Step = steps[step];

  const close = useCallback(
    function close() {
      onClose();
    },
    [onClose]
  );

  const previous = useCallback(
    function previous() {
      if (step > 0) {
        setStep(step - 1);
      }
    },
    [step]
  );

  const next = useCallback(
    function next() {
      if (step + 1 < steps.length) {
        setStep(step + 1);
      }
    },
    [step, steps.length]
  );

  useEffect(
    function resetStepOnOpen() {
      if (isOpen) {
        setStep(0);
      }
    },
    [isOpen]
  );

  return (
    <Dialog className="studio-wizard" open={isOpen} onClose={close}>
      <IconButton
        data-testid="studio-wizard-close"
        className="studio-wizard__close"
        aria-label="close"
        onClick={close}
      >
        <CloseIcon />
      </IconButton>
      <Step />
    </Dialog>
  );

  function Step1() {
    return (
      <div className="studio-wizard__step">
        <div className="studio-wizard__image">
          <Step1Img />
        </div>
        <div className="studio-wizard__title">
          <FormattedMessage id="wizard.step1Title" />
        </div>
        <div className="studio-wizard__text">
          <FormattedMessage id="wizard.step1Content" />
        </div>
        <IconButton onClick={next}>
          <Step1Button />
        </IconButton>
        <div className="studio-wizard__numbering">1/4</div>
      </div>
    );
  }

  function Step2() {
    return (
      <div className="studio-wizard__step">
        <div className="studio-wizard__image">
          <Step2Img />
        </div>
        <div className="studio-wizard__title">
          <FormattedMessage id="wizard.step2Title" />
        </div>
        <div className="studio-wizard__text">
          <FormattedMessage id="wizard.step2Content" />
        </div>
        <div className="studio-wizard__navigation">
          <IconButton onClick={previous}>
            <BackButton />
          </IconButton>
          <IconButton onClick={next}>
            <NextButton />
          </IconButton>
        </div>
        <div className="studio-wizard__numbering">2/4</div>
      </div>
    );
  }

  function Step3() {
    return (
      <div className="studio-wizard__step">
        <div className="studio-wizard__image">
          <Step3Img />
        </div>
        <div className="studio-wizard__title">
          <FormattedMessage id="wizard.step3Title" />
        </div>
        <div className="studio-wizard__text">
          <FormattedMessage
            id="wizard.step3Content"
            values={{
              link: function renderLink(chunks: ReactNodeArray) {
                return (
                  <a
                    href={intl.formatMessage({id: 'wizard.step3Link'})}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {chunks}
                  </a>
                );
              },
            }}
          />
        </div>
        <div className="studio-wizard__navigation">
          <IconButton onClick={previous}>
            <BackButton />
          </IconButton>
          <IconButton onClick={next}>
            <NextButton />
          </IconButton>
        </div>
        <div className="studio-wizard__numbering">3/4</div>
      </div>
    );
  }

  function Step4() {
    return (
      <div className="studio-wizard__step">
        <div className="studio-wizard__image">
          <Step4Img />
        </div>
        <div className="studio-wizard__title">
          <FormattedMessage id="wizard.step4Title" />
        </div>
        <div className="studio-wizard__text">
          <FormattedMessage
            id="wizard.step4Content"
            values={{
              link: function renderLink(chunks: ReactNodeArray) {
                return (
                  <a
                    href={intl.formatMessage({id: 'wizard.step4Link'})}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {chunks}
                  </a>
                );
              },
            }}
          />
        </div>
        <div className="studio-wizard__navigation">
          <IconButton onClick={previous}>
            <BackButton />
          </IconButton>
          <IconButton onClick={next}>
            <NextButton />
          </IconButton>
        </div>
        <div className="studio-wizard__numbering">4/4</div>
      </div>
    );
  }

  function Step5() {
    return (
      <div className="studio-wizard__step">
        <div className="studio-wizard__image">
          <Step5Img />
        </div>
        <div className="studio-wizard__title">
          <FormattedMessage id="wizard.step5Title" />
        </div>
        <div className="studio-wizard__text">
          <FormattedMessage
            id="wizard.step5Content"
            values={{
              link: function renderLink(chunks: ReactNodeArray) {
                return (
                  <a
                    href={intl.formatMessage({id: 'wizard.step5Link'})}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {chunks}
                  </a>
                );
              },
            }}
          />
          <a
            className="studio-wizard__external-link"
            href={intl.formatMessage({id: 'wizard.step4Link'})}
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="wizard.step4LinkText" />
            &nbsp;
            <OpenInNew />
          </a>
          <a
            className="studio-wizard__external-link"
            href={intl.formatMessage({id: 'wizard.step3Link'})}
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="wizard.step3LinkText" />
            &nbsp;
            <OpenInNew />
          </a>
        </div>
        <FormButton buttonRole="primary" value="Got it!" onClick={close} />
      </div>
    );
  }
}
