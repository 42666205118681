import React from 'react';
import {ChatMessage} from '../ChatMessage';
import {useIntl} from 'react-intl';
import {DynamicForm} from '../../DynamicForm';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {ClickableListForm} from '../ClickableListForm';
import {useConversation} from '../useConversation';
import './Conversation.scss';

const MESSAGE_DELAY = 300;

export const Conversation = ({theme, onSubmit, onFeedback}) => {
  const intl = useIntl();
  const [
    {status: {conversationId, messages, prompts, defaults} = {}, history},
  ] = useConversation();

  const convertToMap = (arr = []) =>
    arr.reduce((acc, curr) => {
      acc[curr.key] = curr;
      return acc;
    }, {});

  const isClickableList = prompts =>
    prompts.length === 1 &&
    prompts[0].type === 'MULTIPLE_CHOICES' &&
    ['clickable', 'hybrid'].includes(prompts[0].control);

  const getMessageBody = (text, type) =>
    type === 'HTML_MESSAGE' ? <div dangerouslySetInnerHTML={{__html: text}} /> : text;

  return (
    <TransitionGroup>
      {history?.map(({text, type, source, id, isHelpful}, index) => (
        <CSSTransition
          key={`${conversationId}_history_${index}`}
          exit={false}
          timeout={MESSAGE_DELAY}
          classNames="conversation__message"
        >
          <ChatMessage
            source={source}
            theme={theme}
            showFeedback={Boolean(source === 'ca' && id)}
            isHelpful={isHelpful}
            onFeedback={isHelpful =>
              onFeedback({type: 'SET_FEEDBACK_IN_HISTORY', id, isHelpful, index})
            }
          >
            {getMessageBody(text, type)}
          </ChatMessage>
        </CSSTransition>
      ))}
      {messages?.map(({id, text, type, isHelpful}, index) => (
        <CSSTransition
          key={`${conversationId}_${id}`}
          exit={false}
          timeout={MESSAGE_DELAY * (1 + index)}
          classNames="conversation__message"
        >
          <ChatMessage
            source="ca"
            theme={theme}
            showFeedback={Boolean(id)}
            isHelpful={isHelpful}
            onFeedback={isHelpful =>
              onFeedback({type: 'SET_FEEDBACK_IN_MESSAGES', id, isHelpful, index})
            }
            style={{
              transitionDelay: `${MESSAGE_DELAY * (1 + index)}ms`,
            }}
          >
            {getMessageBody(text, type)}
          </ChatMessage>
        </CSSTransition>
      ))}
      {prompts?.length > 0 && (
        <CSSTransition
          key={`${conversationId}_prompt`}
          exit={false}
          timeout={MESSAGE_DELAY * (1 + messages.length)}
          classNames="conversation__message"
        >
          <ChatMessage
            source="user"
            theme={theme}
            style={{
              transitionDelay: `${MESSAGE_DELAY * (1 + messages.length)}ms`,
            }}
          >
            {// the 'clickable' control does not really fit the DynamicForm
            // paradigm. Render it as a separate control
            isClickableList(prompts) ? (
              <ClickableListForm prompt={prompts[0]} onSubmit={onSubmit} />
            ) : (
              <DynamicForm
                onSubmit={onSubmit}
                prompts={prompts}
                // CA sends defaults as an array, but DynamicForm expects map
                defaults={convertToMap(defaults)}
                renderButtons={(isFormSubmitting, isValid) => (
                  <FormButton
                    type="submit"
                    buttonRole="primary"
                    value={intl.formatMessage({id: 'form.submit'})}
                    disabled={isFormSubmitting || !isValid}
                  />
                )}
              />
            )}
          </ChatMessage>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};
