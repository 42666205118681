import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getFormFieldComponent, getLazyValidation} from '../DetailForm';
import {Formik, Form, FieldArray, Field} from 'formik';
import * as yup from 'yup';
import './ParamForm.scss';

export const ParamForm = ({data, onError, onChange}) => {
  const [modValues, setModValues] = useState(data);
  data.forEach(val => {
    val.param_value =
      val.param_value !== undefined ? val.param_value : val.param_value_default;
  });

  useEffect(() => {
    onChange && onChange(modValues);
  }, [modValues, onChange]);

  return (
    <Formik
      initialValues={{
        params: data,
      }}
      validationSchema={yup.object().shape({
        params: yup.array().of(
          yup.object().shape({
            param_value: getLazyValidation(),
          })
        ),
      })}
    >
      {({values, errors}) => {
        onError(errors);
        setModValues(values.params);
        return (
          <>
            <Form className="param-form">
              <FieldArray name="params">
                <>
                  {values.params.map((p, index) => {
                    return p.invisible ? null : (
                      <div key={p.param_name}>
                        <Field
                          name={`params[${index}].param_value`}
                          component={getFormFieldComponent(p)}
                          data={p}
                        />
                      </div>
                    );
                  })}
                </>
              </FieldArray>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

ParamForm.propTypes = {
  data: PropTypes.any,
};

ParamForm.defaultProps = {
  data: {},
};
