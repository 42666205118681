import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import URL_CONST from '../../config/url';
import {ConfigResponse} from '../../types/operation/Config';

const MAX_UPLOAD_LIMIT = 100000000; //in bytes (100 MB)

export type ConfigState = {
  maxRequestSize: number;
  eventDashboardUrl: string | null;
  gspMonitorUrl: string | null;
  videoServerUrl: string | null;
  turnServerUrl: string | null;
  turnServerUsername: string | null; //temporary
  turnServerPassword: string | null; //temporary
  maxJobRuntimeSeconds: number | null;
};

const initialState: ConfigState = {
  maxRequestSize: MAX_UPLOAD_LIMIT,
  eventDashboardUrl: null,
  gspMonitorUrl: null,
  videoServerUrl: null,
  turnServerUrl: null,
  turnServerUsername: null,
  turnServerPassword: null,
  maxJobRuntimeSeconds: null,
};

export const loadConfig = createAsyncThunk('config/load', async () => {
  const {data} = await axios.get<ConfigResponse>(URL_CONST.CONFIG);
  return data.body;
});

const configSlice = createSlice({
  name: 'operation/config',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadConfig.fulfilled, (state, action) => {
      const configState = action.payload as ConfigState;
      state.maxRequestSize = configState.maxRequestSize;
      state.eventDashboardUrl = configState.eventDashboardUrl;
      state.gspMonitorUrl = configState.gspMonitorUrl;
      state.videoServerUrl = configState.videoServerUrl;
      state.turnServerUrl = configState.turnServerUrl;
      state.turnServerUsername = configState.turnServerUsername;
      state.turnServerPassword = configState.turnServerPassword;
      state.maxJobRuntimeSeconds = configState.maxJobRuntimeSeconds;
    });
  },
});

export const {actions: configActions, reducer: configReducer} = configSlice;
