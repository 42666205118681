import {Button} from '@material-ui/core';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import cn from 'classnames';
import {StudioCheckbox} from '../../../base-components/StudioCheckbox';
import {StudioPopover} from '../../../base-components/StudioPopover';
import {StudioRadioGroup} from '../../../base-components/StudioRadio';
import {StudioSelect} from '../../../base-components/StudioSelect';
import {StudioTags} from '../../../base-components/StudioTags';
import {MarketplaceFilters} from '../../../types/marketplace/MarketplaceFilesRequest';
import {
  IntegrationPurpose,
  IntegrationRole,
} from '../../../types/integrations/IntegrationResponse';
import {FilterDefinitions} from '../filterDefinitions';
import {CategoryFilter} from './CategoryFilter';
import './MarketplaceFilterPanel.scss';

type MarketplaceFilterPanelProps = {
  filterDefinitions: FilterDefinitions;
  purpose: IntegrationPurpose;
  filters: MarketplaceFilters;
  onChange: (newFilters: MarketplaceFilters) => void;
  onReset: () => void;
  className?: string;
  compact?: boolean;
  role?: IntegrationRole;
  disabled?: boolean;
};

const SELECT_FALLBACK_LENGTH = 5;
export const MarketplaceFilterPanel = ({
  filterDefinitions,
  role,
  purpose,
  filters,
  onChange,
  onReset,
  className,
  compact = false,
  disabled,
}: MarketplaceFilterPanelProps) => {
  const intl = useIntl();
  const filterCount = Object.entries(filters).filter(([key, value]) => value != null)
    .length;

  const renderFilters = () =>
    filterDefinitions
      .filter(
        definition =>
          !definition.limitBy.length ||
          definition.limitBy.find(limit => limit === purpose)
      )
      .map(definition => {
        if (definition.type === 'FLAG') {
          const {key, label} = definition;
          return (
            <div key={key} className="marketplace-filter-panel__filter">
              <StudioCheckbox
                name={key}
                label={intl.formatMessage({id: label})}
                checked={filters[key]}
                disabled={disabled}
                onChange={(_event, checked) => {
                  onChange({
                    ...filters,
                    [key]: checked || undefined,
                  });
                }}
              />
            </div>
          );
        } else if (definition.type === 'OPTIONS') {
          const {key, label, options, tooltip} = definition;
          const fieldProps = {
            value: filters[key] || '',
            onChange: (event: React.ChangeEvent<{value: unknown}>) =>
              onChange({
                ...filters,
                [key]: (event.target.value as string) || undefined,
              }),
          };
          return (
            <div key={key} className="marketplace-filter-panel__filter">
              {!compact && options.getLength() <= SELECT_FALLBACK_LENGTH ? (
                <StudioRadioGroup
                  label={intl.formatMessage({id: label})}
                  options={options.getValues()}
                  order={options.getOrder()}
                  disabled={disabled}
                  placeholder={intl.formatMessage({id: 'marketplaceExplorer.all'})}
                  RadioGroupProps={fieldProps}
                  tooltip={tooltip}
                />
              ) : (
                <StudioSelect
                  options={options.getValues()}
                  order={options.getOrder()}
                  placeholder={intl.formatMessage({id: 'marketplaceExplorer.all'})}
                  label={intl.formatMessage({id: label})}
                  fullWidth
                  disabled={disabled}
                  SelectProps={fieldProps}
                  additionalLabel={tooltip && <StudioPopover infoMessage={tooltip} />}
                />
              )}
            </div>
          );
        } else if (definition.type === 'MULTIPLE') {
          const {key, label, options, tooltip} = definition;
          const fieldProps = {
            multiple: true,
            value: filters[key] || [],
            onChange: (event: React.ChangeEvent<{value: unknown}>) =>
              onChange({
                ...filters,
                [key]: (event.target.value as string[]) || undefined,
              }),
          };
          return (
            <div key={key} className="marketplace-filter-panel__filter">
              {!compact && options.getLength() <= SELECT_FALLBACK_LENGTH ? (
                <StudioSelect
                  label={intl.formatMessage({id: label})}
                  options={options.getValues()}
                  order={options.getOrder()}
                  disabled={disabled}
                  placeholder={intl.formatMessage({id: 'marketplaceExplorer.all'})}
                  SelectProps={fieldProps}
                />
              ) : (
                <StudioSelect
                  options={options.getValues()}
                  order={options.getOrder()}
                  disabled={disabled}
                  placeholder={intl.formatMessage({id: 'marketplaceExplorer.all'})}
                  label={intl.formatMessage({id: label})}
                  fullWidth
                  SelectProps={fieldProps}
                  additionalLabel={tooltip && <StudioPopover infoMessage={tooltip} />}
                />
              )}
            </div>
          );
        } else if (definition.type === 'TAGS') {
          const {key, label, tooltip} = definition;
          return (
            <div key={key} className="marketplace-filter-panel__filter">
              <StudioTags
                name={key}
                label={intl.formatMessage({id: label})}
                onChange={(event: React.ChangeEvent<{}>, value) =>
                  onChange({
                    ...filters,
                    [key]: value.length ? value : undefined,
                  })
                }
                disabled={disabled}
                options={filters[key] || []}
                value={filters[key] || []}
                tooltip={tooltip}
                tooltipPlacement="top"
                placeholder={
                  !filters[key]?.length
                    ? intl.formatMessage({id: 'marketplaceExplorer.tagFilterPlaceholder'})
                    : undefined
                }
              />
            </div>
          );
        } else if (definition.type === 'CUSTOM') {
          const {key, label} = definition;
          if (key === 'categoryName' && role) {
            return (
              <div key={key} className="marketplace-filter-panel__filter">
                <CategoryFilter
                  value={filters[key] || ''}
                  label={intl.formatMessage({id: label})}
                  disabled={disabled}
                  placeholder={intl.formatMessage({id: 'marketplaceExplorer.all'})}
                  role={role}
                  onChange={value =>
                    onChange({
                      ...filters,
                      [key]: value || undefined,
                    })
                  }
                />
              </div>
            );
          }
        }
        return null;
      });

  return (
    <div
      className={cn('marketplace-filter-panel', className)}
      data-testid="marketplace-filter-panel"
      id="marketplace-filter-panel"
    >
      <div className="marketplace-filter-panel__filter-header">
        <div className="marketplace-filter-panel__title">
          <FormattedMessage id="marketplaceExplorer.filters" tagName="h5" />
          {filterCount > 0 && (
            <span className="marketplace-filter-panel__filter-count">{filterCount}</span>
          )}
        </div>
        <Button color="primary" onClick={() => onReset()}>
          <FormattedMessage id="marketplaceExplorer.reset" />
        </Button>
      </div>
      {renderFilters()}
    </div>
  );
};
