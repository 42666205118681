import React from 'react';
import {Box, Button} from '@material-ui/core';
import {NavigateBeforeRounded} from '@material-ui/icons';
import {FormattedMessage} from 'react-intl';
import './DataPrepare.scss';

type NavigationType = {
  onForward: () => void;
  onBack?: () => void;
  hideBack?: Boolean;
  disableForward?: Boolean;
  disableBack?: Boolean;
};

export const Navigation = ({
  onForward,
  onBack,
  hideBack,
  disableForward,
  disableBack,
}: NavigationType) => {
  return (
    <nav className="data-prepare__navigation">
      <Box flexGrow={1}></Box>
      <div>
        {!hideBack && (
          <Button
            className="data-prepare__navigation-btn"
            onClick={onBack}
            startIcon={<NavigateBeforeRounded />}
            disabled={Boolean(disableBack)}
          >
            <FormattedMessage id="dataPrep.back" />
          </Button>
        )}
      </div>
      <div>
        <Button
          className="data-prepare__navigation-btn"
          color="primary"
          disabled={Boolean(disableForward)}
          onClick={onForward}
          variant="contained"
          disableElevation
        >
          <FormattedMessage id="dataPrep.next" />
        </Button>
      </div>
    </nav>
  );
};
