import {isNil, mapValues, omitBy} from 'lodash';
import React, {useContext, useEffect, useMemo} from 'react';
import {getFormControlConfig} from '../DynamicForm.helpers';
import {
  FieldWatcherAPIContext,
  FieldWatcherProvider,
  FieldWatcherStateContext,
} from './FieldWatcher';

const removeDots = str => str.replace(/\./g, '');

const Adapter = ({prompts, defaults, onSubmit, children}) => {
  const registeredFields = useContext(FieldWatcherStateContext);
  const [preparedPrompts, namesMap] = useMemo(() => {
    // The names given to Formik cannot contain dots (.), since they have special
    // meaning. This function recursively converts keys with dots into valid
    // Formik names. Additionally, it builds a reverse lookup map to map
    // the Formik names back into the original prompts
    const getPreparedPrompts = (prompts, defaults) => {
      const preparedPrompts = [];
      const namesMap = {};
      prompts.forEach(prompt => {
        const name = removeDots(prompt.key);
        const defaultValue =
          prompt?.key && defaults
            ? defaults[prompt.key]?.value || defaults[prompt.key]?.values || null
            : null;
        const preparedPrompt = {...prompt, name, defaultValue};

        if (prompt.tooltips && prompt.options) {
          const preparedTooltips = {};
          Object.entries(prompt.options).forEach(([optionKey]) => {
            const tooltipKey = `${prompt.key}.${optionKey}`;
            preparedTooltips[optionKey] = prompt.tooltips[tooltipKey];
          });
          preparedPrompt.tooltips = preparedTooltips;
        }

        if (prompt.subQuestions) {
          Object.entries(prompt.subQuestions).forEach(([path, pathPrompts]) => {
            preparedPrompt.subQuestions = {...preparedPrompt.subQuestions};
            const [preparedPathPrompts, pathNamesMap] = getPreparedPrompts(pathPrompts);
            Object.assign(namesMap, pathNamesMap);
            preparedPrompt.subQuestions[path] = preparedPathPrompts;
          });
        }

        namesMap[name] = preparedPrompt;
        preparedPrompts.push(preparedPrompt);
      });
      return [preparedPrompts, namesMap];
    };

    return getPreparedPrompts(prompts, defaults);
  }, [prompts, defaults]);

  const hiddenDefaults = defaults
    ? Object.values(defaults).filter(def => !namesMap[removeDots(def.key)])
    : [];

  const initialValues = mapValues(namesMap, ({key, type}) => {
    if (defaults?.[key]?.value || defaults?.[key]?.values?.length > 0) {
      return defaults[key].value || defaults[key].values;
    }
    return getFormControlConfig(type, 0).initialValue;
  });

  const initialErrors = omitBy(
    mapValues(namesMap, ({key}) => defaults?.[key]?.errorMessage),
    isNil
  );

  const preparedOnSubmit = values => {
    const answers = [...hiddenDefaults];
    Object.entries(values).forEach(([name, value]) => {
      // Formik, by default, does not remove fields that are unmounted from 'values'.
      // Hence, filter by the currently displayed fields (registeredFields)
      if (value != null && value !== '' && registeredFields[name]) {
        const {key, type, text: question, options} = namesMap[name];
        const multipleValuesExist = Array.isArray(value);
        const optionsExist = options && Object.keys(options).length > 0;
        answers.push({
          key,
          type,
          value: multipleValuesExist ? null : value,
          values: multipleValuesExist ? value : null,
          valueText: multipleValuesExist
            ? value.join(',')
            : optionsExist
            ? options[value]
            : value,
          question,
        });
      }
    });

    // return the value of "onSubmit" in case it returns a promise
    return onSubmit(answers);
  };

  return children(preparedPrompts, initialValues, initialErrors, preparedOnSubmit);
};

/** Adapter component to help convert DynamicForm props into Formik Props.
 */
export const DynamicFormToFormikAdapter = props => (
  <FieldWatcherProvider>
    <Adapter {...props} />
  </FieldWatcherProvider>
);

/**
 * React hook that notifies the DynamicFormToFormikAdapter whenver
 * a field mounts or unmounts. This helps the adapter keep track of
 * currently displayed fields.
 * */

export const useDynamicFormField = name => {
  const {register, unregister} = useContext(FieldWatcherAPIContext);
  useEffect(() => {
    // register on mount
    register(name);

    // unregister if field unmounts or changes its name
    return () => {
      unregister(name);
    };
  }, [name, register, unregister]);
};
