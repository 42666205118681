import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, IconButton, makeStyles} from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import GroupIcon from '@material-ui/icons/Group';
import ListIcon from '@material-ui/icons/List';
import AppsIcon from '@material-ui/icons/Apps';
import {Pagination, Skeleton, ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import {debounce} from 'lodash';
import {useDispatch} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {MarketplaceGridItem} from './MarketplaceGridItem';
import {StudioTab, StudioTabs} from '../../../base-components/StudioTab';
import {
  IntegrationPurpose,
  IntegrationRole,
} from '../../../types/integrations/IntegrationResponse';
import {filterDefinitions as allFilters} from '../filterDefinitions';
import {StudioSearch} from '../../../base-components/StudioSearch';
import {MarketplaceList} from './MarketplaceList';
import {MarketplaceDetailsDialog} from './MarketplaceDetailsDialog';
import {SortableColumn} from '../../../types/marketplace/MarketplaceFilesRequest';
import {viewSetProjectForm} from '../../../store/view';
import {MarketplaceResult} from '../../../types/marketplace/MarketplaceFilesResponse';
import {useMarketplace, actions} from './useMarketplace';
import {MarketplaceFilterPanel} from '../MarketplaceFilterPanel/MarketplaceFilterPanel';
import {useHistory, useLocation} from 'react-router';
import {useResponsivePageSize} from './useExplorerPageSize';
import {useCompatibleDatasetsMap} from './useCompatibleDatasetsMap';
import {useStudioMode} from '../../StudioMode/StudioModeProvider';
import {LITE_TABS} from '../../StudioLite/LiteMarketplaceTabs';
import {LiteDatasetInfo} from '../../StudioLite/LiteDatasetInfo';
import {LiteISPInfo} from '../../StudioLite/LiteISPInfo';
import {LiteModelInfo} from '../../StudioLite/LiteModelInfo';
import {LiteSolutionInfo} from '../../StudioLite/LiteSolutionInfo';
import './MarketplaceExplorer.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import {toast} from '../../../base-components/StudioToast';
import axios from 'axios';
import URL from '../../../config/url';
import {LitePPInfo} from '../../StudioLite/LitePPInfo';

const {
  setDisplayName,
  setSort,
  setSortDir,
  setSelected,
  setFilters,
  resetSort,
  resetSelectedIds,
} = actions;

const FILTERS_TO_EXCLUDE: Array<string> = ['compatibleOnly'];
const filterDefinitions = allFilters.filter(
  filter => FILTERS_TO_EXCLUDE.findIndex(excluded => excluded === filter.key) === -1
);

const ROLE_TABS: Array<{name: string; role: IntegrationRole; icon: JSX.Element}> = [
  {name: 'marketplaceExplorer.public', role: 'PUBLIC', icon: <PublicIcon />},
  {name: 'marketplaceExplorer.org', role: 'ORGANIZATION', icon: <GroupIcon />},
];
const DEFAULT_PURPOSE_TABS: Array<{name: string; purpose: IntegrationPurpose}> = [
  {name: 'marketplaceExplorer.dataset', purpose: 'DATASET_STORAGE'},
  {name: 'marketplaceExplorer.model', purpose: 'MODEL_STORAGE'},
  {name: 'marketplaceExplorer.isp', purpose: 'ISP_STORAGE'},
  {name: 'marketplaceExplorer.pp', purpose: 'PP_STORAGE'},
  {name: 'marketplaceExplorer.solution', purpose: 'SOLUTION_STORAGE'},
];

const COLUMNS_TO_SHOW: Record<IntegrationPurpose, SortableColumn[]> = {
  DATASET_STORAGE: [
    'displayName',
    'provider',
    'creationDateTime',
    'colorSpace',
    'dataLoader',
    'purpose',
    'size',
    'studioVersion',
  ],
  MODEL_STORAGE: [
    'displayName',
    'provider',
    'creationDateTime',
    'purpose',
    'type',
    'baseModel',
    'yoloType',
    'size',
    'studioVersion',
  ],
  ISP_STORAGE: ['displayName', 'provider', 'creationDateTime', 'size', 'studioVersion'],
  PP_STORAGE: ['displayName', 'provider', 'creationDateTime', 'size', 'studioVersion'],
  SOLUTION_STORAGE: [
    'displayName',
    'categoryName',
    'provider',
    'creationDateTime',
    'size',
    'studioVersion',
  ],
};

export const GRID_ITEM_DIMENSIONS = {
  width: 280,
  height: 312,
  marginX: 20,
  marginY: 32,
};

export const LIST_ITEM_DIMENSIONS = {
  // approximation of the table item height
  height: 60,
};

const useStyles = makeStyles({
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: GRID_ITEM_DIMENSIONS.marginX,
      marginBottom: GRID_ITEM_DIMENSIONS.marginY,
    },
  },
  gridItem: {
    height: `${GRID_ITEM_DIMENSIONS.height}px`,
    width: `${GRID_ITEM_DIMENSIONS.width}px`,
  },
});

export const MarketplaceExplorer = () => {
  const intl = useIntl();
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const [mode, setMode] = useState<'grid' | 'list'>('grid');
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [detailsDialogItem, setDetailsDialogItem] = useState<MarketplaceResult | null>(
    null
  );
  const [role, setRole] = useState<IntegrationRole>('PUBLIC');
  const [purpose, setPurpose] = useState<IntegrationPurpose>('DATASET_STORAGE');
  const gridAreaRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const {mode: studioMode} = useStudioMode();
  const isLiteMode = studioMode === 'LITE';
  const showInfoOnly =
    isLiteMode && !(purpose === 'SOLUTION_STORAGE' && role === 'PUBLIC');
  const PURPOSE_TABS = useMemo(() => (isLiteMode ? LITE_TABS : DEFAULT_PURPOSE_TABS), [
    isLiteMode,
  ]);

  const pageSize = useResponsivePageSize({
    containerRef: gridAreaRef,
    mode,
  });

  const isEnabled = pageSize != null && !showInfoOnly;

  const {
    state: {isLoading, selectedIds, sort, sortDir, filters, results, count, page},
    integration,
    updateResults,
    dispatch,
  } = useMarketplace({
    role,
    purpose,
    pageSize: pageSize || 0,
    isEnabled,
  });

  const query = new URLSearchParams(location.search);
  const purposeQuery = query.get('purpose');
  const roleQuery = query.get('role');
  const filtersQuery = query.get('filters');
  const displayNameQuery = query.get('displayName');

  const actionButtonId =
    purpose === 'SOLUTION_STORAGE'
      ? isLiteMode
        ? 'marketplaceExplorer.testSolution'
        : 'marketplaceExplorer.useSolution'
      : 'marketplaceExplorer.useProject';

  const selectedItem = Object.values(selectedIds).filter(value => value)[0];

  useEffect(() => {
    try {
      const tabPurposes = Object.values(PURPOSE_TABS).map(value => value.purpose);
      const tabRoles = Object.values(ROLE_TABS).map(value => value.role);
      tabPurposes.includes(purposeQuery as IntegrationPurpose) &&
        setPurpose(purposeQuery as IntegrationPurpose);
      tabRoles.includes(roleQuery as IntegrationRole) &&
        setRole(roleQuery as IntegrationRole);
      if (isEnabled) {
        filtersQuery != null && dispatch(setFilters(JSON.parse(filtersQuery)));
        if (displayNameQuery != null) {
          dispatch(setDisplayName(decodeURIComponent(displayNameQuery)));
          setSearch(decodeURIComponent(displayNameQuery));
        }
      }
    } catch (e) {
      console.debug(e, 'Error when parsing marketplace url parameters');
    }
  }, [
    purposeQuery,
    roleQuery,
    filtersQuery,
    displayNameQuery,
    PURPOSE_TABS,
    isEnabled,
    dispatch,
  ]);

  // Grid view does not have sorting (yet), so reset sort when we change modes
  useEffect(() => {
    dispatch(resetSort());
  }, [mode, dispatch]);

  const compatibleDatasetSignatures = (purpose === 'MODEL_STORAGE' ||
  results === undefined
    ? []
    : results
  )
    .flatMap(result => result.metadata.compatibleDatasets)
    .filter((id: string | undefined): id is string => id != null);

  const compatibleDatasetsMap = useCompatibleDatasetsMap(compatibleDatasetSignatures);

  const updateURLSearchParams = useCallback(
    (newParams: Record<string, string | object>) => {
      const existingSearchParams = new URLSearchParams(location.search);
      Object.entries(newParams).forEach(([key, value]) => {
        const uriSafeString = typeof value === 'object' ? JSON.stringify(value) : value;
        existingSearchParams.set(key, uriSafeString);
      });
      history.push({search: existingSearchParams.toString()});
    },
    [history, location.search]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateDisplayName = useCallback(
    debounce((displayName: string) => {
      updateURLSearchParams({displayName});
    }, 500),
    [updateURLSearchParams]
  );

  const handleDetailsClick = (results: Array<MarketplaceResult>, index: number) => {
    setDetailsDialogItem(results[index]);
    setIsDetailsDialogOpen(true);
  };

  const handleDetailsClose = () => {
    setDetailsDialogItem(null);
    setIsDetailsDialogOpen(false);
  };

  function updateResultsAndResetSelection(page: number) {
    if (integration) {
      updateResults(integration, page);
    }
    dispatch(resetSelectedIds());
  }

  const deleteMarketplaceItem = (item: MarketplaceResult) => {
    var request = {
      name: item.name,
      displayName: item.displayName,
      integrationId: item.integrationId,
    };
    axios
      .post(URL.MARKETPLACE_ITEM_DELETE, request)
      .then(res => {
        if (res.data.errors) {
          toast.error(res.data.errors);
        } else {
          updateResultsAndResetSelection(page);
        }
      })
      .catch(error => toast.error(error));
  };

  const handleDeleteButtonClick = (item: MarketplaceResult) => {
    const onCloseNo = () => {
      return null;
    };
    const onCloseYes = () => {
      deleteMarketplaceItem(item);
    };
    toast.warn({
      title: 'Delete',
      subtitle: 'Are you sure?',
      position: 'bottom',
      actions: [
        {label: 'Yes', callback: onCloseYes},
        {label: 'No', callback: onCloseNo},
      ],
      duration: null,
    });
  };

  const handleUseButtonClick = (item: MarketplaceResult) => {
    let form;
    if (item) {
      if (purpose === 'DATASET_STORAGE') {
        form = {dataset: item};
      } else if (purpose === 'MODEL_STORAGE') {
        form = {model: item};
      } else if (purpose === 'ISP_STORAGE') {
        form = {isp: item};
      } else if (purpose === 'PP_STORAGE') {
        form = {pp: item};
      } else if (purpose === 'SOLUTION_STORAGE') {
        form = {solution: item};
      }
      reduxDispatch(
        viewSetProjectForm({
          newFormOpen: true,
          form,
        })
      );
    }
  };

  const isUseProjectDisabled = (result: MarketplaceResult) =>
    purpose === 'PP_STORAGE' ||
    (purpose === 'ISP_STORAGE' &&
      result.metadata.transformation?.transformationType !== 'ISP');

  const getResultKey = (result: MarketplaceResult) =>
    `${result.name}_${result.integrationId}`;

  return (
    <div className="marketplace-explorer" data-testid="marketplace-explorer">
      <MarketplaceDetailsDialog
        isOpen={isDetailsDialogOpen}
        submitLabel={intl.formatMessage({id: actionButtonId})}
        onClose={handleDetailsClose}
        onSubmit={handleUseButtonClick}
        item={detailsDialogItem}
        purpose={purpose}
      />
      <div
        className="marketplace-explorer__filters"
        data-testid="marketplace-explorer-filter-panel"
      >
        {purpose && role && (
          <MarketplaceFilterPanel
            role={role}
            filterDefinitions={filterDefinitions}
            purpose={purpose}
            filters={filters}
            disabled={isLiteMode && purpose !== 'SOLUTION_STORAGE'}
            onChange={newFilters => updateURLSearchParams({filters: newFilters})}
            onReset={() => updateURLSearchParams({filters: {}})}
          />
        )}
      </div>
      <div className="marketplace-explorer__main">
        <div className="marketplace-explorer__main-bar">
          <StudioTabs
            value={ROLE_TABS.findIndex(tab => tab.role === role)}
            onChange={(event, index) => {
              const role = ROLE_TABS[index].role;
              updateURLSearchParams({role});
              dispatch(resetSelectedIds());
            }}
            variant="scrollable"
            level="primary"
          >
            {ROLE_TABS.map(({name, icon}, index) => (
              <StudioTab
                key={index}
                icon={icon}
                label={intl.formatMessage({id: name})}
                className="marketplace-explorer__role-tab"
                data-testid="marketplace-role-tab"
              />
            ))}
          </StudioTabs>
          <div className="marketplace-explorer__main-bar-right">
            <StudioSearch
              placeholder={intl.formatMessage({id: 'marketplaceExplorer.search'})}
              onChange={e => {
                /* We want to debounce the API fetch as the user types a search query, without
                 * interrupting the user while typing. Hence, we separate the filter used for the
                 * API call (displayName) with the value used for the search box (search).
                 */
                debouncedUpdateDisplayName(e.target.value);
                setSearch(e.target.value);
              }}
              value={search}
              data-testid="marketplace-explorer-search"
            />
            <ToggleButtonGroup
              value={mode}
              exclusive
              onChange={(event, value) => value && setMode(value)}
              aria-label="text alignment"
            >
              <ToggleButton
                value="grid"
                aria-label="grid"
                data-testid="marketplace-explorer-grid-mode-btn"
              >
                <AppsIcon />
              </ToggleButton>
              <ToggleButton
                value="list"
                aria-label="list"
                data-testid="marketplace-explorer-list-mode-btn"
              >
                <ListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <StudioTabs
          value={PURPOSE_TABS.findIndex(tab => tab.purpose === purpose)}
          onChange={(event, index) => {
            const purpose = PURPOSE_TABS[index].purpose;
            // Filters and columns can be specific to an integration purpose, so we reset filters and column
            // sorting before fetching each time we switch the purpose.
            updateURLSearchParams({purpose, filters: {}});
            dispatch(resetSort());
            dispatch(resetSelectedIds());
          }}
          variant="scrollable"
          className="marketplace-explorer__purpose-tabs"
        >
          {PURPOSE_TABS.map(({name}, index) => (
            <StudioTab
              key={index}
              label={intl.formatMessage({id: name})}
              className="marketplace-explorer__purpose-tab"
              data-testid="marketplace-purpose-tab"
            />
          ))}
        </StudioTabs>
        {showInfoOnly ? (
          <>
            {purpose === 'DATASET_STORAGE' && <LiteDatasetInfo />}
            {purpose === 'MODEL_STORAGE' && <LiteModelInfo />}
            {purpose === 'ISP_STORAGE' && <LiteISPInfo />}
            {purpose === 'SOLUTION_STORAGE' && <LiteSolutionInfo />}
            {purpose === 'PP_STORAGE' && <LitePPInfo />}
          </>
        ) : (
          <>
            {results && results.length > 0 && (
              <div className="marketplace-explorer__bar">
                {pageSize != null && (
                  <FormattedMessage
                    id="marketplaceExplorer.pageInfo"
                    values={{
                      start: page * pageSize + 1,
                      end: page * pageSize + results.length,
                      total: count,
                    }}
                  />
                )}
                {role !== 'PUBLIC' && (
                  <IconButton
                    size="medium"
                    color="primary"
                    aria-label="delete"
                    disabled={!selectedItem}
                    className="marketplace-explorer__delete"
                    onClick={() => selectedItem && handleDeleteButtonClick(selectedItem)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  className="marketplace-explorer__action"
                  disabled={!selectedItem || isUseProjectDisabled(selectedItem)}
                  onClick={() => selectedItem && handleUseButtonClick(selectedItem)}
                >
                  <FormattedMessage id={actionButtonId} />
                </Button>
              </div>
            )}
            {purpose && (
              <div className="marketplace-explorer__results" ref={gridAreaRef}>
                {mode === 'grid' && results && results.length > 0 && (
                  <div className={classes.grid}>
                    {isLoading
                      ? new Array(pageSize)
                          .fill(0)
                          .map((_, index) => (
                            <Skeleton
                              className={classes.gridItem}
                              key={index}
                              variant="rect"
                            />
                          ))
                      : results.map((result, index) => (
                          <MarketplaceGridItem
                            className={classes.gridItem}
                            key={getResultKey(result)}
                            isSelected={Boolean(selectedIds[getResultKey(result)])}
                            item={result}
                            compatibleDatasetsMap={compatibleDatasetsMap}
                            purpose={purpose}
                            onSelect={() =>
                              dispatch(setSelected({[getResultKey(result)]: result}))
                            }
                            onDetailsClick={() => handleDetailsClick(results, index)}
                          />
                        ))}
                  </div>
                )}
                {mode === 'list' && results && results.length > 0 && (
                  <MarketplaceList
                    getKey={getResultKey}
                    results={results}
                    sort={sort}
                    sortDir={sortDir}
                    selectedIds={selectedIds}
                    columns={COLUMNS_TO_SHOW[purpose]}
                    onSelectedChange={result =>
                      dispatch(setSelected({[getResultKey(result)]: result}))
                    }
                    onSort={(column, direction) => {
                      dispatch(setSort(column));
                      dispatch(setSortDir(direction));
                    }}
                    onViewMore={index => handleDetailsClick(results, index)}
                  />
                )}
                {results === undefined && (
                  <div className="marketplace-explorer__not-configured">
                    <FormattedMessage id="marketplaceExplorer.not.configured" />
                  </div>
                )}
                {results && results.length === 0 && (
                  <div className="marketplace-explorer__empty">
                    <FormattedMessage id="marketplaceExplorer.empty" />
                  </div>
                )}
              </div>
            )}
            {pageSize != null && count > pageSize && (
              <Pagination
                count={Math.ceil(count / pageSize)}
                page={page + 1}
                shape="rounded"
                className="marketplace-explorer__pagination"
                data-testid="marketplace-explorer-pagination"
                onChange={(_, p) => updateResultsAndResetSelection(p - 1)}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
