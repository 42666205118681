import useSWR from 'swr';
import axios from 'axios';
import URL from '../../../config/url';
import {RootState} from '../../../store';
import {useSelector} from 'react-redux';
import {
  MarketplaceFilesResponse,
  MarketplaceResult,
} from '../../../types/marketplace/MarketplaceFilesResponse';

export const useCompatibleDatasetsMap = (signatures: string[]) => {
  const uniqueSignatures = Array.from(new Set(signatures));
  const publicIntegration = useSelector(
    (state: RootState) => state.marketplace.integrations['DATASET_STORAGE']?.['PUBLIC']
  );
  const orgIntegration = useSelector(
    (state: RootState) =>
      state.marketplace.integrations['DATASET_STORAGE']?.['ORGANIZATION']
  );

  const {data: compatibleDatasetsResponse} = useSWR<MarketplaceFilesResponse>(
    uniqueSignatures.length && (publicIntegration || orgIntegration)
      ? uniqueSignatures
      : null,
    async () => {
      const {data} = await axios.post<MarketplaceFilesResponse>(URL.MARKETPLACE_FILES, {
        params: {
          classesSignature: uniqueSignatures,
        },
        integrationIds: [publicIntegration?.id, orgIntegration?.id].filter(
          id => id != null
        ),
      });
      return data;
    }
  );

  const compatibleDatasets = compatibleDatasetsResponse?.body?.entries || [];

  return compatibleDatasets.reduce<Map<string, MarketplaceResult>>((acc, curr) => {
    acc.set(curr.name, curr);
    return acc;
  }, new Map<string, MarketplaceResult>());
};
