import React, {useContext} from 'react';
import {Pagination, PaginationItem} from '@material-ui/lab';
import {PageListContext} from './PageListProvider';

export const PageListNavigation = ({onChange = (page: number) => {}}) => {
  const {page, pageCount, listCount, itemsPerPageCount} = useContext(PageListContext);

  return (
    <>
      <span>
        {(page - 1) * itemsPerPageCount + 1}-
        {Math.min(page * itemsPerPageCount, listCount)} of {listCount}
      </span>
      <Pagination
        className="detection-view__thumbnail_toolbar_nav"
        page={page}
        count={pageCount}
        onChange={(e, val) => onChange(val)}
        renderItem={item => {
          return item.type === 'next' || item.type === 'previous' ? (
            <PaginationItem {...item} />
          ) : (
            <></>
          );
        }}
      />
    </>
  );
};
