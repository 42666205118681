import React from 'react';
import BaseDialog, {DialogProps} from './BaseDialog';
import './ModalWindow.scss';
import './AssistantPanel.scss';

class AssistantPanel extends BaseDialog {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  render() {
    this.toggleButtons(!this.state.isOpen);
    if (!this.state.isOpen) {
      return null;
    }
    return [
      <div
        className="modal-window"
        id={this.props.id}
        key="assistant-panel-wnd"
        data-testid="assistant-panel"
      >
        <div
          key="assistant-panel-top"
          className="assistant-panel top"
          id={this.props.id + '-top'}
        >
          <div className="assistant-bullet" />
        </div>
        <div
          key="assistant-panel-cap"
          className="assistant-panel cap"
          id={this.props.id + '-cap'}
        >
          <div className="assistant-panel-filler" />
          <div className="assistant-panel-header" />
        </div>
        <div
          key="assistant-panel-bottom"
          className="assistant-panel bottom"
          id={this.props.id + '-bottom'}
        >
          <div className="assistant-panel-filler" />
          <div className="assistant-panel-border" />
          <div className="assistant-panel-content">{this.state.form}</div>
        </div>
      </div>,
    ];
  }

  hideElements() {
    this.hideElement('-top');
    this.hideElement('-cap');
    this.hideElement('-bottom');
  }

  hideElement(postfix) {
    let element = document.getElementById(this.props.id + postfix);
    if (element) {
      element.classList.add('hidden');
    }
  }

  closeModal() {
    this.hide();
  }

  toggleButtons(visible) {
    const buttons = document.getElementsByClassName('stage-panel-buttons');
    for (const button of buttons) {
      if (visible) {
        button.style.removeProperty('visibility');
      } else {
        button.style.visibility = 'hidden';
      }
    }
  }
}

AssistantPanel.propTypes = DialogProps;

export default AssistantPanel;
