import React, {ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import './MetadataList.scss';

export const MetadataList = ({children}: {children: ReactNode}) => (
  <dl className="metadata-list">{children}</dl>
);
export const Metadata = ({label, children}: {label: string; children: ReactNode}) =>
  children ? (
    <>
      <dt>
        <FormattedMessage id={label} />
      </dt>
      <dd>{children}</dd>
    </>
  ) : null;
