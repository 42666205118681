import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import {
  Integration,
  IntegrationPurpose,
} from '../../types/integrations/IntegrationResponse';
import {
  IntegrationType,
  IntegrationTypesResponse,
} from '../../types/integrations/IntegrationTypesResponse';
import axios from 'axios';
import URL from '../../config/url';
import {IntegrationNewResponse} from '../../types/integrations/IntegrationNewResponse';
import {Answer} from '../../types/prompts/Answer';
import {IntegrationNewFinalizeResponse} from '../../types/integrations/IntegrationNewFinalizeResponse';
import {IntegrationEditResponse} from '../../types/integrations/IntegrationEditResponse';
import {IntegrationListResponse} from '../../types/integrations/IntegrationListResponse';
import {IntegrationEditFinalizeResponse} from '../../types/integrations/IntegrationEditFinalizeResponse';
import {MarketplacePublishRequest} from '../../types/marketplace/MarketplacePublishRequest';
import {MarketplacePublishResponse} from '../../types/marketplace/MarketplacePublishResponse';
import {Defaults} from '../../types/prompts/Defaults';
import {fetchMarketplaceIntegrations} from './integrations';
import {PROJECT_SETTINGS} from '../../config/constants';

type EmptyResponse = {body: {}};

type ConfigureState = {
  isChooseModalOpen: boolean;
  isCreateModalOpen: boolean;
  isEditModalOpen: boolean;

  createModalPurpose: IntegrationPurpose;

  share: {
    isOpen: boolean;
    isCancelled: boolean;
    defaults?: Defaults;
    prompts: any;
    resourceId?: string;
    purpose: IntegrationPurpose;
  };

  integrationTypes: Array<IntegrationType>;

  isNewPromptsPending: boolean;
  newPromptsData: {prompts: any; defaults: Defaults; type: IntegrationType} | null;

  isNewFinalizePending: boolean;
  newFinalize: Integration | null;
  newFinalizeError: SerializedError | null;

  list: Array<Integration> | null;
  isListPending: boolean;

  isEditPromptsPending: boolean;
  editPromptsData: {prompts: any; defaults: Defaults; id: string; name: string} | null;

  isEditFinalizePending: boolean;
  editFinalize: Integration | null;
  editFinalizeError: SerializedError | null;

  isDeletePending: boolean;
  deleteResponse: EmptyResponse | null;
};

export const fetchIntegrationTypes = createAsyncThunk(
  'marketplace-configure/integration-types',
  async () => {
    const {data} = await axios.get<IntegrationTypesResponse>(URL.INTEGRATION_TYPES);
    return data.body;
  }
);

export const fetchNewIntegrationForm = createAsyncThunk(
  'marketplace-configure/new-integration-form',
  async (args: {type: IntegrationType; purpose: IntegrationPurpose}) => {
    const {type, purpose} = args;
    const {data} = await axios.post<IntegrationNewResponse>(URL.INTEGRATION_NEW, null, {
      params: {
        type: type.key,
        purpose,
      },
    });
    return {prompts: data.prompts, defaults: data.defaults, type};
  }
);

export const integrationNewFinalize = createAsyncThunk(
  'marketplace-configure/new-integration-finalize',
  async (answers: Array<Answer>, {dispatch}) => {
    const {data} = await axios.post<IntegrationNewFinalizeResponse>(
      URL.INTEGRATION_NEW_FINALIZE,
      answers
    );
    if (data.errors?.length) {
      throw new Error('Received integration new error');
    }
    dispatch(fetchIntegrationList());
    dispatch(fetchMarketplaceIntegrations());
    return data.body;
  }
);

export const fetchEditIntegrationForm = createAsyncThunk(
  'marketplace-configure/edit-integration-form',
  async (args: {id: string; name: string}) => {
    const {id, name} = args;
    const {data} = await axios.post<IntegrationEditResponse>(URL.INTEGRATION_EDIT(id));
    return {prompts: data.prompts, defaults: data.defaults, id, name};
  }
);

export const integrationEditFinalize = createAsyncThunk(
  'marketplace-configure/edit-integration-finalize',
  async (args: {id: string; answers: Array<Answer>}, {dispatch}) => {
    const {id, answers} = args;
    const {data} = await axios.post<IntegrationEditFinalizeResponse>(
      URL.INTEGRATION_EDIT_FINALIZE(id),
      answers
    );
    if (data.errors?.length) {
      throw new Error('Received integration edit error');
    }
    dispatch(fetchIntegrationList());
    return data.body;
  }
);

export const integrationDelete = createAsyncThunk(
  'marketplace-configure/delete-integration',
  async (id: string, {dispatch}) => {
    const {data} = await axios.delete<EmptyResponse>(URL.INTEGRATION_DELETE(id));
    dispatch(fetchIntegrationList());
    dispatch(fetchMarketplaceIntegrations());
    return data;
  }
);

export const fetchIntegrationList = createAsyncThunk(
  'marketplace-configure/integration-list',
  async () => {
    const role = 'ORGANIZATION';
    const {data} = await axios.get<IntegrationListResponse>(
      URL.INTEGRATION_FLATTENED_LIST(role)
    );
    return data.body;
  }
);

function decoratePublishData(data: MarketplacePublishResponse) {
  const newData: MarketplacePublishResponse = {...data};

  newData.prompts = newData.prompts.map(prompt => {
    let newPrompt = {...prompt};

    switch (prompt.key) {
      case 'Marketplace.Name':
        newPrompt.inputProps = {
          maxLength: PROJECT_SETTINGS.name.maxLength,
        };
        break;
      case 'Marketplace.Description':
        newPrompt.multiline = PROJECT_SETTINGS.description.multiline;
        newPrompt.inputProps = {
          maxLength: PROJECT_SETTINGS.description.maxLength,
          className: PROJECT_SETTINGS.description.className,
        };
        break;
      default:
        break;
    }

    return newPrompt;
  });

  return newData;
}

export const openPublishForm = createAsyncThunk(
  'marketplace-configure/publish-form',
  async (
    params: MarketplacePublishRequest & {
      purpose: IntegrationPurpose;
    },
    {dispatch}
  ) => {
    let {data} = await axios.get<MarketplacePublishResponse>(URL.MARKETPLACE_PUBLISH, {
      params: {
        projectId: params.projectId,
        resourceId: params.resourceId,
      },
    });

    if (!data.errors) {
      data = decoratePublishData(data);
      dispatch(setIsShareModalOpen({isOpen: true, data, params}));
    }

    return data;
  }
);

const initialState: ConfigureState = {
  isChooseModalOpen: false,
  isCreateModalOpen: false,
  isEditModalOpen: false,

  createModalPurpose: 'DATASET_STORAGE',

  share: {
    isOpen: false,
    isCancelled: false,
    defaults: {},
    prompts: [],
    resourceId: undefined,
    purpose: 'DATASET_STORAGE',
  },

  integrationTypes: [],

  isNewPromptsPending: false,
  newPromptsData: null,

  isNewFinalizePending: false,
  newFinalize: null,
  newFinalizeError: null,

  list: null,
  isListPending: false,

  isEditPromptsPending: false,
  editPromptsData: null,

  isEditFinalizePending: false,
  editFinalize: null,
  editFinalizeError: null,

  isDeletePending: false,
  deleteResponse: null,
};

const configureSlice = createSlice({
  name: 'marketplace-configure',
  initialState,
  reducers: {
    setIsShareModalOpen: (
      state,
      action: PayloadAction<
        | {
            isOpen: true;
            data: MarketplacePublishResponse;
            params: MarketplacePublishRequest & {purpose: IntegrationPurpose};
          }
        | {isOpen: false; isCancelled?: boolean}
      >
    ) => {
      state.share.isOpen = action.payload.isOpen;
      if (action.payload.isOpen) {
        const data = action.payload.data;
        state.share.defaults = data.defaults;
        state.share.prompts = data.prompts;
        state.share.resourceId = action.payload.params.resourceId;
        state.share.purpose = action.payload.params.purpose;
        state.share.isCancelled = false;
      } else {
        state.share.isCancelled = Boolean(action.payload.isCancelled);
      }
    },
    setIsChooseModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isChooseModalOpen = action.payload;
    },
    setIsCreateModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isCreateModalOpen = action.payload;
    },
    setIsEditModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditModalOpen = action.payload;
    },
    setCreateModalPurpose: (state, action: PayloadAction<IntegrationPurpose>) => {
      state.createModalPurpose = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchIntegrationTypes.fulfilled, (state, action) => {
        state.integrationTypes = action.payload;
      })
      .addCase(fetchNewIntegrationForm.pending, state => {
        state.isNewPromptsPending = true;
      })
      .addCase(fetchNewIntegrationForm.rejected, state => {
        state.isNewPromptsPending = false;
        state.newPromptsData = null;
      })
      .addCase(fetchNewIntegrationForm.fulfilled, (state, action) => {
        state.isNewPromptsPending = false;
        state.newPromptsData = action.payload;
      })
      .addCase(fetchIntegrationList.pending, state => {
        state.isListPending = true;
      })
      .addCase(fetchIntegrationList.rejected, state => {
        state.isListPending = false;
        state.list = [];
      })
      .addCase(fetchIntegrationList.fulfilled, (state, action) => {
        state.isListPending = false;
        state.list = action.payload;
      })
      .addCase(integrationNewFinalize.pending, state => {
        state.isNewFinalizePending = true;
      })
      .addCase(integrationNewFinalize.rejected, (state, action) => {
        state.isNewFinalizePending = false;
        state.newFinalizeError = action.error;
      })
      .addCase(integrationNewFinalize.fulfilled, (state, action) => {
        state.isNewFinalizePending = false;
        state.newFinalize = action.payload;
        state.newFinalizeError = null;
      })
      .addCase(fetchEditIntegrationForm.pending, state => {
        state.isEditPromptsPending = true;
      })
      .addCase(fetchEditIntegrationForm.rejected, state => {
        state.isEditPromptsPending = false;
        state.editPromptsData = null;
      })
      .addCase(fetchEditIntegrationForm.fulfilled, (state, action) => {
        state.isEditPromptsPending = false;
        state.editPromptsData = action.payload;
      })
      .addCase(integrationEditFinalize.pending, state => {
        state.isEditFinalizePending = true;
      })
      .addCase(integrationEditFinalize.rejected, (state, action) => {
        state.isEditFinalizePending = false;
        state.editFinalizeError = action.error;
      })
      .addCase(integrationEditFinalize.fulfilled, (state, action) => {
        state.isEditFinalizePending = false;
        state.editFinalize = action.payload;
        state.editFinalizeError = null;
      })
      .addCase(integrationDelete.pending, state => {
        state.isDeletePending = true;
      })
      .addCase(integrationDelete.rejected, state => {
        state.isDeletePending = false;
        state.deleteResponse = null;
      })
      .addCase(integrationDelete.fulfilled, (state, action) => {
        state.isDeletePending = false;
        state.deleteResponse = action.payload;
      });
  },
});

export const {
  reducer: configureMarketplaceReducer,
  actions: {
    setIsShareModalOpen,
    setIsChooseModalOpen,
    setIsEditModalOpen,
    setIsCreateModalOpen,
    setCreateModalPurpose,
  },
} = configureSlice;
