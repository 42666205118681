import Axios from 'axios';

type Request = {
  applicationId: string;
  projectId: string;
};

export const getApplicationONNXFileURL = ({projectId, applicationId}: Request) =>
  `studio/application/${projectId}/${applicationId}/onnx`;

export async function getApplicationONNXFile(url: string) {
  return Axios.request<Blob>({
    method: 'GET',
    url,
    responseType: 'blob',
  });
}
