import React, {ChangeEvent} from 'react';
import Tabs, {TabsProps} from '@material-ui/core/Tabs';
import cn from 'classnames';
import './Tabs.scss';

// Tabs component in MUI has a bug: https://github.com/mui-org/material-ui/issues/17454
type TabsPropsFixed = Omit<TabsProps, 'onChange'> & {
  onChange?: (event: ChangeEvent<{}>, value: any) => void;
};

type StudioTabsProps = TabsPropsFixed & {
  level?: 'primary' | 'secondary' | 'basic';
};

const StudioTabs = ({level, className, ...rest}: StudioTabsProps) => (
  <Tabs
    className={cn(
      'studio-tabs',
      level === 'primary' && 'studio-tabs--primary',
      level === 'secondary' && 'studio-tabs--secondary',
      className
    )}
    {...rest}
  />
);

export default StudioTabs;
