import React from 'react';
import {ReactComponent as LiteEmptyStateIcon} from '../../assets/images/studio-lite-empty.svg';

export const LiteEmptyPage = ({children}: {children: React.ReactNode}) => {
  return (
    <div style={{margin: '96px auto 0'}}>
      <div style={{maxWidth: '720px'}}>
        <>
          <LiteEmptyStateIcon style={{display: 'block', margin: '0 auto'}} />
          {children}
        </>
      </div>
    </div>
  );
};
