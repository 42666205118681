import React from 'react';
import BeforeImage1 from './images/crop-before-1.png';
import BeforeImage2 from './images/crop-before-2.png';
import AfterImage from './images/crop-after.png';
import './Help.scss';

export default () => {
  return (
    <div className="flow-graph__help_content">
      <h4>Crop</h4>
      <div className="flow-graph__help_content_detail">
        <p>Cropping is cutting off a portion of the image to improve framing or change the aspect ratio. This kind of photo manipulation allows you to keep all the elements which are required, focus on a specific section (Region of Interest ROI) and eliminate the ones which are not required. Cropping can also help you to draw the viewer into a certain area for greater impact. When an image is cropped, the number of pixels will be reduced as certain areas of image are being cut off, thus reducing the overall size of the photo. The aspect ratio is the ratio of the sides of image in different dimensions, or the width and height a photo needs to fit a certain space - the ratio of width to height of the output image.</p>
        <div className="help_images">
          <figure>
            <img src={BeforeImage1} alt="before" />
            <figcaption>Original</figcaption>
          </figure>
          <figure>
            <img src={BeforeImage2} alt="before" />
            <figcaption>What is being cropped</figcaption>
          </figure>
          <figure>
            <img src={AfterImage} alt="after" />
            <figcaption>Cropped</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};
