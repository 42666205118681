import {useEffect, useRef, useState} from 'react';
import {ExecutionContainer} from '../../../types/model/framework/ExecutionContainer';
import {PostProcessorType} from '../../../types/postprocessor/PostProcessorType';
import {PostProcessorScript} from '../../../types/scripts/PostProcessorScript';

type usePostProcessingScriptProps = {
  projectId?: string;
  id?: string;
  hasPostProcessors?: boolean;
  fileName?: string;
  postProcessorType?: PostProcessorType;
  executionContainer?: ExecutionContainer;
  viewOnly?: boolean;
  onError?: (message?: string) => void;
};

export const usePostProcessingScript = ({
  projectId,
  id,
  hasPostProcessors,
  fileName,
  postProcessorType,
  executionContainer,
  onError,
  viewOnly = false,
}: usePostProcessingScriptProps) => {
  const [module, setModule] = useState<PostProcessorScript>();
  const onErrorRef = useRef(onError);

  useEffect(() => {
    const fetchPostProcessor = async () => {
      try {
        const module = await import(
          /* webpackIgnore: true */ `/studio/postprocessor/${
            viewOnly ? 'view' : 'edit'
          }/${projectId}/${id}/${fileName}?type=${postProcessorType}&container=${executionContainer}&cachebust=${Date.now()}`
        );
        setModule(module);
      } catch (e) {
        onErrorRef.current?.('Error fetching post processor.');
        console.error(e);
      }
    };
    if (
      projectId &&
      id &&
      hasPostProcessors &&
      fileName &&
      postProcessorType &&
      executionContainer
    ) {
      fetchPostProcessor();
    }
  }, [
    projectId,
    id,
    hasPostProcessors,
    fileName,
    postProcessorType,
    executionContainer,
    viewOnly,
  ]);

  return module;
};
