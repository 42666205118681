const CONFIG = {
  MODEL_IMAGE: 'icons/model-icon.png',
  LONG_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSSSSZ',
};

export const LS_KEY = {
  ISP: 'new_isp',
  DATA_PREPARE: 'data_prep',
  DATA_DETECTION: 'data_detection',
  CONFIG: 'config',
};

const GENERAL_DESCRIPTIONS_SETTINGS = {
  maxLength: 450,
  multiline: true,
  className: 'studio__input-field-description',
};

export const PROJECT_SETTINGS = {
  name: {
    maxLength: 32,
  },
  description: GENERAL_DESCRIPTIONS_SETTINGS,
};

export const ISP_SETTINGS = {
  name: {
    maxLength: 32,
  },
  description: GENERAL_DESCRIPTIONS_SETTINGS,
};

export const PRE_AND_POST_PROCESSORS_SETTINGS = {
  name: {
    maxLength: 32,
  },
  description: GENERAL_DESCRIPTIONS_SETTINGS,
};

export const TEST_PANEL_SETTINGS = {
  name: {
    maxLength: 32,
  },
  description: GENERAL_DESCRIPTIONS_SETTINGS,
};

export const DEPLOY_PANEL_SETTINGS = {
  name: {
    maxLength: 32,
  },
  description: GENERAL_DESCRIPTIONS_SETTINGS,
};

export default CONFIG;
