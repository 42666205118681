import {
  Box,
  Button,
  Grid,
  IconButton,
  Tabs,
  Tab,
  Toolbar,
  Input,
} from '@material-ui/core';
import {Close as CloseIcon} from '@material-ui/icons';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {consoleAdd, consoleClear, getConsoleList} from '../../store/console';
import {getIsConsoleOpen, viewCloseConsole} from '../../store/view';
import {BuildVersion} from '../BuildVersion';
import {useBuild} from '../BuildVersion/useBuild';
import {StudioDrawer} from '../../base-components/StudioDrawer';
import {StudioSelect} from '../../base-components/StudioSelect';
import {FormattedMessage, useIntl} from 'react-intl';
import {StudioTabPanel} from '../../base-components/StudioTab';
import * as queryString from 'query-string';
import URL_SETTINGS from '../../config/url';
import './StudioConsole.scss';
import moment from 'moment';

export const MESSAGE_TYPE = {
  ERROR: 'error',
  WARN: 'warn',
  SUCCESS: 'success',
};

const LOG_DOWNLOAD_OPTIONS = {
  '2h': '2h',
  '3h': '3h',
  CUSTOM: 'custom',
  DEFAULT: 'default',
};

const MessageRenderer = ({data}) => {
  const [showLog, setShowlog] = useState(false);
  return (
    <>
      <Grid item xs={2} md={1}>
        <div className="studio-console__message_time">{data.time}</div>
      </Grid>
      <Grid item xs={2} md={1}>
        <div className={cn('studio-console__message_type', data.type)}>{data.type}</div>
        {data?.request?.method && (
          <div className={cn('studio-console__method_type', data.request.method)}>
            {data.request.method}
          </div>
        )}
      </Grid>
      <Grid item xs={8} md={10}>
        <Grid container>
          {data?.request?.url && (
            <Grid item xs={12} className="studio-console__message_url">
              {data.request.url}
            </Grid>
          )}
          {data?.message && (
            <Grid item xs={12} className="studio-console__message_text">
              {data.message}
            </Grid>
          )}
          {data?.detailMessage && (
            <Grid item xs={12} className="studio-console__message_text">
              {data.detailMessage}
            </Grid>
          )}
          {data?.errors?.map(err => (
            <Grid item xs={12} className="studio-console__message_text">
              {err}
            </Grid>
          ))}
          {showLog && (
            <Grid item xs={12} className="studio-console__message_log">
              {data.log}
            </Grid>
          )}
          {data?.log && (
            <Button
              className="studio-console__message_log_button"
              size="small"
              variant="outlined"
              onClick={() => setShowlog(!showLog)}
            >
              {!showLog ? (
                <FormattedMessage id="console.showLog" />
              ) : (
                <FormattedMessage id="console.hideLog" />
              )}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const downloadFile = (filename, mimeType, content) => {
  const url = URL.createObjectURL(new File([content], filename, {type: mimeType}));
  const a = document.createElement('a');
  a.style.display = 'none';
  a.setAttribute('download', filename);
  a.setAttribute('href', url);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

const downloadFileFromURL = url => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.setAttribute('download', 'filename');
  a.setAttribute('href', url);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const StudioConsoleBase = ({isOpen, list, onClose, onAdd, onClear}) => {
  const intl = useIntl();

  const [messageTypes, setMessageTypes] = useState([
    MESSAGE_TYPE.ERROR,
    MESSAGE_TYPE.WARN,
  ]);
  const [filteredList, setFilteredList] = useState([]);
  const consoleList = useSelector(getConsoleList);
  const build = useBuild(isOpen);
  const filename = new Date().toISOString();
  const logData = {
    ...build,
    logs: consoleList,
  };
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [selectedDownloadOption, setSelectedDownloadOption] = useState('default');

  useEffect(() => setFilteredList(list.filter(mes => messageTypes.includes(mes.type))), [
    list,
    setFilteredList,
    messageTypes,
  ]);

  const onMessageType = (event, newMessageFilter) => setMessageTypes(newMessageFilter);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const downloadLog = async () => {
    let formattedFrom, formattedTo;
    if (selectedDownloadOption === LOG_DOWNLOAD_OPTIONS.CUSTOM) {
      formattedFrom = moment()
        .subtract(Number(from), 'h')
        .format();
      formattedTo = moment()
        .subtract(Number(to), 'h')
        .format();
    } else if (selectedDownloadOption === LOG_DOWNLOAD_OPTIONS['2h']) {
      formattedFrom = moment()
        .subtract(2, 'h')
        .format();
      formattedTo = null;
    } else if (selectedDownloadOption === LOG_DOWNLOAD_OPTIONS['3h']) {
      formattedFrom = moment()
        .subtract(3, 'h')
        .format();
      formattedTo = null;
    } else {
      formattedFrom = null;
      formattedTo = null;
    }

    const stringified = queryString.stringifyUrl(
      {
        url: URL_SETTINGS.DOWNLOAD_LOG,
        query: {
          from: formattedFrom,
          to: formattedTo,
        },
      },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );

    downloadFileFromURL(stringified);
  };

  const options = {
    [LOG_DOWNLOAD_OPTIONS['2h']]: intl.formatMessage({
      id: 'console.download.option.last2hours',
    }),
    [LOG_DOWNLOAD_OPTIONS['3h']]: intl.formatMessage({
      id: 'console.download.option.last3hours',
    }),
    [LOG_DOWNLOAD_OPTIONS.CUSTOM]: intl.formatMessage({
      id: 'console.download.option.custom',
    }),
    [LOG_DOWNLOAD_OPTIONS.DEFAULT]: intl.formatMessage({
      id: 'console.download.option.default',
    }),
  };

  const order = Object.entries(options)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .map(a => a[0]);

  return (
    <StudioDrawer
      open={isOpen}
      className="studio-console"
      anchor="bottom"
      variant="temporary"
      style={{
        zIndex: 10000,
      }}
      onClose={onClose}
    >
      <div className="studio-console__container">
        <Toolbar className="studio-console__toolbar">
          <Tabs value={value} onChange={handleChange} aria-label="Console navigation">
            <Tab
              label={intl.formatMessage({
                id: 'console.title.messages',
              })}
            />
            <Tab
              label={intl.formatMessage({
                id: 'console.title.develop',
              })}
            />
          </Tabs>
          <div className="studio-console__spacer" />
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <StudioTabPanel value={value} index={0} className="studio-console__tab_panel">
          <Toolbar className="studio-console__toolbar">
            <ToggleButtonGroup
              value={messageTypes}
              onChange={onMessageType}
              aria-label="message filter"
            >
              <ToggleButton value="success" aria-label="success">
                <FormattedMessage id="console.success" />
              </ToggleButton>
              <ToggleButton value="warn" aria-label="warning">
                <FormattedMessage id="console.warning" />
              </ToggleButton>
              <ToggleButton value="error" aria-label="error">
                <FormattedMessage id="console.error" />
              </ToggleButton>
            </ToggleButtonGroup>
            <div className="studio-console__spacer" />
            <StudioSelect
              options={options}
              className="studio-console__from__date"
              order={order}
              SelectProps={{
                value: selectedDownloadOption,
                onChange: event => {
                  setSelectedDownloadOption(event.target.value);
                },
              }}
            />
            {selectedDownloadOption === LOG_DOWNLOAD_OPTIONS.CUSTOM && (
              <>
                <Input
                  name="from"
                  type="number"
                  placeholder={intl.formatMessage({
                    id: 'console.download.date.from',
                  })}
                  className="studio-console__from__date"
                  onChange={event => {
                    setFrom(event.target.value);
                  }}
                />
                <Input
                  name="to"
                  type="number"
                  placeholder={intl.formatMessage({
                    id: 'console.download.date.to',
                  })}
                  className="studio-console__from__date"
                  onChange={event => {
                    setTo(event.target.value);
                  }}
                />
              </>
            )}
            <Box marginX={1}>
              <Button variant="contained" onClick={downloadLog}>
                <FormattedMessage id="console.download" />
              </Button>
            </Box>
            <Box marginX={1}>
              <Button
                variant="contained"
                onClick={() =>
                  downloadFile(
                    `${filename}.json`,
                    'application/json',
                    JSON.stringify(logData)
                  )
                }
              >
                <FormattedMessage id="console.export" />
              </Button>
            </Box>
            <Box marginX={1}>
              <Button variant="outlined" onClick={() => onClear()}>
                <FormattedMessage id="console.clear" />
              </Button>
            </Box>
          </Toolbar>
          <div className="studio-console__message">
            {filteredList.map((item, i) => (
              <Grid key={i} container className="studio-console__message_row">
                <MessageRenderer data={item} />
              </Grid>
            ))}
          </div>
        </StudioTabPanel>
        <StudioTabPanel
          value={value}
          index={1}
          className="studio-console__tab_panel application"
        >
          <Box marginX={1}>
            <BuildVersion />
          </Box>
        </StudioTabPanel>
      </div>
    </StudioDrawer>
  );
};

const mapStateToProps = state => ({
  isOpen: getIsConsoleOpen(state),
  list: getConsoleList(state),
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(viewCloseConsole()),
  onAdd: () =>
    dispatch(consoleAdd({type: 'error', time: new Date(), text: 'hello there'})),
  onClear: () => dispatch(consoleClear()),
});

export const StudioConsole = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudioConsoleBase);
