import React, {forwardRef, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  IconButton,
  InputAdornment,
  Link,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import './StudioPasswordField.scss';

export type StudioPasswordFieldProps = TextFieldProps;

export const StudioPasswordField = forwardRef<HTMLDivElement, StudioPasswordFieldProps>(
  ({className, InputLabelProps, InputProps, variant, ...rest}, ref) => {
    const [visiblePassword, setVisiblePassword] = useState(false);

    const toggleVisiblePassword = (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      e.preventDefault();
      setVisiblePassword(!visiblePassword);
    };

    return (
      <TextField
        ref={ref}
        type={visiblePassword ? 'text' : 'password'}
        className={cn('studio-password-field', className)}
        InputLabelProps={{
          shrink: true,
          ...InputLabelProps,
        }}
        InputProps={{
          disableUnderline: variant !== 'outlined',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                component={Link}
                type="button"
                onClick={toggleVisiblePassword}
                tabIndex={-1}
              >
                {visiblePassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps,
        }}
        {...rest}
      />
    );
  }
);

StudioPasswordField.propTypes = {
  className: PropTypes.string,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
};
