import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmIcon from '@material-ui/icons/HelpOutline';
import {useIntl} from 'react-intl';

// TODO: Need to merge this functionlity with Confirm Dialog
//  Make the Confirm dialog accept cutom button actions
export const MergeDialog = ({
  open = false,
  title = '',
  onClose = () => {},
  onMerge = () => {},
  onDiscard = () => {},
}) => {
  const intl = useIntl();

  return (
    <Dialog
      className="confirm-dialog"
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog"
      aria-describedby="confirm-dialog-description"
      data-testid="confirm-dialog"
    >
      <DialogTitle>
        <div className="confirm-dialog__title-container">
          <ConfirmIcon fontSize="default" color="secondary" />
          <span className="confirm-dialog__title">{title}</span>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="confirm-dialog__message">
          {intl.formatMessage({
            id: 'dataPrep.finalize.merge.predictions',
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="confirm-dialog-keep"
          onClick={onMerge}
          variant="contained"
          color="primary"
          disableElevation
        >
          {intl.formatMessage({id: 'dataPrep.finalize.merge.keep'})}
        </Button>
        <Button
          data-testid="confirm-dialog-discard"
          onClick={onDiscard}
          variant="contained"
          color="primary"
          disableElevation
        >
          {intl.formatMessage({id: 'dataPrep.finalize.merge.discard'})}
        </Button>
        <Button onClick={onClose} variant="outlined">
          {intl.formatMessage({
            id: 'form.cancel',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
