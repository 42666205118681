export type DeploymentType =
  | 'VALIDATION'
  | 'INFERENCE'
  | 'SAMPLE_INFERENCE'
  | 'VALIDATION_PLUS_INFERENCE'
  | 'BATCH_INFERENCE'
  | 'BATCH_INFERENCE_JS'
  | 'PRODUCTION';

export const DeploymentTypes: Record<string, DeploymentType> = {
  VALIDATION: 'VALIDATION',
  INFERENCE: 'INFERENCE',
  SAMPLE_INFERENCE: 'SAMPLE_INFERENCE',
  VALIDATION_PLUS_INFERENCE: 'VALIDATION_PLUS_INFERENCE',
  BATCH_INFERENCE: 'BATCH_INFERENCE',
  PRODUCTION: 'PRODUCTION',
};
