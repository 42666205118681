// Message selection to response mapping
export const INFO_MESSAGES = {
  NEW_GRAPH:
    'Create an ISP flow by selecting modules from below menu and connect them in the canvas on the right',
  CONNECT_MODULE:
    'Create links by clicking the ports in the nodes on canvas and drag and drop the link to port of another node',
};

export const ERROR_MESSAGES = {
  INVALID_CONFIG: 'Invalid configuration. See the field parameters of this module',
  PARALLEL_GRAPH_NOT_SUPPORTED: 'No disconnected modules or flows are allowed',
  LOOP_GRAPH_NOT_SUPPORTED: 'Loops or cycles in graph are not supported',
  BRANCH_GRAPH_NOT_SUPPORTED: 'Graphs with branches are not supported',
  AT_LEAST_ONE_MODULE: 'Graph should at least have one module',
  CONNECT_MODULE: (input, output) =>
    `${input} input and ${output} output ports are incompatible. Compatible ports have the same color. Either link a ${input} input to a module's ${input} output or ${output} output to another module's ${output} input`,
  MODULE_INCOMPATIBLE:
    'Module is incompatible with the current selected input or output color space',
};
