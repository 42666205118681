import React, {RefObject} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {IntegrationPurpose} from '../../../types/integrations/IntegrationResponse';
import {actions} from '../../../store/marketplace/add-pp';
import {ImportDialog} from './ImportDialog';
import {SortableColumn} from '../../../types/marketplace/MarketplaceFilesRequest';
import {PPUploadActionBase} from '../../StageView/StageActions/PPUploadAction';

export type AddPPDialogProps = {
  actionRef?: RefObject<InstanceType<typeof PPUploadActionBase>>;
};

const PURPOSE: IntegrationPurpose = 'PP_STORAGE';
const {setIsOpen} = actions;
const columns: SortableColumn[] = [
  'displayName',
  'postProcessorType',
  'applicationType',
  'executionContainer',
  'provider',
  'creationDateTime',
  'size',
  'studioVersion',
];

export const AddPPDialog = ({actionRef}: AddPPDialogProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.addPPDialog.isOpen);

  return (
    <ImportDialog
      isOpen={isOpen}
      title="Add Post Processor"
      purpose={PURPOSE}
      columns={columns}
      onClose={() => dispatch(setIsOpen({isOpen: false}))}
      onSubmit={item =>
        actionRef?.current?.ppUpload([
          {
            name: item.name,
            type: item.type,
            integrationId: item.integrationId,
          },
        ])
      }
      defaultFilterValues={{compatibleOnly: true}}
    />
  );
};
