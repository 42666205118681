import cn from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';
import {matchPath, Route, useHistory, useLocation} from 'react-router';
import SubheaderButton from '../../../base-components/StudioButton/SubheaderButton';
import HumanReadableTime from '../../../base-components/StudioHumanReadableTime/HumanReadableTime';
import PopUpMenu from '../../../base-components/StudioPopUpMenuLegacy/PopUpMenu';
import {PrivateRoutes} from '../../../config/routes';
import {useAppDispatch} from '../../../store';
import {getProjectData} from '../../../store/project';
import {getProjectListData} from '../../../store/projectList';
import {viewToggleEditISP, viewToggleEditProjectForm} from '../../../store/view';
import {Project} from '../../../types/project/Project';
import {ProjectListItem} from '../../../types/project/ProjectListResponse';
import {PostProcessingMenu} from './PostProcessingMenu';
import {PreProcessingMenu} from './PreProcessingMenu';
import './SideBar.scss';

export type SideBarProps = {
  className: string;
};

export function SideBar({className}: SideBarProps) {
  const appDispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const project: Project = useSelector(getProjectData);
  const projectList: ProjectListItem[] = useSelector(getProjectListData);

  const projectId = project.id;
  const name = project.name;

  const isDesignPage = isMatch(PrivateRoutes.DESIGN);
  const isFlowgraph = isMatch(PrivateRoutes.FLOWGRAPH);
  const isTestPage = isMatch(PrivateRoutes.TEST);

  return (
    <div className={cn('sidebar', className)}>
      <div>
        <Route
          path="/project/:projectId"
          render={routeProps => projectId && projectList.length > 0 && renderProjectNav()}
        />
      </div>
      <div className="subheader-right-buttons" data-testid="subheader-right-buttons">
        {isTestPage
          ? renderTestPageActions()
          : isDesignPage || isFlowgraph
          ? renderISPActions(Boolean(isFlowgraph))
          : null}
      </div>
    </div>
  );

  function renderProjectNav() {
    const navProjectList = projectList.slice(0, 5).map(project => {
      return {
        id: project.id,
        title: project.name,
        action: () => {
          // TODO: simplify to use router links rather than force window reload
          history.push(`/project/${project.id}/upload`);
        },
        helpingInfo: <HumanReadableTime date={project.lastUpdateTime} />,
      };
    });
    return (
      <div className="leftBtns" data-testid="breadcrumb-navigation">
        <SubheaderButton value="Projects" onClick={() => history.push('/project/list')} />
        {/* TODO: Change to material breadcrumb to simplify implementation */}
        <p>&nbsp; / &nbsp;</p>
        <PopUpMenu
          list={navProjectList}
          launcher="subheader"
          selected={{id: projectId, title: name}}
          actionSegment={{
            title: 'View all projects',
            icon: 'chevron',
            action: () => history.push('/project/list'),
          }}
          customClass="subheader-button-holder"
        />
      </div>
    );
  }

  function renderTestPageActions() {
    /*
        <SubheaderButton
          buttonIcon="arrowRight"
          onClick={() => history.push('/deployment/list')}
          value="View Deployments"
        />
      */
    return null;
  }

  function renderISPActions(isFlowgraph: boolean) {
    return (
      <>
        <PreProcessingMenu projectId={projectId} isFlowgraphPage={!!isFlowgraph} />
        <PostProcessingMenu projectId={projectId} />
        <SubheaderButton
          id="propertiesButton"
          buttonIcon="properties"
          onClick={() => {
            !!isFlowgraph
              ? appDispatch(viewToggleEditISP())
              : appDispatch(viewToggleEditProjectForm());
          }}
          data-testid="properties-panel-btn"
          customClass="propertiesBtn"
        />
      </>
    );
  }

  function isMatch(path: string) {
    return matchPath(location.pathname, {
      path,
      exact: true,
    });
  }
}
