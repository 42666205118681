import React, {useState, createContext, PropsWithChildren} from 'react';
import {ImageDataType} from './ImageThumbView';

interface PageListContextInterface {
  page: number; // Current page number
  setPage: (val: number) => void;
  pageCount: number; // Nunber of pages
  setPageCount: (val: number) => void;
  itemsPerPageCount: number; // Item number per page
  setItemsPerPage: (val: number) => void;
  listCount: number; // filtered list count
  setListCount: (val: number) => void;
  totalListCount: number; // total/unfiltered list  count
  setTotalListCount: (val: number) => void;
  // Visible list on the viewer
  listContent: ImageDataType[];
  setListContent: (arr: ImageDataType[]) => void;
  // Complete sorted list
  sortedList: ImageDataType[];
  setSortedList: (arr: ImageDataType[]) => void;
}

const initState = {
  page: 1,
  setPage: (val: number) => {},
  pageCount: 0,
  setPageCount: (val: number) => {},

  itemsPerPageCount: 0,
  setItemsPerPage: (val: number) => {},

  listCount: 0,
  setListCount: (val: number) => {},
  totalListCount: 0,
  setTotalListCount: (val: number) => {},

  listContent: [],
  setListContent: (arr: ImageDataType[]) => {},

  sortedList: [],
  setSortedList: (arr: ImageDataType[]) => {},
};

export const PageListContext = createContext<PageListContextInterface>(initState);

export const PageListProvider = (props: PropsWithChildren<{}>) => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPageCount, setItemsPerPage] = useState(0);
  const [listCount, setListCount] = useState(0);
  const [totalListCount, setTotalListCount] = useState(0);
  const [listContent, setListContent] = useState<ImageDataType[]>([]);
  const [sortedList, setSortedList] = useState<ImageDataType[]>([]);
  return (
    <PageListContext.Provider
      value={
        {
          page,
          pageCount,
          itemsPerPageCount,
          setPage,
          setPageCount,
          setItemsPerPage,
          listCount,
          setListCount,
          totalListCount,
          setTotalListCount,
          listContent,
          setListContent,
          sortedList,
          setSortedList,
        } as PageListContextInterface
      }
    >
      {props.children}
    </PageListContext.Provider>
  );
};
