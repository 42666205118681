import axios from 'axios';
import {toast} from '../../base-components/StudioToast';
import URL from '../../config/url';
import StageAnimation from '../../components/StageView/StageAnimation/StageAnimation';

// ACTIONS
export const STAGES_FETCH_PENDING = 'stages/fetch-pending';
export const STAGES_FETCH_SUCCESS = 'stages/fetch-success';
export const STAGES_FETCH_ERROR = 'stages/fetch-error';
export const SET_STAGES_PROMPTS = 'stages/set-prompts';

// ACTION CREATORS

export const stagesFetchPending = id => ({
  type: STAGES_FETCH_PENDING,
  id,
});

export const stagesFetchSuccess = (id, payload) => ({
  type: STAGES_FETCH_SUCCESS,
  id,
  payload,
});

export const stagesFetchError = (id, payload) => ({
  type: STAGES_FETCH_ERROR,
  id,
  payload,
});

export const setStagesPrompts = ({isOpen, notifyType, isCancelled}) => ({
  type: SET_STAGES_PROMPTS,
  payload: {isOpen, notifyType, isCancelled},
});

export const stageFetch = (
  id,
  params,
  redraw = false,
  uploadingStages
) => async dispatch => {
  dispatch(stagesFetchPending(id));
  try {
    const res = await axios.get(URL.STAGE_STATUS(id), {params});
    if (res.data.body) {
      // temporary until stages are refactored
      if (!redraw) {
        let newActiveStage = res.data.body.stages.find(stage => stage.active) || {
          type: 'ADD_DATA',
        };
        const stageAnimation = new StageAnimation();
        res.data.body.stages.forEach(stage => {
          // refresh if current stage not doing file upload
          if (!uploadingStages?.[stage.type]) {
            stageAnimation.refreshStage(stage);
          }
        });
        stageAnimation.shiftStages(newActiveStage.type);
      }
      dispatch(stagesFetchSuccess(id, {...res.data}));
    } else {
      dispatch(stagesFetchSuccess(id, res.data));
    }
  } catch (error) {
    toast.error(`Failed to fetch project status: ${error.message}`);
    dispatch(stagesFetchError(id, error));
  }
};
