import React from 'react';
import './StageLine.scss';
import StageStep from '../StageStep/StageStep';
import BaseStageList from '../Base/BaseStageList';
import StageAnimation from '../StageAnimation/StageAnimation';
import PropTypes from 'prop-types';

class StageLine extends BaseStageList {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.resizeLine = this.resizeLine.bind(this);
  }

  componentDidMount() {
    if (this.containerRef.current) {
      this.resizeObserver = new ResizeObserver(this.resizeLine);
      this.resizeObserver.observe(this.containerRef.current);
    }
    this.resizeLine();
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  render() {
    return (
      <ul ref={this.containerRef} className="stage-line-area">
        {this.renderList()}
      </ul>
    );
  }

  getStage(stage, first, last, finished, active) {
    const key = stage.type + '-step';
    return (
      <StageStep
        stage={stage}
        first={first}
        last={last}
        prevFinished={finished}
        active={active}
        key={key}
      />
    );
  }

  resizeLine() {
    let elem = this.props.stages
      .map(stage => {
        return document.getElementById(stage.type + '-container');
      })
      .find(element => {
        return element && element.classList.contains('active');
      });
    if (elem) {
      const stageAnimation = new StageAnimation();
      stageAnimation.updateStages(elem);
    }
  }
}

StageLine.propTypes = {
  stages: PropTypes.array.isRequired,
};

export default StageLine;
