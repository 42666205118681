import {Box, Button} from '@material-ui/core';
import {Field, FieldProps, Form, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useParams} from 'react-router';
import {DropArea} from '../../base-components/StudioDropArea';
import {ACCEPTED_FILE_TYPES} from '../TestDeployView/DeployConfigPanel';
import {FileExplorer} from '../TestDeployView/FileExplorer';
import * as Yup from 'yup';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../../store';
import {LoadingSkeleton} from './LoadingSkeleton';
import cn from 'classnames';
import {InferenceAppExecution} from './CreateInferenceAppPage';
import {OpenVXRuntimeContainer} from './OpenVXRuntimeContainer';
import {RUNTIME_MODE_OPTIONS, RuntimeModeType} from '../TestDeployView/useDeploy';
import {StudioSelect} from '../../base-components/StudioSelect';
import {ONNXRuntimeContainer} from '../ONNXRuntimeContainer/ONNXRuntimeContainer';
import './InferenceApp.scss';
import {InferenceApplicationInfo} from '../../types/deployment/InferenceApplicationsResponse';
import {InferenceApplicationsRequest} from '../../types/deployment/InferenceApplicationsRequest';
import {fetchInferenceApps} from '../../store/prodDeployments/dashboard';
import {Project} from '../../types/project/Project';
import {projectFetch} from '../../store/project';
import {ApplicationProcessing} from '../../types/project/Application';

type ViewInferenceAppForm = {
  images: File[];
  runtimeMode: RuntimeModeType;
};

const ValidationSchema = Yup.object().shape(
  {
    images: Yup.array().min(1, 'Please add images'),
  },
  [['exposeAPI', 'exposeInferencePage']]
);

export const ViewInferenceAppPage = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const {projectId, applicationId, deploymentId} = useParams<{
    projectId: string;
    applicationId: string;
    deploymentId: string;
  }>();
  const [inProgress, setInProgress] = useState(false);
  const [executionContext, setExecutionContext] = useState<InferenceAppExecution>();
  const userId = useSelector((state: RootState) => state.user.userId);
  const groupId = useSelector((state: RootState) => state.user.groupId);
  const inferenceApps: Array<InferenceApplicationInfo> = useSelector(
    (state: RootState) => state.deploymentDashboard.inferenceApps.data
  );
  const inferenceApplication = inferenceApps.find(app => app.id === applicationId);
  const projectData: Project = useSelector((state: RootState) => state.project.data);
  const application = Object.values(projectData.applications).find(
    application => application.id === applicationId
  );
  const testPostProcessor = inferenceApplication?.postProcessors?.find(
    pp => pp.postProcessorType === 'APPLICATION' && pp.executionContainer === 'BROWSER'
  );

  const postProcessorExists = !!testPostProcessor;

  useEffect(() => {
    if (applicationId && groupId) {
      const requestBody: InferenceApplicationsRequest = {
        filterOutNonInferenceApps: true,
        firstPage: 0,
        pageSize: 100,
        paginated: false,
        orderDefinitions: [],
        params: {},
        applicationId: applicationId,
        groupId,
      };

      dispatch(fetchInferenceApps(requestBody));
    }
  }, [projectId, applicationId, dispatch, groupId]);

  useEffect(() => {
    if (inferenceApplication?.ownerId === userId) {
      dispatch(projectFetch(projectId));
    }
  }, [projectId, dispatch, inferenceApplication?.ownerId, userId]);

  const handleSubmit = async (values: ViewInferenceAppForm) => {
    try {
      setInProgress(true);
      const application: ApplicationProcessing | undefined = findApplication();
      if (application && application?.runtimeType === 'OPENVX') {
        setExecutionContext({
          type: 'OPENVX',
          images: [...values.images],
          application: application,
          deploymentId,
        });
      } else if (application && application?.runtimeType === 'ONNX_RUNTIME_WEB') {
        setExecutionContext({
          type: 'ONNX_RUNTIME_WEB',
          images: [...values.images],
          application: application,
          runtimeMode: values.runtimeMode,
          id: Date.now().toString(),
          configuredParams: [],
        });
      }
    } catch (e) {
      setInProgress(false);
      console.error(e);
    }
  };

  const handleImageAppend = (existing: File[], newFiles: File[]) => {
    const merged = [...existing];
    newFiles.forEach(newFile => {
      if (!merged.find(file => file.name === newFile.name)) {
        merged.push(newFile);
      }
    });
    return merged;
  };

  const findModelInputShape = (): number[] | undefined => {
    if (inferenceApplication) {
      if (inferenceApplication.ownerId === userId) {
        if (application) {
          return application.model.frameworkParameters.inputShape;
        }
      } else {
        return inferenceApplication.modelInputShape;
      }
    }
  };

  const findApplication = (): ApplicationProcessing | undefined => {
    if (inferenceApplication) {
      if (inferenceApplication.ownerId === userId) {
        if (application) {
          return application;
        }
      } else {
        return inferenceApplication;
      }
    }
  };

  const inputShape: number[] | undefined = findModelInputShape();

  return (
    <Formik<ViewInferenceAppForm>
      initialValues={{
        images: [],
        runtimeMode: 'webgl',
      }}
      enableReinitialize
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {({isValid, dirty, values}) => (
        <div className="inference-app">
          <div className="inference-app__menu">
            <h2>{application?.name}</h2>
          </div>
          <div className="inference-app__body">
            <Form className="inference-app__panel">
              <div className="inference-app__fields">
                {application?.runtimeType === 'ONNX_RUNTIME_WEB' && (
                  <Field name="runtimeMode">
                    {({field}: FieldProps<ViewInferenceAppForm['runtimeMode']>) => (
                      <StudioSelect
                        id="runtime-mode"
                        required
                        fullWidth
                        disabled={inProgress}
                        label={intl.formatMessage({id: 'test.runtime.label'})}
                        options={RUNTIME_MODE_OPTIONS}
                        SelectProps={field}
                        tooltip={intl.formatMessage({id: 'test.runtime.tooltip'})}
                      />
                    )}
                  </Field>
                )}
                <Field name="images">
                  {({field, form}: FieldProps<ViewInferenceAppForm['images']>) => {
                    const setValue = (value: File[] | null) =>
                      form.setFieldValue('images', value);
                    return (
                      <div>
                        <div className="inference-app__label">
                          <FormattedMessage id="inferenceApp.images" />
                        </div>
                        <DropArea
                          onChange={files =>
                            setValue(handleImageAppend(values.images, files))
                          }
                          accept={ACCEPTED_FILE_TYPES.join(',')}
                          disabled={inProgress}
                        />
                        <Box>
                          <FileExplorer
                            files={field.value || []}
                            onDelete={(item, index) => {
                              const updated = [...field.value];
                              updated.splice(index, 1);
                              setValue(updated);
                            }}
                            onClear={() => setValue([])}
                            size="small"
                            icon={inProgress ? 'check' : 'delete'}
                          />
                        </Box>
                      </div>
                    );
                  }}
                </Field>
              </div>
              <div className="inference-app__submit">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={
                    !isValid ||
                    !dirty ||
                    inProgress ||
                    values.images.length === 0 ||
                    !postProcessorExists
                  }
                >
                  <FormattedMessage id="inferenceApp.test" />
                </Button>
              </div>
            </Form>
            <section className="inference-app__results">
              <h5>
                {intl.formatMessage({
                  id: inProgress ? 'form.loading' : 'prodDeployment.inferenceResults',
                })}
              </h5>
              {inProgress && <LoadingSkeleton count={values.images.length} />}
              <div
                id="inference-results-element"
                className={cn(inProgress && 'inference-app--hidden')}
              ></div>
              {executionContext?.type === 'OPENVX' && (
                <OpenVXRuntimeContainer
                  projectId={projectId}
                  application={executionContext.application}
                  deploymentId={executionContext.deploymentId}
                  images={executionContext.images}
                  onError={() => setInProgress(false)}
                  onFinish={() => setInProgress(false)}
                />
              )}
              {inputShape && executionContext?.type === 'ONNX_RUNTIME_WEB' && (
                <ONNXRuntimeContainer
                  key={executionContext.id}
                  type="IMAGES"
                  projectId={projectId}
                  application={executionContext.application}
                  runtimeMode={executionContext.runtimeMode}
                  configuredParams={executionContext.configuredParams}
                  inputShape={inputShape}
                  files={executionContext.images}
                  onError={() => setInProgress(false)}
                  onFinish={() => setInProgress(false)}
                />
              )}
            </section>
          </div>
        </div>
      )}
    </Formik>
  );
};
