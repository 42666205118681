import {useEffect} from 'react';
import {CategoryType} from '../../../types/dataset/DatasetGetAnnotationsResponse';
import {RawInferenceResponse} from '../../../types/gsp/RawInferenceResponse';
import {PostProcessorScript} from '../../../types/scripts/PostProcessorScript';

type InferenceInfo = {
  type: 'inference';
  inferenceOutput?: RawInferenceResponse;
  uploadedFiles?: File[];
  labels?: CategoryType[];
};

type VideoDataInfo = {
  type: 'videoData';
  dataContainer?: HTMLDivElement | null;
  data?: Record<string, any> | null;
};

type useApplicationPostProcessorProps = {
  module: PostProcessorScript | undefined;
} & (InferenceInfo | VideoDataInfo);

export const useApplicationPostProcessor = ({
  module,
  ...args
}: useApplicationPostProcessorProps) => {
  useEffect(() => {
    if (module && args) {
      try {
        if (args.type === 'inference' && 'postProcess' in module) {
          const {labels, inferenceOutput, uploadedFiles} = args;
          if (labels && inferenceOutput && uploadedFiles) {
            module.readAnnotations && module.readAnnotations(labels);
            module.postProcess &&
              module.postProcess(inferenceOutput, labels, uploadedFiles);
          }
        } else if (args.type === 'videoData' && 'displayVideoData' in module) {
          const {data, dataContainer} = args;
          if (data && dataContainer && module.displayVideoData) {
            module.displayVideoData(dataContainer, data);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [module, args]);
};
