import {IntegrationPurpose} from '../../types/integrations/IntegrationResponse';

export const LITE_TABS: Array<{name: string; purpose: IntegrationPurpose}> = [
  {
    name: 'dashboard.marketplace.solution',
    purpose: 'SOLUTION_STORAGE',
  },
  {
    name: 'dashboard.marketplace.data',
    purpose: 'DATASET_STORAGE',
  },
  {
    name: 'dashboard.marketplace.model',
    purpose: 'MODEL_STORAGE',
  },
  {
    name: 'dashboard.marketplace.isp',
    purpose: 'ISP_STORAGE',
  },
  {
    name: 'dashboard.marketplace.pp',
    purpose: 'PP_STORAGE',
  },
];
