import React, {Component} from 'react';
import {connect} from 'react-redux';
import StageAnimation from '../StageAnimation/StageAnimation';
import Util from '../../../util';
import {toast} from '../../../base-components/StudioToast';
import {StageAssistantForm} from '../../StageAssistantForm';
import axios from 'axios';
import URL from '../../../config/url';
import {addListener, removeListener} from '../../../store/statusChecker';
import {viewDisableTermination, viewEnableTermination} from '../../../store/view';

class ModelFilePrepareAction extends Component {
  state = {
    preparing: false,
  };

  constructor(props) {
    super(props);

    this.stageAnimation = new StageAnimation();
    this.terminateImport = this.terminateImport.bind(this);
    this.preparePyTorchModelFile = this.preparePyTorchModelFile.bind(this);
    this.clarifyPrepareModelFile = this.clarifyPrepareModelFile.bind(this);
    this.handlePrepareModelFileClarify = this.handlePrepareModelFileClarify.bind(this);
    this.monitorProgress = this.monitorProgress.bind(this);
    this.processPrepareModelFileProgress = this.processPrepareModelFileProgress.bind(
      this
    );
    this.finalizePrepareModelFile = this.finalizePrepareModelFile.bind(this);
    this.finishPreparation = this.finishPreparation.bind(this);
    this.isPreparing = this.isPreparing.bind(this);
  }

  componentDidMount() {
    if (this.props.readyForPyTorchModelPreparation) {
      this.preparePyTorchModelFile();
    } else if (this.props.taskProgress?.type === 'PREPARE_MODEL_FILE') {
      if (this.props.taskProgress.body?.messages?.length) {
        this.stageAnimation.shiftStages('IMPORT_MODEL');
        this.showAssistant(this.props.taskProgress);
      } else {
        this.monitorProgress();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.taskProgress?.type !== 'PREPARE_MODEL_FILE' &&
      this.props.taskProgress?.type === 'PREPARE_MODEL_FILE'
    ) {
      if (this.props.taskProgress.body?.messages?.length) {
        this.stageAnimation.shiftStages('IMPORT_MODEL');
        this.showAssistant(this.props.taskProgress);
      } else {
        this.monitorProgress();
      }
    }
  }

  componentWillUnmount() {
    this.props.removeStatusListener('PREPARE_MODEL_FILE');
  }

  isPreparing() {
    return this.state.preparing;
  }

  render() {
    return null;
  }

  // I just copy-pasted it from ModelCreateHelperFileAction

  preparePyTorchModelFile() {
    this.stageAnimation.markInProgress('IMPORT_MODEL');
    return axios
      .get(URL.MODEL_FILE_PREPARE, {
        params: {projectId: this.props.projectId},
      })
      .then(res => this.handleCreationResponse(res.data))
      .catch(error => {
        toast.error(`model create helper error: ${error.message}`);
        this.terminateImport();
      });
  }

  handleCreationResponse(response) {
    if (response.errors && response.errors.length > 0) {
      this.terminateImport();
      this.finish(response?.body?.name, false);
    } else if (response.prompts) {
      // Show questions
      this.showAssistant(response);
    } else {
      this.clarifyPrepareModelFile([]);
    }
  }

  finish(modelName, isSuccess) {
    if (this.props.onFinish) {
      // Hold on this since conversion comes up next
      if (isSuccess) {
        toast.success({
          title: 'Success!',
          subtitle: `Successfully created PyTorch model file for ${modelName || 'Model'}`,
          position: 'top',
          actions: [
            {
              label: 'View Details',
              callback: () => this.props.toggleModelDetailsPanel(this.props.projectId),
            },
          ],
          duration: 7000,
        });
        this.props.setModelViewerStale(true);
      }
      this.props.onFinish('IMPORT_MODEL');
    }
    this.setState({busy: false});
  }

  showAssistant(response) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.display(
        this.getQuestionsForm(
          response.prompts,
          response.defaults,
          response.body?.messages,
          response.body?.header
        ),
        'IMPORT_MODEL',
        null,
        this.terminateImport
      );
    }
  }

  getQuestionsForm(questions, defaults, messages, header) {
    return (
      <StageAssistantForm
        messages={messages}
        prompts={questions}
        defaults={defaults}
        onSubmit={this.clarifyPrepareModelFile}
        onCancel={this.terminateImport}
        title={header}
      />
    );
  }

  clarifyPrepareModelFile(answersArray) {
    if (this.props.assistantPanel.current) {
      this.props.assistantPanel.current.hideStage('IMPORT_MODEL');
    }

    const clarificationRequest = {
      projectId: this.props.projectId,
      answers: answersArray,
      modelId: this.props.model.id,
    };

    axios
      .post(URL.MODEL_FILE_PREPARE_CLARIFY, clarificationRequest)
      .then(res => this.handlePrepareModelFileClarify(res.data))
      .catch(error => toast.error(`model file preparation error: ${error.message}`));
  }

  handlePrepareModelFileClarify(response) {
    if (response.errors && response.errors.length > 0) {
      this.finishPreparation();
    } else if (response.body?.messages?.length) {
      this.showAssistant(response);
    } else {
      this.monitorProgress();
    }
  }

  monitorProgress() {
    this.props.viewEnableTermination();
    this.stageAnimation.markInProgress('IMPORT_MODEL');
    this.props.addStatusListener(
      'PREPARE_MODEL_FILE',
      this.processPrepareModelFileProgress
    );
    this.setState({preparing: true});
    this.props.setModelActionInProgress('import');
  }

  processPrepareModelFileProgress(status) {
    this.displayProgressMessage(status);
    if (Util.isRealNumberEqual(status.percentCompleted, 100)) {
      this.props.removeStatusListener('PREPARE_MODEL_FILE');
      this.finalizePrepareModelFile();
    }
  }

  finalizePrepareModelFile() {
    this.props.viewDisableTermination();
    axios
      .get(URL.MODEL_FILE_PREPARE_FINALIZE, {
        params: {projectId: this.props.projectId},
      })
      .then(response => {
        this.finishPreparation();
        if (!response.errors?.length) {
          this.props.convertModelAction.current &&
            this.props.convertModelAction.current.convertModel();
        }
      })
      .catch(error => toast.error(`model file preparation error: ${error.message}`));
  }

  finishPreparation() {
    this.props.onFinish('IMPORT_MODEL');
    this.setState({preparation: false});
  }

  displayProgressMessage(status) {
    let progressText = document.getElementById('model-action-progress');
    if (progressText) {
      progressText.innerText = status.statusMessage;
    }
  }

  terminateImport() {
    this.props.viewDisableTermination();
    return axios
      .get(URL.OPERATION_CANCEL('PREPARE_MODEL_FILE'), {
        params: {projectId: this.props.projectId},
      })
      .then(res => {
        this.props.assistantPanel.current.hideStage('IMPORT_MODEL');
        this.props.removeStatusListener('PREPARE_MODEL_FILE');
        this.finishPreparation();
      })
      .catch(error => toast.error(`cancel Conversion error: ${error.message}`));
  }
}

const mapDispatchToProps = {
  addStatusListener: addListener,
  removeStatusListener: removeListener,
  viewEnableTermination,
  viewDisableTermination,
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(
  ModelFilePrepareAction
);
