import React from 'react';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';

export const LiteMarketplaceInfo = () => {
  const intl = useIntl();
  return (
    <div>
      <p>
        {intl.formatMessage(
          {id: 'studioLite.intro.2'},
          {
            browseLink: (
              <Link to="/marketplace?role=PUBLIC&purpose=SOLUTION_STORAGE">
                Browse Solutions
              </Link>
            ),
            learnLink: (
              <a
                href="https://www.blaize.com/products/ai-studio/#marketplace"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            ),
          }
        )}
      </p>
    </div>
  );
};
