import useSWR from 'swr';
import {Project} from '../../types/project/Project';
import {assertResponseBody, request} from '../request';

export type ProjectGetRequest = {
  projectId: string | null;
};

export type ProjectGetResponse = Project;

export const ProjectGetUrl = ({projectId}: ProjectGetRequest) =>
  `/project/${projectId}/get`;

export async function projectGet({projectId}: ProjectGetRequest) {
  return request<ProjectGetResponse>({
    method: 'GET',
    url: `/project/${projectId}/get`,
    params: {
      projectId,
    },
  });
}

export function useProjectGet(params: ProjectGetRequest) {
  return useSWR(
    params.projectId ? [ProjectGetUrl(params), params.projectId] : null,
    () => assertResponseBody(projectGet(params)),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
}
