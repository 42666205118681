import React, {Component} from 'react';
import {compose} from 'redux';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {Button} from '@material-ui/core';
import {Formik} from 'formik';
import {DeleteDialog} from '../../base-components/StudioDeleteDialog';
import validationSchema from '../CreateProjectForm/ValidationSchema';
import {toast} from '../../base-components/StudioToast';
import {StudioTextField} from '../../base-components/StudioTextField';
import {StudioDrawer} from '../../base-components/StudioDrawer';
import {
  getProjectData,
  projectUpdate,
  projectFetch,
  projectDelete,
} from '../../store/project';
import {projectListFetchReplace} from '../../store/projectList';
import {getIsEditProjectFormOpen, viewCloseEditProjectForm} from '../../store/view';
import {FormattedMessage, injectIntl} from 'react-intl';
import ModelActionButton from '../../base-components/StudioButton/ModelActionButton';
import {openPublishForm} from '../../store/marketplace/configure';
import './PropertiesPanelForm.scss';
import {PROJECT_LOG_LEVELS} from './ProjectTypes';
import {StudioFormikSelect} from '../Formik/StudioFormikSelect';
import {PROJECT_SETTINGS} from './../../config/constants';

class PropertiesPanelFormBase extends Component {
  componentWillUnmount() {
    this.props.dispatchViewCloseEditProjectForm();
  }

  deleteProject = async () => {
    try {
      await this.props.dispatchProjectDelete(this.props.projectData.id);
      toast.success({
        title: this.props.intl.formatMessage(
          {id: 'project.deleteToastContent'},
          {name: this.props.projectData.name}
        ),
        duration: 10000,
        position: 'top',
      });
      this.props.dispatchViewCloseEditProjectForm();
      this.props.history.push('/');
    } catch (e) {
      toast.error({
        title: this.props.intl.formatMessage(
          {id: 'projectPanel.deleteError'},
          {name: this.props.projectData.name}
        ),
        duration: 10000,
      });
    }
  };

  render() {
    const name = this.props.projectData.name ?? '';
    const description = this.props.projectData.description ?? '';
    const logLevel = this.props.projectData.logLevel ?? PROJECT_LOG_LEVELS.INFO;
    return (
      <>
        {this.props.isOpen && (
          <div
            className="properties-panel__backdrop"
            onClick={this.props.dispatchViewCloseEditProjectForm}
            data-testid="properties-panel-backdrop"
            id="properties-panel-backdrop"
          />
        )}
        <StudioDrawer
          open={this.props.isOpen}
          anchor="right"
          className="properties-panel"
        >
          <div className="properties-panel-form-content" data-testid="properties-panel">
            <div className="content-upper">
              <Formik
                initialValues={{name, description, logLevel}}
                enableReinitialize={true}
                validateOnChange={true}
                validationSchema={validationSchema}
                onSubmit={async (data, {setSubmitting, resetForm}) => {
                  const {id} = this.props.projectData;
                  await this.props
                    .dispatchProjectUpdate(id, data.name, data.description, data.logLevel)
                    .then(res => {
                      toast.success({
                        title:
                          name !== res.payload.body.name
                            ? this.props.intl.formatMessage(
                                {id: 'projectPanel.nameChange'},
                                {
                                  oldName: name,
                                  newName: res.payload.body.name,
                                }
                              )
                            : this.props.intl.formatMessage(
                                {id: 'projectPanel.descriptionChange'},
                                {name}
                              ),
                        duration: 3000,
                      });
                    })
                    .then(res => this.props.dispatchProjectListFetchReplace())
                    .then(res => resetForm());
                }}
              >
                {({
                  dirty,
                  errors,
                  isSubmitting,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur,
                }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <StudioTextField
                      placeholder={this.props.intl.formatMessage({
                        id: 'projectPanel.namePlaceholder',
                      })}
                      name="name"
                      autoFocus
                      margin="normal"
                      label={this.props.intl.formatMessage({
                        id: 'projectPanel.nameLabel',
                      })}
                      required
                      fullWidth
                      className="name-field"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        maxLength: PROJECT_SETTINGS.name.maxLength,
                      }}
                      strategy="formik"
                      useFieldProps={false}
                      value={values?.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <StudioTextField
                      inputProps={{
                        className: PROJECT_SETTINGS.description.className,
                        maxLength: PROJECT_SETTINGS.description.maxLength,
                      }}
                      placeholder={this.props.intl.formatMessage({
                        id: 'projectPanel.descriptionPlaceholder',
                      })}
                      name="description"
                      margin="normal"
                      id="description"
                      label={this.props.intl.formatMessage({
                        id: 'projectPanel.descriptionLabel',
                      })}
                      fullWidth
                      className="description-field"
                      multiline={PROJECT_SETTINGS.description.multiline}
                      variant="filled"
                      useFieldProps={false}
                      value={values?.description}
                      strategy="formik"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="log-level">
                      <StudioFormikSelect
                        name="logLevel"
                        label={this.props.intl.formatMessage({
                          id: 'projectPanel.logLevel',
                        })}
                        className="log-level-field"
                        disablePlaceholder
                        options={PROJECT_LOG_LEVELS}
                        tooltip={this.props.intl.formatMessage({
                          id: 'properetiesPanelForm.tooltip',
                        })}
                      />
                    </div>
                    <div className="content-button-container">
                      <Button
                        className="submit-properties-button"
                        onClick={handleSubmit}
                        color="primary"
                        size="large"
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting || !!Object.keys(errors).length || !dirty}
                      >
                        <FormattedMessage id="form.update" />
                      </Button>
                      <div className="content-middle">
                        <DeleteDialog
                          title={this.props.intl.formatMessage({
                            id: 'project.deleteDialogTitle',
                          })}
                          message={this.props.intl.formatMessage(
                            {id: 'project.deleteDialogContent'},
                            {name: this.props.projectData?.name}
                          )}
                          onDelete={this.deleteProject}
                        />
                        <ModelActionButton
                          value="share"
                          type="button"
                          onClick={() =>
                            this.props.dispatchOpenPublishForm({
                              projectId: this.props.projectData.id,
                              resourceId: this.props.projectData.id,
                              purpose: 'SOLUTION_STORAGE',
                            })
                          }
                        />
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </StudioDrawer>
      </>
    );
  }
}

PropertiesPanelFormBase.defaultProps = {
  projectData: {},
};

const mapStateToProps = state => ({
  isOpen: getIsEditProjectFormOpen(state),
  projectData: getProjectData(state),
});

const mapDispatchToProps = {
  dispatchViewCloseEditProjectForm: viewCloseEditProjectForm,
  dispatchProjectUpdate: projectUpdate,
  dispatchProjectFetch: projectFetch,
  dispatchProjectListFetchReplace: projectListFetchReplace,
  dispatchProjectDelete: projectDelete,
  dispatchOpenPublishForm: openPublishForm,
};

const PropertiesPanelForm = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(PropertiesPanelFormBase);

export {PropertiesPanelForm};
