import {
  Box,
  CardMedia,
  IconButton,
  IconButtonProps,
  Popover,
  PopoverProps,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import fileSize from 'filesize';
import {ImageProps} from 'material-ui-image/lib/components/Image/Image';
import * as React from 'react';
import HumanReadableTime from '../../base-components/StudioHumanReadableTime/HumanReadableTime';
import {StudioCanvasOverlay} from '../../base-components/StudioOverlay/StudioCanvasOverlay';
import {BoundingBoxType} from '../../base-components/StudioOverlay/StudioCanvasOverlay/StudioCanvasOverlay';
import {StudioImageNode} from '../../base-components/StudioOverlay/StudioImageNode/StudioImageNode';
import {useIntl} from 'react-intl';
import './DataViewerTooltip.scss';

type DataViewerTooltipProps = {
  children: React.ReactNode;
  filename: string;
  label?: string;
  lastEdited?: string;
  size: number;
  url?: string;
  width?: number | undefined;
  height?: number | undefined;
  annotation?: BoundingBoxType[];
};

function getPreviewSize() {
  // It was 800 before which was ok for small images
  // 0.9 seems reasonable in the absence of the UX design
  return window.innerWidth * 0.9;
}

const getScaleFactor = (from: number, to: number) => to / from;
const scaleAnnotation = (scaleX: number, scaleY: number, annotation: BoundingBoxType[]) =>
  annotation.map(({bbox, ...rest}) => ({
    ...rest,
    bbox: bbox
      ? [(bbox[0] || 0) * scaleX, bbox[1] * scaleY, bbox[2] * scaleX, bbox[3] * scaleY]
      : undefined,
  }));

export const DataViewerTooltip = ({
  children,
  filename,
  label,
  lastEdited,
  size,
  url,
  width,
  height,
  annotation,
}: DataViewerTooltipProps) => {
  const [anchorEl, setAnchorEl] = React.useState<PopoverProps['anchorEl']>(null);

  const onOpen: ImageProps['onClick'] = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const onClose: DataViewerTooltipItemProps['onClick'] = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div onClick={onOpen}>
      {children}
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        <DataViewerTooltipItem
          onClick={onClose}
          filename={filename}
          label={label}
          lastEdited={lastEdited}
          size={size || getPreviewSize()}
          src={url || ''}
          width={width}
          height={height}
          annotation={annotation}
        />
      </Popover>
    </div>
  );
};

type DataViewerTooltipItemProps = {
  filename: string;
  label?: string;
  lastEdited?: string;
  onClick: IconButtonProps['onClick'];
  size: number;
  src: string;
  width: number | undefined;
  height: number | undefined;
  annotation?: BoundingBoxType[];
};

const DataViewerTooltipItem = ({
  filename,
  label,
  lastEdited,
  onClick,
  size,
  src,
  width = 0,
  height = 0,
  annotation = [],
}: DataViewerTooltipItemProps) => {
  const intl = useIntl();
  const scaleX = getScaleFactor(width, getPreviewSize());
  const scaleY = getScaleFactor(height, getPreviewSize());
  const scaleFactor = Math.min(scaleX, scaleY);
  const nWidth = Math.round(scaleFactor * width);
  const nHeight = Math.round(scaleFactor * height);
  const [imageResolution, setImageResolution] = React.useState({
    width: 0,
    height: 0,
  });

  return (
    <div className="data-viewer-tooltip">
      <CardMedia>
        <StudioCanvasOverlay
          width={nWidth}
          height={nHeight}
          data={scaleAnnotation(scaleFactor, scaleFactor, annotation)}
          showLabel={true}
          selectOnMouseOver={true}
        >
          {Boolean(nWidth && nHeight) && (
            <StudioImageNode
              src={src + `&resolution=${nWidth}:${nHeight}`}
              size={320}
              onLoad={value => setImageResolution(value)}
            />
          )}
        </StudioCanvasOverlay>
      </CardMedia>
      <IconButton className="data-viewer-tooltip__close-button" onClick={onClick}>
        <Close fontSize="small" />
      </IconButton>
      <Box display="flex" className="data-viewer-tooltip__metadata">
        <Box display="flex" flexDirection="column">
          <div className="data-viewer-tooltip__filename">{filename}</div>
          {lastEdited && (
            <div className="data-viewer-tooltip__last-edited">
              Last edited <HumanReadableTime date={lastEdited} />
            </div>
          )}
          {label && <div className="data-viewer-tooltip__label">Label: {label}</div>}
        </Box>
        <Box display="flex">
          <div className="data-viewer-tooltip__size">File size: {fileSize(size)}</div>
        </Box>
        <Box display="flex">
          <div className="data-viewer-tooltip__size">
            {intl.formatMessage(
              {id: 'dataViewer.dimensions.size'},
              {
                width: imageResolution?.width * 2,
                height: imageResolution?.height * 2,
              }
            )}
          </div>
        </Box>
      </Box>
    </div>
  );
};
