import React from 'react';
import StagePanel from '../StagePanel/StagePanel';
import BaseStageList from '../Base/BaseStageList';
import ModelPanel from '../StagePanel/ModelPanel';
import DataPanel from '../StagePanel/DataPanel';
import OptimizationPanel from '../StagePanel/OptimizationPanel';
import FinetunePanel from '../StagePanel/FinetunePanel';
import DeploymentPanel from '../StagePanel/DeploymentPanel';
import AssistantPanel from '../../DialogLegacy/AssistantPanel';
import {ModelViewer} from '../../ModelViewer/ModelViewer';
import CreateDeploymentAction from '../StageActions/CreateDeploymentAction';
import ImportModelAction from '../StageActions/ImportModelAction';
import {DataUploadActionBase} from '../StageActions/DataUploadAction';
import ModelConversionAction from '../StageActions/ModelConversionAction';
import FinetuneAction from '../StageActions/FinetuneAction';
import PropTypes from 'prop-types';
import TaskInfoProps from '../../../model/taskInfoPropTypes';
import ModelCreateHelperFileAction from '../StageActions/ModelCreateHelperFileAction';
import './StageList.scss';

class StageList extends BaseStageList {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };

    this.finetunePanel = React.createRef();
    this.modelPanel = React.createRef();
    this.dataPanel = React.createRef();

    this.updateAccuracy = this.updateAccuracy.bind(this);
    this.updateConversionStatus = this.updateConversionStatus.bind(this);
    this.updateStatistics = this.updateStatistics.bind(this);
    this.setImageSet = this.setImageSet.bind(this);
  }

  render() {
    return (
      <div className="stage-list" id="stage-list-container">
        {this.renderList()}
      </div>
    );
  }

  updateAccuracy(stage) {
    if (stage && stage.type === 'FINETUNE_MODEL' && this.finetunePanel.current) {
      this.finetunePanel.current.update(stage);
    }
  }

  updateConversionStatus(stage) {
    if (stage && this.modelPanel.current) {
      this.modelPanel.current.update(stage);
    }
  }

  updateStatistics(statistics, message, percent) {
    if (this.finetunePanel.current) {
      this.finetunePanel.current.updateStatistics(statistics, message, percent);
    }
  }

  setImageSet(images) {
    if (this.dataPanel.current) {
      this.dataPanel.current.setImageSet(images);
    }
  }

  getDataPanel() {
    return this.dataPanel;
  }

  getModelPanel() {
    return this.modelPanel;
  }

  getStage(stage, first, last, finished, active) {
    const key = stage.type + '-panel';

    switch (stage.type) {
      case 'ADD_DATA':
        return (
          <DataPanel
            stage={stage}
            first={first}
            last={last}
            prevFinished={finished}
            active={active}
            key={key}
            projectId={this.props.projectId}
            ref={this.dataPanel}
            assistantPanel={this.props.assistantPanel}
            onFinish={this.props.onFinish}
            dataUploadAction={this.props.dataUploadAction}
            dataSplitAction={this.props.dataSplitAction}
            dataTilingAction={this.props.dataTilingAction}
            taskProgress={this.props.taskProgress}
            datasetActionInProgress={this.props.datasetActionInProgress}
            setDatasetActionInProgress={this.props.setDatasetActionInProgress}
            resplitAvailable={this.props.resplitAvailable}
            tilingAvailable={this.props.tilingAvailable}
            modelActionInProgress={this.props.modelActionInProgress}
            openTerminateDialog={this.props.openTerminateDialog}
            dataPrepUnfinished={this.props.dataPrepUnfinished}
            dataPrepAction={this.props.dataPrepAction}
          />
        );
      case 'IMPORT_MODEL':
        return (
          <ModelPanel
            stage={stage}
            first={first}
            last={last}
            prevFinished={finished}
            active={active}
            key={key}
            projectId={this.props.projectId}
            ref={this.modelPanel}
            assistantPanel={this.props.assistantPanel}
            modelViewer={this.props.modelViewer}
            onFinish={this.props.onFinish}
            importModelAction={this.props.importModelAction}
            modelCreateHelperFileAction={this.props.modelCreateHelperFileAction}
            modelConversionAction={this.props.modelConversionAction}
            modelBuildAction={this.props.modelBuildAction}
            prepareModelFileAction={this.props.prepareModelFileAction}
            taskProgress={this.props.taskProgress}
            modelActionInProgress={this.props.modelActionInProgress}
            setModelActionInProgress={this.props.setModelActionInProgress}
            readyForModelBuilding={this.props.readyForModelBuilding}
            readyForDataSplit={this.props.readyForDataSplit}
            createHelperFileAction={this.props.createHelperFileAction}
            openTerminateDialog={this.props.openTerminateDialog}
          />
        );
      case 'OPTIMIZE_MODEL':
        return (
          <OptimizationPanel
            stage={stage}
            first={first}
            last={last}
            prevFinished={finished}
            active={active}
            key={key}
            projectId={this.props.projectId}
            assistantPanel={this.props.assistantPanel}
            onFinish={this.props.onFinish}
            modelOptimizationAction={this.props.modelOptimizationAction}
            openTerminateDialog={this.props.openTerminateDialog}
          />
        );
      case 'FINETUNE_MODEL':
        return (
          <FinetunePanel
            stage={stage}
            modelPurpose={this.props.model?.modelPurpose}
            dataset={this.props.dataset}
            optimizationStage={this.props.stages[2]}
            first={first}
            last={last}
            prevFinished={finished}
            active={active}
            key={key}
            projectId={this.props.projectId}
            assistantPanel={this.props.assistantPanel}
            ref={this.finetunePanel}
            onFinish={this.props.onFinish}
            finetuneAction={this.props.finetuneAction}
            readyForFinetuning={this.props.readyForFinetuning}
            openTerminateDialog={this.props.openTerminateDialog}
          />
        );
      case 'CREATE_DEPLOYMENT':
        return (
          <DeploymentPanel
            stage={stage}
            first={first}
            last={last}
            prevFinished={finished}
            active={active}
            key={key}
            projectId={this.props.projectId}
            assistantPanel={this.props.assistantPanel}
            onFinish={this.props.onFinish}
            createDeploymentAction={this.props.createDeploymentAction}
            openTerminateDialog={this.props.openTerminateDialog}
          />
        );
      default:
        return (
          <StagePanel
            stage={stage}
            first={first}
            last={last}
            prevFinished={finished}
            active={active}
            key={key}
          />
        );
    }
  }
}

StageList.propTypes = {
  assistantPanel: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(AssistantPanel)}),
    PropTypes.shape({current: null}),
  ]).isRequired,
  modelViewer: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(ModelViewer)}),
    PropTypes.shape({current: null}),
  ]).isRequired,
  onFinish: PropTypes.func.isRequired,
  dataUploadAction: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(DataUploadActionBase)}),
    PropTypes.shape({current: null}),
  ]).isRequired,
  importModelAction: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(ImportModelAction)}),
    PropTypes.shape({current: null}),
  ]).isRequired,
  modelConversionAction: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(ModelConversionAction)}),
    PropTypes.shape({current: null}),
  ]).isRequired,
  finetuneAction: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(FinetuneAction)}),
    PropTypes.shape({current: null}),
  ]).isRequired,
  createDeploymentAction: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(CreateDeploymentAction)}),
    PropTypes.shape({current: null}),
  ]).isRequired,
  createHelperFileAction: PropTypes.oneOfType([
    PropTypes.shape({current: PropTypes.instanceOf(ModelCreateHelperFileAction)}),
    PropTypes.shape({current: null}),
  ]).isRequired,
  taskProgress: TaskInfoProps,
  model: PropTypes.object,
};

export default StageList;
