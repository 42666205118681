const CURVATURE = -1;
export const renderPath = data => {
  const {points} = data;
  const [x1, y1, x2, y2] = points;
  const hx1 = x1 - (x2 - x1) * CURVATURE;
  const hx2 = x2 + (x2 - x1) * CURVATURE;
  data.d = `M ${x1} ${y1} C ${hx2} ${y2} ${hx1} ${y1} ${x2} ${y2}`;
};

const ZOOM_VAR = 0.015;
const ZOOM_UPPER_LIMIT = 2.0;
const ZOOM_LOWER_LIMIT = 0.15;
export const zoom = e => {
  let k = e.transform.k; // current zoom level
  e.zoom = e.zoom > k ? k + ZOOM_VAR : k - ZOOM_VAR;

  // Fix zoom limits
  if (e.zoom < ZOOM_LOWER_LIMIT) e.zoom = ZOOM_LOWER_LIMIT;
  else if (e.zoom > ZOOM_UPPER_LIMIT) e.zoom = ZOOM_UPPER_LIMIT;

  e.zoom = +e.zoom.toFixed(2);

  // Prevent zoom due to double clicking
  return e.source !== 'dblclick';
};
