import React from 'react';
import {Skeleton} from '@material-ui/lab';

export const LoadingSkeleton = ({count}: {count: number}) => {
  const dimensions = {
    height: 200,
    width: 200,
  };

  return (
    <div style={{display: 'flex', flexWrap: 'wrap', gap: '16px'}}>
      {new Array(count).fill(0).map((_, index) => (
        <Skeleton
          key={index}
          variant="rect"
          width={dimensions.width}
          height={dimensions.height}
        />
      ))}
    </div>
  );
};
