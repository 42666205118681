import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from '@material-ui/core/styles';
import './StudioTooltip.scss';

// Reference: https://argodesign.invisionapp.com/d/main/#/console/18628127/390524587/preview
const useStyles = makeStyles(theme => ({
  // Note: Could not convert this to css since its an svg reference.
  arrow: {
    color: 'var(--greyscale6)',
  },
}));

export const StudioTooltip = props => (
  <Tooltip arrow classes={useStyles()} {...props} data-testid="tooltip" />
);
