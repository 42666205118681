import {BaseModel} from '../../types/job/BaseModel';
import {Framework} from '../../types/job/Framework';
import {SortDirection} from '../../types/marketplace/MarketplaceFilesRequest';
import {ModelPurpose} from '../../types/model/ModelPurpose';
import {ModelType} from '../../types/model/ModelType';
import {request} from '../request';
import {RuntimeType} from '../../types/model/framework/RuntimeType';

export type NodeStatus = 'ONLINE' | 'OFFLINE';

export type NodeSupportedModel = {
  type: ModelType;
  modelPurpose: ModelPurpose;
  baseModel?: BaseModel;
  source: string;
  framework: Framework;
};

export type NodeSource = {
  sourceId: string;
  sourceName: string;
  sourceType: string;
};

export type NodeResourceMetric =
  | 'STORAGE_AVAILABLE'
  | 'STORAGE_USED'
  | 'STORAGE_TOTAL'
  | 'RAM_AVAILABLE'
  | 'RAM_USED'
  | 'RAM_TOTAL'
  | 'SWAP_AVAILABLE'
  | 'SWAP_USED'
  | 'SWAP_TOTAL';

export type NodeSensorMetric = 'TEMPERATURE' | 'UTILIZATION' | 'POWER_CONSUMPTION';

export type BoardType =
  | 'gsp_accelerator'
  | 'gsp_embedded'
  | 'gsp_embedded_remote'
  | 'gpu';

export type NodeRuntime = {
  type: RuntimeType;
  runtimeVersion: string;
  frameworksAndVersions: Record<string, string>;
  supportedModels?: Array<NodeSupportedModel>;
};

export type NodeBoard = {
  id: string;
  type: BoardType;
  deviceName: string;
  model: string;
  serial: string;
  driverName: string;
  driverVersion: string;
  sdkName: string;
  sdkVersion: string;
  resources?: Record<NodeResourceMetric, number>;
  sensors?: Record<NodeSensorMetric, number>;
};

export type ProductionNode = {
  id: string;
  name: string;
  ownerId: string;
  nodeUrl: string;
  runtimes?: Array<NodeRuntime>;
  boards?: Array<NodeBoard>;
  sources?: Array<NodeSource>;
  resources?: Record<NodeResourceMetric, number>;
  sensors?: Record<NodeSensorMetric, number>;
  status: NodeStatus;
  lastUpdateTime: string;
};

export type NodesSortableColumn = 'name' | 'status' | 'lastUpdateTime' | 'nodeUrl';

export type DeploymentNodesRequest = {
  filter: string;
  page: number;
  size?: number;
  sort: [NodesSortableColumn, SortDirection];
};

export const GET_DEPLOYMENT_NODES_URL = '/deployment/nodes';

export async function getDeploymentNodes(params?: DeploymentNodesRequest) {
  return request<Array<ProductionNode>>({
    method: 'get',
    url: '/deployment/nodes',
    params,
    // The default param serializer for axios doesn't format array params in the way
    // studio server expects. TODO: determine if this setting should be global on axios
    paramsSerializer: params => {
      const searchParams = new URLSearchParams(params);
      return searchParams.toString();
    },
  });
}
