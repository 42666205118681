import {maxBy} from 'lodash';
import React, {useMemo, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import URL_SETTINGS, {MODEL_SUB_OP} from '../../../config/url';
import {DeploymentClassificationResult} from '../../../types/deployment/DeploymentClassificationResult';
import {UnparsedJson} from '../../../types/utility/UnparsedJson';
import {ImageCardDetail, ImageCardHorizontal} from '../ImageCard';
import './ClassificationResult.scss';

const IMAGE_WIDTH = 125;
const IMAGE_HEIGHT = 125;
const IMAGE_TITLE_HEIGHT = 20;

export type ClassificationResultProps = {
  search: string;
  data: UnparsedJson;
  projectId: string;
  deploymentId: string;
};

export function ClassificationResult({
  search,
  data,
  projectId,
  deploymentId,
}: ClassificationResultProps) {
  const baseImagesUrl = useMemo(
    () => URL_SETTINGS.DATASET(projectId, MODEL_SUB_OP.GET_BY_ID),
    [projectId]
  );
  const images = useMemo(() => {
    // TODO: fix backend for empty inferenceResults
    if (data === '""') {
      return [];
    }

    let images: DeploymentClassificationResult[] = JSON.parse(data);

    if (search) {
      images = images.filter(image => {
        const topResult = maxBy(image.stats, row => parseFloat(row.progress ?? '0'));
        return topResult?.label?.toLowerCase().includes(search.toLowerCase()) ?? false;
      });
    }

    return images ?? [];
  }, [data, search]);

  const getImageUrl = useCallback(
    (filename: string) =>
      `${baseImagesUrl}?id=${encodeURIComponent(
        filename
      )}&deploymentId=${deploymentId}&dataPurpose=INPUT&cachebust=${Date.now()}&resolution=${IMAGE_WIDTH}:${IMAGE_HEIGHT}`,
    [baseImagesUrl, deploymentId]
  );

  return (
    <div className="classification-result">
      <div className="inference-grid-container">
        <div className="view-grid">
          {images.length === 0 ? (
            <div className="classification-result__no-results">
              <FormattedMessage id="test.noResults" />
            </div>
          ) : (
            images.map((image, i) => (
              <ImageCardHorizontal
                key={`inference-image-${i}`}
                image={getImageUrl(image.filename ?? '')}
                width={IMAGE_WIDTH}
                height={IMAGE_HEIGHT}
                detailComponent={
                  image.stats && (
                    <ImageCardDetail
                      data={image.stats.map(stat => ({
                        label: stat.label ?? '',
                        progress: parseFloat(stat.progress ?? '0') / 100,
                      }))}
                      height={IMAGE_HEIGHT + IMAGE_TITLE_HEIGHT}
                      countObjects={false}
                    />
                  )
                }
                data-testid="classification-inference-result"
                className="inference-result__image-card"
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}
