import React from 'react';
import './ContentCopyIcon';

export const ContentCopyIcon = () => {
  return (
    <button className={'MuiButtonBase-root MuiIconButton-root copy-content-icon'}>
      <div className={'MuiIconButton-label'}>
        <svg
          className={'MuiSvgIcon-root MuiSvgIcon-fontSizeSmall'}
          focusable={false}
          aria-hidden={true}
          viewBox={'0 0 24 24'}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path>
        </svg>
      </div>
    </button>
  );
};
