import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import './ConfirmDialog.scss';

const ICON_MAP_TYPE = {
  confirm: <ErrorIcon fontSize="medium" color="secondary" />,
};

export function ConfirmDialog({
  type,
  title,
  message,
  onClose,
  onOk,
  open,
  cancelLabel,
  submitLabel,
  disableSubmit,
}) {
  return (
    <Dialog
      className="confirm-dialog"
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog"
      aria-describedby="confirm-dialog-description"
      data-testid="confirm-dialog"
      id="confirm-dialog"
    >
      <DialogTitle>
        <div className="confirm-dialog__title-container">
          {ICON_MAP_TYPE[type]}
          <span className="confirm-dialog__title">{title}</span>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="confirm-dialog__message">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {cancelLabel}
        </Button>
        <Button
          data-testid="confirm-dialog-ok"
          id="confirm-dialog-ok"
          onClick={onOk}
          variant="contained"
          color="secondary"
          disableElevation
          disabled={disableSubmit}
        >
          {submitLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  type: '',
  title: '',
  message: '',
  cancelLabel: 'Cancel',
  submitLabel: 'Submit',
  open: false,
  onClose: () => {},
  onOk: () => {},
  disableSubmit: false,
};
