import {Button} from '@material-ui/core';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router';
import cn from 'classnames';
import './ReturnToPreviousPageButton.scss';

type ReturnToPreviousPageButtonProps = {
  className?: string;
};

export const ReturnToPreviousPageButton = ({
  className,
}: ReturnToPreviousPageButtonProps) => {
  const history = useHistory();

  const previousPageExists = history.length > 1;

  return previousPageExists ? (
    <Button
      className={cn('return-to-previous-btn', className)}
      variant="contained"
      disableElevation
      onClick={() => history.goBack()}
    >
      <FormattedMessage id="form.returnToPreviousPage" />
    </Button>
  ) : null;
};
