import Axios from 'axios';
import {DataSplitType} from '../../types/dataset/Dataset';
import {DatasetGetAnnotationsResponse} from '../../types/dataset/DatasetGetAnnotationsResponse';

type GetAnnotationsRequest = {
  applicationId: string;
  splitType: DataSplitType;
};

export async function getAnnotations({applicationId, splitType}: GetAnnotationsRequest) {
  return Axios.request<DatasetGetAnnotationsResponse>({
    method: 'GET',
    url: `/studio/dataset/application/${applicationId}/get_image_categories`,
    params: {
      splitType,
    },
  });
}
