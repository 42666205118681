import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type DialogState = {
  isOpen: boolean;
};

const initialState: DialogState = {
  isOpen: false,
};

const addPPDialogSlice = createSlice({
  name: 'marketplace-add-pp',
  initialState,
  reducers: {
    setIsOpen: (
      state,
      action: PayloadAction<{
        isOpen: DialogState['isOpen'];
      }>
    ) => {
      state.isOpen = action.payload.isOpen;
    },
  },
});

export const {reducer: addPPDialogReducer, actions} = addPPDialogSlice;
