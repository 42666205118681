import React from 'react';
import ImageIcon from '@material-ui/icons/Image';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import TextIcon from '@material-ui/icons/Description';
import ArchiveIcon from '@material-ui/icons/Unarchive';

const getIcon = ext => {
  // TODO: Need identify more file types
  switch (ext) {
    case 'image/png':
    case 'image/jpg':
      return <ImageIcon />;
    case 'application/pdf':
    case 'text/html':
    case 'application/vnd.ms-excel':
    case 'doc':
      return <TextIcon />;
    case 'application/zip':
    case 'application/tar+gzip':
      return <ArchiveIcon />;

    default:
      return <FileIcon />;
  }
};

export {getIcon};
