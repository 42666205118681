import axios from 'axios';
import {Application} from '../../types/project/Application';
import {Response} from '../../types/response/Response';

type UpdatePermissionsRequest = {
  projectId: string;
  applicationId: string;
  exposeAPI: boolean;
  exposeInferencePage: boolean;
};

export const UPDATE_PERMISSIONS_URL = '/studio/application/permissions/update';

export async function updatePermissions(args: [string, UpdatePermissionsRequest]) {
  const {data} = await axios.post<Response<Application>>(args[0], args[1]);
  return data.body;
}
