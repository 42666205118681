import React from 'react';
import {useFormikContext} from 'formik';

import {DeploymentDetailsInfo} from '../../../types/deployment/DeploymentDetailsInfo';
import {DeploymentActionButton} from '../DeploymentActionButton/DeploymentActionButton';
import {GSPProdOperationStatus} from '../../../types/deployment/GSPProdOperationStatus';

type DeploymentButtonProps = {
  deployment: DeploymentDetailsInfo;
  statusToDisplay: GSPProdOperationStatus | undefined;

  onStop?: () => void;
  onStart?: () => void;
};

const DeploymentButton = ({
  deployment,
  statusToDisplay,
  onStop,
  onStart,
}: DeploymentButtonProps) => {
  const {isValid} = useFormikContext();

  return Boolean(
    deployment.status && deployment.projectId && deployment.applicationId
  ) ? (
    <DeploymentActionButton
      onStop={onStop}
      onStart={onStart}
      detailedTooltip={true}
      formStatusOverride={isValid}
      status={statusToDisplay || 'IDLE'}
      streamingUrl={deployment.source?.streamingUrl}
      inputSourceId={deployment.source?.inputSourceId}
      inputSourceType={deployment.source?.inputSourceType}
      inputSourceLocationType={deployment.source?.inputSourceLocationType}
    />
  ) : null;
};

export default React.memo(DeploymentButton);
