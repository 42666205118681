import {Chip} from '@material-ui/core';
import {Autocomplete, UseAutocompleteMultipleProps} from '@material-ui/lab';
import cn from 'classnames';
import React from 'react';
import {StudioPopover} from '../StudioPopover';
import {StudioTextField} from '../StudioTextField';
import './StudioTags.scss';

type StudioTagsProps = {
  className?: string;
  label: string;
  name: string;
  onChange: UseAutocompleteMultipleProps<string>['onChange'];
  options: UseAutocompleteMultipleProps<string>['options'];
  tooltip?: string;
  fixedTooltip?: boolean;
  tooltipPlacement?: 'top' | 'right';
  value?: string[];
  placeholder?: string;
  disabled?: boolean;
};

export const StudioTags = ({
  className,
  label,
  name,
  onChange,
  options,
  tooltip,
  fixedTooltip = false,
  tooltipPlacement = 'right',
  value,
  placeholder,
  disabled,
}: StudioTagsProps) => {
  return (
    <div
      className={cn('studio-tags', `studio-tags__tooltip-${tooltipPlacement}`, className)}
    >
      <Autocomplete<string>
        multiple
        freeSolo
        onChange={onChange}
        options={options}
        filterOptions={() => []}
        disabled={disabled}
        value={value}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({index})} />
          ))
        }
        renderInput={params => (
          <StudioTextField
            {...params}
            name={name}
            label={label}
            disabled={disabled}
            placeholder={placeholder}
            variant="filled"
          />
        )}
      />
      {(tooltip || fixedTooltip) && (
        <StudioPopover
          infoIconClass={cn(
            'studio-tags__tooltip',
            fixedTooltip && !tooltip && 'studio-text-field__tooltip--hidden'
          )}
          infoMessage={tooltip}
          ttPlacement="top"
        />
      )}
    </div>
  );
};
