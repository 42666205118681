import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './ModelActionButton.scss';

import ViewDetailIcon from '../../assets/icons/ViewDetailsIcon';
import PlaceholderIcon from '../../assets/icons/PlaceholderIcon';
import RestartIcon from '../../assets/icons/RestartIcon';
import PauseIcon from '../../assets/icons/PauseIcon';
import DuplicateIcon from '../../assets/icons/DuplicateIcon';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import DeployIcon from '../../assets/icons/DeployIcon';
import RunIcon from '../../assets/icons/RunIcon';
import StopIcon from '../../assets/icons/StopIcon';
import CancelIcon from '../../assets/icons/CancelIcon';
import CloseIcon from '../../assets/icons/CloseIcon';
import ReplaceIcon from '../../assets/icons/ReplaceIcon';
import PercentIcon from '../../assets/icons/PercentIcon';
import PlusIcon from '../../assets/icons/PlusIcon';
import ShareIcon from '../../assets/icons/ShareIcon';
import {FormattedMessage} from 'react-intl';
import {Edit} from '@material-ui/icons';
import TilingIcon from '../../assets/icons/TilingIcon';

// when making a button group, wrap them in a div given this styling...
//
// .[your-parent-element-class-here] > .[you-div-class-here] {
//     box-shadow: var(--shadow2); <--------------------------------if floating, else remove line
//     border-radius: 8px; <----------------------------------------if floating, else remove line
//     display:inline-flex;
//     flex-flow: row nowrap;
//     align-items: flex-start;
// }

class ModelActionButton extends Component {
  render() {
    const {id, value, customClass, nested, ...rest} = this.props;
    return (
      <button
        {...(id ? {id: id} : {})}
        className={cn(
          'model-action-button',
          customClass,
          this.props.disabled && 'disabled',
          this.props.value,
          nested && 'model-action-button--nested'
        )}
        {...rest}
      >
        {this.getIcon(value)}
        <FormattedMessage id={`action.${value}`} />
      </button>
    );
  }

  getIcon(name) {
    const icons = {
      details: <ViewDetailIcon className="button-icon" />,
      retrain: <PlaceholderIcon className="button-icon" />,
      reoptimize: <PlaceholderIcon className="button-icon" />,
      'fine-tune': <PlaceholderIcon className="button-icon" />,
      'fine-tuned': <PlaceholderIcon className="button-icon" />,
      restart: <RestartIcon className="button-icon" />,
      pause: <PauseIcon className="button-icon" />,
      duplicate: <DuplicateIcon className="button-icon" />,
      delete: <DeleteIcon className="button-icon" />,
      edit: <Edit className="button-icon" />,
      deploy: <DeployIcon className="button-icon" />,
      run: <RunIcon className="button-icon" />,
      resume: <RunIcon className="button-icon" />,
      stop: <StopIcon className="button-icon" />,
      cancel: <CancelIcon className="button-icon" />,
      terminate: <CloseIcon className="button-icon" />,
      replace: <ReplaceIcon className="button-icon" />,
      'split data': <PercentIcon className="button-icon" />,
      'tile data': <TilingIcon className="button-icon" />,
      solution: <PlusIcon className="button-icon" />,
      share: <ShareIcon className="button-icon" />,
    };
    return icons[name];
  }
}

ModelActionButton.propTypes = {
  value: PropTypes.string.isRequired,
  id: PropTypes.string,
  customClass: PropTypes.string,
  nested: PropTypes.bool,
};

ModelActionButton.defaultProps = {
  id: null,
  customClass: null,
  onClick: () => null,
  disabled: false,
  nested: false,
};

export default ModelActionButton;
