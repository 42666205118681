import Axios, {AxiosRequestConfig} from 'axios';
import {Response} from '../types/response/Response';

export const request = async <T = never>(config: AxiosRequestConfig) => {
  const {data} = await Axios.request<Response<T>>({
    ...config,
    url: '/studio' + config.url,
  });

  return data;
};

export const assertResponseBody = async <T>(promise: Promise<Response<T>>) => {
  try {
    const data = await promise;
    return data.body;
  } catch (error) {
    console.error(error);
  }
};
