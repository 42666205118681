import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {Field, FieldArray, FieldArrayRenderProps, FieldProps, FormikProps} from 'formik';
import React from 'react';
import {StudioCheckbox} from '../../../base-components/StudioCheckbox';
import {StudioSelect} from '../../../base-components/StudioSelect';
import {StudioTextField} from '../../../base-components/StudioTextField';
import {DataType, Parameter} from '../../../types/model/framework/Parameter';
import {Delete} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import {getParameterValidationRule, ParameterField} from './ParameterField';
import {
  NumericIntervalField,
  validateIsInteger,
  validateMin,
} from './NumericIntervalField';
import {OptionsBuilderField} from './OptionsBuilderField';
import * as Yup from 'yup';

export const parameterArrayValidation = Yup.array().of(
  Yup.object().shape({
    dataType: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    displayName: Yup.string().required('Required'),
    numericInterval: Yup.string()
      .when('dataType', {
        is: (dataType: DataType) =>
          dataType === 'FLOATING_POINT_NUMBER' || dataType === 'INTEGER',
        then: validateMin,
      })
      .when('dataType', {
        is: (dataType: DataType) => dataType === 'INTEGER',
        then: validateIsInteger,
      }),
    options: Yup.object().when('dataType', {
      is: (dataType: DataType) => dataType === 'MULTIPLE_CHOICES',
      then: Yup.object().test('options-exist', 'Required', options =>
        Boolean(options && Object.entries(options).length)
      ),
    }),
    value: Yup.mixed().test('validate-value', '', function(value) {
      const param: Parameter = this.parent;
      try {
        getParameterValidationRule(param)
          ?.required('Required')
          .validateSync(value);
        return true;
      } catch (e) {
        if (e instanceof Error) {
          return this.createError({
            message: e.message,
          });
        }
        return false;
      }
    }),
  })
);

const dataTypeOptions: Record<DataType, string> = {
  FLAG: 'Boolean',
  FLOATING_POINT_NUMBER: 'Floating point',
  INTEGER: 'Integer',
  MULTILINE_TEXT: 'Multiline Text',
  MULTIPLE_CHOICES: 'Multiple Choice',
  TEXT: 'Text',
  URL: 'URL',
};

type ParameterFieldArrayProps = {
  name: string;
  parameters: Parameter[];
  disabled?: boolean;
};
export const ParameterFieldArray = ({
  name,
  parameters,
  disabled,
}: ParameterFieldArrayProps) => {
  const intl = useIntl();

  const required = (label: string) => <>{label}</>;

  const handleAddNewParameter = (push: FieldArrayRenderProps['push']) => {
    push({
      name: '',
      parameterType: 'DEPLOYMENT',
      dataType: '',
      displayName: '',
      description: '',
      value: '',
      options: undefined,
    });
  };

  const resetOnDataTypeChange = (index: number, form: FormikProps<any>) => {
    form.setFieldValue(`${name}[${index}].numericInterval`, undefined);
    form.setFieldValue(`${name}[${index}].options`, undefined);
    form.setFieldValue(`${name}[${index}].value`, '');
  };

  const renderConfiguration = (dataType?: DataType, index?: number) => {
    if (!dataType || (!index && index !== 0)) {
      return null;
    }
    if (dataType === 'FLOATING_POINT_NUMBER' || dataType === 'INTEGER') {
      return (
        <Field name={`${name}[${index}].numericInterval`}>
          {({field, form, meta}: FieldProps<Parameter[][number]['numericInterval']>) => (
            <NumericIntervalField
              dataType={dataType}
              interval={field.value}
              onChange={interval => {
                form.setFieldValue(`${name}[${index}].numericInterval`, interval);
              }}
              errorText={meta.error && meta.touched ? meta.error : ''}
              disabled={disabled}
            />
          )}
        </Field>
      );
    }
    if (dataType === 'MULTIPLE_CHOICES') {
      return (
        <Field name={`${name}[${index}].options`}>
          {({field, form, meta}: FieldProps<Parameter[][number]['options']>) => (
            <OptionsBuilderField
              options={field.value}
              onChange={options =>
                form.setFieldValue(`${name}[${index}].options`, options)
              }
              errorText={meta.error && meta.touched ? meta.error : ''}
              disabled={disabled}
            />
          )}
        </Field>
      );
    }
    return 'N/A';
  };

  return (
    <FieldArray name={name}>
      {arrayHelpers => (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {required(
                    intl.formatMessage({
                      id: 'sharedProcessor.form.referenceName',
                    })
                  )}
                </TableCell>
                <TableCell>
                  {required(
                    intl.formatMessage({
                      id: 'sharedProcessor.form.displayName',
                    })
                  )}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: 'sharedProcessor.form.description',
                  })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: 'sharedProcessor.form.realtime',
                  })}
                </TableCell>
                <TableCell>
                  {required(
                    intl.formatMessage({
                      id: 'sharedProcessor.form.dataType',
                    })
                  )}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: 'sharedProcessor.form.dataTypeConfig',
                  })}
                </TableCell>
                <TableCell>
                  {required(
                    intl.formatMessage({
                      id: 'sharedProcessor.form.defaultValue',
                    })
                  )}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parameters.map((parameter, index) => {
                const dataType = parameter.dataType;
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Field name={`${name}[${index}].name`}>
                        {({field, meta}: FieldProps<Parameter[][number]['name']>) => (
                          <StudioTextField
                            placeholder={intl.formatMessage({
                              id: 'sharedProcessor.form.referenceName',
                            })}
                            className="processing-form__param-field"
                            InputProps={field}
                            error={Boolean(meta.error && meta.touched)}
                            helperText={meta.error && meta.touched ? meta.error : ''}
                            disabled={disabled}
                            required
                          />
                        )}
                      </Field>
                    </TableCell>
                    <TableCell>
                      <Field name={`${name}[${index}].displayName`}>
                        {({
                          field,
                          meta,
                        }: FieldProps<Parameter[][number]['displayName']>) => (
                          <StudioTextField
                            placeholder={intl.formatMessage({
                              id: 'sharedProcessor.form.displayName',
                            })}
                            className="processing-form__param-field"
                            InputProps={field}
                            error={Boolean(meta.error && meta.touched)}
                            helperText={meta.error && meta.touched ? meta.error : ''}
                            disabled={disabled}
                            required
                          />
                        )}
                      </Field>
                    </TableCell>
                    <TableCell>
                      <Field name={`${name}[${index}].description`}>
                        {({field}: FieldProps<Parameter[][number]['description']>) => (
                          <StudioTextField
                            placeholder={intl.formatMessage({
                              id: 'sharedProcessor.form.description',
                            })}
                            className="processing-form__param-field"
                            InputProps={field}
                            disabled={disabled}
                            multiline
                          />
                        )}
                      </Field>
                    </TableCell>
                    <TableCell>
                      <Field name={`${name}[${index}].parameterType`}>
                        {({
                          field,
                          form,
                        }: FieldProps<Parameter[][number]['parameterType']>) => (
                          <StudioCheckbox
                            {...field}
                            checked={field.value === 'REALTIME'}
                            onChange={() =>
                              form.setFieldValue(
                                `${name}[${index}].parameterType`,
                                field.value === 'REALTIME' ? 'DEPLOYMENT' : 'REALTIME'
                              )
                            }
                            disabled={disabled}
                          />
                        )}
                      </Field>
                    </TableCell>
                    <TableCell>
                      <Field name={`${name}[${index}].dataType`}>
                        {({
                          field,
                          form,
                          meta,
                        }: FieldProps<Parameter[][number]['dataType']>) => (
                          <StudioSelect
                            className="processing-form__param-field"
                            options={dataTypeOptions}
                            placeholder={intl.formatMessage({
                              id: 'sharedProcessor.form.dataType',
                            })}
                            disablePlaceholder
                            SelectProps={{
                              ...field,
                              value: field.value || '',
                              onChange: e => {
                                resetOnDataTypeChange(index, form);
                                field.onChange(e);
                              },
                            }}
                            error={Boolean(meta.error && meta.touched)}
                            helperText={meta.error && meta.touched ? meta.error : ''}
                            disabled={disabled}
                            required
                          />
                        )}
                      </Field>
                    </TableCell>
                    <TableCell>{renderConfiguration(dataType, index)}</TableCell>
                    <TableCell>
                      {!parameter.dataType ? (
                        <StudioTextField
                          className="processing-form__param-field"
                          disabled
                        />
                      ) : (
                        <div className="processing-form__param-field">
                          <ParameterField
                            fieldName={`${name}[${index}].value`}
                            parameter={{
                              dataType: parameter.dataType,
                              options: parameter.options,
                            }}
                            disabled={
                              (parameter.dataType === 'MULTIPLE_CHOICES' &&
                                !Object.entries(parameter.options || {}).length) ||
                              disabled
                            }
                          />
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => arrayHelpers.remove(index)}
                        disabled={disabled}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {parameters.length === 0 && (
            <div className="processing-form__empty">
              {intl.formatMessage({id: 'sharedProcessor.params.empty'})}
            </div>
          )}
          <Button
            className="processing-form__new-param"
            onClick={() => handleAddNewParameter(arrayHelpers.push)}
            disabled={disabled}
          >
            {intl.formatMessage({id: 'sharedProcessor.params.add'})}
          </Button>
        </TableContainer>
      )}
    </FieldArray>
  );
};
