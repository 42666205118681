import React from 'react';
import {useEffect, useState, useRef} from 'react';
import {Image as KImage} from 'react-konva';

export const IMAGE_NAME = 'image';
export type ImageDetails = {
  width: number;
  height: number;
};

export type StudioImageNodeProps = {
  src?: string;
  onLoad?: (e: ImageDetails) => void;
  // Used for batch image loading
  size?: number | undefined;
  position?: number | undefined;
};

export function StudioImageNode({
  src = '',
  onLoad = () => {},
  size,
  position = 0,
}: StudioImageNodeProps) {
  const imageRef = useRef<HTMLImageElement>();
  const [image, setImage] = useState<HTMLImageElement>();

  const onError = (e: Event) => onLoad({width: 0, height: 0});

  const loadImage = () => {
    const img = new window.Image();
    img.src = src;
    imageRef.current = img;
    imageRef.current.addEventListener('load', onImageLoad);
    imageRef.current.addEventListener('error', onError);
  };

  const onImageLoad = (e: Event) => {
    const img = e.target as HTMLImageElement;
    setImage(imageRef.current);
    onLoad({
      width: img.width,
      height: img.height,
    });
  };

  useEffect(() => {
    loadImage();
    return () => {
      const iRef: any = imageRef?.current;
      if (iRef) {
        iRef.removeEventListener('load', onLoad);
        iRef.removeEventListener('error', onError);
      }
    };
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  useEffect(() => loadImage(), [src]);
  return <KImage name={IMAGE_NAME} x={0} y={size ? size * -position : 0} image={image} />;
}
