import React, {Component} from 'react';
import PropTypes from 'prop-types';

class DuplicateIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="16px"
          height="19px"
          viewBox="0 0 16 19"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-848.000000, -6646.000000)"
            >
              <g id="Model-Action-Icons" transform="translate(56.000000, 6308.000000)">
                <g id="row" transform="translate(0.000000, 297.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="model-actions-/-duplicate-/-Enabled,-Focus"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M15.7695312,3 L15.7695312,4.6796875 L5.76953125,4.6796875 L5.76953125,16.359375 L4.08984375,16.359375 L4.08984375,4.6796875 C4.08984375,4.21093516 4.25260254,3.81380371 4.578125,3.48828125 C4.90364746,3.16275879 5.30077891,3 5.76953125,3 L15.7695312,3 Z M18.2695312,6.359375 C18.7122418,6.359375 19.0963525,6.52213379 19.421875,6.84765625 C19.7473975,7.17317871 19.9101562,7.55728945 19.9101562,8 L19.9101562,19.6796875 C19.9101562,20.1484398 19.7473975,20.5455713 19.421875,20.8710938 C19.0963525,21.1966162 18.7122418,21.359375 18.2695312,21.359375 L9.08984375,21.359375 C8.62109141,21.359375 8.22395996,21.1966162 7.8984375,20.8710938 C7.57291504,20.5455713 7.41015625,20.1484398 7.41015625,19.6796875 L7.41015625,8 C7.41015625,7.55728945 7.57291504,7.17317871 7.8984375,6.84765625 C8.22395996,6.52213379 8.62109141,6.359375 9.08984375,6.359375 L18.2695312,6.359375 Z M18.2695312,19.6796875 L18.2695312,8 L9.08984375,8 L9.08984375,19.6796875 L18.2695312,19.6796875 Z"
                        id="duplicate"
                        fill="currentColor"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

DuplicateIcon.propTypes = {
  className: PropTypes.string,
};

export default DuplicateIcon;
