import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import React from 'react';
import cn from 'classnames';
import {useIntl} from 'react-intl';
import {IntegrationRole} from '../../../types/integrations/IntegrationResponse';
import './RoleTabs.scss';

const ROLES: Array<{name: string; type: IntegrationRole}> = [
  {
    name: 'dashboard.marketplace.public',
    type: 'PUBLIC',
  },
  {
    name: 'dashboard.marketplace.org',
    type: 'ORGANIZATION',
  },
];

type RoleTabsProps = {
  role: IntegrationRole;
  onChange: (role: IntegrationRole) => void;
};

export const RoleTabs = ({role, onChange}: RoleTabsProps) => {
  const intl = useIntl();

  const currentIndex = ROLES.findIndex(tab => tab.type === role);
  return (
    <ToggleButtonGroup
      value={currentIndex}
      exclusive
      onChange={(event, index) => {
        if (index != null) {
          const role = ROLES[index].type;
          onChange(role);
        }
      }}
      aria-label="Role"
      className={cn('dashboard__tabs', 'role-tabs')}
    >
      {ROLES.map((org, index) => (
        <ToggleButton
          key={index}
          value={index}
          aria-label="left aligned"
          data-test-id="org-tab"
          className="role-tabs__tab"
        >
          {intl.formatMessage({id: org.name})}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
