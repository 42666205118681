import {
  VIEW_SET_PROJECT_FORM,
  VIEW_CLOSE_PROJECT_FORM,
  VIEW_OPEN_PROJECT_FORM,
  VIEW_RESET_PROJECT_FORM,
  VIEW_OPEN_EDIT_PROJECT_FORM,
  VIEW_CLOSE_EDIT_PROJECT_FORM,
  VIEW_TOGGLE_EDIT_PROJECT_FORM,
  VIEW_SET_ISP,
  VIEW_TOGGLE_EDIT_ISP,
  VIEW_SET_CONSOLE,
  VIEW_SET_PROJECT,
  VIEW_OPEN_TERMINATE_DIALOG,
  VIEW_CLOSE_TERMINATE_DIALOG,
  VIEW_DISABLE_TERMINATION,
  VIEW_ENABLE_TERMINATION,
} from './actions';

// Note: This store deals with global shared state for the application
export const initialState = {
  console: {
    open: false,
  },
  isp: {
    selectedId: null,
    newFormOpen: false,
    editFormOpen: false,
  },
  project: {
    // Used for pre-creation of a new project
    form: {
      templateId: null,
      dataset: null,
      model: null,
      isp: null,
      name: null,
      description: null,
    },
    newFormOpen: false,
    editFormOpen: false,
    disableEditModel: false,
    disableEditData: false,
    disableEditDataStage: false,
    disableAddEditModelStage: false,
    disableModelOptimizationStage: false,
    disableFineTuningStage: false,
    disableDeploymentStage: false,
    terminate: {
      dialogOpen: false,
      enabled: false,
      title: null,
      message: null,
    },
  },
};

export const viewReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_SET_PROJECT:
      return {
        ...state,
        project: {
          ...state.project,
          ...action.payload,
        },
      };
    case VIEW_SET_PROJECT_FORM:
      return {
        ...state,
        project: {
          ...state.project,
          ...action.payload,
        },
      };
    case VIEW_OPEN_PROJECT_FORM:
      return {
        ...state,
        project: {
          ...state.project,
          newFormOpen: true,
        },
      };
    case VIEW_CLOSE_PROJECT_FORM:
      return {
        ...state,
        project: {
          ...state.project,
          form: {...initialState.project.form},
          newFormOpen: false,
        },
      };
    case VIEW_RESET_PROJECT_FORM:
      return {
        ...state,
        project: {
          ...state.project,
          form: {...initialState.project.form},
        },
      };
    case VIEW_OPEN_EDIT_PROJECT_FORM:
      return {
        ...state,
        project: {
          ...state.project,
          editFormOpen: true,
        },
      };
    case VIEW_CLOSE_EDIT_PROJECT_FORM:
      return {
        ...state,
        project: {
          ...state.project,
          editFormOpen: false,
        },
      };
    case VIEW_TOGGLE_EDIT_PROJECT_FORM:
      return {
        ...state,
        project: {
          ...state.project,
          editFormOpen: !state.project.editFormOpen,
        },
      };

    case VIEW_SET_ISP:
      return {
        ...state,
        isp: {...state.isp, ...action.payload},
      };
    case VIEW_TOGGLE_EDIT_ISP:
      return {
        ...state,
        isp: {...state.isp, editFormOpen: !state.isp.editFormOpen},
      };

    case VIEW_SET_CONSOLE:
      return {
        ...state,
        console: {...state.console, ...action.payload},
      };

    case VIEW_OPEN_TERMINATE_DIALOG:
      return {
        ...state,
        project: {
          ...state.project,
          terminate: {
            ...state.project.terminate,
            dialogOpen: true,
            dialogTitle: action.payload.title,
            dialogMessage: action.payload.message,
          },
        },
      };
    case VIEW_CLOSE_TERMINATE_DIALOG:
      return {
        ...state,
        project: {
          ...state.project,
          terminate: {
            ...state.project.terminate,
            dialogOpen: false,
            dialogTitle: null,
            dialogMessage: null,
          },
        },
      };
    case VIEW_ENABLE_TERMINATION:
      return {
        ...state,
        project: {
          ...state.project,
          terminate: {
            ...state.project.terminate,
            enabled: true,
          },
        },
      };
    case VIEW_DISABLE_TERMINATION:
      return {
        ...state,
        project: {
          ...state.project,
          terminate: {
            ...state.project.terminate,
            enabled: false,
          },
        },
      };

    default:
      return state;
  }
};
